import React from 'react';
import { ProgressBar } from 'react-bootstrap';

const GetStartedTemplate = ({title, bar, body}) => {
  return (
    <div className="container" style={{ textAlign: 'center', marginBottom: 50, marginTop: 50, maxWidth:'600px' }}>
      <p style={{ fontSize: 18 }}>{title}</p>
      <ProgressBar animated style={{ height: 4, marginTop: 20, marginBottom: 20 }} now={bar} />
      {body}

    </div>
  );
};

export default GetStartedTemplate;
