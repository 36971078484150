import initialState from './initialState'
import { ADD_USER_ROLE } from '../actions/userRolesAction'
export default (state = initialState.userRolesData, action) => {
  switch (action.type) {
    case ADD_USER_ROLE:
      return { ...state, ...action.userRolesData }
    default:
      return state
  }
}
