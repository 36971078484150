import initialState from "./initialState";
import _ from 'lodash';
import { ADD_OTHER_ASSET_LIST, DELETE_OTHER_ASSET, CREATE_OTHER_ASSET, EDIT_OTHER_ASSET } from "../actions/otherAssetsActions";
export default (state = initialState.otherAssets, action) => {
    switch (action.type) {
        case ADD_OTHER_ASSET_LIST:
            return action.otherAssets;

        case DELETE_OTHER_ASSET:
            return state.filter(con => con.id !== action.otherAssetId);
        case CREATE_OTHER_ASSET:
            return state.concat(action.otherAsset)
        case EDIT_OTHER_ASSET:
            const index = _.findIndex(state, { id: action.otherAsset.id });
            state.splice(index, 1, action.otherAsset);
            return [...state];
        default:
            return state;
    }
}