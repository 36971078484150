import _ from 'lodash';
import React, { useEffect, useImperativeHandle, useRef, useState, forwardRef } from 'react';
import { connect } from 'react-redux';
import { deleteBeneficiaryFromList, getStoreBeneficiaryList, setBeneficiaryData, createBeneficiary } from '../../../actions/beneficiaryActions';
import { getStoreContactList, setContactData } from '../../../actions/contactActions';
import { getStoreFamily } from '../../../actions/familyActions';
import CollapseSection from '../../../components/CollapsibleSection';
import { SingleActionItem, AlignColumn, CenterAlignRow, ContainerPosition, CustomButtom, HelpSection, List, TableContainer } from '../../../components/CustomElements';
import DropDown from '../../../components/DropDown';
import HeadBar from '../../../components/HeaderBar';
import string from '../../../constant/en.json';
import { getStoreUserId, getStoreUserRole } from '../../../selectors/userSelectors';
import { getContactFullName, getContactType, getRelation } from '../../../utils/commonUtil';
import PickContactTable from '../../components/PickContactTable';
import { createBeneficiary as createBeneficiaryFn, deleteBeneficiary, getBeneficiaryListFromServer } from './beneficiaryService';
import { useInput } from '../../../hooks/useInput';
import HelperModal from '../../helper/HelperModal';
import { useQuaishToasts } from '../../../actions/alertAction';

const Beneficiary = ({ userId, dispatchSetBeneficiaryList, dispatchCreateBeneficiary, dispatchDeleteBeneficiary, beneficiaryList, userRole }) => {
  const [isFormVisible, formVisibility] = useState(true);
  const { success, error, removeAll } = useQuaishToasts()
  const setToggleForm = () => formVisibility(!isFormVisible);
  const receiverFlag= userRole==='GIVER' ? false : true
  const refPickBeneficiary = useRef();
  const addBeneficiary = (body) => {
    createBeneficiaryFn(body).then(res => {
      success('Record Created Successfully')
      formVisibility(true)
      dispatchCreateBeneficiary(res.data)
    }).catch(err => error(err.message))
  }
  /*const validate = (payload) => {
    const ev = []
    let emptyData = []
    payload.length === 0 ? ev.push(string.forms.benificiarySpecific.emptySelectContact) : emptyData.push(null)
    if (ev.length > 0) {
      warn(ev)
      return false
    }
    else return true
  }*/
  const submit = () => {
    removeAll()
    let ids = refPickBeneficiary.current.returnDetails();
    const body = ids.map(id => ({ contacts: { id }, userId, receiverFlag:receiverFlag }))
    //if (validate(body))
      addBeneficiary(body);
  };

  useEffect(() => {
    getBeneficiaryListFromServer(userId, receiverFlag)
      .then(response => {
        dispatchSetBeneficiaryList(response.data)
        // loading(false) 
      })
      .catch(err => {
        error(err);
        // loading(false);
      })
  }, [])
  return (
    <div>
      <div style={{ marginBottom: 20, display: 'block' }}>
        <HelpSection heading="HELP TIPS!">
          <List>{string.forms.benificiarySpecific.helpTip}</List>
        </HelpSection>
        {beneficiaryList.length > 0 ? <div><SavedBeneficiaries data={beneficiaryList} dispatchDeleteBeneficiary={dispatchDeleteBeneficiary} setToggleForm={() => formVisibility()} />
        </div> : null}
        <ContainerPosition>
          <CustomButtom event={setToggleForm} title={string.forms.commonFormFields.addbenificiary} />
        </ContainerPosition>
      </div>
      <CollapseSection isHidden={isFormVisible} mainTitle={string.forms.benificiarySpecific.benificiaryFormTitle} subtitle={string.forms.commonFormFields.mandatoryFields}>
        <PickBeneficiaryTable ref={refPickBeneficiary} />
        <ContainerPosition>
          <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
          <CustomButtom event={setToggleForm} title={string.forms.commonFormFields.cancel} />
        </ContainerPosition>
      </CollapseSection>
    </div>
  );
};

const SavedBeneficiaries = ({ data, dispatchDeleteBeneficiary, setToggleForm }) => {
  const { success, error } = useQuaishToasts()
  const deleteBene = (id) => {
    if (window.confirm("Delete?\n Are you sure you want to delete this record?")) {
      deleteBeneficiary(id)
        .then(response => {
          success('Record Deleted Successfully');
          dispatchDeleteBeneficiary(id);
          if (data.length === 0) setToggleForm(false);
        })
        .catch(err => {
          error(err)
        })
    }

  }
  const row = data.map((e) =>{
    return(
    <TableContainer>
            <CenterAlignRow>
                <AlignColumn label="Name" text={e.contacts !== null ?getContactFullName(e.contacts) : ''} />
                <AlignColumn label="Relationship" text={e.contacts !== null ?getContactType(e.contacts): ''} />
                <AlignColumn label="Type" text={e.contacts !== null ?getRelation(e.contacts) : ''} />
                <AlignColumn label="Action" text={<SingleActionItem onDelete={()=>deleteBene(e.id)} 
                  />} />
            </CenterAlignRow>
            </TableContainer>
  )});
  return (
    <CollapseSection mainTitle={string.forms.benificiarySpecific.savedBeneficiaries} collapsed={false} isCollapsable={true} customStyle={true}>
      <TableContainer>{row}</TableContainer>
    </CollapseSection>
  );
};
const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
  beneficiaryList: getStoreBeneficiaryList(state),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchSetContactList: contact => dispatch(setContactData(contact)),
  dispatchCreateBeneficiary: ben => dispatch(createBeneficiary(ben)),
  dispatchSetBeneficiaryList: beneficiary => dispatch(setBeneficiaryData(beneficiary)),
  dispatchDeleteBeneficiary: beneficiaryId => dispatch(deleteBeneficiaryFromList(beneficiaryId))
})
export default connect(mapStateToProps, mapDispatchToProps)(Beneficiary);

const PickBeneficiary = ({ pickBeneficiaryList }, ref) => {
  let { value: pickBeneficiary, bind: bindPickBeneficiary } = useInput('INDV');
  const refPickContact = useRef()
  useImperativeHandle(ref, () => ({
    returnDetails() { return refPickContact.current.returnDetails() }
  }));
  return <React.Fragment>
    <div className="row"  style={{marginLeft:'2px'}}>
    <HeadBar title={string.forms.benificiarySpecific.existingBeneficary} />
    <HelperModal helperTextId={"beneficiaryDropdown"} />
    </div>
    <div className="row">
      <DropDown placeholder={string.forms.commonFormFields.pleaseSelect}
        options={[{ value: 'Individual', code: 'INDV' }, { value: 'Institution', code: 'INST' }]}
        bind={bindPickBeneficiary}
      />
    </div>
    <PickContactTable data={pickBeneficiaryList.filter(c => _.get(c, ['contactLookupCode']) === pickBeneficiary)} ref={refPickContact} />
  </React.Fragment>
}
const mapStateToPropsBen = state => ({
  pickBeneficiaryList: (getStoreContactList(state).concat(getStoreFamily(state))).filter(c => _.findIndex(_.flattenDeep(getStoreBeneficiaryList(state).map(b => b.contacts)), { id: _.get(c, ['id']) }) === -1),
})
const PickBeneficiaryTable = connect(mapStateToPropsBen, null, null, { forwardRef: true })(forwardRef(PickBeneficiary));