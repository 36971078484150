import initialState from "./initialState";
import { ADD_CONTACT_LIST, DELETE_CONTACT, CREATE_CONTACT, EDIT_CONTACT } from "../actions/contactActions";
import _ from 'lodash';
import { contactToUiLoad } from "../objectMapping/contactMapping";
import { UPDATE_CONTACTS_TO_EXECUTOR, DELETE_EXECUTOR } from "../actions/executorActions";
export default (state = initialState.contacts, action) => {
    switch (action.type) {
        case ADD_CONTACT_LIST:
            return action.contacts.map(c => ({ ...contactToUiLoad(c) }));

        case DELETE_CONTACT:
            return state.filter(con => con.id !== action.contactId);
        case CREATE_CONTACT:
            return state.concat(contactToUiLoad(action.contact))
        case EDIT_CONTACT:
            const index = _.findIndex(state, { id: action.contact.id });
            state.splice(index, 1, contactToUiLoad(action.contact));
            return [...state];
        case UPDATE_CONTACTS_TO_EXECUTOR:
            return state.map(c => {
                if (action.contacts.includes(c.id)) {
                    return { ...c, executorFlag: true }
                } else return c;
            })
        case DELETE_EXECUTOR:
            return state.map(c => {
                if (action.executorId === c.id) {
                    return { ...c, executorFlag: false }
                } else return c;
            })
        default:
            return state;
    }
}