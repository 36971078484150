import React from 'react';
import { Button, Alert } from 'react-bootstrap';
import { DefaultToastContainer } from 'react-toast-notifications';

// Help tips box
export const List = ({ children }) => <li style={{ listStyle: 'disc', fontSize: '14px', fontWeight: 300 }}>{children}</li>;
export const HighlightedText = ({ children }) => <span style={{ color: 'rgb(0, 123, 255)' }}>{children}</span>;
export const HelpSection = ({ heading, children }) => {
  return (
    <div style={{ border: '1px solid #ccc', padding: '20px', marginBottom: 20 }}>
      <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
        <HighlightedText>{heading}</HighlightedText>
      </p>
      {children}
    </div>
  );
};

// Table
export const TableContainer = ({ children }) => (
  <div style={{ borderBottom: '1px solid #ccc' }}>
    <div style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 17, paddingRight: 14 }}>{children}</div>
  </div>
);
export const CenterAlignRow = ({ children }) => <div className="row align-items-center">{children}</div>;
export const AlignColumn = ({ label, text, className }) => (
  <div className={className ? className : "col-xl-3 col-md-6 col-lg-4 col-xs-12"}>
    <div style={{ display: 'inline-block', fontWeight: 600, fontSize: '13px' }}>{label}:</div>
    <p style={{ display: 'inline-block', fontSize: '13px'}}><span>&nbsp;&nbsp;</span>{text}</p>
  </div>
);
// Button
export const CustomButtom = ({ variant, title, event, isCancel, disabled }) => (
  <Button disabled={disabled} onClick={event} variant={variant ? variant : false ? 'outline-primary' : 'primary'} style={{ borderRadius: 5, marginTop: '20px', marginRight: '10px' }}>
    {title}
  </Button>
);

export const ContainerPosition = ({ children, position }) => <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>{children}</div>;
export const ActionItem = ({ onEdit, onDelete, onShare, datashare }) => (
  datashare === 'yes' ? null : datashare === 'shareby' ? 
    <span style={{ color: 'rgb(0, 123, 255)' }}>
        <i className="fa fa-pencil" aria-hidden="true" onClick={onEdit} style={{ cursor: 'pointer', padding: 4 }} />
    </span>
    :
    datashare==='noShare'
    ?
    <span style={{ color: 'rgb(0, 123, 255)' }}>
    <i className="fa fa-trash" aria-hidden="true" onClick={onDelete} style={{ cursor: 'pointer', padding: 4 }} />
    <i className="fa fa-pencil" aria-hidden="true" onClick={onEdit} style={{ cursor: 'pointer', padding: 4 }} />
  </span>
  :
  <span style={{ color: 'rgb(0, 123, 255)' }}>
    <i className="fa fa-trash" aria-hidden="true" onClick={onDelete} style={{ cursor: 'pointer', padding: 4 }} />
    <i className="fa fa-pencil" aria-hidden="true" onClick={onEdit} style={{ cursor: 'pointer', padding: 4 }} />
    <i className="fa fa-share-alt" aria-hidden="true" onClick={onShare} style={{ cursor: 'pointer', padding: 4 }} />
  </span>
);
export const ActionItemUpload = ({ onEdit, onDelete, onUpload, onShare, datashare }) => (
    datashare === 'yes' ? null : datashare === 'shareby' ? 
    <span style={{ color: 'rgb(0, 123, 255)' }}>
        <i className="fa fa-pencil" aria-hidden="true" onClick={onEdit} style={{ cursor: 'pointer', padding: 4 }} />
    </span>
    :
    datashare==='noShare'
    ?
    <span style={{ color: 'rgb(0, 123, 255)' }}>
    <i className="fa fa-trash" aria-hidden="true" onClick={onDelete} style={{ cursor: 'pointer', padding: 4 }} />
    <i className="fa fa-pencil" aria-hidden="true" onClick={onEdit} style={{ cursor: 'pointer', padding: 4 }} />
    <i className="fa fa-upload" aria-hidden="true" onClick={onUpload} style={{ cursor: 'pointer', padding: 4 }} />
    </span>
    :
    <span style={{ color: 'rgb(0, 123, 255)' }}>
    <i className="fa fa-trash" aria-hidden="true" onClick={onDelete} style={{ cursor: 'pointer', padding: 4 }} />
    <i className="fa fa-pencil" aria-hidden="true" onClick={onEdit} style={{ cursor: 'pointer', padding: 4 }} />
    <i className="fa fa-upload" aria-hidden="true" onClick={onUpload} style={{ cursor: 'pointer', padding: 4 }} />
    <i className="fa fa-share-alt" aria-hidden="true" onClick={onShare} style={{ cursor: 'pointer', padding: 4 }} />
    </span>
);
export const SingleActionItem = ({ onDelete }) => (
  <span style={{ color: 'rgb(0, 123, 255)' }}>
    <i className="fa fa-trash" aria-hidden="true" onClick={onDelete} style={{ cursor: 'pointer', padding: 4 }} />
  </span>
);

export const Loader = () => <div className="spinner-border" role="status">
  <span className="sr-only">Loading...</span>
</div>

export const CustomToast = ({ children, ...props }) => (
  <AlertDismissibleExample {...props}>
    {children}
  </AlertDismissibleExample>
);

export const CustomToastContainer = ({ children, ...props }) => <div><DefaultToastContainer {...props}>{children}</DefaultToastContainer></div>;

function AlertDismissibleExample({ children, ...props }) {
  const [show, setShow] = React.useState(true);

  if (show) {
    return (
      <Alert {...props} onClose={() => setShow(false)} dismissible>
        {children}
      </Alert>
    );
  }
  return null;
}