import React,  { useState, useRef }  from 'react';
import { setDigilockerData, getDigilockerList, setDigilockerFiles, getDigilockerFileList } from '../../../actions/digilockerAction'
import { getStoreRefreshToken, getStoreAccessToken, getStoreUserId } from '../../../selectors/userSelectors'
import { connect } from 'react-redux'
import _ from 'lodash';
import SliderBar from './UploadToDigilocker';
import { getUserDocumentById } from './digilockerServices';
import Spinner from 'react-bootstrap/Spinner'
import { Card } from 'react-bootstrap'
import { updateUserDocumentById, getRefreshToken, getDigilockerFiles } from './digilockerServices';
import moment from 'moment';
import { DIGILOCKER_URI } from '../../../constant/ServerConst'
import { Link} from 'react-router-dom'
import ModalContainer from '../../../home/component/layout/Modal'
import Login from '../../../auth/Login'
import {
    ListItemIcon,
    ListItemText,
    ListItem,
    Tooltip,
    Zoom,
  } from '@material-ui/core';
  
const Digilocker = ({userId, dispatchSetDigilockerData, dispatchSetDigilockerFiles, tokenData, uploadType, digilockerFileData, visibleLogin, toggleLogin, userRole, drawerState})  => {

const docsRef = useRef()
const [spinnerData, setData]=useState(false)
const digilockerWindow = () => {
    window.open(DIGILOCKER_URI,"_blank", "height=500,width=500,status=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,top=300,left=500");
}
const digilockerLogin=()=>{
    setData(true)
    getUserDocumentById(userId)
        .then(response => {
            const accessData = response.data
            dispatchSetDigilockerData(accessData)
            if(Object.keys(accessData).length === 0){
                setData(false)
                digilockerWindow()
            }
            else{
                const validTill = new Date(accessData['valid_till']);
                const refreshTokenTimestamp = new Date(accessData['refresh_token_timestamp']);
                const now = new Date()
                if(validTill > now){
                    if(now  > refreshTokenTimestamp){
                        let refreshTok =  accessData['refresh_token']
                        getRefreshToken(refreshTok)
                        .then(res => {
                            const accessData = res.data
                            const format1 = "YYYY-MM-DD HH:mm:ss"
                            const date1 = new Date()
                            const now = moment(date1).format(format1);
                            const date2 = new Date()
                            date2.setHours( date2.getHours() + 1 );
                            const validTill = moment(date2).format(format1);
                            const date3 = new Date()
                            date3.setMinutes(date3.getMinutes() + 50)
                            const accessTokenTimestamp = moment(date3).format(format1);
                            Promise.all([
                                    updateUserDocumentById( userId,  now, accessData.access_token, accessData.refresh_token,  validTill, accessTokenTimestamp),
                                    getDigilockerFiles(res.access_token)
                                    ])
                                    .then(response => response[1].json())
                                    .then(res => {
                                        dispatchSetDigilockerFiles(res.items)
                                    })
                                    .then(res=>{
                                        setData(false)
                                        docsRef.current.submit()
                                    })
                                .catch(err =>{
                                    console.log(err);
                                }) 
                            })
                    }
                    else{
                            getDigilockerFiles(accessData['access_token'])
                                .then(res => res.json())
                                .then(res => {
                                    dispatchSetDigilockerFiles(res.items)
                                })
                                .then(res=>{
                                    setData(false)
                                    docsRef.current.submit()
                                  })
                        .catch(err =>{
                            console.log(err);
                        })
                    }
                }
                else{
                    setData(false)
                    digilockerWindow()
                }
            }
        })
        .catch(err => {
            console.log(err)
        })
}
const GridCard = ({ icon, text, subText, link, userId, visibleLogin, toggleLogin, userRole }) => (
    
    <Card
      border='primary'
      style={{ width: '18rem', margin: 5, cursor: 'pointer' }}
    >
    { isNaN(userId) || _.isEmpty(userRole) ?
    <div>
        <Link
            className='top-nav-link'
            style={{ fontWeight: 500, marginRight: 25, color: '#fff' }}
            onClick={toggleLogin}
        >
        <Card.Body style={{ textAlign: 'center', fontSize: 13 }}>
        {/* <Card.Title>Primary Card Title</Card.Title> */}
        <img
          style={{ width: '5', height: '4em' }}
          src={require(`../../../assets/svg/${icon}.svg`)}
          alt=''
        />
        <Card.Text style={{ marginTop: 5, fontSize: 13 }}>{text}</Card.Text>
        <Card.Text style={{ marginTop: 10, fontSize: 11 }}>{subText}</Card.Text>
        </Card.Body>
        </Link>
        <ModalContainer visible={visibleLogin} toggle={toggleLogin}>
            <div style={{ padding: '40px' }}>
                <Login onClose={toggleLogin} />
            </div>
        </ModalContainer>
      </div>
      :
      <div>
      <span isUpload onClick={digilockerLogin}>
        <Card.Body style={{ textAlign: 'center', fontSize: 13 }}>
        {/* <Card.Title>Primary Card Title</Card.Title> */}
        <img
            style={{ width: '5', height: '4em' }}
            src={require(`../../../assets/svg/${icon}.svg`)}
            alt=''
        />
        <Card.Text style={{ marginTop: 5, fontSize: 13 }}>{text}</Card.Text>
        <Card.Text style={{ marginTop: 10, fontSize: 11 }}>{subText}
            { spinnerData ?
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
            :null}
    </Card.Text>
    </Card.Body>
    </span>
      </div>
    }
    </Card>
)

return(
    <div>
        { uploadType === 'menubar' ?


            <Tooltip
            TransitionComponent={Zoom}
            title="Digilocker"
            placement="right"
            disableHoverListener={drawerState}
            disableFocusListener={true}
            enterDelay={400}
            >
            <ListItem  onClick={digilockerLogin} activeStyle={{ background: '#f2f2f2' }} button>
            <ListItemIcon>
                    <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../../../assets/svg/safety.svg`)}
                        alt=''
                      />
                { spinnerData ?
                <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
              :null}
            </ListItemIcon>
            <ListItemText style={{paddingLeft: 0}} inset primary="Digilocker" />
            </ListItem>
            </Tooltip>

        : uploadType === 'grid' ?
          <GridCard 
          icon='safety' 
          text='Upload to Digilocker' 
          subText='A Digital India initiative'
          userRole={userRole}
          userId={userId} visibleLogin={visibleLogin} toggleLogin={toggleLogin} 
          link = { digilockerLogin }
           />
        : uploadType === 'btn' ?
              <button style={{backgroundColor:'white', fontSize:10}} isUpload onClick={digilockerLogin}>
              <img src="https://services.digitallocker.gov.in/savelocker/api/1/image/locker.png" alt='digilocker'></img>{' '}
              Save to Digilocker
              { spinnerData ? <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
              :null}
            </button>
          : uploadType === 'uploadIcon' ?
            <button style={{backgroundColor:'white', fontSize:10,display:'block'}} isUpload onClick={digilockerLogin}>
            <img alt='digilocker' src="/img/banner/digilocker.png"></img>{' '}
                { spinnerData ? <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                    :null}
               </button>
          :
          null
          }
      <SliderBar ref={docsRef}/>
    </div>
    )
}

const mapStateToProps = (state) => ({
    refreshToken:getStoreRefreshToken(state),
    tokenData:getDigilockerList(state),
    accessTokenData:getStoreAccessToken(state),
    digilockerFileData:getDigilockerFileList(state),
    userId: getStoreUserId(state),
  })
  const mapDispatchToProps = () => (dispatch) => ({
    dispatchSetDigilockerData: (digilockerData) => dispatch(setDigilockerData(digilockerData)),
    dispatchSetDigilockerFiles: (digilockerFiles) => dispatch(setDigilockerFiles(digilockerFiles)),
  })

export default connect(mapStateToProps, mapDispatchToProps)(Digilocker)