import React, { useImperativeHandle, forwardRef } from 'react';
import { useInput } from '../../hooks/useInput';
import HeadBar from '../../components/HeaderBar';
import DropDown from '../../components/DropDown';

const relationOptions = [{
    "lookupKey": "relationship",
    "code": "IN-LAWS",
    "value": "In Laws"
},
{
    "lookupKey": "relationship",
    "code": "SIBLING",
    "value": "Siblings"
},
{
    "lookupKey": "relationship",
    "code": "OTH-FAM",
    "value": "Other Family Members"
},
{
    "lookupKey": "relationship",
    "code": "FRND",
    "value": "Friends"
},
{
    "lookupKey": "relationship",
    "code": "COL",
    "value": "Colleagues"
},
{
    "lookupKey": "relationship",
    "code": "DOC",
    "value": "Doctor"
},
{
    "lookupKey": "relationship",
    "code": "LAW",
    "value": "Lawyer"
},
{
    "lookupKey": "relationship",
    "code": "ACC",
    "value": "Accountant"
},
{
    "lookupKey": "relationship",
    "code": "TAX",
    "value": "Consultants"
},
{
    "lookupKey": "relationship",
    "code": "OTH_REL",
    "value": "Others"
}];
const Relationship = ({ defaultValue }, ref) => {
    const { value: relationship, bind: bindRelation } = useInput(defaultValue);
    useImperativeHandle(ref, () => ({
        returnDetails() { return { relationship: relationship === '' ? null : relationship } }
    }));
    return (
        <section className='section_form'>
            <HeadBar title={'Relationship*'} />
            <div className='row'>
                <DropDown placeholder={'Please Select'} options={relationOptions}
                    bind={bindRelation} />
            </div>
        </section>)
};
const RelationshipForm = forwardRef(Relationship);
export default RelationshipForm;
