import React, { forwardRef, useState, useRef } from 'react';
import CollapseSection from "../../../components/CollapsibleSection";
import HeadBar from "../../../components/HeaderBar";
import Input from "../../../components/Input";
import { useInput } from "../../../hooks/useInput";
import string from '../../../constant/en.json';
import { getStoreUserId, getStoreUserRole } from '../../../selectors/userSelectors';
import { batchState, getId, getValue } from '../../../utils/commonUtil';
import { ContainerPosition, CustomButtom, TableContainer, AlignColumn, CenterAlignRow, ActionItemUpload, HelpSection, HighlightedText, List } from '../../../components/CustomElements';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getStoreContactList, addContact, editContact, deleteContactFromList  } from '../../../actions/contactActions';
import { getStoreFamily } from '../../../actions/familyActions';
import SliderBar from '../../../components/UploadComponent';
import { useQuaishToasts } from '../../../actions/alertAction';
import { createContact, updateContactById, deleteContact } from '../../forms/contacts/ContactService';
import GenderForm from "../../components/Gender";
import TextArea from '../../../components/TextArea'

const Pets = ({userId, pickList, contactList, dispatchAddContact, dispatchEditContact, dispatchDeleteContact, userRole}, ref) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [contact, setContact] = useState();
  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && contact &&  contact.id ) {
        setContact(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setContact(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
        setContact(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const docsRef = React.useRef()
  const onUpload = (c) => {
    docsRef.current.submit('INS', c.id, "Upload Files")
  }
  return (
    <div>
      <HelpSection heading="HELP TIPS!">
        <List>
        Add all your pets details in place to make it easier for you. Make sure you fill all the necessary blanks as best as you can.
        <HighlightedText>(Fields Marked With * Are Mandatory) </HighlightedText>
        </List>
      </HelpSection>
        <SavedInsurance onUpload={onUpload} data={contactList} onEdit={onEdit} isFormHidden={isFormHidden} dispatchDeleteContact={dispatchDeleteContact} />
        <div  style={{ marginBottom: 20, display: 'block' }}>
          <ContainerPosition>
            <CustomButtom event={() => toggleForm()} title={'Add Pets'} />
          </ContainerPosition>
        </div>
        {isFormHidden ? null : <PetsForm contact={contact} userId={userId} onCancel={toggleForm} onDone={onDone} contactList={contactList} dispatchAddContact={dispatchAddContact} dispatchEditContact={dispatchEditContact} pickList={pickList} userRole={userRole} />}
        <SliderBar ref={docsRef} />
    </div>
  );
}

const PetsFrm = ({contact, pickList,userId, onCancel, onDone, dispatchAddContact, dispatchEditContact, userRole}, ref) => {
    const { value: fullName, bind: bind1 } = useInput(!contact ? '' : contact.fullName);
    const { value: age, bind: bind2 } = useInput(!contact ? '' : contact.age);
    const { value: membership, bind: bind3 } = useInput(!contact ? '' : contact.custField2);
    const { value: instructions, bind: bind4 } = useInput(!contact ? '' : contact.custField3);
    const { value: caregiver, bind: bind5 } = useInput(!contact ? '' : contact.custField4);
    const refGenderForm = useRef();
  const { success, warn, error, removeAll } = useQuaishToasts()

  const validate=(payload)=>{
    const ev=[]
    let emptyData = []
    for (var key in payload) {
      switch(key){
          case 'custField3':
            (payload[key]).length > 255 ? ev.push("Special instructions should not exceed 255 characters"):emptyData.push(null)
            break
          default:
            break
          } 
        }
          if(ev.length>0){
            warn(ev)
            return false
          }
          else return true
        }
  const submit = () => {
    removeAll()
    const payload = {
      userId,
      fullName : fullName,
      age : age,
      custField2 : membership,
      custField3 : instructions,
      ...getValue(refGenderForm),
      contactLookupCode:"INDV",
      custField4 : caregiver,
      relationship:'PET'
    }
    console.log(payload)
    if(validate(payload))

    getId(contact) ? updateContactById(contact.id, {id:contact.id, ...payload} )
    .then(response => {
      success('Record Updated Successfully');
        dispatchEditContact(response.data)
        onDone()
    }).catch(err => {
        error(err)
    }) : 
    createContact(payload)
    .then(response => {
      success('Record Created Successfully');
      dispatchAddContact(response.data);
      onDone()
        // dispatchSetAllContactList(response.data);
    })
    .catch(err => {
      error(err)
    })
}
  return <CollapseSection  mainTitle={'Enter Pets'}>
  <section className='section_form'>
    <div>
      <HeadBar title={'Name of the pet'} subtitle={'*'} />
    </div>
    <div className='row'>
      <Input type='text' placeholder={'Pet\'s Name'} isBigger bind={bind1} />
    </div>
    <div>
      <HeadBar title={'Approximate Age'} />
    </div>
    <div className='row'>
      <Input type='number' placeholder={'Age'} isBigger bind={bind2} />
    </div>
    <GenderForm ref={refGenderForm} defaultValue={_.get(contact, 'gender')} />
    
    <div>
      <HeadBar title={'CareGiver Name'} />
    </div>
    <div className='row'>
      <Input type='text' placeholder={'Caregiver'} isBigger bind={bind5} />
    </div>
    
    <div>
      <HeadBar title={'Kennel Club / Pet Club membership (if any)'} />
    </div>
    <div className='row'>
      <Input type='text' placeholder={'Membership'} isBigger bind={bind3} />
    </div>

    <div>
      <HeadBar title={'Special Instructions (should be less than 255 characters) '} />
    </div>
    <div className='row'>
    <div className='col'>
    <TextArea bind={bind4} />
    </div>
    </div>

  </section>
  <ContainerPosition>
          <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
          <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
      </ContainerPosition>
</CollapseSection>
}
const PetsForm = forwardRef(PetsFrm)

export function SavedInsurance ({onUpload, data, dispatchDeleteContact, onEdit, dispalyActions}) {
  const { success, error } = useQuaishToasts()
  const deletePet = (id) =>{
    if(window.confirm("Delete?\n Are you sure you want to delete this record?")){
        deleteContact(id)
        .then(response => {
          success('Record Deleted Successfully');
          dispatchDeleteContact(id);
        })
        .catch(err => {
          error(err)
        })
    }
  }
  let petsData = data.filter(fa=> fa.relationship === 'PET')
  const row = petsData.map((e) => {
    return(
    <TableContainer>
      <CenterAlignRow>
        <AlignColumn label="Pet's Name" text={e.fullName} />
        <AlignColumn label="Age" text={e.age} />
        <AlignColumn label="CareGiver" text={e.custField4} />
        <AlignColumn label="Action" text={<ActionItemUpload onUpload={onUpload} datashare={'noShare'} onEdit={()=>onEdit(e)} onDelete={()=>deletePet(e.id)} />} />
        
        </CenterAlignRow>
    </TableContainer>
  )});
  return (
    <CollapseSection isHidden={data.length === 0} mainTitle={'Pets'} collapsed={false} isCollapsable={true} customStyle={true}>
      {row}
    </CollapseSection>
  );
};

 const mapStateToProps = (state) => ({
    contactList: getStoreContactList(state), 
  userId: getStoreUserId(state),
  pickList: (getStoreContactList(state).concat(getStoreFamily(state))),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
    dispatchAddContact: contact => dispatch(addContact(contact)),
    dispatchEditContact: contact => dispatch(editContact(contact)),
  dispatchDeleteContact:contact=>dispatch(deleteContactFromList(contact)),
})
export default connect(mapStateToProps,mapDispatchToProps)(Pets)