export const ADD_USER_ROLE = 'ADD_USER_ROLE';
export const setUserRoleData = (userRolesData) => {

    return {
        type: ADD_USER_ROLE,
        userRolesData
    }
}

export const getUserRolesList = state => state.userRolesData;
