import Storage from "@aws-amplify/storage";
import _ from 'lodash';
import React, { useMemo, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { Modal, Button } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { usePromiseTracker } from "react-promise-tracker";
import { AWS_CONFIG } from "../../../constant/ServerConst";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import { batchState } from "../../../utils/commonUtil";
import { getStoreUser, getStoreUserId, getStoreAccessToken } from "../../../selectors/userSelectors";
import { connect } from "react-redux";
import { setUserData } from "../../../actions/userActions";
import { getDigilockerFileList, setDigilockerFiles, getDigilockerList, setDigilockerData } from "../../../actions/digilockerAction"
import { getFile, gethmac } from './digilockerServices';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderColor: '#eeeeee',
    backgroundColor: '#cccc',
    color: '#000',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#2196f3'
};

const rejectStyle = {
    borderColor: '#ff1744'
};
Storage.configure(AWS_CONFIG.Storage)
const UploadDrop = ({ handleDrop }) => <Dropzone onDrop={handleDrop}>
    {({ getRootProps,
        getInputProps,
        isDragActive, isFileDialogActive,
        isDragAccept,
        isDragReject, }) => {
        const style = //useMemo(() => ({
            {...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }
        /*), [
            isDragActive,
            isDragReject,
            isDragAccept
        ]);*/
        return <div {...getRootProps({ className: `dropzone`, style })}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop</p> : isFileDialogActive ? <p>Select file</p> : <p>Drag'n'drop files, or click to select files</p>}
        </div>
    }}
</Dropzone>
const UploadComponentTag = ({ docKey, userId, onDone, docs, inProgress, d, tokenData }, ref) => {
    const formatted = docs.map(file => ({ ...file, file: { name: file.docLink.split("/").reverse()[0] }, url: file.docLink, progress: '100' }))
    const [files, setFiles] = useState(formatted);
    const supportedCtype = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']
    const [fileType, setFileType] = useState(true)
    React.useEffect(() => {
        setFiles(formatted)
    }, [docs.toString()])
    const handleDrop = acceptedFiles => {
        batchState(() => {
            const f = acceptedFiles.map(f => ({ file: f, progress: 0, preview: URL.createObjectURL(f) })).concat(files);
            let contentType = acceptedFiles[0].type  
            if(supportedCtype.includes(contentType)){
                setFileType(true)
            let name = acceptedFiles[0].name
            readFileDataAsBase64(acceptedFiles[0])
            .then(res=>{
                let fileData = contentType === 'application/pdf' ? res : acceptedFiles[0]
                gethmac(fileData, tokenData['access_token'], name, contentType)
                .then(res => 
                    res.json())
                .then(res => {
                      console.log(res)
                      f[0].progress = 100
                      setFiles([...f])
                  })
                })
            .catch(e =>
                console.log(e)
                )            
                setFiles(f)
            }
            else{
                setFileType(false)
                alert("Please upload png, jpg, jpeg and pdf file")
            }
        })
    
    }

    function readFileDataAsBase64(e) {
        const file = e;   
        return new Promise((resolve, reject) => {
            const reader = new FileReader();        reader.onload = (event) => {
                resolve(event.target.result);
            };        reader.onerror = (err) => {
                reject(err);
            };        reader.readAsBinaryString(file);
        });
    }

    const { promiseInProgress } = usePromiseTracker();
    React.useEffect(() => {
        inProgress(promiseInProgress)
    }, [promiseInProgress])
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => null);
    /*const deleteFromArr = (index) => {
        // if (files[index].id) {
        //     files[index].active = false
        //     setFiles([...files])
        // } else {
        files.splice(index, 1)
        setFiles([...files])
        // }
        // onDone(files)
    }*/
    const getUploadedFile = (e) =>{
        let idAndName=e.target.id
        let fileSplit = idAndName.split(" &&& ")
        let fileId = fileSplit[0]
        let fileName = fileSplit[1]
        getFile(tokenData['access_token'], fileId)
        .then(res => 
            res.blob()
            )
        .then(res => {
 
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              var blob = new Blob([res])
                var url =  window.URL.createObjectURL(blob)
                a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
            //window.open(url)
          })
  }
    useImperativeHandle(ref, () => ({
        returnDetails() { return files }
    }));
    return (
        <div ref={wrapperRef} className="container" style={{ height: '100%', marginBottom: '20px' }}>
            <UploadDrop handleDrop={handleDrop} />
            {<div>
                <div className="row" style={{ height: '60px', alignItems: 'center', justifyContent: 'space-between', margin: '10px' }}>
                    <div style={{ color: '#000' }}>Files: {Object.keys(d).length}</div></div>

                    {files.map(({ file, progress, error, url, preview, docLink, id, active }, idx) => active || typeof active === 'undefined' ? (
                    <div style={{ height: '50px', justifyContent: 'center', padding: '5px' }}>
                        { fileType ?  <div key={idx} className="row border-bottom border-light">
                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }} className="col-8">
                                <div style={{ fontSize: '13px', color: '#000' }} className="text-truncate">
                                    {file.name}
                                </div>
                            </div>
                            <div style={{
                                fontSize: '15px', color: '#000', display: "flex",
                                alignItems: "center"
                            }} className="text-truncate col-2">
                                 {progress}%
                            </div>
                        </div>
                        : null
                        }
                        <div style={{ fontSize: '11px', color: 'red' }} className="text-truncate">
                            {error ? "X Error" : undefined}
                        </div>
                    </div>
                ) : null)}
                    <div className='container'>
            {d === [] ? <p> No files</p> :
            Object.entries(d)
            .map( ([key, value]) => <p style={{ fontSize: '13px', color: '#000' }}>{value['name']} <i  id={value['uri']+' &&& '+value['name']} onClick={getUploadedFile} style={{ fontSize: '20px', color: '#2196f3', cursor: 'pointer', float:'right' }} class={`fa fa-arrow-circle-o-down`}></i>   </p> )
        }
       
      </div>
            </div>}
        </div>
    );
};
const UploadComponent = forwardRef(UploadComponentTag);

let newReturns = []
const SliderBarTag = ({ docs, userId, digilockerFileData, tokenData }, ref) => {
    docs = _.isEmpty(docs) ? [] : docs

    const [docKey, setKey] = useState("");
    const [docId, setId] = useState("");
    const [open, setOpen] = useState(false);
    const [inProg, setProgress] = useState(false)
    const [title, setTitle] = useState("");
    let dbSpecFiles = docs.filter(f => f.docLookupCode === docKey);
    if (docId) {
        dbSpecFiles = dbSpecFiles.filter(f => f.docNumber === `${docId}`)
    }
    const docsRef = useRef()
    useImperativeHandle(ref, () => ({
        submit(docKey, docId, title) {
            batchState(() => {
                setKey(docKey)
                setTitle(title)
                setId(docId)
                if (!open)
                    setOpen(true)
            })
        }
    }));
    const onDone = () => {
        const files = docsRef.current.returnDetails()
        const docsNew = files.map(f => ({ docLink: f.url, userId, id: f.id, docLookupCode: docKey, docNumber: docId }))
        newReturns = docsNew;
        setOpen(false)

    }
    const { promiseInProgress } = usePromiseTracker()
    return <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            < UploadComponent inProgress={setProgress} ref={docsRef} docs={[]} d={digilockerFileData} tokenData={tokenData} userId={userId} docKey={`${docKey}${docId ? `_${docId}` : ''}`} />
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => setOpen(false)}>
                Cancel
      </Button>
               <Button disabled={inProg || promiseInProgress} onClick={() => onDone()}>
                Save
      </Button> 
        </Modal.Footer>
    </Modal>
}
const mapStateToProps = state => ({
    docs: getStoreUser(state).userDocuments,
    userId: getStoreUserId(state),
    accessTokenData:getStoreAccessToken(state),
    digilockerFileData:getDigilockerFileList(state),
    tokenData:getDigilockerList(state),
})
const mapDispatchToProps = () => dispatch => ({
    dispatchSetUserDetails: user => dispatch(setUserData(user)),
    dispatchSetDigilockerFiles: (digilockerFiles) => dispatch(setDigilockerFiles(digilockerFiles)),
    dispatchSetDigilockerData: (digilockerData) => dispatch(setDigilockerData(digilockerData)),
})
const SliderBar = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(forwardRef(SliderBarTag));

export default SliderBar