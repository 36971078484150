export const ADD_ALL_CONTACT_LIST = 'ADD_ALL_CONTACT_LIST';
export const setAllContactData = (contact) => {

    return {
        type:ADD_ALL_CONTACT_LIST,
        contact
    }
}

export const DELETE_FROM_ALL_CONTACT = 'DELETE_FROM_ALL_CONTACT';
export const deleteContactFromAllList = (contactId) => {

    return {
        type:DELETE_FROM_ALL_CONTACT,
        contactId
    }
}

export const getStoreAllContactList = state => state.allContacts;