import initialState from './initialState'
import { ADD_DATASHARED_WITHME_CONSTANTS } from '../actions/dataSharedWithmeActions'
export default (state = initialState.dataShareWithme, action) => {
  switch (action.type) {
    case ADD_DATASHARED_WITHME_CONSTANTS:
      return { ...state, ...action.dataShareWithme }
    default:
      return state
  }
}
