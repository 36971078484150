import React from 'react';
import FixedDeposits from './forms/FixedDeposit';
import PhysicalShares from './forms/PhysicalShares';
import Demat from './forms/Demat';
import GovtScheme from './forms/GovtScheme';
import BankAccount from './forms/BankAccount';
import Insurance from './forms/Insurance';
import MutualFund from './forms/MutualFund';
import Bond from './forms/Bond';
import PF from './forms/PF';
import Gratuity from './forms/Gratuity';
import ImmovableProperty from './forms/ImmovableProperty';
import Social from './forms/Social';
import Website from './forms/Website';
import Portfolio from './forms/Portfolio';
import Artefacts from './forms/Artefacts';
import Jewellery from './forms/Jewellery';
import Arts from './forms/Art';
import Locker from './forms/Locker';
import Vehicles from './forms/Vehicle';
import PersonalEffect from './forms/PersonalEffect';
import Receivable from './forms/Receivable';
import Credit from './forms/CreditCard';
import Stem from './forms/Stemcell';
import Loyalty from './forms/LoyaltyPrograms';
import Password from './forms/Password';
export const initialAssets = [
    <FixedDeposits />,
    <PhysicalShares />,
    <Demat />,
    <GovtScheme />,
    <BankAccount />,
    <Insurance />,
    <MutualFund />,
    <Bond />,
    <PF />,
    <Gratuity />,
    <ImmovableProperty />,
    <Social />,
    <Website />,
    <Portfolio />,
    <Artefacts />,
    <Jewellery />,
    <Arts/>,
    <Locker />,
    <Vehicles />,
    <PersonalEffect />,
    <Receivable />,
    <Credit />,
    <Loyalty />,
    <Password />,
    <Stem />,
]
