import Auth from "@aws-amplify/auth";
import React, { useRef } from "react";
import { AWS_CONFIG } from "../constant/ServerConst";

Auth.configure({ ...AWS_CONFIG.Auth });

const ForgotPassword = ({ onClose = () => null }) => {
  const refUsername = useRef();

  const onSubmit = (event) => {
    event.preventDefault();
    Auth.forgotPassword(refUsername.current.value.trim().toLowerCase())
      .then(() => {
        console.log("successfully submitted");
        onClose();
        alert(
          "A reset link has been sent to your email. Please click to enter new password."
        );
      })
      .catch((err) => {
        console.log(err);
        onClose();
        alert(
          "Unable to reset password," + err.message + ". Please try again!"
        );
      });
  };
  return (
    <div>
      <div style={{ marginBottom: 20, textAlign: "center" }}>
        <h5 style={{ display: "inline-block" }}>
          Not a problem. Just a few steps.
        </h5>
        <button type="button" className="close" onClick={onClose}>
          &times;
        </button>
      </div>
      <form>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="username"
            placeholder="Username"
            required="required"
            ref={refUsername}
          />
        </div>
        <div className="form-group">
          <button
            onClick={(event) => onSubmit(event)}
            type="submit"
            className="btn btn-primary btn-lg btn-block login-btn"
          >
            Verify
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
