import Axios from 'axios'
import { FLASK_SERVER } from '../../constant/ServerConst'

const basr_uri = FLASK_SERVER
export const mailJustInCaseList = (body) =>
  Axios.post(basr_uri+'getStarted', body)
export const mailQueries = (body) =>
  Axios.post(basr_uri+'helpListAppend', body)
export const mailEstatePlan = (body) =>
 Axios.post(basr_uri+'estatePlanner', body)
export const mailLegalReview = (body) =>
  Axios.post(basr_uri+'legalReview', body)
export const sendInvite = (body) =>
  Axios.post(basr_uri+'sendInvite', body)
export const getHelpList = (id) =>
  Axios.get(basr_uri+'quickList?userId=' + id)
export const sendMail = (body) =>
  Axios.post(basr_uri+'helpListEmail', body)
