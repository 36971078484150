import React, { useImperativeHandle, forwardRef } from 'react';
import { useInput } from '../../hooks/useInput';
import HeadBar from '../../components/HeaderBar';
import DropDown from '../../components/DropDown';
import string from '../../constant/en.json';

const Gender = ({ defaultValue }, ref) => {
    const { value: gender, bind: bindGender } = useInput(defaultValue);
    useImperativeHandle(ref, () => ({
        returnDetails() { return { gender } }
    }));
    return (
        <section className='section_form'>
            <HeadBar title={string.forms.commonFormFields.gender} />
            <div className='row'>
                <DropDown placeholder={string.forms.commonFormFields.pleaseSelect} options={[{ code: 'Male', value: 'Male' }, { code: 'Female', value: 'Female' }, { code: 'Other', value: 'Other' }]} bind={bindGender} />
            </div>
        </section>)
};
const GenderForm = forwardRef(Gender);
export default GenderForm;
