import React from 'react';
import Digilocker from '../dashboard/forms/digilocker/Digilocker'

const DigilockerBtn = ({ isSmaller, noClass }) => {
  return <div className={noClass? null : isSmaller ? 'col-xl-3 col-md-6 col-lg-3' : 'col-xl-4 col-md-6 col-lg-4'}>
    <div className='form-group '>
        <Digilocker uploadType='btn'/>
    </div> 
  </div>
};
export default DigilockerBtn;
