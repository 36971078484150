import React, { useEffect } from 'react';
import { setOrderData, getOrderData } from '../../actions/orderAction';
import { connect } from 'react-redux';
import { getStoreUser } from '../../selectors/userSelectors';
import { getAllOrders } from '../../dashboard/forms/payment/paymentService'

const tableStyle={
  borderBottom: '1px solid #000',
  paddingRight: 12,
  paddingBottom: 10,
  paddingTop: 10,
  backgroundColor:'#f2f2f2',
  color:'#666666',
  fontSize:15,
  textAlign:'center',
}

const tableSty={
 borderBottom: '1px solid #ddd',
 backgroundColor:'#d9d9d9',
 textWeight:'700',
 paddingBottom: 10,
 paddingTop: 10,
 textAlign:'center',
}
 const MyOrders =({personalDetails, dispatchSetOrderData, orderData})=>{
const data = orderData.length > 0 ? orderData.map((e) => {
  return(
    <tr>
    <td width='20%'style={tableStyle}>{e.product_desc}</td>
    <td width='20%'style={tableStyle}>{e.amount}</td>
    <td width='20%'style={tableStyle}>{Date(e.creation_date)}</td>
    <td width='20%'style={tableStyle}>{e.razorpay_order_id}</td>
    {/*<td style={tableStyle}>{e.razorpay_payment_id}</td>*/}
    <td width='20%'style={tableStyle}>{e.razorpay_status}</td>
    </tr>
  )
}): null

    useEffect(() => {
    getAllOrders(personalDetails.id)
    .then(res=>{
      dispatchSetOrderData(res.data)
    })
    }, [])

  return (
    <div className="container" style={{ marginTop: '8vh' }}>
        <div className="section_title">
            <h2>My Orders</h2>
        </div>
        <hr/>
        {orderData.length > 0 ?
        <table style={{ width:'100%', alignContent:'center', marginTop:'12vh'}}>
          <tr >
            <th style={tableSty}>Product</th>
            <th style={tableSty}>Amount</th>
            <th style={tableSty}>Order Date</th>
            <th style={tableSty}>Order Id</th>
            {/*<th style={tableSty}>Payment Id</th>*/}
            <th style={tableSty}>Status</th>
          </tr>
        
       {data}
      
       </table>
       :
       <p style={{marginTop:'2%',textAlign:'center'}}> No payments yet</p>
        }

        <br/> <br/> <br/> <br/>  <br/>  <br/>
    </div>
  );
};
const mapStateToProps = (state) => ({
    orderData: getOrderData(state), 
    personalDetails: getStoreUser(state),
  });

  const mapDispatchToProps = () => dispatch => ({
    dispatchSetOrderData: orders => dispatch(setOrderData(orders))
  })
export default connect(mapStateToProps, mapDispatchToProps)(MyOrders)
