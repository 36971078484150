export default {
  user: {},
  contacts: [],
  family: [],
  beneficiary: [],
  nonDisInherit: [],
  executor: [],
  disInherit: [],
  liability: [],
  finAssets: [],
  otherAssets: [],
  immovableAssets: [],
  digitalAssets: [],
  finAssigns: {
    FD: [],
    SHR: [],
    DEM: [],
    GOV: [],
    BNK: [],
    PMS: [],
    INS: [],
    MF: [],
    BON: [],
    PF: [],
    GRA: [],
  },
  immPropAssigns: [],
  otherAssigns: [],
  digitalAssigns: [],
  allContacts: [],
  speedometerData: {},
  helpList: [],
  payment:[],
  userRolesData:{},
  digilockerData:{},
  digilockerFiles:[],
  orders:[],
  estatePlan:{},
  legalReview:{},
  willData:{},
  dataSharedByme:[],
  dataShareWithme:[],
  dataSharedWithNames:{},
  dataSharedBy:[],
  dataSharedByNames:{},
}
