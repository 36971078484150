import React, { useState } from 'react';
import Input from "../../../components/Input";
import { useInput } from "../../../hooks/useInput";
import { getStoreFinAssetList} from '../../../actions/finAssetsActions';
import { getStoreUserId , getStoreUser } from '../../../selectors/userSelectors'
import { getSplitName } from '../../../utils/commonUtil';
import { Loader } from '../../../components/CustomElements';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button } from 'react-bootstrap'
import Auth from "@aws-amplify/auth";
import { AWS_CONFIG } from '../../../constant/ServerConst';
import { getUserIdByEmail } from '../../../dashboard/forms/personal/personalService'
import { Post } from '../../../utils/ApiUtil';
import { createDataShare, multiCreateDataShare, createInviteEmail, createInviteNewUserEmail } from '../../../dashboard/forms/dataShare/datashareService'
import {HelpSection } from '../../../components/CustomElements';
import { addDataSharedbyme } from '../../../actions/dataSharedByMeActions';

Auth.configure({ ...AWS_CONFIG.Auth })

const DataShareModal = ({user, userId, AssetList, toggleDataShare, dataShareId, assetLookupKey, assetLookupCode, dispatchAddDataShare}, ref) => {
  const { value: nameOfUser, bind: setFirstName } = useInput('')
  const { value: emailId, bind: setEmail } = useInput('')
  const { value: phoneNum, bind: setPhoneNum } = useInput('','mobile')
  const [spinner, setSpinner] = useState(false)
  const [accessLevel, setAccessLevel] = useState('READ')
  const createUser = async () => {
    const re = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/g
    const phoneFormat = ((/^[0-9\b]+$/).test(phoneNum)) || ((/^[+|-][0-9\b]+$/).test(phoneNum))
    let emailIds = emailId.toLowerCase().trim();
    if (
      re.test(String(emailId).toLowerCase()) &&
      phoneFormat
    ) {
      let awsPayload = {
        username: emailIds,
        password: 'Welcome@01',
        attributes: {
          email: emailIds,
          phone_number: ((/^[+|-][0-9\b]+$/).test(phoneNum)) ? phoneNum : '+91' + phoneNum,
          given_name: nameOfUser,
        },
      }
      console.log(awsPayload)
      Auth.signUp(awsPayload)
        .then((response) => {
          console.log('aws signup complete')
          console.log(response)
          Post('userprofile/create?userId=' + response.userSub, {
            userName: response.userSub,
            primaryEmail: emailIds,
            mobilePhone: ((/^[+|-][0-9\b]+$/).test(phoneNum)) ? phoneNum : '+91' + phoneNum,
            ...getSplitName(nameOfUser),
          })
            .then((resp) => {
              console.log('backend user create complete')
              console.log(resp)
              createInviteNewUserEmail(emailIds,user.firstName,nameOfUser)
              .then((res)=>{
                      console.log(res.data)
                  })
              const body = AssetList.map(idss => ({ 
                "assetId": idss.id ,
                "shareToUserId":resp.data.id,
                "accessLevel":accessLevel,
                "sharedByUserId":userId,
                "assetLookupKey":assetLookupKey,
                "assetLookupCode":assetLookupCode
               }))

              let dataSharePayload = dataShareId === 'multicreate' ? body
              :
              {
                    "assetId":dataShareId,
                    "shareToUserId":resp.data.id,
                    "accessLevel":accessLevel,
                    "sharedByUserId":userId,
                    "assetLookupKey":assetLookupKey,
                    "assetLookupCode":assetLookupCode
              }
              dataShareId === 'multicreate'
              ?
              multiCreateDataShare(dataSharePayload)
              .then(res => {
                setSpinner(false)
                dispatchAddDataShare(res.data)
                toggleDataShare()
                alert("Your data has been successfully shared")
                console.log(res.data)
              })
              .catch(err => alert(err))
              :
              createDataShare(dataSharePayload)
              .then(res => {
                dispatchAddDataShare(res.data)
                setSpinner(false)
                toggleDataShare()
                alert("Your data has been successfully shared")
                console.log(res.data)
              })
              .catch(err => alert(err))
        })
            .catch((error) => {
              console.log('backend error')
              console.log(error)
              alert('Unable to register, please try again!')
            })
        })
        .catch((error) => {
          console.log('aws error')
          console.log(error)
          if (error.code === 'UsernameExistsException') {
            getUserIdByEmail(emailIds)
            .then(response=>{

              createInviteEmail(emailIds,nameOfUser,user.firstName)
              .then((res)=>{
                      console.log(res.data)
                  })

              const body = AssetList.map(idss => ({ 
                "assetId": idss.id ,
                "shareToUserId":response.data.id,
                "accessLevel":accessLevel,
                "sharedByUserId":userId,
                "assetLookupKey":assetLookupKey,
                "assetLookupCode":assetLookupCode
               }))

              let dataSharePayload = dataShareId === 'multicreate' ? body
              :
              {
                    "assetId":dataShareId,
                    "shareToUserId":response.data.id,
                    "accessLevel":accessLevel,
                    "sharedByUserId":userId,
                    "assetLookupKey":assetLookupKey,
                    "assetLookupCode":assetLookupCode
              }
              dataShareId === 'multicreate'
              ?
              multiCreateDataShare(dataSharePayload)
              .then(res => {
                setSpinner(false)
                dispatchAddDataShare(res.data)
                toggleDataShare()
                alert("Your data has been successfully shared")
                console.log(res.data)
              })
              .catch(err => alert(err))
              :
              createDataShare(dataSharePayload)
              .then(res => {
                setSpinner(false)
                dispatchAddDataShare(res.data)
                toggleDataShare()
                alert("Your data has been successfully shared")
                console.log(res.data)
              })
              .catch(err => alert(err))
            })
          } else alert(error.message)
        })
    } else {
      if (!phoneFormat)
        alert('please enter valid phone number')
      else
        alert(
          'Please enter a valid email address. You could be missing something in the e-mail format.'
        )
    }
  }

  const validateUser = () =>{

    const payload = {'name' : nameOfUser, 'email': emailId.toLowerCase().trim() , 'phone':phoneNum}
    const ev=[]
    let emptyData= [] 
    for (var key in payload) {
      switch(key){
          case 'name':
            _.isEmpty(payload[key]) ? ev.push("Please Enter Your Name\n"):emptyData.push(null)
            break
          case 'email':
            _.isEmpty(payload[key]) ? ev.push("Please Enter Your Email\n"):emptyData.push(null)
            break
          case 'phone':
             _.isEmpty(payload[key]) ? ev.push("Please Enter Your Phone Number\n"): !(((/^[0-9\b]+$/).test(payload[key])) || ((/^[+|-][0-9\b]+$/).test(payload[key]))) ? ev.push("Enter Valid Phone Number\n") : emptyData.push(null)
            break
          default:
            break
          } 
        }
          if(ev.length>0){
            alert(ev.join(',').replace(/,/g, '').split())
          }
          else{
            setSpinner(true)
            createUser()
          }
  }

  return (
    <div>
        <div style={{ padding: '30px' }}>
        <div>
        <HelpSection heading="HELP TIPS!">
        You can share this data with more than one person. We will inform them via email automatically.<br></br><br></br>
        </HelpSection>
        </div>
        <div>
        <label>
        <b>Name</b>
        </label>
        <Input
            noClass
            type='text'
            className='form-control form-control-lg'
            bind={setFirstName}
            placeholder="E.g. Manik Chand" 
        />
        <label>
        <b>e-mail</b>
        </label>
        <Input
            noClass
            type='text'
            className='form-control form-control-lg'
            bind={setEmail}
            placeholder="E.g. Manik@gmail.com" 
        />
        <label>
          <b>Phone Number</b>
        </label>
        <Input
            noClass
            type='text'
            className='form-control form-control-lg'
            bind={setPhoneNum}
            placeholder="E.g. Enter 10 digit number" 
        />
         <label>
          <b>Access Level &nbsp;&nbsp;&nbsp;</b> 
        </label>
       <input type="radio" value="READ" name="access" onChange={() => setAccessLevel('READ')} /> Read Only&nbsp;&nbsp; 
       <input type="radio" value="EDIT" name="access" onChange={() => setAccessLevel('EDIT')}/> Edit
        <br/><br/>
        <label>
          <b>Event based disclosure ? &nbsp;&nbsp;&nbsp;</b> 
        </label>
        <input type="radio" value="LATER" name="event" onChange={() => setAccessLevel('LATER')} /> Yes&nbsp;&nbsp; 
       <input type="radio" value="NOW" name="event" onChange={() => setAccessLevel('NOW')}/> No
        &nbsp;&nbsp;&nbsp;<a href='https://zfrmz.in/wGRTdkI0Y5q9aryUDSTo'>| Set details |</a>
        <br/><br/>
        <div style={{ textAlign:'center'}}>
        
        <Button
          onClick={toggleDataShare}
          style={{ variant:'outline-primary', size:'sm', fontWeight: 500}}>
          Cancel
        </Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        
        <Button
          style={{ variant:'outline-primary', size:'sm', fontWeight: 500}}
          onClick={validateUser}
        >
          {spinner ? <Loader /> : 'Submit'}
        </Button>
        <br></br><br></br>

        </div>
        </div>
        </div>      
    </div>
  );
}

 const mapStateToProps = (state) => ({
  insuranceFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'INS'),
  userId: getStoreUserId(state),
  user: getStoreUser(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchAddDataShare:dataShareBymeids=>dispatch(addDataSharedbyme(dataShareBymeids)),
})
export default connect(mapStateToProps,mapDispatchToProps)(DataShareModal)