export const ADD_FAMILY = 'ADD_FAMILY';
export const DELETE_FAMILY_CONTACT = 'DELETE_CONTACT';
export const CREATE_FAMILY = 'CREATE_FAMILY';
export const EDIT_FAMILY = 'EDIT_FAMILY';
export const setFamilyData = (family) => {

    return {
        type: ADD_FAMILY,
        family
    }
}
export const UPDATE_FAMILY_TO_EXECUTOR = 'UPDATE_FAMILY_TO_EXECUTOR';
export const updateToExecutor = contacts => {
    const contactArr = contacts.map(c => c.contacts.id)
    return {
        type: UPDATE_FAMILY_TO_EXECUTOR,
        family: contactArr,
    }
}
export const deleteFamilyFromList = (familyId) => {

    return {
        type: DELETE_FAMILY_CONTACT,
        familyId
    }
}

export const createFamily = (person) => ({ type: CREATE_FAMILY, person });
export const editFamily = person => ({ type: EDIT_FAMILY, person });
export const getStoreFamily = state => state.family;