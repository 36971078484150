import React, { useState, useRef } from 'react';
import Input from '../../../components/Input'
import { useInput } from '../../../hooks/useInput'
import DropDown from '../../../components/DropDown'
import TextArea from '../../../components/TextArea'
import { ContainerPosition, CustomButtom, HelpSection, HighlightedText, List } from '../../../components/CustomElements';
import Auth from "@aws-amplify/auth";
import { AWS_CONFIG } from '../../../constant/ServerConst';
import { Post } from '../../../utils/ApiUtil';
import { getSplitName, getContactFullName, getCodeValueMap } from '../../../utils/commonUtil'
import moment from 'moment';
import _ from 'lodash'
import { connect } from 'react-redux';
import ModalContainer from '../../../home/component/layout/Modal';
import { Button, Modal } from 'react-bootstrap';
import CalenderSlots from '../../../dashboard/components/CalenderSlots'
import Login from '../../../auth/Login'
import Assign from '../../../dashboard/forms/assign/Assign'
import Summary from '../../../dashboard/forms/summary/Summary'
import './WillStyles.css'
import jsPDF from 'jspdf'
import $ from 'jquery'
import Payment from '../../../dashboard/forms/payment/Payment'

import { setUserData } from '../../../actions/userActions';
import { getStoreUserId, getStoreUser, getStoreUserRole } from '../../../selectors/userSelectors';

import { setFamilyData, getStoreFamily, createFamily, editFamily } from '../../../actions/familyActions'
import { setContactData, getStoreContactList, addContact, editContact } from '../../../actions/contactActions'

import { deleteExecutorFromList, setExecutorData, updateToExecutor } from '../../../actions/executorActions';
import { deleteBeneficiaryFromList, getStoreBeneficiaryList, setBeneficiaryData, createBeneficiary, updateContactBeneficiary } from '../../../actions/beneficiaryActions';

import { setFinAssetData, getStoreFinAssetList } from '../../../actions/finAssetsActions'
import { setImmovableAssetData, getStoreImmovableAssetList } from '../../../actions/immovableAssetsActions'
import { setDigitalAssetData, getStoreDigitalAssetList } from '../../../actions/digitalAssetsActions'
import { setOtherAssetData, getStoreOtherAssetList } from '../../../actions/otherAssetsActions';

import { getUserIdByEmail, updateUser } from '../personal/personalService'

import { getFamilyListFromServer } from '../../forms/family/familyService'
import { getContactListFromServer, createContact, updateContactById } from '../contacts/ContactService'

import { createBeneficiary as createBeneficiaryFn, getBeneficiaryListFromServer } from '../benificiary/beneficiaryService';

import { getFinAssetListFromServer  } from '../../forms/assets/finAssetService'
import { getDigitalAssetListFromServer } from '../../forms/assets/digitalAssetService'
import { getImmovableAssetListFromServer } from '../../forms/assets/immovableAssetService'
import {  getOtherAssetListFromServer } from '../../forms/assets/otherAssetService';

import { createWill, getWillData, updateWill, deleteWill } from './WillServices'
import { setWillDataList, getWillDataList } from '../../../actions/willAction'

Auth.configure({ ...AWS_CONFIG.Auth })

const Will = ({
    userProfileId, 
    userRole,
    personalDetails, 
    familyList, 
    contactList, 
    familyListExecutor,
    contactListExecutor,
    familyListBeneficiary,
    contactListBeneficiary,
    disinheritList, 
    executorList, 
    alternateExecutorList,
    fatherList,
    motherList,
    spouseList,
    childList,
    pickBeneficiaryList, 
    beneficiaryList, 
    finAssetList, 
    digitalAssetList, 
    immPropImmovableAssetList, 
    otherAssetList, 
    pfFinAssetList, 
    fdFinAssetList, 
    bankFinAssetList, 
    lockerOtherAssetList, 
    willDataList,
    dispatchSetUserDetails, 
    dispatchSetFamilyList, 
    dispatchSetContactList, 
    dispatchSetExecutorList, 
    dispatchCreateFamily,
    dispatchEditFamily,
    dispatchAddContact, 
    dispatchEditContact,
    dispatchSetBeneficiaryList, 
    dispatchAddFinAsset, 
    dispatchSetImmovableAssetList, 
    dispatchSetDigitalAssetList, 
    dispatchSetOtherAssetList, 
    dispatchUpdateExecutorList, 
    dispatchDeleteExecutorList, 
    dispatchCreateBeneficiary, 
    dispatchDeleteBeneficiary,
    dispatchSetWillData,
    createwill}) =>{

    const getFullName = (e) => {
        let name = e.firstName + " " + e.middleName + " " + e.lastName;
       name = name.replace(/null/g, '')
       return name.replace(/ +/g, " ")
    }
    const [ userId, setUserId ] = useState(isNaN(userProfileId) ? '' : userProfileId)
    const { value: place, bind: setPlace } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.w_loc) ? '' : willDataList.w_loc)
    const { value: willDate, bind: setWillDate } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.w_date) || willDataList.w_date === 'Invalid date' ? '' : willDataList.w_date)
    const [gender, setGender] = useState(_.isEmpty(personalDetails.gender) ? 'Male' : personalDetails.gender)
    const { value: nameOfUser, bind: setNameOfUser } = useInput(_.isEmpty(personalDetails.firstName) ? '' : getFullName(personalDetails))
    const { value: email, bind: setEmail } = useInput(_.isEmpty(personalDetails.primaryEmail) ? '' : personalDetails.primaryEmail)
    const { value: phoneNum, bind: setPhoneNum } = useInput(_.isEmpty(personalDetails.mobilePhone) ? '' : personalDetails.mobilePhone, 'mobile')
    const { value: fatherName, bind: setFatherName } = useInput(Object.keys(fatherList).length === 0 ? '' : _.isEmpty(fatherList[0].firstName) ? '' : getFullName(fatherList[0]))
    const { value: maritalStatus, bind: setMaritalStatus } = useInput(_.isEmpty(personalDetails.maritalStatus) ? 'Single' : personalDetails.maritalStatus )
    const maritalStatusOpt=[
        { code: 'Single', value: 'Single' }, 
        { code: 'Married', value: 'Married' }, 
        { code: 'Divorced', value: 'Divorced' }, 
        { code: 'Widow/Widower', value: 'Widow/Widower' }, 
    ]
    const { value: age, bind: setAge } = useInput(_.isEmpty(personalDetails.dob) || personalDetails.dob === 'Invalid date' ? '' : personalDetails.dob )
    const { value: address, bind: setAddress } = useInput(!Object.keys(personalDetails).length ? '' :_.isEmpty(personalDetails.currentAddress.houseName) ? '' : personalDetails.currentAddress.houseName)
    const { value: motherName, bind: setMotherName } = useInput(Object.keys(motherList).length === 0 ? '' : _.isEmpty(motherList[0].firstName) ? '' : getFullName(motherList[0]))
    const { value: religion, bind: setReligion } = useInput(_.isEmpty(personalDetails.religion)? 'Hindu' : personalDetails.religion)
    const [testatorRegister, setTestatorRegister] = useState(!Object.keys(willDataList).length ? 'Yes' : _.isEmpty(willDataList.prev_will_sr) ? 'Yes' : willDataList.prev_will_sr === true ? 'Yes' : 'No')
    const { value: subRegistrarDate, bind: setSubRegistrarDate } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.prev_will_date) ? '' : willDataList.prev_will_date)
    const { value: registrationDetails, bind: setRegistrationDetails } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.reg_details) ? '' : willDataList.reg_details)
    const { value: earlierDateOfRegistration, bind: setEarlierDateOfRegistration } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.prev_will_sr_date) ? '' : willDataList.prev_will_sr_date)
    const { value: childNumber, bind: setchildNumber } = useInput('0')
    const numChildrens=[
         { code: '0', value: '0' },
         { code: '1', value: '1' },
         { code: '2', value: '2' }, 
         { code: '3', value: '3' }, 
         { code: '4', value: '4' }, 
         { code: '5', value: '5' }, 
         { code: '6', value: '6' },
         { code: '7', value: '7' }, 
         { code: '8', value: '8' }, 
         { code: '9', value: '9' }, 
         { code: '10', value: '10'}
        ]
    const { value: childrens, bind: setNumChildrens } =  useInput(childNumber === '0' ? _.isEmpty(childList) ? '0' :  Object.keys(childList).length : childNumber)
    const [survivingFather, setSurvivingFather] = useState('Yes')
    const [survivingMother, setSurvivingMother] = useState('Yes')
   
    const [child, setChild] = useState('0')
    const { value: childName, bind: setChildName } = useInput(_.isEmpty(childList) ? '' : _.isEmpty(childList[child]) ? '' : _.isEmpty(getFullName(childList[child])) ? '' : getFullName(childList[child]) )
    const { value: childAddress, bind: setChildAddress } = useInput(_.isEmpty(childList) ? '' :  _.isEmpty(childList[child]) ? '' : _.isEmpty(childList[child].homeAddress.houseName) ? '' : childList[child].homeAddress.houseName )
    const { value: childDob, bind: setChildDob } = useInput(_.isEmpty(childList) ? '' :  _.isEmpty(childList[child]) ? '' : _.isEmpty(childList[child].dob) || childList[child].dob === 'Invalid date' ? '' : childList[child].dob )

    const [currentChildName, setCurrentChildName] = useState(_.isEmpty(childList) ? '' : _.isEmpty(childList[child]) ? '' :  _.isEmpty(getFullName(childList[child])) ? '' : getFullName(childList[child]))
    const [currentChildDob, setCurrentChildDob] = useState(_.isEmpty(childList) ? '' :  _.isEmpty(childList[child]) ? '' : _.isEmpty(childList[child].homeAddress.houseName) ? '' : childList[child].homeAddress.houseName)
    const [currentChildAddress, setCurrentChildAddress] = useState(_.isEmpty(childList) ? '' :  _.isEmpty(childList[child]) ? '' :  _.isEmpty(childList[child].dob) || childList[child].dob === 'Invalid date' ? '' : childList[child].dob)
    
    const { value: childNum, bind: setChildNum } = useInput(
           child === 0 ? 'first'
        :  child === 1 ? 'second'
        :  child === 2 ? 'third'
        :  child === 3 ? 'fourth'
        :  child === 4 ? 'fifth'
        :  child === 5 ? 'sixth'
        :  child === 6 ? 'seventh'
        :  child === 7 ? 'eight'
        :  child === 8 ? 'ninth'
        :  child === 9 ? 'tenth'
        : 'first'
    )
    const { value: spouseName, bind: setSpouseName } = useInput(Object.keys(spouseList).length===0 ? '' : _.isEmpty(spouseList[0].firstName) ? '' : getFullName(spouseList[0]))
    const [executor, setExecutor] = useState('Yes')
    const { value: executorType, bind: setExecutorType } = useInput('Family')
    const executorTypeOpt = [
        {code: 'Family',value: 'Family'},
        {code: 'Contact',value: 'Contact'},
        {code: 'Other',value: 'Other'}
    ]
    const relationshipMapping = {
        'FATHER' : 'Father',
        'MOTHER' : 'Mother',
        'SPOUSE' : 'Spouse',
        'CHILD' : 'Child',
        "IN-LAWS" : "In Laws",
        "SIBLING" : "Siblings",
        "OTH-FAM" : "Other Family Members",
        "FRND" : "Friends",
        "COL" : "Colleagues",
        "DOC" : "Doctor",
        "LAW" : "Lawyer",
        "ACC" : "Accountant",
        "TAX" : "Consultants"
    }
    const { value: executorName, bind: setExecutorName } = useInput(executorList.length===0 ? '' : _.isEmpty(executorList[0].firstName) ? '' : getFullName(executorList[0]))
    const { value: executorRelationship, bind: setExecutorRelationship } = useInput(executorList.length===0 ? 'In Laws' : _.isEmpty(executorList[0].relationship) ? 'In Laws' : 
        (executorList[0].relationship === "IN-LAWS" ? "In Laws" :
        executorList[0].relationship ===  "SIBLING" ? "Siblings" :
        executorList[0].relationship ===  "OTH-FAM" ? "Other Family Members"   :
        executorList[0].relationship === "FRND" ? "Friends"  :
        executorList[0].relationship ===  "COL" ? "Colleagues" :
        executorList[0].relationship === "DOC" ? "Doctor" :
        executorList[0].relationship ===  "LAW" ? "Lawyer" :
        executorList[0].relationship === "ACC" ? "Accountant"  :
        executorList[0].relationship === "TAX" ? "Consultants"  : "In Laws")
        )
    const relationOptions = [
        {"code": "In Laws","value": "In Laws"},
        {"code": "Siblings","value": "Siblings"},
        {"code": "Other Family Members","value": "Other Family Members"},
        {"code": "Other Family Members","value": "Other Family Members"},
        {"code": "Colleagues","value": "Colleagues"},
        {"code": "Doctor","value": "Doctor"},
        {"code": "Lawyer","value": "Lawyer"},
        {"code": "Accountant","value": "Accountant"},
        {"code": "Consultants","value": "Consultants"}
    ];
    const [selectedIds, bindSelected] = useState([]);
    const famExe = familyListExecutor.filter(fa=> fa.id === selectedIds[0])
    const famExecutor = famExe.lenght===0 ? '' :famExe[0]
    const contExe = contactListExecutor.filter(fa=> fa.id === selectedIds[0])
    const conExecutor = contExe.lenght===0 ? '' :contExe[0]

    const { value: alternateExecutorType, bind: setAlternateExecutorType } = useInput('Family')
    const [alternateSelectedIds, bindAlternateSelectedIds] = useState([]);
    const famAltExe = familyListExecutor.filter(fa=> fa.id === alternateSelectedIds[0])
    const famAltExecutor = famAltExe.lenght===0 ? '' :famAltExe[0]
    const contAltExe = contactListExecutor.filter(fa=> fa.id === alternateSelectedIds[0])
    const conAltExecutor = contAltExe.lenght===0 ? '' :contAltExe[0]

    const [disinheritSelectedIds, bindDisinheritSelected] = useState([]);
    const famDis = familyListExecutor.filter(fa=> fa.id === disinheritSelectedIds[0])
    const famDisinherit = famDis.lenght===0 ? '' :famDis[0]
    const contDis = contactListExecutor.filter(fa=> fa.id === disinheritSelectedIds[0])
    const conDisinherit = contDis.lenght===0 ? '' :contDis[0]

    const { value: executorAddress, bind: setExecutorAddress } = useInput(executorList.length===0 ? '' : _.isEmpty(executorList[0].homeAddress.houseName) ? '' : executorList[0].homeAddress.houseName)
    const [alternateExecutor, setAlternateExecutor] = useState('No')
    const { value: alternateExecutorName, bind: setAlternateExecutorName } = useInput(alternateExecutorList.length===0 ? '' : _.isEmpty(alternateExecutorList[0].firstName) ? '' : getFullName(alternateExecutorList[0]))
    const { value: alternateExecutorRelationship, bind: setAlternateExecutorRelationship } = useInput(alternateExecutorList.length===0 ? 'In Laws' : _.isEmpty(alternateExecutorList[0].relationship) ? 'In Laws' : 
    (alternateExecutorList[0].relationship === "IN-LAWS" ? "In Laws" :
    alternateExecutorList[0].relationship ===  "SIBLING" ? "Siblings" :
    alternateExecutorList[0].relationship ===  "OTH-FAM" ? "Other Family Members"   :
    alternateExecutorList[0].relationship === "FRND" ? "Friends"  :
    alternateExecutorList[0].relationship ===  "COL" ? "Colleagues" :
    alternateExecutorList[0].relationship === "DOC" ? "Doctor" :
    alternateExecutorList[0].relationship ===  "LAW" ? "Lawyer" :
    alternateExecutorList[0].relationship === "ACC" ? "Accountant"  :
    alternateExecutorList[0].relationship === "TAX" ? "Consultants"  : "In Laws")
    )
    const { value: alternateExecutorAddress, bind: setAlternateExecutorAddress } = useInput(alternateExecutorList.length===0 ? '' : _.isEmpty(alternateExecutorList[0].homeAddress.houseName) ? '' : alternateExecutorList[0].homeAddress.houseName)
    const [executorCompensation, setExecutorCompensation] = useState('Yes')
    const { value: executorCompensionDetails, bind: setExecutorCompensionDetails } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.exec_comp) ? '' : willDataList.exec_comp.replace(/&#92n/gm,'\n'));
    const [listAssets, setListAssets] = useState('Enter All Assets')    
    const { value: assetDetails, bind: setAssetDetails } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.w_assets) ? '' : willDataList.w_assets.replace(/&#92n/gm,'\n'));
    const { value: assetDistribution, bind: setAssetDistribution } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.w_asset_dist) ? 'All to my spouse' : willDataList.w_asset_dist)
    const [assetDistributionType, setAssetDistributionType] = useState('Asset Distribution')
    const assetDistributionOpt = [
        { code: 'All to my spouse', value: 'All to my spouse'},
        { code: 'All to my living child or children', value: 'All to my living child or children'},
        { code: 'All to one named beneficiary', value: 'All to one named beneficiary'},
        { code: 'Divided between multiple named beneficiaries in equal shares', value: 'Divided between multiple named beneficiaries in equal shares'},
        { code: 'Divided between multiple named beneficiaries in unequal shares', value: 'Divided between multiple named beneficiaries in unequal shares'},
        { code: 'In an alternate method of distribution', value: 'In an alternate method of distribution'}
    ]
    const { value: alternateAssetDistribution, bind: setAlternateAssetDistribution } = useInput('The children of the testator')
    const { value: alternateBenificiary, bind: setAlternateBenificiary } = useInput('')
    const { value: multipleAlternateBenificiary, bind: setMultipleAlternateBenificiary } = useInput('')
    const { value: multipleAlternateBenificiaryUnequal, bind: setMultipleAlternateBenificiaryUnequal } = useInput('')
    const { value: multipleAlternateBenificiaryUnequalShare, bind: setMultipleAlternateBenificiaryUnequalShare } = useInput('')
    const { value: childAssetDistribution, bind: setChildAssetDistribution } = useInput('An alternate named beneficiary')
    const childAssetDistributionOpt = [
        { code: 'An alternate named beneficiary', value: 'An alternate named beneficiary'},
        { code: 'Multiple named beneficiaries in equal shares', value: 'Multiple named beneficiaries in equal shares'},
        { code: 'Multiple named beneficiaries in unequal shares', value: 'Multiple named beneficiaries in unequal shares'},
    ]
    const alternateAssetDistributionOpt = [
        { code: 'The children of the testator', value: 'The children of the testator'},
        { code: 'An alternate named beneficiary', value: 'An alternate named beneficiary'},
        { code: 'Multiple named beneficiaries in equal shares', value: 'Multiple named beneficiaries in equal shares'},
        { code: 'Multiple named beneficiaries in unequal shares', value: 'Multiple named beneficiaries in unequal shares'},
    ]
    const { value: benificiaryForAssets, bind: setBenificiaryForAssets } = useInput('')
    const { value: benificiaryForAssetsOpt, bind: setBenificiaryForAssetsOpt } = useInput('')
    const benificiaryOpt =[
        { code: 'The children of the testator', value: 'The children of the testator'},
        { code: 'An alternate named beneficiary', value: 'An alternate named beneficiary'},
        { code: 'Multiple named beneficiaries in equal shares', value: 'Multiple named beneficiaries in equal shares'},
        { code: 'Multiple named beneficiaries in unequal shares', value: 'Multiple named beneficiaries in unequal shares'},
    ]
    const { value: divideMultipleAlternateBenificiaryequalShare, bind: setDivideMultipleAlternateBenificiaryequalShare } = useInput('')
    const { value: divideMultipleAlternateBenificiaryUnequal, bind: setDivideMultipleAlternateBenificiaryUnequal } = useInput('')
    const { value: divideMultipleAlternateBenificiaryUnequalShare, bind: setDivideMultipleAlternateBenificiaryUnequalShare } = useInput('')
    const { value: descriptionWillInherit, bind: setDescriptionWillInherit } = useInput('')
    const [exculdePerson, setExculdePerson] = useState('No')
    
    const { value: exculdePersonName, bind: setExculdePersonName } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.exculdePersonName) ? '' : willDataList.exculdePersonName)
    const { value: excludePersonRelationship, bind: setExcludePersonRelationship  } = useInput(disinheritList.length===0 ? 'In Laws' : _.isEmpty(disinheritList[0].relationship) ? 'In Laws' : 
    (
    disinheritList[0].relationship === "IN-LAWS" ? "In Laws" :
    disinheritList[0].relationship ===  "SIBLING" ? "Siblings" :
    disinheritList[0].relationship ===  "OTH-FAM" ? "Other Family Members"   :
    disinheritList[0].relationship === "FRND" ? "Friends"  :
    disinheritList[0].relationship ===  "COL" ? "Colleagues" :
    disinheritList[0].relationship === "DOC" ? "Doctor" :
    disinheritList[0].relationship ===  "LAW" ? "Lawyer" :
    disinheritList[0].relationship === "ACC" ? "Accountant"  :
    disinheritList[0].relationship === "TAX" ? "Consultants"  : "In Laws")
    )

    const { value: excludePersonType, bind: setExcludePersonType } = useInput('Family')
    const [appointTrustee, setAppointTrustee] = useState('some other person')
    const [benPredeceases, setBenPredeceases] = useState('Yes')
    const { value: trusteeName, bind: setTrusteeName } = useInput('')
    const [bankAccount, setBankAccount] = useState(bankFinAssetList.lenght === 0 ? 'No' : 'Yes')
    const [fixedDeposits, setFixedDeposits] = useState(fdFinAssetList.lenght === 0 ? 'No' : 'Yes')
    const [bankLocker, setBankLocker] = useState(lockerOtherAssetList.lenght === 0 ? 'No' : 'Yes')
    const [pf, setPf] = useState(pfFinAssetList.lenght === 0 ? 'No' : 'Yes')
    //const [listOtherAssets, setListOtherAssets] = useState('Enter All Assets')
    const { value: testatorGifts, bind: setTestatorGifts } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.non_fin_assets) ? '' : willDataList.non_fin_assets.replace(/&#92n/gm,'\n'))
    const { value: personManagingEstate, bind: setPersonManagingEstate } = useInput('')
    const [specialInstructions, setSpecialInstructions] = useState('Yes')
    const { value: specialInstructionDetails, bind: setSpecialInstructionDetails } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.spl_ins) ? '' : willDataList.spl_ins.replace(/&#92n/gm,'\n'))
    const { value: testatorAlternate, bind: setTestatorAlternate } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.wpcls) ? '' : willDataList.wpcls)
    const { value: testatorGuardian, bind: setTestatorGuardian } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.minor_guardian) ? '' : willDataList.minor_guardian)
    const { value: testatorGuardianResidence, bind: setTestatorGuardianResidence } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.minor_loc) ? '' : willDataList.minor_loc)
    const { value: minorTrusteeName, bind: setMinorTrusteeName } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.trusteeName) ? '' : willDataList.trusteeName)
    const [appointTrusteeForMinor, setAppointTrusteeForMinor] = useState('some other person')
    const [minorBeneficiaries, setMinorBeneficiaries] = useState(!Object.keys(willDataList).length ? 'No' : _.isEmpty(willDataList.minor_trustee) ? 'No' : willDataList.minor_trustee === true ? 'Yes' : 'No')
    const [testatorDeath, setTestatorDeath] = useState('')
    const { value: testatorDeathOption, bind: setTestatorDeathOption } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.final_opt) ? '' : willDataList.final_opt)
    const deathOpt = [
        { code: 'Cremated', value: 'Cremated'},
        { code: 'Buried', value: 'Buried'},
        { code: 'Other', value: 'Other'},
    ]
    const { value: instructions, bind: setInstructions } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.final_ins) ? '' : willDataList.final_ins.replace(/&#92n/gm,'\n')) 
    
    const [ question, setQuestion] =useState(52)
    const [divname, setDivName] = useState('div0')
    const  ref1 =useRef(null)
    const  ref2 =useRef(null)
    const  ref3 =useRef(null)
    const  ref4 =useRef(null)
    const  ref5 =useRef(null)
    const  ref6 =useRef(null)
    const  ref7 =useRef(null)
    const  ref8 =useRef(null)
    const  ref9 =useRef(null)
    const  ref10 =useRef(null)

    const [receiverFlag, setReceiverFlag] = useState(false)

    const propertyType={"RES":"Residential",
              "COMM": "Commercial",
              "LAND": "Land"
            }

    let [visibleMuslimReligion, setVisibleMuslimReligion] = useState(false)
    let toggleMuslimReligion = ()=>setVisibleMuslimReligion(!visibleMuslimReligion)
    let [visibleChristianReligion, setVisibleChristianReligion] = useState(false)
    let toggleChristianReligion = ()=>setVisibleChristianReligion(!visibleChristianReligion)
    let [visibleParsiReligion, setVisibleParsiReligion] = useState(false)
    let toggleParsiReligion = ()=>setVisibleParsiReligion(!visibleParsiReligion)

    let [calenderslot, setCalenderSlot] = useState(false)
    let toggleCalenderSlot= ()=>setCalenderSlot(!calenderslot)
    const [mailcontent, setMailContent] = useState('')
    const [mailpayload, setMailPayload] = useState('')

    let [visibleLogin, setVisibleLogin] = useState(false)
    let toggleLogin = () => setVisibleLogin(!visibleLogin)

    let [visibleAssetDescriptionToo1, setVisibleAssetDescriptionToo1] = useState(false)
    let toggleAssetDescriptionToo1 = () => setVisibleAssetDescriptionToo1(!visibleAssetDescriptionToo1)
    
    let [visiblePayment, setVisiblePayment] = useState(false)
    let togglePayment = () => setVisiblePayment(!visiblePayment)

    let [openWill, setOpenWill] = useState(false)
    let toggleOpenWill = () => setOpenWill(!openWill)

      /*const paymentPage = () =>{
          togglePayment()
      }*/
      const appointment = () =>{
        var doc = new jsPDF();
        var elementHTML = $('#HTMLtoPDF').html();
        var specialElementHandlers = {
            '#elementH': function (element, renderer) {
                return true;
            }
        };
        doc.fromHTML(elementHTML, 15, 15, {
            'width': 170,
            'elementHandlers': specialElementHandlers
        });
      
        
        // Save the PDF
        var base64pdf = doc.output();
        let payload={
            "email" : personalDetails.primaryEmail
           }
       
        setMailContent(base64pdf)
        setMailPayload(payload)
        toggleCalenderSlot()
      }
      const backToFirst = () =>{
        setQuestion(0)
        setDivName('div0')
      }

    const resetWillData = () =>{
        deleteWill(userProfileId)
        .then(res =>{
            dispatchSetWillData(res.data)
            setQuestion(0)
            setDivName('div0')
        })
        .catch(e => {
            console.log(e.message)
        })
    }
    const scroll = (e) =>{
       e.current.scrollIntoView({
            behavior: 'smooth',
            block: "start",
          });
          window.scroll(0,0)
          
    }

    const callWillApi = () =>{
        updateUser(userProfileId, {gender : gender, mobilePhone: phoneNum, ...getSplitName(nameOfUser), middleName:''})
        .then(res => {
        dispatchSetUserDetails(res.data)
        getWillData(userProfileId)
        .then(res =>(
            dispatchSetWillData(res.data),
            setQuestion(question + 1),
            setDivName(`div${question + 1}`)
        ))
        .catch(e => {
            console.log(e.message)
        })
        }).catch(e => {
        console.log(e.message)
    })
    }
    const callApis = (userId) =>{

        updateUser(userId, {primaryEmail : email.toLowerCase().trim(), gender : gender, mobilePhone: phoneNum, ...getSplitName(nameOfUser), middleName:''})
        .then(res => {
            console.log("Updated Successfully")
            dispatchSetUserDetails(res.data)
            Promise.all([
                getFamilyListFromServer(userId),
                getContactListFromServer(userId),
            
                getBeneficiaryListFromServer(userId, receiverFlag),

                getFinAssetListFromServer(userId, receiverFlag),
                getImmovableAssetListFromServer(userId, receiverFlag),
                getDigitalAssetListFromServer(userId, receiverFlag),
                getOtherAssetListFromServer(userId, receiverFlag),
                getWillData(userId),
            ])
                .then((response) => {
                dispatchSetFamilyList(response[0].data)
                dispatchSetContactList(response[1].data)
            
                dispatchSetBeneficiaryList(response[2].data)

                dispatchAddFinAsset(response[3].data)
                dispatchSetImmovableAssetList(response[4].data)
                dispatchSetDigitalAssetList(response[5].data)
                dispatchSetOtherAssetList(response[6].data)
                dispatchSetWillData(response[7].data)
                   
                })
                .catch(
                    (ex) => console.error(ex)
                )

        }).catch(e => {
            console.log(e.message)
        })
    }

    const createWillApi = () =>{
        const format = "YYYY-MM-DD HH:mm:ss"
        const date = new Date()
        const now = moment(date).format(format);
        const payload = {
            user_id : userId,
            w_loc : place,        
            w_date : willDate,
            last_modification_date:now,
            exec_comp : executorCompensionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_assets : assetDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_asset_dist : assetDistribution,
            spl_ins : specialInstructionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            wpcls : testatorAlternate,
            trst_name : trusteeName,
            prev_will_sr: testatorRegister === 'Yes' ? true : false,
            prev_will_date: subRegistrarDate,
            reg_details: registrationDetails,
            prev_will_sr_date: earlierDateOfRegistration,
            bank_acc: bankAccount === 'Yes' ? true : false,
            bank_loc: bankLocker === 'Yes' ? true : false,
            pf: pf === 'Yes' ? true : false,
            fixed_deposits: fixedDeposits === 'Yes' ? true : false,
            final_opt: testatorDeathOption,
            final_ins: instructions.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            non_fin_assets:testatorGifts.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            minor_trustee : minorBeneficiaries,
            minor_guardian : testatorGuardian,
            minor_loc : testatorGuardianResidence,
            exclude_person : exculdePersonName
        }
        createWill(payload)
        .then((res)=>{
                dispatchSetWillData(res.data)
                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
                console.log(res.data)
            })
    }
    const updateWillApi = () =>{
        const format = "YYYY-MM-DD HH:mm:ss"
        const date = new Date()
        const now = moment(date).format(format);
        const payload = {
            user_id : userId,
            w_loc : place,        
            w_date : willDate,
            last_modification_date:now,
            exec_comp : executorCompensionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_assets : assetDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_asset_dist : assetDistribution,
            spl_ins : specialInstructionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            wpcls : testatorAlternate,
            trst_name : trusteeName,
            prev_will_sr: testatorRegister === 'Yes' ? true : false,
            prev_will_date: subRegistrarDate,
            reg_details: registrationDetails,
            prev_will_sr_date: earlierDateOfRegistration,
            bank_acc: bankAccount === 'Yes' ? true : false,
            bank_loc: bankLocker === 'Yes' ? true : false,
            pf: pf === 'Yes' ? true : false,
            fixed_deposits: fixedDeposits === 'Yes' ? true : false,
            final_opt: testatorDeathOption,
            final_ins: instructions.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            non_fin_assets:testatorGifts.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            minor_trustee : minorBeneficiaries,
            minor_guardian : testatorGuardian,
            minor_loc : testatorGuardianResidence,
            exclude_person : exculdePersonName
        }
        updateWill(payload)
        .then((res)=>{
                dispatchSetWillData(res.data)
                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
                console.log(res.data)
        })
    }
    const updateWillApi2 = (num) =>{
        const format = "YYYY-MM-DD HH:mm:ss"
        const date = new Date()
        const now = moment(date).format(format);    
        const payload = {
            user_id : userId,
            w_loc : place,        
            w_date : willDate,
            last_modification_date:now,
            exec_comp : executorCompensionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_assets : assetDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_asset_dist : assetDistribution,
            spl_ins : specialInstructionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            wpcls : testatorAlternate,
            trst_name : trusteeName,
            prev_will_sr: testatorRegister === 'Yes' ? true : false,
            prev_will_date: subRegistrarDate,
            reg_details: registrationDetails,
            prev_will_sr_date: earlierDateOfRegistration,
            bank_acc: bankAccount === 'Yes' ? true : false,
            bank_loc: bankLocker === 'Yes' ? true : false,
            pf: pf === 'Yes' ? true : false,
            fixed_deposits: fixedDeposits === 'Yes' ? true : false,
            final_opt: testatorDeathOption,
            final_ins: instructions.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            non_fin_assets:testatorGifts.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            minor_trustee : minorBeneficiaries,
            minor_guardian : testatorGuardian,
            minor_loc : testatorGuardianResidence,
            exclude_person : exculdePersonName
        }
        updateWill(payload)
        .then((res)=>{
                dispatchSetWillData(res.data);
                setQuestion(question + num);
                setDivName(`div${question + num}`);
                console.log(res.data)
        })
        .catch(err=>
            (console.log(err))
        )
    }

    const updateWillApi3 = (num) =>{
        const format = "YYYY-MM-DD HH:mm:ss"
        const date = new Date()
        const now = moment(date).format(format);    
        const payload = {
            user_id : userId,
            w_loc : place,        
            w_date : willDate,
            last_modification_date:now,
            exec_comp : executorCompensionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_assets : assetDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_asset_dist : assetDistribution,
            spl_ins : specialInstructionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            wpcls : testatorAlternate,
            trst_name : trusteeName,
            prev_will_sr: testatorRegister === 'Yes' ? true : false,
            prev_will_date: subRegistrarDate,
            reg_details: registrationDetails,
            prev_will_sr_date: earlierDateOfRegistration,
            bank_acc: bankAccount === 'Yes' ? true : false,
            bank_loc: bankLocker === 'Yes' ? true : false,
            pf: pf === 'Yes' ? true : false,
            fixed_deposits: fixedDeposits === 'Yes' ? true : false,
            final_opt: testatorDeathOption,
            final_ins: instructions.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            non_fin_assets:testatorGifts.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            minor_trustee : minorBeneficiaries,
            minor_guardian : testatorGuardian,
            minor_loc : testatorGuardianResidence,
            exclude_person : exculdePersonName
        }
        updateWill(payload)
        .then((res)=>{
                dispatchSetWillData(res.data);
                setQuestion(num);
                setDivName(`div${num}`);
                console.log(res.data)
        })
        .catch(err=>
            (console.log(err))
        )
    }

    const createUser = async () => {
        const re = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/g
        const phoneFormat = ((/^[0-9\b]+$/).test(phoneNum))  || ((/^[+|-][0-9\b]+$/).test(phoneNum))
        let emailIDs = email.toLowerCase().trim();
        if (
          re.test(String(email).toLowerCase()) &&
          phoneFormat
        ) {
          let awsPayload = {
            username: emailIDs,
            password: 'Welcome@01',
            attributes: {
              email: emailIDs,
              phone_number: ((/^[+|-][0-9\b]+$/).test(phoneNum)) ? phoneNum : '+91' + phoneNum,
              given_name: nameOfUser,
            },
          }
          console.log(awsPayload)
          Auth.signUp(awsPayload)
            .then((response) => {
              console.log('aws signup complete')
              console.log(response)
              Post('userprofile/create?userId=' + response.userSub, {
                userName: response.userSub,
                primaryEmail: emailIDs,
                mobilePhone: ((/^[+|-][0-9\b]+$/).test(phoneNum)) ? phoneNum : '+91' + phoneNum,
                gender:gender,
                ...getSplitName(nameOfUser),
              })
                .then((resp) => {
                  console.log('backend user create complete',resp)
                  updateUser(resp.data.id, {primaryEmail : email.toLowerCase().trim(), gender : gender, mobilePhone: ((/^[+|-][0-9\b]+$/).test(phoneNum)) ? phoneNum : '+91' + phoneNum, ...getSplitName(nameOfUser), middleName:''})
                  .then(res => {
                      dispatchSetUserDetails(res.data)
                  })
                  .catch(err=>console.log(err))
                  setUserId(resp.data.id)
                  setQuestion(question + 1)
                  setDivName(`div${question + 1}`)
            })
                .catch((error) => {
                  console.log('backend error')
                  console.log(error)
                  alert('Unable to register, please try again!')
                })
            })
            .catch((error) => {
              console.log('aws error')
              console.log(error)
              if (error.code === 'UsernameExistsException') {
                getUserIdByEmail(emailIDs)
                .then(response=>{
                setUserId(response.data.id)

                callApis(response.data.id)

                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
                })
              } else alert(error.message)
            })
        } else {
          if (!phoneFormat)
            alert('please enter valid phone number ')
          else
            alert(
              'Please enter a valid email address. You could be missing something in the e-mail format.'
            )
        }
      }
    const createFather = () =>{
        let payload = {
            userId,
            relationship:'FATHER',
            deceased: survivingFather === 'Yes' ? false : true,
            contactLookupCode: 'INDV',
            gender: 'Male',
            ...getSplitName(fatherName),
            middleName:''
          }
          let updatePayload = {
            deceased: survivingFather === 'Yes' ? false : true,
            fullName:fatherName,
            ...getSplitName(fatherName),
            middleName:''
        }
          Object.keys(fatherList).length === 0 ?
          (
            createContact(payload)
            .then(response => {
                console.log('Record Created Successfully');
              dispatchCreateFamily(response.data);
              setQuestion(question + 1)
              setDivName(`div${question + 1}`)
            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          updateContactById(fatherList[0].id, updatePayload)
          .then(response => {
            console.log('Record Updated Successfully');
            dispatchEditFamily(response.data);
            setQuestion(question + 1);
            setDivName(`div${question + 1}`);
          }).catch(err => {
            console.log(err)
          })
    }
  
    const createMother = () =>{
        let payload = {
            userId,
            relationship:'MOTHER',
            deceased: survivingMother === 'Yes' ? false : true,
            contactLookupCode: 'INDV',
            gender: 'Female',
            ...getSplitName(motherName),
            middleName:''
          }
        let updatePayload = {
            deceased: survivingMother === 'Yes' ? false : true,
            ...getSplitName(motherName),
            middleName:''
        }
        Object.keys(motherList).length === 0 ?
          (
            createContact(payload)
            .then(response => {
              console.log('Record Created Successfully');
              dispatchCreateFamily(response.data);
              setQuestion(question + 1);
              setDivName(`div${question + 1}`);
            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          updateContactById( motherList[0].id, updatePayload)
          .then(response => {
            console.log('Record Updated Successfully');
            dispatchEditFamily(response.data);
            setQuestion(question + 1);
            setDivName(`div${question + 1}`);
          }).catch(err => {
            console.log(err)
          })
    }

    const createSpouse = () =>{
        let payload = {
            userId,
            relationship:'SPOUSE',
            contactLookupCode: 'INDV',
            ...getSplitName(spouseName),
            middleName:''
          }
          let updatePayload = {
            ...getSplitName(spouseName),
            middleName:''
        }
        Object.keys(spouseList).length === 0 ?
          (
            createContact(payload)
            .then(response => {
              console.log('Record Created Successfully');
              dispatchCreateFamily(response.data);
              if(childrens == 0)
              {
              setQuestion(question + 2)
              setDivName(`div${question + 2}`)
              }
              else
              {
              setQuestion(question + 1)
              setDivName(`div${question + 1}`)
              }
            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          updateContactById( spouseList[0].id, updatePayload)
          .then(response => {
            console.log('Record Updated Successfully');
            dispatchEditFamily(response.data);
            if(childrens == 0)
            {
            setQuestion(question + 2)
            setDivName(`div${question + 2}`)
            }
            else
            {
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
            }
          }).catch(err => {
            console.log(err)
          })
    }

    const createChild = () =>{
        let payload = {
            userId,
            relationship:'CHILD',
            contactLookupCode: 'INDV',
            ...getSplitName(childName),
            contHomeAddress1 : childAddress,
            dob : childDob
          }
          let updatePayload = {
            relationship:'CHILD',
            contactLookupCode: 'INDV',
            ...getSplitName(childName),
            contHomeAddress1 : childAddress,
            dob : childDob
        }
        parseInt(child) + 1 > childList.length || parseInt(child) == childList.length || childList.length === 0 ?
          (
            createContact(payload)
            .then(response => {
              console.log('Record Created Successfully');
              dispatchCreateFamily(response.data);
              if(parseInt(child) + 1 == childrens)
              {
              setQuestion(44)
              setDivName('div44')
              }
              else
              {
                setChild(parseInt(child) + 1)
              }
            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          (
            updateContactById( childList[child].id, updatePayload)
            .then(response => {
              console.log('Record Updated Successfully');
              dispatchEditFamily(response.data);
              if(parseInt(child) + 1 == childrens)
              {
              setQuestion(44)
              setDivName('div44')
              }
              else
              {
                setChild(parseInt(child) + 1)
              }
            }).catch(err => {
              console.log(err)
            })
        )
    }

    const updateUserProfile = () =>{ 
        let payload= question === 5 ? 
        {
            religion : religion,
        }
        : question === 3 ?
        {
            maritalStatus : maritalStatus
        } : question === 4 ?
        { 
            dob : age,
            currHomeAddress1 : address
        }: {}
        updateUser(userId, payload)
        .then(res => {
            console.log(res.data)
            dispatchSetUserDetails(res.data)
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
          }).catch(e => {
            console.log('error')
          })
    }

    const updateExecutorFlag = () =>{
        let id = selectedIds[0]
        updateContactById(id, {executorFlag : true})
        .then(response =>{
            response.data.relationship === 'FATHER' || response.data.relationship === 'MOTHER' || response.data.relationship === 'SPOUSE' || response.data.relationship === 'CHILD'
            ? dispatchEditFamily(response.data)
            : dispatchEditContact(response.data)
            setQuestion(17)
            setDivName('div17')
        })
    }

    const createExecutor = () =>{
        let relation = executorRelationship === "In Laws" ? "IN-LAWS" :
        executorRelationship === "Siblings" ? "SIBLING" :
        executorRelationship === "Other Family Members" ? "OTH-FAM" :
        executorRelationship === "Friends" ? "FRND" :
        executorRelationship === "Colleagues" ? "COL" :
        executorRelationship === "Doctor" ? "DOC" :
        executorRelationship === "Lawyer" ? "LAW" :
        executorRelationship === "Accountant" ? "ACC" :
        executorRelationship === "Consultants" ? "TAX" : "IN-LAWS"
    
        let payload={
            userId,
            contactLookupCode: 'INDV',
            relationship:relation,
            executorFlag : true,
            ...getSplitName(executorName),
            middleName:'',
            contHomeAddress1 : executorAddress,
        }
          let updatePayload = {
            relationship:relation,
            executorFlag : true,
            ...getSplitName(executorName),
            middleName:'',
            contHomeAddress1 : executorAddress,
        }
        executorList.length === 0 ?
          (
            createContact(payload)
            .then(response => {
              console.log('Record Created Successfully');
              dispatchAddContact(response.data);
              setQuestion(question + 1)
              setDivName(`div${question + 1}`)
            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          updateContactById( executorList[0].id, updatePayload)
          .then(response => {
            console.log('Record Updated Successfully');
            executorList[0].relationship === 'FATHER' || executorList[0].relationship === 'MOTHER' || executorList[0].relationship === 'SPOUSE' || executorList[0].relationship === 'CHILD'
            ? dispatchEditFamily(response.data)
            : dispatchEditContact(response.data)
            setQuestion(question + 1);
            setDivName(`div${question + 1}`);
          }).catch(err => {
            console.log(err)
          })
    }
    
    const createDisinherit = () =>{
        /*let relation = excludePersonRelationship === "In Laws" ? "IN-LAWS" :
        excludePersonRelationship === "Siblings" ? "SIBLING" :
        excludePersonRelationship === "Other Family Members" ? "OTH-FAM" :
        excludePersonRelationship === "Friends" ? "FRND" :
        excludePersonRelationship === "Colleagues" ? "COL" :
        excludePersonRelationship === "Doctor" ? "DOC" :
        excludePersonRelationship === "Lawyer" ? "LAW" :
        excludePersonRelationship === "Accountant" ? "ACC" :
        excludePersonRelationship === "Consultants" ? "TAX" : "IN-LAWS"*/
        let payload={
            userId,
            contactLookupCode: 'INDV',
            disinherit : true,
            ...getSplitName(exculdePersonName),
            middleName:'',
        }
          let updatePayload = {
            contactLookupCode: 'INDV',
            disinherit : true,
            ...getSplitName(exculdePersonName),
            middleName:'',
        }
        disinheritList.length === 0 ?
          (
            createContact(payload)
            .then(response => {
              console.log('Record Created Successfully');
              dispatchAddContact(response.data);
              
              if(assetDistribution === 'All to my spouse' 
              && assetDistribution === 'The children of the testator' 
              && exculdePerson === 'Yes')
              {
              setQuestion(46)
              setDivName('div46')
                }
              else
              {
              setQuestion(46)
              setDivName('div46')
              }

            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          updateContactById( disinheritList[0].id, updatePayload)
          .then(response => {
            console.log('Record Updated Successfully');
            if(disinheritList[0].relationship === 'FATHER' || disinheritList[0].relationship === 'MOTHER' || disinheritList[0].relationship === 'SPOUSE' || disinheritList[0].relationship === 'CHILD')
            ( dispatchEditFamily(response.data))
            else( dispatchEditContact(response.data))
           if(assetDistribution === 'All to my spouse' && assetDistribution === 'The children of the testator' && exculdePerson === 'Yes')
            {
            setQuestion(46)
            setDivName('div46')
            }
            else
            {setQuestion(46)
            setDivName('div46')
            }
          }).catch(err => {
            console.log(err)
          })
    }
    
    const updateAlternateExecutorFlag = () =>{
        let id =  alternateSelectedIds[0]
        updateContactById(id, {executorFlag : true, custField5:'true'})
        .then(response =>{
            response.data.relationship === 'FATHER' || response.data.relationship === 'MOTHER' || response.data.relationship === 'SPOUSE' || response.data.relationship === 'CHILD'
            ? dispatchEditFamily(response.data)
            : dispatchEditContact(response.data)
            setQuestion(19)
            setDivName('div19')
        })
    }

    const createAlternateExecutor = () =>{
        let relation = alternateExecutorRelationship === "In Laws" ? "IN-LAWS" :
        alternateExecutorRelationship === "Siblings" ? "SIBLING" :
        alternateExecutorRelationship === "Other Family Members" ? "OTH-FAM" :
        alternateExecutorRelationship === "Friends" ? "FRND" :
        alternateExecutorRelationship === "Colleagues" ? "COL" :
        alternateExecutorRelationship === "Doctor" ? "DOC" :
        alternateExecutorRelationship === "Lawyer" ? "LAW" :
        alternateExecutorRelationship === "Accountant" ? "ACC" :
        alternateExecutorRelationship === "Consultants" ? "TAX" : "IN-LAWS"
        let payload={
            userId,
            contactLookupCode: 'INDV',
            executorFlag : true,
            relationship:relation,
            ...getSplitName(alternateExecutorName),
            middleName:'',
            contHomeAddress1 : alternateExecutorAddress,
            custField5:true
        }
          let updatePayload = {
            relationship:relation,
            ...getSplitName(alternateExecutorName),
            middleName:'',
            contHomeAddress1 : alternateExecutorAddress,
            custField5:true,
        }
        alternateExecutorList.length === 0 ?
          (
            createContact(payload)
            .then(response => {
              console.log('Record Created Successfully');
              dispatchAddContact(response.data);
              setQuestion(question + 1)
              setDivName(`div${question + 1}`)
            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          updateContactById( alternateExecutorList[0].id, updatePayload)
          .then(response => {
            console.log('Record Updated Successfully');
            alternateExecutorList[0].relationship === 'FATHER' || alternateExecutorList[0].relationship === 'MOTHER' || alternateExecutorList[0].relationship === 'SPOUSE' || alternateExecutorList[0].relationship === 'CHILD'
            ? dispatchEditFamily(response.data)
            : dispatchEditContact(response.data)
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
          }).catch(err => {
            console.log(err)
          })
    }
    

    const createBeneficiary = () =>{
    let contactPayload = {
        userId,
        contactLookupCode: 'INDV',
        ...getSplitName(alternateBenificiary),
        middleName:'',
      }
      createContact(contactPayload)
      .then((resp)=>{
          console.log(resp)
          let id = resp.data.id
          const body = [{ contacts: { id }, userId, receiverFlag:false }]
          createBeneficiaryFn(body)
          .then(res => {
            console.log('Record Created Successfully')
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
            dispatchCreateBeneficiary(res.data)
          })
          .catch(err => console.log(err.message))
      })
      .catch((error) => {
        console.log('error')
      })
    }
   
    const nextReligionButton = (toggleModal) =>{
        updateUserProfile()
        toggleModal()
    }
    const StayOnPageReligionButton = (toggleModal) =>{
        toggleModal()
    }
    const nextQuestion = () =>{
        if(question === 1)
            {
            isNaN(userProfileId)
            ?
            createUser()
            : callWillApi()
            }
            else if(question === 2)
            {
            !Object.keys(willDataList).length ? createWillApi() : updateWillApi()
            }
            else if(question === 3 || question=== 4)
            {
            updateUserProfile()
            }
            else if(question === 5 && religion === 'Muslim')
            {
            toggleMuslimReligion()
            }
            else if(question === 5 && religion === 'Christian')
            (
            toggleChristianReligion()
            )
            else if(question === 5 && religion === 'Parsi')
            (
            toggleParsiReligion()
            )
            else if(question === 5)
            {
            updateUserProfile()
            }
            else if(question === 7 || question === 8)
            {
            updateWillApi2(1)
            }
            else if(question === 9)
            {
            createFather()
            }
            else if(question === 10)
            {
            if(maritalStatus === 'Married' )
                {setQuestion(question + 1)
                    setDivName(`div${question + 1}`)
                }
                else{
                    setQuestion(question + 3)
                    setDivName(`div${question + 3}`)
                }
            }
            else if(question === 11)
            {
            createSpouse()
            }
            else if(question === 12 && childrens == 0)
            {
                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
            }
            else if(question === 12 && childrens !== 0)
            {
            createChild()
            }
            else if(question === 13)
            {
            createMother()
            }
            else if(question === 15 && executorType === 'Other')
            {
            createExecutor()
            }
            else if(question === 15 && (executorType === 'Family' || executorType === 'Contact'))
            {
            updateExecutorFlag()
            }
            else if(question === 18 && alternateExecutorType === 'Other')
            {
            createAlternateExecutor()
            }
            else if(question === 18 && (alternateExecutorType === 'Family' || alternateExecutorType === 'Contact'))
            {
            updateAlternateExecutorFlag()
            }
            else if(question === 24)
            {
            createBeneficiary()
            }
            else if(question === 36 || question === 37 || question === 38 || question === 21)
            {
            updateWillApi2(1)
            }
            else if(question === 39)
            {
                setQuestion(41)
                setDivName('div41')
            }
            else if(question === 41 && specialInstructions === 'Yes')
            {
                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
            }
            else if(question === 41 && specialInstructions === 'No')
            {
                setQuestion(question + 2)
                setDivName(`div${question + 2}`)
            }
            else if(question === 42)
            {
                updateWillApi2(1)
            }
            else if(question === 43)
            {
                setQuestion(49)
                setDivName('div49')
             }
            else if(question === 44)
            {
                updateWillApi3(45)
            }
            else if(question === 45)
            {
                updateWillApi3(13)
            }
            else if(question === 50 ||  question === 51)
            {
                updateWillApi2(1)
            }
            else
            {
                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
            }
        }
        const PreviousChildMinorQuestion = () =>{
            setQuestion(47)
            setDivName('div47')
        }
    const nextChildQuestion = () =>{
        if(assetDistribution === 'All to my living child or children')
        {
            setQuestion(34)
            setDivName('div34')
        }
        else
        {
            setQuestion(36)
            setDivName('div36')
        }
    }
    const previousMaritalStatus = () => {
        if(maritalStatus === 'Married')
        {
            if(childrens == 0) {
                setQuestion(question - 2)
                setDivName(`div${question - 2}`)
             } 
             else {
                 setQuestion(45)
                 setDivName('div45')
             }
            }
        else{
             setQuestion(question - 3)
             setDivName(`div${question - 3}`)
        }
    }
    const previousQuestion = () =>{
            if(question === 42)
            {
                setQuestion(41)
                setDivName('div41')
            }
            else if(question === 43 && specialInstructions === 'Yes')
            {
                setQuestion(question - 1)
                setDivName(`div${question - 1}`)
            }
            else if(question === 43 && specialInstructions === 'No')
            {
                setQuestion(question - 2)
                setDivName(`div${question - 2}`)
             }
            else if(question === 44)
            {
                setQuestion(12)
                setDivName('div12')
                setChild(0)
            }
            else if(question === 45)
            {
                setQuestion(44)
                setDivName('div44')
            }
            else if(question === 46 && exculdePerson === 'Yes')
            {
                setQuestion(33)
                setDivName('div33')
            }
            else if(question === 46)
            {
                setQuestion(29)
                setDivName('div29')
            }
            else
            {
                setQuestion(question - 1)
                setDivName(`div${question - 1}`)
            }
    }
    const next2Question = () =>{
        if(testatorRegister === 'Yes')  
        (updateWillApi2(1)) 
        else
        (updateWillApi2(3))
    }
    const previousExeQuestion = () =>{
        if(executorType === 'Family' || executorType === 'Contact')
        {
            setQuestion(15)
            setDivName('div15')
        }
        else{
        setQuestion(question - 1)
        setDivName(`div${question - 1}`)
        }
    }
    const previous2Question = () =>{
        if(testatorRegister === 'Yes')
        { 
        setQuestion(question - 1)
        setDivName(`div${question - 1}`)
        }
        else {
            setQuestion(question - 3)
             setDivName(`div${question -3}`)
        }
    }
    const nextExecutor = () =>{
        if(executor === 'Yes') {
            setQuestion(question + 1)
         setDivName(`div${question + 1}`)
    }
        else{
            setQuestion(21)
            setDivName('div21')
        }
    }
    /*const previousExecutor = () =>{
        if(executor === 'Yes'){
            setQuestion(question - 1)
             setDivName(`div${question - 1}`)
        }
        else{setQuestion(14)
             setDivName(14)}
    }*/
    const nextAlternateExecutor = () =>{
        if(alternateExecutor === 'Yes') 
        {
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
        }
        else{setQuestion(19)
             setDivName('div19')}
    }
    const previousAlternateExecutor = () =>{
        if(alternateExecutor === 'Yes')
        {
            setQuestion(question - 1)
             setDivName(`div${question - 1}`)
        } 
        else{
            setQuestion(17) 
            setDivName('div17')
        }
    }
    const nextExecutorCompensation = () =>{
        if(executorCompensation === 'Yes') {setQuestion(question + 1)
             setDivName(`div${question + 1}`)}
        else{setQuestion(21)
             setDivName('div21')}
    }
    const previousExecutorCompensation = () =>{
        if(executor === 'No')
        {
            setQuestion(14)
             setDivName('div14')
        }
        else{
        if(executorCompensation === 'Yes')
        {
            setQuestion(question - 1)
             setDivName(`div${question - 1}`)
        }
        else{ 
        if(alternateExecutor === 'Yes')
        {
            setQuestion(17)
            setDivName('div17')
        }
        else 
        {
            if(executor === 'Yes' )
            {setQuestion(14)}
            else{setQuestion(question - 1) 
                setDivName(`div${question - 1}`)
            }
        }
        }
        }
    }
    const nextAssetDistribution = () =>{
        if(assetDistributionType === 'Asset Distribution Tool')
        {
            setQuestion(36)
            setDivName('div36')
        }
        else if(assetDistribution === 'All to my spouse')
        {
        setQuestion(23)
        setDivName('div23')
        }
        else if(assetDistribution === 'All to my living child or children')
        {
        setQuestion(29)
        setDivName('div29')
        }
        else if(assetDistribution === 'All to one named beneficiary')
        {
        setQuestion(28)
        setDivName('div28')
        }
        else if(assetDistribution === 'Divided between multiple named beneficiaries in equal shares')
        {
        setQuestion(30)
        setDivName('div30')
        }
        else if(assetDistribution === 'Divided between multiple named beneficiaries in unequal shares')
        {
        setQuestion(31)
        setDivName('div31')
        }
        else if(assetDistribution === 'In an alternate method of distribution')
        {
        setQuestion(32)
        setDivName('div32')
         }
        else
        {
        setQuestion(question + 1)
        setDivName(`div${question + 1}`)
        }
        }
        const previousAssetDistribution = () =>{
            setQuestion(22)
            setDivName(22)
        }
       
        const nextAlternateAssetDistribution = () =>{
        if(alternateAssetDistribution === 'The children of the testator')
        {
        setQuestion(29)
        setDivName('div29')
        }
        else if(alternateAssetDistribution === 'An alternate named beneficiary')
        {
        setQuestion(24)
        setDivName('div24')
        }
        else if(alternateAssetDistribution === 'Multiple named beneficiaries in equal shares' )
        {
        setQuestion(25)
        setDivName('div25')
        }
        else if(alternateAssetDistribution === 'Multiple named beneficiaries in unequal shares' )
        {
        setQuestion(26)
        setDivName('div26')
        }
        else
        {
        setQuestion(23)
        setDivName('div23')
        }
        }
        const previousAlternateAssetDistribution = () =>{
            if(assetDistribution === 'All to my living child or children')
            {
                setQuestion(34)
                setDivName('div34')
            }
            else
            {
            setQuestion(23)
            setDivName('div23')
            }
        }
       
        const previousAlternateChildQuestion = () =>{
            setQuestion(35)
            setDivName('div35')
        }
        const nextMinorBenificiary = () =>{
            if(minorBeneficiaries === 'Yes')
            {
            setQuestion(47)
            setDivName('div47')
            }
            else{
                setQuestion(36)
                setDivName('div36')
            }
        }
        const PreviousMinorBenificiary = () =>{
            if(minorBeneficiaries === 'No')
            {
            setQuestion(43)
            setDivName('div43')
            }
            else{
                setQuestion(46)
                setDivName('div46')
            }
        }
        const previousAssetDistributions = () =>{
            if(assetDistribution === 'All to my spouse' && alternateAssetDistribution === 'The children of the testator')
            {
            setQuestion(23)
            setDivName('div23')
            }
            else
            {setQuestion(22)
            setDivName('div22')
            }
        }
        const nextChildExcludeQuestion = () =>{
            if(exculdePerson === 'Yes')
            {
            setQuestion(33)
            setDivName('div33')
            }
            else
            {setQuestion(46)
            setDivName('div46')
            }
        }
        const PreviousChildExcludeQuestion = () =>{
            setQuestion(29)
            setDivName('div29')
        }
        const nextExculdePersonQUestion = () =>{
           createDisinherit()
        }
        const PreviousTrustee = () =>{
                setQuestion(46)
                setDivName('div46')
        }
        const nextTrustee = () =>{
            if(appointTrusteeForMinor === 'the Executor (if applicable)')
            {
                setQuestion(36)
                setDivName('div36')
            }
            else
            {
            setQuestion(48)
            setDivName('div48')
            }
        }
        /*const nextExecutorExclude = () =>{
            if(appointTrustee === 'some other person')
            {
            setQuestion(35)
            setDivName('div35')
            }
            else
            {setQuestion(36)
            setDivName('div36')
            }
        }*/
        const nextChildDistribution = () =>{
            if(assetDistribution === 'All to my living child or children')
            {
                if(childAssetDistribution === 'An alternate named beneficiary')
                {
                setQuestion(24)
                setDivName('div24')
                }
                else if
                (childAssetDistribution === 'Multiple named beneficiaries in equal shares')
                        {
                        setQuestion(25)
                        setDivName('div25')
                        }
                    else
                    //childAssetDistribution === 'Multiple named beneficiaries in unequal shares'
                    //?
                        {
                        setQuestion(26)
                        setDivName('div26')
                        }
            }
            else
                {
                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
                }
        }
        const previousExecutorQuestion = () =>{
            if(assetDistribution ==='All to my spouse' && alternateAssetDistribution === 'The children of the testator')
            {
            if(exculdePerson === 'No')
            {
                setQuestion(29)
                setDivName('div29')
            }
            else{
                setQuestion(33)
                setDivName('div33')
            }
            }
            else if(assetDistribution ==='All to my living child or children' && alternateAssetDistribution === 'The children of the testator')
            {
            if(exculdePerson === 'No')
            {
                setQuestion(29)
                setDivName('div29')
            }
            else{
                setQuestion(33)
                setDivName('div33')
            }
            }
            else
            {
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
            }
        }
        const previousExecutorExclude = () =>{
            if(assetDistributionType === 'Asset Distribution Tool')
            {
                setQuestion(22)
                setDivName('div22')
            }
            else if(assetDistribution ==='All to my spouse' && alternateAssetDistribution === 'The children of the testator')
            {
                    if(minorBeneficiaries === 'No')
                    {
                        setQuestion(46)
                        setDivName('div46')
                    }
                    
                    else if(minorBeneficiaries === 'Yes' && appointTrusteeForMinor === 'the Executor (if applicable)')
                    {
                    setQuestion(47)
                    setDivName('div47')
                    }
                    else
                    {
                    setQuestion(48)
                    setDivName('div48')
                    }
            }
            else if(assetDistribution ==='All to my spouse' && alternateAssetDistribution === 'An alternate named beneficiary')
            {
            setQuestion(24)
            setDivName('div24')
            }
            
            else if(assetDistribution ==='All to my spouse' && alternateAssetDistribution === 'Multiple named beneficiaries in equal shares')
            {
            setQuestion(25)
            setDivName('div25')
            }
            else if(assetDistribution ==='All to my spouse' && alternateAssetDistribution === 'Multiple named beneficiaries in unequal shares')
            {
            setQuestion(26)
            setDivName('div26')
            }
           else if(assetDistribution ==='All to my living child or children')
            {
                    if(childAssetDistribution === 'An alternate named beneficiary')
                    {
                    setQuestion(24)
                    setDivName('div24')
                    }
                    else if(childAssetDistribution === 'Multiple named beneficiaries in equal shares')
                    {
                    setQuestion(25)
                    setDivName('div25')
                    }
                    else if(childAssetDistribution ===  'Multiple named beneficiaries in unequal shares')
                    {
                    setQuestion(26)
                    setDivName('div26')
                    }
                    else
                    {
                    setQuestion(25)
                    setDivName('div25')
                    }
            }
            else{
            setQuestion(25)
            setDivName('div25')
            }
    }
        const nextAssetQuestions = () =>{
            setQuestion(36)
            setDivName('div36')
        }
        const nextAlternateBenificiary = () =>{
            if(benificiaryForAssets === 'The children of the testator')
            {
            setQuestion(29)
            setDivName('div29')
            }
            else if(benificiaryForAssets === 'An alternate named beneficiary')
            {
            setQuestion(24)
            setDivName('div24')
            }
            else if(benificiaryForAssets === 'Multiple named beneficiaries in equal shares')
            {
            setQuestion(25)
            setDivName('div25')
            }
            else if(benificiaryForAssets === 'Multiple named beneficiaries in unequal shares')
            {
            setQuestion(26)
            setDivName('div26')
            }
            else
            {
            setQuestion(23)
            setDivName('div23')
            }
            }
        const nextBenQuestion = () =>{
                setQuestion(27)
                setDivName('div27')
            }
        const previousBenQuestion = () =>{
            setQuestion(30)
            setDivName('div30')
        }
        const nextDeathQuestion = () =>{
            if(testatorDeath === 'No')
            {
            setQuestion(52)
            setDivName('div52')
            }
            else
            {
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
            }
        }
        
    const WillDocument = () =>{
        return(
            <div>
        <div  ref={ref1}>
<span></span><p style={{textAlign: 'center'}}><strong>LAST WILL AND TESTAMENT</strong></p>
<p >This will (hereinafter referred to as the "<strong>Will</strong>") is executed at <span ><span  style={{ backgroundColor: divname !== 'div0' ? '#fff': 'yellow'}}  id='placeName'>{place === '' ? '____________' : <b>{place}</b>}</span></span>
, on this <span><span style={{ backgroundColor: divname !== 'div0' ? '#fff': 'yellow'}} ><b>{willDate === '' ? '____________' : moment(willDate).format('DD-MM-YYYY')} </b></span></span>
by <span  ><span  style={{ backgroundColor: divname !== 'div0' ? '#fff': 'yellow'}}>{nameOfUser === '' ? '____________' : nameOfUser} </span></span>
<span style={{ backgroundColor: divname !== 'div1' ? '#fff': 'yellow'}}><span style={{ backgroundColor: divname !== 'div3' ? '#fff': 'yellow'}} >{gender ==='Male' ? 'son' : gender === 'Female' ? maritalStatus === 'Married' ? 'Wife' : 'daughter' : 'son'} </span> </span>of <span  >
<span  style={{ backgroundColor: divname !== 'div2' ? '#fff': 'yellow'}}>{maritalStatus === 'Married' ? 
gender ==='Female' ? spouseName === '' ? '__________' : spouseName : fatherName === '' ? '____________' : fatherName : fatherName === '' ? '____________' : fatherName}</span></span>,
aged <span   ><span  style={{ backgroundColor: divname !== 'div4' ? '#fff': 'yellow'}} >{age === '' ? '____________' : moment().diff(moment(age, 'YYYYMMDD'), 'years')} </span></span>
years resident of <span  ><span  style={{ backgroundColor: divname !== 'div4' ? '#fff': 'yellow'}}>{address === '' ? '____________' : address} </span></span>
(hereinafter referred to as the "<strong>Testator</strong>".)
</p>
</div>

<div ref={ref2}>
<p><strong>DECLARATIONS</strong></p>
{testatorRegister === 'No' ?
<p style={{ backgroundColor: divname !== 'div6' ? '#fff': '#dfdee5'}}>1.&nbsp;&nbsp;&nbsp;The Testator hereby revokes all his previous Wills and the Testator is executing this Will and Testament voluntarily there being no pressure or coercion and in sound health and state of mind.</p>
:
<p style={{ backgroundColor: divname !== 'div6' ? '#fff': '#dfdee5'}}>1.&nbsp;&nbsp;&nbsp;I hereby revoke my last registered Will dated <span  >
<span  style={{ backgroundColor: divname !== 'div7' ? '#fff': 'yellow'}}>{subRegistrarDate === '' ? '____________' : moment(subRegistrarDate).format('DD-MM-YYYY')}</span></span> registered in the office of the Sub-Registrar vide Registration No. <span >
<span style={{ backgroundColor: divname !== 'div7' ? '#fff': 'yellow'}}>{registrationDetails === '' ? '____________' : registrationDetails}</span></span> on <span >
<span style={{ backgroundColor: divname !== 'div8' ? '#fff': 'yellow'}}> {earlierDateOfRegistration === '' ? '____________' : moment(earlierDateOfRegistration).format('DD-MM-YYYY')}</span></span>
.</p>
}
<p>2.&nbsp;&nbsp;&nbsp;I have the following surviving legal heirs:</p>
<p>3.&nbsp;&nbsp;&nbsp;I am <span   ><span style={{ backgroundColor: divname !== 'div3' ? '#fff': 'yellow'}} >{maritalStatus === '' ? 'married to ____________' : maritalStatus === 'Married' ? <span>married to <span style={{ backgroundColor: divname !== 'div11' ? '#fff': 'yellow'}}>{spouseName === '' ? ' ____________' : spouseName} </span></span>: 'not married'}</span></span> </p>
{childrens === '0'?
<div>
{ survivingFather === 'Yes' ? <p>4.&nbsp;&nbsp;&nbsp;My father's name is <span ><span  style={{ backgroundColor: divname !== 'div2' ? '#fff': 'yellow'}}>{fatherName === '' ? '____________' : fatherName}</span></span>.</p> : null}
{ survivingMother === 'Yes' ? <p>5.&nbsp;&nbsp;&nbsp;My mother's name is <span ><span  style={{ backgroundColor: divname !== 'div13' ? '#fff': 'yellow'}}>{motherName === '' ? '____________' : motherName} </span></span>.</p> : null}
</div>
:
<div>
<p>4.&nbsp;&nbsp;&nbsp;I have the following living <span>children:</span></p>
{[...Array(parseInt(childrens))].map((x, i) =>
<p style={{ backgroundColor: divname !== 'div9' ? '#fff': '#dfdee5'}}>{String.fromCharCode(97 + i)}.
&nbsp;&nbsp;&nbsp;<span style={{ backgroundColor: divname !== 'div12' ? '#fff': 'yellow'}}>{_.isEmpty(childList[i]) ? '____________' : _.isEmpty(childList[i].firstName) ? '____________' : _.isEmpty(personalDetails.firstName) ? '' : getFullName(childList[i])}</span>, of <span >
<span  style={{ backgroundColor: divname !== 'div12' ? '#fff': 'yellow'}}>{_.isEmpty(childList[i]) ?'____________' : _.isEmpty(childList[i].homeAddress.houseName) ? '____________' : childList[i].homeAddress.houseName}</span></span>, born on <span >
<span  style={{ backgroundColor: divname !== 'div12' ? '#fff': 'yellow'}}>{_.isEmpty(childList[i]) ? '____________' : _.isEmpty(childList[i].dob) || childList[i].dob === 'Invalid date' ? '____________' : moment(childList[i].dob).format('DD-MM-YYYY')}</span></span> </p>
)}
<p>5.&nbsp;&nbsp;&nbsp;The term 'child' or 'children' as used in my Will includes all the above listed children and any children of mine that are subsequently born or legally adopted.</p>
{ survivingFather === 'Yes' ? <p>6.&nbsp;&nbsp;&nbsp;My father's name is <span ><span  style={{ backgroundColor: divname !== 'div2' ? '#fff': 'yellow'}}>{fatherName === '' ? '____________' : fatherName}</span></span>.</p> : null}
{ survivingMother === 'Yes' ? <p>7.&nbsp;&nbsp;&nbsp;My mother's name is <span  ><span  style={{ backgroundColor: divname !== 'div13' ? '#fff': 'yellow'}}>{motherName === '' ? '____________' : motherName} </span></span>.</p> : null}
</div>
}
</div>

<div ref={ref3}>
{executor === 'Yes' ?
<div >
<p><strong>EXECUTOR</strong></p>
<p><strong>Definition</strong> </p>
<p><p>6.&nbsp;&nbsp;&nbsp;The expression 'my Executor' used throughout this Will includes either the singular and plural, and the masculine or feminine gender as appropriate wherever the fact or context so requires. The term 'executor' in this Will is synonymous with and includes the terms 'personal representative' and 'executrix'.</p>
<p><strong>Appointment of Executor</strong></p>
<p style={{ backgroundColor: divname !== 'div14' ? '#fff': '#dfdee5'}}>7.&nbsp;&nbsp;&nbsp;I appoint the following: <span style={{ backgroundColor: divname !== 'div15' ? '#fff': 'yellow'}}>
{ executorType === 'Family' ? _.isEmpty(famExecutor) ? '__________' : getFullName(famExecutor)
    : executorType === 'Contact' ? _.isEmpty(conExecutor) ? '___________' : getFullName(conExecutor) 
    : executorType === 'Other' ?  executorName === '' ? '__________' : executorName 
    : '____________'
}</span>
<span>, my <span style={{ backgroundColor: divname !== 'div16' ? '#fff': 'yellow'}}>
{ executorType === 'Family' ? _.isEmpty(famExecutor) ? '__________' : famExecutor.relationship 
: executorType === 'Contact' ? _.isEmpty(conExecutor) ? '___________' : conExecutor.relationship  
: executorType === 'Other' ?  executorRelationship === '' ? '__________' : executorRelationship 
: '____________'
}</span></span>
, of <span ><span style={{ backgroundColor: divname !== 'div16' ? '#fff': 'yellow'}}>
{ executorType === 'Family' ? _.isEmpty(famExecutor) ? '__________' : famExecutor.homeAddress.houseName
: executorType === 'Contact' ? _.isEmpty(conExecutor) ? '___________' : conExecutor.homeAddress.houseName
: executorType === 'Other' ?  executorAddress === '' ? '__________' : executorAddress 
: '____________'
}
</span></span>,  to be the sole executor of my Will.</p>
{alternateExecutor === 'Yes' ? 
<span style={{ backgroundColor: divname !== 'div17' ? '#fff': '#dfdee5'}}>but if <span><span style={{ backgroundColor: divname !== 'div15' ? '#fff': 'yellow'}}>{executorName === ''?'________' : executorName}</span></span>
<span> should predecease me or is unable or unwilling to act as my Executor, then I appoint : </span>
<span><span style={{ backgroundColor: divname !== 'div18' ? '#fff': 'yellow'}}>
{ alternateExecutorType === 'Family' ? _.isEmpty(famAltExecutor) ? '__________' : getFullName(famAltExecutor)
: alternateExecutorType === 'Contact' ? _.isEmpty(conAltExecutor) ? '___________' : getFullName(conAltExecutor)
: alternateExecutorType === 'Other' ?  alternateExecutorName === '' ? '__________' : alternateExecutorName 
: '____________'
} </span></span>
, my <span><span style={{ backgroundColor: divname !== 'div18' ? '#fff': 'yellow'}}>
{ alternateExecutorType === 'Family' ? _.isEmpty(famAltExecutor) ? '__________' : famAltExecutor.relationship 
: alternateExecutorType === 'Contact' ? _.isEmpty(conAltExecutor) ? '___________' : conAltExecutor.relationship  
: alternateExecutorType === 'Other' ?  alternateExecutorRelationship === '' ? '__________' : alternateExecutorRelationship 
: '____________'
}</span></span>
, of <span><span style={{ backgroundColor: divname !== 'div18' ? '#fff': 'yellow'}}>
{ alternateExecutorType === 'Family' ? _.isEmpty(famAltExecutor) ? '__________' : famAltExecutor.homeAddress.houseName
: alternateExecutorType === 'Contact' ? _.isEmpty(conAltExecutor) ? '___________' : conAltExecutor.homeAddress.houseName
: alternateExecutorType === 'Other' ?  alternateExecutorAddress === '' ? '__________' : alternateExecutorAddress 
: '____________'
}</span></span> to be Executor of my Will in the place and stead of 
 <span  ><span  style={{ backgroundColor: divname !== 'div15' ? '#fff': 'yellow'}}> 
 { executorType === 'Family' ? _.isEmpty(famExecutor) ? '__________' : getFullName(famExecutor)
    : executorType === 'Contact' ? _.isEmpty(conExecutor) ? '___________' : getFullName(conExecutor)
    : executorType === 'Other' ?  executorName === '' ? '__________' : executorName 
    : '____________'
} .</span></span></span>
 : null} </p>
<p>8.&nbsp;&nbsp;&nbsp;No individual acting as an Executor under this Will will be required to give any security for the performance of his or her duties notwithstanding the laws of any country or other jurisdiction to the contrary.</p>


<p><strong>Powers of my Executor</strong></p>
<p>9.&nbsp;&nbsp;&nbsp;To carry out the terms of my Will, I give my Executor the following duties and powers with respect to my estate:</p>
<p>a.&nbsp;&nbsp;&nbsp;To pay my legally enforceable debts, funeral expenses and all expenses in connection with the administration of my estate and trusts created by my Will as soon as convenient after my death. If any of the real property devised in my Wills remains subject to a mortgage at the time of my death, then I direct that the devisee taking that mortgaged property will take the property subject to that mortgage and that the devisee will not be entitled to have the mortgage paid out or resolved from the remaining assets of the residue of my estate;</p>
<p>b.&nbsp;&nbsp;&nbsp;To take all legal actions to have the probate of my Will completed as quickly and simply as possible, if required by law, or if deemed necessary by the Executor, under the laws of India;</p>
<p>c.&nbsp;&nbsp;&nbsp;To retain, exchange, insure, repair, improve, sell or dispose or any and all personal property belonging to my estate as specified in this will, and if not specifically specified as my Executor deems advisable without liability for loss or depreciation;</p>
<p>d.&nbsp;&nbsp;&nbsp;To invest, manage, lease, rent, exchange, mortgage, sell, dispose of or give options without being limited to term and to insure, repair, improve, or add to or otherwise deal with any and all real property belonging to my estate as specified in this will, and if not specifically specified as my Executor deems advisable without liability or depreciation;</p>
<p>e.&nbsp;&nbsp;&nbsp;To purchase, maintain, convert and liquidate investments or securities, and to vote stock, or exercise any option concerning any investments or securities without liability for loss;</p>
<p>f.&nbsp;&nbsp;&nbsp;To open or close bank accounts;</p>
<p>g.&nbsp;&nbsp;&nbsp;To maintain, continue, dissolve, change or sell any business which is part of my estate, or to purchase any business if deemed necessary or beneficial to my estate by me Executor;</p>
<p>h.&nbsp;&nbsp;&nbsp;To maintain, settle, abandon, sue or defend, or otherwise deal with any lawsuit against my estate;</p>
<p>i.&nbsp;&nbsp;&nbsp;To employ any lawyer, accountant or other professional; and</p>
<p>j.&nbsp;&nbsp;&nbsp;Except as otherwise provided in this Will, to act as my Trustee by holding in trust the share of any minor beneficiary, and to keep such share invested, pay the income or capital or as much of either or both as my Executor considers available for the maintenance, education, advancement or benefit of such minor beneficiary and to pay or transfer the capital of such share or the amount remaining of that share to such beneficiary when he or she reaches the age of majority or, during the minority of such beneficiary, to pay or transfer such share to any parent or guardian of such beneficiary subject to like conditions and the receipt of any such parent or guardian discharges my Executor.</p>
<p>10.&nbsp;&nbsp;&nbsp;The above authority and powers granted to my Executor are in addition to any powers and elective rights conferred by provincial/territorial or federal law or by any other provision of this Will and me be exercised as often as required, and without application to or approval by any court. </p>

<div ref={ref4}>
{executorCompensation === 'Yes' ?
<div style={{ backgroundColor: divname !== 'div19' ? '#fff': '#dfdee5'}}>
<p><strong>Compensation of Executor</strong></p>
<p>11.&nbsp;&nbsp;&nbsp;My Executor is entitled to compensation for acting as Executor under this Will in addition to any gift or benefit given by me under my Will or any codicil to it. </p>
<p>14.&nbsp;&nbsp;&nbsp; The compensation for acting as Executor under this Will is the following:</p>
<p><span style={{ backgroundColor: divname !== 'div20' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{executorCompensionDetails === '' ? null : executorCompensionDetails}</span></p>
<p>12.&nbsp;&nbsp;&nbsp;Where at any time any of my Executor is a solicitor, my Executor will be entitled to charge and be paid all usual professional fees or other charges for business transacted, time expended, and acts done by my Executor or my Executor's in connection with the administration of my estate and the trusts of this my Will, including acts which a Trustee not being in any professional or business could have done personally.</p>
</div>
: null}
</div>
</div>
:
null}
</div>

<div ref={ref5}>
<p><strong>DISPOSITION OF ESTATE</strong></p>

<div ref={ref6}>
{listAssets === 'Pick from Asset Register' || assetDetails !== '' ?
<p><p>I am the owner of the following assets and properties:</p>
<p><span style={{ backgroundColor: divname !== 'div21' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>
{
    listAssets === 'Pick from Asset Register'
    ?
    <div>
    {finAssetList.length > 0 ?  <h6>Financial Assets</h6> : null }
    {finAssetList.map(f =>
    <div>{f.assetIssuerName} {f.assetIdentificationNumber} {f.contacts !== null ? getContactFullName(f.contacts) : ''}</div>
    )}
    {immPropImmovableAssetList.length > 0 ? <h6>Immovable Assets</h6> : null }
    {immPropImmovableAssetList.map(f =>
    <div>{propertyType[f.assetHolding]}{f.assetAddress1}{f.contacts !== null && f.contacts.id !== ''? getContactFullName(f.contacts) : 'None'}</div>
    )} 
    {digitalAssetList.length > 0 ? <h6>Digital Assets</h6> : null } 
    {digitalAssetList.map(f =>
    <div>{f.assetDetail}{f.assetDescription}</div>
    )}
    {otherAssetList.length > 0 ? <h6>Other Assets</h6> : null } 
    {otherAssetList.map(f =>
    <div>{f.assetIssuerName}{f.assetDescription}</div>
    )}
    </div>
    :
    assetDetails  === ''?'________' : assetDetails
}</span></p>
</p>
: null
}
</div>
</div>
</div>

)
}
const WillDocs = () => {
    return(
        <div>
            <div>
{assetDistributionType === 'Asset Distribution' && assetDistribution === 'All to my spouse' ?
<div>
<p style={{ backgroundColor: divname !== 'div22' ? '#fff': '#dfdee5'}}>13.&nbsp;&nbsp;&nbsp;I hereby declare that after my demise, my assets and properties  be bequeathed on my spouse for their own use absolutely.</p>

{exculdePerson === 'Yes' ?
<p style={{ backgroundColor: divname !== 'div29' ? '#fff': '#dfdee5'}}>
17.&nbsp;&nbsp;&nbsp;<span  style={{ backgroundColor: divname !== 'div33' ? '#fff': 'yellow'}}>
{ excludePersonType === 'Family' ? _.isEmpty(famDisinherit) ? '__________' : getFullName(famDisinherit)
: excludePersonType === 'Contact' ? _.isEmpty(conDisinherit) ? '___________' : getFullName(conDisinherit)
: excludePersonType === 'Other' ?  exculdePersonName === '' ? '__________' : exculdePersonName 
: '____________'
}
 </span>
, my <span style={{ backgroundColor: divname !== 'div18' ? '#fff': 'yellow'}}>
{ excludePersonType === 'Family' ? _.isEmpty(famDisinherit) ? '__________' : famDisinherit.relationship 
: excludePersonType === 'Contact' ? _.isEmpty(conDisinherit) ? '___________' : conDisinherit.relationship  
: excludePersonType === 'Other' ?  excludePersonRelationship === '' ? '__________' : excludePersonRelationship 
: '____________'
}</span>
<span> shall not be entitled to receive any part of the assets and properties.</span>
</p>
: null
}

{alternateAssetDistribution === 'The children of the testator' ? 
<div style={{ backgroundColor: divname !== 'div23' ? '#fff': '#dfdee5'}}>
<p>14.&nbsp;&nbsp;&nbsp;If my spouse pre-deceases me, all assets and properties  will devolve on my children 
{exculdePerson === 'Yes' ?<span style={{ backgroundColor: divname !== 'div29' ? '#fff': '#dfdee5'}}> other than <span style={{ backgroundColor: divname !== 'div33' ? '#fff': 'yellow'}}>{ excludePersonType === 'Family' ? _.isEmpty(famDisinherit) ? '__________' : getFullName(famDisinherit)
: excludePersonType === 'Contact' ? _.isEmpty(conDisinherit) ? '___________' : getFullName(conDisinherit)
: excludePersonType === 'Other' ?  exculdePersonName === '' ? '__________' : exculdePersonName 
: '____________'
}</span></span> : null } then alive at my death in equal shares, subject to the provisions hereinafter specified.</p>
<p>15.&nbsp;&nbsp;&nbsp;If any child of mine dies before becoming entitled in accordance with the terms of this Will, to receive of his or her share of my estate, I direct that such share or the amount remaining of that share will be divided and transferred in equal shares to each of the surviving children of that deceased child of mine. And if any of such children of my deceased child dies before receiving the whole of his or her share of my estate, that share or the amount remaining thereof will be divided in equal shares amongst the surviving children of that child of mine. But if that deceased child of mine leaves no surviving children, then that share or the amount remaining of that share will be divided amongst my surviving children in equal shares.</p>
<p>16.&nbsp;&nbsp;&nbsp;In cases where a child or grandchild of mine is a minor at the time he or she becomes entitled, in accordance with the terms of this Will, to receive his or her share of my estate, and where a guardian, custodian or trustee is not already identified under my Will, that share shall be held by 
<span style={{ backgroundColor: divname !== 'div47' ? '#fff': 'yellow'}}> {appointTrusteeForMinor === 'the Executor (if applicable)' ? 'the Executor' : <span style={{ backgroundColor: divname !== 'div48' ? '#fff': 'yellow'}}>{minorTrusteeName === '' ? '________' : minorTrusteeName}</span>}</span> in trust for each of those surviving children who is a minor child. </p>

{bankAccount === 'No' && bankLocker === 'No' && pf==='No' && fixedDeposits==='No' ? null :
<p>17.&nbsp;&nbsp;&nbsp;As regards <span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{bankAccount==='No' ? null : 'the Bank Accounts, '}</span><span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{fixedDeposits==='No' ? null : 'the Fixed Deposits, '}</span><span style={{ backgroundColor: divname !== 'div37' ? '#fff': 'yellow'}}>{bankLocker==='No' ? null : 'the assets in my bank lockers, '}</span>and <span style={{ backgroundColor: divname !== 'div38' ? '#fff': 'yellow'}}>{pf==='No' ? null : 'my public provident fund account, '}</span>the same shall be bequeathed to the members of family already nominated in the accounts. </p>
}
</div>
: null}

{alternateAssetDistribution === 'An alternate named beneficiary' ? 
<div style={{ backgroundColor: divname !== 'div23' ? '#fff': '#dfdee5'}}>
<p>14.&nbsp;&nbsp;&nbsp;If my spouse pre-deceases me, I direct that the assets and properties of my estate be divided  in the following manner:</p>
<p>a.&nbsp;&nbsp;&nbsp;All assets and properties  will be distributed to the following beneficiary:
<span  style={{ backgroundColor: divname !== 'div24' ? '#fff': 'yellow'}}> {alternateBenificiary === '' ? '________' : alternateBenificiary}</span><p/></p>
{bankAccount === 'No' && bankLocker === 'No' && pf==='No' && fixedDeposits==='No' ? null :
<p>17.&nbsp;&nbsp;&nbsp;As regards <span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{bankAccount==='No' ? null : 'the Bank Accounts, '}</span>
<span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{fixedDeposits==='No' ? null : 'the Fixed Deposits, '}</span>
<span style={{ backgroundColor: divname !== 'div37' ? '#fff': 'yellow'}}>{bankLocker==='No' ? null : 'the assets in my bank lockers, '}</span>and <span style={{ backgroundColor: divname !== 'div38' ? '#fff': 'yellow'}}>{pf==='No' ? null : 'my public provident fund account, '}</span>the same shall be bequeathed to the members of family already nominated in the accounts. </p>
}
</div>: null}

{alternateAssetDistribution === 'Multiple named beneficiaries in equal shares' ? 
<div style={{ backgroundColor: divname !== 'div23' ? '#fff': '#dfdee5'}}>
<p>14.&nbsp;&nbsp;&nbsp;If my spouse pre-deceases me, I direct that the assets and properties of my estate be divided  in the following manner:</p>
<p>a.&nbsp;&nbsp;&nbsp;All assets and properties will be distributed to the following beneficiaries in equal shares: </p>
<p><span  style={{ backgroundColor: divname !== 'div25' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{multipleAlternateBenificiary === '' ? '________' : multipleAlternateBenificiary}</span></p>
{bankAccount === 'No' && bankLocker === 'No' && pf==='No' && fixedDeposits==='No' ? null :
<p>17.&nbsp;&nbsp;&nbsp;As regards <span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{bankAccount==='No' ? null : 'the Bank Accounts, '}</span><span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{fixedDeposits==='No' ? null : 'the Fixed Deposits, '}</span><span style={{ backgroundColor: divname !== 'div37' ? '#fff': 'yellow'}}>{bankLocker==='No' ? null : 'the assets in my bank lockers, '}</span>and <span style={{ backgroundColor: divname !== 'div38' ? '#fff': 'yellow'}}>{pf==='No' ? null : 'my public provident fund account, '}</span>the same shall be bequeathed to the members of family already nominated in the accounts. </p>
}
</div>: null}

{alternateAssetDistribution === 'Multiple named beneficiaries in unequal shares' ? 
<div style={{ backgroundColor: divname !== 'div23' ? '#fff': '#dfdee5'}}>
<p>14.&nbsp;&nbsp;&nbsp;If my spouse pre-deceases me, I direct that the assets and properties of my estate be divided  in the following manner:</p>
<p>a.&nbsp;&nbsp;&nbsp;The assets and properties will be distributed to the following beneficiaries: </p>
<p><span  style={{ backgroundColor: divname !== 'div26' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{multipleAlternateBenificiaryUnequal  === '' ? '________' : multipleAlternateBenificiaryUnequal}</span></p>
<p>b.&nbsp;&nbsp;&nbsp;My beneficiaries will receive the assets and properties  according to the following description:</p>
<p><span  style={{ backgroundColor: divname !== 'div26' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{multipleAlternateBenificiaryUnequalShare  === '' ? '________' : multipleAlternateBenificiaryUnequalShare}</span></p>
{bankAccount === 'No' && bankLocker === 'No' && pf==='No' && fixedDeposits==='No' ? null :
<p>17.&nbsp;&nbsp;&nbsp;As regards <span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{bankAccount==='No' ? null : 'the Bank Accounts, '}</span><span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{fixedDeposits==='No' ? null : 'the Fixed Deposits, '}</span><span style={{ backgroundColor: divname !== 'div37' ? '#fff': 'yellow'}}>{bankLocker==='No' ? null : 'the assets in my bank lockers, '}</span>and <span style={{ backgroundColor: divname !== 'div38' ? '#fff': 'yellow'}}>{pf==='No' ? null : 'my public provident fund account, '}</span>the same shall be bequeathed to the members of family already nominated in the accounts. </p>
}
</div>: null}

</div>
: null}

{assetDistributionType === 'Asset Distribution' && assetDistribution === 'All to my living child or children' ?
<div>
{exculdePerson === 'Yes' ?
<p>
<p style={{ backgroundColor: divname !== 'div22' ? '#fff': '#dfdee5'}}>13.&nbsp;&nbsp;&nbsp;<span >{ excludePersonType === 'Family' ? _.isEmpty(famDisinherit) ? '__________' : getFullName(famDisinherit)
: excludePersonType === 'Contact' ? _.isEmpty(conDisinherit) ? '___________' : getFullName(conDisinherit)
: excludePersonType === 'Other' ?  exculdePersonName === '' ? '__________' : exculdePersonName 
: '____________'
}</span> shall not be entitled to receive any part of the assets and properties.</p>
<p>
<span>14.&nbsp;&nbsp;&nbsp;I direct that the assets and properties of my estate be divided </span>
<span> into as many equal shares as there shall be children of mine then alive at my death (excluding specifically : 
<span><span>{ excludePersonType === 'Family' ? _.isEmpty(famDisinherit) ? '__________' : getFullName(famDisinherit)
: excludePersonType === 'Contact' ? _.isEmpty(conDisinherit) ? '___________' : getFullName(conDisinherit)
: excludePersonType === 'Other' ?  exculdePersonName === '' ? '__________' : exculdePersonName 
: '____________'
}</span></span>)
<span>, subject to the provisions hereinafter specified and to pay and transfer one such share to each of those surviving children other than </span>
<span><span>{ excludePersonType === 'Family' ? _.isEmpty(famDisinherit) ? '__________' : getFullName(famDisinherit)
: excludePersonType === 'Contact' ? _.isEmpty(conDisinherit) ? '___________' : getFullName(conDisinherit)
: excludePersonType === 'Other' ?  exculdePersonName === '' ? '__________' : exculdePersonName 
: '____________'
}</span></span>.</span>
</p>
</p>
:
<p>13.&nbsp;&nbsp;&nbsp;I direct that the assets and properties of my estate be divided  into as many equal shares as there shall be children of mine then alive at my death, subject to the provisions hereinafter specified and to pay and transfer one such share to each of those surviving children.</p>
}
<p>14.&nbsp;&nbsp;&nbsp;If any child of mine dies before becoming entitled in accordance with the terms of this Will to receive of his or her share of my estate, I direct that such share or the amount remaining of that share will be divided and transferred in equal shares to each of the surviving children of that deceased child of mine. And if any of such children of my deceased child dies before receiving the whole of his or her share of my estate, that share or the amount remaining thereof will be divided in equal shares amongst the surviving children of that child of mine. But if that deceased child of mine leaves no surviving children, then that share or the amount remaining of that share will be divided amongst my surviving children in equal shares.</p>
<p>15.&nbsp;&nbsp;&nbsp; In cases where a child or grandchild of mine is a minor at the time he or she becomes entitled, in accordance with the terms of this Will, to receive his or her share of my estate, and where a guardian, custodian or trustee is not already identified under my Will, that share shall be held <span style={{ backgroundColor: divname !== 'div48' ? '#fff': 'yellow'}}>{minorTrusteeName === '' ? '________' : minorTrusteeName}</span> in trust for each of those surviving children who is a minor child.</p>
{childAssetDistribution === 'An alternate named beneficiary' ?
<div>
<p>16.&nbsp;&nbsp;&nbsp;If no children of mine survives me, and if none of my deceased children are survived by any children, I direct that the assets and properties of my estate be divided  in the following manner:</p>
<p>a.&nbsp;&nbsp;&nbsp;The assets and properties of my estate  will be distributed to the following beneficiary: <span>{alternateBenificiary === '' ? '________' : alternateBenificiary}</span></p>
</div>
:
childAssetDistribution === 'Multiple named beneficiaries in equal shares' ?
<div>
<p>16.&nbsp;&nbsp;&nbsp;If no children of mine survives me, and if none of my deceased children are survived by any children, I direct that the assets and properties of my estate be divided  in the following manner:</p>
<p>a.&nbsp;&nbsp;&nbsp;All assets and properties will be distributed to the following beneficiaries in equal shares: </p>
<p><span  style={{ backgroundColor: divname !== 'div25' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{multipleAlternateBenificiary === '' ? '________' : multipleAlternateBenificiary}</span></p>
</div>
:childAssetDistribution === 'Multiple named beneficiaries in unequal shares' ?
<div>
<p>16.&nbsp;&nbsp;&nbsp;If no children of mine survives me, and if none of my deceased children are survived by any children, I direct that the assets and properties of my estate be divided  in the following manner:</p>
<p>a.&nbsp;&nbsp;&nbsp;The assets and properties will be distributed to the following beneficiaries: </p>
<p><span  style={{ backgroundColor: divname !== 'div26' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{multipleAlternateBenificiaryUnequal  === '' ? '________' : multipleAlternateBenificiaryUnequal}</span></p>
<p>b.&nbsp;&nbsp;&nbsp;My beneficiaries will receive the assets and properties  according to the following description:</p>
<p><span  style={{ backgroundColor: divname !== 'div26' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{multipleAlternateBenificiaryUnequalShare  === '' ? '________' : multipleAlternateBenificiaryUnequalShare}</span></p>
</div>
: null
}
{bankAccount === 'No' && bankLocker === 'No' && pf==='No' && fixedDeposits==='No' ? null :
<p>17.&nbsp;&nbsp;&nbsp;As regards <span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{bankAccount==='No' ? null : 'the Bank Accounts, '}</span><span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{fixedDeposits==='No' ? null : 'the Fixed Deposits, '}</span><span style={{ backgroundColor: divname !== 'div37' ? '#fff': 'yellow'}}>{bankLocker==='No' ? null : 'the assets in my bank lockers, '}</span>and <span style={{ backgroundColor: divname !== 'div38' ? '#fff': 'yellow'}}>{pf==='No' ? null : 'my public provident fund account, '}</span>the same shall be bequeathed to the members of family already nominated in the accounts. </p>
}
</div>
: null
}

{benificiaryForAssetsOpt === 'All to one named beneficiary' ?
<div style={{ backgroundColor: divname !== 'div28' ? '#fff': '#dfdee5'}}>
<p>13.&nbsp;&nbsp;&nbsp;I direct that the assets and properties of my estate be divided  in the following manner:</p>
<p>a.&nbsp;&nbsp;&nbsp;The assets and properties of my estate  will be distributed to the following beneficiary: 
<span  style={{ backgroundColor: divname !== 'div28' ? '#fff': 'yellow'}}>{benificiaryForAssets === '' ? '________' : benificiaryForAssets}</span> </p>

{benificiaryForAssetsOpt === 'The children of the testator' ?
<p style={{ backgroundColor: divname !== 'div28' ? '#fff': '#dfdee5'}}>14.&nbsp;&nbsp;&nbsp;If any such beneficiary of mine dies before becoming entitled in accordance with the terms of this Will to receive of his or her share of my estate, I direct that such share or the amount remaining of that share will be divided and transferred in equal shares to each of the surviving children of that deceased beneficiary of mine. And if any of such children of my deceased beneficiary dies before receiving the whole of his or her share of my estate, that share or the amount remaining thereof will be divided in equal shares amongst the surviving children of that beneficiary of mine. </p>
: null}

{benificiaryForAssetsOpt === 'An alternate named beneficiary' ?
<div style={{ backgroundColor: divname !== 'div28' ? '#fff': '#dfdee5'}}>
<span>14.&nbsp;&nbsp;&nbsp;If any such beneficiary of mine dies before becoming entitled in accordance with the terms of this Will, I direct that the assets and properties of my estate be divided </span>
<span> in the following manner:</span>
<span>a.&nbsp;&nbsp;&nbsp; The assets and properties of my estate </span><span   > will be distributed to the following beneficiary: 
<span><span style={{ backgroundColor: divname !== 'div24' ? '#fff': 'yellow'}}>{alternateBenificiary === '' ? '________' : alternateBenificiary}</span></span></span>
</div>
: null}

{benificiaryForAssetsOpt === 'Multiple named beneficiaries in equal shares' ? 
<div style={{ backgroundColor: divname !== 'div28' ? '#fff': '#dfdee5'}}>
<span>14.&nbsp;&nbsp;&nbsp;If any such beneficiary of mine dies before becoming entitled in accordance with the terms of this Will, I direct that the assets and properties of my estate be divided </span>
<span>in the following manner:</span>
<p>a.&nbsp;&nbsp;&nbsp;All assets and properties will be distributed to the following beneficiaries in equal shares: </p>
<span style={{ backgroundColor: divname !== 'div25' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{multipleAlternateBenificiary === '' ? '________' : multipleAlternateBenificiary}</span>
</div>: null}

{benificiaryForAssetsOpt === 'Multiple named beneficiaries in unequal shares' ? 
<div style={{ backgroundColor: divname !== 'div28' ? '#fff': '#dfdee5'}}>
<span>14.&nbsp;&nbsp;&nbsp;If any such beneficiary of mine dies before becoming entitled in accordance with the terms of this Will, I direct that the assets and properties of my estate be divided </span>
<span>in the following manner:</span>
<p>a.&nbsp;&nbsp;&nbsp;All assets and properties will be distributed to the following beneficiaries in equal shares: </p>
<span  style={{ backgroundColor: divname !== 'div26' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{multipleAlternateBenificiaryUnequal === '' ? '________' : multipleAlternateBenificiaryUnequal}</span>
<p>b.&nbsp;&nbsp;&nbsp;My beneficiaries will receive the assets and properties  according to the following description:</p>
<span  style={{ backgroundColor: divname !== 'div26' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{multipleAlternateBenificiaryUnequalShare  === '' ? '________' : multipleAlternateBenificiaryUnequalShare}</span>
{bankAccount === 'No' && bankLocker === 'No' && pf==='No' && fixedDeposits==='No' ? null :
<p>17.&nbsp;&nbsp;&nbsp;As regards <span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{bankAccount==='No' ? null : 'the Bank Accounts, '}</span><span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{fixedDeposits==='No' ? null : 'the Fixed Deposits, '}</span><span style={{ backgroundColor: divname !== 'div37' ? '#fff': 'yellow'}}>{bankLocker==='No' ? null : 'the assets in my bank lockers, '}</span>and <span style={{ backgroundColor: divname !== 'div38' ? '#fff': 'yellow'}}>{pf==='No' ? null : 'my public provident fund account, '}</span>the same shall be bequeathed to the members of family already nominated in the accounts. </p>
}
</div>: null}

<p>15.&nbsp;&nbsp;&nbsp;As regards the Bank Accounts, the Fixed Deposits, the assets in my bank lockers, and my public provident fund account, the same shall be bequeathed to the members of family already nominated in the accounts. </p>
</div>
: null}

{assetDistributionType === 'Asset Distribution' && assetDistribution === 'All to one named beneficiary' ?
<div style={{ backgroundColor: divname !== 'div22' ? '#fff': '#dfdee5'}}>
<p>6.&nbsp;&nbsp;&nbsp;I direct that the assets and properties of my estate be divided  in the following manner:</p>
<p>a.&nbsp;&nbsp;&nbsp;The assets and properties of my estate  will be distributed to the following beneficiary: <span class="variable_vide">________</span></p>
<p>7.&nbsp;&nbsp;&nbsp;If any such beneficiary of mine dies before becoming entitled in accordance with the terms of this Will, I direct that the assets and properties of my estate be divided  in the following manner:</p>
<p>a.&nbsp;&nbsp;&nbsp;The assets and properties of my estate  will be distributed to the following beneficiary: <span style={{ backgroundColor: divname !== 'div28' ? '#fff': 'yellow'}}>{benificiaryForAssets==='' ? '_________________' : benificiaryForAssets}</span></p>
{bankAccount === 'No' && bankLocker === 'No' && pf==='No' && fixedDeposits==='No' ? null :
<p>17.&nbsp;&nbsp;&nbsp;As regards <span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{bankAccount==='No' ? null : 'the Bank Accounts, '}</span><span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{fixedDeposits==='No' ? null : 'the Fixed Deposits, '}</span><span style={{ backgroundColor: divname !== 'div37' ? '#fff': 'yellow'}}>{bankLocker==='No' ? null : 'the assets in my bank lockers, '}</span>and <span style={{ backgroundColor: divname !== 'div38' ? '#fff': 'yellow'}}>{pf==='No' ? null : 'my public provident fund account, '}</span>the same shall be bequeathed to the members of family already nominated in the accounts. </p>
}
</div>: null}

{assetDistributionType === 'Asset Distribution' && assetDistribution === 'Divided between multiple named beneficiaries in equal shares' ?
<div style={{ backgroundColor: divname !== 'div22' ? '#fff': '#dfdee5'}}>
<p>13.&nbsp;&nbsp;&nbsp;I direct that the assets and properties of my estate be divided  in the following manner:</p>
<p>a.&nbsp;&nbsp;&nbsp;The assets and properties of my estate  will be distributed to the following beneficiary: <span id="span_id_one_beneficiary"  >
<span style={{ backgroundColor: divname !== 'div30' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{divideMultipleAlternateBenificiaryequalShare === '' ? '________' : divideMultipleAlternateBenificiaryequalShare} </span></span> </p>
<p>14.&nbsp;&nbsp;&nbsp;If any such beneficiary of mine dies before becoming entitled in accordance with the terms of this Will to receive of his or her share of my estate, I direct that such share or the amount remaining of that share will be divided and transferred in equal shares to each of the surviving children of that deceased beneficiary of mine. And if any of such children of my deceased beneficiary dies before receiving the whole of his or her share of my estate, that share or the amount remaining thereof will be divided in equal shares amongst the surviving children of that beneficiary of mine. </p>
{bankAccount === 'No' && bankLocker === 'No' && pf==='No' && fixedDeposits==='No' ? null :
<p>17.&nbsp;&nbsp;&nbsp;As regards <span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{bankAccount==='No' ? null : 'the Bank Accounts, '}</span><span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{fixedDeposits==='No' ? null : 'the Fixed Deposits, '}</span><span style={{ backgroundColor: divname !== 'div37' ? '#fff': 'yellow'}}>{bankLocker==='No' ? null : 'the assets in my bank lockers, '}</span>and <span style={{ backgroundColor: divname !== 'div38' ? '#fff': 'yellow'}}>{pf==='No' ? null : 'my public provident fund account, '}</span>the same shall be bequeathed to the members of family already nominated in the accounts. </p>
}
</div>: null}

{assetDistributionType === 'Asset Distribution' && assetDistribution === 'Divided between multiple named beneficiaries in unequal shares' ?
<div style={{ backgroundColor: divname !== 'div22' ? '#fff': '#dfdee5'}}>
<p>13.&nbsp;&nbsp;&nbsp;I direct that the assets and properties of my estate be divided  in the following manner:</p>
<p>a.&nbsp;&nbsp;&nbsp;The assets and properties of my estate  will be distributed to the following beneficiary: 
<span  ><span  style={{ backgroundColor: divname !== 'div31' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{divideMultipleAlternateBenificiaryUnequal === '' ? '________' : divideMultipleAlternateBenificiaryUnequal} </span></span> </p>
<p>b.&nbsp;&nbsp;&nbsp;My beneficiaries will receive shares of my estate according to the following description:
<span ><span  style={{ backgroundColor: divname !== 'div31' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{divideMultipleAlternateBenificiaryUnequalShare=== '' ? '________' : divideMultipleAlternateBenificiaryUnequalShare} </span></span> </p>
<p>14.&nbsp;&nbsp;&nbsp;If any such beneficiary of mine dies before becoming entitled in accordance with the terms of this Will to receive of his or her share of my estate, I direct that such share or the amount remaining of that share will be divided and transferred in equal shares to each of the surviving children of that deceased beneficiary of mine. And if any of such children of my deceased beneficiary dies before receiving the whole of his or her share of my estate, that share or the amount remaining thereof will be divided in equal shares amongst the surviving children of that beneficiary of mine. </p>
{bankAccount === 'No' && bankLocker === 'No' && pf==='No' && fixedDeposits==='No' ? null :
<p>17.&nbsp;&nbsp;&nbsp;As regards <span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{bankAccount==='No' ? null : 'the Bank Accounts, '}</span><span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{fixedDeposits==='No' ? null : 'the Fixed Deposits, '}</span><span style={{ backgroundColor: divname !== 'div37' ? '#fff': 'yellow'}}>{bankLocker==='No' ? null : 'the assets in my bank lockers, '}</span>and <span style={{ backgroundColor: divname !== 'div38' ? '#fff': 'yellow'}}>{pf==='No' ? null : 'my public provident fund account, '}</span>the same shall be bequeathed to the members of family already nominated in the accounts. </p>
}
</div>: null}

{assetDistributionType === 'Asset Distribution' && assetDistribution === 'In an alternate method of distribution' ?
<div style={{ backgroundColor: divname !== 'div22' ? '#fff': '#dfdee5'}}>
<p>a.&nbsp;&nbsp;&nbsp;The assets and properties of my estate  will be distributed to the following beneficiaries
<span style={{ backgroundColor: divname !== 'div32' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}> {descriptionWillInherit=== '' ? '________' : descriptionWillInherit}</span></p>
{bankAccount === 'No' && bankLocker === 'No' && pf==='No' && fixedDeposits==='No' ? null :
<p>17.&nbsp;&nbsp;&nbsp;As regards <span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{bankAccount==='No' ? null : 'the Bank Accounts, '}</span>
<span style={{ backgroundColor: divname !== 'div36' ? '#fff': 'yellow'}}>{fixedDeposits==='No' ? null : 'the Fixed Deposits, '}</span><span style={{ backgroundColor: divname !== 'div37' ? '#fff': 'yellow'}}>{bankLocker==='No' ? null : 'the assets in my bank lockers, '}</span>and <span style={{ backgroundColor: divname !== 'div38' ? '#fff': 'yellow'}}>{pf==='No' ? null : 'my public provident fund account, '}</span>the same shall be bequeathed to the members of family already nominated in the accounts. </p>
}
</div>: null}   
</div>

<div ref={ref7}>
{testatorGifts === '' ? null :
<div >
<p><strong>Specific Bequests</strong></p>
<p>
<p>22.&nbsp;&nbsp;&nbsp; To receive a specific bequest under this Will, a beneficiary must survive me. Any item that fails to pass to a beneficiary will return to my estate to be included in the residue of my estate. All property given under this Will is subject to any encumbrances or liens attached to the property. </p>
<p>23.&nbsp;&nbsp;&nbsp; My specific bequests are as follows: </p>
<p><span style={{ backgroundColor: divname !== 'div39' ? '#fff': 'yellow',whiteSpace: 'pre-wrap' }}> 
            {testatorGifts === '' ? '________' : testatorGifts}
</span></p></p>
<p>24.&nbsp;&nbsp;&nbsp;In cases where a child or grandchild of mine is a minor at the time that a gift is provided under my Will, and where a guardian, custodian or trustee is not already identified under my Will, that share shall be held by 
<span  style={{ backgroundColor: divname !== 'div40' ? '#fff': 'yellow'}}> {personManagingEstate ==='' ? '___________' : personManagingEstate}</span> in trust for each of those surviving children who is a minor child.
</p>
</div>
}
</div>

<div ref={ref8}>
{specialInstructions === 'Yes' ?
<div style={{ backgroundColor: divname !== 'div41' ? '#fff': '#dfdee5'}}>
<p><strong>Special Instructions</strong></p>
<p>I give the following additional instructions:</p>
<p><span ><span style={{ backgroundColor: divname !== 'div42' ? '#fff': 'yellow',whiteSpace: 'pre-wrap'}}>{specialInstructionDetails === '' ? '________' : specialInstructionDetails}</span></span></p>
</div>
: null
}


{testatorAlternate === '' ? null :
<p>
<p><strong>Wipeout Clause</strong></p>
<p>25.&nbsp;&nbsp;&nbsp;Should all my above beneficiaries predecease me, or fail to survive me for thirty (30) full days and should I leave no children, child, grandchildren or grandchild surviving me, or should they all die before becoming entitled to receive the whole of their share of my estate, then any remaining residue of my estate to be distributed as follows:</p>
<p><span style={{ backgroundColor: divname !== 'div43' ? '#fff': 'yellow'}}> {testatorAlternate==='' ? '_________' : testatorAlternate}</span>. </p>
</p>
}

{testatorGuardian === '' ? null :
<div>
<p><strong>CHILDREN</strong></p>
<p><strong>Guardian for Minor and Dependent Children</strong></p>
<p>26.&nbsp;&nbsp;&nbsp; Should my spouse not survive me and should my minor or dependent children require a guardian to care for them, I appoint the following individual to be their guardian:</p>
<p>a.&nbsp;&nbsp;&nbsp;I appoint <span style={{ backgroundColor: divname !== 'div44' ? '#fff': 'yellow'}}>{testatorGuardian==='' ? '_________' : testatorGuardian}</span>
<span> of <span style={{ backgroundColor: divname !== 'div45' ? '#fff': 'yellow'}}>{testatorGuardianResidence ==='' ? '_________' : testatorGuardianResidence}</span></span> to be the sole guardian of all my minor children until they are at least 18 years of age.</p>
</div>
}
</div>

<div ref={ref9}>
{minorBeneficiaries === 'Yes' ?
<div>
<p><strong>TESTAMENTARY TRUST</strong></p>

<p><strong>Testamentary Trust For Minor Beneficiaries</strong></p>
<p style={{ backgroundColor: divname !== 'div46' ? '#fff': '#dfdee5'}}>18.&nbsp;&nbsp;&nbsp;It is my intent to create a testamentary trust (the "Testamentary Trust") for each minor beneficiary named in this Will. I name 
<span style={{ backgroundColor: divname !== 'div47' ? '#fff': 'yellow'}}>{appointTrusteeForMinor === 'the Executor (if applicable)' ? 'the Executor' : <span style={{ backgroundColor: divname !== 'div48' ? '#fff': 'yellow'}}> {minorTrusteeName === '' ? '________' : minorTrusteeName}</span>}</span> as trustee (the "Trustee") of all Testamentary Trust required in my Will. Any assets bequeathed, transferred, or gifted to a minor beneficiary named in my Will are to be held in a separate trust by the Trustee until that minor beneficiary reaches the designated age. Any property left by me to any minor beneficiary in my Will will be given to my Trustee to be managed until that minor reaches the age of 18 years.</p>

<p><strong>Trust Administration</strong></p>
<p>19.&nbsp;&nbsp;&nbsp;The Trustee will manage the Testamentary Trust as follows:</p>
<p>a.&nbsp;&nbsp;&nbsp;The assets and property will be managed for the benefit of the minor until the minor reaches the age set by me in the final distribution;</p>
<p>b.&nbsp;&nbsp;&nbsp;Upon the minor reaching the age set by me for final distribution, all property and asset remaining in the trust will be transferred to the minor beneficiary as quickly as possible; and</p>
<p>c.&nbsp;&nbsp;&nbsp;Until the minor beneficiary reaches the age set by me for final distribution, my Trustee will keep the assets of the trust invested and pay the whole or such part of the net income derived therefrom and any amount or amounts out of the capital that my Trustee may deem advisable to or for the support, health, maintenance, education, or benefit of that minor beneficiary.</p>
<p>20.&nbsp;&nbsp;&nbsp;The Trustee may, in the Trustee's discretion, invest and reinvest trust funds in any kind of real or personal property and any kind of investment, provided that the Trustee acts with the care, skill, prudence and diligence, considering all financial and economic considerations, that a prudent person acting in a similar capacity and familiar such matters would use. </p>
<p>21.&nbsp;&nbsp;&nbsp;No bond or other security of any kind will be required of any Trustee appointed under my Will. </p>

<p><strong>Trust Termination</strong></p>
<p>22.&nbsp;&nbsp;&nbsp;The Testamentary Trust will end after any of the following:</p>
<p>a.&nbsp;&nbsp;&nbsp;The minor beneficiary reaches the age set by me for final distribution;</p>
<p>b.&nbsp;&nbsp;&nbsp;The minor beneficiary dies; or</p>
<p>c.&nbsp;&nbsp;&nbsp;The assets of the trust are exhausted through distributions.</p>

<p><strong>General Trust Provision</strong></p>
<p><p>23.&nbsp;&nbsp;&nbsp;The expression "my Trustee" used throughout this Will includes either the singular of the plural, and the masculine or the feminine gender as appropriate wherever the fact or context so requires. </p>

<p><strong>Powers of Trustee</strong></p>
<p>24.&nbsp;&nbsp;&nbsp;To carry out the term of my Will, I give my Trustee the following powers to be used in his or her discretion in the management of a trust created hereunder, namely:</p>
<p>a.&nbsp;&nbsp;&nbsp;The power to make such expenditures as are necessary to carry out the purpose of the trust;</p>
<p>b.&nbsp;&nbsp;&nbsp;Subject to my express direction to the contrary, the power to sell, call in and convert into money any trust property, including real property, that my Trustee in his or her discretion deems advisable;</p>
<p>c.&nbsp;&nbsp;&nbsp;Subject to my express direction to the contrary, the power to mortgage trust property where my Trustee considers it advisable to do so;</p>
<p>d.&nbsp;&nbsp;&nbsp;Subject to my express direction to the contrary, the power to borrow money where my Trustee considers it advisable to do so;</p>
<p>e.&nbsp;&nbsp;&nbsp;Subject to my express direction to the contrary, the power to lend money to the trust beneficiary if my Trustee considers it is in the best interest of the beneficiary to do so;</p>
<p>f.&nbsp;&nbsp;&nbsp;To make expenditures for the purpose of repairing, improving and rebuilding any property;</p>
<p>g.&nbsp;&nbsp;&nbsp;To exercise all rights and options of an owner of any securities held in trust;</p>
<p>h.&nbsp;&nbsp;&nbsp;To lease trust property, including real estate, without being limited as to term;</p>
<p>i.&nbsp;&nbsp;&nbsp;To make investments he or she consider advisable, without being limited to those investments authorized by law for trustees;</p>
<p>j.&nbsp;&nbsp;&nbsp;To receive additional property from any source and in any form of ownership;</p>
<p>k.&nbsp;&nbsp;&nbsp;Instead of acting personally, to employ and pay any other person or persons, including a body corporate, to transact any business or to do any act of any nature in relation to a trust created under my Will including the receipt and payment of money, without being liable for any loss incurred. And I authorize my Trustee to appoint from time to time upon such terms as he or she may think fit for any person or persons, any body corporate, for the purpose of exercising any powers herein expressly or impliedly given to my Trustee with respect to any property belonging to the trust;</p>
<p>l.&nbsp;&nbsp;&nbsp;Without the consent of any persons interested in trusts established hereunder, to compromise, settle or waive any claim or claims at any time due to tor by the trust in such manner and to such extent as my Trustee considers to be in the best interest pf the trust beneficiary, and to make an agreement with any other person, persons or corporation in respect thereof, which will be binding upon such beneficiary;</p>
<p>m.&nbsp;&nbsp;&nbsp;To make or not to make any election, determination, designation or allocation required or permitted to be made by my Trustee (either alone or jointly with others) under any of the provisions of any municipal, provincial/territorial, federal or other taxing statute, in such manner as my Trustee, in his or her absolute discretion, deems advisable, and each election, determination, designation or allocation when so made will be final and binding upon all persons concerned;</p>
<p>n.&nbsp;&nbsp;&nbsp;To pay himself or herself a reasonable compensation out of the trust assets; and </p>
<p>o.&nbsp;&nbsp;&nbsp;To employ and rely on the advice being given by an attorney, accountant, investement advisor, or other agent to assist the Trustee in the administration of this trust and to compensate them from the trust assets.</p>
<p>The above authority and powers granted to my Trustee are in addition to any powers and elective rights conferred by statue or federal or by any other provision of this Will and my be exercised as often as required, and without application to or approval by any court. </p>

<p><strong>Other provisions</strong></p>
<p>25.&nbsp;&nbsp;&nbsp;Subject to the terms of my Will, I direct that my Trustee will not be liable for any loss to my estate or to any beneficiary resulting from the exercise by him or her in good faith of any discretion given him or her in this Will;</p>
<p>26.&nbsp;&nbsp;&nbsp;Any trust created in this Will will be administered as independently of court supervision as possible under the laws of the Province or Territory having jurisdiction over the trust; and</p>
<p>27.&nbsp;&nbsp;&nbsp;If any trust condition is held invalid, it will not affect other provisions that can be given effect without the invalid provision.</p>
</p>
</div>
: null
}
</div>



<p><strong>Insufficient Estate</strong></p>
<p><p><p style={{paddingBottom:'20px'}}>28.&nbsp;&nbsp;&nbsp;If the value of my estate is insufficient to fulfill all of the bequests described in this Will, then I give my Executor the authority to decrease each bequest by a proportionate amount. </p>
</p>
</p>

<p><strong>Severability</strong></p>
<p>29.&nbsp;&nbsp;&nbsp;If any provisions of this Will are deemed unenforceable, the remaining provisions will remain in full force and effect. </p>

<div ref={ref10}>
{testatorDeath === 'Yes' ? 
<div style={{ backgroundColor: divname !== 'div49' ? '#fff': '#dfdee5'}}>
<p><strong>DISPOSITION OF REMAINS </strong></p>
<p>30.&nbsp;&nbsp;&nbsp;It is my wish that my remains be <span style={{ backgroundColor: divname !== 'div50' ? '#fff': 'yellow'}}>{testatorDeathOption=== '' ? '________' : testatorDeathOption}</span>.</p>
{instructions !== '' ? 
<p>30.&nbsp;&nbsp;&nbsp;I also wish the following in regards to my remains:
<span style={{ backgroundColor: divname !== 'div50' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>{instructions}</span></p>
: null}
</div>
: null
}
</div>


<p>I have executed this will in complete possession of my mental faculties and without any undue pressure or influence of any kind whatsoever from any quarter.</p>
<p><strong>IN WITNESS WHEREOF</strong>,<p style={{paddingBottom:'20px'}}> I have signed my name on this _______ (day) day of __________ (month), _______ (year) at_________________ (city), _________________ (state), declaring and publishing this instrument as my Last Will, in the presence of the undersigned witnesses, who witnessed and subscribed this Last Will at my request, and in my presence. </p>,<p style={{paddingBottom:'20px'}}>_____________________ </p>(Testator's Name)</p>
<p><p style={{paddingBottom:'20px'}}> ____________________ </p><p style={{paddingBottom:'20px'}}> (Testators Signature)</p><p style={{paddingBottom:'20px'}}> </p></p>
<p><p style={{paddingBottom:'20px'}}>SIGNED AND DECLARED by <span  >________</span> on this _______ (day) day of __________ (month), _______ (year) to be the Testator's Last Will, in our presence, at _________________ (city), _________________ (state) in the presence of us, both present at the same time, who at the Testator's request and in the presence of the Testator and of each other, all being present at the same time, have signed our names as witnesses.</p></p>
<p style={{paddingBottom:'20px'}}>WITNESS:<p style={{paddingBottom:'20px'}}>______________________ </p><p style={{paddingBottom:'20px'}}>Name:</p>Address:<p style={{paddingBottom:'20px'}}>City:</p><p style={{paddingBottom:'20px'}}>Occupation:</p></p>
<p style={{paddingBottom:'20px'}}>WITNESS:<p style={{paddingBottom:'20px'}}>_______________________ </p><p style={{paddingBottom:'20px'}}>Name:</p><p style={{paddingBottom:'20px'}}>Address:</p><p style={{paddingBottom:'20px'}}>City:</p><p style={{paddingBottom:'20px'}}>Occupation:</p></p>				
</div>
              )
    }
    return(
    <div >
    <div className='row'>
    <div className='col-xl-12 col-md-12 col-lg-12'>
    <HelpSection heading="HELP TIPS!">
    <List>
        The first step in making a Will is accurate information. Talk to us if you ever have a doubt.
   </List>
   <List>
        <HighlightedText>Create a Draft Will first -- Review with our Lawyers -- Print, Sign and Witness the final -- Register -- Keep it Safe </HighlightedText>
    </List>
     </HelpSection>
     </div>
     </div>
<div style={{paddingBottom:'50px'}} className='row'>
<div className='col-sm-3'>				
    <div style={{ backgroundColor:'#fff',paddingLeft:'10px', paddingRight:'10px',  paddingTop:'20px',height:500}}>
            {question === 0 ? 
            <div>    
                 <label>
                    <b>Where would you sign the Will ?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setPlace}
                    placeholder="E.g. Nagpur, Maharashtra" 
                  />
                    <label>
                    <b>When do you plan to sign the will ?</b>
                    </label>
                    <Input 
                    noClass
                    isBigger 
                    type='date' 
                    bind={setWillDate} 
                    placeholder="mm-dd-yyyy" 
                    />
                    <label>
                    <b>And lastly, your full legal name.</b>
                    </label>
                    <Input
                        noClass
                        type='text'
                        className='form-control form-control-lg'
                        bind={setNameOfUser}
                        placeholder="E.g. Manik Chand, Sarita Verma" 
                    />


                    <ContainerPosition>
                        <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    </ContainerPosition>

                    <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>The first step to a valid Will is correct personal information.</li>
                        </p>
                    </div>


            </div>
            : null}

            {question === 1 ? 
            <div>
                    {scroll(ref1)}
                    {isNaN(userProfileId) ?
                    <div>
                    <label>
                    <b>What's your e-mail ?</b>
                    </label>
                    <Input
                        noClass
                        type='text'
                        className='form-control form-control-lg'
                        bind={setEmail}
                        placeholder="E.g. Manik@gmail.com" 
                    />
                    <label>
                    <b>What is your Phone Number ?</b>
                    </label>
                    <Input
                        noClass
                        type='text'
                        className='form-control form-control-lg'
                        bind={setPhoneNum}
                        placeholder="E.g. Enter 10 digit number" 
                    />
                    </div>
                    : null
                    }
                    <label> 
                        <b>What is your gender ?</b>
                    </label>
                    <div className='row' style={{ marginLeft: 5 }}>
                        <div className='form-check form-check-inline'>
                        <label className='form-check-label'>
                            <input
                            className='form-check-input'
                            type='radio'
                            checked={gender === 'Male'}
                            name={'inlineRadiogender'}
                            id={'inlineRadiogender'}
                            value={'Male'}
                            onChange={(event) => setGender(event.target.value)}
                            />
                            Male
                        </label>
                        </div>
                        <div className='form-check form-check-inline'>
                        <label className='form-check-label'>
                            <input
                            className='form-check-input'
                            type='radio'
                            checked={gender === 'Female'}
                            name={'inlineRadiogender'}
                            id={'inlineRadiogender'}
                            value={'Female'}
                            onChange={(event) => setGender(event.target.value)}
                            />
                            Female
                        </label>
                        </div>
                    </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Succession laws have gender specific provisions. Watch how it changes the text in the Will.</li>
                        </p>
                    </div>
            </div>             
            : null}

            {question === 2 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>What is your father's name ?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setFatherName}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                   <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Strange as they sound, the law tries to identify you as accurately as possible.</li>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Family information is important when determining succession of ancestral property. 
                                            Make sure you have provided full and accurate info.</li>
                        </p>
                    </div>



            </div> : null}

            {question === 3 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>Are you currently married ?</b>
                </label>
                  <DropDown
                    noClass
                    placeholder='Please Select'
                    options={maritalStatusOpt}
                    bind={setMaritalStatus}
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Marital status matters. For example, by law members of certain religious communities need to re-write their wills if they change their marital status.</li>
                            <div align= 'center'><Button onClick={appointment} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'50%' }}>
                            {'Talk to our lawyer'}
                            </Button></div>
                            <br></br>
                        </p>
                    </div>


            </div> : null}

            {question === 4 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>What is your date of birth ?</b>
                  </label>
                    <Input 
                    noClass
                    isBigger 
                    type='date' 
                    bind={setAge} 
                    placeholder="mm-dd-yyyy" 
                    />
                <label>
                    <b>What is your address ?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setAddress}
                    placeholder="E.g. No.6 Bangalore 560067" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Build your INHERITANCE PORTFOLIO to keep all information in one easy location.</li>
                            <br></br>
                        </p>
                    </div>
            </div> 
            
            : null}

            {question === 5 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>What is your Religion ?</b>
                </label>
                  <DropDown
                    noClass
                    placeholder='Please Select'
                    options={getCodeValueMap(['Hindu', 'Buddhist', 'Sikh', 'Jain', 'Christian', 'Jewish', 'Parsi', 'Muslim', 'Other'])}
                    bind={setReligion}
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>It's a sensitive topic for some. But the law handles matters differently for each faith.</li>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>If you wish to know more or have a situation, use our <b>FREE</b> legal consult.</li>
                            <div align= 'center'><Button onClick={appointment} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'50%' }}>
                            {'Talk to our lawyer'}
                            </Button></div>
                        </p>
                    </div>
                
            </div> : null}

            {question === 6 ?
            <div>
                {scroll(ref1)}
                <label> 
                    <b>Did you ever sign and register any will(s) previously ?</b>
                  </label>
                  <div className='row' style={{ marginLeft: 5 }}>
                    <div className='form-check form-check-inline'>
                      <label className='form-check-label'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={testatorRegister === 'Yes'}
                          name={'inlineRadiotestator'}
                          id={'inlineRadiotestator'}
                          value={'Yes'}
                          onChange={(event) => setTestatorRegister(event.target.value)}
                        />
                        Yes
                      </label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <label className='form-check-label'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={testatorRegister === 'No'}
                          name={'inlineRadiotestator'}
                          id={'inlineRadiotestator'}
                          value={'No'}
                          onChange={(event) => setTestatorRegister(event.target.value)}
                        />
                        No
                      </label>
                    </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => next2Question()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>The latest REGISTERED will supercedes earlier wills. However, this is also the most contested topic among beneficiaries.</li>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>To avoid future legal challenges, use our legal consult. Your FIRST HALF-HOUR is <b>FREE</b>.</li>
                        </p>
                    </div>
            </div> : null}

            {question === 7 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>What is the date of the earlier will which was registered with the Sub-registrar?</b>
                    </label>
                    <Input 
                    noClass
                    isBigger 
                    type='date' 
                    bind={setSubRegistrarDate} 
                    placeholder="mm-dd-yyyy" 
                    />

                    <label>
                    <b>What are the registration details of the earlier will which was registered with the Sub-registrar?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setRegistrationDetails}
                    placeholder="E.g. 1234/12" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

            </div> 
                   
            : null}

            {question === 8 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>When was the earlier will registered at the Sub-Registrar's office?</b>
                    </label>
                    <Input 
                    noClass
                    isBigger 
                    type='date' 
                    bind={setEarlierDateOfRegistration} 
                    placeholder="mm-dd-yyyy" 
                  />
             <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 9 ?
            <div>
                {scroll(ref1)}
                {maritalStatus ==='single' ?
                <div>
                <label>
                    <b>Do you have children ?</b>
                </label>
                <DropDown
                    noClass
                    placeholder='Please Select'
                    options={numChildrens}
                    bind={setchildNumber}
                />
                </div>
                : null}
                <label> 
                <b>We need to know a bit about your parents. Do you have a surviving father ?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={survivingFather === 'Yes'}
                    name={'inlineRadioSurvivingFather'}
                    id={'inlineRadioSurvivingFather'}
                    value={'Yes'}
                    onChange={(event) => setSurvivingFather(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={survivingFather === 'No'}
                    name={'inlineRadioSurvivingFather'}
                    id={'inlineRadioSurvivingFather'}
                    value={'No'}
                    onChange={(event) => setSurvivingFather(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previous2Question()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 10 ?
            <div>
                {scroll(ref1)}
                <label> 
                <b>Do you have a surviving mother?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={survivingMother === 'Yes'}
                    name={'inlineRadioSurvivingMother'}
                    id={'inlineRadioSurvivingMother'}
                    value={'Yes'}
                    onChange={(event) => setSurvivingMother(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={survivingMother === 'No'}
                    name={'inlineRadioSurvivingMother'}
                    id={'inlineRadioSurvivingMother'}
                    value={'No'}
                    onChange={(event) => setSurvivingMother(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 11 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>What is the name of your spouse ?</b>
                </label>
                <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setSpouseName}
                    placeholder="E.g. Priya Khanna" 
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Immediate family are usually the beneficiaries of your will. Accurate information helps them through the Succession process easily.</li>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Did you notice we are gradually building up your <b>Inheritance Portfolio</b>? Your info is safe with us for your beneficiaries when they need it.</li>
                        </p>
                    </div>


            </div> : null}

            {question === 12 ?
            <div>
                {scroll(ref2)}
                <label>
                    <b>What is the name of your {childNum} child?</b>
                </label>
                <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    onChange={(event) => setCurrentChildName(event.target.value)}
                    bind={setChildName}
                    placeholder="E.g. Priya Khanna" 
                />
                <label>
                    <b>Where does your {childNum} child live?</b>
                </label>
                <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    onChange={(event) => setCurrentChildAddress(event.target.value)}
                    bind={setChildAddress}
                    placeholder="E.g. No. 6 Bangalore" 
                />
                <label>
                    <b>What is your {childNum} childs Date of Birth?</b>
                </label>
                <Input 
                    noClass
                    isBigger 
                    type='date' 
                    onChange={(event) => setCurrentChildDob(event.target.value)}
                    bind={setChildDob}
                    placeholder="mm-dd-yyyy" 
                    />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Pay special attention if any of your children are <b>minors</b>( i.e below 18 yrs of age ).</li>
                        </p>
            </div>

                
            </div> : null}

            {question === 13 ?
            <div>
                {scroll(ref1)}
                 <label>
                    <b>What is your mother's name?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setMotherName}
                    placeholder="E.g. Waheeda Khan" 
                  />
                   <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousMaritalStatus()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 14 ?
            <div>
                {scroll(ref3)}
                <label> 
                <b>Do you wish to appoint an executor for the will? </b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                        <div className='form-check form-check-inline'>
                        <label className='form-check-label'>
                            <input
                            className='form-check-input'
                            type='radio'
                            checked={executor === 'Yes'}
                            name={'inlineRadioExecutor'}
                            id={'inlineRadioExecutor'}
                            value={'Yes'}
                            onChange={(event) => setExecutor(event.target.value)}
                            />
                            Yes
                        </label>
                        </div>
                        <div className='form-check form-check-inline'>
                        <label className='form-check-label'>
                            <input
                            className='form-check-input'
                            type='radio'
                            checked={executor === 'No'}
                            name={'inlineRadioExecutor'}
                            id={'inlineRadioExecutor'}
                            value={'No'}
                            onChange={(event) => setExecutor(event.target.value)}
                            />
                            No
                        </label>
                        </div>
                </div>
                
                {executor === 'Yes' ?
                <div>
                  <label>
                  <b>Choose an Executor</b>
                </label>
                 <DropDown
                 noClass
                 placeholder='Please Select'
                 options={executorTypeOpt}
                 bind={setExecutorType}
               />
               </div>
                : null}
                <ContainerPosition>
                    <CustomButtom event={() => nextExecutor()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>The executor is a person who will administer the Will. It can be anyone you trust, but executors have a lot of RESPONSIBILITY like paying any <b>debts</b> and <b>taxes</b>.</li>
                        </p>
            </div>
            </div> 
            
            : null}

            {question === 15 ?
            <div>
                 {scroll(ref4)}

                {executorType === 'Other' ?
                <div>
                <label>
                    <b>Name of the executor. It has to be someone you can trust and someone willing to take the responsibility.</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setExecutorName}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                  </div>
                  :
                  executorType === 'Family'?
                  
                  <div>
                   <label>
                    <b>72% of our users choose family. Fill Family details in the Inheritance Portfolio to choose here.</b>
                  </label>
                       <thead>
                            <tr>
                            <th data-field="CheckBox">&nbsp;&nbsp;</th>
                            <th data-field="Name"><font size='2'>Name and&nbsp;</font></th>
                            <th data-field="Relationship"><font size='2'>Relationship</font></th>
                            </tr>
                        </thead>
                      {familyListExecutor.map((f, i) =>
                      <div>
                        <tr key={i} className="col-xl-3 col-md-6 col-lg-4 col-xs-12">
                            <td data-field="CheckBox"><input type="checkbox"
                            checked={_.includes(selectedIds, f.id)}
                            onChange={(event) => {
                                if (event.target.checked) {
                                bindSelected([f.id])
                                } else {
                                bindSelected(selectedIds.filter(id => id !== f.id))
                                }
                            }}
                            />&nbsp;&nbsp;
                            </td>
                            <td data-field="Name">{getFullName(f)},</td>
                            <td data-field="Relationship">{relationshipMapping[f.relationship]}</td>
                        </tr>
                    </div>
                      )
                    }
                  </div>
                   : 
                   executorType === 'Contact'?
                   <div>
                  <label>
                    <b>25% of our users choose trusted friends. Don't see a name? Fill Other Contacts in the Inheritance Portfolio.</b>
                  </label>
                   <thead>
                        <tr>
                        <th data-field="CheckBox"></th>
                        <th data-field="Name"><font size='2'>Name and&nbsp;</font></th>
                        <th data-field="Relationship"><font size='2'>Relationship</font></th>
                        </tr>
                    </thead>
                  {contactListExecutor.map((f, i) =>
                  <div>
                    <tr key={i} className="col-xl-3 col-md-6 col-lg-4 col-xs-12">
                        <td data-field="CheckBox"><input type="checkbox"
                        checked={_.includes(selectedIds, f.id)}
                        onChange={(event) => {
                            if (event.target.checked) {
                            bindSelected([f.id])
                            } else {
                            bindSelected(selectedIds.filter(id => id !== f.id))
                            }
                        }}
                        />&nbsp;&nbsp;
                        </td>
                        <td data-field="Name">{getFullName(f)},</td>
                        <td data-field="Relationship">{relationshipMapping[f.relationship]}</td>
                    </tr>
                </div>
                  )
                }
              </div>
                   :
                   null
            }
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Choose wisely. If you have a running LOAN, your Executor will have to negotiate its closure to free up your assets.</li>
                        </p>
                    </div>
            </div> : null}

            {question === 16 ?
            <div>
                {scroll(ref3)}      
                <label>
                    <b>Relationship of the executor in regards the testator.</b>
                </label>
                    <DropDown
                    noClass
                    placeholder='Please Select'
                    options={relationOptions}
                    bind={setExecutorRelationship}
                  />
                <label>
                    <b>What is the residential address of the executor?</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setExecutorAddress}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Do you know you can appoint a THIRD-PARTY ( like a legal firm ) as your Executor ? Talk to us.</li>
                            <div align= 'center'><Button onClick={appointment} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'50%' }}>
                            {'Talk to our lawyer'}
                            </Button></div>
                        </p>
                 </div>
            </div> : null}

            {question === 17 ?
            <div>
                 {scroll(ref3)}
                <label> 
                    <b>Do you wish to appoint any alternate executor ? </b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                        <div className='form-check form-check-inline'>
                        <label className='form-check-label'>
                            <input
                            className='form-check-input'
                            type='radio'
                            checked={alternateExecutor === 'Yes'}
                            name={'inlineRadioAlternateExecutor'}
                            id={'inlineRadioAlternateExecutor'}
                            value={'Yes'}
                            onChange={(event) => setAlternateExecutor(event.target.value)}
                            />
                            Yes
                        </label>
                        </div>
                        <div className='form-check form-check-inline'>
                        <label className='form-check-label'>
                            <input
                            className='form-check-input'
                            type='radio'
                            checked={alternateExecutor === 'No'}
                            name={'inlineRadioAlternateExecutor'}
                            id={'inlineRadioAlternateExecutor'}
                            value={'No'}
                            onChange={(event) => setAlternateExecutor(event.target.value)}
                            />
                            No
                        </label>
                        </div>
                    </div>    
                {alternateExecutor === 'Yes' ?
                <div>
                  <label>
                  <b>Choose an Alternate Executor</b>
                </label>
                 <DropDown
                 noClass
                 placeholder='Please Select'
                 options={executorTypeOpt}
                 bind={setAlternateExecutorType}
               />
               </div>

                : null}

                <ContainerPosition>
                    <CustomButtom event={() => nextAlternateExecutor()} title={'Next'} />
                    <CustomButtom event={() => previousExeQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Always have a Plan B.</li>
                        </p>
                 </div>
                
            </div> 
            
            : null}

            {question === 18 ?
            <div>
                 {scroll(ref3)}
                 {alternateExecutorType === 'Other' ?
                 <div>
                <label>
                    <b>Name of the alternate executor.</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setAlternateExecutorName}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />

                <label>
                    <b>Your relationship to the alternate executor.</b>
                </label>
                <DropDown
                    noClass
                    placeholder='Please Select'
                    options={relationOptions}
                    bind={setAlternateExecutorRelationship}
                  />
                <label>
                    <b>What is the residential address of the alternate executor?</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setAlternateExecutorAddress}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                  </div>
                    :
                    alternateExecutorType === 'Family'?
                  <div>
                                     <label>
                    <b>35% of our users choose Family. Don't see a name? Fill Other Contacts in the Inheritance Portfolio.</b>
                  </label>
                       <thead>
                            <tr>
                            <th data-field="CheckBox"></th>
                            <th data-field="Name">Name and </th>
                            <th data-field="Relationship">Relationship</th>
                            </tr>
                        </thead>
                      {familyListExecutor.map((f, i) =>
                      <div>
                        <tr key={i} className="col-xl-3 col-md-6 col-lg-4 col-xs-12">
                            <td data-field="CheckBox"><input type="checkbox"
                            checked={_.includes(alternateSelectedIds, f.id)}
                            onChange={(event) => {
                                if (event.target.checked) {
                                bindAlternateSelectedIds([f.id])
                                } else {
                                bindAlternateSelectedIds(alternateSelectedIds.filter(id => id !== f.id))
                                }
                            }}
                            />
                            </td>
                            <td data-field="Name">{getFullName(f)},</td>
                            <td data-field="Relationship">{relationshipMapping[f.relationship]}</td>
                        </tr>
                    </div>
                      )
                    }
                  </div>
                   : 
                   alternateExecutorType === 'Contact'?
                   <div>
                                      <label>
                    <b>22% of our users trust friends. Don't see a name ? Fill Other Contacts in the Inheritance Portfolio.</b>
                  </label>
                   <thead>
                        <tr>
                        <th data-field="CheckBox"></th>
                        <th data-field="Name">Name and </th>
                        <th data-field="Relationship">Relationship</th>
                        </tr>
                    </thead>
                  {contactListExecutor.map((f, i) =>
                  <div>
                    <tr key={i} className="col-xl-3 col-md-6 col-lg-4 col-xs-12">
                        <td data-field="CheckBox"><input type="checkbox"
                        checked={_.includes(alternateSelectedIds, f.id)}
                        onChange={(event) => {
                            if (event.target.checked) {
                                bindAlternateSelectedIds([f.id])
                            } else {
                                bindAlternateSelectedIds(alternateSelectedIds.filter(id => id !== f.id))
                            }
                        }}
                        />
                        </td>
                        <td data-field="Name">{getFullName(f)},</td>
                        <td data-field="Relationship">{relationshipMapping[f.relationship]}</td>
                    </tr>
                </div>
                  )
                }
              </div>
                   :
                   null
            }
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Remember you can always appoint a THIRD-PARTY ( like a legal firm ) as your Executor ?</li>
                        </p>
                 </div>
            </div> 
            
            : null}

            {question === 19 ?
            <div>
                 {scroll(ref4)}
                <label>
                    <b>Do you wish to compensate the executor?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={executorCompensation === 'Yes'}
                    name={'inlineRadioExecutorCompensation'}
                    id={'inlineRadioExecutorCompensation'}
                    value={'Yes'}
                    onChange={(event) => setExecutorCompensation(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={executorCompensation === 'No'}
                    name={'inlineRadioExecutorCompensation'}
                    id={'inlineRadioExecutorCompensation'}
                    value={'No'}
                    onChange={(event) => setExecutorCompensation(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextExecutorCompensation()} title={'Next'} />
                    <CustomButtom event={() => previousAlternateExecutor()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>A lawyer or a law firm acting as your Executor would need compensation.</li>
                        </p>
                    </div>
                
            </div> : null}

            {question === 20 ?
            <div>
                 {scroll(ref4)}
                <label>
                    <b>(Optional) You can specify the compensation of the executor.</b>
                </label>
                <TextArea
                onkeypress
                bind={setExecutorCompensionDetails}
                placeholder="E.g. Rs. 10,000; 3% of the value of the entire estate" 
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 21 ?
            <div>
                 {scroll(ref6)}
                 <label>
                    <b>(Optional) Please list your assets and properties. If you do not want to specifically list all the assets and properties, this field can be left blank.</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                {/*<div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={listAssets === 'Pick from Asset Register'}
                    name={'inlineRadioListAssets'}
                    id={'inlineRadioListAssets'}
                    value={'Pick from Asset Register'}
                    onChange={(event) => setListAssets(event.target.value)}
                    />
                    Pick from Asset Register
                </label>
              </div>*/}
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={listAssets === 'Enter All Assets'}
                    name={'inlineRadioListAssets'}
                    id={'inlineRadioListAssets'}
                    value={'Enter All Assets'}
                    onChange={(event) => setListAssets(event.target.value)}
                    />
                    Enter All Assets
                </label>
                </div>
                </div>
                {listAssets === 'Enter All Assets'
                ?
                <div>
                <TextArea
                onkeypress
                placeholder="E.g. 1. Residential property situated at 12,Rajinder Nagar, New Delhi, India; 
                2. Shop bearing number A-5 situated at Vyapar Kendra, Gurgaon, Harayana; 
                3. Maruti Baleno car bearing registration number DL 3456."
                bind={setAssetDetails} 
                />
                </div>
                :
                _.isEmpty(userRole) 
                ?
                <p>Fill the Asset Register <span onClick={toggleLogin} style={{color:'#007bff',cursor:'pointer'}}>here</span></p>
                : null 
                }
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousExecutorCompensation()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 22 ?
            <div>
                 {scroll(ref6)}
                <label>
                    <b>How will your assets and properties be distributed?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                {/*<div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={assetDistributionType === 'Asset Distribution Tool'}
                    name={'inlineRadioAssetDistributionType'}
                    id={'inlineRadioAssetDistributionType'}
                    value={'Asset Distribution Tool'}
                    onChange={(event) => setAssetDistributionType(event.target.value)}
                    />
                    Asset Distribution Tool
                </label>
               </div>*/}
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={assetDistributionType === 'Asset Distribution'}
                    name={'inlineRadioAssetDistributionType'}
                    id={'inlineRadioAssetDistributionType'}
                    value={'Asset Distribution'}
                    onChange={(event) => setAssetDistributionType(event.target.value)}
                    />
                    Asset Distribution
                </label>
                </div>
                </div>
                {assetDistributionType === 'Asset Distribution' ?
                <div>
                  <DropDown
                    noClass
                    placeholder='Please Select'
                    options={assetDistributionOpt}
                    bind={setAssetDistribution}
                  />
                  </div>
                :
                <p>Distribution Tool <span onClick={toggleAssetDescriptionToo1} style={{color:'#007bff',cursor:'pointer'}}>here</span></p>
                }
                <ContainerPosition>
                    <CustomButtom event={() => nextAssetDistribution()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 23 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>In the event that your spouse does not survive you, who should receive the estate (i.e. assets and properties)?</b>
                </label>
                  <DropDown
                    noClass
                    placeholder='Please Select'
                    options={alternateAssetDistributionOpt}
                    bind={setAlternateAssetDistribution}
                  />
                 <ContainerPosition>
                    <CustomButtom event={() => nextAlternateAssetDistribution()} title={'Next'} />
                    <CustomButtom event={() => previousAssetDistribution()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 24 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Name of the alternate beneficiary.</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setAlternateBenificiary}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextAssetQuestions()} title={'Next'} />
                    <CustomButtom event={() => previousAlternateAssetDistribution()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Various Succession Acts in India deal with this differently. Talk to our lawyers for your specific case.</li>
                        </p>
                    </div>
            </div> : null}

            {question === 25 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Names of the alternate beneficiaries who will receive the assets and properties in equal parts.</b>
                </label>
                <TextArea
                onkeypress
                placeholder="Dev, Arati"
                bind={setMultipleAlternateBenificiary} 
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextAssetQuestions()} title={'Next'} />
                    <CustomButtom event={() => previousAlternateAssetDistribution()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 26 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Names of the alternate beneficiaries who will receive the assets and properties of the estate in unequal parts.</b>
                </label>
                <TextArea
                onkeypress
                placeholder="Dev, Arati"
                bind={setMultipleAlternateBenificiaryUnequal} 
                />
                <label>
                        <b>Description of how the assets and properties will be split between the multiple beneficiaries.</b>
                </label>
                    <TextArea
                    onkeypress
                    placeholder="Dev, Arati"
                    bind={setMultipleAlternateBenificiaryUnequalShare} 
                    />
                <ContainerPosition>
                    <CustomButtom event={() => nextAssetQuestions()} title={'Next'} />
                    <CustomButtom event={() => previousAlternateAssetDistribution()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 27 ?
                <div>
                    <label>
                    <b>If a named beneficiary predeceases the testator, should the children of the beneficiary receive their part?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={benPredeceases === 'Yes'}
                    name={'inlineRadioBenPredeceases'}
                    id={'inlineRadioBenPredeceases'}
                    value={'Yes'}
                    onChange={(event) => setBenPredeceases(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={benPredeceases === 'No'}
                    name={'inlineRadioBenPredeceases'}
                    id={'inlineRadioBenPredeceases'}
                    value={'No'}
                    onChange={(event) => setBenPredeceases(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextAssetQuestions()} title={'Next'} />
                    <CustomButtom event={() => previousBenQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 28 ?
                <div>
                    {scroll(ref6)}
                    <label>
                    <b>Name of the beneficiary of the assets and properties of the estate of the testator.</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setBenificiaryForAssets}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                <label>
                    <b>If the named beneficiary does not survive the testator, who should receive the assets and properties of the estate?</b>
                </label>
                <DropDown
                    noClass
                    placeholder='Please Select'
                    options={benificiaryOpt}
                    bind={setBenificiaryForAssetsOpt}
                />
                    <ContainerPosition>
                        <CustomButtom event={() => nextAlternateBenificiary()} title={'Next'} />
                        <CustomButtom event={() => previousAssetDistribution()} title={'Previous'} />
                    </ContainerPosition>
            </div> : null}

            {question === 29 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Is there any child or other person to be specifically excluded from the will?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={exculdePerson === 'Yes'}
                    name={'inlineRadioExculdePerson'}
                    id={'inlineRadioExculdePerson'}
                    value={'Yes'}
                    onChange={(event) => setExculdePerson(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={exculdePerson === 'No'}
                    name={'inlineRadioExculdePerson'}
                    id={'inlineRadioExculdePerson'}
                    value={'No'}
                    onChange={(event) => setExculdePerson(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextChildExcludeQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousAssetDistributions()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 30 ?
                <div>
                    {scroll(ref6)}
                    <label>
                        <b>Names of the beneficiaries who will receive equal shares of the assets and properties of the estate.</b>
                    </label>
                    <TextArea
                    onkeypress
                    placeholder="Dev, Arati"
                    bind={setDivideMultipleAlternateBenificiaryequalShare} 
                    />
                    <ContainerPosition>
                        <CustomButtom event={() => nextBenQuestion()} title={'Next'} />
                        <CustomButtom event={() => previousAssetDistribution()} title={'Previous'} />
                    </ContainerPosition>
            </div> : null}

            {question === 31 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Names of the beneficiaries who will inherit the assets and properties of the testator's estate.</b>
                </label>
                <TextArea
                onkeypress
                placeholder="Dev, Arati"
                bind={setDivideMultipleAlternateBenificiaryUnequal} 
                />
                <label>
                    <b>Description of how the assets and properties of the estate of the testator will be split between the multiple beneficiaries.</b>
                </label>
                    <TextArea
                    onkeypress
                    placeholder="Dev, Arati"
                    bind={setDivideMultipleAlternateBenificiaryUnequalShare} 
                    />
                <ContainerPosition>
                    <CustomButtom event={() => nextBenQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousAssetDistribution()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 32 ?
            <div>
                {scroll(ref6)}
                <label>
                     <b>Description of who will inherit the assets and properties of the testator's estate.</b>
                </label>
                    <TextArea
                    onkeypress
                    placeholder="Dev, Arati"
                    bind={setDescriptionWillInherit} 
                    />
                    <ContainerPosition>
                        <CustomButtom event={() => nextAssetQuestions()} title={'Next'} />
                        <CustomButtom event={() => previousAssetDistribution()} title={'Previous'} />
                    </ContainerPosition>
            </div> : null}


            {question === 33 ?
                <div>
                 {scroll(ref6)}
                <label>
                    <b>What is the name of the person who is to be excluded from inherting any assets or properties of the testator under the will?</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setExculdePersonName}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextExculdePersonQUestion()} title={'Next'} />
                    <CustomButtom event={() => PreviousChildExcludeQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}


            {question === 34 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>If all the children and grandchildren predecease the testator and leave no issue, who should receive the assets of the estate?</b>
                </label>
                <DropDown
                    noClass
                    placeholder='Please Select'
                    options={childAssetDistributionOpt}
                    bind={setChildAssetDistribution}
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextChildDistribution()} title={'Next'} />
                    <CustomButtom event={() => previousAlternateChildQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 35 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>What is the name of the person who is being appointed as trustee for the trust set up for minor beneficiaries (i.e. person who will manage the property left for minor beneficiaries till attain majority)?</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setTrusteeName}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextChildQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousExecutorQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 36 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Do you have any bank accounts?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={bankAccount === 'Yes'}
                    name={'inlineRadioBankAccount'}
                    id={'inlineRadioBankAccount'}
                    value={'Yes'}
                    onChange={(event) => setBankAccount(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={bankAccount === 'No'}
                    name={'inlineRadioBankAccount'}
                    id={'inlineRadioBankAccount'}
                    value={'No'}
                    onChange={(event) => setBankAccount(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                
                <label>
                    <b>Do you have any fixed deposits which are to be distributed?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={fixedDeposits === 'Yes'}
                    name={'inlineRadioFixedDeposits'}
                    id={'inlineRadioFixedDeposits'}
                    value={'Yes'}
                    onChange={(event) => setFixedDeposits(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={fixedDeposits === 'No'}
                    name={'inlineRadioFixedDeposits'}
                    id={'inlineRadioFixedDeposits'}
                    value={'No'}
                    onChange={(event) => setFixedDeposits(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousExecutorExclude()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>VERY IMPORTANT. Nomination registered on your Bank accounts, FD, PF, Shares etc SUPERCEDE your will. Please ensure there are no conflicts between Nominees and Beneficiaries.</li>
                        </p>
                    </div>


            </div> : null}

            {question === 37 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Do you have any lockers in the bank or elsewhere whose contents are to be distributed to beneficiaries?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={bankLocker === 'Yes'}
                    name={'inlineRadioBankLocker'}
                    id={'inlineRadioBankLocker'}
                    value={'Yes'}
                    onChange={(event) => setBankLocker(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={bankLocker === 'No'}
                    name={'inlineRadioBankLocker'}
                    id={'inlineRadioBankLocker'}
                    value={'No'}
                    onChange={(event) => setBankLocker(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>VERY IMPORTANT. Nominees registered on your Bank locker will get first access.</li>
                        </p>
                    </div>
            </div> : null}

            {question === 38 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Do you have any public provident fund accounts which are to be distrbuted to beneficiaries?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={pf === 'Yes'}
                    name={'inlineRadioPf'}
                    id={'inlineRadioPf'}
                    value={'Yes'}
                    onChange={(event) => setPf(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={pf === 'No'}
                    name={'inlineRadioPf'}
                    id={'inlineRadioPf'}
                    value={'No'}
                    onChange={(event) => setPf(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Many have multiple unclaimed PF accounts. Talk to us how to consolidate these to make the claim process easy.</li>
                        </p>
                    </div>
            </div> : null}

            {question === 39 ?
             <div>
             {scroll(ref7)}
             <label>
                <b>(Optional) List any and all gifts of specific items of property or money to a named beneficiary that you wish to make (which have not already been included above). The gift must be described well enough that anyone reading the Will would recognize what you meant. It is also important to clearly identify the beneficiary.</b>
            </label>
            <TextArea
                onkeypress
                bind={setTestatorGifts}
                placeholder="E.g. My property situated at Road No. 1, Banjara Hills, Hyderabad to Age Help Foundation; my Maruti Swift car to my daughter in law Sunita Verma."
            />
             <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
        </div> : null}

            {question === 40 ?
                <div>
                    {scroll(ref7)}
                <label>
                    <b>The person managing estate</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setPersonManagingEstate}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                 <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 41 ?
            <div>
                {scroll(ref8)}
                <label>
                    <b>Are there any special instructions regarding any assets or properties or any other instructions?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={specialInstructions === 'Yes'}
                    name={'inlineRadioSpecialInstructions'}
                    id={'inlineRadioSpecialInstructions'}
                    value={'Yes'}
                    onChange={(event) => setSpecialInstructions(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={specialInstructions === 'No'}
                    name={'inlineRadioSpecialInstructions'}
                    id={'inlineRadioSpecialInstructions'}
                    value={'No'}
                    onChange={(event) => setSpecialInstructions(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 42 ?
            <div>
                {scroll(ref8)}
                <label>
                    <b>Please list any special instructions here.</b>
                </label>
                <TextArea
                onkeypress
                bind={setSpecialInstructionDetails}
                placeholder="E.g. My horse Chetan to be taken care of by my eldest son, Nikhil and monthly expense of Rs. 10000 will be paid from my bank account no. 121331313 to Nikhil for the same."
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 43 ?
            <div>
                {scroll(ref8)}
                <label>
                    <b>(Optional) In the event that all named beneficiaries and their alternate predecease you, to whom should the estate be distributed?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setTestatorAlternate}
                    placeholder="E.g. My niece, Neha Saini; Helpage India"
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>This is called a WIPEOUT clause. Sadly, it does happen. Choose wisely; your gift can change many lives.</li>
                        </p>
                    </div>


            </div> : null}

            {question === 44 ?
            <div>
                {scroll(ref8)}
                <label>
                    <b>(Optional) Do you wish to appoint a guardian for a minor or dependent child ?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setTestatorGuardian}
                    placeholder="E.g. My niece, Neha Saini; Helpage India"
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Highly recommended, especially if your spouse predeceases you.</li>
                        </p>
                    </div>
            </div> : null}

            {question === 45 ?
            <div>
                {scroll(ref8)}
                <label>
                    <b>City and province of residence of the guardian.</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setTestatorGuardianResidence}
                    placeholder="E.g. Bangalore"
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}
            
            {question === 46 ?
            <div>
                {scroll(ref9)}
                <label>
                    <b>Are there any minor beneficiaries (i.e. children or persons less than 18 years of age) who will inherit from you ?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={minorBeneficiaries === 'Yes'}
                    name={'inlineRadioMinorBeneficiaries'}
                    id={'inlineRadioMinorBeneficiaries'}
                    value={'Yes'}
                    onChange={(event) => setMinorBeneficiaries(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={minorBeneficiaries === 'No'}
                    name={'inlineRadioMinorBeneficiaries'}
                    id={'inlineRadioMinorBeneficiaries'}
                    value={'No'}
                    onChange={(event) => setMinorBeneficiaries(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextMinorBenificiary()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Pay ATTENTION here. Minors will inherit ONLY when they become 18 years old. Till then, your property will have to be in Trust.<br></br>                            <div align= 'center'><Button onClick={appointment} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'50%' }}>
                            {'Talk to our lawyer'}
                            </Button></div></li>
                        </p>
                    </div>
            </div> : null}

            {question === 47 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Who is to be appointed as Trustee (i.e. person who will manage the property left for minor beneficiaries till they attain majority) of the trust set up for minor beneficiaries?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={appointTrusteeForMinor === 'the Executor (if applicable)'}
                    name={'inlineRadioAppointTrusteeForMinor'}
                    id={'inlineRadioAppointTrusteeForMinor'}
                    value={'the Executor (if applicable)'}
                    onChange={(event) => setAppointTrusteeForMinor(event.target.value)}
                    />
                    the Executor (if applicable)
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={appointTrusteeForMinor === 'some other person'}
                    name={'inlineRadioAppointTrusteeForMinor'}
                    id={'inlineRadioAppointTrusteeForMinor'}
                    value={'some other person'}
                    onChange={(event) => setAppointTrusteeForMinor(event.target.value)}
                    />
                    some other person
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextTrustee()} title={'Next'} />
                    <CustomButtom event={() => PreviousTrustee()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Choose wisely. This is very IMPORTANT. Talk to us for various options.<br></br><div align= 'center'><Button onClick={appointment} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'50%' }}>
                            {'Talk to our lawyer'}
                            </Button></div></li>
                        </p>
                    </div>

            </div> : null}
            
            {question === 48 ?
                <div>
                    {scroll(ref6)}
                <label>
                    <b>Who are you appointing as trustee for the trust set up for minor beneficiaries (i.e. person who will manage the property left for minor beneficiaries till attain majority)?</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setMinorTrusteeName}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                 <ContainerPosition>
                    <CustomButtom event={() => nextChildQuestion()} title={'Next'} />
                    <CustomButtom event={() => PreviousChildMinorQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 49 ?
            <div>
                {scroll(ref10)}
                <label>
                    <b>Do you wish to specify how your remains are to be handled after death?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={testatorDeath === 'Yes'}
                    name={'inlineRadioTestatorDeath'}
                    id={'inlineRadioTestatorDeath'}
                    value={'Yes'}
                    onChange={(event) => setTestatorDeath(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={testatorDeath === 'No'}
                    name={'inlineRadioTestatorDeath'}
                    id={'inlineRadioTestatorDeath'}
                    value={'No'}
                    onChange={(event) => setTestatorDeath(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextDeathQuestion()} title={'Next'} />
                    <CustomButtom event={() => PreviousMinorBenificiary()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 50 ?
            <div>
                {scroll(ref10)}
                <label>
                    <b>My remains are to be:</b>
                </label>
                <DropDown
                    noClass
                    placeholder='Please Select'
                    options={deathOpt}
                    bind={setTestatorDeathOption}
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 51 ?
            <div>
                {scroll(ref10)}
                <label>
                    <b>(Optional) If any, enter further instructions you may have in regards to your remains.</b>
                </label>
                <TextArea
                onkeypress
                bind={setInstructions}
                placeholder="E.g. My remains are to be buried in my home city, Banaras, U.P.; my cremated remains are to become part of the Ganga ; I want my cremated remains to be scattered under the oak tree in the backyard of my countryside property." 
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> 
            : null}

             {question === 52 ?
            <div style={{textAlign:'center'}}>
                <Button onClick={backToFirst} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'100%' }}>
                    {!Object.keys(willDataList).length ? `Draft a Will.` : `Continue editing draft`}
                </Button>
                <Button onClick={resetWillData} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'100%' }}>
                    {`Reset draft Will`}
                </Button>
                <Button onClick={appointment} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'100%' }}>
                    {'Get FREE copy on mail + FREE consult'}
                </Button>
                {/*<Button onClick={paymentPage} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'100%' }}>
                    {'Pay and Print the Will'}
                </Button>*/}

            </div> 
            : null}
            </div>
</div>
<div className='col-sm-9'>
<div style={{ 
        paddingLeft:'10px',
        backgroundColor:'#fff', 
        height:500, 
        overflow:'scroll',
         }} 
        className='d-none d-sm-block'>
<div id="HTMLtoPDF" className="unselectable">
<WillDocument/>
{assetDistributionType === 'Asset Distribution Tool'
?
visibleAssetDescriptionToo1 ? null :
<div>
    <p>The assets and properties of my estate  will be distributed in the following manner</p>
    <Summary will = {'assetDistribution'} />
    </div>
:
null }

{assetDistributionType === 'Asset Distribution' && assetDistribution === '' ?
<div>
<p style={{ backgroundColor: divname !== 'div22' ? '#fff': '#dfdee5'}}>a.&nbsp;&nbsp;&nbsp;The assets and properties of my estate  will be distributed to the following beneficiaries
<span ><span  >{alternateBenificiary ==='' ? '___________' : alternateBenificiary}</span></span></p>
<p>14.&nbsp;&nbsp;&nbsp;As regards the Bank Accounts, the Fixed Deposits, the assets in my bank lockers, and my public provident fund account, the same shall be bequeathed to the members of family already nominated in the accounts. </p>
</div>
: null
}
<WillDocs/>
</div>
</div>

<div className='d-block d-sm-none'>
<div>
{!openWill ?
<div style={{position:'fixed',bottom:'0',left:'0', width:'100%', backgroundColor:'#1b2e4a'}}>
<div style={{width:'100%', color:'white', textAlign:'center',padding:'10px'}} onClick={toggleOpenWill}>
<i class="fa fa-angle-up" style={{fontSize:25, color:'#b3b3b3', backgroundColor:'white',float:'left'}}></i>
See your document in progress
<i class="fa fa-angle-up" style={{fontSize:25, color:'#b3b3b3', backgroundColor:'white',float:'right'}}></i>
</div>
</div>
:
<div style={{position:'fixed',top:'30%',width:'100%',bottom:'0',left:'0',backgroundColor:'#b3b3b3'}}>
<div style={{width:'100%', position:'fixed', backgroundColor:'#b3b3b3',color:'white', textAlign:'center', padding:'10px'}} onClick={toggleOpenWill}>
<i class="fa fa-angle-down" style={{fontSize:25,color:'#b3b3b3', backgroundColor:'white', float:'left'}}></i>
Review your document
<i class="fa fa-angle-down" style={{fontSize:25,color:'#b3b3b3', backgroundColor:'white', float:'right'}}></i>
</div>
<div style={{margin:'10px', marginTop:'40px', backgroundColor:'white', borderRadius:'15px', padding:'5px',height:'60vh' ,overflow:'scroll'}}>
<div id="HTMLtoPDF" >
<WillDocument/>
{assetDistributionType === 'Asset Distribution Tool'
?
visibleAssetDescriptionToo1 ? null :
<div>
    <p>The assets and properties of my estate  will be distributed in the following manner</p>
    <Summary will = {'assetDistribution'} /></div>
:
null }

{assetDistributionType === 'Asset Distribution' && assetDistribution === '' ?
<div>
<p style={{ backgroundColor: divname !== 'div22' ? '#fff': '#dfdee5'}}>a.&nbsp;&nbsp;&nbsp;The assets and properties of my estate  will be distributed to the following beneficiaries
<span ><span  >{alternateBenificiary ==='' ? '___________' : alternateBenificiary}</span></span></p>
<p>14.&nbsp;&nbsp;&nbsp;As regards the Bank Accounts, the Fixed Deposits, the assets in my bank lockers, and my public provident fund account, the same shall be bequeathed to the members of family already nominated in the accounts. </p>
</div>
: null
}
<WillDocs/>
</div>
</div>
</div>
}

</div>
</div>
</div>
<ModalContainer visible={visibleMuslimReligion} toggle={toggleMuslimReligion}>
    <div style={{ padding: '40px' }}>
        <h3 style={{textAlign:'center'}}>IMPORTANT</h3>
        <p>In India, matters relating to succession and inheritance of a Muslim are governed by Muslim personal laws. The general rule under Muslim personal laws in India is that a Muslim may, by his will, dispose only up to one-third of his property which is left after payment of funeral expenses and debts without the consent of his heirs. The remaining two-thirds of the testator’s property must go to those who are his heirs at the time of his death.</p>
        <div style={{textAlign:'center'}}>
        <button style={{backgroundColor:'#b3b3b3',padding:'5px',borderRadius:'10px',border: 'none',color:'white',fontWeight:700}} onClick={() =>StayOnPageReligionButton(toggleMuslimReligion)}>Stay On Page</button>
        <button style={{backgroundColor:'#007bff',padding:'5px',borderRadius:'10px',border: 'none',color:'white',fontWeight:700}} onClick={() =>nextReligionButton(toggleMuslimReligion)}>Next</button>
        </div>
    </div>
</ModalContainer>

<ModalContainer visible={visibleChristianReligion} toggle={toggleChristianReligion}>
    <div style={{ padding: '40px' }}>
        <h3 style={{textAlign:'center'}}>IMPORTANT</h3>
        <p>Upon the marriage of an Indian christian, his or her will automatically stands revoked. Accordingly, post marrriage a person has to again make a will.</p>
        <div style={{textAlign:'center'}}>
        <button style={{backgroundColor:'#b3b3b3',padding:'5px',borderRadius:'10px',border: 'none',color:'white',fontWeight:700}} onClick={() =>StayOnPageReligionButton(toggleChristianReligion)}>Stay On Page</button>
        <button style={{backgroundColor:'#007bff',padding:'5px',borderRadius:'10px',border: 'none',color:'white',fontWeight:700}} onClick={() =>nextReligionButton(toggleChristianReligion)}>Next</button>
        </div>
    </div>
</ModalContainer>

<ModalContainer visible={visibleParsiReligion} toggle={toggleParsiReligion}>
    <div style={{ padding: '40px' }}>
        <h3 style={{textAlign:'center'}}>IMPORTANT</h3>
        <p>Upon the marriage of a Parsi, his or her will automatically stands revoked. Accordingly, post marrriage a person has to again make a will.</p>
        <div style={{textAlign:'center'}}>
        <button style={{backgroundColor:'#b3b3b3',padding:'5px',borderRadius:'10px',border: 'none',color:'white',fontWeight:700}} onClick={() => StayOnPageReligionButton(toggleParsiReligion)}>Stay On Page</button>
        <button style={{backgroundColor:'#007bff',padding:'5px',borderRadius:'10px',border: 'none',color:'white',fontWeight:700}} onClick={() => nextReligionButton(toggleParsiReligion)}>Next</button>
        </div>
    </div>
</ModalContainer>

{calenderslot === true ?
<CalenderSlots name={personalDetails.firstName} email={personalDetails.primaryEmail} freeConsult={'true'} updateId={personalDetails.id} toggleFreeConsultModal={toggleCalenderSlot} consultWho = {'Our Legal Experts'} will content = {mailcontent} summarypayload = {mailpayload}/>
: null }

<ModalContainer visible={visibleLogin} toggle={toggleLogin}>
        <div style={{ padding: '40px' }}>
            <Login onClose={toggleLogin} will/>
        </div>
</ModalContainer>

<Modal
        show={visibleAssetDescriptionToo1}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
    <div>
        <button style={{marginRight:'20px',marginTop:'10px'}} type='button' className='close' onClick={toggleAssetDescriptionToo1}>
            &times;
        </button>
        <Assign/>
    </div>
</Modal>

<ModalContainer visible={visiblePayment} toggle={togglePayment}>
<div>
    <div style={{paddingBottom:'30px',paddingRight:'30px',paddingTop:'30px'}}>
    <button type='button' className='close' onClick={togglePayment}>
        &times;
    </button>
    </div>
    <tr>
        <th style={{textAlign:'center', paddingBottom:'10px'}} colspan={1}>Did you review your draft with our lawyers? Your first consult is FREE.<hr></hr></th>
    </tr>
    <tr>
        <td width="30%">
        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> You get legal vetting</ol>
        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Printing and home delivery</ol>
        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Registration and other paperwork</ol>
        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Digital Archive</ol>
        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Preferred pricing on other products</ol>
        </td>
        <td width='3%'></td>
    </tr>
    <tr>
        <td align="center"><ol><Payment productID={1} amount={35000} productDescrition={'Will and Estate Services'} will={'createWill'} togglePayment={togglePayment}/></ol></td>
        <td width='3%'></td>
    </tr>
</div>

</ModalContainer>
</div>
			</div>
       		)
}

const mapStateToProps = (state) => ({
    userProfileId: getStoreUserId(state),
    userRole:getStoreUserRole(state),

    personalDetails: getStoreUser(state),

    familyList: getStoreFamily(state),
    contactList: getStoreContactList(state),
    
    familyListExecutor: getStoreFamily(state).filter(fa=> fa.deceased === false),
    contactListExecutor: getStoreContactList(state).filter(fa=> fa.deceased === false),

    familyListBeneficiary: getStoreFamily(state).filter(fa=> fa.deceased === false && fa.disinherit === false),
    contactListBeneficiary: getStoreContactList(state).filter(fa=> fa.deceased === false && fa.disinherit === false),
 
    disinheritList: (getStoreContactList(state).concat(getStoreFamily(state)).filter((dis) => dis.disinherit === true)),
    executorList: (getStoreContactList(state).concat(getStoreFamily(state)).filter((exe) => exe.executorFlag === true && exe.custField5 !== 'true')),
    alternateExecutorList: (getStoreContactList(state).concat(getStoreFamily(state)).filter((exe) => exe.executorFlag === true && exe.custField5 === 'true')),

    fatherList: (getStoreFamily(state)).filter((f) => f.relationship === 'FATHER'),
    motherList: (getStoreFamily(state)).filter((m) => m.relationship === 'MOTHER'),
    spouseList: (getStoreFamily(state)).filter((s) => s.relationship === 'SPOUSE'),
    childList: (getStoreFamily(state)).filter((c) => c.relationship === 'CHILD'),

    pickBeneficiaryList: (getStoreContactList(state).concat(getStoreFamily(state))).filter(c => _.findIndex(_.flattenDeep(getStoreBeneficiaryList(state).map(b => b.contacts)), { id: _.get(c, ['id']) }) === -1),
    beneficiaryList: getStoreBeneficiaryList(state),

    finAssetList: getStoreFinAssetList(state),
    digitalAssetList: getStoreDigitalAssetList(state),
    immPropImmovableAssetList: getStoreImmovableAssetList(state),
    otherAssetList: getStoreOtherAssetList(state),

    pfFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'PF'),
    fdFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'FD'),
    bankFinAssetList: getStoreFinAssetList(state).filter(fa => fa.assetLookupCode === 'BNK'),
    lockerOtherAssetList: getStoreOtherAssetList(state).filter(fa=> fa.assetLookupCode === 'LOCK'),

    willDataList: getWillDataList(state),
  });
  const mapDispatchToProps = () => dispatch => ({
    dispatchSetUserDetails: user => dispatch(setUserData(user)),

    dispatchSetFamilyList: (contact) => dispatch(setFamilyData(contact)),
    dispatchSetContactList: (contact) => dispatch(setContactData(contact)),

    dispatchCreateFamily: parent => dispatch(createFamily(parent)),
    dispatchEditFamily: parent => dispatch(editFamily(parent)),
    
    dispatchAddContact: contact => dispatch(addContact(contact)),
    dispatchEditContact: contact => {
                                        dispatch(editContact(contact));
                                        dispatch(updateContactBeneficiary(contact))
                                    },

    dispatchSetExecutorList: executor => dispatch(setExecutorData(executor)),
    dispatchSetBeneficiaryList: beneficiary => dispatch(setBeneficiaryData(beneficiary)),

    dispatchAddFinAsset: (finAsset) => dispatch(setFinAssetData(finAsset)),
    dispatchSetImmovableAssetList: (immovableAsset) => dispatch(setImmovableAssetData(immovableAsset)),
    dispatchSetDigitalAssetList: (digitalAsset) => dispatch(setDigitalAssetData(digitalAsset)),
    dispatchSetOtherAssetList: (otherAsset) => dispatch(setOtherAssetData(otherAsset)),

    dispatchUpdateExecutorList: executor => dispatch(updateToExecutor(executor)),
    dispatchDeleteExecutorList: executorId => dispatch(deleteExecutorFromList(executorId)),

    dispatchCreateBeneficiary: ben => dispatch(createBeneficiary(ben)),
    dispatchDeleteBeneficiary: beneficiaryId => dispatch(deleteBeneficiaryFromList(beneficiaryId)),

    dispatchSetWillData: willData => dispatch(setWillDataList(willData)),
  
  })
  
export default connect(mapStateToProps, mapDispatchToProps)(Will);