import React from 'react';
import SectionHeading from '../../home/component/layout/SectionHeading';

const Questions = ()  => {

    let imgUrl = '../img/banner/greybackground.png';

    const QuestionAnswers = () =>{
        return(
            <div>
            <SectionHeading heading={'Questions ?'} subHeading={''} image={'../img/banner/plain_greybackground.png'}/>
            <p align='justify'>Ofcourse you will have questions! <br></br><br></br>
            This is not an easy topic and it is natural that you have questions.<br></br><br></br>
            Over the past year, we have collected a set of the most Frequently Asked Questions. These have come mostly from subscribers to our site; but we also collated questions that were asked
            to our lawyers, advisors, technical help-desk and posted to our social media sites.<br></br><br></br>
            We hope the answers would satisfy your curiosity, but please feel free to contact us if you have a different question or need more detailed answers.</p><br></br><br></br>
            </div>
        )
    }
    return(
 <div className='container' style={{textAlign:'justify'}}>

        <div className='d-block d-sm-none'>         

        <div className = 'Component-Bg' 
                style = {{ backgroundImage: `url(${imgUrl})`,
                backgroundSize: 'cover', 
                backgroundPosition: 'left center',
                backgroundRepeat: 'no-repeat',
                }}> 
        
        <div style={{width:'100%', paddingLeft:'5%', paddingRight:'2%'}}>  
            <QuestionAnswers/>
        </div>
        </div>
        </div>

        <div className='d-none d-sm-block'>

        <div className = 'Component-Bg' 
                style = {{ backgroundImage: `url(${imgUrl})`,
                backgroundSize: 'cover', 
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                }}> 
          
        <div style={{width:'100%', paddingLeft:'35%', paddingRight:'2%',paddingTop:'40px'}}>  
            <QuestionAnswers/>
        </div>
        </div>
        </div>

            <br></br>
                    <p><b>How do I better manage my assets and properties for my family?</b></p>
                    <p>Record and create an asset portfolio and update it regularly. Write a Will to ensure proper allotment, disposal and distribution of your assets to your beneficiaries after death.</p>
                    <br></br>
                    <p><b>What should I know about an Asset Portfolio?</b></p>
                    <p>An Asset Portfolio is a repository where you keep a record of all your tangible and intangible assets. It contains details of your Bank Accounts, Securities, Bonds, Movable and Immovable properties, digital assets etc. People usually maintain a diary wherein they record the details of their assets, however, sometimes this practise arises complications such as in the cases where the diary gets misplaced or when they 
                        forget updating the diary right after purchasing an asset.</p>
                    <br></br>
                    
                    <p><b>What should I know about a Will?</b></p>
                    <p>Will/Testament is a legal declaration made in writing containing your final wishes, distribution, allotment, disposal and distribution of your assets. It can also contain detailed instructions of the manner in which your assets must 
                        be operated after your death as per your wish.</p>
                    <br></br>
                    <p><b>What are the general terminologies I must know before writing a Will?</b></p>
                    <p> <b>‘Testator’</b> is the person who makes a will giving out its legacy.<br></br>
                        <b>‘Executor/Executrix’</b> is the person appointed by the testator to look after the testators’ assets and facilitate execution of the will after the dismissal of the testator.<br></br>
                        <b>‘Beneficiary’</b> is the person who is entitled to and avails the distributed assets as per will. The term is inclusive of any, who has a recognised legal personality such as a corporate organisation/ public or private trust/ a body politic etc.<br></br>
                        <b>‘Probate’</b> is a certification obtained from a Judicial Court stating the validity of a Will.<br></br>
                        <b>‘Intestate’</b> is the condition where a person dies without writing a Will. In such a condition, the asset distribution happens as per the complicated personal laws of the deceased.</p>
                    <br></br>   
                    <p><b>How many people are required to sign my will?</b></p>
                    <p>A will obtains validity only when it holds signatures of two or more witnesses apart from the testators signature. It, however, is not necessary for the witnesses to actually witness the writing of the will. Witnesses can attest/sign the will after receiving from the testator a personal acknowledgement 
                        of his/her signature or mark on the will.</p>
                    <br></br>
                    <p><b>Can I make an Oral Will?</b></p>
                    <p>The current Indian Legal System does not permit for the enforcement of a will made by word of mouth unless your Personal Laws permit to do so (for eg. Muslim law requires no specific formalities for creation of a will. It may be made in writing or oral or even by gestures) or you are eligible to create a Privileged Will under Section 66 of The Indian Succession Act, 1925 (i.e. you are a soldier/airman/mariner 
                        employed in an expedition or engaged in actual warfare).</p>
                    <br></br>
                    <p><b>What role does an Executor/Executrix play?</b></p>
                    <p>‘Executor/Executrix’ is the person appointed by the testator to look after the testators’ assets and facilitate execution of the will after the dismissal of the testator. It is not mandatory to appoint an Executor/Executrix, however, it is recommended to appoint one to safeguard the asset disposal/distribution and the bequeathing process. An Executor/Executrix can be a beneficiary under the will or a family friend, 
                        a lawyer or any professional assistant i.e. ‘A Person of Trust’.</p>
                    
                    <br></br><br></br>


                    <p>If case of any further questions or clarifications, please write to us <u>info@easyinherit.in</u>. We would be happy to talk to you.
                    </p><br></br>
    </div>

    )
}


export default (Questions);
