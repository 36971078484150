import React, { useState, useEffect, useRef, useImperativeHandle, createRef } from 'react';
import { CustomButtom, HighlightedText } from '../../../components/CustomElements';
import CollapseSection from '../../../components/CollapsibleSection';
import { Table } from 'react-bootstrap';
import string from '../../../constant/en.json';
import { getStoreImmovableAssetList } from '../../../actions/immovableAssetsActions';
import { getStoreUserId, getStoreUserRole } from '../../../selectors/userSelectors';
import { getStoreFinAssetList } from '../../../actions/finAssetsActions';
import { getStoreOtherAssetList } from '../../../actions/otherAssetsActions';
import { getStoreDigitalAssetList } from '../../../actions/digitalAssetsActions';
import { getStoreBeneficiaryList, setBeneficiaryData } from '../../../actions/beneficiaryActions';
import { connect } from 'react-redux';
import { getContactFullName, getRelation } from '../../../utils/commonUtil';
import { createFinAssignment, getFinAssignListFromServer, createImmovableAssignment, createOtherAssignment, getImmovableAssignListFromServer, createDigitalAssignment, getOtherAssignListFromServer, getDigitalAssignListFromServer } from './assignService';
import { forwardRef } from 'react';
import { setFinAssignData, getStoreFinAssignList, addFinAssign } from '../../../actions/finAssignActions';
import _ from 'lodash';
import { addImmPropAssign, getStoreImmPropAssignList, setImmPropAssignData } from '../../../actions/immovablePropAssignActions';
import { getBeneficiaryListFromServer } from '../benificiary/beneficiaryService';
import { setOtherAssignData, addOtherAssign, getStoreOtherAssignList } from '../../../actions/otherAssignActions';
import { setDigitalAssignData, addDigitalAssign, getStoreDigitalAssignList } from '../../../actions/digitalAssignActions';


const AssignForms = ({ selectedAssets, finAssetList, otherAssetList, digitalAssetList, immPropAssetList,immPropAssignList, finAssignList,otherAssignList,digitalAssignList, userId, beneficiariesList, dispatchSetFinAssignList, dispatchSetImmovableAssignList, dispatchAddImmovableAssign, dispatchSetBeneficiaryList, dispatchSetOtherAssignList, dispatchSetDigitalAssignList, dispatchAddDigitalAssign, dispatchAddOtherAssign, onAction, userRole}) => {
  let beneficiaryList = beneficiariesList.map(e=> {return  {...e, ratio:'', isChecked: false,}})
  let fds =[],
  phyShares=[],
  demat=[],
  govtSchemes=[],
  portfolio=[],
  bankAccounts=[],
  insurance=[],
  mfs=[],
  bonds=[],
  pfs=[],
  gratuity=[],
  artefacts=[],
  jewellery=[],
  art=[],
  lockers=[],
  vehicles=[],
  perEffect=[],
  social=[],
  web=[];
  if(finAssetList.length >0){
     fds = finAssetList.filter(e=>e.assetLookupCode === 'FD');
     phyShares = finAssetList.filter(e=>e.assetLookupCode === 'SHR');
     demat = finAssetList.filter(e=>e.assetLookupCode === 'DEM');
     govtSchemes = finAssetList.filter(e=>e.assetLookupCode === 'GOV');
     portfolio = finAssetList.filter(e=>e.assetLookupCode === 'PMS');
     bankAccounts = finAssetList.filter(e=>e.assetLookupCode === 'BNK');
     insurance = finAssetList.filter(e=>e.assetLookupCode === 'INS');
     mfs = finAssetList.filter(e=>e.assetLookupCode === 'MF');
     bonds = finAssetList.filter(e=>e.assetLookupCode === 'BON');
     pfs = finAssetList.filter(e=>e.assetLookupCode === 'PF');
     gratuity = finAssetList.filter(e=>e.assetLookupCode === 'GRA');
  }
  if(otherAssetList.length >0){
    artefacts = otherAssetList.filter(e=>e.assetLookupCode === 'ARTI');
    jewellery = otherAssetList.filter(e=>e.assetLookupCode === 'JEWL');
    art = otherAssetList.filter(e=>e.assetLookupCode === 'ART');
    lockers = otherAssetList.filter(e=>e.assetLookupCode === 'LOCK');
    vehicles = otherAssetList.filter(e=>e.assetLookupCode === 'VEH');
    perEffect = otherAssetList.filter(e=>e.assetLookupCode === 'PERS');
 }
 if(digitalAssetList.length >0){
  social = digitalAssetList.filter(e=>e.assetLookupCode === 'SOC');
  web = digitalAssetList.filter(e=>e.assetLookupCode === 'WEB');
}
let assginArray = [
  <FDAssign onAction={onAction} list={fds} assignList={finAssignList['FD']} beneficiaryList={beneficiaryList}  dispatchAddList={dispatchSetFinAssignList} />,
  <PhysicalSharesAssign onAction={onAction} assignList={finAssignList['SHR']} list={phyShares} beneficiaryList={beneficiaryList} dispatchAddList={dispatchSetFinAssignList} />,
  <DematAssign onAction={onAction} assignList={finAssignList['DEM']} list={demat} beneficiaryList={beneficiaryList}  dispatchAddList={dispatchSetFinAssignList}  />,
  <GovtSchemesAssign onAction={onAction} assignList={finAssignList['GOV']} list={govtSchemes} beneficiaryList={beneficiaryList}  dispatchAddList={dispatchSetFinAssignList} />,
  <BanckAccountAssign onAction={onAction} assignList={finAssignList['BNK']}  list={bankAccounts} beneficiaryList={beneficiaryList}   dispatchAddList={dispatchSetFinAssignList} />,
  <InsuranceAssign onAction={onAction} assignList={finAssignList['INS']} list={insurance} beneficiaryList={beneficiaryList}  dispatchAddList={dispatchSetFinAssignList} />,
  <MFAssign onAction={onAction} assignList={finAssignList['MF']} list={mfs} beneficiaryList={beneficiaryList}  dispatchAddList={dispatchSetFinAssignList}  />,
  <BondsAssign onAction={onAction} assignList={finAssignList['BON']} list={bonds} beneficiaryList={beneficiaryList}  dispatchAddList={dispatchSetFinAssignList} />,
  <PFAssign onAction={onAction} assignList={finAssignList['PF']} list={pfs} beneficiaryList={beneficiaryList}  dispatchAddList={dispatchSetFinAssignList} />,
  <GratuityAssign onAction={onAction} assignList={finAssignList['GRA']} list={gratuity} beneficiaryList={beneficiaryList} dispatchAddList={dispatchSetFinAssignList}/>,
  <ImmovablePropertyAssign onAction={onAction} list={immPropAssetList} beneficiaryList={beneficiaryList} assignList={immPropAssignList} dispatchAddList={dispatchAddImmovableAssign} />,
  <SocialAssign onAction={onAction} list={social} beneficiaryList={beneficiaryList}  assignList={digitalAssignList.filter(e=>e.assetLookupCode === 'SOC')} dispatchAddList={dispatchAddDigitalAssign}  />,
  <WebsiteAssign onAction={onAction} list={web} beneficiaryList={beneficiaryList}  assignList={digitalAssignList.filter(e=>e.assetLookupCode === 'WEB')} dispatchAddList={dispatchAddDigitalAssign}  />,
  <PortfolioAssign onAction={onAction} assignList={finAssignList['PMS']} list={portfolio} beneficiaryList={beneficiaryList}  dispatchAddList={dispatchSetFinAssignList}  />,
  <ArtefactsAssign onAction={onAction} list={artefacts} beneficiaryList={beneficiaryList}  assignList={otherAssignList.filter(e=>e.assetLookupCode === 'ARTI')} dispatchAddList={dispatchAddOtherAssign}  />,
  <JewelleryAssign onAction={onAction} list={jewellery} beneficiaryList={beneficiaryList}  assignList={otherAssignList.filter(e=>e.assetLookupCode === 'JEWL')} dispatchAddList={dispatchAddOtherAssign}  />,
  <ArtAssign onAction={onAction} list={art} beneficiaryList={beneficiaryList}   assignList={otherAssignList.filter(e=>e.assetLookupCode === 'ART')} dispatchAddList={dispatchAddOtherAssign}  />,
  <LockerAssign onAction={onAction} list={lockers} beneficiaryList={beneficiaryList}  assignList={otherAssignList.filter(e=>e.assetLookupCode === 'LOCK')} dispatchAddList={dispatchAddOtherAssign}  />,
  <VehicleAssign onAction={onAction} list={vehicles} beneficiaryList={beneficiaryList}  assignList={otherAssignList.filter(e=>e.assetLookupCode === 'VEH')} dispatchAddList={dispatchAddOtherAssign}  />,
  <PersonalEffectAssign onAction={onAction}  Assign list={perEffect} beneficiaryList={beneficiaryList}  assignList={otherAssignList.filter(e=>e.assetLookupCode === 'PERS')} dispatchAddList={dispatchAddOtherAssign}  />
        
]
const receiverFlag = userRole==='GIVER' ? false : true
useEffect(()=>{
    getFinAssignListFromServer(userId, receiverFlag)
    .then(resp=>{
      console.log(resp.data)
      if(!_.isEmpty(resp.data))
        dispatchSetFinAssignList(resp.data)
    })
    .catch(err=>{
      console.log(err)
    })
    getImmovableAssignListFromServer(userId, receiverFlag)
    .then(resp=>{
      console.log(resp.data)
        dispatchSetImmovableAssignList(resp.data)
    })
    .catch(err=>{
      console.log(err)
    })
    getOtherAssignListFromServer(userId, receiverFlag)
    .then(resp=>{
      console.log(resp.data)
        dispatchSetOtherAssignList(resp.data)
    })
    .catch(err=>{
      console.log(err)
    })
    getDigitalAssignListFromServer(userId, receiverFlag)
    .then(resp=>{
      console.log(resp.data)
        dispatchSetDigitalAssignList(resp.data)
    })
    .catch(err=>{
      console.log(err)
    })
    getBeneficiaryListFromServer(userId, receiverFlag)
      .then(response => {
        dispatchSetBeneficiaryList(response.data)
        // loading(false) 
      })
      .catch(error => {
        alert(error);
        // loading(false);
      })
  },[])

 return (<div style ={{marginTop:20}}>
      {selectedAssets.map(e => <React.Fragment>
            {assginArray[e]}
            <br />
        </React.Fragment>)}
    </div>
  );
};

const FDAssign = ({list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  console.log(collapsed)
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={!collapsed || list.length === 0} mainTitle={"Fixed Deposit Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
        <AssignToAll onAction={()=>onAction(false,0)}  setCollapsed={setCollapsed} assetLookupCode="FD" beneficiaryList={beneficiaryList} assignmentList={list} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const PhysicalSharesAssign = ({list, beneficiaryList, dispatchAddList,onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={!collapsed || list.length === 0} mainTitle={"Physical Shares Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
        <AssignToAll onAction={()=>onAction(false,1)}  setCollapsed={setCollapsed} assetLookupCode="SHR"  beneficiaryList={beneficiaryList} assignmentList={list} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const DematAssign = ({list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={!collapsed || list.length === 0} mainTitle={"Demat Account Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
        <AssignToAll  onAction={()=>onAction(false,2)}  setCollapsed={setCollapsed} assetLookupCode="DEM" beneficiaryList={beneficiaryList} assignmentList={list} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const GovtSchemesAssign = ({list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={!collapsed || list.length === 0} mainTitle={"Govenrment Saving Schemes Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
        <AssignToAll  onAction={()=>onAction(false,3)}  setCollapsed={setCollapsed} assetLookupCode="GOV"  beneficiaryList={beneficiaryList} assignmentList={list} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const BanckAccountAssign = ({list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  return(
        <CollapseSection isHidden={!collapsed || list.length === 0} mainTitle={"Bank Account Beneficiary Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
          <AssignToAll  onAction={()=>onAction(false,4)}  setCollapsed={setCollapsed} dispatchAddList={dispatchAddList} assetLookupCode="BNK" beneficiaryList={beneficiaryList} assignmentList={list} />
        </CollapseSection>
  );
}
const PortfolioAssign = ({list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={!collapsed || list.length === 0} mainTitle={"Portfolio Management Service Beneficiary Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
        <AssignToAll  onAction={()=>onAction(false,13)}  setCollapsed={setCollapsed} assetLookupCode="PMS" beneficiaryList={beneficiaryList} assignmentList={list} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const InsuranceAssign = ({list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={!collapsed || list.length === 0} mainTitle={"Insurance Policy Beneficiary Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
        <AssignToAll  onAction={()=>onAction(false,5)}  setCollapsed={setCollapsed} assetLookupCode="INS"  beneficiaryList={beneficiaryList} assignmentList={list} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}

const MFAssign = ({list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={!collapsed || list.length === 0} mainTitle={"Mutual Funds Units[Physical] Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
        <AssignToAll  onAction={()=>onAction(false,6)}  setCollapsed={setCollapsed} assetLookupCode="MF"  beneficiaryList={beneficiaryList} assignmentList={list} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const BondsAssign = ({list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={!collapsed || list.length === 0} mainTitle={"Bonds Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
        <AssignToAll  onAction={()=>onAction(false,7)}  setCollapsed={setCollapsed} assetLookupCode="BON" beneficiaryList={beneficiaryList} assignmentList={list}  dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}

const PFAssign = ({list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={!collapsed || list.length === 0} mainTitle={"Provident Funds Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
          <AssignToAll  onAction={()=>onAction(false,8)}  setCollapsed={setCollapsed} assetLookupCode="PF" beneficiaryList={beneficiaryList} assignmentList={list} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}

const GratuityAssign = ({list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={!collapsed || list.length === 0} mainTitle={"Gratuity Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
          <AssignToAll  onAction={()=>onAction(false,9)}  setCollapsed={setCollapsed} assetLookupCode="GRA" beneficiaryList={beneficiaryList} assignmentList={list} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const ImmovablePropertyAssign = ({assignList, list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  let assigned = new Set(list.map(obj => obj.id));
  assigned = assignList.filter(obj => assigned.has(obj.assetId));
  let list1 = list.filter(c => _.findIndex(_.flattenDeep(assigned), { assetId: _.get(c, ['id']) })===-1)
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={list.length === 0} mainTitle={"Immovable Property Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
         <AssignOneOnOne onAction={()=>onAction(false,10)}  setCollapsed={setCollapsed} disabled={true} assetLookupCode="PROP" createAssign={createImmovableAssignment} beneficiaryList={beneficiaryList} assignmentList={list1} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const SocialAssign = ({assignList, list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  let assigned = new Set(list.map(obj => obj.id));
  assigned = assignList.filter(obj => assigned.has(obj.assetId));
  let list1 = list.filter(c => _.findIndex(_.flattenDeep(assigned), { assetId: _.get(c, ['id']) })===-1)
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={list.length === 0} mainTitle={"Social Media/Email Account Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
        <AssignOneOnOne onAction={()=>onAction(false,11)}  setCollapsed={setCollapsed} disabled={true} assetLookupCode="SOC" createAssign={createDigitalAssignment} beneficiaryList={beneficiaryList} assignmentList={list1} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const WebsiteAssign = ({assignList, list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  let assigned = new Set(list.map(obj => obj.id));
  assigned = assignList.filter(obj => assigned.has(obj.assetId));
  let list1 = list.filter(c => _.findIndex(_.flattenDeep(assigned), { assetId: _.get(c, ['id']) })===-1)
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={list.length === 0} mainTitle={"Websites Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
         <AssignOneOnOne onAction={()=>onAction(false,12)}  setCollapsed={setCollapsed} disabled={true} assetLookupCode="WEB" createAssign={createDigitalAssignment} beneficiaryList={beneficiaryList} assignmentList={list1} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}

const ArtefactsAssign = ({assignList, list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  let assigned = new Set(list.map(obj => obj.id));
  assigned = assignList.filter(obj => assigned.has(obj.assetId));
  let list1 = list.filter(c => _.findIndex(_.flattenDeep(assigned), { assetId: _.get(c, ['id']) })===-1)
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={list.length === 0} mainTitle={"Artefacts Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
        <AssignOneOnOne onAction={()=>onAction(false,14)}  setCollapsed={setCollapsed} disabled={true} assetLookupCode="ARTI" createAssign={createOtherAssignment} beneficiaryList={beneficiaryList} assignmentList={list1} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const JewelleryAssign = ({assignList, list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  let assigned = new Set(list.map(obj => obj.id));
  assigned = assignList.filter(obj => assigned.has(obj.assetId));
  let list1 = list.filter(c => _.findIndex(_.flattenDeep(assigned), { assetId: _.get(c, ['id']) })===-1)
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={list.length === 0} mainTitle={"Jewellery Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
        <AssignOneOnOne onAction={()=>onAction(false,15)}  setCollapsed={setCollapsed} disabled={true} assetLookupCode="JEWL" createAssign={createOtherAssignment} beneficiaryList={beneficiaryList} assignmentList={list1} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const ArtAssign = ({assignList, list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  let assigned = new Set(list.map(obj => obj.id));
  assigned = assignList.filter(obj => assigned.has(obj.assetId));
  let list1 = list.filter(c => _.findIndex(_.flattenDeep(assigned), { assetId: _.get(c, ['id']) })===-1)
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={list.length === 0} mainTitle={"Art Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
        <AssignOneOnOne onAction={()=>onAction(false,16)}  setCollapsed={setCollapsed} disabled={true} assetLookupCode="ART"createAssign={createOtherAssignment} beneficiaryList={beneficiaryList} assignmentList={list1} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const LockerAssign = ({assignList, list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  let assigned = new Set(list.map(obj => obj.id));
  assigned = assignList.filter(obj => assigned.has(obj.assetId));
  let list1 = list.filter(c => _.findIndex(_.flattenDeep(assigned), { assetId: _.get(c, ['id']) })===-1)
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={list.length === 0} mainTitle={"Bank Lockers Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
        <AssignOneOnOne onAction={()=>onAction(false,17)}  setCollapsed={setCollapsed} disabled={true} assetLookupCode="LOCK" createAssign={createOtherAssignment} beneficiaryList={beneficiaryList} assignmentList={list1} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const VehicleAssign = ({assignList, list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  let assigned = new Set(list.map(obj => obj.id));
  assigned = assignList.filter(obj => assigned.has(obj.assetId));
  let list1 = list.filter(c => _.findIndex(_.flattenDeep(assigned), { assetId: _.get(c, ['id']) })===-1)
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={list.length === 0} mainTitle={"Vehicles Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
         <AssignOneOnOne onAction={()=>onAction(false,18)}  setCollapsed={setCollapsed} disabled={true} assetLookupCode="VEH" createAssign={createOtherAssignment} beneficiaryList={beneficiaryList} assignmentList={list1} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}
const PersonalEffectAssign = ({assignList, list, beneficiaryList, dispatchAddList, onAction}) => {
  let [collapsed, setCollapsed] = useState(true);
  let assigned = new Set(list.map(obj => obj.id));
  assigned = assignList.filter(obj => assigned.has(obj.assetId));
  let list1 = list.filter(c => _.findIndex(_.flattenDeep(assigned), { assetId: _.get(c, ['id']) })===-1)
  return(
    <div style={{marginBottom:20}}>
        <CollapseSection isHidden={list.length === 0} mainTitle={"Personal Effects Assignment"} subtitle={string.forms.commonFormFields.mandatoryFields} collapsed={collapsed}  isCollapsable >
         <AssignOneOnOne onAction={()=>onAction(false,19)}  setCollapsed={setCollapsed} disabled={true} assetLookupCode="PERS" createAssign={createOtherAssignment} beneficiaryList={beneficiaryList} assignmentList={list1} dispatchAddList={dispatchAddList} />
        </CollapseSection>
      </div>
  );
}

const AssignToAll =({assetLookupCode, dispatchAddList, beneficiaryList ,assignmentList, asset, onAction}) =>{
  const refAllAssign = useRef();
  const beneficiaryData = beneficiaryList.map(e=>{ return {userId:e.userId, contacts: e.contacts, assetPercentage:'', isChecked:false, userBeneficiariesId:e.id}})

  const onSubmit = (i) => {
    console.log(asset)
    let payload = []
      payload = refAllAssign.current.returnDetails().filter(e=>e.isChecked===true).map(p=>{
        return assignmentList.map(a=>{
          return {...p, contacts:{id: p.contacts.id},assetId: a.id, assetLookupCode, assetIssuerName: a.assetIssuerName, assetDescription: a.assetDescription, receiverFlag:false }
        })
      })
    payload = [].concat(...payload)
    console.log(payload)
    let sum = payload.map(v => {
        return (
            parseFloat(v.assetPercentage))
        })
    .reduce(function (a, b) {
        return a + b;
    }, 0);
        if(sum/assignmentList.length > 100)
            alert('Ratio Exceeded maximum Limit! Please maintain the ratio within 100')
        else if(sum/assignmentList.length < 100)
            alert('The sum of the ratio of all the beneficiaries should be equal to 100, please check.')
        else if(sum/assignmentList.length === 100){
            createFinAssignment(payload)
            .then(resp=>{
                console.log(resp.data)
                alert('Record Updated Successfully')
                dispatchAddList({[assetLookupCode]: resp.data})
                onAction()
            })
            .catch(err=>{
                console.log(err)
                alert(err)
            })
        }
    }
  return(
    <div>
      <SectionContainer mainTitle="Assign All" data={beneficiaryData} ref={refAllAssign} onSubmit={onSubmit} />
    </div>
  );
}


const AssignOneOnOne =({onAction, assetLookupCode, dispatchAddList, editList, beneficiaryList ,assignmentList, createAssign,setCollapsed}) =>{
    let [refIndividualAssign, setrefIndividualAssign] = useState([createRef()]);
    useEffect(() => {
      setrefIndividualAssign(refIndividualAssign => (
          Array(assignmentList.length).fill().map((_, i) => refIndividualAssign[i] || createRef())
        ));
    }, []);
    const beneficiaryData = beneficiaryList.map(e=>{ return {userId:e.userId, contacts: e.contacts, assetPercentage:'', isChecked:false, userBeneficiariesId:e.id}})

    const onSubmit = (i) => {
      let payload = []
        payload = refIndividualAssign[i]!== null ? [...refIndividualAssign[i].current.returnDetails()] : []
        payload = [].concat(...payload).filter(e=>e.isChecked===true && e.assetPercentage!=='').map(p=>{
            return {...p, contacts:{id: p.contacts.id}, assetLookupCode, receiverFlag:false}
        })
      console.log(payload)
      if(_.size(editList) > 0)
          alert('Asset(s) have already been assigned to beneficary(s).Please delete the existing assignment to continue.')
      else{
          payload.map(v => {
              return (
                parseFloat(v.assetPercentage))
            })
          .reduce(function (a, b) {
              return a + b;
          }, 0);
          if(payload.length === 0)
            alert('No beneficiary has been assigned to this asset, please check.')
        else {
              createAssign(payload)
              .then(resp=>{
                  console.log(resp.data)
                  dispatchAddList(resp.data)
                  alert('Record Updated Successfully')
                  onAction()
              })
              .catch(err=>{
                  console.log(err)
                  alert(err)
              })
        }
      }
    };
    return(
        assignmentList.map((e,i)=>{
          return <div style={{marginBottom:20}}> <SectionContainer index={i} onSubmit={onSubmit} disabled={true} mainTitle={e.assetIssuerName ? e.assetIssuerName : e.assetAddress1 ? e.assetAddress1 : e.assetDetail} data={beneficiaryData.map(b=>{ return {...b, assetId:e.id, assetIssuerName: e.assetIssuerName ? e.assetIssuerName : e.assetAddress1? e.assetAddress1 : e.assetDetail , assetDescription: e.assetDescription? e.assetDescription : ''}})} ref={refIndividualAssign[i]}/></div>;
        })
    );
  }
  
  

// Handle individual section separately
const SectionContainerTable = ({ data,mainTitle, disabled, onSubmit, index },ref) => {
  let [selected, setSelected] = useState(data);
  let [total, setTotal] = useState(0);
  useImperativeHandle(ref, () => ({
    returnDetails() {
      return selected
    }
  }));
  useEffect(()=>{
    let sum = selected.map(v => {
      return (
        parseFloat(v.assetPercentage))
    }).filter(Boolean)
      .reduce(function (a, b) {
        return a + b;
      }, 0)
        
   setTotal(isNaN(sum)? 0 : sum)
  }, [selected]);
  const onChange = (target, value, index, isText) => {
    let result = selected;
    result[index] = { ...selected[index], [target]: value };

    setSelected([...result]);
  };
  
  return (
    // <div>
    //   <div>
    //     <p className='mb-0'>
    //       {mainTitle}
    //       <span
    //         style={{
    //           margin: '0 5px',
    //           fontSize: 12,
    //           color: '#007bff',
    //           fontWeight: 400,
    //         }}
    //       >
    //         Select beneficiary to add the assigment ratio
    //       </span>
    //     </p>
        <CollapseSection mainTitle={mainTitle? mainTitle :''}subtitle={ <HighlightedText>Select beneficiary to add the assigment ratio</HighlightedText>}>
      {/* </div> */}
      <Table boder='1' responsive  size="sm">
        <tbody>
        <tr style={{ fontSize: 13, color: '#767676', fontWeight: 'bold' }}>
          <td />
          <td align="left">Name</td>
          <td align="left">Relation</td>
          <td align="left">Age</td>
          <td align="center">Ratio</td>
        </tr>
        </tbody>
        <tbody>
          <Rows data={selected} event={onChange} disabled={disabled} />
          <tr style={{ fontSize: 13 }}>
            <td colSpan={4} align="right">
              Total
            </td>
            <td align="center">{total}</td>
          </tr>
        </tbody>
      </Table>
      <CustomButtom title="Save" event={()=>onSubmit(index)}  />
    </CollapseSection>
  );
};
export const SectionContainer = forwardRef(SectionContainerTable);

const Rows = ({ data, event, disabled }) => {
  let [selectedRow , setSelectedRow] = useState([]);

  return data.map((e, i) => (
    <tr key={i} style={{ fontSize: 12 }}>
      <td align="center">
        <input type="checkbox" disabled={disabled && _.size(selectedRow) >0 &&  !_.includes(selectedRow, e.userBeneficiariesId) } defaultChecked={e.isChecked} key={e.userBeneficiariesId}  onChange={(eventVal) => {
          event('isChecked', eventVal.target.checked, i)
          
            if(eventVal.target.checked && disabled) event('assetPercentage', '100', i)
            else  event('assetPercentage', '', i)
            if( disabled && eventVal.target.checked ) setSelectedRow([...selectedRow,e.userBeneficiariesId])
            else setSelectedRow(selectedRow.filter(s=>s !==e.userBeneficiariesId))

        }} />
      </td>
      <td align="left">{getContactFullName(e.contacts)}</td>
      <td align="left">{getRelation(e.contacts)}</td>
      <td align="left">{e.contacts.age}</td>
      <td align="center" width={70}>
        <input type ="number" value={e.assetPercentage} style={{border:'none',textAlign:'center'}} onChange={(eventVal) => event('assetPercentage', eventVal.target.value, i)} disabled={disabled ? disabled : !e.isChecked}/>
      </td>
    </tr>
  ));
};

const mapStateToProps = (state) => ({
  immPropAssetList: getStoreImmovableAssetList(state),
  finAssetList: getStoreFinAssetList(state),
  otherAssetList: getStoreOtherAssetList(state),
  digitalAssetList : getStoreDigitalAssetList(state),
  beneficiariesList: getStoreBeneficiaryList(state),
  userId: getStoreUserId(state),
  finAssignList : getStoreFinAssignList(state),
  immPropAssignList: getStoreImmPropAssignList(state),
  otherAssignList: getStoreOtherAssignList(state),
  digitalAssignList: getStoreDigitalAssignList(state),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchSetBeneficiaryList: beneficiary => dispatch(setBeneficiaryData(beneficiary)),
  dispatchSetFinAssignList: finAssign=> dispatch(setFinAssignData(finAssign)),
  dispatchAddFinAssign: finAssign=> dispatch(addFinAssign(finAssign)),
  dispatchAddImmovableAssign: immProp => dispatch( addImmPropAssign(immProp)),
  dispatchSetImmovableAssignList: immProp => dispatch( setImmPropAssignData(immProp)),
  dispatchSetOtherAssignList:otherAssign=>dispatch(setOtherAssignData(otherAssign)),
  dispatchAddOtherAssign:otherAssign=>dispatch(addOtherAssign(otherAssign)),
  dispatchSetDigitalAssignList:digitalAssign=>dispatch(setDigitalAssignData(digitalAssign)),
  dispatchAddDigitalAssign:digitalAssign=>dispatch(addDigitalAssign(digitalAssign)),
})
 export default connect(mapStateToProps, mapDispatchToProps)(AssignForms);
