import initialState from './initialState'
import { ADD_LEGALREVIEW_CONSTANTS } from '../actions/freeLegalReviewAction'
export default (state = initialState.legalReview, action) => {
  switch (action.type) {
    case ADD_LEGALREVIEW_CONSTANTS:
      return { ...state, ...action.legalReview }
    default:
      return state
  }
}
