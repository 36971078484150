import React, { forwardRef, useRef, useState } from 'react';
import CollapseSection from "../../../../components/CollapsibleSection";
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import { useInput } from "../../../../hooks/useInput";
import DropDown from '../../../../components/DropDown';
import string from '../../../../constant/en.json';
import { connect } from 'react-redux';
import { deleteFinAssetFromList, editFinAsset, addFinAsset, getStoreFinAssetList } from '../../../../actions/finAssetsActions';
import { getStoreUserId, getStoreUserRole } from '../../../../selectors/userSelectors';
import { AlignColumn, CenterAlignRow, TableContainer, ContainerPosition, CustomButtom, ActionItemUpload } from '../../../../components/CustomElements';
import { getContactFullName, getId, batchState } from '../../../../utils/commonUtil';
import { deleteFinAsset, updateFinAssetById, createFinAsset } from '../finAssetService';
import PickContactTable from '../../../components/PickContactTable';
import { getStoreContactList } from '../../../../actions/contactActions';
import { getStoreFamily } from '../../../../actions/familyActions';
import _ from 'lodash';
import HelperModal from '../../../helper/HelperModal';
import SliderBar from '../../../../components/UploadComponent';
import { useQuaishToasts } from '../../../../actions/alertAction';
import ModalContainer from '../../../../home/component/layout/Modal'
import DataShareModal from '../../../../dashboard/forms/dataShare/DataShareModal'

const Gratuity = ({userId, pickList, gratuityFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, dispatchDeleteFinAsset, userRole}, ref) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [gratuity, setGra] = useState();
  let [visibleDataShare, setVisibleDataShare] = useState(false)
  let toggleDataShare = () => setVisibleDataShare(!visibleDataShare)
  const [dataShareId, setDataShareId] = useState('')

  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && gratuity &&  gratuity.id ) {
        setGra(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setGra(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
      setGra(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const onShare = (c) => {
    setDataShareId(c)
    toggleDataShare()
  }
       
  const docsRef = React.useRef()
  const onUpload = (c) => {
    docsRef.current.submit('GRA', c.id, "Upload Files")
  }
  return (
    <div>
        <SavedGratuity  onUpload={onUpload} data={gratuityFinAssetList} onEdit={onEdit} onShare={onShare} isFormHidden={isFormHidden} dispatchDeleteFinAsset={dispatchDeleteFinAsset} />
        <div  style={{ marginBottom: 20, display: 'block' }}>
          <ContainerPosition>
            <CustomButtom event={() => toggleForm()} title={'Add Gratuity'} />
          </ContainerPosition>
        </div>
        {isFormHidden ? null : <GratuityFrm gratuity={gratuity} userId={userId} onCancel={toggleForm} onDone={onDone} gratuityFinAssetList={gratuityFinAssetList} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole}/>}
        <SliderBar ref={docsRef} />
        {visibleDataShare
                  ? 
                  <ModalContainer visible={visibleDataShare} toggle={toggleDataShare}>
                    <DataShareModal visibleDataShare={visibleDataShare} toggleDataShare={toggleDataShare} dataShareId={dataShareId} AssetList={gratuityFinAssetList}  assetLookupKey={"asset_fin"} assetLookupCode={'GRA'}/>
                  </ModalContainer> 
                  : null 
        }
    </div>
  );
}

const GratuityFrm = ({gratuity,userId, pickList, onCancel, onDone, gratuityFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, userRole, displayNominee}, ref) => {
  const { value: assetIssuerName, bind: bind2 } = useInput(!gratuity ? '' : gratuity.assetIssuerName);
  const { value: assetIdentificationNumber, bind: bind1 } = useInput(!gratuity ? '' : gratuity.assetIdentificationNumber);
  const { value: pickedNominee, bind: bindNominee } =useInput(userRole === 'RECEIVER' ? null : !gratuity ? 'INDV' : gratuity['contacts']['contactLookupCode']);
  const selectedId =  userRole === 'RECEIVER' ? null : !gratuity ? '' : gratuity['contacts']['id']
  const refPickNominee = useRef();
  const { success, error, removeAll } = useQuaishToasts()
  /*const validate=(payload)=>{
    const ev=[]
    for (var key in payload) {
      switch(key){
          case 'assetIssuerName':
            _.isEmpty(payload[key])  ? ev.push(string.forms.gratuityDetails.emptyissuerName):null
            break
          case 'assetIdentificationNumber':
            _.isEmpty(payload[key]) ? ev.push(string.forms.gratuityDetails.emptyIdNo):null
            break
          case 'contacts':
            payload[key]['id']=== undefined? ev.push(string.forms.gratuityDetails.emptyNominee):null
            break
          default:
            break
          } 
        }
          if(ev.length>0){
            warn(ev)
            return false
          }
          else return true
}*/
  const submit = () => {
    removeAll()
    const roleOfUser= userRole==='GIVER' ? false : true
    const payload = {
      userId,
      assetLookupCode: 'GRA',
      receiverFlag:	roleOfUser,
      assetIssuerName,
      assetIdentificationNumber,
      contacts: roleOfUser ? null : displayNominee === 'no' ? _.isEmpty(gratuity) || _.isEmpty(gratuity.contacts) ? null : { id: gratuity['contacts']['id'] } : { id: _.head(refPickNominee.current.returnDetails()) }
    }
    console.log(payload)
    //if(validate(payload))

    getId(gratuity) ? updateFinAssetById(gratuity.id,{id:gratuity.id ,...payload})
    .then(response => {
        success('Record Updated Successfully');
        dispatchEditFinAsset(response.data)
        onDone()
    }).catch(err => {
        error(err)
    }) : 
    createFinAsset(payload)
    .then(response => {
      success('Record Created Successfully');
      dispatchAddFinAsset(response.data);
      onDone()
        // dispatchSetAllContactList(response.data);
    })
    .catch(err => {
      error(err)
    })
}
    return <CollapseSection  mainTitle={'Enter ' + string.forms.gratuityDetails.gratuityFormTitle} >
      <section className='section_form'>
        <div>
          <HeadBar title={string.forms.gratuityDetails.identificationNo} subtitle={'*'} />
        </div>
        <div className='row'>
          <Input type='text' placeholder={string.forms.gratuityDetails.identificationNo} isBigger bind={bind1} />
        </div>
        <div>
          <HeadBar title={string.forms.gratuityDetails.issuerName} subtitle={'*'} />
        </div>
        <div className='row'>
          <Input type='text' placeholder={string.forms.gratuityDetails.issuerName} isBigger bind={bind2} />
        </div>
        {userRole === 'GIVER' && displayNominee !== 'no'?
      <div>
        <div className="row" style={{marginLeft:'2px'}}>
        <HeadBar title={'Who is the nominee'} subtitle={''} /> 
        <HelperModal helperTextId={"nominee"} />
      </div>
      <div className='row'>
      <DropDown options={[{ value: 'Individual', code: 'INDV' }, { value: 'Institution', code: 'INST' }]} placeholder={string.forms.commonFormFields.nominee} isBigger bind={bindNominee} />
      </div>
      <PickContactTable isSingle data={pickList.filter(c => _.get(c, ['contactLookupCode']) === pickedNominee)} ref={refPickNominee} selected={[selectedId]} />
      </div>
      : null
    }
      </section>
    <ContainerPosition>
            <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
            <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
        </ContainerPosition>
    </CollapseSection>
  }
  export const GratuityForm = forwardRef(GratuityFrm);
  export function SavedGratuity ({onUpload, data, dispatchDeleteFinAsset, onEdit, dispalyActions, onShare, datashare, dataShareByMe, accessLevelArray, toggle }) {
    const { success, error } = useQuaishToasts()
    const deleteGRA = (id) =>{
      if(window.confirm("Delete?\n Are you sure you want to delete this record?")){
          deleteFinAsset(id)
          .then(response => {
            success('Record Deleted Successfully');
            dispatchDeleteFinAsset(id);
  
          })
          .catch(err => {
            error(err)
          })
      }
    }
  
    const row = data.map((e) => {
      return(
        
      <TableContainer>
        <CenterAlignRow>
          <AlignColumn label="Issuer Name" text={e.assetIssuerName} />
          <AlignColumn label="ID No." text={e.assetIdentificationNumber} />
          <AlignColumn label="Nominee" text={e.contacts !== null ?getContactFullName(e.contacts) : ''} />
          {datashare === 'yes' ? <AlignColumn label="Shared with" text={_.isEmpty(dataShareByMe[e.id][0]) ? null : dataShareByMe[e.id][0].map((f,index)=>(<span style={{cursor:'pointer', color: '#007bff'}} onClick={()=>toggle(dataShareByMe[e.id][1][index])}><span style={{textDecoration:'underline'}}>{f}</span><span>{index !== (dataShareByMe[e.id][0].length) -1 ? ' , ' : ''}</span></span>))} />: null}
          {datashare === 'shareby' ? <AlignColumn label="Shared By" text={dataShareByMe[e.id]} />: null}
          {dispalyActions === 'no' || datashare === 'yes' || (datashare === 'shareby' && accessLevelArray[e.id]==='READ')  ? null :
          <AlignColumn label="Action" text={<ActionItemUpload onUpload={onUpload} onEdit={()=>onEdit(e)} onDelete={()=>deleteGRA(e.id)} onShare={() => onShare(e.id)} datashare={datashare}/>} />}
          </CenterAlignRow>
      </TableContainer>
    )});
    return (
      data.length === 0 ? null :
      <div>
      <CollapseSection isHidden={data.length === 0} mainTitle={'Gratuities'} collapsed={false} onShare={() => onShare('multicreate')} isCollapsable={true} customStyle={true}  datashareuser={datashare}  datashare={'yes'}>
        {row}
      </CollapseSection>
            {dispalyActions === 'no' || datashare === 'yes' || datashare === 'shareby' ? <br/> : null }
       </div>
    );
  };
   const mapStateToProps = (state) => ({
    gratuityFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'GRA'),
    userId: getStoreUserId(state),
    pickList: (getStoreContactList(state).concat(getStoreFamily(state))),
    userRole:getStoreUserRole(state),
  });
  const mapDispatchToProps = () => dispatch => ({
    dispatchAddFinAsset: finAsset => dispatch(addFinAsset(finAsset)),
    dispatchEditFinAsset:finAsset=>dispatch(editFinAsset(finAsset)),
    dispatchDeleteFinAsset:finAsset=>dispatch(deleteFinAssetFromList(finAsset)),
  })
   export default connect(mapStateToProps, mapDispatchToProps)(Gratuity);
  
