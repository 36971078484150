import React, { forwardRef, useRef } from 'react';
import { connect } from 'react-redux';
import { createFamily, editFamily } from '../../../actions/familyActions';
import CheckBox from '../../../components/CheckBox';
import CollapseSection from '../../../components/CollapsibleSection';
import { ContainerPosition, CustomButtom } from '../../../components/CustomElements';
import HeadBar from '../../../components/HeaderBar';
import string from '../../../constant/en.json';
import { getMapping, getValue, getId } from '../../../utils/commonUtil';
import AgeDobAndMarriage from '../../components/AgeDobAndMarriage';
import Identification from '../../components/Identification';
import NameAndGender from '../../components/NameAndGender';
import ReligionNationality from '../../components/ReligionNationality';
import { createContact, updateContactById } from '../contacts/ContactService';
import HelperModal from '../../helper/HelperModal';
import { useQuaishToasts } from '../../../actions/alertAction';

const MyChildrenForm = ({ onDone, onCancel, dispatchCreateFamily, dispatchEditFamily, userId, data = { age: 0, maritalStatus: '', custField1: '', religion: '', nationality: '', relationship: '', title: '', firstName: '', lastName: '', middleName: '', idProofNumber: '', idProofType: '', adopted: false, disabled: false } }, ref) => {
  const { age, title, middleName, firstName, lastName, religion, nationality, idProofNumber, idProofType, gender, id, dob, maritalStatus, custField1 } = data;
  const refReligionNationality = useRef();
  const refId = useRef();
  const refAgeDOB = useRef();
  const refNameAndGender = useRef();
  const refAdopted = useRef();
  const refDisabled = useRef();
  const { success, error, removeAll } = useQuaishToasts()
  const submit = () => {
    removeAll()
    let payload = {
      userId,
      adopted: getValue(refAdopted),
      disabled: getValue(refDisabled),
      relationship: 'CHILD',
      contactLookupCode: 'INDV',
      ...getValue(refAgeDOB),
      'gender': refNameAndGender.current.returnDetails().gender,
      ...getValue(refReligionNationality),
      ...getValue(refNameAndGender).name,
      ...getMapping(['idProofNumber', 'idProofType'], refId),
    }
    getId(data) ? updateContactById(id, payload)
      .then(response => {
        success('Record Updated Successfully');
        dispatchEditFamily(response.data)
        onDone()
      }).catch(err => {
        error(err)
      }) : createContact(payload)
        .then(response => {
          success('Record Created Successfully');
          dispatchCreateFamily(response.data);
          onDone()
        })
        .catch(err => {
          error(err)
        })
  }
  return (
    <CollapseSection mainTitle={'Enter Your Child Details'} subtitle={'(Click on save to add more children)'} isCollapsable>
      <section className='section_form'><HeadBar title={`Child's Name*`} />
        <NameAndGender ref={refNameAndGender} defaultValue={{ gender, name: { title, firstName, middleName, lastName } }} />
        <AgeDobAndMarriage defaultValue={{ age, dob, custField1, maritalStatus }} ref={refAgeDOB} />
        <ReligionNationality defaultValue={{ religion, nationality }} ref={refReligionNationality} />
        <Identification defaultValue={{ idProofNumber, idProofType }} ref={refId} />
        <div className='row'  style={{marginLeft:'2px'}}>
        <CheckBox title={'Adopted'} defaultValue={data.adopted} ref={refAdopted} noClass />
        <HelperModal helperTextId={'adopted'}/>
        <div className="row"  style={{marginLeft:'20px'}}>
        <CheckBox title={'Differently abled'} defaultValue={data.disabled} ref={refDisabled} noClass/>
        <HelperModal helperTextId={'disabled'}/>
        </div>
        </div>
      </section>
      <ContainerPosition>
        <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
        <CustomButtom event={() => onCancel()} title={string.forms.commonFormFields.cancel} />
      </ContainerPosition>
    </CollapseSection>
  );
};
const MyChildren = forwardRef(MyChildrenForm);
const mapDispatchToProps = () => dispatch => ({
  dispatchCreateFamily: parent => dispatch(createFamily(parent)),
  dispatchEditFamily: parent => dispatch(editFamily(parent)),
})
export default connect(null, mapDispatchToProps)(MyChildren);

