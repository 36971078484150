import React, { useImperativeHandle, forwardRef, useState, useCallback } from 'react';
import HeadBar from '../../components/HeaderBar';
import NameDetails from '../components/NameDetails';
import string from '../../constant/en.json';
import _ from 'lodash';

export const NameFrm = ({ defaultValue }, ref) => {
  let [nameDetails,setNameDetails]=useState()
  const nameDeets = useCallback(node => {
    if (node !== null && node.returnDetails() && !_.isEqual( node.returnDetails() , nameDetails)) {
      let {firstName, lastName, middleName, title} = node.returnDetails()
      if((firstName !=="" || lastName !== "" || middleName !== "" || title !== ""))
        setNameDetails(node.returnDetails())      
    }
  },[nameDetails]);
  useImperativeHandle(ref, () => ({
    returnDetails() { return nameDetails }
  }));
  return (<div>
    <section className='section_form'>
      <HeadBar title={string.forms.commonFormFields.name} subtitle={string.forms.commonFormFields.mName} />
      <NameDetails defaultValue={defaultValue} ref={nameDeets} />
    </section>
  </div>
  );
};
const NameForm = forwardRef(NameFrm);
export default NameForm;