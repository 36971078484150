import React from 'react';

const HeadBar = ({title, subtitle }) => (
    <div>
      <label style={{ fontSize: '16px' }}>
        {title}
        <span style={{ marginLeft: '5px', color: '#007bff', fontSize: '13px' }}>{subtitle}</span>{' '}
      </label>
    </div>
  );
  export default HeadBar;