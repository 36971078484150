import React from 'react'
import GetStartedTemplate from './template'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useInput } from '../../hooks/useInput'
import Input from '../Input'
import relationship from '../../resources/relationships.json'
import DropDown from '../DropDown'
import { useHistory, Link } from 'react-router-dom'
import Auth from '@aws-amplify/auth'
import { AWS_CONFIG } from '../../constant/ServerConst'
import { Post } from '../../utils/ApiUtil'
import { getSplitName, getContactFullName } from '../../utils/commonUtil'
import { useDispatch } from 'react-redux'
import ModalContainer from '../../home/component/layout/Modal'
import Login from '../../auth/Login'
import {
  createCaregiverAndEmergencyContact,
  createNeighbourContact,
  createBankAccAsset,
  createInsuranceAsset,
  createPasswordManager,
  mailList,
} from './CreateApis'
Auth.configure({ ...AWS_CONFIG.Auth })

const GetStarted = ({ user, onSubmitBtn, toggleModal, dashboardSignUp }) => {
  const history = useHistory()
  //const dispatchData = useDispatch()
  const [page, setPage] = useState(0)
  const [healthIns, setHealthIns] = useState(true)
  const [userId, setUserId] = useState(user ? user.id : null)
  const [passwordStoredIn, setPasswordStoredIn] = useState('')
  const { value: userDetailsNameValue, bind: userDetailsName } = useInput(
    dashboardSignUp ? '' : user ? getContactFullName(user) : ''
    ),
    { value: userDetailsEmailValue, bind: userDetailsEmail } = useInput(
      user ? user.primaryEmail : ''
    ),
    { value: userDetailsMobileValue, bind: userDetailsMobile } = useInput(
      user ? user.mobilePhone : '','mobile'
    ),
    {
      value: emergencyContactsNameValue,
      bind: emergencyContactsName,
    } = useInput(),
    {
      value: emergencyContactsRelationshipValue,
      bind: emergencyContactsRelationship,
    } = useInput(),
    {
      value: dependentGaurdianNameValue,
      bind: dependentGaurdianName,
    } = useInput(),
    {
      value: dependentGaurdianRelationshipValue,
      bind: dependentGaurdianRelationship,
    } = useInput(),
    {
      value: lifeInsuranceProviderValue,
      bind: lifeInsuranceProvider,
    } = useInput(),
    {
      value: lifeInsurancePolicyNumberValue,
      bind: lifeInsurancePolicyNumber,
    } = useInput(),
    {
      value: healthInsuranceProviderValue,
      bind: healthInsuranceProvider,
    } = useInput(),
    {
      value: healthInsurancePolicyNumberValue,
      bind: healthInsurancePolicyNumber,
    } = useInput(),
    { value: passwordManagerValue, bind: passwordManager } = useInput(),
    {
      value: financialBasicPrimaryBankAccValue,
      bind: financialBasicPrimaryBankAcc,
    } = useInput(),
    {
      value: financialBasicPrimaryBankAccNoValue,
      bind: financialBasicPrimaryBankAccNo,
    } = useInput(),
    {
      value: financialBasicMortgagePaymentBankValue,
      bind: financialBasicMortgagePaymentBank,
    } = useInput(),
    {
      value: financialBasicMortgagePaymentBankNoValue,
      bind: financialBasicMortgagePaymentBankNo,
    } = useInput(),
    {
      value: financialBasicOtherCriticalBankAccValue,
      bind: financialBasicOtherCriticalBankAcc,
    } = useInput(),
    {
      value: financialBasicOtherCriticalBankAccNoValue,
      bind: financialBasicOtherCriticalBankAccNo,
    } = useInput(),
    {
      value: neighbourDetailsNameValue,
      bind: neighbourDetailsName,
    } = useInput(),
    {
      value: neighbourDetailsMobileNumberValue,
      bind: neighbourDetailsMobileNumber,
    } = useInput()

  const onSubmit = () => setPage(page + 1)

  const createUser = async () => {
    const re = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/g
    const phoneFormat = ((/^[0-9\b]+$/).test(userDetailsMobileValue)) || ((/^[+|-][0-9\b]+$/).test(userDetailsMobileValue))
    let emailIDs = userDetailsEmailValue.toLowerCase().trim();

    if (
      re.test(String(userDetailsEmailValue).toLowerCase()) &&
      phoneFormat
    ) {
      let awsPayload = {
        username: emailIDs,
        password: 'Welcome@01',
        attributes: {
          email: emailIDs,
          phone_number: ((/^[+|-][0-9\b]+$/).test(userDetailsMobileValue)) ? userDetailsMobileValue : '+91' + userDetailsMobileValue,
          given_name: userDetailsNameValue,
        },
      }
      console.log(awsPayload)
      Auth.signUp(awsPayload)
        .then((response) => {
          console.log('aws signup complete')
          console.log(response)
          Post('userprofile/create?userId=' + response.userSub, {
            userName: response.userSub,
            primaryEmail: emailIDs,
            mobilePhone: ((/^[+|-][0-9\b]+$/).test(userDetailsMobileValue)) ? userDetailsMobileValue : '+91' + userDetailsMobileValue,
            custField1: 'JUST_IN_CASE_DONE',
            ...getSplitName(userDetailsNameValue),
          })
            .then((resp) => {
              console.log('backend user create complete')
              console.log(resp)
              setUserId(resp.data.id)
              onSubmit()
            })
            .catch((error) => {
              console.log('backend error')
              console.log(error)
              alert('Unable to register, please try again!')
            })
        })
        .catch((error) => {
          console.log('aws error')
          console.log(error)
          if (error.code === 'UsernameExistsException') {
            dashboardSignUp ? setPage(12): history.push('/')
          } else alert(error.message)
        })
      // onSubmit()
    } else {
      if (!phoneFormat)
        alert('please enter valid phone number')
      else
        alert(
          'Please enter a valid email address. You could be missing something in the e-mail format.'
        )
    }
  }
  const callApi = async () => {
    let data = {
      userId,
      userDetails: {
        name: userDetailsNameValue,
        email: userDetailsEmailValue.toLowerCase().trim(),
        phoneNo: ((/^[+|-][0-9\b]+$/).test(userDetailsMobileValue)) ? userDetailsMobileValue : '+91' + userDetailsMobileValue,
      },
      emergencyContacts: {
        name: emergencyContactsNameValue,
        relationship: emergencyContactsRelationshipValue,
      },
      dependentGaurdian: {
        name: dependentGaurdianNameValue,
        relation: dependentGaurdianRelationshipValue,
      },
      lifeInsurance: {
        insuranceProvider: lifeInsuranceProviderValue,
        policyNumber: lifeInsurancePolicyNumberValue,
      },
      HealthInsurance: {
        insuranceProvider: healthInsuranceProviderValue,
        policyNumber: healthInsurancePolicyNumberValue,
      },
      financialBasic: {
        primaryBankAcc: financialBasicPrimaryBankAccValue,
        primaryBankAccNo: financialBasicPrimaryBankAccNoValue,
        mortgagePaymentBank: financialBasicMortgagePaymentBankValue,
        mortgagePaymentBankNo: financialBasicMortgagePaymentBankNoValue,
        otherCriticalBankAcc: financialBasicOtherCriticalBankAccValue,
        otherCriticalBankAccNo: financialBasicOtherCriticalBankAccNoValue,
      },
      passwordManager: {
        passwordStoredIn,
        passwordLocation: passwordManagerValue,
      },
      neighbourDetails: {
        name: neighbourDetailsNameValue,
        mobileNumber: neighbourDetailsMobileNumberValue,
      },
    }
    console.log(JSON.stringify(data))
    if (emergencyContactsNameValue != '')
      createCaregiverAndEmergencyContact(
        emergencyContactsNameValue,
        emergencyContactsRelationshipValue,
        userId
      )
    if (dependentGaurdianNameValue != '')
      createCaregiverAndEmergencyContact(
        dependentGaurdianNameValue,
        dependentGaurdianRelationshipValue,
        userId
      )
    if (neighbourDetailsNameValue != '')
      createNeighbourContact(
        neighbourDetailsNameValue,
        neighbourDetailsMobileNumberValue,
        userId
      )
    if (lifeInsuranceProviderValue != '')
      createInsuranceAsset(
        lifeInsuranceProviderValue,
        lifeInsurancePolicyNumberValue,
        userId
      )
    if (healthInsuranceProviderValue != '')
      createInsuranceAsset(
        healthInsuranceProviderValue,
        healthInsurancePolicyNumberValue,
        userId
      )
    if (financialBasicPrimaryBankAccValue != '')
      createBankAccAsset(
        financialBasicPrimaryBankAccValue,
        financialBasicPrimaryBankAccNoValue,
        userId
      )
    if (financialBasicMortgagePaymentBankValue != '')
      createBankAccAsset(
        financialBasicMortgagePaymentBankValue,
        financialBasicMortgagePaymentBankNoValue,

        userId
      )
    if (financialBasicOtherCriticalBankAccValue != '')
      createBankAccAsset(
        financialBasicOtherCriticalBankAccValue,
        financialBasicOtherCriticalBankAccNoValue,
        userId
      )
    if (passwordManagerValue != '')
      createPasswordManager(passwordStoredIn, passwordManagerValue, userId)
    mailList(data)
    onSubmit()
  }
  const redirectHomePage = () =>{
    history.push('/')
  }
  let [visibleLogin, setVisibleLogin] = useState(false)
  let toggleLogin = () => setVisibleLogin(!visibleLogin)

  const onBack = () => setPage(page - 1)
  const renderPage = () => {
    switch (page) {
      case 0:
        return (
          <GetStartedTemplate
            title={"Let's get started!"}
            bar={5}
            body={
              <div>
                <p style={{ textAlign: 'left', fontSize: 14 }}>
                  In the next few minutes, we're going to help you compile a
                  list of simple yet useful bits of information. 
                  <br /> <br></br>
                  E.g which doctor to contact? Or which bank account to access? This List 
                  securely shares these important and carefully curated details with people who will need them. Just In Case !
                  <br /> <br></br>
                  You can skip questions you're not ready to answer and come
                  back to them later before sending your list.
                  <br /> (Or leave them blank if they don't apply.)
                  <br />
                </p>
                <Button
                  onClick={onSubmit}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                >
                  Get Started
                </Button>
                <br />
                {onSubmitBtn ? (
                  <Button
                    className='btn btn-outline-primary'
                    onClick={toggleModal}
                    style={{ paddingLeft: 40, paddingRight: 40 }}
                  >
                    Later
                  </Button>
                ) : 
                <Button
                className='btn btn-outline-primary'
                onClick={redirectHomePage}
                style={{ paddingLeft: 40, paddingRight: 40 }}
              >
                Later
              </Button>
                }
                <p style={{ textAlign: 'left' }}></p>
              </div>
            }
          />
        )
      case 1:
        return (
          <GetStartedTemplate
            title={'Emergency Contacts'}
            bar={10}
            body={
              <div>
                { onSubmitBtn ? (<button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>)
                :
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={redirectHomePage}>&times;</button>
                }
                <p style={{ textAlign: 'left', fontSize: 14 }}>
                  If an emergency struck, who should know this information most
                  ?
                  <br />
                  <br />
                  It might not be an obvious choice. Your spouse or partner
                  might already know all this info. What about your children,
                  parents, a best friend, or another person you trust to come
                  through in a pinch ?
                  <br />
                  <br />
                  Or you can keep it for yourself and share it later.
                </p>

                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>What's their name?</b>
                  </label>
                  <Input
                    noClass
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={emergencyContactsName}
                  />
                  <small className='form-text text-muted'>
                    You can change this anytime later.
                  </small>
                </div>
                <br />
                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>What is your relation?</b>
                  </label>
                  <DropDown
                    noClass
                    options={relationship}
                    placeholder={'Please Select'}
                    bind={emergencyContactsRelationship}
                  />
                </div>
                <Button
                  onClick={onBack}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                    marginRight: 50,
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={() => (dashboardSignUp ? onSubmit() : onSubmitBtn ? setPage(page + 2)  : onSubmit())}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                >
                  Continue
                </Button>
              </div>
            }
          />
        )
      case 2:
        return (
          <GetStartedTemplate
            title={'Securing your account'}
            bar={20}
            body={
              <div>
                { onSubmitBtn ? (<button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>)
                :
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={redirectHomePage}>&times;</button>
                }
                  <p style={{ textAlign: 'left', fontSize: 14 }}>
                  Let's set up your account so none of your information is lost.{' '}
                  <br />
                  Fear not, your info will be kept completely secure!
                </p>

                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>What's your name?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={userDetailsName}
                  />
                </div>
                <br />
                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>What is your email address?</b>
                  </label>
                  <Input
                    noClass
                    type='email'
                    className='form-control form-control-lg'
                    bind={userDetailsEmail}
                  />
                  <small className='form-text text-muted'>
                    We'll never share your email with anyone else.
                  </small>
                </div>
                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>What is your phone number?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={userDetailsMobile}
                  />
                  <small className='form-text text-muted'>
                    We'll never share your number with anyone else.
                  </small>
                </div>
                <Button
                  onClick={onBack}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                    marginRight: 50,
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={createUser}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                  disabled={
                    !(
                      userDetailsNameValue !== '' &&
                      userDetailsMobileValue !== '' &&
                      userDetailsEmailValue !== ''
                    )
                  }
                >
                  Continue
                </Button>
              </div>
            }
          />
        )
      case 3:
        return (
          <GetStartedTemplate
            title={'Health Insurance'}
            bar={30}
            body={
              <div>
                { onSubmitBtn ? (<button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>)
                :
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={redirectHomePage}>&times;</button>
                }
                  <p style={{ textAlign: 'left', fontSize: 14 }}>
                  Share the basic information about your health coverage so your
                  loved ones aren't scrambling for it in the worst possible
                  time. <br />
                  {/* Fear not, your info will be kept completely secure! */}
                </p>

                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>Who is your primary Medical Insurance Provider ?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={healthInsuranceProvider}
                  />
                </div>
                <br />
                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>Do you have your policy Number handy ?</b>
                  </label>
                  <Input
                    noClass
                    type='email'
                    className='form-control form-control-lg'
                    bind={healthInsurancePolicyNumber}
                  />
                </div>
                <Button
                  onClick={() => (onSubmitBtn ? setPage(page - 2) : onBack())}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                    marginRight: 50,
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={() => {
                    onSubmit()
                  }}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                >
                  Continue
                </Button>
              </div>
            }
          />
        )
      case 4:
        return (
          <GetStartedTemplate
            title={'Those in Your Care'}
            bar={40}
            body={
              <div>
                { onSubmitBtn ? (<button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>)
                :
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={redirectHomePage}>&times;</button>
                }
                <p style={{ textAlign: 'left', fontSize: 14 }}>
                  If you have any type of dependents -- kids, pets, parents --
                  who should take care of them if you couldn't ? <br />
                </p>

                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>Name of care-giver / guardian:</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={dependentGaurdianName}
                  />
                </div>
                <br />
                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>Relationship to you:</b>
                  </label>
                  <DropDown
                    noClass
                    options={relationship}
                    placeholder={'Please Select'}
                    bind={dependentGaurdianRelationship}
                  />
                </div>
                <Button
                  onClick={onBack}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                    marginRight: 50,
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={onSubmit}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                >
                  Continue
                </Button>
              </div>
            }
          />
        )
      case 5:
        return (
          <GetStartedTemplate
            title={'Financial Basic'}
            bar={50}
            body={
              <div>
                { onSubmitBtn ? (<button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>)
                :
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={redirectHomePage}>&times;</button>
                }
                  <p style={{ textAlign: 'left', fontSize: 14 }}>
                  Let's create a simple map of where you bank and invest. If
                  something happens to you, your family will know where to go
                  for help.
                  <br />
                  ☝️ We're only asking these questions for the purpose of
                  creating your list. Your data will only be visible to you and
                  the people you share the list with.
                </p>

                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>Which bank is your primary account ? </b>
                    <small>
                      (Primary account is typically for your day-to-day
                      expenses)
                    </small>
                  </label>
                  <div className='row'>
                    <div className='col-sm-6 col-md-5 offset-md-2 col-lg-6 offset-lg-0'>
                      <Input
                        noClass
                        placeholder='Bank Name'
                        type='text'
                        className='form-control form-control-lg'
                        bind={financialBasicPrimaryBankAcc}
                      />
                    </div>
                    <div className='col-sm-6 col-md-5 offset-md-2 col-lg-6 offset-lg-0'>
                      <Input
                        noClass
                        placeholder='Account No.'
                        type='text'
                        className='form-control form-control-lg'
                        bind={financialBasicPrimaryBankAccNo}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>Which bank pays your mortgage payments ?</b>
                    <small>
                      (Your home/car/education loans that need to be funded
                      consistently)
                    </small>
                  </label>
                  <div className='row'>
                    <div className='col-sm-6 col-md-5 offset-md-2 col-lg-6 offset-lg-0'>
                      <Input
                        noClass
                        placeholder='Bank Name'
                        type='text'
                        className='form-control form-control-lg'
                        bind={financialBasicMortgagePaymentBank}
                      />
                    </div>
                    <div className='col-sm-6 col-md-5 offset-md-2 col-lg-6 offset-lg-0'>
                      <Input
                        noClass
                        placeholder='Account No.'
                        type='text'
                        className='form-control form-control-lg'
                        bind={financialBasicMortgagePaymentBankNo}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>Any other critical bank accounts ?</b>
                    <small>
                      (Any other accounts that need to be accessed/funded)
                    </small>
                  </label>
                  <div className='row'>
                    <div className='col-sm-6 col-md-5 offset-md-2 col-lg-6 offset-lg-0'>
                      <Input
                        noClass
                        placeholder='Bank Name'
                        type='text'
                        className='form-control form-control-lg'
                        bind={financialBasicOtherCriticalBankAcc}
                      />
                    </div>
                    <div className='col-sm-6 col-md-5 offset-md-2 col-lg-6 offset-lg-0'>
                      <Input
                        noClass
                        placeholder='Account No.'
                        type='text'
                        className='form-control form-control-lg'
                        bind={financialBasicOtherCriticalBankAccNo}
                      />
                    </div>
                  </div>
                </div>
                <Button
                  onClick={onBack}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                    marginRight: 50,
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={onSubmit}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                >
                  Continue
                </Button>
              </div>
            }
          />
        )
      case 6:
        return (
          <GetStartedTemplate
            title={'Life Insurance'}
            bar={60}
            body={
              <div>
                { onSubmitBtn ? (<button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>)
                :
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={redirectHomePage}>&times;</button>
                }
                  <p style={{ textAlign: 'left', fontSize: 14 }}>
                  <b>Do you have a Life Insurance policy ? </b>(including the
                  one you might have gotten through your employer)
                </p>

                <div>
                  <Button
                    onClick={onSubmit}
                    style={{
                      paddingLeft: 40,
                      paddingRight: 40,
                      marginTop: 50,
                      marginBottom: 10,
                    }}
                  >
                    Yes, I have life insurance
                  </Button>
                </div>
                <div>
                  <Button
                    variant={'secondary'}
                    onClick={() => {
                      setPage(page + 2)
                      setHealthIns(false)
                    }}
                    style={{
                      paddingLeft: 40,
                      paddingRight: 40,
                      marginTop: 20,
                      marginBottom: 50,
                    }}
                  >
                    No
                  </Button>
                </div>
              </div>
            }
          />
        )
      case 7:
        return (
          <GetStartedTemplate
            title={'Life insurance'}
            bar={70}
            body={
              <div>
                { onSubmitBtn ? (<button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>)
                :
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={redirectHomePage}>&times;</button>
                }
                <p style={{ textAlign: 'left', fontSize: 14 }}>
                  Who provides your Life Insurance? This will let your family
                  know where to file a claim.
                </p>

                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>Who is your primary Life Insurance Provider ?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={lifeInsuranceProvider}
                  />
                </div>
                <br />
                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>Do you have your policy Number handy ?</b>
                  </label>
                  <Input
                    noClass
                    type='email'
                    className='form-control form-control-lg'
                    bind={lifeInsurancePolicyNumber}
                  />
                </div>
                <Button
                  onClick={onBack}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                    marginRight: 50,
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={onSubmit}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                >
                  Continue
                </Button>
              </div>
            }
          />
        )
      case 8:
        return (
          <GetStartedTemplate
            title={'Password manager'}
            bar={80}
            body={
              <div>
                { onSubmitBtn ? (<button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>)
                :
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={redirectHomePage}>&times;</button>
                }
                <p style={{ textAlign: 'left', fontSize: 14 }}>
                  Access to passwords, especially the unlock code to your phone,
                  can be a virtual lifesaver in an emergency.
                  <br />
                  ☝️ Again, this information will only be visible to you and the
                  people you share the list with.
                </p>
                <p>
                  <b>How do you keep track of all your passwords ?</b>
                </p>
                <div
                  style={{
                    border: '1px solid #ccc',
                    width: 400,
                    margin: '0 auto',
                    textAlign: 'left',
                  }}
                >
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='exampleRadios'
                      id='exampleRadios1'
                      value='Password Manager'
                      onChange={(event) =>
                        setPasswordStoredIn(event.target.value)
                      }
                    />
                    <label className='form-check-label' for='exampleRadios1'>
                      Password Manager (the name of the application is enough)
                    </label>
                  </div>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='exampleRadios'
                      id='exampleRadios1'
                      value='A file on your computer'
                      onChange={(event) =>
                        setPasswordStoredIn(event.target.value)
                      }
                    />
                    <label className='form-check-label' for='exampleRadios1'>
                      A file on your computer
                    </label>
                  </div>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='radio'
                      name='exampleRadios'
                      id='exampleRadios1'
                      value='Other Password Manager'
                      onChange={(event) =>
                        setPasswordStoredIn(event.target.value)
                      }
                    />
                    <label className='form-check-label' for='exampleRadios1'>
                      Other
                    </label>
                  </div>
                </div>
                {passwordStoredIn !== '' ? (
                  <div className='form-group' style={{ textAlign: 'left' }}>
                    <label>
                      {passwordStoredIn === 'Password Manager'
                        ? 'Name of password manager'
                        : passwordStoredIn === 'A file on your computer'
                        ? 'file name and location'
                        : 'Location of passwords'}
                    </label>
                    <Input
                      noClass
                      type='text'
                      className='form-control form-control-lg'
                      bind={passwordManager}
                    />
                  </div>
                ) : null}
                <Button
                  onClick={onBack}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                    marginRight: 50,
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={onSubmit}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                >
                  Continue
                </Button>
              </div>
            }
          />
        )
      case 9:
        return (
          <GetStartedTemplate
            title={'A Trusted Neighbor or Friend'}
            bar={90}
            body={
              <div>
                { onSubmitBtn ? (<button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>)
                :
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={redirectHomePage}>&times;</button>
                }
                <p style={{ textAlign: 'left', fontSize: 14 }}>
                  Your family might need to get in touch with someone who lives
                  near you if they can't physically get to you. Who should they
                  contact?
                </p>

                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>Name of contact:</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={neighbourDetailsName}
                  />
                </div>
                <br />
                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>Contact number ?</b>
                  </label>
                  <Input
                    noClass
                    type='email'
                    className='form-control form-control-lg'
                    bind={neighbourDetailsMobileNumber}
                  />
                </div>
                <Button
                  onClick={onBack}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                    marginRight: 50,
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={onSubmit}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                >
                  Continue
                </Button>
              </div>
            }
          />
        )
      case 10:
        return (
          <GetStartedTemplate
            title={'🎉 All done!'}
            bar={95}
            body={
              <div>
                { onSubmitBtn ? (<button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>)
                :
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={redirectHomePage}>&times;</button>
                }
                <p>Do you want to send the Just-In-Case List to yourself? </p>
                <Button
                  onClick={callApi}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                >
                  Send it to me
                </Button>
              </div>
            }
          />
        )
      case 11:
        return (
          <GetStartedTemplate
            title={'One more step'}
            bar={99}
            body={
              <div style={{ textAlign: 'left', fontSize: 14 }}>
                <p>
                  We compiled your Just-In-Case List and sent a personalized
                  link to{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {userDetailsEmailValue}
                  </span>
                  <br />
                  Please look for an email from{' '}
                  <span style={{ color: '#007bff', fontWeight: 'bold' }}>
                    EasyInherit
                  </span>{' '}
                  in your inbox.
                </p>
                {dashboardSignUp ? 
                 (
                  <div>
                    <p>
                      We have also created an account with us, you can verify
                      yourself with the verification sent to your email and
                      login to get started on your estate planning.
                    </p>
                    <p>
                      Your temporary password is:
                      <span style={{ fontWeight: 'bold' }}>Welcome@01</span>
                    </p>
                    <p>
                      <a
                        href='/dashboardsignup'
                        style={{
                          color: '#007bff',
                          textDecoration: 'underline',
                        }}
                      >
                        {' '}
                        Back to our homepage.
                      </a>
                    </p>
                  </div>
                )
                :
                onSubmitBtn ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      onClick={onSubmitBtn}
                      style={{
                        paddingLeft: 40,
                        paddingRight: 40,
                        marginTop: 50,
                        marginBottom: 50,
                      }}
                    >
                      Done
                    </Button>
                  </div>
                ) : (
                  <div>
                    <p>
                      We have also created an account with us, you can verify
                      yourself with the verification sent to your email and
                      login to get started on your estate planning.
                    </p>
                    <p>
                      Your temporary password is:
                      <span style={{ fontWeight: 'bold' }}>Welcome@01</span>
                    </p>
                    <p>
                      <a
                        href='/'
                        style={{
                          color: '#007bff',
                          textDecoration: 'underline',
                        }}
                      >
                        {' '}
                        Back to our homepage.
                      </a>
                    </p>
                  </div>
                )}
              </div>
            }
          />
        )
        case 12:
        return (
          <GetStartedTemplate
            title={'Account Exists'}
            bar={95}
            body={
              <div style={{ textAlign: 'left', fontSize: 14 }}>
                <div>
                  <Link
              className='top-nav-link'
              style={{ fontWeight: 500, marginRight: 25, color: '#000' }}
              onClick={toggleLogin}
            >
             <span style={{ color: '#007bff', fontWeight: 'bold' }}>
             Login
              </span>
            </Link>
            <ModalContainer visible={visibleLogin} toggle={toggleLogin}>
              <div style={{ padding: '40px' }}>
                <Login onClose={toggleLogin} />
              </div>
            </ModalContainer>
            </div>
                  <span style={{ fontWeight: 'bold' }}>
                  to get 30 minute FREE appointment{' '}
                  </span>
                  <p>
                  with our Estate planning consultants by selecting a slot on
                  the calendar once you exit this pop-up.
                  </p>
                  <br />
                <p>
                  Our Estate planning consultants will get back to you as soon
                  as possible.
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={toggleModal}
                    style={{
                      paddingLeft: 40,
                      paddingRight: 40,
                      marginTop: 50,
                      marginBottom: 50,
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            }
          />
        )
      // case '0': return  <GetStartedTemplate title={} bar={} body={}/>
      default:
        return null
    }
  }
  return renderPage()
}
export default GetStarted
