import React, { forwardRef, useState, useRef } from 'react';
import CollapseSection from "../../../../components/CollapsibleSection";
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import { useInput } from "../../../../hooks/useInput";
import DropDown from '../../../../components/DropDown';
import string from '../../../../constant/en.json';
import { batchState, getId, getContactFullName } from '../../../../utils/commonUtil';
import { ContainerPosition, CustomButtom, AlignColumn, CenterAlignRow, TableContainer, ActionItemUpload } from '../../../../components/CustomElements';
import PickContactTable from '../../../components/PickContactTable';
import { connect } from 'react-redux';
import { getStoreUserId, getStoreUserRole } from '../../../../selectors/userSelectors';
import { addFinAsset, editFinAsset, deleteFinAssetFromList, getStoreFinAssetList } from '../../../../actions/finAssetsActions';
import { deleteFinAsset, updateFinAssetById, createFinAsset } from '../finAssetService';
import _ from 'lodash';
import { getStoreContactList } from '../../../../actions/contactActions';
import { getStoreFamily } from '../../../../actions/familyActions';
import HelperModal from '../../../helper/HelperModal';
import SliderBar from '../../../../components/UploadComponent';
import { useQuaishToasts } from '../../../../actions/alertAction';
import ModalContainer from '../../../../home/component/layout/Modal'
import DataShareModal from '../../../../dashboard/forms/dataShare/DataShareModal'

const Portfolio = ({userId, pickList, portfolioFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, dispatchDeleteFinAsset, userRole}, ref) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [portfolio, setPortfolio] = useState();
  let [visibleDataShare, setVisibleDataShare] = useState(false)
  let toggleDataShare = () => setVisibleDataShare(!visibleDataShare)
  const [dataShareId, setDataShareId] = useState('')

  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && portfolio &&  portfolio.id ) {
        setPortfolio(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setPortfolio(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
      setPortfolio(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const onShare = (c) => {
    setDataShareId(c)
    toggleDataShare()
  }
       
  const docsRef = React.useRef()
  const onUpload = (c) => {
    docsRef.current.submit('PMS', c.id, "Upload Files")
  }
  return (
    <div>
        <SavedPortfolio onUpload={onUpload} data={portfolioFinAssetList} onEdit={onEdit} onShare={onShare} isFormHidden={isFormHidden} dispatchDeleteFinAsset={dispatchDeleteFinAsset} />
        <div  style={{ marginBottom: 20, display: 'block' }}>
          <ContainerPosition>
            <CustomButtom event={() => toggleForm()} title={'Add Portfolio Management Service'} />
          </ContainerPosition>
        </div>
        {isFormHidden ? null : <PortfolioForm portfolio={portfolio} userId={userId} onCancel={toggleForm} onDone={onDone} portfolioFinAssetList={portfolioFinAssetList} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} />}
        <SliderBar ref={docsRef} />
        {visibleDataShare
                  ? 
                  <ModalContainer visible={visibleDataShare} toggle={toggleDataShare}>
                    <DataShareModal visibleDataShare={visibleDataShare} toggleDataShare={toggleDataShare} dataShareId={dataShareId} AssetList={portfolioFinAssetList} assetLookupKey={"asset_digital"} assetLookupCode={'PMS'}/>
                  </ModalContainer> 
                  : null 
        }
    </div>
  );
}

const PortfolioFrm = ({portfolio, pickList,userId, onCancel, onDone, portfolioFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, userRole, displayNominee}, ref) => {
 
  const { value: assetIssuerName, bind: bindName } = useInput(!portfolio ? '' : portfolio.assetIssuerName);
  const { value: assetIdentificationNumber, bind: bindAccNo } = useInput(!portfolio ? '' : portfolio.assetIdentificationNumber);
  const { value: pickedNominee, bind: bindNominee } =useInput(userRole === 'RECEIVER' ? null : !portfolio ? 'INDV' : portfolio['contacts']['contactLookupCode']);
  const selectedId =  userRole === 'RECEIVER' ? null : !portfolio ? '' : portfolio['contacts']['id']
  const refPickNominee = useRef();
  const { success, error, removeAll } = useQuaishToasts()
  /*const validate=(payload)=>{
    const ev=[]
    for (var key in payload) {
      switch(key){
          case 'assetIssuerName':
            _.isEmpty(payload[key]) ? ev.push(string.forms.portfolioMgtServices.emptyName):null
            break
          case 'assetIdentificationNumber':
            _.isEmpty(payload[key]) ? ev.push(string.forms.portfolioMgtServices.emptyAccountNo):null
            break
          case 'contacts':
            payload[key]['id']=== undefined? ev.push(string.forms.portfolioMgtServices.emptyNominee):null
            break
          default:
            break
          } 
        }
          if(ev.length>0){
            warn(ev)
            return false
          }
          else return true
        }*/
  const submit = () => {
    removeAll()
    const roleOfUser= userRole==='GIVER' ? false : true
    const payload = {
      userId,
      assetLookupCode: 'PMS',
      receiverFlag:	roleOfUser,
      assetIssuerName,
      assetIdentificationNumber,
      contacts: roleOfUser ? null : displayNominee === 'no' ? _.isEmpty(portfolio) || _.isEmpty(portfolio.contacts) ? null : { id: portfolio['contacts']['id'] } : { id: _.head(refPickNominee.current.returnDetails()) }
    }
    console.log(payload)
    //if(validate(payload))

    getId(portfolio) ? updateFinAssetById(portfolio.id,{id:portfolio.id ,...payload})
    .then(response => {
        success('Record Updated Successfully');
        dispatchEditFinAsset(response.data)
        onDone()
    }).catch(err => {
        error(err)
    }) : 
    createFinAsset(payload)
    .then(response => {
      success('Record Created Successfully');
      dispatchAddFinAsset(response.data);
      onDone()
        // dispatchSetAllContactList(response.data);
    })
    .catch(err => {
      error(err)
    })
  }
  return <CollapseSection  mainTitle={string.forms.portfolioMgtServices.portfolioMgtServicesFormTitle} >
    <section className='section_form'>
      <div>
        <HeadBar title={string.forms.portfolioMgtServices.name} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.portfolioMgtServices.name} isBigger bind={bindName} />
      </div>
      <div>
        <HeadBar title={string.forms.portfolioMgtServices.accountNo} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.portfolioMgtServices.accountNo} isBigger bind={bindAccNo} />
      </div>
      {userRole === 'GIVER' && displayNominee !== 'no'?
      <div>
      <div className="row" style={{marginLeft:'2px'}}>
        <HeadBar title={'Who is the nominee'} subtitle={''} /> 
        <HelperModal helperTextId={"nominee"} />
      </div>
      <div className='row'>
      <DropDown options={[{ value: 'Individual', code: 'INDV' }, { value: 'Institution', code: 'INST' }]} placeholder={string.forms.commonFormFields.nominee} isBigger bind={bindNominee} />
      </div>
      <PickContactTable isSingle data={pickList.filter(c => _.get(c, ['contactLookupCode']) === pickedNominee)} ref={refPickNominee} selected={[selectedId]} />
      </div>
      : null
    }
    </section><ContainerPosition>
            <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
            <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
        </ContainerPosition>
  </CollapseSection>
}
export const PortfolioForm = forwardRef(PortfolioFrm);

export function  SavedPortfolio ({ onUpload, data, dispatchDeleteFinAsset, onEdit, dispalyActions, onShare, datashare, dataShareByMe, accessLevelArray, toggle }) {
  const { success, error } = useQuaishToasts()
  const deletePMS = (id) =>{
    if(window.confirm("Delete?\n Are you sure you want to delete this record?")){
        deleteFinAsset(id)
        .then(response => {
          success('Record Deleted Successfully');
          dispatchDeleteFinAsset(id);

        })
        .catch(err => {
          error(err)
        })
    }
  }

  const row = data.map((e) => {
    return(
      
    <TableContainer>
      <CenterAlignRow>
        <AlignColumn label="Provider Name" text={e.assetIssuerName} />
        <AlignColumn label="Account No." text={e.assetIdentificationNumber} />
        <AlignColumn label="Nominee" text={e.contacts !== null ? getContactFullName(e.contacts):''} />
        {datashare === 'yes' ? <AlignColumn label="Shared with" text={_.isEmpty(dataShareByMe[e.id][0]) ? null : dataShareByMe[e.id][0].map((f,index)=>(<span style={{cursor:'pointer', color: '#007bff'}} onClick={()=>toggle(dataShareByMe[e.id][1][index])}><span style={{textDecoration:'underline'}}>{f}</span><span>{index !== (dataShareByMe[e.id][0].length) -1 ? ' , ' : ''}</span></span>))} />: null}
        {datashare === 'shareby' ? <AlignColumn label="Shared By" text={dataShareByMe[e.id]} />: null}
        {dispalyActions === 'no' || datashare === 'yes' || (datashare === 'shareby' && accessLevelArray[e.id]==='READ')  ? null :
        <AlignColumn label="Action" text={<ActionItemUpload onUpload={onUpload} onEdit={()=>onEdit(e)} onDelete={()=>deletePMS(e.id)} onShare={() => onShare(e.id)} datashare={datashare}/>} />}
        </CenterAlignRow>
    </TableContainer>
  )});
  return (
    data.length === 0 ? null :
    <div>
    <CollapseSection isHidden={data.length === 0} mainTitle={'Portfolio Management Services'} onShare={() => onShare('multicreate')}  collapsed={false} isCollapsable={true} customStyle={true}  datashareuser={datashare}  datashare={'yes'}>
      {row}
    </CollapseSection>
     {dispalyActions === 'no' || datashare === 'yes' || datashare === 'shareby' ? <br/> : null }
     </div>
  );
};

 const mapStateToProps = (state) => ({
  portfolioFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'PMS'),
  userId: getStoreUserId(state),
  pickList: (getStoreContactList(state).concat(getStoreFamily(state))),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchAddFinAsset: finAsset => dispatch(addFinAsset(finAsset)),
  dispatchEditFinAsset:finAsset=>dispatch(editFinAsset(finAsset)),
  dispatchDeleteFinAsset:finAsset=>dispatch(deleteFinAssetFromList(finAsset)),
})
 export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);
