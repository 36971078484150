import Auth from "@aws-amplify/auth";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { setUserData } from "../actions/userActions";
import { getUserByUserName } from "../dashboard/forms/personal/personalService";
import { getStoreUserId } from "../selectors/userSelectors";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { Loader } from "../components/CustomElements";
import ForgotPassword from "./ForgotPassword";
import { Link } from "react-router-dom";
import { setUserRoleData } from "../actions/userRolesAction";
import ModalContainer from "../home/component/layout/Modal";
import SignUp from "./SignUp";
// import './aqua.css';
import {
  AWS_CONFIG,
  AWS_CLIENTID,
  AWS_REDIRECTURI,
  AWS_DOMAIN,
  AWS_USERPOOLID,
  WEBSERVER,
} from "../constant/ServerConst";
import { userVerificationEmail } from "./AuthServices";
import { Image } from "react-bootstrap";

Auth.configure({ ...AWS_CONFIG.Auth });
const url = WEBSERVER;

const Login = ({
  onClose = () => null,
  dispatchSetUserDetails,
  userId,
  dispatchSetUserRoleData,
  will,
}) => {
  const history = useHistory();
  const refUsername = useRef();
  const refPassword = useRef();
  const [isForgotPassword, setForgotPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [termsAndCond, setTermsAndCond] = useState(false);
  let [visibleSignUp, setVisibleSignUp] = useState(false);
  let toggleSignUp = () => setVisibleSignUp(!visibleSignUp);
  let [visibleFg, setVisibleFg] = useState(false);
  let toggleFg = () => setVisibleFg(!visibleFg);
  const getUserByRole = () => {
    let payload = {
      roleLookupCode: "GIVER",
    };
    dispatchSetUserRoleData(payload);
    if (will) {
      window.open("/dashboard");
    } else {
      history.push("/dashboard");
    }
    onClose();
  };

  const userLogin = () => {
    if (refUsername.current.value !== "" && refPassword.current.value !== "") {
      trackPromise(
        Auth.signIn(
          refUsername.current.value.toLowerCase().trim(),
          refPassword.current.value
        )
          .then((response) => {
            console.log("aws signin complete");
            console.log(response);
            getUserByUserName(response.username)
              .then((resp) => {
                dispatchSetUserDetails(resp.data);
                getUserByRole();
              })
              .catch((err) => {
                alert("Login failed at this time. Please try again!");
              });
          })
          .catch((err) => {
            if (err.message === "User does not exist.")
              alert(
                "This user does not exist in our records. Please Sign up to create a new user"
              );
            else if (err.message === "User is not confirmed.") {
              setLoader(true);
              let payload = {
                ClientId: AWS_CLIENTID,
                UserPoolId: AWS_USERPOOLID,
                email: refUsername.current.value.toLowerCase().trim(),
              };
              userVerificationEmail(payload)
                .then((res) => {
                  setLoader(false);
                  alert("Please check mail and Verify your account to sign in");
                })
                .catch((err) => {
                  console.log("error", err);
                  alert(err);
                });
            } else alert(err.message);
          })
      );
    }
  };
  const completeSignin = (event) => {
    event.preventDefault();
    if (termsAndCond) {
      userLogin();
    } else {
      alert("Please Agree for Terms and Conditions of EasyInherit to Login");
    }
  };

  const { promiseInProgress } = usePromiseTracker();
  // if (isForgotPassword)
  //   return (
  //     <div>
  //       <ForgotPassword onClose={onClose} />
  //     </div>
  //   );
  const handleCheck = ({ target }) => {
    setTermsAndCond(target.checked);
  };
  const uri =
    "https://" +
    AWS_DOMAIN +
    "/signup?client_id=" +
    AWS_CLIENTID +
    "&response_type=token&scope=email+openid+profile&redirect_uri=" +
    AWS_REDIRECTURI;

  const completeGoogleSignin = (event) => {
    event.preventDefault();
    if (termsAndCond) {
      window.location.href = uri;
    } else {
      alert("Please Agree for Terms and Conditions of EasyInherit to Login");
    }
  };

  // const LoginView = () => {
  //   return (

  //   );
  // };
  return (
    <div>
      <div className='sticky-top'
        style={{
          flex: 1,
          backgroundColor: "#2f353a",
          display: "flex",
        }}
      >
        <a href={WEBSERVER}>
          <Image
          style={{
            overflow: "auto",
            width: "70%",
            padding: "15px",
            marginLeft: "110px",
            height: "100%",
          }}
          src={require(`../assets/img/logo-light.png`)}
          alt="Easyinherit"
        /></a>
        <ul
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "10px",
            paddingLeft: "180px",
          }}
        >
          <li style={{ color: "white" }}>
            <a
              style={{ color: "white", fontWeight: "bold" }}
              exact
              activeClassName="active-link"
              href={`${url}/`}
            >
              Home
            </a>
          </li>
          <li className="nav-item ">
            <a
              style={{ color: "white", fontWeight: "bold" }}
              href={`${url}/about`}
            >
              About
            </a>
          </li>
          <li className="nav-item dropdown">
            <a
              href={`${url}/service`}
              style={{ color: "white", fontWeight: "bold" }}
              className="nav-link dropdown-toggle "
            >
              Service
            </a>
            <ul className="dropdown-menu">
              <li className="nav-item">
                <NavLink className="dropdown-item" to="easyclaims">
                  EasyCLAIMS
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="dropdown-item" to="easylegal">
                  EasyLEGAL
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="dropdown-item" to="easyportfolio">
                  EasyPORTFOLIO
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-item ">
            <a
              style={{ color: "white", fontWeight: "bold" }}
              href={`${url}/faqs`}
            >
              Faqs
            </a>
          </li>
          <li className="nav-item ">
            <a
              style={{ color: "white", fontWeight: "bold" }}
              href={`${url}/blog`}
            >
              Blog
            </a>
          </li>
          <li className="nav-item ">
            <a
              style={{ color: "white", fontWeight: "bold" }}
              href={`${url}/contact`}
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
      <div
        style={{
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          marginTop: "70px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div>
            <div style={{ textAlign: "center" }}>
              {/* <button type="button" className="close" onClick={onClose}>
       &times;
     </button> */}
              <div>
                <h5 style={{ display: "inline-block" }}>
                  Already a member? Login
                </h5>
              </div>
            </div>
            <div>
              <form onSubmit={(event) => completeSignin(event)}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    placeholder="Username"
                    required="required"
                    ref={refUsername}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    placeholder="Password"
                    required="required"
                    ref={refPassword}
                  />
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-block login-btn"
                    disabled={promiseInProgress}
                  >
                    {promiseInProgress || loader ? <Loader /> : "Login"}
                  </button>
                </div>
              </form>
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  borderBottom: "1px solid #000",
                  lineHeight: "0.1em",
                  margin: "10px 0 20px",
                }}
              >
                <span style={{ background: "#fff", padding: "0 10px" }}>
                  or
                </span>
              </p>
              <button
                style={{
                  color: "black",
                  backgroundColor: "#f2f2f2",
                  fontSize: "3vmin",
                }}
                className="btn btn-lg btn-block login-btn"
                onClick={completeGoogleSignin}
              >
                <img
                  alt=""
                  width="30"
                  height="30"
                  src="https://www.duupdates.in/wp-content/uploads/2020/07/google.jpg"
                />
                &nbsp;&nbsp;&nbsp;&nbsp;Sign in with Google
              </button>
              <div>
                <p>
                  <font size="2">
                    <span
                      style={{ cursor: "pointer", color: "#007bff" }}
                      onClick={() => toggleFg()}
                    >
                      Forgot Password?
                    </span>
                  </font>

                  <br></br>
                  <font size="2">
                    Not a member yet? Please{" "}
                    <span
                      style={{ cursor: "pointer", color: "#007bff" }}
                      onClick={toggleSignUp}
                    >
                      Sign Up.
                    </span>
                  </font>
                </p>
                <label>
                  <input
                    type="checkbox"
                    onClick={handleCheck}
                    defaultChecked={termsAndCond}
                  />{" "}
                  &nbsp;&nbsp; I agree to{" "}
                  <span
                    style={{ cursor: "pointer", color: "#007bff" }}
                    onClick={() => {
                      history.push("/t&c");
                      onClose();
                    }}
                  >
                    Terms and Conditions
                  </span>{" "}
                  of EasyInherit
                </label>
              </div>
            </div>
          </div>
          <ModalContainer visible={visibleSignUp} toggle={toggleSignUp}>
            <div style={{ padding: "40px" }}>
              <SignUp onClose={toggleSignUp} />
            </div>
          </ModalContainer>
          <ModalContainer visible={visibleFg} toggle={toggleFg}>
            <div style={{ padding: "40px" }}>
              <ForgotPassword onClose={toggleFg} />
            </div>
          </ModalContainer>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
});
const mapDispatchToProps = () => (dispatch) => ({
  dispatchSetUserRoleData: (userRolesData) =>
    dispatch(setUserRoleData(userRolesData)),
  dispatchSetUserDetails: (user) => dispatch(setUserData(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
