import initialState from "./initialState";
import _ from 'lodash';
import { ADD_FIN_ASSIGN_LIST, DELETE_FIN_ASSIGN, CREATE_FIN_ASSIGN, EDIT_FIN_ASSIGN } from "../actions/finAssignActions";
export default (state = initialState.finAssigns, action) => {
    switch (action.type) {
        case ADD_FIN_ASSIGN_LIST:
            return  {...state, ...action.finAssigns};

        case DELETE_FIN_ASSIGN:
            return {...state, [action.finAssign.assetLookupCode]: state[action.finAssign.assetLookupCode].filter(con => con.id !== action.finAssign.finAssignId)};
        case CREATE_FIN_ASSIGN:
            return {...state, ...action.finAssigns};
        case EDIT_FIN_ASSIGN:
            const index = _.findIndex(state[action.finAssign.assetLookupCode], { id: action.finAssign.id });
            state[action.finAssign.assetLookupCode].splice(index, 1, action.finAssign);
            return {...state};
        default:
            return state;
    }
}