import React from 'react'
import { connect } from 'react-redux'
import '../css/dashboard.css'
import '../css/bootstrap.min.css'
import { getStoreUserId, getStoreUserRole } from '../selectors/userSelectors'
import string from '../constant/en.json'
import { getUserRolesList } from '../actions/userRolesAction'
import '../home/component/layout/Header.css'


export const FooterTag = () => (
  <footer className='footer-distributed'>
    <div className='footer-left'>
      {/* <p className="footer-links">
      <a href="#">Home</a>·<a href="#">Blog</a>·<a href="#">Pricing</a>·<a href="#">About</a>·<a href="#">Faq</a>·<a href="#">Contact</a>
    </p> */}

      <p className='footer-company-name'>Quaish Bizsense Pvt. Ltd.  </p>
      <p className='footer-company-name'>All Copyright Reserved, 2020</p>
    </div>

    <div className='footer-center'>
      <div>
        <i className='fa fa-map-marker'></i>
        <p>
          <span>
            C-1021, Windmills of the Mind, Whitefield<br></br>Bengaluru, India
          </span>
        </p>
      </div>

      <div>
        <i className='fa fa-phone'></i>
        <p>
          {' '}
          <span>(+91 ) 99024-35733</span>
        </p>
      </div>

      <div>
        <i className='fa fa-envelope'></i>
        <p>
          <a href='mailto:info@easyinherit.in'>info@easyinherit.in</a>
        </p>
      </div>
    </div>

    <div className='footer-right'>
      <p className='footer-company-about'>
        <span> About the company</span>
        {string.home.footer.text}
      </p>

      <div className='footer-icons'>
        <a href='https://www.facebook.com/EasyInherit-109471097548929' target="_blank">
          <i className='fa fa-facebook'></i>
        </a>
        <a href='https://www.instagram.com/easyinherit/' target="_blank">
          <i className='fa fa-instagram'></i>
        </a>
        <a href='https://www.linkedin.com/company/easyinherit/' target="_blank">
          <i className='fa fa-linkedin'></i>
        </a>
      </div>
    </div>
  </footer>
)

const mapStateToProps = (state) => ({
  userRoleListData: getUserRolesList(state),
  userId: getStoreUserId(state),
  userRole:getStoreUserRole(state),
})

export default connect(mapStateToProps)(FooterTag)