import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setDigitalAssetData } from '../../../actions/digitalAssetsActions';
import { setFinAssetData } from '../../../actions/finAssetsActions';
import { setImmovableAssetData } from '../../../actions/immovableAssetsActions';
import { setOtherAssetData } from '../../../actions/otherAssetsActions';
import CheckBox from '../../../components/CheckBox';
import CollapseSection from '../../../components/CollapsibleSection';
import { HelpSection, HighlightedText, List } from '../../../components/CustomElements';
import { getStoreUserId, getStoreUserRole } from '../../../selectors/userSelectors';
import AssetsBody from './AssetsBody';
import { getDigitalAssetListFromServer } from './digitalAssetService';
import { getFinAssetListFromServer } from './finAssetService';
import { getImmovableAssetListFromServer } from './immovableAssetService';
import { getOtherAssetListFromServer } from './otherAssetService';
const Assets = ({ userId, dispatchSetFinAssetList, dispatchSetOtherAssetList, dispatchSetImmovableAssetList, dispatchSetDigitalAssetList, userRole }) => {

  const [selectedAssets, setSelected] = useState([]);
  const receiverFlag= userRole==='GIVER' ? false : true
  const onCheck = (e) => {
    setSelected([e, ...selectedAssets]);
  };
  const onUnCheck = (e) => {
    setSelected(_.filter(selectedAssets, (s) => s !== e));
  };
  const onAction = (e, id) => (e ? onCheck(id) : onUnCheck(id));
  useEffect(() => {
    getFinAssetListFromServer(userId, receiverFlag)
      .then((resp) => {
        dispatchSetFinAssetList(resp.data);
      })
      .catch((error) => {
        console.log(error);
      });
    getOtherAssetListFromServer(userId, receiverFlag)
      .then((resp) => {
        console.log(resp.data);
        dispatchSetOtherAssetList(resp.data);
      })
      .catch((error) => {
        console.log(error);
      });
    getImmovableAssetListFromServer(userId, receiverFlag)
      .then((resp) => {
        console.log(resp.data);
        dispatchSetImmovableAssetList(resp.data);
      })
      .catch((error) => {
        console.log(error);
      });
    getDigitalAssetListFromServer(userId, receiverFlag)
      .then((resp) => {
        console.log(resp.data);
        dispatchSetDigitalAssetList(resp.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div>
      <HelpSection heading="HELP TIPS!">
        <List>
          List down all your assets here. Tick on the relevant boxes and fill the details below.{' '}
          <HighlightedText> It is advisable to provide details of all your assets.</HighlightedText> 
        </List>
      </HelpSection>
      <CollapseSection mainTitle={'Assets Selection'}>
        <div className="card-block">
          <div
            className="card-header"
            style={{
              fontSize: 15,
              color: '#007bff',
            }}
          >
            Please select from the Assets listed below -
          </div>
          <div className="row" style={{ marginTop: '10px' }}>
            <Arrange routeId="financial">
              <div className="col-xl-3 col-md-6 col-lg-3">
                <h5 className="size14">Financial Assets</h5>
                <CheckBox onChange={(e) => onAction(e, 0)} title={'Fixed Deposits'} />
                <CheckBox onChange={(e) => onAction(e, 1)} title={'Physical Shares'} />
                <CheckBox onChange={(e) => onAction(e, 2)} title={'Demat Account'} />
                <CheckBox onChange={(e) => onAction(e, 3)} title={'Government saving schemes'} />
                <CheckBox onChange={(e) => onAction(e, 4)} title={'Bank Account'} />
              </div>
            </Arrange>
            <div className="col-xl-3 col-md-6 col-lg-3 div">
              <Arrange routeId="financial">
                <br/>
                <CheckBox onChange={(e) => onAction(e, 13)} title={'Portfolio Management Services'} />
                <CheckBox onChange={(e) => onAction(e, 6)} title={'Mutual Funds Units[Physical]'} />
                <CheckBox onChange={(e) => onAction(e, 7)} title={'Bonds'} />
                <CheckBox onChange={(e) => onAction(e, 8)} title={'Provident Fund'} />
                <CheckBox onChange={(e) => onAction(e, 9)} title={'Gratuity'} />
              </Arrange>
              <Arrange routeId="insurance">
                <h5 className="size14">Insurance Policy</h5>
                <CheckBox onChange={(e) => onAction(e, 5)} title={'Insurance Policy'} />
              </Arrange>
              <Arrange routeId="home">
                <h5 className="size14">Immovable Assets</h5>
                <CheckBox onChange={(e) => onAction(e, 10)} title={'Immovable Properties'} />
              </Arrange>
            </div>
              <Arrange routeId="digital&other">
              <div className="col-xl-3 col-md-6 col-lg-3">
                <h5 className="size14">Digital Assets</h5>
                <CheckBox onChange={(e) => onAction(e, 11)} title={'Social Media/Email Account'} />
                <CheckBox onChange={(e) => onAction(e, 12)} title={'Websites'} />
                <CheckBox onChange={(e) => onAction(e, 23)} title={'Digital Passwords'} />
                {/*<CheckBox onChange={(e) => onAction(e, 25)} title={'Frequent Flyer Programs'} />*/}
                <CheckBox onChange={(e) => onAction(e, 21)} title={'Credit Card based Insurance'} />
                <CheckBox onChange={(e) => onAction(e, 22)} title={'Loyalty Programs'} />
                </div>
              </Arrange>
              <div className="col-xl-3 col-md-6 col-lg-3 div">
              <Arrange routeId="digital&other">
                <h5 className="size14">Other Assets</h5>
                <CheckBox onChange={(e) => onAction(e, 14)} title={'Artefacts'} />
                <CheckBox onChange={(e) => onAction(e, 15)} title={'Jewellery'} />
                <CheckBox onChange={(e) => onAction(e, 16)} title={'Art'} />
                <CheckBox onChange={(e) => onAction(e, 17)} title={'Bank Lockers'} />
                <CheckBox onChange={(e) => onAction(e, 18)} title={'Vehicles'} />
                <CheckBox onChange={(e) => onAction(e, 19)} title={'Personal Effects'} />
                {<CheckBox onChange={(e) => onAction(e, 24)} title={'Stemcell Banking'} />}
              </Arrange>
            </div>
                {/* <h5 className="size14">Receivable Assets</h5>
              <CheckBox onChange={(e) => onAction(e, 20)} title={'Receivables(From Loans)'} /> */}
          </div>
        </div>
        <br />
      </CollapseSection>
      <div style={{ marginTop: 20, display: 'block' }}>
        <AssetsBody selectedAssets={selectedAssets} />
      </div>
    </div>
  );
};
const Arrange = ({ routeId, children }) => {
  let { id } = useParams();
  if (routeId === id) return children;
  if (!id) return children;
  return null;
};

const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => (dispatch) => ({
  dispatchSetFinAssetList: (finAsset) => dispatch(setFinAssetData(finAsset)),
  dispatchSetOtherAssetList: (otherAsset) => dispatch(setOtherAssetData(otherAsset)),
  dispatchSetImmovableAssetList: (immovableAsset) => dispatch(setImmovableAssetData(immovableAsset)),
  dispatchSetDigitalAssetList: (digitalAsset) => dispatch(setDigitalAssetData(digitalAsset)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Assets);
