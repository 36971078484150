import React from 'react'

const TextArea = ({ placeholder, bind }) => (
  
  <div>
    <textarea
      style={{ fontSize: '14px' }}
      className='form-control form-control-lg'
      id='exampleInputEmail1'
      aria-describedby='emailHelp'
      placeholder={placeholder}
      onkeypress
      {...bind}
    />
  </div>
)
export default TextArea
