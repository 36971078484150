import React, { useState, forwardRef, useCallback, useImperativeHandle } from "react";
import DOB from "./DOB";
import CheckBox from "../../components/CheckBox";
import HeadBar from "../../components/HeaderBar";
import Input from "../../components/Input";
import string from "../../constant/en.json";
import moment from 'moment';
import Marriage from '../components/Marriage';
import HelperModal from "../helper/HelperModal";

const AgeDobAndMarriageForm = ({ defaultValue = { age: 0, minor: false, dob: null, splMarriageFlag:'',maritalStatus:''} }, ref) => {
    let [age, setAge] = useState(defaultValue.age);
    let [minor, setMinor] = useState(defaultValue.minor);
    let [dob, setDob] = useState(defaultValue.dob);
    let [splMarriageFlag, setSplMarriageFlag] = useState(defaultValue.custField1);
    let [maritalStatus, setMaritalStatus] = useState(defaultValue.maritalStatus);
    let bindAge = {
        value: age
    }
    const refDOB = useCallback(node => {
        if (node !== null && node.returnDetails() && node.returnDetails().dob !== '') {
            let dob = node.returnDetails().dob;
            let age = moment().diff(moment(dob, 'YYYYMMDD'), 'years')
            setAge(age)
            setDob(node.returnDetails().dob)
            setMinor(age < 18)
        }
    }, [dob]);


  const refMarriage = useCallback(node => {
    if (node !== null && node.returnDetails() && node.returnDetails().splMarriageFlag !== '' && node.returnDetails().maritalStatus !== '') {
        let splMarriageFlag = node.returnDetails().splMarriageFlag;
        let maritalStatus = node.returnDetails().maritalStatus;
        setSplMarriageFlag(splMarriageFlag)
        setMaritalStatus(maritalStatus)
    }
}, [splMarriageFlag,maritalStatus]);


    useImperativeHandle(ref, () => ({
        returnDetails() {
            return {
                age,
                minor,
                dob,
                custField1:splMarriageFlag,
                maritalStatus,
            };
        },
    }));
    return (
        <div>
            <DOB defaultValue={dob} ref={refDOB} />
            {minor ? <CheckBox title={string.forms.commonFormFields.minor} defaultValue={minor} disabled={true} /> : null}
            <div className="row" style={{marginLeft:'2px'}}>
                <HeadBar title={'Age'} />
                <HelperModal helperTextId={'age'} />
            </div>
            <div className='row'>
                <Input placeholder={string.forms.commonFormFields.age} bind={bindAge} disabled isBigger />
            </div>
            {minor ? null:<Marriage ref={refMarriage} defaultValue={{ splMarriageFlag:splMarriageFlag === 'true', maritalStatus }} />}
        </div>
    );
}

const AgeDobAndMarriage = forwardRef(AgeDobAndMarriageForm);
export default AgeDobAndMarriage;