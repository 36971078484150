import { Get, Patch } from "../../../utils/ApiUtil";
import Axios from 'axios'
import { FLASK_SERVER } from "../../../constant/ServerConst"

const baseuri = FLASK_SERVER
export const getUserDataFromServer = (id) => Get('/userprofile/get/' + id);

export const updateUser = (id, data) => Patch('/userprofile/update/'+id, { ...data });

export const getUserByUserName = username => Get('/userprofile/getbyusername?userName=' + username)

export const getUserRolesFromServer = (id) => Get('/userroles/get/' + id);

export const getUserIdByEmail = (email) =>
  Axios.post(baseuri+'getUserNameByEmail', { }, {
    headers: {
      'email': email
    }
  })