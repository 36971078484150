import React from 'react';
let SWITCH = ReactIsInDevelomentMode();
// -----
function ReactIsInDevelomentMode() {
  return !'_self' in React.createElement('div');
}

// AWS PROD 
let AWS_CLIENTID="1p8b4gohm4bkdbpdl6evgru9qn"
let AWS_REDIRECTURI="https://app.easyinherit.in/googleLoginRedirect"
let AWS_DOMAIN='oauth2.easyinherit.in'

let AWS_IDENTITYPOOLID = "us-west-2:90915f49-265e-43a9-9e78-902eb1ed5c7b"
let AWS_USERPOOLID = "us-west-2_T7okF23Ag"
let AWS_REDIRECT_SIGNOUT = "https://app.easyinherit.in" 


// AWS DEV
// let AWS_CLIENTID="4amjue2qnq08fd25bgvd03420a"
// let AWS_REDIRECTURI="https://dev.easyinherit.in/googleLoginRedirect"
// let AWS_DOMAIN='devoauth2.easyinherit.in'

// let AWS_IDENTITYPOOLID = "us-west-2:63183875-9a5d-459c-8a69-23b21b2fdde6"
// let AWS_USERPOOLID = "us-west-2_AzNUvwIt1"
// let AWS_REDIRECT_SIGNOUT = "https://dev.easyinherit.in"

let AWS_CONFIG={
    Auth:{
        identityPoolId: AWS_IDENTITYPOOLID,
        region: "us-west-2",
        userPoolId: AWS_USERPOOLID,
        userPoolWebClientId: AWS_CLIENTID,
        oauth: 
        {
          domain:AWS_DOMAIN,
          clientID:AWS_CLIENTID,
          scope : ['email', 'profile', 'openid'],
          redirectSignIn:AWS_REDIRECTURI,
          redirectSignOut : AWS_REDIRECT_SIGNOUT,
          responseType:"token"
        },
    },
    Storage: {
      bucket: 'quaish-customer-docs',
      region: 'us-west-2'
    }
}

//DEV CONSTANTS
// let  FLASK_SERVER = 'https://d2xruq7db7tl2r.cloudfront.net/' 
// let  SERVER= 'https://d2207lfwyowdy3.cloudfront.net/estate-controller-0.0.1-SNAPSHOT/objects/'
// let DIGILOCKER_URI = "https://api.digitallocker.gov.in/public/oauth2/1/authorize?grant_type=authorization_code&response_type=code&client_id=5D392E95&redirect_uri=https://dev.easyinherit.in/easyinherit&state=easyinherit"
// let RAZORPAY_KEY = 'rzp_test_UUPEPXtvDtzwrg'
// let TRAKING_ID = 'UA-177600092-1'

//PROD CONSTANTS
let SERVER='https://d261rdwshv52nb.cloudfront.net/estate-controller-0.0.1-SNAPSHOT/objects/'
let FLASK_SERVER = 'https://d7wvft98szk0m.cloudfront.net/'
let DIGILOCKER_URI = "https://api.digitallocker.gov.in/public/oauth2/1/authorize?grant_type=authorization_code&response_type=code&client_id=09EEABBE&redirect_uri=https://app.easyinherit.in/easyinherit&state=easyinherit"
let RAZORPAY_KEY = 'rzp_live_zUTMC6z4i1xkrH'
let TRAKING_ID = 'UA-180633526-1'
let WEBSERVER ='https://easyinherit.in/#'

//let FLASK_SERVER = SWITCH ? FLASK_DEV_SERVER : FLASK_PROD_SERVER
//let SERVER = SWITCH ? DEV_SERVER : PROD_SERVER

let S3URL = "https://quaish-customer-docs.s3-us-west-2.amazonaws.com/public/";

export {SERVER, WEBSERVER, FLASK_SERVER, DIGILOCKER_URI, SWITCH, AWS_CONFIG, RAZORPAY_KEY, AWS_CLIENTID, AWS_REDIRECTURI, AWS_DOMAIN, AWS_USERPOOLID, TRAKING_ID, S3URL};
