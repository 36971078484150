import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { setUserData } from '../../../actions/userActions';
import CollapseSection from '../../../components/CollapsibleSection';
import { ContainerPosition, CustomButtom, HelpSection, HighlightedText, List } from '../../../components/CustomElements';
import SliderBar from '../../../components/UploadComponent';
import string from '../../../constant/en.json';
import ModalContainer from '../../../home/component/layout/Modal';
import { getStoreUser } from '../../../selectors/userSelectors';
import { getMapping, getObjMapped, getValue } from '../../../utils/commonUtil';
import Address from '../../components/Address';
import ContactDeets from '../../components/ContactDetails';
import DOB from '../../components/DOB';
import Domicile from '../../components/Domicile';
import Identification from '../../components/Identification';
import Marriage from '../../components/Marriage';
import NameAndGender from '../../components/NameAndGender';
import ReligionNationality from '../../components/ReligionNationality';
import { updateUser } from './personalService';
import { useQuaishToasts } from '../../../actions/alertAction';

const Personal = ({ personalDetails, dispatchSetUserDetails }) => {
 
  const resetForm = () => {
    console.log('reset')
    isReset(!reset)
    setTimeout(() => {
      isReset(false);
    }, 500)
  }
  // const [open, setOpen] = useState(false)
  const refDOB = useRef(null),
  refMarriage = useRef(),
  refContactDeets = useRef(),
  refReligionNationality = useRef(),
  refDomicile = useRef(),
  refId = useRef(),
  refAddress = useRef();
  // const { addToast, removeAllToasts } = useToasts()
  const { success, error, removeAll } = useQuaishToasts()
  const refNameAndGender = useRef();
  let [reset, isReset] = useState(false)
  const docsRef = useRef()


  const { id, title, firstName, lastName, middleName, gender, primaryPhone, secondaryPhone, mobilePhone, domicile, idProofNumber, idProofType, maritalStatus, permanentAddress, currentAddress, dob, splMarriageFlag, sameAddressFlag, religion, nationality, } = personalDetails;
  const submit = () => {
    removeAll()
    const payload = {
      gender: refNameAndGender.current.returnDetails().gender,
      ...getValue(refDOB),
      ...getValue(refReligionNationality),
      ...getValue(refMarriage),
      ...getValue(refContactDeets),
      ...getValue(refNameAndGender).name,
      ...getValue(refAddress),
      ...getValue(refId),
      domicile: getValue(refDomicile)
    }
      updateUser(id, payload).then(res => {
        success("Updated Successfully")
        // addToast("Updated Successfully.", { variant: 'success' })
        dispatchSetUserDetails(res.data);
      }).catch(e => {
        error(e.message)
        // addToast(e.message, { variant: 'error' })
      })
  }
  return (
    <div>
      <div>
        <HelpSection heading="HELP TIPS!">
          <List>
            {string.forms.personalDetailSpecific.personalHelpTipHeader}
            <HighlightedText> {string.forms.personalDetailSpecific.personalHelpTip}</HighlightedText>
          </List>
        </HelpSection>
        <CollapseSection mainTitle={string.forms.personalDetailSpecific.personalFormTitle} subtitle={string.forms.commonFormFields.mandatoryFields}>
          {/* <div>{JSON.stringify(pay)}</div> */}
          {reset ?
            <ModalContainer visible={reset}>
              <span className="sr-only">Resetting...</span>
            </ModalContainer>
            :
            <div>
              <NameAndGender ref={refNameAndGender} defaultValue={{ gender, name: { title, firstName, middleName, lastName } }} />
              <DOB defaultValue={dob} ref={refDOB} />
              <Marriage defaultValue={{ splMarriageFlag, maritalStatus }} ref={refMarriage} />
              <ContactDeets defaultValue={{ primaryPhone, secondaryPhone, mobilePhone }} ref={refContactDeets} />
              <ReligionNationality defaultValue={{ religion, nationality }} ref={refReligionNationality} />
              <Domicile defaultValue={domicile} ref={refDomicile} />
              <Identification defaultValue={{ idProofNumber, idProofType, data: personalDetails }} ref={refId} />
              <SameAsAddress defaultValue={{ permanent: permanentAddress, current: currentAddress, sameAddressFlag }} ref={refAddress} />
              <ContainerPosition>
                <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
                {/*<CustomButtom isUpload event={() => docsRef.current.submit('PERSONAL', undefined, null, idProofType)} title={"Upload"} />*/}
                <CustomButtom isCancel event={() => resetForm()} title={string.forms.commonFormFields.cancelBtn} />
              </ContainerPosition>
            </div>}
        </CollapseSection>
      </div>
      <SliderBar ref={docsRef} />
    </div>
  );
};

const SameAsAddressForm = ({ defaultValue }, ref) => {
  const [sameAddressFlag, setAddressSame] = useState(defaultValue.sameAddressFlag)
  const refAddress = useRef(),
    refCurrentAddress = useRef();
  const getPerAddress = () => refAddress.current.returnDetails();
  useImperativeHandle(ref, () => ({
    returnDetails() {
      return {
        sameAddressFlag,
        ...getObjMapped(
          ['perHomeAddress1', 'perHomeAddress2', 'perHomeAddressState', 'perHomeAddressCity', 'perHomeAddressCountry', 'perHomeAddressPin'],
          getPerAddress()
        ),
        ...getMapping(
          ['currHomeAddress1', 'currHomeAddress2', 'currHomeAddressState', 'currHomeAddressCity', 'currHomeAddressCountry', 'currHomeAddressPin'],
          refCurrentAddress
        ),
      };
    },
  }));
  const sameAs = (isSame) => {
    isSame ? refCurrentAddress.current.setAddress(getPerAddress()) : refCurrentAddress.current.setNotSame();
    setAddressSame(isSame);
  };
  return (
    <React.Fragment>
      <Address defaultValue={defaultValue.permanent} title={string.forms.commonFormFields.permanentAddress} ref={refAddress} />
      <Address defaultValue={{ ...defaultValue.current, sameAddressFlag }} title={string.forms.commonFormFields.currentAddress} ref={refCurrentAddress} checkbox onCheck={sameAs} />
    </React.Fragment>
  );
};

const SameAsAddress = forwardRef(SameAsAddressForm);

const mapStateToProps = (state) => ({
  personalDetails: getStoreUser(state)
});
const mapDispatchToProps = () => dispatch => ({
  dispatchSetUserDetails: user => dispatch(setUserData(user))
})
export default connect(mapStateToProps, mapDispatchToProps)(Personal);
