import React from 'react';
import SectionHeading from '../../home/component/layout/SectionHeading';


const Contact = ()  => {

    let imgUrl = '../img/banner/greybackground.png';


    return(
        <div className='container'>

<div className='d-block d-sm-none'>

            <div className = 'Component-Bg' 
                    style = {{ backgroundImage: `url(${imgUrl})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'left center',
                    backgroundRepeat: 'no-repeat',
                    }}> 
              
                        <div id ='price' style={{width:'100%', paddingLeft:'5%', paddingRight:'2%'}}>
                    
                            <SectionHeading heading={'It is a Digital World'} image={'../img/banner/plain_greybackground.png'}/>   
                            <p align='justify'>Today, more so.</p>
                            <p align='justify'> Our entire focus has been to keep you and your family safe. Not just from the risk of 
                            physical contact during Covid-19 but also from intimidating processes and unscrupulous individuals. </p>
                            
                            <p><b><i>Our entire team works digitially and is available on email, video and social media. Feel free to get in touch
                                anytime convenient to you.</i></b></p>
                            
                            
                            <div>   
                                    <table border="0" valign="top" align='center' width='40%'>
                                        <tr>
                                           <td align="center" width='33%'>
                                            <p style={{fontSize:12}}><a href ="https://api.whatsapp.com/send?phone=919164705620"><img alt='' src='../img/banner/whatsapp.png' width="50" height="50" ></img></a><br></br>Chat with us   </p>
                                            </td>
                                            
                                            <td align="center" width='33%'>
                                            <p style={{fontSize:12}}><a href ="tel:+919902435733"><img alt='' src='../img/banner/phonecall.svg' width="50" height="50" ></img></a><br></br>Call us  </p>
                                            </td>

                                            <td align="center" width='33%'>
                                            <p style={{fontSize:12}}><a href="https://www.facebook.com/EasyInherit-109471097548929"><img alt='' src='../img/banner/facebook.png' width="50" height="50"></img> </a><br></br>/easyInherit</p>
                                            </td>
                                        </tr>
                                    </table>
            </div>



                      </div>
            </div>
      
</div>

<div className='d-none d-sm-block'>

            <div className = 'Component-Bg' 
                    style = {{ backgroundImage: `url(${imgUrl})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'left center',
                    backgroundRepeat: 'no-repeat',
                    }}> 
              
                        <div id ='price' style={{width:'100%', paddingLeft:'35%', paddingRight:'2%',paddingTop:'40px'}}>  
                    
                            <SectionHeading heading={'It is a Digital World'} image={'../img/banner/plain_greybackground.png'}/>   
                            <p align='justify'>Today, more so.</p>
                            <p align='justify'> Our entire focus has been to keep you and your family safe. Not just from the risk of 
                            physical contact during Covid-19 but also from intimidating processes and unscrupulous individuals. </p>
                            
                            <p><b><i>Our entire team works digitially and is available on email, video and social media. Feel free to get in touch
                                anytime convenient to you.</i></b></p>
                            
                            
                            <div>   
                                    <table border="0" valign="top" align='center' width='40%'>
                                        <tr>
                                            <td align="center" width='33%'>
                                            <p style={{fontSize:12}}><a href ="https://api.whatsapp.com/send?phone=9164705620" ><img alt=''src='../img/banner/whatsapp.png' width="50" height="50" ></img></a><br></br>Chat with us    </p>
                                            </td>

                                            <td align="center" width='33%'>
                                            <p style={{fontSize:12}}><a href ="tel:+919902435733" ><img alt=''src='../img/banner/phonecall.svg' width="50" height="50" ></img></a><br></br>Call us  </p>
                                            </td>

                                            <td align="center" width='33%'>
                                            <p style={{fontSize:12}}><a href="https://www.facebook.com/EasyInherit-109471097548929" ><img alt='' src='../img/banner/facebook.png' width="50" height="50"></img> </a><br></br>/easyinherit</p>
                                            </td>
                                        </tr>
                                    </table>
            </div>



                      </div>
            </div>
      
</div>

        <div>
            <p style={{fontSize:12}}>However, if you absolutely need to meet us (by appointment only), we are in a city near you. </p>
            <p>
            
                    <table border="0" valign="top" width='80%' style={{fontSize:12}}>
                        <tr align="left"  valign="top" fontcolor="#FFFFFFf">
                            <td>
                                <b style={{fontSize:18}}>Bengaluru</b><br></br>
                                                C-1021, Windmills of the Mind <br></br>Whitefield<br></br>
                                                Bengaluru 560 066<br></br>India<br></br>
                                                # (+91 ) 99024-35733

                            </td>
                        </tr> <br></br>
                        <tr align="left"  valign="top" fontcolor="#FFFFFFf">
                            <td>
                                <b style={{fontSize:18}}>Mumbai</b><br></br>
                                113-114 Raheja Crest 2 <br></br>
                                            Lokhandwala Complex<br></br> 
                                            Andheri West,  Mumbai 400053 <br></br>
                                            India<br></br>
                                            # (+91 ) 99024-35733

                            </td>
                        </tr> <br></br>
                        <tr align="left"  valign="top" fontcolor="#FFFFFFf">
                            <td>
                                <b style={{fontSize:18}}>Kolkata</b><br></br>
                                            25/C/2 Belvedere Road <br></br>
                                            Alipore<br></br> 
                                            Kolkata 700 027<br></br>
                                            India<br></br>
                                            # (+91 ) 99024-35733

                            </td>
                        </tr>

                    </table>
                </p>
        </div>
</div>

    )
}


export default (Contact);
