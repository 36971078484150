export const ADD_LEGALREVIEW_CONSTANTS = 'ADD_LEGALREVIEW_CONSTANTS';
export const setLegalReviewData = (legalReview) => {

    return {
        type: ADD_LEGALREVIEW_CONSTANTS,
        legalReview
    }
}

export const getLegalReviewData = state => state.legalReview;
