import React from 'react';
import { useRef, useState } from 'react';
import Auth from "@aws-amplify/auth";
import { AWS_CONFIG } from '../constant/ServerConst';
import { Post } from '../utils/ApiUtil';
import { useInput } from '../hooks/useInput';

Auth.configure({ ...AWS_CONFIG.Auth });

const SignUp = ({ onClose }) => {
  const refName = useRef();
  const refEmail = useRef();
  const refPassword = useRef();
  const refRePassword = useRef();
  const [disableBtn, setDisableBtn] = useState(false)
  const { value: mobileNum, bind: bindPN } = useInput('','mobile');

  const completeSignUp = (event) => {
    event.preventDefault();
    if (refName.current.value !== '' && refEmail.current.value !== ''
     && refPassword.current.value !== '' && refRePassword.current.value !== '' && mobileNum !== '') {
      setDisableBtn(true)
      const phoneFormat = ((/^[0-9\b]+$/).test(mobileNum)) || ((/^[+|-][0-9\b]+$/).test(mobileNum))
      if(!phoneFormat)
        alert("Invalid Phone number format")
      let phone = ((/^[+|-][0-9\b]+$/).test(mobileNum)) ? mobileNum : '+91' + mobileNum
      let name = refName.current.value
      let index = name.indexOf(' ');
      name = index === -1? [name] : [name.slice(0, index), name.slice(index + 1)];
      let firstName = name[0]
      let lastName = name.length > 1 ? name[1] : ''
      let given_name = firstName + " " + lastName
      let email = refEmail.current.value.toLowerCase().trim();
      let awsPayload = {
            username: email,
            password:  refPassword.current.value,
            attributes: {
                email,
                phone_number:phone,
                given_name: given_name.trim()
            }
        };
        console.log(awsPayload)
      Auth.signUp(awsPayload)
      .then((response)=>{
          console.log('aws signup complete')
          console.log(response)
          Post('userprofile/create?userId='+response.userSub,
          { 
              userName:response.userSub,
              firstName,
              lastName,
              mobilePhone: phone,
              primaryEmail: email,
          })
          .then((resp)=>{
                console.log('backend user create complete')
                alert('Sucessfully Registered! A verification link has been sent to your registered email address.')
                onClose()
                console.log(resp)
            })
            .catch((error)=>{
                console.log('backend error')
                console.log(error)
                setDisableBtn(false)
                alert('Unable to register, please try again!');
              });
        })
        .catch((error)=>{
            console.log('aws error')
            setDisableBtn(false)
            console.log(error)
            alert(error.message);
        });
    }
  }
  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <h5 style={{ display: 'inline-block' }}>Not a Member? Sign Up</h5>
        <button type='button' className='close' onClick={onClose}>
          &times;
        </button>
      </div>
      <form onSubmit={(event) => completeSignUp(event)}>
        <div className='form-group'>
          <input type='text' className='form-control' name='name' placeholder='Name' required='required' ref={refName} />
        </div>
        <div className='form-group'>
          <input type='email' className='form-control' name='email' placeholder='Email Address' required='required' ref={refEmail} />
        </div>
        <div className='form-group'>
          <input type='password' className='form-control' name='password' placeholder='Password' required='required' ref={refPassword} />
          <p><font size='2' color='green'>Atleast 1 number, 1 in caps and 1 special character. eg: # @ ! % </font></p>
        </div>
        <div className='form-group'>
          <input type='password' className='form-control' name='repassword' placeholder='Re-type Password' required='required' ref={refRePassword} />
          <p><font size='2' color='green'>Must match the above entry</font></p>
        </div>
        <div className='form-group'>
        <input type='text' {...bindPN}  className='form-control' placeholder="Phone number" required='required'/>
        </div>
        <div className='form-group'>
          <button type='submit' className='btn btn-primary btn-lg btn-block login-btn' disabled={disableBtn}>
            And, done !
            </button>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
