import Storage from "@aws-amplify/storage";
import _ from 'lodash';
import moment from 'moment'
import React, { useMemo, useRef, useState, useImperativeHandle, forwardRef } from "react";
import { Image, OverlayTrigger, Popover, Modal, Button } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { AWS_CONFIG, S3URL } from "../constant/ServerConst";
import useOutsideAlerter from "../hooks/useOutsideAlerter";
import { batchState } from "../utils/commonUtil";
import { getStoreUser, getStoreUserId } from "../selectors/userSelectors";
import { connect } from "react-redux";
import { updateUser } from "../dashboard/forms/personal/personalService";
import { setUserData } from "../actions/userActions";
const s3Url = S3URL;
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderColor: '#eeeeee',
    backgroundColor: '#cccc',
    color: '#000',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#2196f3'
};

const rejectStyle = {
    borderColor: '#ff1744'
};
Storage.configure(AWS_CONFIG.Storage)
const UploadDrop = ({ handleDrop }) => <Dropzone onDrop={handleDrop}>
    {({ getRootProps,
        getInputProps,
        isDragActive, isFileDialogActive,
        isDragAccept,
        isDragReject, }) => {
            
        const style = //useMemo(() => ({
            {
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }
        /*), [
            isDragActive,
            isDragReject,
            isDragAccept
        ]);*/
        return <div {...getRootProps({ className: `dropzone`, style })}>
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop</p> : isFileDialogActive ? <p>Select file</p> : <p>Drag'n'drop files, or click to select files</p>}
        </div>
    }}
</Dropzone>
const UploadComponentTag = ({ docKey, userId, onDone, docs, inProgress }, ref) => {

    const formatted = docs.map(file => ({ ...file, file: { name: file.docLink.split("/").reverse()[0] }, url: file.docLink, progress: '100' }))
    const [files, setFiles] = useState(formatted);
    React.useEffect(() => {
        setFiles(formatted)
    }, [docs.toString()])
    const handleDrop = acceptedFiles => {
        batchState(() => {
            const f = acceptedFiles.map(f => ({ file: f, progress: 0, preview: URL.createObjectURL(f) })).concat(files);
            uploadFiles(f)
            setFiles(f)
        })
    }
    const uploadToS3 = (file, index, files) => {
        if (file.progress !== 100 + "")
            trackPromise(
                Storage.put(`${userId}/${docKey}/` + moment().format("MMM,DD YYYY HH.mm.ss") + "." + file.file.name.split(".").reverse()[0], file.file, {
                    progressCallback({ loaded, total }) {
                        const perc = ((loaded / total) * 100).toFixed(0);
                        files[index].progress = perc
                        setFiles([...files])
                    },
                })
                    .then(resp => {
                        files[index].url = resp.key;
                        setFiles([...files]);
                    })
                    .catch(err => {
                        files[index].error = err.message;
                    })
            )
    }
    const { promiseInProgress } = usePromiseTracker();
    React.useEffect(() => {
        inProgress(promiseInProgress)
    }, [promiseInProgress])
    const uploadFiles = async (files) => Promise.all(files.map(async (file, index) => await uploadToS3(file, index, files)));
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => null);
    const deleteFromArr = (index) => {
        // if (files[index].id) {
        //     files[index].active = false
        //     setFiles([...files])
        // } else {
        files.splice(index, 1)
        setFiles([...files])
        // }
        // onDone(files)
    }

    useImperativeHandle(ref, () => ({
        returnDetails() { return files }
    }));
    return (
        <div ref={wrapperRef} className="container" style={{ height: '100%', marginBottom: '20px' }}>
            <UploadDrop handleDrop={handleDrop} />
            {<div>
                <div className="row" style={{ height: '60px', alignItems: 'center', justifyContent: 'space-between', margin: '10px' }}>
                    <div style={{ color: '#000' }}>Files: {_.size(files.filter(f => f.active || typeof f.active === 'undefined'))}</div></div>
                {files.map(({ file, progress, error, url, preview, docLink, id, active }, idx) => active || typeof active === 'undefined' ? (
                    <div style={{ height: '50px', justifyContent: 'center', padding: '5px' }}>
                        <div key={idx} className="row border-bottom border-light">
                            <div className="col-2">
                                {file.name.split(".").reverse()[0] === "pdf" ? <i className="fa fa-file-pdf-o" style={{ fontSize: '30px', color: '#fff' }} /> : <ImageHover src={docLink ? s3Url + docLink : preview} />}
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }} className="col-8">
                                <div style={{ fontSize: '13px', color: '#000' }} className="text-truncate">
                                    {file.name}
                                </div>
                            </div>
                            <div style={{
                                fontSize: '15px', color: '#000', display: "flex",
                                alignItems: "center"
                            }} className="text-truncate col-2">
                                {progress === 100 + "" ? <div className="row" style={{ flex: 1, justifyContent: 'space-between' }}>
                                    <i className={`fa fa-${id ? 'check-double' : 'check'}`} style={{ fontSize: '20px', color: '#00ff00' }} />
                                    <i className={`fa fa-times`} style={{ fontSize: '20px', color: '#ff0000', cursor: 'pointer' }} onClick={() => deleteFromArr(idx)} />
                                    <a href={`${s3Url}${docLink?docLink:url}`}><i className={`fa fa-arrow-circle-o-down`} style={{ fontSize: '20px', color: '#2196f3', cursor: 'pointer' }} /></a>
                                </div> : `${progress}%`}
                            </div>
                        </div>
                        <div style={{ fontSize: '11px', color: 'red' }} className="text-truncate">
                            {error ? "X Error" : undefined}
                        </div>
                    </div>
                ) : null)}
            </div>}
        </div>
    );
};
const UploadComponent = forwardRef(UploadComponentTag);


const ImageHover = ({ src }) => (
    <OverlayTrigger trigger="click" placement="right" overlay={<Popover style={{ height: '300px', width: '300px', backgroundColor: 'transparent' }} id="popover-basic">
        <Popover.Content>
            <Image style={{ height: '300px', width: '300px' }} src={src} />
        </Popover.Content>
    </Popover>}>
        <Image style={{ height: '50px', width: '50px' }} roundedCircle src={src} />
    </OverlayTrigger>
);

let newReturns = []
const SliderBarTag = ({ docs, userId, dispatchSetUserDetails }, ref) => {
    const [docKey, setKey] = useState("");
    const [docId, setId] = useState("");
    const [open, setOpen] = useState(false);
    const [inProg, setProgress] = useState(false)
    const [title, setTitle] = useState("");
    let dbSpecFiles = docs.filter(f => f.docLookupCode === docKey);
    if (docId) {
        dbSpecFiles = dbSpecFiles.filter(f => f.docNumber === `${docId}`)
    }
    const docsRef = useRef()
    useImperativeHandle(ref, () => ({
        submit(docKey, docId, title) {
            batchState(() => {
                setKey(docKey)
                setTitle(title)
                setId(docId)
                if (!open)
                    setOpen(true)
            })
        }
    }));
    const onDone = () => {
        const files = docsRef.current.returnDetails()
        const docsNew = files.map(f => ({ docLink: f.url, userId, id: f.id, docLookupCode: docKey, docNumber: docId }))
        newReturns = docsNew;
        const getDocs = () => {
            const currArr = newReturns;
            const deletedArr = dbSpecFiles.filter(d => _.findIndex(currArr, { id: d.id }) === -1)
            const formatted = deletedArr.map(d => ({ ...d, active: false, }))
            return formatted.concat(currArr);
        }
        trackPromise(
            updateUser(userId, { id: userId, userDocuments: getDocs() }).then(resp => {
                alert("Success")
                setOpen(false)
                dispatchSetUserDetails(resp.data)
            }).catch((err) => alert(err.message))
        );

    }
    const { promiseInProgress } = usePromiseTracker()
    return <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            < UploadComponent inProgress={setProgress} ref={docsRef} docs={dbSpecFiles} userId={userId} docKey={`${docKey}${docId ? `_${docId}` : ''}`} />
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => setOpen(false)}>
                Cancel
      </Button>
            <Button disabled={inProg || promiseInProgress} onClick={() => onDone()}>
                Save
      </Button>
        </Modal.Footer>
    </Modal>
}
const mapStateToProps = state => ({
    docs: getStoreUser(state).userDocuments,
    userId: getStoreUserId(state)
})
const mapDispatchToProps = () => dispatch => ({
    dispatchSetUserDetails: user => dispatch(setUserData(user))
})
const SliderBar = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(forwardRef(SliderBarTag));

export default SliderBar