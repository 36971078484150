import React, {useEffect} from 'react';
import Footer from './component/layout//Footer';
import Header from './component/layout/Header';
import Contact from '../dashboard/components/Contact';
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';

const Home = () => {
  useEffect(() => {ReactGA.pageview('Contact Page')},[])
  useEffect(() => {mixpanel.track("Contact Page")},[])
    return (
        <div>
        <Header />
        <Contact/>
        <Footer />
      </div>
    );
}
export default Home;