import initialState from "./initialState";
import _ from 'lodash';
import { ADD_EXECUTOR_LIST } from "../actions/executorActions";
export default (state = initialState.executor, action) => {
    switch (action.type) {
        case ADD_EXECUTOR_LIST:
            return  _.uniqBy(state.concat(action.executor), 'id');
        default:
            return state;
    }
}