import React from 'react';
import Footer from './component/layout//Footer';
import Header from './component/layout/Header';
import Services from '../dashboard/components/Services';

const Home = () => {
    return (
        <div>
        <Header />
        <Services/>
        <Footer />
      </div>
    );
}
export default Home;