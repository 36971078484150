import React, { forwardRef, useState } from 'react';
import CollapseSection from "../../../../components/CollapsibleSection";
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import { useInput } from "../../../../hooks/useInput";
import string from '../../../../constant/en.json';
import { batchState, getId } from '../../../../utils/commonUtil';
import { ContainerPosition, CustomButtom, ActionItem, TableContainer, CenterAlignRow, AlignColumn } from '../../../../components/CustomElements';
import { updateDigitalAssetById, createDigitalAsset, deleteDigitalAsset } from '../digitalAssetService';
import { getStoreDigitalAssetList, addDigitalAsset, editDigitalAsset, deleteDigitalAssetFromList } from '../../../../actions/digitalAssetsActions';
import { getStoreUserId, getStoreUserRole } from '../../../../selectors/userSelectors';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useQuaishToasts } from '../../../../actions/alertAction';
import ModalContainer from '../../../../home/component/layout/Modal'
import DataShareModal from '../../../../dashboard/forms/dataShare/DataShareModal'

const Password = ({userId, passwordDigitalAssetList, dispatchAddDigitalAsset, dispatchEditDigitalAsset, dispatchDeleteDigitalAsset, userRole}, ref) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [password, setPassword] = useState();
  let [visibleDataShare, setVisibleDataShare] = useState(false)
  let toggleDataShare = () => setVisibleDataShare(!visibleDataShare)
  const [dataShareId, setDataShareId] = useState('')

  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && password &&  password.id ) {
        setPassword(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setSocial(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
      setPassword(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const onShare = (c) => {
    setDataShareId(c)
    toggleDataShare()
  }
       
  return (
    <div>
        <SavedPassword data={passwordDigitalAssetList} onEdit={onEdit} onShare={onShare} isFormHidden={isFormHidden} dispatchDeleteDigitalAsset={dispatchDeleteDigitalAsset} />
        <div  style={{ marginBottom: 20, display: 'block' }}>
          <ContainerPosition>
            <CustomButtom event={() => toggleForm()} title={'Add Password Details'} />
          </ContainerPosition>
        </div>
        {isFormHidden ? null : <PasswordForm password={password} userId={userId} onCancel={toggleForm} onDone={onDone} passwordDigitalAssetList={passwordDigitalAssetList} dispatchAddDigitalAsset={dispatchAddDigitalAsset} dispatchEditDigitalAsset={dispatchEditDigitalAsset} userRole={userRole} />}
        {visibleDataShare
                  ? 
                  <ModalContainer visible={visibleDataShare} toggle={toggleDataShare}>
                    <DataShareModal visibleDataShare={visibleDataShare} toggleDataShare={toggleDataShare} dataShareId={dataShareId} AssetList={passwordDigitalAssetList} assetLookupKey={"password_digital"} assetLookupCode={'PWD'}/>
                  </ModalContainer> 
                  : null 
        }
    </div>
  );
}


const PasswordFrm = ({password,userId, onCancel, onDone, PasswordDigitalAssetList, dispatchAddDigitalAsset, dispatchEditDigitalAsset, userRole}, ref) => {
  const { value: assetDetail, bind: bindPlatform } = useInput(!password ? '' : password.assetDetail);
  const { value: assetDescription, bind: bindUsername } = useInput(!password ? '' : password.assetDescription);
  const { value: custField2, bind: bindNominee } = useInput(!password ? '' : password.custField2);
  const { success, error, removeAll } = useQuaishToasts()
  /*const validate=(payload)=>{
    const ev=[]
    for (var key in payload) {
      switch(key){
          case 'assetDescription':
            _.isEmpty(payload[key]) ? ev.push(string.forms.socialMediaOrEmailAccounts.emptyUserName):null
            break
          case 'assetDetail':
            _.isEmpty(payload[key]) ? ev.push(string.forms.socialMediaOrEmailAccounts.emptyPlatform):null
            break
          default:
            break
          } 
        }
          if(ev.length>0){
            warn(ev)
            return false
          }
          else return true
        }*/
  const submit = () => {
    removeAll()
    const roleOfUser= userRole==='GIVER' ? false : true
    const payload = {
      userId,
      assetLookupCode: 'PWD',
      receiverFlag:	roleOfUser,
      assetDescription, // Platform for which password is stored
      //custField1, used for storing the  amounts
      custField2, //used for actual password
      assetDetail, //Additional information
      // contacts: refPickNominee.current.returnDetails()
    }
    console.log(payload)
    //if(validate(payload))

    getId(password) ? updateDigitalAssetById(password.id,{id:password.id ,...payload})
    .then(response => {
        success('Record Updated Successfully');
        dispatchEditDigitalAsset(response.data)
        onDone()
    }).catch(err => {
        error(err)
    }) : 
    createDigitalAsset(payload)
    .then(response => {
      success('Record Created Successfully');
      dispatchAddDigitalAsset(response.data);
      onDone()
        // dispatchSetAllContactList(response.data);
    })
    .catch(err => {
      error(err)
    })
  }
  return <CollapseSection  mainTitle={'Enter Password Details'} >
    <section className='section_form'>
      <div>
        <HeadBar title={'Application Name'} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={'e.g. AppleID, iPhone, Netflix'} isBigger bind={bindPlatform} />
      </div>
      <div>
        <HeadBar title={'Username'} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={'e.g. kishore@abc.com'} isBigger bind={bindUsername} />
      </div>

      <div>
        <HeadBar title={'Password'} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='password' placeholder={'*******'} isBigger bind={bindNominee} />
      </div>

    </section>
    <ContainerPosition>
            <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
            <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
        </ContainerPosition>
  </CollapseSection>
}
export const PasswordForm = forwardRef(PasswordFrm);

export function SavedPassword ({ data, dispatchDeleteDigitalAsset, onEdit, dispalyActions, onShare, datashare, dataShareByMe, accessLevelArray, toggle }) {
  const { success, error } = useQuaishToasts()
  const deleteSoc = (id) =>{
    if(window.confirm("Delete?\n Are you sure you want to delete this record?")){
        deleteDigitalAsset(id)
        .then(response => {
          success('Record Deleted Successfully');
          dispatchDeleteDigitalAsset(id);

        })
        .catch(err => {
          error(err)
        })
    }
  }

  const row = data.map((e) => {
    return(
      
    <TableContainer>
      <CenterAlignRow>
        <AlignColumn label="Application Name" text={e.assetDetail} />
        <AlignColumn label="Username" text={e.assetDescription} />
        <AlignColumn label="Password" text={e.custField2} />
        {datashare === 'yes' ? <AlignColumn label="Shared with" text={_.isEmpty(dataShareByMe[e.id][0]) ? null : dataShareByMe[e.id][0].map((f,index)=>(<span style={{cursor:'pointer', color: '#007bff'}} onClick={()=>toggle(dataShareByMe[e.id][1][index])}><span style={{textDecoration:'underline'}}>{f}</span><span>{index !== (dataShareByMe[e.id][0].length) -1 ? ' , ' : ''}</span></span>))} />: null}
        {datashare === 'shareby' ? <AlignColumn label="Shared By" text={dataShareByMe[e.id]} />: null}
       {dispalyActions === 'no' || datashare === 'yes' || (datashare === 'shareby' && accessLevelArray[e.id]==='READ')  ? null :
        <AlignColumn label="Action" text={<ActionItem onEdit={()=>onEdit(e)} onDelete={()=>deleteSoc(e.id)} onShare={() => onShare(e.id)} datashare={datashare}/>} />}
      </CenterAlignRow>
    </TableContainer>
  )});
  return (
    data.length === 0 ? null :
    <div>
    <CollapseSection isHidden={data.length === 0} mainTitle={'Password Details'} onShare={() => onShare('multicreate')}  collapsed={false} isCollapsable={true} customStyle={true}  datashareuser={datashare}  datashare={'yes'}>
      {row}
    </CollapseSection>
     {dispalyActions === 'no' || datashare === 'yes' || datashare === 'shareby' ? <br/> : null }
     </div>
  );
};

 const mapStateToProps = (state) => ({
  passwordDigitalAssetList: getStoreDigitalAssetList(state).filter(fa=> fa.assetLookupCode === 'PWD'),
  userId: getStoreUserId(state),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchAddDigitalAsset: digitalAsset => dispatch(addDigitalAsset(digitalAsset)),
  dispatchEditDigitalAsset:digitalAsset=>dispatch(editDigitalAsset(digitalAsset)),
  dispatchDeleteDigitalAsset:digitalAsset=>dispatch(deleteDigitalAssetFromList(digitalAsset)),
})
 export default connect(mapStateToProps, mapDispatchToProps)(Password);