import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import CollapseSection from "../../../../components/CollapsibleSection";
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import { useInput } from "../../../../hooks/useInput";
import Address from '../../../components/Address';
import string from '../../../../constant/en.json';

const RxvbleFrm = ({}, ref) => {
  const { value: from, bind: bindDueFrom } = useInput('');
  const { value: contNum, bind: bindNum } = useInput('');
  const { value: amt, bind: bindAmt } = useInput('');
  const refAddress = useRef();
  useImperativeHandle(ref, () => ({
    returnDetails() { return { from, amt, contNum, address: refAddress.current.returnDetails() } }
  }));
  return <CollapseSection isCollapsable mainTitle={string.forms.receivable.receivableFormTitle} collapsed>
    <section className='section_form'>
      <div>
        <HeadBar title={string.forms.receivable.receivableDue} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.receivable.payerName} isBigger bind={bindDueFrom} />
      </div>
      <Address title={string.forms.receivable.payerAddress} ref={refAddress} />
      <div>
        <HeadBar title={string.forms.receivable.payerContactNo} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.receivable.payerContactNo} isBigger bind={bindNum} />
      </div>
      <div>
        <HeadBar title={string.forms.receivable.amount} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.receivable.amount} isBigger bind={bindAmt} />
      </div>
    </section>
  </CollapseSection>
}
const Receivable = forwardRef(RxvbleFrm);
export default Receivable;
