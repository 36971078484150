import React, { useImperativeHandle, forwardRef } from 'react';
import { useInput } from '../../hooks/useInput';
import HeadBar from '../../components/HeaderBar';
import Input from '../../components/Input';
import string from '../../constant/en.json';

export const ContactDeetsForm = ({ defaultValue = { primaryPhone: '', secondaryPhone: '', mobilePhone: '' } }, ref) => {
  const { value: primaryPhone, bind: bindFN } = useInput(defaultValue.primaryPhone);
  const { value: secondaryPhone, bind: bindMN } = useInput(defaultValue.secondaryPhone);
  const { value: mobilePhone, bind: bindLN } = useInput(defaultValue.mobilePhone,'mobile');
  useImperativeHandle(ref, () => ({
    returnDetails() { return { primaryPhone, secondaryPhone, mobilePhone } }
  }));
  return <section className='section_form'>
    <HeadBar title={string.forms.commonFormFields.contactDetails} subtitle={string.forms.commonFormFields.contactMandatory} />
    <div className='row'>
      <Input type='text' placeholder={string.forms.commonFormFields.residentialLandline} isBigger bind={bindFN} />
      <Input type='text' placeholder={string.forms.commonFormFields.officeLandline} isBigger bind={bindMN} />
      <Input type='text' placeholder={string.forms.commonFormFields.mobile} isBigger bind={bindLN} />
    </div>
  </section>
};
const ContactDeets = forwardRef(ContactDeetsForm);
export default ContactDeets;

