import onClickOutside from "react-onclickoutside";
import React from 'react'
import './Header.css';
import { HashLink as Link } from 'react-router-hash-link'

class HeaderMobileDropdown extends React.Component {
      
    handleClickOutside = () => {   
            var dropdowns = document.getElementsByClassName("dropdown-content3");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
              var openDropdown = dropdowns[i];
              if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
              }
            }
          
  }
  
     showDropdown(d) {
        document.getElementById(d).classList.toggle("show");
      }
 
  render() {
    return (
            <div>
                    <ul
                    className='navbar-nav'
                    style={{
                    position: 'absolute',
                    right: 0,
                    textAlign: 'right',
                    top: 8,
                    }}
                    >
                    <li className='nav-item dropdown'>
                      <span
                        onClick={() => this.showDropdown('myDropdown7')} class="dropbtn"
                        className='nav-link dropdown-toggle'
                        role='button'>
                            <i
                              className='fa fa-bars'
                              style={{fontSize: 22, color: '#fff',marginRight: 10,position: 'absolute',right: '0',cursor: 'pointer',top: 15,}}
                              aria-hidden='true'> 
                              
                            </i>
                        </span>
                        <div
                        id="myDropdown7" 
                        class="dropdown-content3"
                        aria-labelledby='navbarDropdownMenuLink'
                        style={{textAlign:'left'}}
                        >
                        <Link to='/aboutus' className='dropdown-item'>
                            About
                        </Link>
                        <Link to='/service' className='dropdown-item'>
                            Services
                        </Link>
                        <Link to='/secure' className='dropdown-item'>
                            Security 
                        </Link>

                        <Link to='/questions' className='dropdown-item'>
                            FAQ 
                        </Link>

                        <Link to='/q&a' className='dropdown-item'>
                            Pricing
                        </Link>
                        <Link to='/contactus' className='dropdown-item'>
                            Contact
                        </Link>
                        <Link
                            className='top-nav-link dropdown-item'
                            onClick={this.props.toggleLogin}
                        >
                            Login
                        </Link>
                        </div>
                        </li>
                        </ul>
      </div>
    );
  }
}

export default onClickOutside(HeaderMobileDropdown)