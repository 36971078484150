import React,{ useEffect } from 'react';
import SectionHeading from '../../home/component/layout/SectionHeading';
//import SignUp from '../../../auth/SignUp';
import ServiceProductCard from '../../home/component/layout/ServiceProductCard';
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
import { TRAKING_ID } from '../../constant/ServerConst'

ReactGA.initialize(TRAKING_ID);
mixpanel.init("60ba11d6c224405b0b1a24bacb55bd8b");

const Services = ({termsAndConditions})  => {
  useEffect(() => {ReactGA.pageview('services')},[])
  useEffect(() => {mixpanel.track("Services Page")},[])
    let imgUrl = '../img/banner/greybackground.png';
    return(
 <div className='container'>

    <div className='d-block d-sm-none'>         

            <div className = 'Component-Bg' 
                    style = {{ backgroundImage: `url(${imgUrl})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'left center',
                    backgroundRepeat: 'no-repeat',
                    }}> 
              
                    <div style={{width:'100%', paddingLeft:'5%', paddingRight:'2%'}}>  
                    
                        <SectionHeading heading={termsAndConditions ? 'Terms and Conditions' : 'Our Services'} image={'../img/banner/plain_greybackground.png'}/>
                        <p align='justify'>Estate, Succession or Legacy Planning - by whatever name called, is a complex process. <br></br>To be EFFECTIVE, it needs the personalized counsel of 
                            estate lawyers, financial advisors and other professionals in this space to help you build a bullet-proof mechanism
                            assuring that your legacy transfers successfully.</p>
            
                           
                            <p><b><i>EasyInherit’s services simplify and help execute succession transfers with a structured approach.</i></b></p>
                            <p><b><i>We provide un-ambiguous legal counsel, 
                            clean documentation and hassle-free execution from the comfort of your home through our secure, 
                            AI powered tech platform and the expertise of our legal team.</i></b></p>
                         
                  </div>
            </div>
            <div style={{width:'100%',textAlign:'center'}}><img alt='' height='72' width='200' src='../img/banner/testator_service_page.png'></img></div>  
                <div className='row'>
                <div style={{width:'100%', paddingLeft:'10%', paddingRight:'2%', paddingTop:'18px'}}>               

                    <h4>A person transfering his/her legacy to a loved one</h4>
      
                     <p>Most people think a simple "Will" or "Nominees" would suffice. They evaluate with their skills, knowledge and connections in mind. They forget, it is their 
                        BENEFICIARIES ( predominantly spouse and children ) who would have to do the hard work to get access to the assets. We at EasyInherit see your intent of ‘Will making’ 
                        as the first identified step in the process of Inheritance Planning. In fact we follow a five step process to 
                        help you plans your Inheritance customized to the needs of your loved ones and your life circumstances. </p>
                 </div>

            <div style={{width:'100%', paddingLeft:'10%',paddingRight:'2%', paddingTop:'18px'}}><h4>What do we do for you?</h4></div>

                 <div style={{width:'100%', paddingLeft:'10%', paddingRight:'2%', paddingTop:'0px'}}> 
                    <p>We at EasyInherit understand the pride and joy of bequeathing. To leave behind a legacy that is remembered and cherished for a long time.
                        Our services are built from personal experience of managing inheritance and hence we built a set of features that will help you plan the best for 
                        your loved ones to enjoy your carefully built legacy. 
                    </p>
                </div>
                </div>
                </div>
    <div className='d-none d-sm-block'>

    <div className = 'Component-Bg' 
                    style = {{ backgroundImage: `url(${imgUrl})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat',
                    }}> 
              
                    <div style={{width:'100%', paddingLeft:'35%', paddingRight:'2%',paddingTop:'40px'}}>  
                    
                        <SectionHeading heading={termsAndConditions ? 'Terms and Conditions' : 'Our Services'} subHeading={''} image={'../img/banner/plain_greybackground.png'}/>
                        <p align='justify'>Estate, Succession or Legacy Planning - by whatever name called, is a complex process. <br></br>To be EFFECTIVE, it needs the personalized counsel of 
                            estate lawyers, financial advisors and other professionals in this space to help you build a bullet-proof mechanism
                            assuring that your legacy transfers successfully.</p>
            
                           
                            <p><b><i>EasyInherit’s services simplify and help execute succession transfers with a structured approach.</i></b></p>
                            <p><b><i>We provide un-ambiguous legal counsel, 
                            clean documentation and hassle-free execution from the comfort of your home through our secure, 
                            AI powered tech platform and the expertise of our legal team.</i></b></p>
                         
                            <div id = 'testator'><p> <br></br><br></br></p></div>
                  </div>
            </div>
                <div style={{float:'left'}}><img alt='' height='72' width='200' src='../img/banner/testator_service_page.png'></img></div>  
        
                <div style={{width:'100%', paddingLeft:'35%', paddingRight:'2%', paddingTop:'18px'}}>               

                    <h4>A person transfering his/her legacy to a loved one</h4>
      
                     <p>Most people think a simple "Will" or "Nominees" would suffice. They evaluate with their skills, knowledge and connections in mind. They forget, it is their 
                        BENEFICIARIES ( predominantly spouse and children ) who would have to do the hard work to get access to the assets. We at EasyInherit see your intent of ‘Will making’ 
                        as the first identified step in the process of Inheritance Planning. In fact we follow a five step process to 
                        help you plans your Inheritance customized to the needs of your loved ones and your life circumstances. </p>
                 </div> 

            <div style={{float:'left'}}><h4>&nbsp;&nbsp;&nbsp;What do we do <br></br>&nbsp;&nbsp;&nbsp;for you?</h4></div>

                 <div style={{width:'100%', paddingLeft:'35%', paddingRight:'2%', paddingTop:'0px'}}> 

                    <p>We at EasyInherit understand the pride and joy of bequeathing. To leave behind a legacy that is remembered and cherished for a long time.
                        Our services are built from personal experience of managing inheritance and hence we built a set of features that will help you plan the best for 
                        your loved ones to enjoy your carefully built legacy. 
                    </p>
                </div>
                </div>
             <p align = 'center'><img  alt='' width='100%'  src='../img/banner/testator.png'></img> </p>


    <div className='row'>
      <div className='col-xl-12 col-md-12 col-lg-12'>
        <div className='section_title mb-40' style={{ textAlign: 'center' }}>
          <h4 style={{ textAlign: 'left'}}>Our star products for Testators</h4>
          <hr/>

              <div className='row'>
              <ServiceProductCard image={'daud_bhag.jpg'}  calenderSlot title={'Wills and Trusts'} text={'These are all vehicles to carry out Inheritance as per your wishes The choices are plenty, depending on your situation and need. Sometimes, a combination is a better option that just a single instrument. Start the process today and work with us to build the right customized solution for your loved ones.'} />
              <ServiceProductCard image={'my_docs.jpg'} link={'/dashboardsignup'} title={'Inheritance Register'} text={'Bring order into chaos. Keep a record of all your Assets, Liabilities and Contacts in a FREE safe digital locker. Try EasyPortfolio. We partner with Government of India Digital India initiative called DigiLocker for safe custody. This register will help you and your beneficiaries locate information quickly, digitally and globally.'} />
              <ServiceProductCard image={'mom_dad.jpg'} calenderSlot title={'Legal Consults'} text={'Avail your FIRST FREE legal consultation with a team of experienced lawyers with expertise in Inheritance laws. Entirely online, contact-less and no obligations.'} />
              <ServiceProductCard image={'jic_list.jpg'} calenderSlot title={'Custodianship'} text={'If you are not sure of how to maintain confidentiality of your Will, its safe-keeping or need a third-part Executor, contact us. We will provide safe custody of your signed, registered will along with Executorship if you desire.'} />
              </div>
         </div>
       </div>
    </div>  



             <div style={{paddingBottom: '0px'}} className='case_study_area case_page'>
             <div className='row'>
           </div>
          </div>
          <div className='d-block d-sm-none'>         
             <div style={{width:'100%',textAlign:'center'}}><img alt='' height='72' width='200' src='../img/banner/beneficiary_services_page.png'></img></div> 
                   <div className='row'>
             <div style={{width:'100%', paddingLeft:'10%', paddingRight:'2%', paddingTop:'15px'}}> 
                <h4>A Beneficiary receives money or other benefits from a Testator</h4>
                <p>We at EasyInherit understand the trauma of loss. Our services are built from personal experience of our founders’ managing and claiming inheritance in the 
                time of bereavement. EasyInherit has therefore built a set of features that will help 
                you and your loved ones at their most vulnerable moments by hand-holding you through that difficult time with a step by step 
                process approach on claiming the legacy left behind by your loved one.</p><br/>
             </div>
    
             <div style={{width:'100%',paddingLeft:'10%'}}><h4>What do we do for you?</h4></div>

             <div style={{width:'100%', paddingLeft:'10%', paddingRight:'2%', paddingTop:'0px'}}> 

                <p>A beneficiary typically refers to someone eligible to receive distributions from a trust, will, or any other form of Inheritance.
                For many, it's a life changing moment. 
                This service aims to assist the beneficiary in all the acts and activities related to the 
                transfer and transmission of movable as well as immovable assets owned by the deceased in favour 
                of the legitimate heirs and beneficiaries. We interact and communicate with all stakeholders 
                (private sector entities, quasi private sector bodies, government agencies etc. ) to facilitate the 
                seamless transmission within the legal framework in a timely manner.
                </p>
             </div>
             </div>
             </div>
             <div className='d-none d-sm-block'>
             <div id = 'beneficiary'><p> <br></br><br></br></p></div>
             <div style={{float:'left'}}><img alt='' height='72' width='200' src='../img/banner/beneficiary_services_page.png'></img></div> 
             <div style={{width:'100%', paddingLeft:'35%', paddingRight:'2%', paddingTop:'15px'}}> 

                <h4>A Beneficiary receives money or other benefits from a Testator</h4>

                <p>We at EasyInherit understand the trauma of loss. Our services are built from personal experience of our founders’ managing and claiming inheritance in the 
                time of bereavement. EasyInherit has therefore built a set of features that will help 
                you and your loved ones at their most vulnerable moments by hand-holding you through that difficult time with a step by step 
                process approach on claiming the legacy left behind by your loved one.</p>
             </div>
    
             <div style={{float:'left'}}><h4>&nbsp;&nbsp;&nbsp;What do we do <br></br>&nbsp;&nbsp;&nbsp;for you?</h4></div>

             <div style={{width:'100%', paddingLeft:'35%', paddingRight:'2%',paddingTop:'0px'}}> 

                <p>A beneficiary typically refers to someone eligible to receive distributions from a trust, will, or any other form of Inheritance.
                For many, it's a life changing moment. 
                This service aims to assist the beneficiary in all the acts and activities related to the 
                transfer and transmission of movable as well as immovable assets owned by the deceased in favour 
                of the legitimate heirs and beneficiaries. We interact and communicate with all stakeholders 
                (private sector entities, quasi private sector bodies, government agencies etc. ) to facilitate the 
                seamless transmission within the legal framework in a timely manner.
                </p>
             </div>
             </div> 
    <p align = 'center'><img alt='' width='100%'  src='../img/banner/beneficiary.png'></img></p>

    <div className='row'>
      <div className='col-xl-12 col-md-12 col-lg-12'>
        <div className='section_title mb-40' style={{ textAlign: 'center' }}>
          <h4 style={{ textAlign: 'left'}}>Our star products for Beneficiaries</h4>
          <hr/>

              <div className='row'>
              <ServiceProductCard image={'daud_bhag.jpg'}  calenderSlot title={'Probate and Successions'} text={'Probate is the legal term for officially proving of a will. All transfer of ownership starts after that. Probates are an exhaustive and time consuming process involving courts and lawyers. It requires immaculate documentation and lots of time.Our legal team works with you to ensure HASSLE-FREE probates in the least possible time.'} />
              <ServiceProductCard image={'my_docs.jpg'} calenderSlot title={'Transmission Services'} text={'As a  beneficiary you will need to consolidate all records, obtain lawful succession and then claim the amounts. Despite a valid will, you will find the process of transmission of all assets to be time consuming and exhausting. We try to help and provide necessary hand-holding to make the transmission in the shortest period of time with the least hassle.'} />
              <ServiceProductCard image={'mom_dad.jpg'} calenderSlot title={'Advisory and Consultancy'} text={'Inheritance is a matter of pride and joy. But what is of value is not necessarily of worth. For example, you could be living abroad permanently while you inherit a house or land in India. TALK to us on how best to safeguard or re-purpose your inheritance to your specific need and situation. Legal work, Taxes, Repatriation, we do it all. '} />
              <ServiceProductCard image={'jic_list.jpg'} calenderSlot title={'Concierge and Runner Services'} text={'Sometimes, all you need is a helping hand. Someone to sort out the paperwork, organize the documentation, visit Banks, Post Offices, Clubs, RWA offices... you name it. It takes time and effort. Our country-wide Operations team will help you with the best possible solutions to navigate the maze of paperwork.'} />
              </div>
         </div>
       </div>
    </div>  

    </div>

    )
}


export default (Services);
