export const ADD_HELPLIST_CONSTANTS = 'ADD_HELPLIST_CONSTANTS';
export const setHelpListData = (helpList) => {

    return {
        type: ADD_HELPLIST_CONSTANTS,
        helpList
    }
}

export const getHelpListData = state => state.helpList;
