import React from 'react'
import { Nav, Navbar, Form } from 'react-bootstrap'
import { useDispatch, connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import '../css/dashboard.css'
import '../css/bootstrap.min.css'
import { SignOut } from '../auth/SignOut'
import { getStoreUserId, getStoreUserRole } from '../selectors/userSelectors'
import { getUserRolesList } from '../actions/userRolesAction'
import '../home/component/layout/Header.css'
import AboutDropdown from '../home/component/layout/AboutDropdown'
import ServicesDropdown from '../home/component/layout/ServicesDropdown'
import LogoutDropdown from '../home/component/layout/LogoutDropdown'
import DashboardMobileDropdown from '../home/component/layout/DashboardMobileDropdown'

export const HeaderBar = ({ drawermenu}) => {
  const history = useHistory()

  const aboutPage = () =>{
    history.push('/about')
  }
  const servicePage = () =>{
    history.push('/services')
  }
  
  const dispatch = useDispatch()
  return (
    <div
      className='sticky-top'
      style={{ background: '#1b2e4a', padding: 0, flex: 1 }}
    >
      <Navbar
        expand='lg'
        style={{ background: '#1b2e4a', padding: 0, flex: 1 }}
      >
        <Navbar.Brand>
          <img
           className='d-none d-sm-block'
           style={{ width: drawermenu ? 120: 180, marginLeft: drawermenu ? 0: 20, cursor: 'pointer'}}
            src={require('../assets/img/logo.png')}
            alt='logo'
            onClick={() => {
              history.push('/dashboard')
            }}
          />
           <img className='res-hide-full'
           style={{ width: drawermenu ? 10: 120, marginLeft: drawermenu ? 0: 20, cursor: 'pointer'}}
            src={require('../assets/img/logo.png')}
            alt='logo'
            onClick={() => {
              history.push('/dashboard')
            }}
          />
        </Navbar.Brand>
       
        <div className='res-hide-full'>
         <DashboardMobileDropdown history={history} dispatch={dispatch}/>
        </div>
        <Navbar.Collapse>
          <Nav  className={`${drawermenu ? null : 'mr-auto'}`} />
          <Form inline style={{ marginRight: drawermenu ? 0: 50, float: drawermenu ? 'left': null}}>
          { }
            
            <Navbar.Text>
            <LogoutDropdown history={history} dispatch={dispatch}/>
            <div className='res-hide-full'>
            <div class="dropdown">
                      <span style={{color:'white'}}>
                      <img
                      src='../img/banner/profile.png'
                      width='40'
                      height='38'
                      alt='profile'
                      className='rounded-circle '
                    />
                   </span>                       
                  <div
                    className='dropdown-menu '
                    style={{ left: '-150%' }}
                    aria-labelledby='navbarDropdownMenuLink'
                  >
                    <span className='res-hide-full'>
                      <Navbar.Text style={{ color: '#fff', marginRight: 20 }}>
                        |
                      </Navbar.Text>
                      <Link
                        to='/profile'
                        style={{ color: '#fff', marginRight: 20 }}
                      >
                        My Account
                      </Link>
                      <Navbar.Text style={{ color: '#fff', marginRight: 20 }}>
                        |
                      </Navbar.Text>
                      <Link
                        to='/myorders'
                        style={{ color: '#fff', marginRight: 20 }}
                      >
                        My Orders
                      </Link>
                      <Navbar.Text style={{ color: '#fff', marginRight: 20 }}>
                        |
                      </Navbar.Text>
                      <Navbar.Text
                        onClick={() => SignOut(history, dispatch)}
                        style={{ color: '#fff', marginRight: 20 }}
                      >
                        Log Out
                      </Navbar.Text>
                    </span>
                  </div>
                    </div>
                    </div>
            </Navbar.Text>
            
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userRoleListData: getUserRolesList(state),
  userId: getStoreUserId(state),
  userRole:getStoreUserRole(state),
})

export default connect(mapStateToProps)(HeaderBar)