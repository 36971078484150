import React from 'react';
import Footer from '../../../dashboard/Footer';
import Header from '../../../home/component/layout/Header';
import Will from './Will'

const CreateWill = () => {
    return (
        <div style={{backgroundColor:'#f2f2f2'}}>
        <Header /><br/>
        <div style={{marginLeft:'20px',marginRight:'20px'}}>
        <Will createwill/>
        </div>
        <Footer />
      </div>
    );
}
export default CreateWill;