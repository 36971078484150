import React, { useEffect, useRef, useState, useImperativeHandle, forwardRef } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { setContactData, getStoreContactList } from '../../../actions/contactActions';
import { deleteExecutorFromList, getStoreExecutorList, updateToExecutor } from '../../../actions/executorActions';
import CheckBox from '../../../components/CheckBox';
import CollapseSection from '../../../components/CollapsibleSection';
import {
  SingleActionItem, AlignColumn, CenterAlignRow,
  ContainerPosition, CustomButtom, HelpSection,
  HighlightedText, List,
  TableContainer
} from '../../../components/CustomElements';
import DropDown from '../../../components/DropDown';
import HeadBar from '../../../components/HeaderBar';
import RadioBtnGrp from '../../../components/RadioButtonGroup';
import string from '../../../constant/en.json';
import { useInput } from '../../../hooks/useInput';
import { getStoreUserId } from '../../../selectors/userSelectors';
import { getCodeValueMap, getContactFullName, getContactType, getRelation } from '../../../utils/commonUtil';
import PickContactTable from '../../components/PickContactTable';
import { createExecutor, deleteExecutor } from './executorService';
import { getStoreFamily, setFamilyData } from '../../../actions/familyActions';
import { getContactListFromServer } from '../contacts/ContactService';
import { getFamilyListFromServer } from '../family/familyService';
import HelperModal from '../../helper/HelperModal';
import { useQuaishToasts } from '../../../actions/alertAction';

const Executor = ({ userId, dispatchSetContactList, dispatchSetExecutorList, executorList, dispatchDeleteExecutorList, dispatchSetFamilyList }) => {
  const { warn, error, removeAll } = useQuaishToasts()
  useEffect(() => {
    getContactListFromServer(userId)
      .then(response => {
        dispatchSetContactList(response.data)
        // loading(false)
      })
      .catch(err => {
        error(err);
        // loading(false);
      });
      getFamilyListFromServer(userId)
      .then(response => {
        dispatchSetFamilyList(response.data)
      })
      .catch(err => {
        error(err);
      })
  }, [])
  const [isFormVisible, formVisibility] = useState(true)
  const setToggleForm = () => formVisibility(!isFormVisible)
  let [dontShowForm, setForm] = useState('Yes');
  const refPickExecutor = useRef();

  const addExecutor = (body) => {
    createExecutor(body).then(res => {
      if(res.data)
      dispatchSetExecutorList(body)
      formVisibility(true)
    }).catch(err => error(err.message))
  }
  const validate=(payload)=>{
    const ev=[]
    let emptyData = []
    payload.length === 0 ? ev.push(string.forms.executorSpecific.emptySelectContact):emptyData.push(null)
    if(ev.length>0){
      warn(ev)
      return false
    }
    else return true
  }
  const submit = () => {
    removeAll()
    if (dontShowForm === 'No') {
      let ids = refPickExecutor.current.returnDetails();
      const body = ids
      if(validate(body))
      addExecutor(body);
    }
  };

  return (
    <div>
      <HelpSection heading="HELP TIPS!">
        <List>
          {string.forms.executorSpecific.willExecutor}
          <HighlightedText>{string.forms.executorSpecific.executorWish}</HighlightedText>
          {string.forms.executorSpecific.chosenExecutor}
        </List>
      </HelpSection>
      {/* Saved Executors */}
      {executorList.length > 0 ?
        <div style={{ marginBottom: 20, display: 'block' }}>
          <SavedExecutors data={executorList} dispatchDeleteExecutorList={dispatchDeleteExecutorList} setToggleForm={setToggleForm} />
        </div> : null}
      <div style={{ marginBottom: 20, display: 'block' }}>
        <ContainerPosition>
          <CustomButtom event={setToggleForm} title={string.forms.executorSpecific.addExecutor} />
        </ContainerPosition>
      </div>
      {/* ---- */}
      <CollapseSection isHidden={isFormVisible} mainTitle={string.forms.executorSpecific.executorFormTitle} subtitle={string.forms.commonFormFields.mandatoryFields}>
        <div className="row"  style={{marginLeft:'2px'}}>
          <HeadBar title={string.forms.executorSpecific.addIWishForExecutor} />
          <HelperModal helperTextId={"addExecutor"} />
        </div>
        <div className="row">
          <RadioBtnGrp
            options={[
              { value: 'Yes', display: 'Yes' },
              { value: 'No', display: 'No' },
            ]}
            defaultValue={'Yes'}
            onChange={setForm}
          />
        </div>
        {/* disable the rest of the form and show iWish contact info */}
        {dontShowForm === 'No' ? (
          <PickExecutorTable ref={refPickExecutor}/>
        ) : (
            <Terms />
          )}
        <ContainerPosition>
          <CustomButtom event={() => submit()} title={string.forms.commonFormFields.addExecutor} />
          {executorList.length > 0 ? <CustomButtom event={setToggleForm} title={string.forms.commonFormFields.cancel} /> : null}
        </ContainerPosition>
      </CollapseSection>
    </div>
  );
}

const Terms = () => {
  const refCheckBox = useRef();
  return (
    <div>
      <HeadBar title={string.forms.commonFormFields.tAndC} />
      <CheckBox
        ref={refCheckBox}
        title={
          <span>{string.forms.commonFormFields.agreeTandC}</span>
        }
      />
    </div>
  );
};
const SavedExecutors = ({ data, dispatchDeleteExecutorList, setToggleForm }) => {
  const { success, error } = useQuaishToasts()
  const deleteExec = (id) => {
    if (window.confirm("Delete?\n Are you sure you want to delete this record?")) {
      deleteExecutor(id)
        .then(response => {
          success('Record Deleted Successfully');
          dispatchDeleteExecutorList(id);
          if (data.length === 0) setToggleForm()
        })
        .catch(err => {
          error(err)
        })
    }

  }
  const row = data.map((e, i) =>{
    return (
    <CenterAlignRow key={e.id}>
      <AlignColumn label="Name" text={getContactFullName(e)} />
      <AlignColumn label="Type" text={getContactType(e)} />
      <AlignColumn label="Relation" text={getRelation(e)} />
      <AlignColumn label="Action" text={<SingleActionItem onDelete={() => deleteExec(e.id)} />} />
    </CenterAlignRow>
  )});
  return (
    <CollapseSection mainTitle={string.forms.executorSpecific.saveExecutor} collapsed={false} isCollapsable={true} customStyle={true}>
      <TableContainer>{row}</TableContainer>
    </CollapseSection>
  );
};
const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
  executorList: getStoreExecutorList(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchSetContactList: contact => dispatch(setContactData(contact)),
  dispatchSetFamilyList: contact => dispatch(setFamilyData(contact)),
  // dispatchSetAllContactList: contact => dispatch(setAllContactData(contact)),
  dispatchSetExecutorList: executor => dispatch(updateToExecutor(executor)),
  dispatchDeleteExecutorList: executorId => dispatch(deleteExecutorFromList(executorId)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Executor);

const PickExecutor = ({ contactNonExecList, familyNonExecList }, ref) => {
  let { value: pickExecutor, bind: bindPickExecutor } = useInput('Contact');
  const refPickContact = useRef()
  useImperativeHandle(ref, () => ({
    returnDetails() { return refPickContact.current.returnDetails() }
  }));
  return <React.Fragment>
<div className="row"  style={{marginLeft:'2px'}}>
      <HeadBar title={string.forms.executorSpecific.existingExecutor} />
      <HelperModal helperTextId={"addExecutor"} />
      </div>   
       <div className="row">
      <DropDown placeholder={string.forms.commonFormFields.pleaseSelect}
        options={getCodeValueMap(['Contact', 'Family'])}
        bind={bindPickExecutor}
      />
    </div>
    <PickContactTable data={pickExecutor === 'Contact' ? contactNonExecList : familyNonExecList} ref={refPickContact} />
  </React.Fragment>
}
const mapStateToPropsBen = state => ({
  contactNonExecList: getStoreContactList(state).filter(c => !_.get(c, ['executorFlag'])),
  familyNonExecList: getStoreFamily(state).filter(c => !_.get(c, ['executorFlag'])),
})
const PickExecutorTable = connect(mapStateToPropsBen, null, null, { forwardRef: true })(forwardRef(PickExecutor));