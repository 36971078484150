import Axios from 'axios'
import { FLASK_SERVER } from '../../../constant/ServerConst'

const base_uri = FLASK_SERVER
export const createWill = (payload) => Axios.post(base_uri+'createWillSaveData', { }, {
  headers: payload
})

export const getWillData = (userId) => Axios.post(base_uri+'getWillDataByUserId', { }, {
  headers: {
    'user_id': userId
  }
})

export const updateWill = (payload) => Axios.post(base_uri+'updateWillSaveData', { }, {
  headers: payload
})

export const sendSummary = (content, payload) => fetch(base_uri+'sendSummaryEmail',{
  method:'POST',
  body: content,
  headers: payload
}) 

export const deleteWill = (userId) => Axios.post(base_uri+'deleteWillSaveData', { },{
  headers: {
    'user_id': userId
  }
})