export const ADD_DIGITAL_ASSIGN_LIST = 'ADD_DIGITAL_ASSIGN_LIST';
export const CREATE_DIGITAL_ASSIGN = 'CREATE_DIGITAL_ASSIGN';
export const EDIT_DIGITAL_ASSIGN = 'EDIT_DIGITAL_ASSIGN';
export const DELETE_DIGITAL_ASSIGN = 'DELETE_DIGITAL_ASSIGN';
export const setDigitalAssignData = (digitalAssigns) => {

    return {
        type: ADD_DIGITAL_ASSIGN_LIST,
        digitalAssigns
    }
}
export const deleteDigitalAssignFromList = (digitalAssignId) => {

    return {
        type: DELETE_DIGITAL_ASSIGN,
        digitalAssignId
    }
}

export const addDigitalAssign = digitalAssign => ({ type: CREATE_DIGITAL_ASSIGN, digitalAssign });
export const editDigitalAssign = digitalAssign => ({ type: EDIT_DIGITAL_ASSIGN, digitalAssign });
export const getStoreDigitalAssignList = state => state.digitalAssigns;
