import initialState from "./initialState";
import _ from 'lodash';
import { ADD_OTHER_ASSIGN_LIST, DELETE_OTHER_ASSIGN, CREATE_OTHER_ASSIGN, EDIT_OTHER_ASSIGN } from "../actions/otherAssignActions";
export default (state = initialState.otherAssigns, action) => {
    switch (action.type) {
        case ADD_OTHER_ASSIGN_LIST:
            return action.otherAssigns;
        case DELETE_OTHER_ASSIGN:
            return state.filter(con => con.id !== action.otherAssignId);
        case CREATE_OTHER_ASSIGN:
            return state.concat(action.otherAssign)
        case EDIT_OTHER_ASSIGN:
            const index = _.findIndex(state, { id: action.otherAssign.id });
            state.splice(index, 1, action.otherAssign);
            return [...state];
        default:
            return state;
    }
}