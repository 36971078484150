import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getStoreBeneficiaryList, setBeneficiaryData } from '../../../actions/beneficiaryActions';
import { getStoreContactList, setContactData } from '../../../actions/contactActions';
import { getStoreFamily, setFamilyData } from '../../../actions/familyActions';
import { deleteLiabilityFromList, getStoreLiabilityList, setLiabilityData, editLiabilityAction, createLiabilityAction } from '../../../actions/liabilitiesActions';
import CollapseSection from '../../../components/CollapsibleSection';
import { ContainerPosition, CustomButtom } from '../../../components/CustomElements';
import DropDown from '../../../components/DropDown';
import HeadBar from '../../../components/HeaderBar';
import Input from '../../../components/Input';
import string from '../../../constant/en.json';
import { useInput } from '../../../hooks/useInput';
import { getStoreUserId, getStoreUserRole } from '../../../selectors/userSelectors';
import { getContactFullName } from '../../../utils/commonUtil';
import PickContactTable from '../../components/PickContactTable';
import { getBeneficiaryListFromServer } from '../benificiary/beneficiaryService';
import { getContactListFromServer } from '../contacts/ContactService';
import { getFamilyListFromServer } from '../family/familyService';
import { createLiability, editLiability } from './LiabilitiesService';
import { useQuaishToasts } from '../../../actions/alertAction';

const typeLiab = [
    {
        "lookupKey": "liab_type",
        "code": "LOAN",
        "value": "Loans"
    },
    {
        "lookupKey": "liab_type",
        "code": "MORT",
        "value": "Mortgage"
    },
    {
        "lookupKey": "liab_type",
        "code": "HYPO",
        "value": "Hypothecation"
    },
    {
        "lookupKey": "liab_type",
        "code": "GUAR",
        "value": "Guarantor"
    },
    {
        "lookupKey": "liab_type",
        "code": "OTH_LIAB",
        "value": "Others"
    }
];




const LiabilitiyForm = ({ beneficiaryList, onCancel, data = {}, dispatchCreateLiability, dispatchEditLiability, dispatchSetContactList, dispatchSetFamilyList, userId, dispatchSetBeneficiaryList, dispatchSetLiabilityList, userRole }) => {
    const receiverFlag= userRole==='GIVER' ? false : true

    useEffect(() => {
        getFamilyListFromServer(userId)
            .then(response => {
                dispatchSetFamilyList(response.data)
            })
            .catch(err => {
                alert(err);
            })
        getContactListFromServer(userId)
            .then(response => {
                dispatchSetContactList(response.data)
                // loading(false)
            })
            .catch(err => {
                alert(err);
                // loading(false);
            })
        getBeneficiaryListFromServer(userId, receiverFlag)
            .then(response => {
                dispatchSetBeneficiaryList(response.data)
                // loading(false) 
            })
            .catch(err => {
                alert(err);
                // loading(false);
            })
    }, [userId])
    const { value: liabLookupCode, bind: bindLiabliltyType } = useInput(data.liabLookupCode ? data.liabLookupCode : '');
    const { value: beneficiaryType, bind: bindBeneficiaryType } = useInput(_.isEmpty(data) || !_.isEmpty(data.userLiabilitiesBeneficiaries) ? 'Beneficiary' : 'Estate');
    const { value: liabStartDate, bind: bindStartDate } = useInput(data.liabStartDate ? moment(data.liabStartDate).format('YYYY-MM-DD') : '');
    const { value: liabEndDate, bind: bindEndDate } = useInput(data.liabEndDate ? moment(data.liabEndDate).format('YYYY-MM-DD') : '');
    const { value: liabAccountNumber, bind: bindAccNo } = useInput(data.liabAccountNumber ? data.liabAccountNumber : '');
    const { value: liabOthDetail, bind: bindLiabOthDetail } = useInput(data.liabOthDetail ? data.liabOthDetail : '');
    const refPickLiabContact = useRef();
    const { warn, success, error, removeAll} = useQuaishToasts()

    const validate = (payload) => {
        const ev = []
        const benId=[]
        const benIds=[]
        for (var key in payload) {
            switch (key) {
                //case 'liabAccountNumber':
                //    _.isEmpty(payload[key]) ? ev.push(string.forms.liabilitySpecific.emptyLiabilityAccountNo) : null
                //    break
                //case 'liabEndDate':
                //    _.isEmpty(payload[key]) ? ev.push(string.forms.liabilitySpecific.emptyLiabilityEndDate) : null
                //    break
                case 'liabLookupCode':
                    _.isEmpty(payload[key]) ? ev.push(string.forms.liabilitySpecific.emptySelectTypeOfLiability) : benIds.push('null')
                    break
                //case 'liabStartDate':
                //    _.isEmpty(payload[key]) ? ev.push(string.forms.liabilitySpecific.emptyLiabilityStartDate) : null
                //    break
                //case 'userLiabilitiesBeneficiaries':
                //    for (var k in payload[key]) {
                //        payload[key].length === 0 ? null : payload[key][k]['userBeneficiariesId'] === undefined ? benId.push(string.forms.liabilitySpecific.emptySelectLiabilityPaidBy) : null
                //        payload[key].length === 0 ? null : isNaN(payload[key][k]['liabilityPercentage'].toString()) ? benId.push(string.forms.liabilitySpecific.emptyLiabilityPaidPercentage) : null
                //    }
                //    break
                //case 'contacts':
                //    payload[key]['id'] === undefined ? ev.push(string.forms.liabilitySpecific.emptyLiabilityContact) : null
                //    break
                default:
                    break
            }
        }
        
        let uniqueVals=Array.from(new Set(benId));
        const validationList = ev.concat(uniqueVals)
        if (validationList.length > 0) {
            warn(validationList)
            return false
        }
        else return true
    }
    const refBen = useRef()
    const roleOfUser= userRole==='GIVER' ? false : true
    const submit = () => {
        removeAll()
        let total = 0
        let userBens = []
        if (refBen.current) {
            userBens = refBen.current.returnDetails().filter(f=>f);
            total = userBens.reduce(function (total, pair) {
                return total + parseFloat(pair.liabilityPercentage);
            }, 0);
        }
        if (total > 100) {
            warn('Liability Percentage Exceeded maximum Limit! Please maintain the percentage within 100')
        }
        else {
            let userLiabilitiesBeneficiaries = userBens.map(c => ({ userBeneficiariesId: c.id, liabilityPercentage: parseFloat(c.liabilityPercentage), contacts: { id: c.contacts.id }, active: c.active }))
            let payload = {
                userId,
                liabLookupCode,
                liabAccountNumber,
                receiverFlag:	roleOfUser,
                liabOthDetail,
                liabStartDate,
                liabEndDate,
                contacts: roleOfUser ? {} : { id: _.head(refPickLiabContact.current.returnDetails()) },
                userLiabilitiesBeneficiaries,
            }
            if (validate(payload))
                data.id ? editLiability(payload, data.id)
                    .then(response => {
                        success('Record Updated Successfully')
                        dispatchEditLiability(response.data);
                        onCancel()
                    })
                    .catch(err => {
                        error(err)
                    }) : createLiability(payload)
                        .then(response => {
                            success('Record Created Successfully')
                            dispatchCreateLiability(response.data);
                            onCancel()
                        })
                        .catch(err => {
                            error(err)
                        })
        }
    }
    return (
        <CollapseSection isHidden={false} mainTitle={string.forms.liabilitySpecific.liabilityFormTitle} subtitle={string.forms.commonFormFields.mandatoryFields}>
            <HeadBar title={string.forms.liabilitySpecific.typeOfLiability} />
            <div className='row'>
                <DropDown options={typeLiab}
                    placeholder={'Type'}
                    bind={bindLiabliltyType} />
            </div>
            {receiverFlag ? null :
            <PickLiabilityTable selected={data.contacts ? data.contacts : undefined} ref={refPickLiabContact} />
            }
            <div>
                <HeadBar title={string.forms.liabilitySpecific.liability + ' ' + string.forms.liabilitySpecific.startDate + ' ' + string.forms.liabilitySpecific.endDate + ' ' + string.forms.commonFormFields.and + ' ' + string.forms.liabilitySpecific.accountNo + string.forms.commonFormFields.mandatory} />
                <div className='row'>
                    <Input type={'date'} bind={bindStartDate} isBigger />
                    <Input type={'date'} bind={bindEndDate} isBigger />
                    <Input placeholder={string.forms.liabilitySpecific.accountNo} bind={bindAccNo} isBigger />
                </div>
                <div className='row'>
                    <Input placeholder={string.forms.liabilitySpecific.otherInfo} bind={bindLiabOthDetail} value={liabOthDetail} isBigger />
                </div>
            </div>
            {receiverFlag ? null :
            <div>
            <HeadBar title={string.forms.liabilitySpecific.paidBy} subtitle={string.forms.liabilitySpecific.liabilityToBePaidBy} />
            <div className='row'>
                <DropDown options={[
                    { code: 'Beneficiary', value: 'Beneficiary' },
                    { code: 'Estate', value: 'Estate' }
                ]}
                    value={beneficiaryType}
                    bind={bindBeneficiaryType} />
            </div>
            </div>
            }
            {/* display only if 'To be Paid by' is beneficiary */}
            {beneficiaryType === 'Beneficiary' && receiverFlag === false ?
                <GiveBeneficiary beneficiaryList={beneficiaryList} liabBen={!_.isEmpty(data.userLiabilitiesBeneficiaries) ? data.userLiabilitiesBeneficiaries : undefined} ref={refBen} /> : null}
            <ContainerPosition>
                <CustomButtom event={submit} title={string.forms.commonFormFields.saveBtn} />
                <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancel} />
            </ContainerPosition>
        </CollapseSection>
    );
}




const mapStateToProps = (state) => ({
    userId: getStoreUserId(state),
    beneficiaryList: getStoreBeneficiaryList(state),
    liabilityList: getStoreLiabilityList(state),
    contactList: getStoreContactList(state),
    familyList: getStoreFamily(state),
    userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
    dispatchSetLiabilityList: liability => dispatch(setLiabilityData(liability)),
    dispatchSetContactList: contact => dispatch(setContactData(contact)),
    dispatchCreateLiability: liability => dispatch(createLiabilityAction(liability)),
    dispatchEditLiability: liability => dispatch(editLiabilityAction(liability)),
    dispatchDeleteLiability: liabilityId => dispatch(deleteLiabilityFromList(liabilityId)),
    dispatchSetBeneficiaryList: beneficiary => dispatch(setBeneficiaryData(beneficiary)),
    dispatchSetFamilyList: contact => dispatch(setFamilyData(contact)),
})
export default connect(mapStateToProps, mapDispatchToProps)(LiabilitiyForm);



let userBens = []

const GiveBeneficiaryForm = ({ beneficiaryList, liabBen }, ref) => {
    useEffect(() => {
        userBens = liabBen ? liabBen.map(l => ({ id: l.userBeneficiariesId, contacts: l.contacts, liabilityPercentage: l.liabilityPercentage })) : []
    }, [2])
    useImperativeHandle(ref, () => ({
        returnDetails() {
            return num
        }
    }));
    const newData = liabBen ? liabBen.map(l => ({ id: l.userBeneficiariesId, contacts: l.contacts, liabilityPercentage: l.liabilityPercentage })) : [undefined]
    const [num, addNum] = useState(newData);
    const remove = (i) => {
        const newUsBen = num[i]
        if (typeof newUsBen.userId === 'undefined') {
            const newArr = num.map((n, index) => (i === index ? { ...newUsBen, active: false } : n))
            addNum(newArr)
            return
        }
        num.splice(i, 1)
        addNum([...num])
    }
    const addNew = () => {
        if (num.length < beneficiaryList.length) {
            userBens.concat([undefined])
            addNum(num.concat([undefined]))
        }
    }
    const onSelect = (value, ben, index) => {
        if (!_.isEmpty(ben)) {
            ben = parseInt(ben)
            const benc = _.find(beneficiaryList, { id: ben });
            //const uBen = num[index];
            // if (uBen) {
            const newObj = { ...benc, liabilityPercentage: value }
            const newArr = num.map((n, i) => (i === index ? newObj : n))
            addNum(newArr)
            // }
            console.log(num)
        }

    }
    let options = beneficiaryList.map(b => ({ code: b.id, value: getContactFullName(b.contacts) }))
    const canAdd = true;
    const disabledAdd = false;
    return <div>
        <HeadBar title={string.forms.liabilitySpecific.paidBy + ' ' + string.forms.liabilitySpecific.details + string.forms.commonFormFields.mandatory} subtitle={string.forms.liabilitySpecific.LiabilityToBePaidBy} />
        {num.map((b, i) =>typeof b ==='undefined' || b.active || typeof b.active === 'undefined' ? <SingleItem b={b} i={i} disabledAdd={disabledAdd} remove={remove} canAdd={canAdd && num.length === i + 1} onSelect={onSelect} addNew={addNew} options={options} /> : null)}
    </div >
}

const SingleItem = ({ onSelect, addNew, options, b, i, canAdd, disabledAdd, remove }) => {
    const { value, setValue, bind } = useInput(b ? b.liabilityPercentage + "" : undefined);
    let { value: option, setValue: setOpt, bind: bindOption } = useInput(b ? b.id + "" : "");
    bindOption.onChange = (event) => {
        onSelect(value, event.target.value, i);
        setOpt(event.target.value)
    }
    bind.onChange = (event) => {
        onSelect(event.target.value, option, i);
        setValue(event.target.value)
    }
    //canAdd = canAdd;
    console.log(option)
    return <div className='row'>
        <DropDown placeholder={'Please Select'} options={options ? options : []} bind={bindOption} />
        <Input disabled={option === ""} inputProps={{ type: 'number', step: 2, max: 100, min: 0 }} placeholder={'00.00'} bind={bind} value={value} isBigger />
        {canAdd ? < button type="button" disabled={disabledAdd || _.isEmpty(value) || _.isEmpty(option) || isNaN(parseFloat(value) || parseFloat(value) <= 0)} className="btn btn-link" onClick={() => addNew()} ><i className="fa fa-plus" aria-hidden="true" style={{ fontSize: 20 }} /></button> : < button type="button" className="btn btn-link" onClick={() => remove(i)} ><i className="fa fa-minus" aria-hidden="true" style={{ fontSize: 20, color: 'red' }} /></button>}
    </div>
};

const GiveBeneficiary = forwardRef(GiveBeneficiaryForm);


const PickLiability = ({ pickLiabilityList, selected }, ref) => {
    let { value: pickLiability, bind: bindPickLiability } = useInput(selected ? selected.contactLookupCode : 'INDV');
    const refPickContact = useRef()
    useImperativeHandle(ref, () => ({
        returnDetails() { return refPickContact.current.returnDetails() }
    }));
    return <React.Fragment>
        <HeadBar title={string.forms.liabilitySpecific.individualOrInstitute} />
        <div className="row">
            <DropDown placeholder={string.forms.commonFormFields.pleaseSelect}
                options={[{ value: 'Individual', code: 'INDV' }, { value: 'Institution', code: 'INST' }]}
                bind={bindPickLiability}
            />
        </div>
        <PickContactTable selected={selected ? [selected.id] : []} isSingle data={pickLiabilityList.filter(c => _.get(c, ['contactLookupCode']) === pickLiability)} ref={refPickContact} />
    </React.Fragment>
}
const mapStateToPropsBen = state => ({
    pickLiabilityList: (getStoreContactList(state).concat(getStoreFamily(state)))//.filter(c => _.findIndex(_.flattenDeep(getStoreLiabilityList(state).map(b => b.contacts)), { id: _.get(c, ['id']) }) === -1),
})
const PickLiabilityTable = connect(mapStateToPropsBen, null, null, { forwardRef: true })(forwardRef(PickLiability));