import React, { useEffect, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import CollapseSection from '../../../components/CollapsibleSection';
import { ContainerPosition, CustomButtom } from '../../../components/CustomElements';
import { getStoreUserId, getStoreUserRole } from '../../../selectors/userSelectors';
import { getStoreFinAssignList, setFinAssignData, deleteFinAssignFromList } from '../../../actions/finAssignActions';
import { getContactFullName, getAssetType } from '../../../utils/commonUtil';
import { connect } from 'react-redux';
import { getFinAssignListFromServer, deleteFinAssignAll, deleteImmovableAssign, getImmovableAssignListFromServer, getOtherAssignListFromServer, getDigitalAssignListFromServer, deleteDigitalAssign, deleteOtherAssign } from '../assign/assignService';
import { getStoreImmovableAssetList } from '../../../actions/immovableAssetsActions';
import { getStoreFinAssetList } from '../../../actions/finAssetsActions'
import { getStoreOtherAssetList } from '../../../actions/otherAssetsActions';
import { getStoreDigitalAssetList } from '../../../actions/digitalAssetsActions';
import _ from 'lodash';
import { getStoreImmPropAssignList, setImmPropAssignData, deleteImmPropAssignFromList } from '../../../actions/immovablePropAssignActions';
import { getStoreOtherAssignList, deleteOtherAssignFromList, setOtherAssignData } from '../../../actions/otherAssignActions';
import { getStoreDigitalAssignList, deleteDigitalAssignFromList, setDigitalAssignData } from '../../../actions/digitalAssignActions';
import { SavedFD } from '../assets/forms/FixedDeposit'
import { SavedPhyShares } from '../assets/forms/PhysicalShares'
import { SavedDemat } from '../assets/forms/Demat'
import { SavedGovSchemes } from '../assets/forms/GovtScheme'
import { SavedPortfolio } from '../assets/forms/Portfolio'
import { SavedBankAcounts } from '../assets/forms/BankAccount'
import { SavedInsurance } from '../assets/forms/Insurance'
import { SavedMutualFunds } from '../assets/forms/MutualFund'
import { SavedBonds } from '../assets/forms/Bond'
import { SavedPF } from '../assets/forms/PF'
import { SavedGratuity } from '../assets/forms/Gratuity'
import { SavedArtefacts } from '../assets/forms/Artefacts'
import { SavedJewellery } from '../assets/forms/Jewellery'
import { SavedArts } from '../assets/forms/Art'
import { SavedLockers } from '../assets/forms/Locker'
import { SavedVehicles } from '../assets/forms/Vehicle'
import { SavedPersonalEffects } from '../assets/forms/PersonalEffect'
import { SavedSocials } from '../assets/forms/Social'
import { SavedCredits } from '../assets/forms/CreditCard'
import { SavedStem } from '../assets/forms/Stemcell'
import { SavedLoyalty } from '../assets/forms/LoyaltyPrograms'
import { SavedPassword } from '../assets/forms/Password'
import { SavedWebsites } from '../assets/forms/Website'
import { SavedimmProps } from '../assets/forms/ImmovableProperty'
import { SavedLiabilities } from '../../forms/liabilities/Liabilities'
import { HelpSection, HighlightedText, List } from '../../../components/CustomElements';
import { getStoreLiabilityList } from '../../../actions/liabilitiesActions';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import SummaryPdf from '../../../dashboard/SummaryPdf'

const Summary = ({finAssignList, immPropAssignList, otherAssignList, digitalAssignList,userId, dispatchDeleteFinAssignList,dispatchSetFinAssignList, dispatchSetImmPropAssignList, dispatchDeleteImmPropAssignList,dispatchSetOtherAssignList, dispatchDeleteOtherAssign, dispatchSetDigitalAssignList, dispatchDeleteDigitalAssign, userRole, immPropAssetList, finAssetList, otherAssetList, digitalAssetList, liabilityList, assetSummary, will}) =>{
  const [finAssignCount, setFinAssignCount]=useState(0)
  const isFormHidden = true
  const ref = useRef();
  /*const options = {
    orientation: 'p',
    unit: 'pt',
    format: 'a4',
    pagesplit: true,
};*/

/*const convertHtmlToPdf = () => {
  window.scrollTo(0, 0)
  const input = document.getElementById('htmlpdf');
  html2canvas(input)
  .then((canvas) => {
    const imgData = canvas.toDataURL('image/png');  
    var imgWidth = 1083
    var pageHeight = 855
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;
    var doc = new jsPDF('p', 'mm');
    var position = 0;
    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    doc.save( 'summary.pdf')
    });
}*/

const saveAsPdf = () =>{
  window.scrollTo(0, 0)

  window.html2canvas = html2canvas;
  var pdf = new jsPDF('p', 'pt',  [1200,1200]);
  pdf.html(document.getElementById('htmlpdf'), {
     callback: (pdf) => {
       pdf.save('Inheritance-Portfolio.pdf');
     },
     pagesplit: true,
  });
}

/*const pdff = (toPdf) =>{
  window.scrollTo(0, 0)
  toPdf()
}*/
 const assetsAndLiabilities = () =>{
  let fds =[],
  phyShares=[],
  demat=[],
  govtSchemes=[],
  portfolio=[],
  bankAccounts=[],
  insurance=[],
  mfs=[],
  bonds=[],
  pfs=[],
  gratuity=[],
  artefacts=[],
  jewellery=[],
  art=[],
  lockers=[],
  vehicles=[],
  perEffect=[],
  social=[],
  credit=[],
  stem=[],
  loyalty=[],
  password=[],
  web=[]
  if(finAssetList.length >0){
     fds = finAssetList.filter(e=>e.assetLookupCode === 'FD');
     phyShares = finAssetList.filter(e=>e.assetLookupCode === 'SHR');
     demat = finAssetList.filter(e=>e.assetLookupCode === 'DEM');
     govtSchemes = finAssetList.filter(e=>e.assetLookupCode === 'GOV');
     portfolio = finAssetList.filter(e=>e.assetLookupCode === 'PMS');
     bankAccounts = finAssetList.filter(e=>e.assetLookupCode === 'BNK');
     insurance = finAssetList.filter(e=>e.assetLookupCode === 'INS');
     mfs = finAssetList.filter(e=>e.assetLookupCode === 'MF');
     bonds = finAssetList.filter(e=>e.assetLookupCode === 'BON');
     pfs = finAssetList.filter(e=>e.assetLookupCode === 'PF');
     gratuity = finAssetList.filter(e=>e.assetLookupCode === 'GRA');
  }
  if(otherAssetList.length >0){
    artefacts = otherAssetList.filter(e=>e.assetLookupCode === 'ARTI');
    jewellery = otherAssetList.filter(e=>e.assetLookupCode === 'JEWL');
    art = otherAssetList.filter(e=>e.assetLookupCode === 'ART');
    lockers = otherAssetList.filter(e=>e.assetLookupCode === 'LOCK');
    vehicles = otherAssetList.filter(e=>e.assetLookupCode === 'VEH');
    perEffect = otherAssetList.filter(e=>e.assetLookupCode === 'PERS');
 }
 if(digitalAssetList.length >0){
  social = digitalAssetList.filter(e=>e.assetLookupCode === 'SOC');
  credit = digitalAssetList.filter(e=>e.assetLookupCode === 'CRD');
  stem = digitalAssetList.filter(e=>e.assetLookupCode === 'STM');
  loyalty = digitalAssetList.filter(e=>e.assetLookupCode === 'LYL');
  password = digitalAssetList.filter(e=>e.assetLookupCode === 'PWD');
  web = digitalAssetList.filter(e=>e.assetLookupCode === 'WEB');
}
  return(
    <div id='scrolltop'>
      {immPropAssetList.length===0 && digitalAssetList.length===0 && otherAssetList.length===0 &&  finAssetList.length===0 && liabilityList.length===0 ? <div><p style={{textAlign:'center',paddingTop:'5%'}}>You have not added any Assets and/or Liabilities yet</p>
      {/* <SummaryPdf/> */}
      </div> :
      <div>
        <HelpSection heading="HELP TIPS !">
        <List>
          This is a list of all your <b>Assets and Liabilities</b>.
          <HighlightedText> It is important you discuss your liabilities with our consultants as it impacts the total worth of your inheritance.Some liabilities may also be instantly payable.</HighlightedText>
        </List>
        </HelpSection>
      <div id='htmlpdf' ref={ref}>
      <SavedFD data={fds} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedPhyShares data={phyShares} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedDemat data={demat} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedGovSchemes data={govtSchemes} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedPortfolio data={portfolio} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedBankAcounts data={bankAccounts} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedInsurance data={insurance} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedMutualFunds data={mfs} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedBonds data={bonds} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedPF data={pfs} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedGratuity data={gratuity} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedArtefacts data={artefacts} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedJewellery data={jewellery} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedArts data={art} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedLockers data={lockers} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedVehicles data={vehicles} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedPersonalEffects data={perEffect} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedSocials data={social} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedCredits data={credit} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedStem data={stem} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedLoyalty data={loyalty} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedPassword data={password} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedWebsites data={web} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      <SavedimmProps data={immPropAssetList} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      {liabilityList.length > 0 ? <div>
      <SavedLiabilities data={liabilityList} isFormHidden={isFormHidden} datashare={'noShare'} dispalyActions={'no'}/>
      </div> : null}
      <SummaryPdf/>
      </div>
      {/*<ContainerPosition>
                <CustomButtom event={() => saveAsPdf()} title={'Save as Pdf'} />
      </ContainerPosition>*/}
        {/*<ReactToPdf targetRef={ref} filename="Bequethment-summary.pdf" options={options}>
          {({ toPdf }) => <ContainerPosition><CustomButtom event={() => pdff(toPdf)} title={"Generate Pdf"} /></ContainerPosition>}
      </ReactToPdf>*/}
      </div>
  }
    </div>
  )
  }


  const receiverFlag= userRole==='GIVER' ? false : true 
  useEffect(()=>{
    getFinAssignListFromServer(userId, receiverFlag)
    .then(resp=>{
      console.log(resp.data)
      let finCount= Object.keys(resp.data).length
      setFinAssignCount(finCount)
      if(!_.isEmpty(resp.data))
      dispatchSetFinAssignList(resp.data)
    })
    .catch(err=>{
      console.log(err)
    })
    getImmovableAssignListFromServer(userId, receiverFlag)
    .then(resp=>{
      console.log(resp.data)
      dispatchSetImmPropAssignList(resp.data)
    })
    .catch(err=>{
      console.log(err)
    })
    getOtherAssignListFromServer(userId, receiverFlag)
    .then(resp=>{
      console.log(resp.data)
      dispatchSetOtherAssignList(resp.data)
    })
    .catch(err=>{
      console.log(err)
    })
    getDigitalAssignListFromServer(userId, receiverFlag)
    .then(resp=>{
      console.log(resp.data)
      dispatchSetDigitalAssignList(resp.data)
    })
    .catch(err=>{
      console.log(err)
    })
  },[])

  let finAssign = Object.entries(finAssignList)
  return (
    <div>
      {userRole === 'GIVER'  && assetSummary ?
       <div>
       {assetsAndLiabilities()}
       </div> 
      :
      userRole === 'GIVER' ? 
  <div>
    {immPropAssignList.length===0 && otherAssignList.length===0 && digitalAssignList.length===0 &&  finAssignCount===0 ? <div><p style={{textAlign:'center',paddingTop:'5%'}}>You have not assigned Assets and Beneficiaries yet</p></div> :
    <div id='scrolltop'>
    <CollapseSection mainTitle={'Summary Of Your Assets And Beneficiaries'} collapsed={false}>
    <div id='htmlpdf'>
      {finAssign.map(assigned=>{
        if(assigned[1].length >0)
        return <SummaryTableAll
        title={getAssetType(assigned[0])}
        header={['Asset Name','Beneficiary Name','Age', 'Ratio']}
        rows={assigned[1].reduce((prev, current) => {const x = prev.find(item =>item.userBeneficiaryId === current.userBeneficiaryId &&item.assetId !== current.assetId);
          if (!x) {
            return prev.concat([current]);
          } else {
            return prev;
          }
        }, [])}
        assetLookupCode={assigned[0]}
        delAll={true}
        deleteFunc={deleteFinAssignAll}
        dispatchDelete={dispatchDeleteFinAssignList}
        will = {will}
      />
      else return null;
      })
    }
      {immPropAssignList.map(row=>{
        return <SummaryTableOne
        title={'Home/Property No. ' + row.assetIssuerName}
        header={['Asset Name','Beneficiary Name','Age', 'Ratio']}
        row={row}
        deleteFunc={deleteImmovableAssign}
        dispatchDelete={dispatchDeleteImmPropAssignList}
        will = {will}
      />
      })
    }
     {otherAssignList.map(row=>{
        return <SummaryTableOne
        title={ getAssetType(row.assetLookupCode)+ " " + row.assetIssuerName}
        header={['Asset Description','Beneficiary Name','Age', 'Ratio']}
        row={row}
        deleteFunc={deleteOtherAssign}
        dispatchDelete={dispatchDeleteOtherAssign}
        will = {will}
      />
      })
    }
     {digitalAssignList.map(row=>{
        return <SummaryTableOne
        title={ getAssetType(row.assetLookupCode)+ " " + row.assetIssuerName}
        header={['Asset Detail','Beneficiary Name','Age', 'Ratio']}
        row={row}
        deleteFunc={deleteDigitalAssign}
        dispatchDelete={dispatchDeleteDigitalAssign}
        will = {will}
      />
      })
    }
    </div>
      {/*<ContainerPosition>
        <CustomButtom title="Mail Summary" />
      </ContainerPosition>*/}
    </CollapseSection>
    {will === 'assetDistribution' ? null :
     <ContainerPosition>
          <CustomButtom event={() => saveAsPdf()} title={'Save as Pdf'} />
      </ContainerPosition>
       }
    </div>
    }
  </div>
       : userRole === 'RECEIVER' ? <div>
         {assetsAndLiabilities()}
         </div> : null
  }
  </div>
);
}

const SummaryTableAll =({rows, title, dispatchDelete, deleteFunc, header, delAll, assetLookupCode, will}) =>{
  
    const onDelete=()=>{
       if (delAll){ 
        let idList = rows.map(e=>e.id)
         deleteFunc(idList)
        .then(resp=>{
            console.log(resp.data)
            alert('Record Updated Successfully')
            idList.map(e=> {return dispatchDelete({assetLookupCode, finAssignId: e})})
        })
        .catch(err=>{
            console.log(err)
            alert(err)
        })}
    }
    return(
        <div style={{marginBottom:20}}>
          <h5 style={{ fontSize: 14 }}>
      {title}
      {will === 'assetDistribution' ? null : <span style={{ fontSize: 11, fontWeight: 300, marginLeft: 5, cursor: 'pointer', color: '#007bff' }} onClick={onDelete}>| click here to delete assignment |</span>}
    </h5>
        <Table responsive bordered size="sm">
          <tbody>
          <tr style={{ fontSize: 14, color: '#767676', fontWeight: 'bold' }}>
            {header.map((e, i) => (
              <td align="center" key={i} style={{ padding: 10 }}>
                {e}
              </td>
            ))}
          </tr></tbody>
          <tbody>{rows.map(buildRow)}</tbody>
        </Table>
      </div>
    )
}
const buildRow = (row,i) => (
  <tr key={row.id+1} style={{ fontSize: 13, color: '#767676' }} align="center">
  <td key={row.id+2} style={{ padding: 10 }}>All Assets</td>
   <td key={row.id+3} style={{ padding: 10 }}>{getContactFullName(row.contacts)}</td>
   <td key={row.id+4} style={{ padding: 10 }}>{row.contacts.age !== null && row.contacts.age !== '' ? row.contacts.age: '-' }</td>
   <td key={row.id+5} style={{ padding: 10 }}>{row.assetPercentage}</td>
</tr>
  );

  const SummaryTableOne =({row, title, dispatchDelete, deleteFunc, header, will}) =>{
    const onDelete=(id)=>{
      deleteFunc(row.id)
      .then(resp=>{
          console.log(resp.data)
          alert('Record Updated Successfully')
          dispatchDelete(row.id)
      })
      .catch(err=>{
          console.log(err)
          alert(err)
      })
    }
    return(
        <div style={{marginBottom:20}}>
          <h5 style={{ fontSize: 14 }}>
      {title}
      {will === 'assetDistribution' ? null : <span style={{ fontSize: 11, fontWeight: 300, marginLeft: 5, cursor: 'pointer', color: '#007bff' }} onClick={onDelete}>| click here to delete assignment |</span>}
    </h5>
        <Table responsive bordered size="sm">
          <tbody>
          <tr style={{ fontSize: 14, color: '#767676', fontWeight: 'bold' }}>
            {header.map((e, i) => (
              <td align="center" key={i} style={{ padding: 10 }}>
                {e}
              </td>
            ))}
          </tr></tbody>
          <tbody>
            <tr style={{ fontSize: 13, color: '#767676' }} align="center">
              {header.length === 4 ? <td style={{ padding: 10 }}>{row.assetDescription}</td>:null}
              <td style={{ padding: 10 }}>{getContactFullName(row.contacts)}</td>
              <td style={{ padding: 10 }}>{row.contacts.age !== null && row.contacts.age !== '' ?row.contacts.age :'-' }</td>
              <td style={{ padding: 10 }}>{row.assetPercentage}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
}

const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
  finAssignList : getStoreFinAssignList(state),
  immPropAssignList: getStoreImmPropAssignList(state),
  immPropAssetList: getStoreImmovableAssetList(state),
  otherAssignList: getStoreOtherAssignList(state),
  digitalAssignList: getStoreDigitalAssignList(state),
  finAssetList:getStoreFinAssetList(state),
  otherAssetList: getStoreOtherAssetList(state),
  digitalAssetList: getStoreDigitalAssetList(state),
  userRole:getStoreUserRole(state),
  liabilityList: getStoreLiabilityList(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchSetFinAssignList: finAssign=> dispatch(setFinAssignData(finAssign)),
  dispatchSetImmPropAssignList: immPropAssign=> dispatch(setImmPropAssignData(immPropAssign)),
  dispatchSetOtherAssignList: otherAssign=> dispatch(setOtherAssignData(otherAssign)),
  dispatchSetDigitalAssignList: digitalAssign=> dispatch(setDigitalAssignData(digitalAssign)),
  dispatchDeleteFinAssignList: finAssign=> dispatch(deleteFinAssignFromList(finAssign)),
  dispatchDeleteImmPropAssignList: immPropAssign=> dispatch(deleteImmPropAssignFromList(immPropAssign)),
  dispatchDeleteOtherAssign: otherAssign=> dispatch(deleteOtherAssignFromList(otherAssign)),
  dispatchDeleteDigitalAssign: digitalAssign=> dispatch(deleteDigitalAssignFromList(digitalAssign)),
  })
 export default connect(mapStateToProps, mapDispatchToProps)(Summary);

