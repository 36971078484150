import React, { useState } from 'react'
//import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { Button, Modal } from 'react-bootstrap'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { getContactFullName, getSplitName } from '../../utils/commonUtil'
import { sendInvite } from '../helper/MailingServices'
import { setUserData } from '../../actions/userActions'
import { updateUser, getUserIdByEmail } from '../forms/personal/personalService'
import { setEstatePlanData} from '../../actions/freeEstatePlanAction'
import { setLegalReviewData } from '../../actions/freeLegalReviewAction'
import { connect } from 'react-redux'
import { getStoreUserId, getStoreUserRole } from '../../selectors/userSelectors'
import _ from 'lodash';
import GetStartedTemplate from '../../components/get_started/template'
import { sendSummary } from '../../dashboard/forms/will/WillServices'
import { useInput } from '../../hooks/useInput'
import Input from '../../components/Input'
import Auth from "@aws-amplify/auth";
import { AWS_CONFIG } from '../../constant/ServerConst';
import { Post } from '../../utils/ApiUtil';
import DayTimePicker from '@mooncake-dev/react-day-time-picker'

Auth.configure({ ...AWS_CONFIG.Auth })
/*const slots = {
  '2020-6-28': ['2 slots'],
  '2020-6-22': ['1 slot'],
  '2020-6-25': ['1 slot'],
  '2020-6-29': ['3 slots'],
}*/

/*function renderDay(day) {
  const date = day.getDate()
  const month = day.getMonth()
  const year = day.getFullYear()
  const dateStyle = {
    fontSize: 10,
  }
  const slotStyle = {
    fontSize: '0.3em',
    color: '#808080',
  }
  const cellStyle = {
    position: 'relative',
  }
  return (
    <div style={cellStyle}>
      <div style={dateStyle}>{date}</div>
      {slots[year + '-' + month + '-' + date] &&
        slots[year + '-' + month + '-' + date].map((name, i) => (
          <div key={i} style={slotStyle}>
            {name}
          </div>
        ))}
    </div>
  )
}*/

function timeSlotValidator(slotTime) {
  const eveningTime = new Date(
    slotTime.getFullYear(),
    slotTime.getMonth(),
    slotTime.getDate(),
    9,
    0,
    0
  );
  const eveningTime1 = new Date(
    slotTime.getFullYear(),
    slotTime.getMonth(),
    slotTime.getDate(),
    18,
    0,
    0
  );
  const isValid = slotTime.getTime() > eveningTime.getTime() && slotTime.getTime() < eveningTime1.getTime();
  return isValid;
}

const CalenderSlots = ({ consultWho, name, email, freeConsult, updateId, toggleFreeConsultModal, dispatchSetEstatePlanData, dispatchSetLegalReviewData, userId, userRole, will, content, summarypayload, starProductCard, unloggedinCalender}) => {
  const dispatch = useDispatch()
  const [selectedDay, setSelectedDay] = useState(null)
  const [visibleCalender, setVisibleCalender] = useState(true)
  const toggleCalenderModal = () => setVisibleCalender(!visibleCalender)
  const [page, setPage] = useState(0)
  const user = useSelector((state) => state.user)
  const nextPage = () => setPage(page+1)
  const { value: nameOfUser, bind: setNameOfUser } = useInput('')
  const { value: emailId, bind: setEmail } = useInput('')
  const { value: phoneNum, bind: setPhoneNum } = useInput('','mobile')
  const [uId, setUserId] = useState('')

  const closeToggle = () => {
    toggleCalenderModal()
    toggleFreeConsultModal()
  }
  /*const handleDayClick = (day) => {
    setSelectedDay(day)
    nextPage()
  }*/
  
  const createUser = async () => {
    const re = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/g
    const phoneFormat = ((/^[0-9\b]+$/).test(phoneNum)) || ((/^[+|-][0-9\b]+$/).test(phoneNum))
    let emailIDs = emailId.toLowerCase().trim();
    if (
      re.test(String(emailId).toLowerCase()) &&
      phoneFormat
    ) {
      let awsPayload = {
        username: emailIDs,
        password: 'Welcome@01',
        attributes: {
          email: emailIDs,
          phone_number: ((/^[+|-][0-9\b]+$/).test(phoneNum)) ? phoneNum : '+91' + phoneNum,
          given_name: nameOfUser,
        },
      }
      console.log(awsPayload)
      Auth.signUp(awsPayload)
        .then((response) => {
          console.log('aws signup complete')
          console.log(response)
          Post('userprofile/create?userId=' + response.userSub, {
            userName: response.userSub,
            primaryEmail: emailIDs,
            mobilePhone: ((/^[+|-][0-9\b]+$/).test(phoneNum)) ? phoneNum : '+91' + phoneNum,
            ...getSplitName(nameOfUser),
          })
            .then((resp) => {
              console.log('backend user create complete')
              console.log(resp)
              setUserId(resp.data.id)
              createCalEvent(selectedDay)
        })
            .catch((error) => {
              console.log('backend error')
              console.log(error)
              alert('Unable to register, please try again!')
            })
        })
        .catch((error) => {
          console.log('aws error')
          console.log(error)
          if (error.code === 'UsernameExistsException') {
            getUserIdByEmail(emailIDs)
            .then(response=>{
            setUserId(response.data.id)
            createCalEvent(selectedDay)
            })
          } else alert(error.message)
        })
    } else {
      if (!phoneFormat)
        alert('please enter valid phone number')
      else
        alert(
          'Please enter a valid email address. You could be missing something in the e-mail format.'
        )
    }
  }

  const validateUser = () =>{
    const payload = {'name' : nameOfUser, 'email': emailId.toLowerCase().trim(), 'phone':phoneNum}
    const ev=[]
    let emptyData = []
    for (var key in payload) {
      switch(key){
          case 'name':
            _.isEmpty(payload[key]) ? ev.push("Please Enter Your Name\n"): emptyData.push(null)
            break
          case 'email':
            _.isEmpty(payload[key]) ? ev.push("Please Enter Your Email\n"): emptyData.push(null)
            break
          case 'phone':
             _.isEmpty(payload[key]) ? ev.push("Please Enter Your Phone Number\n"): !(((/^[0-9\b]+$/).test(payload[key])) || ((/^[+|-][0-9\b]+$/).test(payload[key]))) ? ev.push("Enter Valid Phone Number\n") : emptyData.push(null)
            break
          default:
            break
          } 
        }
          if(ev.length>0){
            alert(ev)
            setPage(3)
          }
          else{
            unloggedinCalender ? createUser() :createCalEvent(selectedDay)
          }
  }
  const createCalEvent = (selectedDay) => {

    let emptyData= []
    let payload = {
      summary: 'FREE 30 minute appointment with ' + consultWho,
      dtstart: moment.utc(selectedDay).format(),
      dtend: moment.utc(selectedDay).add(30, 'minutes').format(),
      attendee: (isNaN(userId) || _.isEmpty(userRole)) ? { email: starProductCard && (isNaN(userId) || _.isEmpty(userRole) || unloggedinCalender) ? emailId.toLowerCase().trim() : email.toLowerCase().trim(), name: starProductCard && isNaN(userId) || _.isEmpty(userRole) || unloggedinCalender ? nameOfUser :name } : { email: user.primaryEmail, phone: user.mobilePhone,name: getContactFullName(user) },
    }
    console.log(JSON.stringify(payload))

    {
      will ?
    (
      sendSummary(content, summarypayload)
    .then((res)=>{
            console.log(res.data)
            nextPage()
    })
    .catch(err=>
        (console.log(err))
    )
    )
    : emptyData.push(null) }

    sendInvite(payload)
      .then((resp) => {
        let body =
          consultWho === 'Our Estate Planners'
            ? { custField4: 'ESTATE_PLANNER_APT_SCHEDULED' }
            : unloggedinCalender ? { custField5: 'LEGAL_REVIEW_APT_SCHEDULED', mobilePhone: phoneNum } :{ custField5: 'LEGAL_REVIEW_APT_SCHEDULED' }
        { will || starProductCard ? emptyData.push(null) :(updateUser(unloggedinCalender ? uId : isNaN(userId) || _.isEmpty(userRole) ? updateId : user.id, body)
          .then((resp) => {
            isNaN(userId) || _.isEmpty(userRole) ? consultWho === 'Our Estate Planners' ? dispatchSetEstatePlanData(body) 
                        : consultWho === 'Our Legal Experts' ? dispatchSetLegalReviewData(body) : emptyData.push(null)
                        : dispatch(setUserData(resp.data))
          })
          .catch((err) => {
            console.log(err)
            alert('Please try again!')
          })
      )
        }
        will ? emptyData.push(null) : setPage(2)
      })
      .catch((error) => {
        alert('Please try Again!')
      })
  }
  const conAppointment = (date) =>{
    setSelectedDay(date)
    starProductCard && (isNaN(userId) || _.isEmpty(userRole)) || unloggedinCalender ? setPage(3) : setPage(1)
  }
  const confirmAppointment = () => {
    let today = new Date()
    let isToday =
      selectedDay === null
        ? null
        : today.toDateString() == selectedDay.toDateString()
    let date = selectedDay === null ? null : selectedDay.getDate()
    let month = selectedDay === null ? null : selectedDay.getMonth()
    let year = selectedDay === null ? null : selectedDay.getFullYear()
    let selectedMonth = moment(new Date(year, month, date)).format('MMMM')
    let createEventOn =
      isToday === false ? date + 'th of ' + selectedMonth +', '+ year: 'today'
    return (
      <div style={{ textAlign: 'center', padding: '10vh' }}>
        <p>Are You sure you want to book an appointment for {createEventOn} ?</p>
        <Button
          style={{ width: '15vw', display: 'inline' }}
          onClick={starProductCard && isNaN(userId) || _.isEmpty(userRole) || unloggedinCalender ? setPage(3) :() => createCalEvent(selectedDay)}
        >
          Confirm
        </Button>

        <br />
        <br />
        <Button
          onClick={closeToggle}
          style={{ width: '15vw', display: 'inline' }}
        >
          Later
        </Button>
      </div>
    )
  }
  const [isScheduled, setIsScheduled] = useState(false);

  const renderPage = () => {
  switch (page) {
    case 0:
      return (
        <div>
        <button style={{marginRight:'20px',marginTop:'40px'}} type='button' className='close' onClick={closeToggle}>
          &times;
        </button>
        <GetStartedTemplate
          title={"We like things organized. Book a suitable day and time and our reps will call you."}
          bar={25}
          body={
        <div>
         <DayTimePicker
        timeSlotSizeMinutes={30}
        timeSlotValidator={timeSlotValidator}
        isDone={isScheduled}
        onConfirm={conAppointment}
      /><br/>
       <Button
          onClick={closeToggle}
          style={{ variant:'outline-info', size:'md', width: '22vw', fontWeight: 500}}>
          Close
        </Button> 
        {/*<DayPicker
          onDayClick={handleDayClick}
          disabledDays={[{ before: new Date() }]}
          renderDay={renderDay}
        />*/}
      </div>
      }
      />
      </div>
      )
      case 1:
      return(
        <GetStartedTemplate
        title={"Confirm your appointment"}
        bar={50}
        body={
        confirmAppointment()
        }
      />
  )
      case 2:
      return(
        <GetStartedTemplate
        title={"Confirm your appointment"}
        bar={100}
        body={
          will ?
          <div>
            <p>We have requested an appointment with our legal team. If you had started a draft will, we have shared a copy with the team as well.</p>
            <Button
            onClick={closeToggle}
            style={{
              paddingLeft: 40,
              paddingRight: 40,
              marginTop: 50,
              marginBottom: 50,
            }}
          >
            Thank you
          </Button>
          </div>
          :
          <div>
          <p>
              Please look for an email from{' '}
              <span style={{ color: '#007bff', fontWeight: 'bold' }}>
                EasyInherit
              </span>{' '}
              in your inbox. <br></br>
              <span style={{ fontWeight: 'bold' }}>Your phone number in our records is  {user.mobilePhone}.</span>
              
          </p>
          <p>
              You have Booked a{' '}
              <span style={{ fontWeight: 'bold' }}>
                30 minute FREE appointment{' '}
              </span>
              with our {consultWho === 'Our Estate planning' ? 'Estate Planners.' : 'Legal Experts.'} 
              <br />
        </p>
        <p>
              Our {consultWho === 'Our Estate planning' ? 'consultants' : 'consultants'} will get back to you as soon
              as possible.
        </p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={closeToggle}
            style={{
              paddingLeft: 40,
              paddingRight: 40,
              marginTop: 50,
              marginBottom: 50,
            }}
          >
            Thank you
          </Button>
        </div>
      </div>
        }
      />
    )
    case 3:
    return(
      <GetStartedTemplate
      title={"Confirm your appointment"}
      bar={75}
      body={
        <div>
        <label>
        <b>What's your name?</b>
        </label>
        <Input
            noClass
            type='text'
            className='form-control form-control-lg'
            bind={setNameOfUser}
            placeholder="E.g. Manik Chand, Sarita Verma" 
        />
        <label>
        <b>What's your e-mail ?</b>
        </label>
        <Input
            noClass
            type='text'
            className='form-control form-control-lg'
            bind={setEmail}
            placeholder="E.g. Manik@gmail.com" 
        />
        <label>
          <b>What is your Phone Number ?</b>
        </label>
        <Input
            noClass
            type='text'
            className='form-control form-control-lg'
            bind={setPhoneNum}
            placeholder="E.g. Enter 10 digit number" 
        />
        <br/>
        
        <Button
          style={{ variant:'outline-info', size:'md', width: '19vw' , fontWeight: 500}}
          onClick={validateUser}
        >
          Confirm
        </Button>

        <br />
        <br />
        
        <Button
          onClick={closeToggle}
          style={{ variant:'outline-info', size:'md', width: '19vw', fontWeight: 500}}>
          Later
        </Button> 
        
        </div>
      }
    />
)
default:
      break
    }
    }
return (
  <Modal
    show={visibleCalender}
    size='lg'
    scrollable
    aria-labelledby='contained-modal-title-vcenter'
    centered
  >
    {renderPage()}
  </Modal>
)
}

const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
  userRole:getStoreUserRole(state),
})
const mapDispatchToProps = () => (dispatch) => ({
  dispatchSetEstatePlanData : (estatePlan) => dispatch(setEstatePlanData(estatePlan)),
  dispatchSetLegalReviewData : (legalReview) => dispatch(setLegalReviewData(legalReview))
})

export default connect(mapStateToProps, mapDispatchToProps)(CalenderSlots)
