import _ from 'lodash';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getStoreContactList } from '../../actions/contactActions';
import { getStoreExecutorList } from '../../actions/executorActions';
import { getStoreFamily } from '../../actions/familyActions';
import { getStoreNonDisinheritList } from '../../actions/nonDisinheritActions';
import CollapseSection from "../../components/CollapsibleSection";
import { getStoreUserId } from '../../selectors/userSelectors';
import { getContactFullName, getContactType, getRelation } from '../../utils/commonUtil';

const PickContact = ({ data, isSingle, selected = [] }, ref) => {
  let [selectedIds, bindSelected] = useState(selected);
  useImperativeHandle(ref, () => ({
    returnDetails() {
      return selectedIds;
    }
  }));
  const row = data.map((e, i) => {
    return (
      <tr key={i} className="col-xl-3 col-md-6 col-lg-4 col-xs-12">
        <td data-field="CheckBox"><input type="checkbox"
          checked={_.includes(selectedIds, e.id)}
          onChange={(event) => {
            if (event.target.checked) {
              bindSelected([e.id])
            } else {
              bindSelected(selectedIds.filter(id => id !== e.id))
            }
          }}
        />
        </td>
        <td data-field="Name">{getContactFullName(e)}</td>
        <td data-field="Relationship">{getRelation(e)}</td>
        <td data-field="Type">{getContactType(e)}</td>
      </tr>
    )
  });
  return (
    <div>
      <CollapseSection mainTitle={'Select '} collapsed={false} isCollapsable={true} customStyle={true}>
        <Table responsive>
          <thead>
            <tr>
              <th data-field="CheckBox">Select</th>
              <th data-field="Name">Name</th>
              <th data-field="Relationship">Relationship</th>
              <th data-field="Type">Type</th>
            </tr>
          </thead>
          <tbody>{row.length > 0 ? row : 'No data'}</tbody>
        </Table>
      </CollapseSection>
    </div>
  );
};
const PickContactTable = forwardRef(PickContact);

const mapStateToProps = (state) => ({
  nonDisinheritList: getStoreNonDisinheritList(state),
  executorList: getStoreExecutorList(state),
  allContactsList: getStoreContactList(state).concat(getStoreFamily(state)),
  userId: getStoreUserId(state),
});
export default connect(mapStateToProps, null, null, { forwardRef: true })(PickContactTable);
