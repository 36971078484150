import React, { useEffect } from 'react';
import Footer from './component/layout/Footer';
import Header from './component/layout/Header';
import Slider from './component/layout/Slider';
import ImageCardSection from './component/section/ImageCardSection';
import LogoCardSection from './component/section/LogoCardSection';
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
import { TRAKING_ID } from '../constant/ServerConst'

ReactGA.initialize(TRAKING_ID);
mixpanel.init("60ba11d6c224405b0b1a24bacb55bd8b");
console.log('mixpanel initilized')

const Home = () => {

  useEffect(() => {
  ReactGA.pageview('homepage')
  mixpanel.track("Home Page")
},[])

    return (
        <div>
        <Header />
        <Slider />
        <ImageCardSection />
        <LogoCardSection/>
        <Footer />
      </div>
    );
}
export default Home;