import React, { forwardRef, useRef, useState } from 'react';
import CollapseSection from "../../../../components/CollapsibleSection";
import DropDown from '../../../../components/DropDown';
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import { useInput } from "../../../../hooks/useInput";
import Address from '../../../components/Address';
import string from '../../../../constant/en.json';
import { batchState, getId, getContactFullName, getObjMapped } from '../../../../utils/commonUtil';
import { ContainerPosition, CustomButtom, ActionItem, TableContainer, CenterAlignRow, AlignColumn, HelpSection, HighlightedText, List } from '../../../../components/CustomElements';
import PickContactTable from '../../../components/PickContactTable';
import { getStoreUserId, getStoreUserRole } from '../../../../selectors/userSelectors';
import { connect } from 'react-redux';
import { deleteImmovableAssetFromList, editImmovableAsset, addImmovableAsset, getStoreImmovableAssetList } from '../../../../actions/immovableAssetsActions';
import { updateImmovableAssetById, createImmovableAsset, deleteImmovableAsset } from '../immovableAssetService';
import _ from 'lodash';
import CheckBox from '../../../../components/CheckBox';
import { getStoreContactList } from '../../../../actions/contactActions';
import { getStoreFamily } from '../../../../actions/familyActions';
import HelperModal from '../../../helper/HelperModal';
import { useQuaishToasts } from '../../../../actions/alertAction';
import ModalContainer from '../../../../home/component/layout/Modal'
import DataShareModal from '../../../../dashboard/forms/dataShare/DataShareModal'

const ImmovableProperty = ({userId, immPropImmovableAssetList, pickList,dispatchAddImmovableAsset, dispatchEditImmovableAsset, dispatchDeleteImmovableAsset, userRole}, ref) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [immProp, setImmProp] = useState();
  let [visibleDataShare, setVisibleDataShare] = useState(false)
  let toggleDataShare = () => setVisibleDataShare(!visibleDataShare)
  const [dataShareId, setDataShareId] = useState('')

  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && immProp &&  immProp.id ) {
        setImmProp(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setImmProp(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
      setImmProp(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const onShare = (c) => {
    setDataShareId(c)
    toggleDataShare()
  }
       
  return (
    <div>
      <HelpSection heading="HELP TIPS!">
        <List>
        List down all your assets here. Tick on the relevant boxes and fill the details below. 
        <HighlightedText>It is advisable to provide details of all your assets. </HighlightedText>
        </List>
      </HelpSection>
        <SavedimmProps data={immPropImmovableAssetList} onEdit={onEdit} onShare={onShare} isFormHidden={isFormHidden} dispatchDeleteImmovableAsset={dispatchDeleteImmovableAsset} />
        <div  style={{ marginBottom: 20, display: 'block' }}>
          <ContainerPosition>
            <CustomButtom event={() => toggleForm()} title={'Add Immovable Properties'} />
          </ContainerPosition>
        </div>
        {isFormHidden ? null : <ImmPropForm immProp={immProp} userId={userId} onCancel={toggleForm} onDone={onDone} immPropImmovableAssetList={immPropImmovableAssetList} dispatchAddImmovableAsset={dispatchAddImmovableAsset} dispatchEditImmovableAsset={dispatchEditImmovableAsset} pickList={pickList} userRole={userRole}/>}
        {visibleDataShare
                  ? 
                  <ModalContainer visible={visibleDataShare} toggle={toggleDataShare}>
                    <DataShareModal visibleDataShare={visibleDataShare} toggleDataShare={toggleDataShare} dataShareId={dataShareId} AssetList={immPropImmovableAssetList}  assetLookupKey={"asset_immv"} assetLookupCode={'PROP'}/>
                  </ModalContainer> 
                  : null 
        }
    </div>
  );
}

const ImmPropFrm = ({immProp,userId, onCancel, onDone,pickList, immPropImmovableAssetList, dispatchAddImmovableAsset, dispatchEditImmovableAsset, userRole, displayNominee}, ref) => {
  // const { value: assetIdentificationNumber, bind: bind2 } = useInput(!immProp ? '' : immProp.assetIdentificationNumber);
  const { value: assetHolding, bind: bind1 } = useInput(!immProp ? '' : immProp.assetHolding);
  const { value: pickedNominee, bind: bindNominee } =useInput(!immProp ? 'INDV' : immProp.contacts === null ? '': immProp['contacts']['contactLookupCode']);
  const selectedId =  !immProp ? '' : immProp.contacts === null ? '':immProp['contacts']['id']
  const { value: parkingSpaceNo, bind: bindPrakingNo } = useInput(!immProp ? '' : immProp.parkingNumber);
  const [parkingSpace, setParkingSpace] = useState( !immProp ? false: immProp.parkingInclFlag);
  const [assetStatus, setStatus] = useState(!immProp ? 'Freehold' : immProp.assetStatus);
  const [jointOwnership, setJointOwnership] = useState(!immProp ?'Yes'  : immProp.contacts === null ? 'No':'Yes');
  const [assetInherited, setInherited] = useState( !immProp ?'Inherited' : immProp.assetInherited ? "Inherited": "Self-Accquired");
  const [liabOnProp, setLiabOnProp] = useState( !immProp ?'No': immProp.liabilityId ? 'Yes': 'No');
  const [litigation, setLitigation] = useState( !immProp ?'No': immProp.litigationFlag ? 'Yes': 'No');
  const { success, error, removeAll } = useQuaishToasts()

  const refPickNominee = useRef();
  const refAddress = useRef();
  let defaultAddr = immProp ? {houseName:immProp.assetAddress1, street: immProp.assetAddress2, city: immProp.assetAddressCity, state: immProp.assetAddressState, country: immProp.assetAddressCountry, postalCode:immProp.assetAddressPin} : {houseName:'', street:'', city:'', state:'', country:'', postalCode:''}
  /*const validate=(payload)=>{
    const ev=[]
    for (var key in payload) {
      switch(key){
          case 'assetHolding':
            _.isEmpty(payload[key]) ? ev.push(string.forms.immovableProperty.immovableType):null
            break
          case 'assetAddress1':
            _.isEmpty(payload[key]) ? ev.push(string.forms.immovableProperty.immovablePropertyPlot):null
            break
          case 'assetAddress2':
            _.isEmpty(payload[key])? ev.push(string.forms.immovableProperty.immovablePropertyStreet):null
            break
          case 'assetAddressState':
            _.isEmpty(payload[key]) ? ev.push(string.forms.immovableProperty.immovablePropertyState):null
            break
          case 'assetAddressCity':
            _.isEmpty(payload[key])? ev.push(string.forms.immovableProperty.immovablePropertyCity):null
            break
          case 'assetAddressCountry':
            _.isEmpty(payload[key]) ? ev.push(string.forms.immovableProperty.immovablePropertyCountry):null
            break
          case 'assetAddressPin':
            _.isEmpty(payload[key])? ev.push(string.forms.immovableProperty.immovablePropertyPostalCode):null
            break
          case 'parkingInclFlag':
            payload[key]===true ? isNaN(payload['parkingNumber']) ? ev.push(string.forms.immovableProperty.immovablePropertyParking)  :null :null
            break
          case 'contacts':
            jointOwnership === 'Yes' ?payload[key]['id']=== undefined ? ev.push(string.forms.immovableProperty.emptyNominee):null : null
            break
          default:
            break
          } 
        }
          if(ev.length>0){
            warn(ev)
            return false
          }
          else return true
}*/
  const submit = () => {
    removeAll()
    const roleOfUser= userRole==='GIVER' ? false : true
    const payload = {
      userId,
      assetLookupCode: 'PROP',
      receiverFlag:	roleOfUser,
      parkingNumber: parseInt(parkingSpaceNo),
      parkingInclFlag:parkingSpace,
      assetInherited: assetInherited === 'Inherited',
      assetStatus: assetStatus,
      litigationFlag: litigation === 'Yes',
      // liabilityId: liabOnProp === 'Yes',
      assetSelfAcquired: assetInherited === 'Self-Accquired',
      ...getObjMapped(['assetAddress1','assetAddress2','assetAddressState','assetAddressCity','assetAddressCountry','assetAddressPin'],refAddress.current.returnDetails()),
      assetHolding: assetHolding ==='' ? null : assetHolding,
      contacts: roleOfUser ? null  : (jointOwnership === 'Yes' ? displayNominee === 'no' ? _.isEmpty(immProp) || _.isEmpty(immProp.contacts) ? null : { id: immProp['contacts']['id'] } : { id: _.head(refPickNominee.current.returnDetails())}: null)
    }
    console.log(payload)
    //if(validate(payload))

    getId(immProp) ? updateImmovableAssetById(immProp.id,{id:immProp.id ,...payload})
    .then(response => {
      success('Record Created Successfully');
        dispatchEditImmovableAsset(response.data)
        onDone()
    }).catch(err => {
        error(err)
    }) : 
    createImmovableAsset(payload)
    .then(response => {
      success('Record Updated Successfully');
      dispatchAddImmovableAsset(response.data);
      onDone()
        // dispatchSetAllContactList(response.data);
    })
    .catch(err => {
      error(err)
    })
}
  return <CollapseSection  mainTitle={'Enter ' + string.forms.immovableProperty.immovablePropertyFormTitle} >
    <section className='section_form'>
      <div>
        <HeadBar title={string.forms.immovableProperty.typeOfProp} subtitle={'*'} />
      </div>
      <div className='row'>
        <DropDown  placeholder={string.forms.immovableProperty.typeOfProp} isBigger bind={bind1} options={[{
      "lookupKey": "immv_asset_type",
      "code": "RES",
      "value": "Residential"
    },
    {
      "lookupKey": "immv_asset_type",
      "code": "COMM",
      "value": "Commercial"
    },
    {
      "lookupKey": "immv_asset_type",
      "code": "LAND",
      "value": "Land"
    }]} />
      </div>
      <Address title={string.forms.immovableProperty.propertyAddress} ref={refAddress} first={'Flat no /Plot no'} noLandMark defaultValue={defaultAddr} />
      {assetHolding !== 'LAND' ?<div>
        <CheckBox title={'Parking Included ?'} defaultValue={parkingSpace} onChange={setParkingSpace} />
        <div className='row'>
        <Input type='number' placeholder={'No. of parking space(s)'}bind={bindPrakingNo} isBigger disabled={!parkingSpace} />
        </div>
      </div> : null}
      {displayNominee === 'no' ? null : 
      userRole==='RECEIVER' ? null :
      <RadioOptions assetStatus={assetStatus} setStatus={setStatus} liabOnProp={liabOnProp} setLiabOnProp={setLiabOnProp} assetInherited={assetInherited} setInherited={setInherited} jointOwnership={jointOwnership} setJointOwnership={setJointOwnership} litigation={litigation} setLitigation={setLitigation} />
      }
      {displayNominee === 'no' ? null : 
      jointOwnership === 'Yes' && userRole==='GIVER'?
        <div>
      <div>
        <HeadBar title={'Joint Ownership with:'} subtitle={''} />
      </div>
      <div className='row'>
        <DropDown options={[{ value: 'Individual', code: 'INDV' }, { value: 'Institution', code: 'INST' }]} placeholder={string.forms.commonFormFields.pleaseSelect} isBigger bind={bindNominee} />
      </div>
      <PickContactTable isSingle data={pickList.filter(c => _.get(c, ['contactLookupCode']) === pickedNominee)} ref={refPickNominee} selected={[selectedId]} />
      </div> : null}
    </section>
    <ContainerPosition>
            <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
            <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
        </ContainerPosition>
  </CollapseSection>
}
export const ImmPropForm = forwardRef(ImmPropFrm);

export function SavedimmProps ({ data, dispatchDeleteImmovableAsset, onEdit, dispalyActions, onShare, datashare, dataShareByMe, accessLevelArray, toggle}) {
  const { success, error } = useQuaishToasts()
  const deleteProp = (id) =>{
    if(window.confirm("Delete?\n Are you sure you want to delete this record?")){
        deleteImmovableAsset(id)
        .then(response => {
          success('Record Deleted Successfully');
          dispatchDeleteImmovableAsset(id);

        })
        .catch(err => {
          error(err)
        })
    }
  }

  const propertyType={"RES":"Residential",
              "COMM": "Commercial",
              "LAND": "Land"
            }
  const row = data.map((e) => {
    return(
      
    <TableContainer>
      <CenterAlignRow>
        <AlignColumn label="Property Type" text={propertyType[e.assetHolding]} />
        <AlignColumn label="Property No." text={e.assetAddress1} />
        <AlignColumn label="Joint Holder" text={e.contacts !== null && e.contacts.id !== ''?getContactFullName(e.contacts) : 'None'} />
        {datashare === 'yes' ? <AlignColumn label="Shared with" text={_.isEmpty(dataShareByMe[e.id][0]) ? null : dataShareByMe[e.id][0].map((f,index)=>(<span style={{cursor:'pointer', color: '#007bff'}} onClick={()=>toggle(dataShareByMe[e.id][1][index])}><span style={{textDecoration:'underline'}}>{f}</span><span>{index !== (dataShareByMe[e.id][0].length) -1 ? ' , ' : ''}</span></span>))} />: null}
        {datashare === 'shareby' ? <AlignColumn label="Shared By" text={dataShareByMe[e.id]} />: null}
        {dispalyActions === 'no' || datashare === 'yes' || (datashare === 'shareby' && accessLevelArray[e.id]==='READ')  ? null :
        <AlignColumn label="Action" text={<ActionItem onEdit={()=>onEdit(e)} onDelete={()=>deleteProp(e.id)} onShare={() => onShare(e.id)} datashare={datashare}/>} />}
      </CenterAlignRow>
    </TableContainer>
  )});
  return (
    data.length === 0 ? null :
    <div>
    <CollapseSection isHidden={data.length === 0} mainTitle={'Immovable Properties'} collapsed={false} onShare={() => onShare('multicreate')}  isCollapsable={true} customStyle={true}  datashareuser={datashare}  datashare={'yes'}>
      {row}
    </CollapseSection>
          {dispalyActions === 'no' || datashare === 'yes' || datashare === 'shareby' ? <br/> : null }
     </div>
  );
};

 const mapStateToProps = (state) => ({
  immPropImmovableAssetList: getStoreImmovableAssetList(state),
  userId: getStoreUserId(state),
  pickList: (getStoreContactList(state).concat(getStoreFamily(state))),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchAddImmovableAsset: immProp => dispatch(addImmovableAsset(immProp)),
  dispatchEditImmovableAsset:immProp=>dispatch(editImmovableAsset(immProp)),
  dispatchDeleteImmovableAsset:immProp=>dispatch(deleteImmovableAssetFromList(immProp)),
})
 export default connect(mapStateToProps, mapDispatchToProps)(ImmovableProperty);


 const RadioOptions = ({assetStatus, setStatus, liabOnProp, setLiabOnProp, assetInherited, setInherited, jointOwnership, setJointOwnership, litigation, setLitigation}) => {
   return(
     <div >
       <div className="row"  style={{marginLeft:5}}  >
       <HeadBar title={string.forms.immovableProperty.propertyStatus} subtitle={'*'}/>
       <HelperModal helperTextId={'propertyStatus'} />
        <div style={{marginLeft:5}}>
        <div className='form-check form-check-inline'>
            <label className='form-check-label'>
              <input className='form-check-input' type='radio' checked={assetStatus==='Lease'} name='inlineRadioOptions1' id='inlineRadio1' value={'Lease'} onChange={(event)=>setStatus(event.target.value)} />
              Lease
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <label className='form-check-label'>
              <input className='form-check-input' type='radio' checked={assetStatus==='Freehold'} name='inlineRadioOptions1' id='inlineRadio1' value={'Freehold'} onChange={(event)=>setStatus(event.target.value)} />
              Freehold
            </label>
          </div>
        </div>
        </div>
        {/* <div className="row"  style={{marginLeft:5}}><HeadBar title={string.forms.immovableProperty.outStandingLiabOnProp} subtitle={'*'}/>
        <div className="row"  style={{marginLeft:5}}>
        <div className='form-check form-check-inline'>
            <label className='form-check-label'>
              <input className='form-check-input' type='radio' checked={liabOnProp === 'Yes'} name='inlineRadioOptions3' id='inlineRadio3' value={'Yes'} onChange={(event)=>setLiabOnProp(event.target.value)} />
              Yes
            </label>
          </div>
        <div className='form-check form-check-inline'>
            <label className='form-check-label'>
              <input className='form-check-input' type='radio' checked={liabOnProp  === 'No'} name='inlineRadioOptions3' id='inlineRadio3' value={'No'} onChange={(event)=>setLiabOnProp(event.target.value)} />
              No
            </label>
          </div>
        </div>
        </div> */}
        <div className="row"  style={{marginLeft:5}}>
        <HeadBar title={'Inherited or Self-Accquired?'} subtitle={'*'}/>
        <HelperModal helperTextId={"inheritedOrSelfAccquired"} />
        <div className="row" style={{marginLeft:5}}>
          <div className='form-check form-check-inline'>
            <label className='form-check-label'>
              <input className='form-check-input' type='radio' checked={assetInherited==='Inherited'} name='inlineRadioOptions4' id='inlineRadio4' value={'Inherited'} onChange={(event)=>setInherited(event.target.value)} />
              Inherited
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <label className='form-check-label'>
              <input className='form-check-input' type='radio' checked={assetInherited==='Self-Accquired'} name='inlineRadioOptions4' id='inlineRadio4' value={'Self-Accquired'} onChange={(event)=>setInherited(event.target.value)} />
              Self-Accquired
            </label>
          </div>
        </div>
        </div>
        <div className="row"  style={{marginLeft:5}}>
        <HeadBar title={string.forms.immovableProperty.pendingLitigation} subtitle={'*'}/>
        <HelperModal helperTextId={"pendingLitigation"} />
        <div className="row"  style={{marginLeft:5}}>
        <div className='form-check form-check-inline'>
            <label className='form-check-label'>
              <input className='form-check-input' type='radio' checked={litigation === 'Yes'} name='inlineRadioOptions5' id='inlineRadio3' value={'Yes'} onChange={(event)=>setLitigation(event.target.value)} />
              Yes
            </label>
          </div>
        <div className='form-check form-check-inline'>
            <label className='form-check-label'>
              <input className='form-check-input' type='radio' checked={litigation  === 'No'} name='inlineRadioOptions5' id='inlineRadio3' value={'No'} onChange={(event)=>setLitigation(event.target.value)} />
              No
            </label>
          </div>
        </div>
        </div>
        <div className="row"  style={{marginLeft:5}}>
        <HeadBar title={string.forms.immovableProperty.jointOwnership} subtitle={'*'}/>
        <HelperModal helperTextId={'jointOwnership'} />
        <div  style={{marginLeft:5}}>
          <div className='form-check form-check-inline'>
            <label className='form-check-label'>
              <input className='form-check-input' type='radio' checked={jointOwnership === 'Yes'} name='inlineRadioOptions2' id='inlineRadio2' value={'Yes'} onChange={(event)=>setJointOwnership(event.target.value)} />
              Yes
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <label className='form-check-label'>
              <input className='form-check-input' type='radio' checked={jointOwnership  === 'No'} name='inlineRadioOptions2' id='inlineRadio2' value={'No'} onChange={(event)=>setJointOwnership(event.target.value)} />
              No
            </label>
          </div>
          </div>
        </div>
     </div>
   );
 }