import React, { useEffect, useState } from 'react';
import { getStoreUserId, getStoreUserRole } from '../../../selectors/userSelectors';
import { getStoreFinAssignList, setFinAssignData, deleteFinAssignFromList } from '../../../actions/finAssignActions';
import { getContactFullName, batchState } from '../../../utils/commonUtil';
import { connect } from 'react-redux';
import { addImmovableAsset, getStoreImmovableAssetList } from '../../../actions/immovableAssetsActions';
import { getStoreFinAssetList, addFinAsset } from '../../../actions/finAssetsActions'
import { addOtherAsset, getStoreOtherAssetList } from '../../../actions/otherAssetsActions';
import { getStoreDigitalAssetList, addDigitalAsset } from '../../../actions/digitalAssetsActions';
import { getFinAssetListById } from '../assets/finAssetService'
import { getImmovableAssetListById } from '../assets/immovableAssetService'
import { getDigitalAssetListById } from '../assets/digitalAssetService'
import { getOtherAssetListById } from '../assets/otherAssetService'
import _ from 'lodash';
import { getStoreImmPropAssignList, setImmPropAssignData, deleteImmPropAssignFromList } from '../../../actions/immovablePropAssignActions';
import { getStoreOtherAssignList, deleteOtherAssignFromList, setOtherAssignData } from '../../../actions/otherAssignActions';
import { getStoreDigitalAssignList, deleteDigitalAssignFromList, setDigitalAssignData } from '../../../actions/digitalAssignActions';
import { getUserDataFromServer } from '../personal/personalService'
import { SavedFD, FixedDepositForm } from '../assets/forms/FixedDeposit'
import { SavedPhyShares, PhysSharesForm } from '../assets/forms/PhysicalShares'
import { SavedDemat, DematForm } from '../assets/forms/Demat'
import { SavedGovSchemes, GovtSchemeForm } from '../assets/forms/GovtScheme'
import { SavedPortfolio, PortfolioForm } from '../assets/forms/Portfolio'
import { SavedBankAcounts, BankAccountForm } from '../assets/forms/BankAccount'
import { SavedInsurance, InsuranceForm } from '../assets/forms/Insurance'
import { SavedMutualFunds, MFform } from '../assets/forms/MutualFund'
import { SavedBonds, BondForm } from '../assets/forms/Bond'
import { SavedPF, ProvForm } from '../assets/forms/PF'
import { SavedGratuity, GratuityForm } from '../assets/forms/Gratuity'
import { SavedArtefacts, ArtefactsForm } from '../assets/forms/Artefacts'
import { SavedJewellery, JewelleryForm } from '../assets/forms/Jewellery'
import { SavedArts, ArtsForm } from '../assets/forms/Art'
import { SavedLockers, LockerForm } from '../assets/forms/Locker'
import { SavedVehicles, Vehicleform } from '../assets/forms/Vehicle'
import { SavedPersonalEffects, PersonalEffectForm } from '../assets/forms/PersonalEffect'
import { SavedSocials, SocialForm } from '../assets/forms/Social'
import { SavedWebsites, WebForm } from '../assets/forms/Website'
import { SavedimmProps, ImmPropForm } from '../assets/forms/ImmovableProperty'
import { HelpSection, HighlightedText, List } from '../../../components/CustomElements';
import { getStoreLiabilityList } from '../../../actions/liabilitiesActions';
import { getAllDataShareWithMe } from '../../../dashboard/forms/dataShare/datashareService'
import { setDataSharedByme, getDataSharedByme } from '../../../actions/dataSharedByMeActions';
import { setDataShareWithme, getDataShareWithme } from '../../../actions/dataSharedWithmeActions';
import { getStoreContactList } from '../../../actions/contactActions';
import { getStoreFamily } from '../../../actions/familyActions';
import { setDataSharedWithNames, getDataSharedWithNamesList } from '../../../actions/dataSharedWithNamesAction'
import { setDataSharedBy, getDataSharedByList, editDataSharedBy } from '../../../actions/dataShareByAction'
import { setDataSharedByNames, getDataSharedByNamesList } from '../../../actions/dataSharedByNamesAction'

const DataShareWith = (
  {userId, 
  userRole, 
  dispatchDataShareWithme, 
  shareDataWithMe,
  dispatchAddFinAsset, 
  dispatchEditFinAsset, 
  pickList, 
  dispatchAddDigitalAsset,
  dispatchAddOtherAsset, 
  dispatchAddImmovableAsset,
  dispatchDataShareBy, 
  shareDataBy,
  shareDataByNames, 
  dispatchDataShareByNames
}) =>{
 
  let isFormHidden = true;

  let [isFormHiddenFds, formVisibilityFds] = useState(true);
  const [fdData, setFd] = useState();

  let [isFormHiddenPhyShares, formVisibilityPhyShares] = useState(true);
  const [phySharesData, setPhyShares] = useState();

  let [isFormHiddenDemat, formVisibilityDemat] = useState(true);
  const [dematData, setDemat] = useState();

  let [isFormHiddenGovtSchemes, formVisibilityGovtSchemes] = useState(true);
  const [govtSchemesData, setGovtSchemes] = useState();

  let [isFormHiddenPortfolio, formVisibilityPortfolio] = useState(true);
  const [portfolioData, setPortfolio] = useState();

  let [isFormHiddenBankAccounts, formVisibilityBankAccounts] = useState(true);
  const [bankAccountsData, setBankAccounts] = useState();

  let [isFormHiddenIns, formVisibilityIns] = useState(true);
  const [insuranceData, setInsurance] = useState();

  let [isFormHiddenMf, formVisibilityMf] = useState(true);
  const [mfData, setMf] = useState();

  let [isFormHiddenBonds, formVisibilityBonds] = useState(true);
  const [bondsData, setBonds] = useState();

  let [isFormHiddenPf, formVisibilityPf] = useState(true);
  const [pfData, setPf] = useState();

  let [isFormHiddenGratuity, formVisibilityGratuity] = useState(true);
  const [gratuityData, setGratuity] = useState();

  let [isFormHiddenArtefacts, formVisibilityArtefacts] = useState(true);
  const [artefactsData, setArtefacts] = useState();

  let [isFormHiddenJewellery, formVisibilityJewellery] = useState(true);
  const [jewelleryData, setJewellery] = useState();

  let [isFormHiddenArt, formVisibilityArt] = useState(true);
  const [artData, setArt] = useState();

  let [isFormHiddenLockers, formVisibilityLockers] = useState(true);
  const [lockersData, setLockers] = useState();

  let [isFormHiddenVehicles, formVisibilityVehicles] = useState(true);
  const [vehiclesData, setVehicles] = useState();

  let [isFormHiddenPerEffect, formVisibilityPerEffect] = useState(true);
  const [perEffectData, setPerEffect] = useState();

  let [isFormHiddenSoc, formVisibilitySoc] = useState(true);
  const [socialData, setSocial] = useState();

  let [isFormHiddenWeb, formVisibilityWeb] = useState(true);
  const [webData, setWeb] = useState();

  let [isFormHiddenProp, formVisibilityProp] = useState(true);
  const [propData, setProp] = useState();
  
  const toggleForm = (e) => {
    batchState(() => {
      switch(e){
        case 'FD':
        {
          if (!isFormHiddenFds && fdData &&  fdData.id ) {
            setFd(undefined)
          }
          formVisibilityFds(!isFormHiddenFds)
          break
        }
        case 'SHR':
        {
          if (!isFormHiddenPhyShares && phySharesData &&  phySharesData.id ) {
            setPhyShares(undefined)
          }
          formVisibilityPhyShares(!isFormHiddenPhyShares)
          break
        }
        case 'DEM':
        {
          if (!isFormHiddenDemat && dematData &&  dematData.id ) {
            setDemat(undefined)
          }
          formVisibilityDemat(!isFormHiddenDemat)
          break
        }
        case 'GOV':
        {
          if (!isFormHiddenGovtSchemes && govtSchemesData &&  govtSchemesData.id ) {
            setGovtSchemes(undefined)
          }
          formVisibilityGovtSchemes(!isFormHiddenGovtSchemes)
          break
        }
        case 'PMS':
        {
          if (!isFormHiddenPortfolio && portfolioData &&  portfolioData.id ) {
            setPortfolio(undefined)
          }
          formVisibilityPortfolio(!isFormHiddenPortfolio)
          break
        }
        case 'BNK':
        {
          if (!isFormHiddenBankAccounts && bankAccountsData &&  bankAccountsData.id ) {
            setBankAccounts(undefined)
          }
          formVisibilityBankAccounts(!isFormHiddenBankAccounts)
          break
        }
        case 'INS':
          {
            if (!isFormHiddenIns && insuranceData &&  insuranceData.id ) {
              setInsurance(undefined)
            }
            formVisibilityIns(!isFormHiddenIns)
            break
          }

          case 'MF':
          {
            if (!isFormHiddenMf && mfData &&  mfData.id ) {
              setMf(undefined)
            }
            formVisibilityMf(!isFormHiddenMf)
            break
          }
          case 'BON':
          {
            if (!isFormHiddenBonds && bondsData &&  bondsData.id ) {
              setBonds(undefined)
            }
            formVisibilityBonds(!isFormHiddenBonds)
            break
          }

          case 'PF':
          {
            if (!isFormHiddenPf && pfData &&  pfData.id ) {
              setPf(undefined)
            }
            formVisibilityPf(!isFormHiddenPf)
            break
          }
          case 'GRA':
          {
            if (!isFormHiddenGratuity && gratuityData &&  gratuityData.id ) {
              setGratuity(undefined)
            }
            formVisibilityGratuity(!isFormHiddenGratuity)
            break
          }

          case 'ARTI':
          {
            if (!isFormHiddenArtefacts && artefactsData &&  artefactsData.id ) {
              setPortfolio(undefined)
            }
            formVisibilityArtefacts(!isFormHiddenArtefacts)
            break
          }
          case 'JEWL':
          {
            if (!isFormHiddenJewellery && jewelleryData &&  jewelleryData.id ) {
              setJewellery(undefined)
            }
            formVisibilityJewellery(!isFormHiddenJewellery)
            break
          }
          case 'ART':
            {
              if (!isFormHiddenArt && artData &&  artData.id ) {
                setArt(undefined)
              }
              formVisibilityArt(!isFormHiddenArt)
              break
            }
            case 'LOCK':
            {
              if (!isFormHiddenLockers && lockersData &&  lockersData.id ) {
                setLockers(undefined)
              }
              formVisibilityLockers(!isFormHiddenLockers)
              break
            }
            case 'VEH':
            {
              if (!isFormHiddenVehicles && vehiclesData &&  vehiclesData.id ) {
                setVehicles(undefined)
              }
              formVisibilityVehicles(!isFormHiddenVehicles)
              break
            }
            case 'PERS':
            {
              if (!isFormHiddenPerEffect && perEffectData &&  perEffectData.id ) {
                setPerEffect(undefined)
              }
              formVisibilityPerEffect(!isFormHiddenPerEffect)
              break
            }
          case 'SOC':
          {
            if (!isFormHiddenSoc && socialData &&  socialData.id ) {
              setSocial(undefined)
            }
            formVisibilitySoc(!isFormHiddenSoc)
            break
          }
          case 'WEB':
          {
            if (!isFormHiddenWeb && webData &&  webData.id ) {
              setWeb(undefined)
            }
            formVisibilityWeb(!isFormHiddenWeb)
            break
          }
          case 'PROP':
          {
            if (!isFormHiddenProp && propData &&  propData.id ) {
              setProp(undefined)
            }
            formVisibilityProp(!isFormHiddenProp)
            break
          }
          default:
            break
    }
  })
}
  const onDone = (e) => {
    toggleForm(e)
  }

  const onEdit = (c) => {
    batchState(() => {
      switch(c.assetLookupCode){
          case 'FD':
          {
            setFd(c);
            if (isFormHiddenFds)
              formVisibilityFds(false)
            break
          }
          case 'SHR':
          {
            setPhyShares(c);
            if (isFormHiddenPhyShares)
              formVisibilityPhyShares(false)
            break
          }
          case 'DEM':
          {
            setDemat(c);
            if (isFormHiddenDemat)
              formVisibilityDemat(false)
            break
          }
          case 'GOV':
          {
            setGovtSchemes(c);
            if (isFormHiddenGovtSchemes)
              formVisibilityGovtSchemes(false)
            break
          }
          case 'PMS':
          {
            setPortfolio(c);
            if (isFormHiddenPortfolio)
              formVisibilityPortfolio(false)
            break
          }
          case 'BNK':
          {
            setBankAccounts(c);
            if (isFormHiddenBankAccounts)
              formVisibilityBankAccounts(false)
            break
          }
          case 'INS':
          {
            setInsurance(c);
            if (isFormHiddenIns)
              formVisibilityIns(false)
            break
          }
            case 'MF':
            {
              setMf(c);
              if (isFormHiddenMf)
                formVisibilityMf(false)
              break
            }
            case 'BON':
            {
              setBonds(c);
              if (isFormHiddenBonds)
                formVisibilityBonds(false)
              break
            }
            case 'PF':
            {
              setPf(c);
              if (isFormHiddenPf)
                formVisibilityPf(false)
              break
            }
            case 'GRA':
            {
              setGratuity(c);
              if (isFormHiddenGratuity)
                formVisibilityGratuity(false)
              break
            }
            case 'ARTI':
            {
              setArtefacts(c);
              if (isFormHiddenArtefacts)
                formVisibilityArtefacts(false)
              break
            }
            case 'JEWL':
            {
              setJewellery(c);
              if (isFormHiddenJewellery)
                formVisibilityJewellery(false)
              break
            }
            case 'ART':
            {
              setArt(c);
              if (isFormHiddenArt)
                formVisibilityArt(false)
              break
            }
              case 'LOCK':
              {
                setLockers(c);
                if (isFormHiddenLockers)
                  formVisibilityLockers(false)
                break
              }
              case 'VEH':
              {
                setVehicles(c);
                if (isFormHiddenVehicles)
                  formVisibilityVehicles(false)
                break
              }
              case 'PERS':
              {
                setPerEffect(c);
                if (isFormHiddenPerEffect)
                  formVisibilityPerEffect(false)
                break
              }
            case 'SOC':
            {
              setSocial(c);
              if (isFormHiddenSoc)
                formVisibilitySoc(false)
              break
            }
            case 'WEB':
            {
              setWeb(c);
              if (isFormHiddenWeb)
                formVisibilityWeb(false)
              break
            }
            case 'PROP':
            {
              setProp(c);
              if (isFormHiddenProp)
                formVisibilityProp(false)
              break
            }
          default:
            break
      }
    })
  }
 const assetsAndLiabilities = () =>{
  let fds =[],
  phyShares=[],
  demat=[],
  govtSchemes=[],
  portfolio=[],
  bankAccounts=[],
  insurance=[],
  mfs=[],
  bonds=[],
  pfs=[],
  gratuity=[],
  artefacts=[],
  jewellery=[],
  art=[],
  lockers=[],
  vehicles=[],
  perEffect=[],
  social=[],
  web=[],
  immov=[]
  const dataShareAssetList = Object.values(shareDataBy);
    if(  dataShareAssetList.length >0){
      fds = dataShareAssetList.filter(e=>e.assetLookupCode === 'FD');
      phyShares = dataShareAssetList.filter(e=>e.assetLookupCode === 'SHR');
      demat = dataShareAssetList.filter(e=>e.assetLookupCode === 'DEM');
      govtSchemes = dataShareAssetList.filter(e=>e.assetLookupCode === 'GOV');
      portfolio = dataShareAssetList.filter(e=>e.assetLookupCode === 'PMS');
      bankAccounts = dataShareAssetList.filter(e=>e.assetLookupCode === 'BNK');
      insurance = dataShareAssetList.filter(e=>e.assetLookupCode === 'INS');
      mfs = dataShareAssetList.filter(e=>e.assetLookupCode === 'MF');
      bonds = dataShareAssetList.filter(e=>e.assetLookupCode === 'BON');
      pfs = dataShareAssetList.filter(e=>e.assetLookupCode === 'PF');
      gratuity = dataShareAssetList.filter(e=>e.assetLookupCode === 'GRA');
      artefacts = dataShareAssetList.filter(e=>e.assetLookupCode === 'ARTI');
      jewellery = dataShareAssetList.filter(e=>e.assetLookupCode === 'JEWL');
      art = dataShareAssetList.filter(e=>e.assetLookupCode === 'ART');
      lockers = dataShareAssetList.filter(e=>e.assetLookupCode === 'LOCK');
      vehicles = dataShareAssetList.filter(e=>e.assetLookupCode === 'VEH');
      perEffect = dataShareAssetList.filter(e=>e.assetLookupCode === 'PERS');
      social = dataShareAssetList.filter(e=>e.assetLookupCode === 'SOC');
      web = dataShareAssetList.filter(e=>e.assetLookupCode === 'WEB');
      immov = dataShareAssetList.filter(e=>e.assetLookupCode === 'PROP');
}

let fdsAccessLevel = {},
  phySharesAccessLevel={},
  dematAccessLevel={},
  govtSchemesAccessLevel={},
  portfolioAccessLevel={},
  bankAccountsAccessLevel={},
  insuranceAccessLevel={},
  mfsAccessLevel={},
  bondsAccessLevel={},
  pfsAccessLevel={},
  gratuityAccessLevel={},
  artefactsAccessLevel={},
  jewelleryAccessLevel={},
  artAccessLevel={},
  lockersAccessLevel={},
  vehiclesAccessLevel={},
  perEffectAccessLevel={},
  socialAccessLevel={},
  webAccessLevel={},
  immovAccessLevel={}
  const dataShareList = Object.values(shareDataWithMe);
    if(  dataShareList.length >0){
      dataShareList.filter(e=>e.assetLookupCode === 'FD').map(f=>fdsAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'SHR').map(f=>phySharesAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'DEM').map(f=>dematAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'GOV').map(f=>govtSchemesAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'PMS').map(f=>portfolioAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'BNK').map(f=>bankAccountsAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'INS').map(f=>insuranceAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'MF').map(f=>mfsAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'BON').map(f=>bondsAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'PF').map(f=>pfsAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'GRA').map(f=>gratuityAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'ARTI').map(f=>artefactsAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'JEWL').map(f=>jewelleryAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'ART').map(f=>artAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'LOCK').map(f=>lockersAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'VEH').map(f=>vehiclesAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'PERS').map(f=>perEffectAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'SOC').map(f=>socialAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'WEB').map(f=>webAccessLevel[f.assetId]=f.accessLevel);
      dataShareList.filter(e=>e.assetLookupCode === 'PROP').map(f=>immovAccessLevel[f.assetId]=f.accessLevel);
}
  return(
    <div>
      {dataShareAssetList.length===0 ? <div><p style={{textAlign:'center',paddingTop:'5%'}}>You Don't have any shared Assets information yet</p></div> :
      <div>
        <HelpSection heading="HELP TIPS !">
        <List>
          You are a deputy for these records. It is a great responsibility.
          <HighlightedText></HighlightedText>
        </List>
        <List>
          <HighlightedText>Depending on access levels you may view or edit these records.</HighlightedText>
        </List>
        </HelpSection>
      <div id='htmlpdf'>
      <SavedFD data={fds} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={fdsAccessLevel} />
      <SavedPhyShares data={phyShares} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={phySharesAccessLevel} />
      <SavedDemat data={demat} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={dematAccessLevel} />
      <SavedGovSchemes data={govtSchemes} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={govtSchemesAccessLevel} />
      <SavedPortfolio data={portfolio} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={portfolioAccessLevel} />
      <SavedBankAcounts data={bankAccounts} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={bankAccountsAccessLevel} />
      <SavedInsurance data={insurance} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={insuranceAccessLevel} />
      <SavedMutualFunds data={mfs} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={mfsAccessLevel} />
      <SavedBonds data={bonds} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={bondsAccessLevel} />
      <SavedPF data={pfs} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={pfsAccessLevel} />
      <SavedGratuity data={gratuity} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={gratuityAccessLevel} />
      <SavedArtefacts data={artefacts} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={artefactsAccessLevel} />
      <SavedJewellery data={jewellery} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={jewelleryAccessLevel} />
      <SavedArts data={art} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={artAccessLevel} />
      <SavedLockers data={lockers} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={lockersAccessLevel} />
      <SavedVehicles data={vehicles} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={vehiclesAccessLevel} />
      <SavedPersonalEffects data={perEffect} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={perEffectAccessLevel} />
      <SavedSocials data={social} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={socialAccessLevel} />
      <SavedWebsites data={web} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames} accessLevelArray={webAccessLevel} />
      <SavedimmProps data={immov} isFormHidden={isFormHidden} datashare={'shareby'} onEdit={onEdit} dataShareByMe={shareDataByNames}accessLevelArray={immovAccessLevel} />
      </div>
      {isFormHiddenFds ? null : <FixedDepositForm fd={fdData} userId={_.isEmpty(fdData) ? userId : fdData.userId} onCancel={()=>toggleForm("FD")} onDone={()=>onDone("FD")} fdFinAssetList={fds} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList } userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenPhyShares ? null : <PhysSharesForm phyShares={phySharesData} userId={_.isEmpty(phySharesData) ? userId : phySharesData.userId} onCancel={()=>toggleForm('SHR')} onDone={()=>onDone('SHR')} phySharesFinAssetList={phyShares} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} displayNominee={'no'}/>}
      {isFormHiddenDemat ? null : <DematForm demat={dematData} userId={_.isEmpty(dematData) ? userId : dematData.userId} onCancel={()=>toggleForm("DEM")} onDone={()=>onDone("DEM")} dematFinAssetList={demat} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenGovtSchemes ? null : <GovtSchemeForm govScheme={govtSchemesData} userId={_.isEmpty(govtSchemesData) ? userId : govtSchemesData.userId} onCancel={()=>toggleForm('GOV')} onDone={()=>onDone('GOV')} govSchemeFinAssetList={govtSchemes} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenPortfolio ? null : <PortfolioForm portfolio={portfolioData} userId={_.isEmpty(portfolioData) ? userId : portfolioData.userId} onCancel={()=>toggleForm("PMS")} onDone={()=>onDone("PMS")} portfolioFinAssetList={portfolio} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenBankAccounts ? null : <BankAccountForm bank={bankAccountsData} userId={_.isEmpty(bankAccountsData) ? userId : bankAccountsData.userId}  onCancel={()=>toggleForm('BNK')} onDone={()=>onDone('BNK')} bankFinAssetList={bankAccounts} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenIns ? null : <InsuranceForm insurance={insuranceData} userId={_.isEmpty(insuranceData) ? userId : insuranceData.userId} onCancel={()=>toggleForm("INS")} onDone={()=>onDone("INS")} insuranceFinAssetList={insurance} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenMf ? null : <MFform mf={mfData} userId={_.isEmpty(mfData) ? userId : mfData.userId} onCancel={()=>toggleForm("MF")} onDone={()=>onDone("MF")} mfFinAssetList={mfs} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenBonds ? null : <BondForm bond={bondsData} userId={_.isEmpty(bondsData) ? userId : bondsData.userId} onCancel={()=>toggleForm('BON')} onDone={()=>onDone('BON')} bondFinAssetList={bonds} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenPf ? null : <ProvForm pf={pfData} userId={_.isEmpty(pfData) ? userId : pfData.userId} onCancel={()=>toggleForm("PF")} onDone={()=>onDone("PF")} pfFinAssetList={pfs} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenGratuity ? null : <GratuityForm gratuity={gratuityData} userId={_.isEmpty(gratuityData) ? userId : gratuityData.userId} onCancel={()=>toggleForm('GRA')} onDone={()=>onDone('GRA')} gratuityFinAssetList={gratuity} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenArtefacts ? null : <ArtefactsForm artefact={artefactsData} userId={_.isEmpty(artefactsData) ? userId : artefactsData.userId} onCancel={()=>toggleForm("ARTI")} onDone={()=>onDone("ARTI")} artefactOtherAssetList={artefacts} dispatchAddOtherAsset={dispatchAddOtherAsset} dispatchEditOtherAsset={dispatchEditFinAsset} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenJewellery ? null : <JewelleryForm jewellery={jewelleryData} userId={_.isEmpty(jewelleryData) ? userId : jewelleryData.userId} onCancel={()=>toggleForm('JEWL')} onDone={()=>onDone('JEWL')} jewelleryOtherAssetList={jewellery} dispatchAddOtherAsset={dispatchAddOtherAsset} dispatchEditOtherAsset={dispatchEditFinAsset} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenArt ? null : <ArtsForm art={artData} userId={_.isEmpty(artData) ? userId : artData.userId} onCancel={()=>toggleForm("ART")} onDone={()=>onDone("ART")} artOtherAssetList={art} dispatchAddOtherAsset={dispatchAddOtherAsset} dispatchEditOtherAsset={dispatchEditFinAsset} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenLockers ? null : <LockerForm locker={lockersData} userId={_.isEmpty(lockersData) ? userId : lockersData.userId} onCancel={()=>toggleForm('LOCK')} onDone={()=>onDone('LOCK')} lockerOtherAssetList={lockers} dispatchAddOtherAsset={dispatchAddOtherAsset} dispatchEditOtherAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenVehicles ? null : <Vehicleform vehicle={vehiclesData} userId={_.isEmpty(vehiclesData) ? userId : vehiclesData.userId} onCancel={()=>toggleForm("VEH")} onDone={()=>onDone("VEH")} vehicleOtherAssetList={vehicles} dispatchAddOtherAsset={dispatchAddOtherAsset} dispatchEditOtherAsset={dispatchEditFinAsset} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenPerEffect ? null : <PersonalEffectForm perEffect={perEffectData} userId={_.isEmpty(perEffectData) ? userId : perEffectData.userId} onCancel={()=>toggleForm('PERS')} onDone={()=>onDone('PERS')} perEffectOtherAssetList={perEffect} dispatchAddOtherAsset={dispatchAddOtherAsset} dispatchEditOtherAsset={dispatchEditFinAsset} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenSoc ? null : <SocialForm social={socialData} userId={_.isEmpty(socialData) ? userId : socialData.userId} onCancel={()=>toggleForm('SOC')} onDone={()=>onDone('SOC')} socialDigitalAssetList={social} dispatchAddDigitalAsset={dispatchAddDigitalAsset} dispatchEditDigitalAsset={dispatchEditFinAsset} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenWeb ? null : <WebForm web={webData} userId={_.isEmpty(webData) ? userId : webData.userId} onCancel={()=>toggleForm('WEB')} onDone={()=>onDone('WEB')} webDigitalAssetList={web} dispatchAddDigitalAsset={dispatchAddDigitalAsset} dispatchEditDigitalAsset={dispatchEditFinAsset} userRole={userRole} displayNominee={'no'} />}
      {isFormHiddenProp ? null : <ImmPropForm immProp={propData} userId={_.isEmpty(propData) ? userId : propData.userId} onCancel={()=>toggleForm('PROP')} onDone={()=>onDone('PROP')} immPropImmovableAssetList={immov} dispatchAddImmovableAsset={dispatchAddImmovableAsset} dispatchEditImmovableAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} displayNominee={'no'} />}
      </div>
  }
    </div>
  )
  }
  const receiverFlag= false
  useEffect(()=>{
    getAllDataShareWithMe(userId, receiverFlag)
    .then(resp=>{
      console.log(resp.data)
      dispatchDataShareWithme(resp.data)
      const allAssets=[]
      resp.data.map((d)=> {
              if(d.assetLookupCode === 'FD' || 
              d.assetLookupCode === 'SHR' || 
              d.assetLookupCode === 'DEM' || 
              d.assetLookupCode === 'GOV' || 
              d.assetLookupCode === 'PMS' ||
              d.assetLookupCode === 'BNK' || 
              d.assetLookupCode === 'INS' ||
              d.assetLookupCode === 'MF' || 
              d.assetLookupCode === 'BON' || 
              d.assetLookupCode === 'PF' || 
              d.assetLookupCode === 'GRA')
              (
              getFinAssetListById(d.assetId)
              .then(res=> {
                  allAssets.push(res.data)
                  dispatchDataShareBy(allAssets)
                })
              .catch((err) => alert(err))
              )
              if(d.assetLookupCode ===  'PROP')
              (
              getImmovableAssetListById(d.assetId)
              .then(res=> {
                allAssets.push(res.data)
                dispatchDataShareBy(allAssets)
              })
              .catch((err) => alert(err))
              )
              if(d.assetLookupCode === 'SOC' || d.assetLookupCode === 'WEB')
              (
              getDigitalAssetListById(d.assetId)
              .then(res=> {
                allAssets.push(res.data)
                dispatchDataShareBy(allAssets)
              })
              .catch((err) => alert(err))
              )
              if(d.assetLookupCode === 'ARTI' ||
              d.assetLookupCode === 'JEWL' || 
              d.assetLookupCode === 'ART' || 
              d.assetLookupCode === 'LOCK' || 
              d.assetLookupCode === 'VEH' || 
              d.assetLookupCode === 'PERS')
              (
              getOtherAssetListById(d.assetId)
              .then(res=> {
                allAssets.push(res.data)
                dispatchDataShareBy(allAssets)
              })
              .catch((err) => alert(err))
              )
              })

              const assetOwnerName ={}
              const emptyData = []
              resp.data.length === 0 ? emptyData.push(null) : resp.data.map(e=>
                {
                getUserDataFromServer(e.sharedByUserId)
                .then(res=>{
                  let fullName=getContactFullName(res.data)
                  assetOwnerName[e.assetId]=fullName
                  dispatchDataShareByNames(assetOwnerName)
                })
                .catch(err=>console.log("error",err))
            })

    })
    .catch(err=>{
      console.log(err)
    })
  },[])

  return (
        <div>
               {assetsAndLiabilities()}
         </div> 
);
}


const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
  finAssignList : getStoreFinAssignList(state),
  immPropAssignList: getStoreImmPropAssignList(state),
  immPropAssetList: getStoreImmovableAssetList(state),
  otherAssignList: getStoreOtherAssignList(state),
  digitalAssignList: getStoreDigitalAssignList(state),
  finAssetList:getStoreFinAssetList(state),
  otherAssetList: getStoreOtherAssetList(state),
  digitalAssetList: getStoreDigitalAssetList(state),
  userRole:getStoreUserRole(state),
  liabilityList: getStoreLiabilityList(state),

  pickList: (getStoreContactList(state).concat(getStoreFamily(state))),

  shareDataWithMe:getDataShareWithme(state),
  shareDataByMe:getDataSharedByme(state),
  shareDataWithNames : getDataSharedWithNamesList(state),
  shareDataBy : getDataSharedByList(state),
  shareDataByNames : getDataSharedByNamesList(state),

});
const mapDispatchToProps = () => dispatch => ({
  dispatchSetFinAssignList: finAssign=> dispatch(setFinAssignData(finAssign)),
  dispatchSetImmPropAssignList: immPropAssign=> dispatch(setImmPropAssignData(immPropAssign)),
  dispatchSetOtherAssignList: otherAssign=> dispatch(setOtherAssignData(otherAssign)),
  dispatchSetDigitalAssignList: digitalAssign=> dispatch(setDigitalAssignData(digitalAssign)),
  dispatchDeleteFinAssignList: finAssign=> dispatch(deleteFinAssignFromList(finAssign)),
  dispatchDeleteImmPropAssignList: immPropAssign=> dispatch(deleteImmPropAssignFromList(immPropAssign)),
  dispatchDeleteOtherAssign: otherAssign=> dispatch(deleteOtherAssignFromList(otherAssign)),
  dispatchDeleteDigitalAssign: digitalAssign=> dispatch(deleteDigitalAssignFromList(digitalAssign)),

  dispatchAddFinAsset: finAsset => dispatch(addFinAsset(finAsset)),
  dispatchEditFinAsset:dataShareBy=>dispatch(editDataSharedBy(dataShareBy)),

  dispatchAddOtherAsset: finAsset => dispatch(addOtherAsset(finAsset)),

  dispatchAddDigitalAsset: digitalAsset => dispatch(addDigitalAsset(digitalAsset)),

  dispatchDataShareWithme: dataShareWithme=> dispatch(setDataShareWithme(dataShareWithme)),
  dispatchDataSharedByme: dataSharedByme=> dispatch(setDataSharedByme(dataSharedByme)),
  dispatchDataShareWithNames: dataSharedWithNames=> dispatch(setDataSharedWithNames(dataSharedWithNames)),
  dispatchDataShareBy: dataSharedBy=> dispatch(setDataSharedBy(dataSharedBy)),
  dispatchDataShareByNames : dataSharedByNames=>dispatch(setDataSharedByNames(dataSharedByNames)),

  dispatchAddImmovableAsset: immProp => dispatch(addImmovableAsset(immProp)),
  })
 export default connect(mapStateToProps, mapDispatchToProps)(DataShareWith);

