import React, { useState } from 'react';
import CollapseSection from "../../../../components/CollapsibleSection";
import { useInput } from "../../../../hooks/useInput";
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import TextArea from "../../../../components/TextArea";
import { forwardRef } from 'react';
import string from '../../../../constant/en.json';
import { batchState, getId } from '../../../../utils/commonUtil';
import { ContainerPosition, CustomButtom, ActionItem, CenterAlignRow, AlignColumn, TableContainer } from '../../../../components/CustomElements';
import { updateOtherAssetById, createOtherAsset, deleteOtherAsset } from '../otherAssetService';
import { connect } from 'react-redux';
import { deleteOtherAssetFromList, editOtherAsset, addOtherAsset, getStoreOtherAssetList } from '../../../../actions/otherAssetsActions';
import { getStoreUserId, getStoreUserRole } from '../../../../selectors/userSelectors';
import _ from 'lodash';
import { useQuaishToasts } from '../../../../actions/alertAction';
import ModalContainer from '../../../../home/component/layout/Modal'
import DataShareModal from '../../../../dashboard/forms/dataShare/DataShareModal'

const Artefacts = ({userId, artefactOtherAssetList, dispatchAddOtherAsset, dispatchEditOtherAsset, dispatchDeleteOtherAsset, userRole}, ref) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [artefact, setArtefact] = useState();
  let [visibleDataShare, setVisibleDataShare] = useState(false)
  let toggleDataShare = () => setVisibleDataShare(!visibleDataShare)
  const [dataShareId, setDataShareId] = useState('')

  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && artefact &&  artefact.id ) {
        setArtefact(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setArtefact(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
      setArtefact(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const onShare = (c) => {
    setDataShareId(c)
    toggleDataShare()
  }
       
  return (
    <div>
        <SavedArtefacts data={artefactOtherAssetList} onEdit={onEdit} onShare={onShare} isFormHidden={isFormHidden} dispatchDeleteOtherAsset={dispatchDeleteOtherAsset} />
        <div  style={{ marginBottom: 20, display: 'block' }}>
          <ContainerPosition>
            <CustomButtom event={() => toggleForm()} title={'Add Artefacts'} />
          </ContainerPosition>
        </div>
        {isFormHidden ? null : <ArtefactsForm artefact={artefact} userId={userId} onCancel={toggleForm} onDone={onDone} artefactOtherAssetList={artefactOtherAssetList} dispatchAddOtherAsset={dispatchAddOtherAsset} dispatchEditOtherAsset={dispatchEditOtherAsset} userRole={userRole}/>}
        {visibleDataShare
                  ? 
                  <ModalContainer visible={visibleDataShare} toggle={toggleDataShare}>
                    <DataShareModal visibleDataShare={visibleDataShare} toggleDataShare={toggleDataShare} dataShareId={dataShareId} AssetList={artefactOtherAssetList}  assetLookupKey={"asset_other"} assetLookupCode={'ARTI'}/>
                  </ModalContainer> 
                  : null 
        }
    </div>
  );
}

const ArtefactsFrm = ({artefact,userId, onCancel, onDone, artefactOtherAssetList, dispatchAddOtherAsset, dispatchEditOtherAsset, userRole}, ref) => {
    const { value: assetIssuerName, bind: bindName } = useInput(!artefact ? '' : artefact.assetIssuerName);
  const { value: assetDescription, bind: bindDesc } = useInput(!artefact ? '' : artefact.assetDescription);
  const { success, error, removeAll } = useQuaishToasts()
  /*const validate=(payload)=>{
    const ev=[]
    for (var key in payload) {
      switch(key){
          case 'assetIssuerName':
            _.isEmpty(payload[key])  ? ev.push(string.forms.artefacts.emptyName):null
            break
          case 'assetDescription':
            _.isEmpty(payload[key])  ? ev.push(string.forms.artefacts.emptyDescription):payload[key].length > 255 ? ev.push(string.forms.commonFormValidations.descriptionLenght) :null
            break
          default:
            break
          } 
        }
          if(ev.length>0){
            warn(ev)
            return false
          }
          else return true
        }*/
  const submit = () => {
    removeAll()
    const roleOfUser= userRole==='GIVER' ? false : true
    const payload = {
      userId,
      assetLookupCode: 'ARTI',
      receiverFlag:	roleOfUser,
      assetIssuerName,
      assetDescription,
      // contacts: refPickNominee.current.returnDetails()
    }
    //if(validate(payload))

    getId(artefact) ? updateOtherAssetById(artefact.id,{id:artefact.id ,...payload})
    .then(response => {
      success('Record Updated Successfully');
        dispatchEditOtherAsset(response.data)
        onDone()
    }).catch(err => {
        error(err)
    }) : 
    createOtherAsset(payload)
    .then(response => {
      success('Record Created Successfully');
      dispatchAddOtherAsset(response.data);
      onDone()
        // dispatchSetAllContactList(response.data);
    })
    .catch(err => {
      error(err)
    })
}
    return <CollapseSection  mainTitle={'Enter ' + string.forms.artefacts.artefactsFormTitle}>
      <section className='section_form'>
        <div>
          <HeadBar title={string.forms.commonFormFields.name}/>
        </div>
        <div className='row'>
          <Input type='text' placeholder={string.forms.artefacts.name} isBigger bind={bindName} />
        </div>
        <div>
          <HeadBar title={string.forms.commonFormFields.description} subtitle={''} />
        </div>
        <div className='row'>
          <TextArea placeholder={string.forms.artefacts.description} bind={bindDesc} />
        </div>
      </section>
      <ContainerPosition>
            <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
            <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
        </ContainerPosition>
    </CollapseSection>
  }
export const ArtefactsForm = forwardRef(ArtefactsFrm);

export function  SavedArtefacts ({ data, dispatchDeleteOtherAsset, onEdit, dispalyActions, onShare, datashare, dataShareByMe, accessLevelArray, toggle }) {
  const { success, error } = useQuaishToasts()
  const deleteArti = (id) =>{
    if(window.confirm("Delete?\n Are you sure you want to delete this record?")){
        deleteOtherAsset(id)
        .then(response => {
          success('Record Deleted Successfully');
          dispatchDeleteOtherAsset(id);

        })
        .catch(err => {
          error(err)
        })
    }
  }

  const row = data.map((e) => {
    return(
      
    <TableContainer>
      <CenterAlignRow>
        <AlignColumn label="Artefact Name" text={e.assetIssuerName} />
        <AlignColumn label="Description" text={e.assetDescription} />
        {datashare === 'yes' ? <AlignColumn label="Shared with" text={_.isEmpty(dataShareByMe[e.id][0]) ? null : dataShareByMe[e.id][0].map((f,index)=>(<span style={{cursor:'pointer', color: '#007bff'}} onClick={()=>toggle(dataShareByMe[e.id][1][index])}><span style={{textDecoration:'underline'}}>{f}</span><span>{index !== (dataShareByMe[e.id][0].length) -1 ? ' , ' : ''}</span></span>))} />: null}
        {datashare === 'shareby' ? <AlignColumn label="Shared By" text={dataShareByMe[e.id]} />: null}
        {dispalyActions === 'no' || datashare === 'yes' || (datashare === 'shareby' && accessLevelArray[e.id]==='READ')  ? null :
        <AlignColumn label="Action" text={<ActionItem onEdit={()=>onEdit(e)} onDelete={()=>deleteArti(e.id)} onShare={() => onShare(e.id)} datashare={datashare}/>} />}
      </CenterAlignRow>
    </TableContainer>
  )});
  return (
    data.length === 0 ? null :
    <div>
    <CollapseSection isHidden={data.length === 0} mainTitle={'Artefacts'} collapsed={false} onShare={() => onShare('multicreate')} isCollapsable={true} customStyle={true}  datashareuser={datashare}  datashare={'yes'}>
      {row}
    </CollapseSection>
          {dispalyActions === 'no' || datashare === 'yes' || datashare === 'shareby' ? <br/> : null }
     </div>
  );
};

 const mapStateToProps = (state) => ({
  artefactOtherAssetList: getStoreOtherAssetList(state).filter(fa=> fa.assetLookupCode === 'ARTI'),
  userId: getStoreUserId(state),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchAddOtherAsset: finAsset => dispatch(addOtherAsset(finAsset)),
  dispatchEditOtherAsset:finAsset=>dispatch(editOtherAsset(finAsset)),
  dispatchDeleteOtherAsset:finAsset=>dispatch(deleteOtherAssetFromList(finAsset)),
})
 export default connect(mapStateToProps, mapDispatchToProps)(Artefacts);
