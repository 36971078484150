import React, {useEffect} from 'react';
import Footer from './component/layout//Footer';
import Header from './component/layout/Header';
import Security from '../dashboard/components/Security';
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
import { TRAKING_ID } from '../constant/ServerConst'

ReactGA.initialize(TRAKING_ID);
mixpanel.init("60ba11d6c224405b0b1a24bacb55bd8b");

const Home = () => {
  useEffect(() => {ReactGA.pageview('Security Page')},[])
  useEffect(() => {mixpanel.track("Security Page")},[])
    return (
        <div>
        <Header />
        <Security/>
        <Footer />
      </div>
    );
}
export default Home;