import { Get, Patch, Post, Delete } from "../../../utils/ApiUtil";
import Axios from 'axios'
import { FLASK_SERVER } from "../../../constant/ServerConst"

const baseuri = FLASK_SERVER
export const createDataShare = (data) => Post('/userdatashare/create?', data);

export const multiCreateDataShare = (data) => Post('/userdatashare/multicreate?', data);

export const getAllDataShareWithMe = (id, receiverFlag) => Get('userdatashare/getalldatasharedwithme?userId='+ id +'&receiverFlag='+receiverFlag);

export const getAllDataShareByMe = (id, receiverFlag) => Get('userdatashare/getalldatasharedbyme?userId='+ id +'&receiverFlag='+receiverFlag);

export const deleteDataShareById = (id) => Delete('userdatashare/softdelete/'+id);

export const updateDataShareById = (id, payload) => Patch('userdatashare/update/'+id+'?',payload);

export const createInviteEmail = (emailId, fromName, toName) => Axios.post(baseuri+'inviteEmail', { }, {
    headers: {
        "email" : emailId,
        "fromName" : fromName,
        "toName" : toName
       }
  })

export const createInviteNewUserEmail = (emailId, fromName, toName) => Axios.post(baseuri+'inviteNewUserEmail', { }, {
    headers: {
        "email" : emailId,
        "fromName" : fromName,
        "toName" : toName
       }
  })