import React, { useImperativeHandle, forwardRef } from 'react';
import { useInput } from '../../hooks/useInput';
import HeadBar from '../../components/HeaderBar';
import DropDown from '../../components/DropDown';
import string from '../../constant/en.json';
import HelperModal from '../helper/HelperModal';

const DomicileForm = ({ defaultValue }, ref) => {
  const { value: domicile, bind: bindDomicile } = useInput(defaultValue);
  useImperativeHandle(ref, () => ({
    returnDetails() { return domicile }
  }));
  return <section className='section_form'>
    <div className="row" style={{marginLeft:'2px'}}>
      <HeadBar title={string.forms.commonFormFields.domicile} />
      <HelperModal helperTextId={'domicile'} />
    </div>
    <div className='row'>
      <DropDown placeholder={string.forms.commonFormFields.pleaseSelect} options={[{ code: 'Indian', value: 'Indian' }, { code: 'Other', value: 'Other' }]} bind={bindDomicile} />
    </div>
  </section>
};
const Domicile = forwardRef(DomicileForm);
export default Domicile;