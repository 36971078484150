export const ADD_OTHER_ASSET_LIST = 'ADD_OTHER_ASSET_LIST';
export const CREATE_OTHER_ASSET = 'CREATE_OTHER_ASSET';
export const EDIT_OTHER_ASSET = 'EDIT_OTHER_ASSET';
export const DELETE_OTHER_ASSET = 'DELETE_OTHER_ASSET';
export const setOtherAssetData = (otherAssets) => {

    return {
        type: ADD_OTHER_ASSET_LIST,
        otherAssets
    }
}
export const deleteOtherAssetFromList = (otherAssetId) => {

    return {
        type: DELETE_OTHER_ASSET,
        otherAssetId
    }
}

export const addOtherAsset = otherAsset => ({ type: CREATE_OTHER_ASSET, otherAsset });
export const editOtherAsset = otherAsset => ({ type: EDIT_OTHER_ASSET, otherAsset });
export const getStoreOtherAssetList = state => state.otherAssets;
