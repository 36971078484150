import { getSplitName } from "../../utils/commonUtil";
import { createContact } from "../../dashboard/forms/contacts/ContactService";
import { createFinAsset } from "../../dashboard/forms/assets/finAssetService";
import { createDigitalAsset } from "../../dashboard/forms/assets/digitalAssetService";
import { mailJustInCaseList } from '../../dashboard/helper/MailingServices';
import { createFinAssetDoc } from "../../dashboard/forms/assets/finAssetService";

export const createCaregiverAndEmergencyContact =(name, relationship, userId) =>{;
    let payload = {
        userId,
        contactLookupCode: 'INDV',
        ...getSplitName(name),
        relationship:relationship === ''? null : relationship
    }
    createContact(payload)
    .then(response => {
    console.log('success');
    })
    .catch(error => {
    console.log(error)
    })   
}

export const createNeighbourContact = (name, mobilePhone, userId) =>{
    
    let payload = {
        userId,
        contactLookupCode: 'INDV',
        ...getSplitName(name),
        relationship:'FRND',
        mobilePhone
    }
    createContact(payload)
    .then(response => {
    console.log('success');
    })
    .catch(error => {
    console.log(error)
    })   
}

export const createInsuranceAsset = (assetIssuerName,assetIdentificationNumber, userId) =>{
    let payload = {
        userId,
        assetLookupCode: 'INS',
        assetIssuerName,
        assetIdentificationNumber
    }
    createFinAsset(payload)
    .then(resp=>{
        console.log(resp)
        console.log('asset insurance success')
    })
    .catch(error=>{
        console.log(error)
    })
}
export const createBankAccAsset = (assetIssuerName, assetIdentificationNumber,userId) =>{
    let payload = {
        userId,
        assetLookupCode: 'BNK',
        assetIssuerName,
        assetIdentificationNumber
    }
    createFinAsset(payload)
    .then(resp=>{
        console.log(resp)
        console.log('asset bank success')
    })
    .catch(error=>{
        console.log(error)
    })
}
export const createPasswordManager = (assetDetail, assetDescription,userId) =>{
    let payload = {
        userId,
        assetLookupCode: 'SOC',
        assetDescription,
        assetDetail,
    }
    createDigitalAsset(payload)
    .then(resp=>{
        console.log(resp)
        console.log('asset bank success')
    })
    .catch(error=>{
        console.log(error)
    })
}

export const mailList = (payload) =>{

    mailJustInCaseList(payload)
    .then(resp=>{
        console.log(resp)
        console.log('Mail sent')
    })
    .catch(error=>{
        console.log(error)
    })
}
