import React from 'react'
import { connect } from 'react-redux'
import { getPaymentData } from '../../../../src/actions/paymentAction'

const tableStyle={
    borderRight:'1px solid white',
    paddingLeft:'1%',
}
const tablevalue={
    paddingLeft:'1%'
}
function PaymentReceipt({onClose, paymentData}) {
	return(
        <div style={{padding:'3%'}}>
            <button type='button' className='close' onClick={onClose}>
                        &times;
            </button>
            <div style={{paddingBottom:'5%',paddingTop:'3%'}}>
                <img style={{ width: 80, height: 80,float:'left'}} src={require('../../../assets/img/Logo_FB.jpg')} alt='Logo' />
                <h4 style={{textAlign:'center',paddingTop:'5%'}}>Thank you for your payment.</h4>
            </div>
            <hr style={{background:' #ecfbf7 '}}></hr>
            <div>
                <p>The payment for your EasyInherit Plan has been successfully processed.</p>
                <p>Please find the transaction details below</p>
            </div>
            <div >
                <table style={{width:'95%'}}>
                    <tr style={{background:'#528FF0',color:'#fff'}}>
                    <td style={tableStyle}>Payment ID</td>
                    <td style={tablevalue}>{paymentData.razorpayPaymentId}</td>
                    </tr>
                    <tr style={{background:'#d8d8ff'}}>
                    <td style={tableStyle}>EasyInherit Order Number</td>
                    <td style={tablevalue}>{paymentData.razorpayOrderId}</td>
                    </tr>
                    <tr style={{background:'#ebebff'}}>
                    <td style={tableStyle}>Order Description</td>
                    <td style={tablevalue}>{paymentData.productDesc}</td>
                    </tr>
                    <tr style={{background:'#d8d8ff'}}>
                    <td style={tableStyle}>Payment Date and Time</td>
                    <td style={tablevalue}>{paymentData.creationDate}</td>
                    </tr>
                    <tr style={{background:'#ebebff'}}>
                    <td style={tableStyle}>Amount</td>
                    <td style={tablevalue}>{paymentData.amount}</td>
                    </tr>
                </table>
            </div><br/>
            <div style={{textAlign:'center'}}>
                <p>For any questions or queries, please write to info@easyinherit.in with above reference </p>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    paymentData: getPaymentData(state)
  })

export default connect(mapStateToProps)(PaymentReceipt)
