export const ADD_FIN_ASSET_LIST = 'ADD_FIN_ASSET_LIST';
export const CREATE_FIN_ASSET = 'CREATE_FIN_ASSET';
export const EDIT_FIN_ASSET = 'EDIT_FIN_ASSET';
export const DELETE_FIN_ASSET = 'DELETE_FIN_ASSET';
export const setFinAssetData = (finAssets) => {

    return {
        type: ADD_FIN_ASSET_LIST,
        finAssets
    }
}
export const deleteFinAssetFromList = (finAssetId) => {

    return {
        type: DELETE_FIN_ASSET,
        finAssetId
    }
}

export const addFinAsset = finAsset => ({ type: CREATE_FIN_ASSET, finAsset });
export const editFinAsset = finAsset => ({ type: EDIT_FIN_ASSET, finAsset });
export const getStoreFinAssetList = state => state.finAssets;
