import Axios from 'axios'
import { FLASK_SERVER } from "../../../constant/ServerConst";

const baseUri = FLASK_SERVER

export const getUserDocumentById = (userId) => Axios.post(baseUri+'getDigilockerUser', { }, {
  headers: {
    'user_id': userId
  }
})

export const createUserDocument = (userId, accessToken, refreshToken, now, validTill, accessTokenTimestamp) => Axios.post(baseUri+'createDigilocker',{ }, {
    headers: {
        "user_id" : userId,
        "access_token" : accessToken,
        "refresh_token" : refreshToken,
        "last_modification_date" : now,
        "valid_till" : validTill,
        "refresh_token_timestamp" : accessTokenTimestamp
        },
})

export const updateUserDocumentById = (userId, now, accessToken, refreshToken, validTill, accessTokenTimestamp) => Axios.post(baseUri+'updateDigilocker', { }, {
headers: {
    'last_modification_date' : now,
    'user_id' : userId,
    'access_token' : accessToken,
    'refresh_token' : refreshToken,
    "valid_till" : validTill,
    "refresh_token_timestamp" : accessTokenTimestamp
  },
})

export const getAccessToken = (authCode) => Axios.post(baseUri+'getDigilockerAccessToken',{ } , {
    headers: {
          "authCode" : authCode
        },
}) 

export const getRefreshToken = (refreshTok) => Axios.post(baseUri+'getDigilockerRefreshToken', { }, {
    headers: {
          "refresh_token" : refreshTok
        },
      })

export const getDigilockerFiles = (accessToken) => fetch(baseUri+'getAllFiles', {
        method: 'POST',
        headers: {
          "Authorization": accessToken,
        },
      })

export const gethmac = (content, accessToken, path, contentType) => fetch(baseUri+'getHmac',{
        method:'POST',
        body: content,
            headers: {
              'Authorization':accessToken,
              'path':path,
              'Content-Type':contentType,
            },
    }) 

export const getFile = (accessToken, fileId) => fetch(baseUri+'uriDownloadFile', {
            method: 'POST',
            headers: {
            "Authorization": accessToken,
            "uri" : fileId,
            },
        })