import { Get, Patch, Post, Delete } from "../../../utils/ApiUtil";
export const getFinAssignListFromServer = (id, receiverFlag) => Get('/userfinassetbeneficiaries/getall?userId='+ id+'&receiverFlag='+receiverFlag);
export const deleteFinAssign = (id) => Delete('/userfinassetbeneficiaries/softdelete/'+id);
export const createFinAssignment = (data) => Post('/userfinassetbeneficiaries/multicreate?', data);
export const updateFinAssetById = (id, data) => Patch('/userfinassetbeneficiaries/update/' + id, data);
export const deleteFinAssignAll = (id) => Delete('/userfinassetbeneficiaries/multisoftdelete', id);


export const getOtherAssignListFromServer = (id, receiverFlag) => Get('/userothassetbeneficiaries/getall?userId='+ id+'&receiverFlag='+receiverFlag);
export const deleteOtherAssign = (id) => Delete('/userothassetbeneficiaries/softdelete/'+id);
export const createOtherAssignment = (data) => Post('/userothassetbeneficiaries/multicreate?', data);
export const updateOtherAssignById = (id, data) => Patch('/userothassetbeneficiaries/update/' + id, data);

export const getImmovableAssignListFromServer = (id, receiverFlag) => Get('/userimmovableassetbeneficiaries/getall?userId='+ id+'&receiverFlag='+receiverFlag);
export const deleteImmovableAssign = (id) => Delete('/userimmovableassetbeneficiaries/softdelete/'+id);
export const createImmovableAssignment = (data) => Post('/userimmovableassetbeneficiaries/multicreate?', data);
export const updateImmovableAssignById = (id, data) => Patch('/userimmovableassetbeneficiaries/update/' + id, data);

export const getDigitalAssignListFromServer = (id, receiverFlag) => Get('/userdigitalassetbeneficiaries/getall?userId='+ id+'&receiverFlag='+receiverFlag);
export const deleteDigitalAssign = (id) => Delete('/userdigitalassetbeneficiaries/softdelete/'+id);
export const createDigitalAssignment = (data) => Post('/userdigitalassetbeneficiaries/multicreate?', data);
export const updateDigitalAssignById = (id, data) => Patch('/userdigitalassetbeneficiaries/update/' + id, data);