import React from 'react';
import Footer from './component/layout/Footer';
import Header from './component/layout/Header';
import Privacy from '../dashboard/components/Privacy';

const PrivacyTermsandCond = () => {
    return (
        <div>
        <Header />
        <Privacy/>
        <Footer />
      </div>
    );
}
export default PrivacyTermsandCond;