import React from 'react';
import HeadBar from '../../components/HeaderBar';
import DropDown from '../../components/DropDown';
import RadioBtnGrp from '../../components/RadioButtonGroup';

export const HoldingTypeTag = ({ bind }) => <React.Fragment>
  <div>
    <HeadBar title={'Account Holding Type'} subtitle={''} />
  </div>
  <div className='row'>
    <DropDown placeholder={'Please Select'} options={[{
      "lookupKey": "fin_asset_holding_type",
      "code": "ACC_SIN",
      "value": "Single"
    },
    {
      "lookupKey": "fin_asset_holding_type",
      "code": "ACC_JNT",
      "value": "Joint"
    }]} bind={bind} />
  </div>
</React.Fragment>

export const CardRadioBtn = ({ options, onChange, defaultValue }) => <div className='card' style={{marginBottom:20}}>
  <RadioBtnGrp options={options}
    onChange={onChange} defaultValue={defaultValue} />
</div>
