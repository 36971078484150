import React, { useState,useEffect } from 'react';
import SectionHeading from '../../home/component/layout/SectionHeading';
import Payment from '../../dashboard/forms/payment/Payment'
import CalenderSlots from '../../dashboard/components/CalenderSlots';
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
import { TRAKING_ID } from '../../constant/ServerConst'

ReactGA.initialize(TRAKING_ID);
mixpanel.init("60ba11d6c224405b0b1a24bacb55bd8b");


const FAQ = ()  => {
    useEffect(() => {ReactGA.pageview('Pricing Page')},[])
    useEffect(() => {mixpanel.track("Pricing Page")},[])

    let imgUrl = '../img/banner/greybackground.png';
    let [calender, setCalender] = useState(false)
    let toggleCalender= ()=>setCalender(!calender)

    return(
        
        <div className='container'>
        <div className='d-none d-sm-block'>
         <div className = 'Component-Bg' 
                    style = {{ backgroundImage: `url(${imgUrl})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'left center',
                    backgroundRepeat: 'no-repeat',
                    }}> 
              
                    <div id ='price' style={{width:'100%', paddingLeft:'35%', paddingRight:'2%',paddingTop:'40px'}}>  
                    
                        <SectionHeading heading={'Peace of mind starting at INR 15,000/-'} subHeading={''} image={'../img/banner/plain_greybackground.png'}/>
                        <p align='justify'>These are challenging times. And we understand the concern and anxieties you face everyday.</p>
                        <p align='justify'> We have designed a simple "All Inclusive" pricing structure that gets you a secure, legally valid succession plan 
                        that is readily executable.</p>
                        <p>But again, you may have heard this earlier too.</p>
                        <p><b><i>So, here's our 'Always On' offer. A FREE half-hour consultation with our highly experienced Legal Experts and Inheritance
                            Advisors at a time convenient to you.</i></b></p>
                        <p style={{textAlign:'center'}}><button className='btn btn-primary' onClick={toggleCalender}>Consult us</button></p>
                            

                        <div id = 'philosophy'><p> <br></br><br></br></p></div>
                  </div>
        </div>
                        <p>
                        <table border="0" valign="top">
                            <tr  align="center"  valign="top" fontcolor="#FFFFFFf" margin="2" >
                                <th colspan={1}>Customized Wills <br></br>Starting @ INR 15,000/-<br></br><hr></hr></th>
                                <th colspan={1}></th>
                                <th colspan={1}>Probate Services in India<br></br>Starting @ INR 99,000/-<br></br><hr></hr></th>
                                <th colspan={1}></th>
                                <th colspan={1}>Claims Services<br></br>Starting @ INR 65,000/-<br></br><hr></hr></th>
                            </tr>
                            <tr><td></td></tr>
                            <tr valign="top" style={{ fontSize: 15}}>

                                 <td width="30%">
                                    <ol> <img  alt='' src="../img/banner/tick.png" height={20} width={20}/> Legally drafted Will</ol>
                                    <ol> <img  alt='' src="../img/banner/tick.png" height={20} width={20}/> Secure Digital Archive</ol>
                                    <ol> <img  alt='' src="../img/banner/tick.png" height={20} width={20}/> Inheritance Advisory</ol>
                                    <ol> <img  alt='' src="../img/banner/tick.png" height={20} width={20}/> Unlimited Legal Consults</ol>
                                    <ol> <img  alt='' src="../img/banner/tick.png" height={20} width={20}/> Preferred pricing on other products</ol>
                                    </td>
                                    <td width='3%'></td>
                            
                                <td width='30%'>
                                    <ol> <img  alt='' src="../img/banner/tick.png" height={20} width={20}/> FREE initial legal consultation</ol>
                                    <ol> <img  alt='' src="../img/banner/tick.png" height={20} width={20}/> Charges independent of Estate Value </ol>
                                    <ol> <img  alt='' src="../img/banner/tick.png" height={20} width={20}/> Execution of Probate Petition</ol>
                                    <ol> <img  alt='' src="../img/banner/tick.png" height={20} width={20}/> Representation before authorities</ol>
                                    <ol> <img  alt='' src="../img/banner/tick.png" height={20} width={20}/> End-to-end Assistance</ol>
                                </td>
                                <td width='3%'></td>

                                <td width="30%">
                                    <ol><img  alt='' src="../img/banner/tick.png" height={20} width={20}/> Collect, consolidate and validate documents</ol>
                                    <ol><img  alt='' src="../img/banner/tick.png" height={20} width={20}/> Notarizing, Attestations, POA, Miscellaneous filings etc</ol>
                                    <ol><img  alt='' src="../img/banner/tick.png" height={20} width={20}/> Liaisioning with Govt. / legal / corporate / individual entities</ol>
                                    <ol><img  alt='' src="../img/banner/tick.png" height={20} width={20}/> End-to-end Process </ol>
                                </td>
                            </tr>
                            <tr>
                            <   td align="center"><ol><Payment productID={1} amount={15000} productDescrition={'Will and Estate Services'}/></ol></td>
                                <td width='3%'></td>
                                <td align="center"><ol><Payment productID={2} amount={99000} productDescrition={'Probate Services'}/></ol></td>
                                <td width='3%'></td>
                                <td align="center"><ol><Payment productID={3} amount={65000} productDescrition={'Transmission and Transfer Services'}/></ol></td>
                            </tr>
                        </table>
                        </p>
        </div>
    <div className='d-block d-sm-none'>
     <div className = 'Component-Bg' 
                    style = {{ backgroundImage: `url(${imgUrl})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'left center',
                    backgroundRepeat: 'no-repeat',
                    }}> 
              
                    <div id ='price' style={{width:'100%', paddingLeft:'5%', paddingRight:'2%'}}>  
                    
                        <SectionHeading heading={'Peace of mind starting at INR 15,000/-'} subHeading={''} image={'../img/banner/plain_greybackground.png'}/>
                        <p align='justify'>These are challenging times. And we understand the concern and anxieties you face everyday.</p>
                        <p align='justify'> We have designed a simple "All Inclusive" pricing structure that gets you a secure, legally valid succession plan 
                        that is readily executable.</p>
                        <p>But again, you may have heard this earlier too.</p>
                        <p><b><i>So, here's our 'Always On' offer. A FREE half-hour consultation with our highly experienced Legal Experts and Inheritance
                            Advisors at a time convenient to you.</i></b></p>
                        <p style={{textAlign:'center'}}><button className='btn btn-primary' onClick={toggleCalender}>Consult us</button> </p>
                            

                        <div id = 'philosophy'><p> <br></br><br></br></p></div>
                  </div>
        </div>
                <div className='row'>
                    <div>
                    <tr>
                    <th style={{width:'100%',textAlign:'center',fontcolor:'#FFFFFFf'}} colspan={1}>Customized Wills <br></br>Starting @ INR 15,000/-<br></br><hr></hr></th>
                    </tr>
                    <tr>
                    <td width="30%">
                        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Legally drafted Will</ol>
                        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Secure Digital Archive</ol>
                        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Consultation with Estate Lawyers</ol>
                        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Registration</ol>
                        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Preferred pricing on other products</ol>
                    </td>
                    </tr>
                    <tr><td width='3%'></td></tr>
                    <tr><td align="center"><ol><Payment productID={1} amount={15000} productDescrition={'Will and Estate Services'}/></ol></td></tr>
                    <tr><td style={{paddingBottom:'10px'}}></td></tr>
                    </div>

                    <div>
                    <tr>
                    <th style={{width:'100%', textAlign:'center' ,fontcolor:'#FFFFFFf'}} colspan={1}>Probate Services in India<br></br>Starting @ INR 99,000/-<br></br><hr></hr></th>
                    </tr>
                    <tr>
                    <td width='30%'>
                        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> FREE initial legal consultation</ol>
                        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Charges independent of Estate Value </ol>
                        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Execution of Probate Petition</ol>
                        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Representation before authorities</ol>
                        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> End-to-end Assistance</ol>
                    </td>
                    </tr>
                    <tr><td width='3%'></td></tr>
                    <tr><td align="center"><ol><Payment productID={2} amount={99000} productDescrition={'Probate Services'}/></ol></td></tr>
                    <tr><td style={{paddingBottom:'10px'}}></td></tr>
                    </div>

                    <div>
                    <tr>
                    <th style={{width:'100%', textAlign:'center',fontcolor:'#FFFFFFf'}} colspan={1}>Claims Services<br></br>Starting @ INR 65,000/-<br></br><hr></hr></th>
                    </tr>
                    <tr>
                    <td width="30%">
                        <ol><img alt='' src="../img/banner/tick.png" height={20} width={20}/> Collect, consolidate and validate documents</ol>
                        <ol><img alt='' src="../img/banner/tick.png" height={20} width={20}/> Notarizing, Attestations, POA, Miscellaneous filings etc</ol>
                        <ol><img alt='' src="../img/banner/tick.png" height={20} width={20}/> Liaisioning with Govt. / legal / corporate / individual entities</ol>
                        <ol><img alt='' src="../img/banner/tick.png" height={20} width={20}/> End-to-end Process </ol>
                    </td>
                    </tr>
                    <tr><td width='3%'></td></tr>
                    <tr><td align="center"><ol><Payment productID={3} amount={65000} productDescrition={'Transmission and Transfer Services'}/></ol></td></tr>
                    </div>
                </div>
    </div>
    {calender === true ?
        <CalenderSlots freeConsult={'true'} toggleFreeConsultModal={toggleCalender} consultWho = {'Our Legal Experts'} starProductCard/>
        : null }
     </div>
    )
}


export default (FAQ);
