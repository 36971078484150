import React from 'react'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { setUserData } from '../../actions/userActions'
import { updateUser } from '../../dashboard/forms/personal/personalService'
import { getStoreUser } from '../../selectors/userSelectors'
import GetStarted from '../get_started'

const JustInCase = ({ user, toggleModal, visible, dispatchSetUserDetails, dashboardSignUp }) => {
  const onSubmitBtn = () => {
    updateUser(user.id, {
      custField1: 'JUST_IN_CASE_DONE',
    })
      .then((resp) => {
        dispatchSetUserDetails(resp.data)
      })
      .catch((err) => {
        console.log(err)
        alert('Please try again!')
      })
    toggleModal()
  }

  return (
    <Modal
      show={visible}
      size='lg'
      scrollable
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <GetStarted
        user={user}
        onSubmitBtn={onSubmitBtn}
        toggleModal={toggleModal}
        dashboardSignUp={dashboardSignUp}
      />
    </Modal>
  )
}

const mapDispatchToProps = () => (dispatch) => ({
  dispatchSetUserDetails: (user) => dispatch(setUserData(user)),
})

const mapStateToProps = (state) => ({
  user: getStoreUser(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(JustInCase)
