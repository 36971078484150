import React from 'react'
import Summary from './Summary'

const TestatorSummary = () =>{
    return(
        <Summary assetSummary={true}/>
    )
}
export default TestatorSummary;


