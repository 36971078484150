export const ADD_BENEFICIARY_LIST = 'ADD_BENEFICIARY_LIST';
export const setBeneficiaryData = (beneficiary) => {

    return {
        type: ADD_BENEFICIARY_LIST,
        beneficiary
    }
}


export const DELETE_BENEFICIARY = 'DELETE_BENEFICIARY';
export const deleteBeneficiaryFromList = (beneficiaryId) => {

    return {
        type: DELETE_BENEFICIARY,
        beneficiaryId
    }
}

export const UPDATE_CONTACT_BENEFICIARY = 'UPDATE_CONTACT_BENEFICIARY';
export const updateContactBeneficiary = contact => {
    return {
        type: UPDATE_CONTACT_BENEFICIARY,
        contact
    }
}
export const CREATE_BENEFICIARY = 'CREATE_BENEFICIARY';
export const createBeneficiary = beneficiary => {
    return {
        type: CREATE_BENEFICIARY,
        beneficiary
    }
}
export const getStoreBeneficiaryList = state => state.beneficiary;
