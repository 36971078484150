import initialState from "./initialState";
import _ from 'lodash';
import { ADD_IMMPROP_ASSIGN_LIST, DELETE_IMMPROP_ASSIGN, CREATE_IMMPROP_ASSIGN, EDIT_IMMPROP_ASSIGN } from "../actions/immovablePropAssignActions";
export default (state = initialState.immPropAssigns, action) => {
    switch (action.type) {
        case ADD_IMMPROP_ASSIGN_LIST:
            return action.immPropAssigns;

        case DELETE_IMMPROP_ASSIGN:
            return state.filter(con => con.id !== action.immPropAssignId);
        case CREATE_IMMPROP_ASSIGN:
            return state.concat(action.immPropAssign)
        case EDIT_IMMPROP_ASSIGN:
            const index = _.findIndex(state, { id: action.immPropAssign.id });
            state.splice(index, 1, action.immPropAssign);
            return [...state];
        default:
            return state;
    }
}