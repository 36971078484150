import React, { useState, useRef } from 'react';
import Input from '../../../components/Input'
import { useInput } from '../../../hooks/useInput'
import DropDown from '../../../components/DropDown'
import TextArea from '../../../components/TextArea'
import { ContainerPosition, CustomButtom, HelpSection, HighlightedText, List } from '../../../components/CustomElements';
import Auth from "@aws-amplify/auth";
import { AWS_CONFIG } from '../../../constant/ServerConst';
import { Post } from '../../../utils/ApiUtil';
import { getSplitName, getContactFullName, getCodeValueMap } from '../../../utils/commonUtil'
import moment from 'moment';
import _ from 'lodash'
import { connect } from 'react-redux';
import ModalContainer from '../../../home/component/layout/Modal';
import { Button, Modal } from 'react-bootstrap';
import CalenderSlots from '../../components/CalenderSlots'
import Login from '../../../auth/Login'
import Assign from '../assign/Assign'
import Summary from '../summary/Summary'
import '../will/WillStyles.css'
import jsPDF from 'jspdf'
import $ from 'jquery'
import Payment from '../payment/Payment'

import { setUserData } from '../../../actions/userActions';
import { getStoreUserId, getStoreUser, getStoreUserRole } from '../../../selectors/userSelectors';

import { setFamilyData, getStoreFamily, createFamily, editFamily } from '../../../actions/familyActions'
import { setContactData, getStoreContactList, addContact, editContact } from '../../../actions/contactActions'

import { deleteExecutorFromList, setExecutorData, updateToExecutor } from '../../../actions/executorActions';
import { deleteBeneficiaryFromList, getStoreBeneficiaryList, setBeneficiaryData, createBeneficiary, updateContactBeneficiary } from '../../../actions/beneficiaryActions';

import { setFinAssetData, getStoreFinAssetList } from '../../../actions/finAssetsActions'
import { setImmovableAssetData, getStoreImmovableAssetList } from '../../../actions/immovableAssetsActions'
import { setDigitalAssetData, getStoreDigitalAssetList } from '../../../actions/digitalAssetsActions'
import { setOtherAssetData, getStoreOtherAssetList } from '../../../actions/otherAssetsActions';

import { getUserIdByEmail, updateUser } from '../personal/personalService'

import { getFamilyListFromServer } from '../family/familyService'
import { getContactListFromServer, createContact, updateContactById } from '../contacts/ContactService'

import { createBeneficiary as createBeneficiaryFn, getBeneficiaryListFromServer } from '../benificiary/beneficiaryService';

import { getFinAssetListFromServer  } from '../assets/finAssetService'
import { getDigitalAssetListFromServer } from '../assets/digitalAssetService'
import { getImmovableAssetListFromServer } from '../assets/immovableAssetService'
import {  getOtherAssetListFromServer } from '../assets/otherAssetService';
import { createWill, getWillData, updateWill, deleteWill } from '../will/WillServices'
import { setWillDataList, getWillDataList } from '../../../actions/willAction'

Auth.configure({ ...AWS_CONFIG.Auth })

const Claim = ({
    userProfileId, 
    userRole,
    personalDetails, 
    familyList, 
    contactList, 
    familyListExecutor,
    contactListExecutor,
    familyListBeneficiary,
    contactListBeneficiary,
    disinheritList, 
    executorList, 
    alternateExecutorList,
    fatherList,
    motherList,
    spouseList,
    childList,
    pickBeneficiaryList, 
    beneficiaryList, 
    finAssetList, 
    digitalAssetList, 
    immPropImmovableAssetList, 
    otherAssetList, 
    pfFinAssetList, 
    fdFinAssetList, 
    bankFinAssetList, 
    lockerOtherAssetList, 
    willDataList,
    dispatchSetUserDetails, 
    dispatchSetFamilyList, 
    dispatchSetContactList, 
    dispatchSetExecutorList, 
    dispatchCreateFamily,
    dispatchEditFamily,
    dispatchAddContact, 
    dispatchEditContact,
    dispatchSetBeneficiaryList, 
    dispatchAddFinAsset, 
    dispatchSetImmovableAssetList, 
    dispatchSetDigitalAssetList, 
    dispatchSetOtherAssetList, 
    dispatchUpdateExecutorList, 
    dispatchDeleteExecutorList, 
    dispatchCreateBeneficiary, 
    dispatchDeleteBeneficiary,
    dispatchSetWillData,
    createwill}) =>{

    const getFullName = (e) => {
        let name = e.firstName + " " + e.middleName + " " + e.lastName;
       name = name.replace(/null/g, '')
       return name.replace(/ +/g, " ")
    }
    const [ userId, setUserId ] = useState(isNaN(userProfileId) ? '' : userProfileId)
    const { value: place, bind: setPlace } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.w_loc) ? '' : willDataList.w_loc)
    const { value: willDate, bind: setWillDate } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.w_date) || willDataList.w_date === 'Invalid date' ? '' : willDataList.w_date)
    const [gender, setGender] = useState(_.isEmpty(personalDetails.gender) ? 'Male' : personalDetails.gender)
    const { value: nameOfUser, bind: setNameOfUser } = useInput(_.isEmpty(personalDetails.firstName) ? '' : getFullName(personalDetails))
    const { value: email, bind: setEmail } = useInput(_.isEmpty(personalDetails.primaryEmail) ? '' : personalDetails.primaryEmail)
    const { value: phoneNum, bind: setPhoneNum } = useInput(_.isEmpty(personalDetails.mobilePhone) ? '' : personalDetails.mobilePhone, 'mobile')
    const { value: fatherName, bind: setFatherName } = useInput(Object.keys(fatherList).length === 0 ? '' : _.isEmpty(fatherList[0].firstName) ? '' : getFullName(fatherList[0]))
    const { value: maritalStatus, bind: setMaritalStatus } = useInput(_.isEmpty(personalDetails.maritalStatus) ? 'Single' : personalDetails.maritalStatus )
    const maritalStatusOpt=[
        { code: 'Single', value: 'Single' }, 
        { code: 'Married', value: 'Married' }, 
        { code: 'Divorced', value: 'Divorced' }, 
        { code: 'Widow/Widower', value: 'Widow/Widower' }, 
    ]
    const { value: age, bind: setAge } = useInput(_.isEmpty(personalDetails.dob) || personalDetails.dob === 'Invalid date' ? '' : personalDetails.dob )
    const { value: address, bind: setAddress } = useInput(!Object.keys(personalDetails).length ? '' :_.isEmpty(personalDetails.currentAddress.houseName) ? '' : personalDetails.currentAddress.houseName)
    const { value: motherName, bind: setMotherName } = useInput(Object.keys(motherList).length === 0 ? '' : _.isEmpty(motherList[0].firstName) ? '' : getFullName(motherList[0]))
    const { value: religion, bind: setReligion } = useInput(_.isEmpty(personalDetails.religion)? 'Hindu' : personalDetails.religion)
    const [testatorRegister, setTestatorRegister] = useState(!Object.keys(willDataList).length ? 'Yes' : _.isEmpty(willDataList.prev_will_sr) ? 'Yes' : willDataList.prev_will_sr === true ? 'Yes' : 'No')
    const { value: subRegistrarDate, bind: setSubRegistrarDate } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.prev_will_date) ? '' : willDataList.prev_will_date)
    const { value: registrationDetails, bind: setRegistrationDetails } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.reg_details) ? '' : willDataList.reg_details)
    const { value: earlierDateOfRegistration, bind: setEarlierDateOfRegistration } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.prev_will_sr_date) ? '' : willDataList.prev_will_sr_date)
    const { value: childNumber, bind: setchildNumber } = useInput('0')
    const numChildrens=[
         { code: '0', value: '0' },
         { code: '1', value: '1' },
         { code: '2', value: '2' }, 
         { code: '3', value: '3' }, 
         { code: '4', value: '4' }, 
         { code: '5', value: '5' }, 
         { code: '6', value: '6' },
         { code: '7', value: '7' }, 
         { code: '8', value: '8' }, 
         { code: '9', value: '9' }, 
         { code: '10', value: '10'}
        ]
    const { value: childrens, bind: setNumChildrens } =  useInput(childNumber === '0' ? _.isEmpty(childList) ? '0' :  Object.keys(childList).length : childNumber)
    const [survivingFather, setSurvivingFather] = useState('Yes')
    const [survivingMother, setSurvivingMother] = useState('Yes')
   
    const [child, setChild] = useState('0')
    const { value: childName, bind: setChildName } = useInput(_.isEmpty(childList) ? '' : _.isEmpty(childList[child]) ? '' : _.isEmpty(getFullName(childList[child])) ? '' : getFullName(childList[child]) )
    const { value: childAddress, bind: setChildAddress } = useInput(_.isEmpty(childList) ? '' :  _.isEmpty(childList[child]) ? '' : _.isEmpty(childList[child].homeAddress.houseName) ? '' : childList[child].homeAddress.houseName )
    const { value: childDob, bind: setChildDob } = useInput(_.isEmpty(childList) ? '' :  _.isEmpty(childList[child]) ? '' : _.isEmpty(childList[child].dob) || childList[child].dob === 'Invalid date' ? '' : childList[child].dob )

    const [currentChildName, setCurrentChildName] = useState(_.isEmpty(childList) ? '' : _.isEmpty(childList[child]) ? '' :  _.isEmpty(getFullName(childList[child])) ? '' : getFullName(childList[child]))
    const [currentChildDob, setCurrentChildDob] = useState(_.isEmpty(childList) ? '' :  _.isEmpty(childList[child]) ? '' : _.isEmpty(childList[child].homeAddress.houseName) ? '' : childList[child].homeAddress.houseName)
    const [currentChildAddress, setCurrentChildAddress] = useState(_.isEmpty(childList) ? '' :  _.isEmpty(childList[child]) ? '' :  _.isEmpty(childList[child].dob) || childList[child].dob === 'Invalid date' ? '' : childList[child].dob)
    
    const { value: childNum, bind: setChildNum } = useInput(
           child === 0 ? 'first'
        :  child === 1 ? 'second'
        :  child === 2 ? 'third'
        :  child === 3 ? 'fourth'
        :  child === 4 ? 'fifth'
        :  child === 5 ? 'sixth'
        :  child === 6 ? 'seventh'
        :  child === 7 ? 'eight'
        :  child === 8 ? 'ninth'
        :  child === 9 ? 'tenth'
        : 'first'
    )
    const { value: spouseName, bind: setSpouseName } = useInput(Object.keys(spouseList).length===0 ? '' : _.isEmpty(spouseList[0].firstName) ? '' : getFullName(spouseList[0]))
    const [executor, setExecutor] = useState('Yes')
    const { value: executorType, bind: setExecutorType } = useInput('Family')
    const executorTypeOpt = [
        {code: 'Family',value: 'Family'},
        {code: 'Contact',value: 'Contact'},
        {code: 'Other',value: 'Other'}
    ]
    const relationshipMapping = {
        'FATHER' : 'Father',
        'MOTHER' : 'Mother',
        'SPOUSE' : 'Spouse',
        'CHILD' : 'Child',
        "IN-LAWS" : "In Laws",
        "SIBLING" : "Siblings",
        "OTH-FAM" : "Other Family Members",
        "FRND" : "Friends",
        "COL" : "Colleagues",
        "DOC" : "Doctor",
        "LAW" : "Lawyer",
        "ACC" : "Accountant",
        "TAX" : "Consultants"
    }
    const { value: executorName, bind: setExecutorName } = useInput(executorList.length===0 ? '' : _.isEmpty(executorList[0].firstName) ? '' : getFullName(executorList[0]))
    const { value: executorRelationship, bind: setExecutorRelationship } = useInput(executorList.length===0 ? 'In Laws' : _.isEmpty(executorList[0].relationship) ? 'In Laws' : 
        (executorList[0].relationship === "IN-LAWS" ? "In Laws" :
        executorList[0].relationship ===  "SIBLING" ? "Siblings" :
        executorList[0].relationship ===  "OTH-FAM" ? "Other Family Members"   :
        executorList[0].relationship === "FRND" ? "Friends"  :
        executorList[0].relationship ===  "COL" ? "Colleagues" :
        executorList[0].relationship === "DOC" ? "Doctor" :
        executorList[0].relationship ===  "LAW" ? "Lawyer" :
        executorList[0].relationship === "ACC" ? "Accountant"  :
        executorList[0].relationship === "TAX" ? "Consultants"  : "In Laws")
        )
    const relationOptions = [
        {"code": "In Laws","value": "In Laws"},
        {"code": "Siblings","value": "Siblings"},
        {"code": "Other Family Members","value": "Other Family Members"},
        {"code": "Other Family Members","value": "Other Family Members"},
        {"code": "Colleagues","value": "Colleagues"},
        {"code": "Doctor","value": "Doctor"},
        {"code": "Lawyer","value": "Lawyer"},
        {"code": "Accountant","value": "Accountant"},
        {"code": "Consultants","value": "Consultants"}
    ];
    const [selectedIds, bindSelected] = useState([]);
    const famExe = familyListExecutor.filter(fa=> fa.id === selectedIds[0])
    const famExecutor = famExe.lenght===0 ? '' :famExe[0]
    const contExe = contactListExecutor.filter(fa=> fa.id === selectedIds[0])
    const conExecutor = contExe.lenght===0 ? '' :contExe[0]

    const { value: alternateExecutorType, bind: setAlternateExecutorType } = useInput('Family')
    const [alternateSelectedIds, bindAlternateSelectedIds] = useState([]);
    const famAltExe = familyListExecutor.filter(fa=> fa.id === alternateSelectedIds[0])
    const famAltExecutor = famAltExe.lenght===0 ? '' :famAltExe[0]
    const contAltExe = contactListExecutor.filter(fa=> fa.id === alternateSelectedIds[0])
    const conAltExecutor = contAltExe.lenght===0 ? '' :contAltExe[0]

    const [disinheritSelectedIds, bindDisinheritSelected] = useState([]);
    const famDis = familyListExecutor.filter(fa=> fa.id === disinheritSelectedIds[0])
    const famDisinherit = famDis.lenght===0 ? '' :famDis[0]
    const contDis = contactListExecutor.filter(fa=> fa.id === disinheritSelectedIds[0])
    const conDisinherit = contDis.lenght===0 ? '' :contDis[0]

    const { value: executorAddress, bind: setExecutorAddress } = useInput(executorList.length===0 ? '' : _.isEmpty(executorList[0].homeAddress.houseName) ? '' : executorList[0].homeAddress.houseName)
    const [alternateExecutor, setAlternateExecutor] = useState('No')
    const { value: alternateExecutorName, bind: setAlternateExecutorName } = useInput(alternateExecutorList.length===0 ? '' : _.isEmpty(alternateExecutorList[0].firstName) ? '' : getFullName(alternateExecutorList[0]))
    const { value: alternateExecutorRelationship, bind: setAlternateExecutorRelationship } = useInput(alternateExecutorList.length===0 ? 'In Laws' : _.isEmpty(alternateExecutorList[0].relationship) ? 'In Laws' : 
    (alternateExecutorList[0].relationship === "IN-LAWS" ? "In Laws" :
    alternateExecutorList[0].relationship ===  "SIBLING" ? "Siblings" :
    alternateExecutorList[0].relationship ===  "OTH-FAM" ? "Other Family Members"   :
    alternateExecutorList[0].relationship === "FRND" ? "Friends"  :
    alternateExecutorList[0].relationship ===  "COL" ? "Colleagues" :
    alternateExecutorList[0].relationship === "DOC" ? "Doctor" :
    alternateExecutorList[0].relationship ===  "LAW" ? "Lawyer" :
    alternateExecutorList[0].relationship === "ACC" ? "Accountant"  :
    alternateExecutorList[0].relationship === "TAX" ? "Consultants"  : "In Laws")
    )
    const { value: alternateExecutorAddress, bind: setAlternateExecutorAddress } = useInput(alternateExecutorList.length===0 ? '' : _.isEmpty(alternateExecutorList[0].homeAddress.houseName) ? '' : alternateExecutorList[0].homeAddress.houseName)
    const [executorCompensation, setExecutorCompensation] = useState('Yes')
    const { value: executorCompensionDetails, bind: setExecutorCompensionDetails } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.exec_comp) ? '' : willDataList.exec_comp.replace(/&#92n/gm,'\n'));
    const [listAssets, setListAssets] = useState('Enter All Assets')    
    const { value: assetDetails, bind: setAssetDetails } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.w_assets) ? '' : willDataList.w_assets.replace(/&#92n/gm,'\n'));
    const { value: assetDistribution, bind: setAssetDistribution } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.w_asset_dist) ? 'All to my spouse' : willDataList.w_asset_dist)
    const [assetDistributionType, setAssetDistributionType] = useState('Asset Distribution')
    const assetDistributionOpt = [
        { code: 'All to my spouse', value: 'All to my spouse'},
        { code: 'All to my living child or children', value: 'All to my living child or children'},
        { code: 'All to one named beneficiary', value: 'All to one named beneficiary'},
        { code: 'Divided between multiple named beneficiaries in equal shares', value: 'Divided between multiple named beneficiaries in equal shares'},
        { code: 'Divided between multiple named beneficiaries in unequal shares', value: 'Divided between multiple named beneficiaries in unequal shares'},
        { code: 'In an alternate method of distribution', value: 'In an alternate method of distribution'}
    ]
    const { value: alternateAssetDistribution, bind: setAlternateAssetDistribution } = useInput('The children of the testator')
    const { value: alternateBenificiary, bind: setAlternateBenificiary } = useInput('')
    const { value: multipleAlternateBenificiary, bind: setMultipleAlternateBenificiary } = useInput('')
    const { value: multipleAlternateBenificiaryUnequal, bind: setMultipleAlternateBenificiaryUnequal } = useInput('')
    const { value: multipleAlternateBenificiaryUnequalShare, bind: setMultipleAlternateBenificiaryUnequalShare } = useInput('')
    const { value: childAssetDistribution, bind: setChildAssetDistribution } = useInput('An alternate named beneficiary')
    const childAssetDistributionOpt = [
        { code: 'An alternate named beneficiary', value: 'An alternate named beneficiary'},
        { code: 'Multiple named beneficiaries in equal shares', value: 'Multiple named beneficiaries in equal shares'},
        { code: 'Multiple named beneficiaries in unequal shares', value: 'Multiple named beneficiaries in unequal shares'},
    ]
    const alternateAssetDistributionOpt = [
        { code: 'The children of the testator', value: 'The children of the testator'},
        { code: 'An alternate named beneficiary', value: 'An alternate named beneficiary'},
        { code: 'Multiple named beneficiaries in equal shares', value: 'Multiple named beneficiaries in equal shares'},
        { code: 'Multiple named beneficiaries in unequal shares', value: 'Multiple named beneficiaries in unequal shares'},
    ]
    const { value: benificiaryForAssets, bind: setBenificiaryForAssets } = useInput('')
    const { value: benificiaryForAssetsOpt, bind: setBenificiaryForAssetsOpt } = useInput('')
    const benificiaryOpt =[
        { code: 'The children of the testator', value: 'The children of the testator'},
        { code: 'An alternate named beneficiary', value: 'An alternate named beneficiary'},
        { code: 'Multiple named beneficiaries in equal shares', value: 'Multiple named beneficiaries in equal shares'},
        { code: 'Multiple named beneficiaries in unequal shares', value: 'Multiple named beneficiaries in unequal shares'},
    ]
    const { value: divideMultipleAlternateBenificiaryequalShare, bind: setDivideMultipleAlternateBenificiaryequalShare } = useInput('')
    const { value: divideMultipleAlternateBenificiaryUnequal, bind: setDivideMultipleAlternateBenificiaryUnequal } = useInput('')
    const { value: divideMultipleAlternateBenificiaryUnequalShare, bind: setDivideMultipleAlternateBenificiaryUnequalShare } = useInput('')
    const { value: descriptionWillInherit, bind: setDescriptionWillInherit } = useInput('')
    const [exculdePerson, setExculdePerson] = useState('No')
    
    const { value: exculdePersonName, bind: setExculdePersonName } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.exculdePersonName) ? '' : willDataList.exculdePersonName)
    const { value: excludePersonRelationship, bind: setExcludePersonRelationship  } = useInput(disinheritList.length===0 ? 'In Laws' : _.isEmpty(disinheritList[0].relationship) ? 'In Laws' : 
    (
    disinheritList[0].relationship === "IN-LAWS" ? "In Laws" :
    disinheritList[0].relationship ===  "SIBLING" ? "Siblings" :
    disinheritList[0].relationship ===  "OTH-FAM" ? "Other Family Members"   :
    disinheritList[0].relationship === "FRND" ? "Friends"  :
    disinheritList[0].relationship ===  "COL" ? "Colleagues" :
    disinheritList[0].relationship === "DOC" ? "Doctor" :
    disinheritList[0].relationship ===  "LAW" ? "Lawyer" :
    disinheritList[0].relationship === "ACC" ? "Accountant"  :
    disinheritList[0].relationship === "TAX" ? "Consultants"  : "In Laws")
    )

    const { value: excludePersonType, bind: setExcludePersonType } = useInput('Family')
    const [appointTrustee, setAppointTrustee] = useState('some other person')
    const [benPredeceases, setBenPredeceases] = useState('Yes')
    const { value: trusteeName, bind: setTrusteeName } = useInput('')
    const [bankAccount, setBankAccount] = useState(bankFinAssetList.lenght === 0 ? 'No' : 'Yes')
    const [fixedDeposits, setFixedDeposits] = useState(fdFinAssetList.lenght === 0 ? 'No' : 'Yes')
    const [bankLocker, setBankLocker] = useState(lockerOtherAssetList.lenght === 0 ? 'No' : 'Yes')
    const [pf, setPf] = useState(pfFinAssetList.lenght === 0 ? 'No' : 'Yes')
    //const [listOtherAssets, setListOtherAssets] = useState('Enter All Assets')
    const { value: testatorGifts, bind: setTestatorGifts } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.non_fin_assets) ? '' : willDataList.non_fin_assets.replace(/&#92n/gm,'\n'))
    const { value: personManagingEstate, bind: setPersonManagingEstate } = useInput('')
    const [specialInstructions, setSpecialInstructions] = useState('Yes')
    const { value: specialInstructionDetails, bind: setSpecialInstructionDetails } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.spl_ins) ? '' : willDataList.spl_ins.replace(/&#92n/gm,'\n'))
    const { value: testatorAlternate, bind: setTestatorAlternate } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.wpcls) ? '' : willDataList.wpcls)
    const { value: testatorGuardian, bind: setTestatorGuardian } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.minor_guardian) ? '' : willDataList.minor_guardian)
    const { value: testatorGuardianResidence, bind: setTestatorGuardianResidence } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.minor_loc) ? '' : willDataList.minor_loc)
    const { value: minorTrusteeName, bind: setMinorTrusteeName } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.trusteeName) ? '' : willDataList.trusteeName)
    const [appointTrusteeForMinor, setAppointTrusteeForMinor] = useState('some other person')
    const [minorBeneficiaries, setMinorBeneficiaries] = useState(!Object.keys(willDataList).length ? 'No' : _.isEmpty(willDataList.minor_trustee) ? 'No' : willDataList.minor_trustee === true ? 'Yes' : 'No')
    const [testatorDeath, setTestatorDeath] = useState('')
    const { value: testatorDeathOption, bind: setTestatorDeathOption } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.final_opt) ? '' : willDataList.final_opt)
    const deathOpt = [
        { code: 'Cremated', value: 'Cremated'},
        { code: 'Buried', value: 'Buried'},
        { code: 'Other', value: 'Other'},
    ]
    const { value: instructions, bind: setInstructions } = useInput(!Object.keys(willDataList).length ? '' : _.isEmpty(willDataList.final_ins) ? '' : willDataList.final_ins.replace(/&#92n/gm,'\n')) 
    
    const [ question, setQuestion] =useState(52)
    const [divname, setDivName] = useState('div0')
    const  ref1 =useRef(null)
    const  ref2 =useRef(null)
    const  ref3 =useRef(null)
    const  ref4 =useRef(null)
    const  ref5 =useRef(null)
    const  ref6 =useRef(null)
    const  ref7 =useRef(null)
    const  ref8 =useRef(null)
    const  ref9 =useRef(null)
    const  ref10 =useRef(null)

    const [receiverFlag, setReceiverFlag] = useState(false)

    const propertyType={"RES":"Residential",
              "COMM": "Commercial",
              "LAND": "Land"
            }

    let [visibleMuslimReligion, setVisibleMuslimReligion] = useState(false)
    let toggleMuslimReligion = ()=>setVisibleMuslimReligion(!visibleMuslimReligion)
    let [visibleChristianReligion, setVisibleChristianReligion] = useState(false)
    let toggleChristianReligion = ()=>setVisibleChristianReligion(!visibleChristianReligion)
    let [visibleParsiReligion, setVisibleParsiReligion] = useState(false)
    let toggleParsiReligion = ()=>setVisibleParsiReligion(!visibleParsiReligion)

    let [calenderslot, setCalenderSlot] = useState(false)
    let toggleCalenderSlot= ()=>setCalenderSlot(!calenderslot)
    const [mailcontent, setMailContent] = useState('')
    const [mailpayload, setMailPayload] = useState('')

    let [visibleLogin, setVisibleLogin] = useState(false)
    let toggleLogin = () => setVisibleLogin(!visibleLogin)

    let [visibleAssetDescriptionToo1, setVisibleAssetDescriptionToo1] = useState(false)
    let toggleAssetDescriptionToo1 = () => setVisibleAssetDescriptionToo1(!visibleAssetDescriptionToo1)
    
    let [visiblePayment, setVisiblePayment] = useState(false)
    let togglePayment = () => setVisiblePayment(!visiblePayment)

    let [openWill, setOpenWill] = useState(false)
    let toggleOpenWill = () => setOpenWill(!openWill)

      /*const paymentPage = () =>{
          togglePayment()
      }*/
      const appointment = () =>{
        var doc = new jsPDF();
        var elementHTML = $('#HTMLtoPDF').html();
        var specialElementHandlers = {
            '#elementH': function (element, renderer) {
                return true;
            }
        };
        doc.fromHTML(elementHTML, 15, 15, {
            'width': 170,
            'elementHandlers': specialElementHandlers
        });
      
        
        // Save the PDF
        var base64pdf = doc.output();
        let payload={
            "email" : personalDetails.primaryEmail
           }
       
        setMailContent(base64pdf)
        setMailPayload(payload)
        toggleCalenderSlot()
      }
      const backToFirst = () =>{
        setQuestion(0)
        setDivName('div0')
      }

    const resetWillData = () =>{
        deleteWill(userProfileId)
        .then(res =>{
            dispatchSetWillData(res.data)
            setQuestion(0)
            setDivName('div0')
        })
        .catch(e => {
            console.log(e.message)
        })
    }
    const scroll = (e) =>{
       e.current.scrollIntoView({
            behavior: 'smooth',
            block: "start",
          });
          window.scroll(0,0)
          
    }

    const callWillApi = () =>{
        updateUser(userProfileId, {gender : gender, mobilePhone: phoneNum, ...getSplitName(nameOfUser), middleName:''})
        .then(res => {
        dispatchSetUserDetails(res.data)
        getWillData(userProfileId)
        .then(res =>(
            dispatchSetWillData(res.data),
            setQuestion(question + 1),
            setDivName(`div${question + 1}`)
        ))
        .catch(e => {
            console.log(e.message)
        })
        }).catch(e => {
        console.log(e.message)
    })
    }
    const callApis = (userId) =>{

        updateUser(userId, {primaryEmail : email.toLowerCase().trim(), gender : gender, mobilePhone: phoneNum, ...getSplitName(nameOfUser), middleName:''})
        .then(res => {
            console.log("Updated Successfully")
            dispatchSetUserDetails(res.data)
            Promise.all([
                getFamilyListFromServer(userId),
                getContactListFromServer(userId),
            
                getBeneficiaryListFromServer(userId, receiverFlag),

                getFinAssetListFromServer(userId, receiverFlag),
                getImmovableAssetListFromServer(userId, receiverFlag),
                getDigitalAssetListFromServer(userId, receiverFlag),
                getOtherAssetListFromServer(userId, receiverFlag),
                getWillData(userId),
            ])
                .then((response) => {
                dispatchSetFamilyList(response[0].data)
                dispatchSetContactList(response[1].data)
            
                dispatchSetBeneficiaryList(response[2].data)

                dispatchAddFinAsset(response[3].data)
                dispatchSetImmovableAssetList(response[4].data)
                dispatchSetDigitalAssetList(response[5].data)
                dispatchSetOtherAssetList(response[6].data)
                dispatchSetWillData(response[7].data)
                   
                })
                .catch(
                    (ex) => console.error(ex)
                )

        }).catch(e => {
            console.log(e.message)
        })
    }

    const createWillApi = () =>{
        const format = "YYYY-MM-DD HH:mm:ss"
        const date = new Date()
        const now = moment(date).format(format);
        const payload = {
            user_id : userId,
            w_loc : place,        
            w_date : willDate,
            last_modification_date:now,
            exec_comp : executorCompensionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_assets : assetDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_asset_dist : assetDistribution,
            spl_ins : specialInstructionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            wpcls : testatorAlternate,
            trst_name : trusteeName,
            prev_will_sr: testatorRegister === 'Yes' ? true : false,
            prev_will_date: subRegistrarDate,
            reg_details: registrationDetails,
            prev_will_sr_date: earlierDateOfRegistration,
            bank_acc: bankAccount === 'Yes' ? true : false,
            bank_loc: bankLocker === 'Yes' ? true : false,
            pf: pf === 'Yes' ? true : false,
            fixed_deposits: fixedDeposits === 'Yes' ? true : false,
            final_opt: testatorDeathOption,
            final_ins: instructions.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            non_fin_assets:testatorGifts.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            minor_trustee : minorBeneficiaries,
            minor_guardian : testatorGuardian,
            minor_loc : testatorGuardianResidence,
            exclude_person : exculdePersonName
        }
        createWill(payload)
        .then((res)=>{
                dispatchSetWillData(res.data)
                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
                console.log(res.data)
            })
    }
    const updateWillApi = () =>{
        const format = "YYYY-MM-DD HH:mm:ss"
        const date = new Date()
        const now = moment(date).format(format);
        const payload = {
            user_id : userId,
            w_loc : place,        
            w_date : willDate,
            last_modification_date:now,
            exec_comp : executorCompensionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_assets : assetDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_asset_dist : assetDistribution,
            spl_ins : specialInstructionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            wpcls : testatorAlternate,
            trst_name : trusteeName,
            prev_will_sr: testatorRegister === 'Yes' ? true : false,
            prev_will_date: subRegistrarDate,
            reg_details: registrationDetails,
            prev_will_sr_date: earlierDateOfRegistration,
            bank_acc: bankAccount === 'Yes' ? true : false,
            bank_loc: bankLocker === 'Yes' ? true : false,
            pf: pf === 'Yes' ? true : false,
            fixed_deposits: fixedDeposits === 'Yes' ? true : false,
            final_opt: testatorDeathOption,
            final_ins: instructions.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            non_fin_assets:testatorGifts.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            minor_trustee : minorBeneficiaries,
            minor_guardian : testatorGuardian,
            minor_loc : testatorGuardianResidence,
            exclude_person : exculdePersonName
        }
        updateWill(payload)
        .then((res)=>{
                dispatchSetWillData(res.data)
                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
                console.log(res.data)
        })
    }
    const updateWillApi2 = (num) =>{
        const format = "YYYY-MM-DD HH:mm:ss"
        const date = new Date()
        const now = moment(date).format(format);    
        const payload = {
            user_id : userId,
            w_loc : place,        
            w_date : willDate,
            last_modification_date:now,
            exec_comp : executorCompensionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_assets : assetDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_asset_dist : assetDistribution,
            spl_ins : specialInstructionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            wpcls : testatorAlternate,
            trst_name : trusteeName,
            prev_will_sr: testatorRegister === 'Yes' ? true : false,
            prev_will_date: subRegistrarDate,
            reg_details: registrationDetails,
            prev_will_sr_date: earlierDateOfRegistration,
            bank_acc: bankAccount === 'Yes' ? true : false,
            bank_loc: bankLocker === 'Yes' ? true : false,
            pf: pf === 'Yes' ? true : false,
            fixed_deposits: fixedDeposits === 'Yes' ? true : false,
            final_opt: testatorDeathOption,
            final_ins: instructions.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            non_fin_assets:testatorGifts.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            minor_trustee : minorBeneficiaries,
            minor_guardian : testatorGuardian,
            minor_loc : testatorGuardianResidence,
            exclude_person : exculdePersonName
        }
        updateWill(payload)
        .then((res)=>{
                dispatchSetWillData(res.data);
                setQuestion(question + num);
                setDivName(`div${question + num}`);
                console.log(res.data)
        })
        .catch(err=>
            (console.log(err))
        )
    }

    const updateWillApi3 = (num) =>{
        const format = "YYYY-MM-DD HH:mm:ss"
        const date = new Date()
        const now = moment(date).format(format);    
        const payload = {
            user_id : userId,
            w_loc : place,        
            w_date : willDate,
            last_modification_date:now,
            exec_comp : executorCompensionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_assets : assetDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            w_asset_dist : assetDistribution,
            spl_ins : specialInstructionDetails.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            wpcls : testatorAlternate,
            trst_name : trusteeName,
            prev_will_sr: testatorRegister === 'Yes' ? true : false,
            prev_will_date: subRegistrarDate,
            reg_details: registrationDetails,
            prev_will_sr_date: earlierDateOfRegistration,
            bank_acc: bankAccount === 'Yes' ? true : false,
            bank_loc: bankLocker === 'Yes' ? true : false,
            pf: pf === 'Yes' ? true : false,
            fixed_deposits: fixedDeposits === 'Yes' ? true : false,
            final_opt: testatorDeathOption,
            final_ins: instructions.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            non_fin_assets:testatorGifts.replace(/(\r\n|\n|\r)/gm,"&#92n"),
            minor_trustee : minorBeneficiaries,
            minor_guardian : testatorGuardian,
            minor_loc : testatorGuardianResidence,
            exclude_person : exculdePersonName
        }
        updateWill(payload)
        .then((res)=>{
                dispatchSetWillData(res.data);
                setQuestion(num);
                setDivName(`div${num}`);
                console.log(res.data)
        })
        .catch(err=>
            (console.log(err))
        )
    }

    const createUser = async () => {
        const re = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/g
        const phoneFormat = ((/^[0-9\b]+$/).test(phoneNum))  || ((/^[+|-][0-9\b]+$/).test(phoneNum))
        let emailIDs = email.toLowerCase().trim();
        if (
          re.test(String(email).toLowerCase()) &&
          phoneFormat
        ) {
          let awsPayload = {
            username: emailIDs,
            password: 'Welcome@01',
            attributes: {
              email: emailIDs,
              phone_number: ((/^[+|-][0-9\b]+$/).test(phoneNum)) ? phoneNum : '+91' + phoneNum,
              given_name: nameOfUser,
            },
          }
          console.log(awsPayload)
          Auth.signUp(awsPayload)
            .then((response) => {
              console.log('aws signup complete')
              console.log(response)
              Post('userprofile/create?userId=' + response.userSub, {
                userName: response.userSub,
                primaryEmail: emailIDs,
                mobilePhone: ((/^[+|-][0-9\b]+$/).test(phoneNum)) ? phoneNum : '+91' + phoneNum,
                gender:gender,
                ...getSplitName(nameOfUser),
              })
                .then((resp) => {
                  console.log('backend user create complete',resp)
                  updateUser(resp.data.id, {primaryEmail : email.toLowerCase().trim(), gender : gender, mobilePhone: ((/^[+|-][0-9\b]+$/).test(phoneNum)) ? phoneNum : '+91' + phoneNum, ...getSplitName(nameOfUser), middleName:''})
                  .then(res => {
                      dispatchSetUserDetails(res.data)
                  })
                  .catch(err=>console.log(err))
                  setUserId(resp.data.id)
                  setQuestion(question + 1)
                  setDivName(`div${question + 1}`)
            })
                .catch((error) => {
                  console.log('backend error')
                  console.log(error)
                  alert('Unable to register, please try again!')
                })
            })
            .catch((error) => {
              console.log('aws error')
              console.log(error)
              if (error.code === 'UsernameExistsException') {
                getUserIdByEmail(emailIDs)
                .then(response=>{
                setUserId(response.data.id)

                callApis(response.data.id)

                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
                })
              } else alert(error.message)
            })
        } else {
          if (!phoneFormat)
            alert('please enter valid phone number ')
          else
            alert(
              'Please enter a valid email address. You could be missing something in the e-mail format.'
            )
        }
      }
    const createFather = () =>{
        let payload = {
            userId,
            relationship:'FATHER',
            deceased: survivingFather === 'Yes' ? false : true,
            contactLookupCode: 'INDV',
            gender: 'Male',
            ...getSplitName(fatherName),
            middleName:''
          }
          let updatePayload = {
            deceased: survivingFather === 'Yes' ? false : true,
            fullName:fatherName,
            ...getSplitName(fatherName),
            middleName:''
        }
          Object.keys(fatherList).length === 0 ?
          (
            createContact(payload)
            .then(response => {
                console.log('Record Created Successfully');
              dispatchCreateFamily(response.data);
              setQuestion(question + 1)
              setDivName(`div${question + 1}`)
            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          updateContactById(fatherList[0].id, updatePayload)
          .then(response => {
            console.log('Record Updated Successfully');
            dispatchEditFamily(response.data);
            setQuestion(question + 1);
            setDivName(`div${question + 1}`);
          }).catch(err => {
            console.log(err)
          })
    }
  
    const createMother = () =>{
        let payload = {
            userId,
            relationship:'MOTHER',
            deceased: survivingMother === 'Yes' ? false : true,
            contactLookupCode: 'INDV',
            gender: 'Female',
            ...getSplitName(motherName),
            middleName:''
          }
        let updatePayload = {
            deceased: survivingMother === 'Yes' ? false : true,
            ...getSplitName(motherName),
            middleName:''
        }
        Object.keys(motherList).length === 0 ?
          (
            createContact(payload)
            .then(response => {
              console.log('Record Created Successfully');
              dispatchCreateFamily(response.data);
              setQuestion(question + 1);
              setDivName(`div${question + 1}`);
            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          updateContactById( motherList[0].id, updatePayload)
          .then(response => {
            console.log('Record Updated Successfully');
            dispatchEditFamily(response.data);
            setQuestion(question + 1);
            setDivName(`div${question + 1}`);
          }).catch(err => {
            console.log(err)
          })
    }

    const createSpouse = () =>{
        let payload = {
            userId,
            relationship:'SPOUSE',
            contactLookupCode: 'INDV',
            ...getSplitName(spouseName),
            middleName:''
          }
          let updatePayload = {
            ...getSplitName(spouseName),
            middleName:''
        }
        Object.keys(spouseList).length === 0 ?
          (
            createContact(payload)
            .then(response => {
              console.log('Record Created Successfully');
              dispatchCreateFamily(response.data);
              if(childrens == 0)
              {
              setQuestion(question + 2)
              setDivName(`div${question + 2}`)
              }
              else
              {
              setQuestion(question + 1)
              setDivName(`div${question + 1}`)
              }
            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          updateContactById( spouseList[0].id, updatePayload)
          .then(response => {
            console.log('Record Updated Successfully');
            dispatchEditFamily(response.data);
            if(childrens == 0)
            {
            setQuestion(question + 2)
            setDivName(`div${question + 2}`)
            }
            else
            {
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
            }
          }).catch(err => {
            console.log(err)
          })
    }

    const createChild = () =>{
        let payload = {
            userId,
            relationship:'CHILD',
            contactLookupCode: 'INDV',
            ...getSplitName(childName),
            contHomeAddress1 : childAddress,
            dob : childDob
          }
          let updatePayload = {
            relationship:'CHILD',
            contactLookupCode: 'INDV',
            ...getSplitName(childName),
            contHomeAddress1 : childAddress,
            dob : childDob
        }
        parseInt(child) + 1 > childList.length || parseInt(child) == childList.length || childList.length === 0 ?
          (
            createContact(payload)
            .then(response => {
              console.log('Record Created Successfully');
              dispatchCreateFamily(response.data);
              if(parseInt(child) + 1 == childrens)
              {
              setQuestion(44)
              setDivName('div44')
              }
              else
              {
                setChild(parseInt(child) + 1)
              }
            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          (
            updateContactById( childList[child].id, updatePayload)
            .then(response => {
              console.log('Record Updated Successfully');
              dispatchEditFamily(response.data);
              if(parseInt(child) + 1 == childrens)
              {
              setQuestion(44)
              setDivName('div44')
              }
              else
              {
                setChild(parseInt(child) + 1)
              }
            }).catch(err => {
              console.log(err)
            })
        )
    }

    const updateUserProfile = () =>{ 
        let payload= question === 5 ? 
        {
            religion : religion,
        }
        : question === 3 ?
        {
            maritalStatus : maritalStatus
        } : question === 4 ?
        { 
            dob : age,
            currHomeAddress1 : address
        }: {}
        updateUser(userId, payload)
        .then(res => {
            console.log(res.data)
            dispatchSetUserDetails(res.data)
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
          }).catch(e => {
            console.log('error')
          })
    }

    const updateExecutorFlag = () =>{
        let id = selectedIds[0]
        updateContactById(id, {executorFlag : true})
        .then(response =>{
            response.data.relationship === 'FATHER' || response.data.relationship === 'MOTHER' || response.data.relationship === 'SPOUSE' || response.data.relationship === 'CHILD'
            ? dispatchEditFamily(response.data)
            : dispatchEditContact(response.data)
            setQuestion(17)
            setDivName('div17')
        })
    }

    const createExecutor = () =>{
        let relation = executorRelationship === "In Laws" ? "IN-LAWS" :
        executorRelationship === "Siblings" ? "SIBLING" :
        executorRelationship === "Other Family Members" ? "OTH-FAM" :
        executorRelationship === "Friends" ? "FRND" :
        executorRelationship === "Colleagues" ? "COL" :
        executorRelationship === "Doctor" ? "DOC" :
        executorRelationship === "Lawyer" ? "LAW" :
        executorRelationship === "Accountant" ? "ACC" :
        executorRelationship === "Consultants" ? "TAX" : "IN-LAWS"
    
        let payload={
            userId,
            contactLookupCode: 'INDV',
            relationship:relation,
            executorFlag : true,
            ...getSplitName(executorName),
            middleName:'',
            contHomeAddress1 : executorAddress,
        }
          let updatePayload = {
            relationship:relation,
            executorFlag : true,
            ...getSplitName(executorName),
            middleName:'',
            contHomeAddress1 : executorAddress,
        }
        executorList.length === 0 ?
          (
            createContact(payload)
            .then(response => {
              console.log('Record Created Successfully');
              dispatchAddContact(response.data);
              setQuestion(question + 1)
              setDivName(`div${question + 1}`)
            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          updateContactById( executorList[0].id, updatePayload)
          .then(response => {
            console.log('Record Updated Successfully');
            executorList[0].relationship === 'FATHER' || executorList[0].relationship === 'MOTHER' || executorList[0].relationship === 'SPOUSE' || executorList[0].relationship === 'CHILD'
            ? dispatchEditFamily(response.data)
            : dispatchEditContact(response.data)
            setQuestion(question + 1);
            setDivName(`div${question + 1}`);
          }).catch(err => {
            console.log(err)
          })
    }
    
    const createDisinherit = () =>{

        let payload={
            userId,
            contactLookupCode: 'INDV',
            disinherit : true,
            ...getSplitName(exculdePersonName),
            middleName:'',
        }
          let updatePayload = {
            contactLookupCode: 'INDV',
            disinherit : true,
            ...getSplitName(exculdePersonName),
            middleName:'',
        }
        disinheritList.length === 0 ?
          (
            createContact(payload)
            .then(response => {
              console.log('Record Created Successfully');
              dispatchAddContact(response.data);
              
              if(assetDistribution === 'All to my spouse' 
              && assetDistribution === 'The children of the testator' 
              && exculdePerson === 'Yes')
              {
              setQuestion(46)
              setDivName('div46')
                }
              else
              {
              setQuestion(46)
              setDivName('div46')
              }

            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          updateContactById( disinheritList[0].id, updatePayload)
          .then(response => {
            console.log('Record Updated Successfully');
            if(disinheritList[0].relationship === 'FATHER' || disinheritList[0].relationship === 'MOTHER' || disinheritList[0].relationship === 'SPOUSE' || disinheritList[0].relationship === 'CHILD')
            ( dispatchEditFamily(response.data))
            else( dispatchEditContact(response.data))
           if(assetDistribution === 'All to my spouse' && assetDistribution === 'The children of the testator' && exculdePerson === 'Yes')
            {
            setQuestion(46)
            setDivName('div46')
            }
            else
            {setQuestion(46)
            setDivName('div46')
            }
          }).catch(err => {
            console.log(err)
          })
    }
    
    const updateAlternateExecutorFlag = () =>{
        let id =  alternateSelectedIds[0]
        updateContactById(id, {executorFlag : true, custField5:'true'})
        .then(response =>{
            response.data.relationship === 'FATHER' || response.data.relationship === 'MOTHER' || response.data.relationship === 'SPOUSE' || response.data.relationship === 'CHILD'
            ? dispatchEditFamily(response.data)
            : dispatchEditContact(response.data)
            setQuestion(19)
            setDivName('div19')
        })
    }

    const createAlternateExecutor = () =>{
        let relation = alternateExecutorRelationship === "In Laws" ? "IN-LAWS" :
        alternateExecutorRelationship === "Siblings" ? "SIBLING" :
        alternateExecutorRelationship === "Other Family Members" ? "OTH-FAM" :
        alternateExecutorRelationship === "Friends" ? "FRND" :
        alternateExecutorRelationship === "Colleagues" ? "COL" :
        alternateExecutorRelationship === "Doctor" ? "DOC" :
        alternateExecutorRelationship === "Lawyer" ? "LAW" :
        alternateExecutorRelationship === "Accountant" ? "ACC" :
        alternateExecutorRelationship === "Consultants" ? "TAX" : "IN-LAWS"
        let payload={
            userId,
            contactLookupCode: 'INDV',
            executorFlag : true,
            relationship:relation,
            ...getSplitName(alternateExecutorName),
            middleName:'',
            contHomeAddress1 : alternateExecutorAddress,
            custField5:true
        }
          let updatePayload = {
            relationship:relation,
            ...getSplitName(alternateExecutorName),
            middleName:'',
            contHomeAddress1 : alternateExecutorAddress,
            custField5:true,
        }
        alternateExecutorList.length === 0 ?
          (
            createContact(payload)
            .then(response => {
              console.log('Record Created Successfully');
              dispatchAddContact(response.data);
              setQuestion(question + 1)
              setDivName(`div${question + 1}`)
            })
            .catch(err => {
                console.log(err)
            })
          )
          :
          updateContactById( alternateExecutorList[0].id, updatePayload)
          .then(response => {
            console.log('Record Updated Successfully');
            alternateExecutorList[0].relationship === 'FATHER' || alternateExecutorList[0].relationship === 'MOTHER' || alternateExecutorList[0].relationship === 'SPOUSE' || alternateExecutorList[0].relationship === 'CHILD'
            ? dispatchEditFamily(response.data)
            : dispatchEditContact(response.data)
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
          }).catch(err => {
            console.log(err)
          })
    }
    

    const createBeneficiary = () =>{
    let contactPayload = {
        userId,
        contactLookupCode: 'INDV',
        ...getSplitName(alternateBenificiary),
        middleName:'',
      }
      createContact(contactPayload)
      .then((resp)=>{
          console.log(resp)
          let id = resp.data.id
          const body = [{ contacts: { id }, userId, receiverFlag:false }]
          createBeneficiaryFn(body)
          .then(res => {
            console.log('Record Created Successfully')
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
            dispatchCreateBeneficiary(res.data)
          })
          .catch(err => console.log(err.message))
      })
      .catch((error) => {
        console.log('error')
      })
    }
   
    const nextReligionButton = (toggleModal) =>{
        updateUserProfile()
        toggleModal()
    }
    const StayOnPageReligionButton = (toggleModal) =>{
        toggleModal()
    }
    const nextQuestion = () =>{
        if(question === 1)
            {
            isNaN(userProfileId)
            ?
            createUser()
            : callWillApi()
            }
            else if(question === 2)
            {
            !Object.keys(willDataList).length ? createWillApi() : updateWillApi()
            }
            else if(question === 3 || question=== 4)
            {
            updateUserProfile()
            }
            else if(question === 5 && religion === 'Muslim')
            {
            toggleMuslimReligion()
            }
            else if(question === 5 && religion === 'Christian')
            (
            toggleChristianReligion()
            )
            else if(question === 5 && religion === 'Parsi')
            (
            toggleParsiReligion()
            )
            else if(question === 5)
            {
            updateUserProfile()
            }
            else if(question === 7 || question === 8)
            {
            updateWillApi2(1)
            }
            else if(question === 9)
            {
            createFather()
            }
            else if(question === 10)
            {
            if(maritalStatus === 'Married' )
                {setQuestion(question + 1)
                    setDivName(`div${question + 1}`)
                }
                else{
                    setQuestion(question + 3)
                    setDivName(`div${question + 3}`)
                }
            }
            else if(question === 11)
            {
            createSpouse()
            }
            else if(question === 12 && childrens == 0)
            {
                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
            }
            else if(question === 12 && childrens !== 0)
            {
            createChild()
            }
            else if(question === 13)
            {
            createMother()
            }
            else if(question === 15 && executorType === 'Other')
            {
            createExecutor()
            }
            else if(question === 15 && (executorType === 'Family' || executorType === 'Contact'))
            {
            updateExecutorFlag()
            }
            else if(question === 18 && alternateExecutorType === 'Other')
            {
            createAlternateExecutor()
            }
            else if(question === 18 && (alternateExecutorType === 'Family' || alternateExecutorType === 'Contact'))
            {
            updateAlternateExecutorFlag()
            }
            else if(question === 24)
            {
            createBeneficiary()
            }
            else if(question === 36 || question === 37 || question === 38 || question === 21)
            {
            updateWillApi2(1)
            }
            else if(question === 39)
            {
                setQuestion(41)
                setDivName('div41')
            }
            else if(question === 41 && specialInstructions === 'Yes')
            {
                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
            }
            else if(question === 41 && specialInstructions === 'No')
            {
                setQuestion(question + 2)
                setDivName(`div${question + 2}`)
            }
            else if(question === 42)
            {
                updateWillApi2(1)
            }
            else if(question === 43)
            {
                setQuestion(49)
                setDivName('div49')
             }
            else if(question === 44)
            {
                updateWillApi3(45)
            }
            else if(question === 45)
            {
                updateWillApi3(13)
            }
            else if(question === 50 ||  question === 51)
            {
                updateWillApi2(1)
            }
            else
            {
                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
            }
        }
        const PreviousChildMinorQuestion = () =>{
            setQuestion(47)
            setDivName('div47')
        }
    const nextChildQuestion = () =>{
        if(assetDistribution === 'All to my living child or children')
        {
            setQuestion(34)
            setDivName('div34')
        }
        else
        {
            setQuestion(36)
            setDivName('div36')
        }
    }
    const previousMaritalStatus = () => {
        if(maritalStatus === 'Married')
        {
            if(childrens == 0) {
                setQuestion(question - 2)
                setDivName(`div${question - 2}`)
             } 
             else {
                 setQuestion(45)
                 setDivName('div45')
             }
            }
        else{
             setQuestion(question - 3)
             setDivName(`div${question - 3}`)
        }
    }
    const previousQuestion = () =>{
            if(question === 42)
            {
                setQuestion(41)
                setDivName('div41')
            }
            else if(question === 43 && specialInstructions === 'Yes')
            {
                setQuestion(question - 1)
                setDivName(`div${question - 1}`)
            }
            else if(question === 43 && specialInstructions === 'No')
            {
                setQuestion(question - 2)
                setDivName(`div${question - 2}`)
             }
            else if(question === 44)
            {
                setQuestion(12)
                setDivName('div12')
                setChild(0)
            }
            else if(question === 45)
            {
                setQuestion(44)
                setDivName('div44')
            }
            else if(question === 46 && exculdePerson === 'Yes')
            {
                setQuestion(33)
                setDivName('div33')
            }
            else if(question === 46)
            {
                setQuestion(29)
                setDivName('div29')
            }
            else
            {
                setQuestion(question - 1)
                setDivName(`div${question - 1}`)
            }
    }
    const next2Question = () =>{
        if(testatorRegister === 'Yes')  
        (updateWillApi2(1)) 
        else
        (updateWillApi2(3))
    }
    const previousExeQuestion = () =>{
        if(executorType === 'Family' || executorType === 'Contact')
        {
            setQuestion(15)
            setDivName('div15')
        }
        else{
        setQuestion(question - 1)
        setDivName(`div${question - 1}`)
        }
    }
    const previous2Question = () =>{
        if(testatorRegister === 'Yes')
        { 
        setQuestion(question - 1)
        setDivName(`div${question - 1}`)
        }
        else {
            setQuestion(question - 3)
             setDivName(`div${question -3}`)
        }
    }
    const nextExecutor = () =>{
        if(executor === 'Yes') {
            setQuestion(question + 1)
         setDivName(`div${question + 1}`)
    }
        else{
            setQuestion(21)
            setDivName('div21')
        }
    }
    /*const previousExecutor = () =>{
        if(executor === 'Yes'){
            setQuestion(question - 1)
             setDivName(`div${question - 1}`)
        }
        else{setQuestion(14)
             setDivName(14)}
    }*/
    const nextAlternateExecutor = () =>{
        if(alternateExecutor === 'Yes') 
        {
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
        }
        else{setQuestion(19)
             setDivName('div19')}
    }
    const previousAlternateExecutor = () =>{
        if(alternateExecutor === 'Yes')
        {
            setQuestion(question - 1)
             setDivName(`div${question - 1}`)
        } 
        else{
            setQuestion(17) 
            setDivName('div17')
        }
    }
    const nextExecutorCompensation = () =>{
        if(executorCompensation === 'Yes') {setQuestion(question + 1)
             setDivName(`div${question + 1}`)}
        else{setQuestion(21)
             setDivName('div21')}
    }
    const previousExecutorCompensation = () =>{
        if(executor === 'No')
        {
            setQuestion(14)
             setDivName('div14')
        }
        else{
        if(executorCompensation === 'Yes')
        {
            setQuestion(question - 1)
             setDivName(`div${question - 1}`)
        }
        else{ 
        if(alternateExecutor === 'Yes')
        {
            setQuestion(17)
            setDivName('div17')
        }
        else 
        {
            if(executor === 'Yes' )
            {setQuestion(14)}
            else{setQuestion(question - 1) 
                setDivName(`div${question - 1}`)
            }
        }
        }
        }
    }
    const nextAssetDistribution = () =>{
        if(assetDistributionType === 'Asset Distribution Tool')
        {
            setQuestion(36)
            setDivName('div36')
        }
        else if(assetDistribution === 'All to my spouse')
        {
        setQuestion(23)
        setDivName('div23')
        }
        else if(assetDistribution === 'All to my living child or children')
        {
        setQuestion(29)
        setDivName('div29')
        }
        else if(assetDistribution === 'All to one named beneficiary')
        {
        setQuestion(28)
        setDivName('div28')
        }
        else if(assetDistribution === 'Divided between multiple named beneficiaries in equal shares')
        {
        setQuestion(30)
        setDivName('div30')
        }
        else if(assetDistribution === 'Divided between multiple named beneficiaries in unequal shares')
        {
        setQuestion(31)
        setDivName('div31')
        }
        else if(assetDistribution === 'In an alternate method of distribution')
        {
        setQuestion(32)
        setDivName('div32')
         }
        else
        {
        setQuestion(question + 1)
        setDivName(`div${question + 1}`)
        }
        }
        const previousAssetDistribution = () =>{
            setQuestion(22)
            setDivName(22)
        }
       
        const nextAlternateAssetDistribution = () =>{
        if(alternateAssetDistribution === 'The children of the testator')
        {
        setQuestion(29)
        setDivName('div29')
        }
        else if(alternateAssetDistribution === 'An alternate named beneficiary')
        {
        setQuestion(24)
        setDivName('div24')
        }
        else if(alternateAssetDistribution === 'Multiple named beneficiaries in equal shares' )
        {
        setQuestion(25)
        setDivName('div25')
        }
        else if(alternateAssetDistribution === 'Multiple named beneficiaries in unequal shares' )
        {
        setQuestion(26)
        setDivName('div26')
        }
        else
        {
        setQuestion(23)
        setDivName('div23')
        }
        }
        const previousAlternateAssetDistribution = () =>{
            if(assetDistribution === 'All to my living child or children')
            {
                setQuestion(34)
                setDivName('div34')
            }
            else
            {
            setQuestion(23)
            setDivName('div23')
            }
        }
       
        const previousAlternateChildQuestion = () =>{
            setQuestion(35)
            setDivName('div35')
        }
        const nextMinorBenificiary = () =>{
            if(minorBeneficiaries === 'Yes')
            {
            setQuestion(47)
            setDivName('div47')
            }
            else{
                setQuestion(36)
                setDivName('div36')
            }
        }
        const PreviousMinorBenificiary = () =>{
            if(minorBeneficiaries === 'No')
            {
            setQuestion(43)
            setDivName('div43')
            }
            else{
                setQuestion(46)
                setDivName('div46')
            }
        }
        const previousAssetDistributions = () =>{
            if(assetDistribution === 'All to my spouse' && alternateAssetDistribution === 'The children of the testator')
            {
            setQuestion(23)
            setDivName('div23')
            }
            else
            {setQuestion(22)
            setDivName('div22')
            }
        }
        const nextChildExcludeQuestion = () =>{
            if(exculdePerson === 'Yes')
            {
            setQuestion(33)
            setDivName('div33')
            }
            else
            {setQuestion(46)
            setDivName('div46')
            }
        }
        const PreviousChildExcludeQuestion = () =>{
            setQuestion(29)
            setDivName('div29')
        }
        const nextExculdePersonQUestion = () =>{
           createDisinherit()
        }
        const PreviousTrustee = () =>{
                setQuestion(46)
                setDivName('div46')
        }
        const nextTrustee = () =>{
            if(appointTrusteeForMinor === 'the Executor (if applicable)')
            {
                setQuestion(36)
                setDivName('div36')
            }
            else
            {
            setQuestion(48)
            setDivName('div48')
            }
        }

        const nextChildDistribution = () =>{
            if(assetDistribution === 'All to my living child or children')
            {
                if(childAssetDistribution === 'An alternate named beneficiary')
                {
                setQuestion(24)
                setDivName('div24')
                }
                else if
                (childAssetDistribution === 'Multiple named beneficiaries in equal shares')
                        {
                        setQuestion(25)
                        setDivName('div25')
                        }
                    else

                        {
                        setQuestion(26)
                        setDivName('div26')
                        }
            }
            else
                {
                setQuestion(question + 1)
                setDivName(`div${question + 1}`)
                }
        }
        const previousExecutorQuestion = () =>{
            if(assetDistribution ==='All to my spouse' && alternateAssetDistribution === 'The children of the testator')
            {
            if(exculdePerson === 'No')
            {
                setQuestion(29)
                setDivName('div29')
            }
            else{
                setQuestion(33)
                setDivName('div33')
            }
            }
            else if(assetDistribution ==='All to my living child or children' && alternateAssetDistribution === 'The children of the testator')
            {
            if(exculdePerson === 'No')
            {
                setQuestion(29)
                setDivName('div29')
            }
            else{
                setQuestion(33)
                setDivName('div33')
            }
            }
            else
            {
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
            }
        }
        const previousExecutorExclude = () =>{
            if(assetDistributionType === 'Asset Distribution Tool')
            {
                setQuestion(22)
                setDivName('div22')
            }
            else if(assetDistribution ==='All to my spouse' && alternateAssetDistribution === 'The children of the testator')
            {
                    if(minorBeneficiaries === 'No')
                    {
                        setQuestion(46)
                        setDivName('div46')
                    }
                    
                    else if(minorBeneficiaries === 'Yes' && appointTrusteeForMinor === 'the Executor (if applicable)')
                    {
                    setQuestion(47)
                    setDivName('div47')
                    }
                    else
                    {
                    setQuestion(48)
                    setDivName('div48')
                    }
            }
            else if(assetDistribution ==='All to my spouse' && alternateAssetDistribution === 'An alternate named beneficiary')
            {
            setQuestion(24)
            setDivName('div24')
            }
            
            else if(assetDistribution ==='All to my spouse' && alternateAssetDistribution === 'Multiple named beneficiaries in equal shares')
            {
            setQuestion(25)
            setDivName('div25')
            }
            else if(assetDistribution ==='All to my spouse' && alternateAssetDistribution === 'Multiple named beneficiaries in unequal shares')
            {
            setQuestion(26)
            setDivName('div26')
            }
           else if(assetDistribution ==='All to my living child or children')
            {
                    if(childAssetDistribution === 'An alternate named beneficiary')
                    {
                    setQuestion(24)
                    setDivName('div24')
                    }
                    else if(childAssetDistribution === 'Multiple named beneficiaries in equal shares')
                    {
                    setQuestion(25)
                    setDivName('div25')
                    }
                    else if(childAssetDistribution ===  'Multiple named beneficiaries in unequal shares')
                    {
                    setQuestion(26)
                    setDivName('div26')
                    }
                    else
                    {
                    setQuestion(25)
                    setDivName('div25')
                    }
            }
            else{
            setQuestion(25)
            setDivName('div25')
            }
    }
        const nextAssetQuestions = () =>{
            setQuestion(36)
            setDivName('div36')
        }
        const nextAlternateBenificiary = () =>{
            if(benificiaryForAssets === 'The children of the testator')
            {
            setQuestion(29)
            setDivName('div29')
            }
            else if(benificiaryForAssets === 'An alternate named beneficiary')
            {
            setQuestion(24)
            setDivName('div24')
            }
            else if(benificiaryForAssets === 'Multiple named beneficiaries in equal shares')
            {
            setQuestion(25)
            setDivName('div25')
            }
            else if(benificiaryForAssets === 'Multiple named beneficiaries in unequal shares')
            {
            setQuestion(26)
            setDivName('div26')
            }
            else
            {
            setQuestion(23)
            setDivName('div23')
            }
            }
        const nextBenQuestion = () =>{
                setQuestion(27)
                setDivName('div27')
            }
        const previousBenQuestion = () =>{
            setQuestion(30)
            setDivName('div30')
        }
        const nextDeathQuestion = () =>{
            if(testatorDeath === 'No')
            {
            setQuestion(52)
            setDivName('div52')
            }
            else
            {
            setQuestion(question + 1)
            setDivName(`div${question + 1}`)
            }
        }
        
    const ClaimDocument = () =>{
        return(
            <div>
                <div  ref={ref1}>
                    <p style={{textAlign: 'left'}}><strong>Welcome to EasyClaims One-Step.</strong></p>
                    <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>We understand the hassles involved in submitting claims after a bereavement. EasyClaims One-Step helps you reduce the paperwork and tediousness from the process. The following information will help our 
                        claims processors understand your situation and start creating an appropriate docket for you. 

                    <br></br><br></br>Feel free to connect with us anytime you have a question. We are always happy to help.<br></br></p>
                </div>

<div ref={ref6}>
{listAssets === 'Pick from Asset Register' || assetDetails !== '' ?
<p><p>I may inherit the following assets and properties:</p>
<p><span style={{ backgroundColor: divname !== 'div21' ? '#fff': 'yellow', whiteSpace: 'pre-wrap'}}>

{ listAssets === 'Pick from Asset Register'  ?
    <div>
    
    </div>
    :
    assetDetails  === ''?'________' : assetDetails
}</span></p>
</p>
: null
}
</div>
</div>

)
}
    const WillDocs = () => {
    return(
        <div>
                <br></br><br></br>
		        <p>WillDocs object return section. Have to figure how to use this.</p>
        </div> 
    )
    }
    
    return(
    <div >
    <div className='row'>
    <div className='col-xl-12 col-md-12 col-lg-12'>
    <HelpSection heading="HELP TIPS ON CLAIMS!">
    <List>
        The first step in making a claim is accurate information. Talk to us if you ever have a doubt.
   </List>
   <List>
        <HighlightedText>Identify claimable assets -- Review eligibility -- Print, Sign and Witness the final docket -- Keep copies -- Submit early </HighlightedText>
    </List>
     </HelpSection>
     </div>
     </div>
<div style={{paddingBottom:'50px'}} className='row'>
<div className='col-sm-3'>				
    <div style={{ backgroundColor:'#fff',paddingLeft:'10px', paddingRight:'10px',  paddingTop:'20px',height:500}}>
            {question === 0 ? 
            <div>    
                 <label>
                    <b>Question number {question} : Where would you sign the Will ?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setPlace}
                    placeholder="E.g. Nagpur, Maharashtra" 
                  />
                    <label>
                    <b>When do you plan to sign the will ?</b>
                    </label>
                    <Input 
                    noClass
                    isBigger 
                    type='date' 
                    bind={setWillDate} 
                    placeholder="mm-dd-yyyy" 
                    />
                    <label>
                    <b>And lastly, your full legal name.</b>
                    </label>
                    <Input
                        noClass
                        type='text'
                        className='form-control form-control-lg'
                        bind={setNameOfUser}
                        placeholder="E.g. Manik Chand, Sarita Verma" 
                    />


                    <ContainerPosition>
                        <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    </ContainerPosition>

                    <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>The first step to a valid Will is correct personal information.</li>
                        </p>
                    </div>


            </div>
            : null}

            {question === 1 ? 
            <div>
                    {scroll(ref1)}
                    {isNaN(userProfileId) ?
                    <div>
                    <label>
                    <b>Question no. {question} :What's your e-mail ?</b>
                    </label>
                    <Input
                        noClass
                        type='text'
                        className='form-control form-control-lg'
                        bind={setEmail}
                        placeholder="E.g. Manik@gmail.com" 
                    />
                    <label>
                    <b>Question no. {question} :What is your Phone Number ?</b>
                    </label>
                    <Input
                        noClass
                        type='text'
                        className='form-control form-control-lg'
                        bind={setPhoneNum}
                        placeholder="E.g. Enter 10 digit number" 
                    />
                    </div>
                    : null
                    }
                    <label> 
                        <b>Question no. {question} :What is your gender ?</b>
                    </label>
                    <div className='row' style={{ marginLeft: 5 }}>
                        <div className='form-check form-check-inline'>
                        <label className='form-check-label'>
                            <input
                            className='form-check-input'
                            type='radio'
                            checked={gender === 'Male'}
                            name={'inlineRadiogender'}
                            id={'inlineRadiogender'}
                            value={'Male'}
                            onChange={(event) => setGender(event.target.value)}
                            />
                            Male
                        </label>
                        </div>
                        <div className='form-check form-check-inline'>
                        <label className='form-check-label'>
                            <input
                            className='form-check-input'
                            type='radio'
                            checked={gender === 'Female'}
                            name={'inlineRadiogender'}
                            id={'inlineRadiogender'}
                            value={'Female'}
                            onChange={(event) => setGender(event.target.value)}
                            />
                            Female
                        </label>
                        </div>
                    </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Succession laws have gender specific provisions. Watch how it changes the text in the Will.</li>
                        </p>
                    </div>
            </div>             
            : null}

            {question === 2 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>Question no. {question} :What is your father's name ?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setFatherName}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                   <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Strange as they sound, the law tries to identify you as accurately as possible.</li>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Family information is important when determining succession of ancestral property. 
                                            Make sure you have provided full and accurate info.</li>
                        </p>
                    </div>



            </div> : null}

            {question === 3 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>Question no. {question} :Are you currently married ?</b>
                </label>
                  <DropDown
                    noClass
                    placeholder='Please Select'
                    options={maritalStatusOpt}
                    bind={setMaritalStatus}
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Marital status matters. For example, by law members of certain religious communities need to re-write their wills if they change their marital status.</li>
                            <div align= 'center'><Button onClick={appointment} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'50%' }}>
                            {'Talk to our lawyer'}
                            </Button></div>
                            <br></br>
                        </p>
                    </div>


            </div> : null}

            {question === 4 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>Question no. {question} :What is your date of birth ?</b>
                  </label>
                    <Input 
                    noClass
                    isBigger 
                    type='date' 
                    bind={setAge} 
                    placeholder="mm-dd-yyyy" 
                    />
                <label>
                    <b>Question no. {question} :What is your address ?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setAddress}
                    placeholder="E.g. No.6 Bangalore 560067" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Build your INHERITANCE PORTFOLIO to keep all information in one easy location.</li>
                            <br></br>
                        </p>
                    </div>
            </div> 
            
            : null}

            {question === 5 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>Question no. {question} :What is your Religion ?</b>
                </label>
                  <DropDown
                    noClass
                    placeholder='Please Select'
                    options={getCodeValueMap(['Hindu', 'Buddhist', 'Sikh', 'Jain', 'Christian', 'Jewish', 'Parsi', 'Muslim', 'Other'])}
                    bind={setReligion}
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>It's a sensitive topic for some. But the law handles matters differently for each faith.</li>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>If you wish to know more or have a situation, use our <b>FREE</b> legal consult.</li>
                            <div align= 'center'><Button onClick={appointment} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'50%' }}>
                            {'Talk to our lawyer'}
                            </Button></div>
                        </p>
                    </div>
                
            </div> : null}

            {question === 6 ?
            <div>
                {scroll(ref1)}
                <label> 
                    <b>Question no. {question} :Did you ever sign and register any will(s) previously ?</b>
                  </label>
                  <div className='row' style={{ marginLeft: 5 }}>
                    <div className='form-check form-check-inline'>
                      <label className='form-check-label'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={testatorRegister === 'Yes'}
                          name={'inlineRadiotestator'}
                          id={'inlineRadiotestator'}
                          value={'Yes'}
                          onChange={(event) => setTestatorRegister(event.target.value)}
                        />
                        Yes
                      </label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <label className='form-check-label'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={testatorRegister === 'No'}
                          name={'inlineRadiotestator'}
                          id={'inlineRadiotestator'}
                          value={'No'}
                          onChange={(event) => setTestatorRegister(event.target.value)}
                        />
                        No
                      </label>
                    </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => next2Question()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>The latest REGISTERED will supercedes earlier wills. However, this is also the most contested topic among beneficiaries.</li>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>To avoid future legal challenges, use our legal consult. Your FIRST HALF-HOUR is <b>FREE</b>.</li>
                        </p>
                    </div>
            </div> : null}

            {question === 7 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>Question no. {question} :What is the date of the earlier will which was registered with the Sub-registrar?</b>
                    </label>
                    <Input 
                    noClass
                    isBigger 
                    type='date' 
                    bind={setSubRegistrarDate} 
                    placeholder="mm-dd-yyyy" 
                    />

                    <label>
                    <b>Question no. {question} :What are the registration details of the earlier will which was registered with the Sub-registrar?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setRegistrationDetails}
                    placeholder="E.g. 1234/12" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

            </div> 
                   
            : null}

            {question === 8 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>Question no. {question} :When was the earlier will registered at the Sub-Registrar's office?</b>
                    </label>
                    <Input 
                    noClass
                    isBigger 
                    type='date' 
                    bind={setEarlierDateOfRegistration} 
                    placeholder="mm-dd-yyyy" 
                  />
             <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 9 ?
            <div>
                {scroll(ref1)}
                {maritalStatus ==='single' ?
                <div>
                <label>
                    <b>uestion no. {question} :Do you have children ?</b>
                </label>
                <DropDown
                    noClass
                    placeholder='Please Select'
                    options={numChildrens}
                    bind={setchildNumber}
                />
                </div>
                : null}
                <label> 
                <b>Question no. {question} :We need to know a bit about your parents. Do you have a surviving father ?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={survivingFather === 'Yes'}
                    name={'inlineRadioSurvivingFather'}
                    id={'inlineRadioSurvivingFather'}
                    value={'Yes'}
                    onChange={(event) => setSurvivingFather(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={survivingFather === 'No'}
                    name={'inlineRadioSurvivingFather'}
                    id={'inlineRadioSurvivingFather'}
                    value={'No'}
                    onChange={(event) => setSurvivingFather(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previous2Question()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 10 ?
            <div>
                {scroll(ref1)}
                <label> 
                <b>Question no. {question} :Do you have a surviving mother?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={survivingMother === 'Yes'}
                    name={'inlineRadioSurvivingMother'}
                    id={'inlineRadioSurvivingMother'}
                    value={'Yes'}
                    onChange={(event) => setSurvivingMother(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={survivingMother === 'No'}
                    name={'inlineRadioSurvivingMother'}
                    id={'inlineRadioSurvivingMother'}
                    value={'No'}
                    onChange={(event) => setSurvivingMother(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 11 ?
            <div>
                {scroll(ref1)}
                <label>
                    <b>Question no. {question} :What is the name of your spouse ?</b>
                </label>
                <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setSpouseName}
                    placeholder="E.g. Priya Khanna" 
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Immediate family are usually the beneficiaries of your will. Accurate information helps them through the Succession process easily.</li>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Did you notice we are gradually building up your <b>Inheritance Portfolio</b>? Your info is safe with us for your beneficiaries when they need it.</li>
                        </p>
                    </div>


            </div> : null}

            {question === 12 ?
            <div>
                {scroll(ref2)}
                <label>
                    <b>Question no. {question} :What is the name of your {childNum} child?</b>
                </label>
                <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    onChange={(event) => setCurrentChildName(event.target.value)}
                    bind={setChildName}
                    placeholder="E.g. Priya Khanna" 
                />
                <label>
                    <b>Question no. {question} :Where does your {childNum} child live?</b>
                </label>
                <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    onChange={(event) => setCurrentChildAddress(event.target.value)}
                    bind={setChildAddress}
                    placeholder="E.g. No. 6 Bangalore" 
                />
                <label>
                    <b>Question no. {question} :What is your {childNum} childs Date of Birth?</b>
                </label>
                <Input 
                    noClass
                    isBigger 
                    type='date' 
                    onChange={(event) => setCurrentChildDob(event.target.value)}
                    bind={setChildDob}
                    placeholder="mm-dd-yyyy" 
                    />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Pay special attention if any of your children are <b>minors</b>( i.e below 18 yrs of age ).</li>
                        </p>
            </div>

                
            </div> : null}

            {question === 13 ?
            <div>
                {scroll(ref1)}
                 <label>
                    <b>Question no. {question} :What is your mother's name?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setMotherName}
                    placeholder="E.g. Waheeda Khan" 
                  />
                   <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousMaritalStatus()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 14 ?
            <div>
                {scroll(ref3)}
                <label> 
                <b>Question no. {question} :Do you wish to appoint an executor for the will? </b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                        <div className='form-check form-check-inline'>
                        <label className='form-check-label'>
                            <input
                            className='form-check-input'
                            type='radio'
                            checked={executor === 'Yes'}
                            name={'inlineRadioExecutor'}
                            id={'inlineRadioExecutor'}
                            value={'Yes'}
                            onChange={(event) => setExecutor(event.target.value)}
                            />
                            Yes
                        </label>
                        </div>
                        <div className='form-check form-check-inline'>
                        <label className='form-check-label'>
                            <input
                            className='form-check-input'
                            type='radio'
                            checked={executor === 'No'}
                            name={'inlineRadioExecutor'}
                            id={'inlineRadioExecutor'}
                            value={'No'}
                            onChange={(event) => setExecutor(event.target.value)}
                            />
                            No
                        </label>
                        </div>
                </div>
                
                {executor === 'Yes' ?
                <div>
                  <label>
                  <b>CQuestion no. {question} :hoose an Executor</b>
                </label>
                 <DropDown
                 noClass
                 placeholder='Please Select'
                 options={executorTypeOpt}
                 bind={setExecutorType}
               />
               </div>
                : null}
                <ContainerPosition>
                    <CustomButtom event={() => nextExecutor()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>The executor is a person who will administer the Will. It can be anyone you trust, but executors have a lot of RESPONSIBILITY like paying any <b>debts</b> and <b>taxes</b>.</li>
                        </p>
            </div>
            </div> 
            
            : null}

            {question === 15 ?
            <div>
                 {scroll(ref4)}

                {executorType === 'Other' ?
                <div>
                <label>
                    <b>Name of the executor. It has to be someone you can trust and someone willing to take the responsibility.</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setExecutorName}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                  </div>
                  :
                  executorType === 'Family'?
                  
                  <div>
                   <label>
                    <b>72% of our users choose family. Fill Family details in the Inheritance Portfolio to choose here.</b>
                  </label>
                       <thead>
                            <tr>
                            <th data-field="CheckBox">&nbsp;&nbsp;</th>
                            <th data-field="Name"><font size='2'>Name and&nbsp;</font></th>
                            <th data-field="Relationship"><font size='2'>Relationship</font></th>
                            </tr>
                        </thead>
                      {familyListExecutor.map((f, i) =>
                      <div>
                        <tr key={i} className="col-xl-3 col-md-6 col-lg-4 col-xs-12">
                            <td data-field="CheckBox"><input type="checkbox"
                            checked={_.includes(selectedIds, f.id)}
                            onChange={(event) => {
                                if (event.target.checked) {
                                bindSelected([f.id])
                                } else {
                                bindSelected(selectedIds.filter(id => id !== f.id))
                                }
                            }}
                            />&nbsp;&nbsp;
                            </td>
                            <td data-field="Name">{getFullName(f)},</td>
                            <td data-field="Relationship">{relationshipMapping[f.relationship]}</td>
                        </tr>
                    </div>
                      )
                    }
                  </div>
                   : 
                   executorType === 'Contact'?
                   <div>
                  <label>
                    <b>25% of our users choose trusted friends. Don't see a name? Fill Other Contacts in the Inheritance Portfolio.</b>
                  </label>
                   <thead>
                        <tr>
                        <th data-field="CheckBox"></th>
                        <th data-field="Name"><font size='2'>Name and&nbsp;</font></th>
                        <th data-field="Relationship"><font size='2'>Relationship</font></th>
                        </tr>
                    </thead>
                  {contactListExecutor.map((f, i) =>
                  <div>
                    <tr key={i} className="col-xl-3 col-md-6 col-lg-4 col-xs-12">
                        <td data-field="CheckBox"><input type="checkbox"
                        checked={_.includes(selectedIds, f.id)}
                        onChange={(event) => {
                            if (event.target.checked) {
                            bindSelected([f.id])
                            } else {
                            bindSelected(selectedIds.filter(id => id !== f.id))
                            }
                        }}
                        />&nbsp;&nbsp;
                        </td>
                        <td data-field="Name">{getFullName(f)},</td>
                        <td data-field="Relationship">{relationshipMapping[f.relationship]}</td>
                    </tr>
                </div>
                  )
                }
              </div>
                   :
                   null
            }
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Choose wisely. If you have a running LOAN, your Executor will have to negotiate its closure to free up your assets.</li>
                        </p>
                    </div>
            </div> : null}

            {question === 16 ?
            <div>
                {scroll(ref3)}      
                <label>
                    <b>Relationship of the executor in regards the testator.</b>
                </label>
                    <DropDown
                    noClass
                    placeholder='Please Select'
                    options={relationOptions}
                    bind={setExecutorRelationship}
                  />
                <label>
                    <b>What is the residential address of the executor?</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setExecutorAddress}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Do you know you can appoint a THIRD-PARTY ( like a legal firm ) as your Executor ? Talk to us.</li>
                            <div align= 'center'><Button onClick={appointment} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'50%' }}>
                            {'Talk to our lawyer'}
                            </Button></div>
                        </p>
                 </div>
            </div> : null}

            {question === 17 ?
            <div>
                 {scroll(ref3)}
                <label> 
                    <b>Do you wish to appoint any alternate executor ? </b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                        <div className='form-check form-check-inline'>
                        <label className='form-check-label'>
                            <input
                            className='form-check-input'
                            type='radio'
                            checked={alternateExecutor === 'Yes'}
                            name={'inlineRadioAlternateExecutor'}
                            id={'inlineRadioAlternateExecutor'}
                            value={'Yes'}
                            onChange={(event) => setAlternateExecutor(event.target.value)}
                            />
                            Yes
                        </label>
                        </div>
                        <div className='form-check form-check-inline'>
                        <label className='form-check-label'>
                            <input
                            className='form-check-input'
                            type='radio'
                            checked={alternateExecutor === 'No'}
                            name={'inlineRadioAlternateExecutor'}
                            id={'inlineRadioAlternateExecutor'}
                            value={'No'}
                            onChange={(event) => setAlternateExecutor(event.target.value)}
                            />
                            No
                        </label>
                        </div>
                    </div>    
                {alternateExecutor === 'Yes' ?
                <div>
                  <label>
                  <b>Choose an Alternate Executor</b>
                </label>
                 <DropDown
                 noClass
                 placeholder='Please Select'
                 options={executorTypeOpt}
                 bind={setAlternateExecutorType}
               />
               </div>

                : null}

                <ContainerPosition>
                    <CustomButtom event={() => nextAlternateExecutor()} title={'Next'} />
                    <CustomButtom event={() => previousExeQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Always have a Plan B.</li>
                        </p>
                 </div>
                
            </div> 
            
            : null}

            {question === 18 ?
            <div>
                 {scroll(ref3)}
                 {alternateExecutorType === 'Other' ?
                 <div>
                <label>
                    <b>Name of the alternate executor.</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setAlternateExecutorName}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />

                <label>
                    <b>Your relationship to the alternate executor.</b>
                </label>
                <DropDown
                    noClass
                    placeholder='Please Select'
                    options={relationOptions}
                    bind={setAlternateExecutorRelationship}
                  />
                <label>
                    <b>What is the residential address of the alternate executor?</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setAlternateExecutorAddress}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                  </div>
                    :
                    alternateExecutorType === 'Family'?
                  <div>
                                     <label>
                    <b>35% of our users choose Family. Don't see a name? Fill Other Contacts in the Inheritance Portfolio.</b>
                  </label>
                       <thead>
                            <tr>
                            <th data-field="CheckBox"></th>
                            <th data-field="Name">Name and </th>
                            <th data-field="Relationship">Relationship</th>
                            </tr>
                        </thead>
                      {familyListExecutor.map((f, i) =>
                      <div>
                        <tr key={i} className="col-xl-3 col-md-6 col-lg-4 col-xs-12">
                            <td data-field="CheckBox"><input type="checkbox"
                            checked={_.includes(alternateSelectedIds, f.id)}
                            onChange={(event) => {
                                if (event.target.checked) {
                                bindAlternateSelectedIds([f.id])
                                } else {
                                bindAlternateSelectedIds(alternateSelectedIds.filter(id => id !== f.id))
                                }
                            }}
                            />
                            </td>
                            <td data-field="Name">{getFullName(f)},</td>
                            <td data-field="Relationship">{relationshipMapping[f.relationship]}</td>
                        </tr>
                    </div>
                      )
                    }
                  </div>
                   : 
                   alternateExecutorType === 'Contact'?
                   <div>
                                      <label>
                    <b>22% of our users trust friends. Don't see a name ? Fill Other Contacts in the Inheritance Portfolio.</b>
                  </label>
                   <thead>
                        <tr>
                        <th data-field="CheckBox"></th>
                        <th data-field="Name">Name and </th>
                        <th data-field="Relationship">Relationship</th>
                        </tr>
                    </thead>
                  {contactListExecutor.map((f, i) =>
                  <div>
                    <tr key={i} className="col-xl-3 col-md-6 col-lg-4 col-xs-12">
                        <td data-field="CheckBox"><input type="checkbox"
                        checked={_.includes(alternateSelectedIds, f.id)}
                        onChange={(event) => {
                            if (event.target.checked) {
                                bindAlternateSelectedIds([f.id])
                            } else {
                                bindAlternateSelectedIds(alternateSelectedIds.filter(id => id !== f.id))
                            }
                        }}
                        />
                        </td>
                        <td data-field="Name">{getFullName(f)},</td>
                        <td data-field="Relationship">{relationshipMapping[f.relationship]}</td>
                    </tr>
                </div>
                  )
                }
              </div>
                   :
                   null
            }
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Remember you can always appoint a THIRD-PARTY ( like a legal firm ) as your Executor ?</li>
                        </p>
                 </div>
            </div> 
            
            : null}

            {question === 19 ?
            <div>
                 {scroll(ref4)}
                <label>
                    <b>Do you wish to compensate the executor?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={executorCompensation === 'Yes'}
                    name={'inlineRadioExecutorCompensation'}
                    id={'inlineRadioExecutorCompensation'}
                    value={'Yes'}
                    onChange={(event) => setExecutorCompensation(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={executorCompensation === 'No'}
                    name={'inlineRadioExecutorCompensation'}
                    id={'inlineRadioExecutorCompensation'}
                    value={'No'}
                    onChange={(event) => setExecutorCompensation(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextExecutorCompensation()} title={'Next'} />
                    <CustomButtom event={() => previousAlternateExecutor()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>A lawyer or a law firm acting as your Executor would need compensation.</li>
                        </p>
                    </div>
                
            </div> : null}

            {question === 20 ?
            <div>
                 {scroll(ref4)}
                <label>
                    <b>(Optional) You can specify the compensation of the executor.</b>
                </label>
                <TextArea
                onkeypress
                bind={setExecutorCompensionDetails}
                placeholder="E.g. Rs. 10,000; 3% of the value of the entire estate" 
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 21 ?
            <div>
                 {scroll(ref6)}
                 <label>
                    <b>(Optional) Please list your assets and properties. If you do not want to specifically list all the assets and properties, this field can be left blank.</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                {/*<div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={listAssets === 'Pick from Asset Register'}
                    name={'inlineRadioListAssets'}
                    id={'inlineRadioListAssets'}
                    value={'Pick from Asset Register'}
                    onChange={(event) => setListAssets(event.target.value)}
                    />
                    Pick from Asset Register
                </label>
              </div>*/}
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={listAssets === 'Enter All Assets'}
                    name={'inlineRadioListAssets'}
                    id={'inlineRadioListAssets'}
                    value={'Enter All Assets'}
                    onChange={(event) => setListAssets(event.target.value)}
                    />
                    Enter All Assets
                </label>
                </div>
                </div>
                {listAssets === 'Enter All Assets'
                ?
                <div>
                <TextArea
                onkeypress
                placeholder="E.g. 1. Residential property situated at 12,Rajinder Nagar, New Delhi, India; 
                2. Shop bearing number A-5 situated at Vyapar Kendra, Gurgaon, Harayana; 
                3. Maruti Baleno car bearing registration number DL 3456."
                bind={setAssetDetails} 
                />
                </div>
                :
                _.isEmpty(userRole) 
                ?
                <p>Fill the Asset Register <span onClick={toggleLogin} style={{color:'#007bff',cursor:'pointer'}}>here</span></p>
                : null 
                }
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousExecutorCompensation()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 22 ?
            <div>
                 {scroll(ref6)}
                <label>
                    <b>How will your assets and properties be distributed?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                {/*<div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={assetDistributionType === 'Asset Distribution Tool'}
                    name={'inlineRadioAssetDistributionType'}
                    id={'inlineRadioAssetDistributionType'}
                    value={'Asset Distribution Tool'}
                    onChange={(event) => setAssetDistributionType(event.target.value)}
                    />
                    Asset Distribution Tool
                </label>
               </div>*/}
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={assetDistributionType === 'Asset Distribution'}
                    name={'inlineRadioAssetDistributionType'}
                    id={'inlineRadioAssetDistributionType'}
                    value={'Asset Distribution'}
                    onChange={(event) => setAssetDistributionType(event.target.value)}
                    />
                    Asset Distribution
                </label>
                </div>
                </div>
                {assetDistributionType === 'Asset Distribution' ?
                <div>
                  <DropDown
                    noClass
                    placeholder='Please Select'
                    options={assetDistributionOpt}
                    bind={setAssetDistribution}
                  />
                  </div>
                :
                <p>Distribution Tool <span onClick={toggleAssetDescriptionToo1} style={{color:'#007bff',cursor:'pointer'}}>here</span></p>
                }
                <ContainerPosition>
                    <CustomButtom event={() => nextAssetDistribution()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 23 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>In the event that your spouse does not survive you, who should receive the estate (i.e. assets and properties)?</b>
                </label>
                  <DropDown
                    noClass
                    placeholder='Please Select'
                    options={alternateAssetDistributionOpt}
                    bind={setAlternateAssetDistribution}
                  />
                 <ContainerPosition>
                    <CustomButtom event={() => nextAlternateAssetDistribution()} title={'Next'} />
                    <CustomButtom event={() => previousAssetDistribution()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 24 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Name of the alternate beneficiary.</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setAlternateBenificiary}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextAssetQuestions()} title={'Next'} />
                    <CustomButtom event={() => previousAlternateAssetDistribution()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Various Succession Acts in India deal with this differently. Talk to our lawyers for your specific case.</li>
                        </p>
                    </div>
            </div> : null}

            {question === 25 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Names of the alternate beneficiaries who will receive the assets and properties in equal parts.</b>
                </label>
                <TextArea
                onkeypress
                placeholder="Dev, Arati"
                bind={setMultipleAlternateBenificiary} 
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextAssetQuestions()} title={'Next'} />
                    <CustomButtom event={() => previousAlternateAssetDistribution()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 26 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Names of the alternate beneficiaries who will receive the assets and properties of the estate in unequal parts.</b>
                </label>
                <TextArea
                onkeypress
                placeholder="Dev, Arati"
                bind={setMultipleAlternateBenificiaryUnequal} 
                />
                <label>
                        <b>Description of how the assets and properties will be split between the multiple beneficiaries.</b>
                </label>
                    <TextArea
                    onkeypress
                    placeholder="Dev, Arati"
                    bind={setMultipleAlternateBenificiaryUnequalShare} 
                    />
                <ContainerPosition>
                    <CustomButtom event={() => nextAssetQuestions()} title={'Next'} />
                    <CustomButtom event={() => previousAlternateAssetDistribution()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 27 ?
                <div>
                    <label>
                    <b>If a named beneficiary predeceases the testator, should the children of the beneficiary receive their part?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={benPredeceases === 'Yes'}
                    name={'inlineRadioBenPredeceases'}
                    id={'inlineRadioBenPredeceases'}
                    value={'Yes'}
                    onChange={(event) => setBenPredeceases(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={benPredeceases === 'No'}
                    name={'inlineRadioBenPredeceases'}
                    id={'inlineRadioBenPredeceases'}
                    value={'No'}
                    onChange={(event) => setBenPredeceases(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextAssetQuestions()} title={'Next'} />
                    <CustomButtom event={() => previousBenQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 28 ?
                <div>
                    {scroll(ref6)}
                    <label>
                    <b>Name of the beneficiary of the assets and properties of the estate of the testator.</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setBenificiaryForAssets}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                <label>
                    <b>If the named beneficiary does not survive the testator, who should receive the assets and properties of the estate?</b>
                </label>
                <DropDown
                    noClass
                    placeholder='Please Select'
                    options={benificiaryOpt}
                    bind={setBenificiaryForAssetsOpt}
                />
                    <ContainerPosition>
                        <CustomButtom event={() => nextAlternateBenificiary()} title={'Next'} />
                        <CustomButtom event={() => previousAssetDistribution()} title={'Previous'} />
                    </ContainerPosition>
            </div> : null}

            {question === 29 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Is there any child or other person to be specifically excluded from the will?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={exculdePerson === 'Yes'}
                    name={'inlineRadioExculdePerson'}
                    id={'inlineRadioExculdePerson'}
                    value={'Yes'}
                    onChange={(event) => setExculdePerson(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={exculdePerson === 'No'}
                    name={'inlineRadioExculdePerson'}
                    id={'inlineRadioExculdePerson'}
                    value={'No'}
                    onChange={(event) => setExculdePerson(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextChildExcludeQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousAssetDistributions()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 30 ?
                <div>
                    {scroll(ref6)}
                    <label>
                        <b>Names of the beneficiaries who will receive equal shares of the assets and properties of the estate.</b>
                    </label>
                    <TextArea
                    onkeypress
                    placeholder="Dev, Arati"
                    bind={setDivideMultipleAlternateBenificiaryequalShare} 
                    />
                    <ContainerPosition>
                        <CustomButtom event={() => nextBenQuestion()} title={'Next'} />
                        <CustomButtom event={() => previousAssetDistribution()} title={'Previous'} />
                    </ContainerPosition>
            </div> : null}

            {question === 31 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Names of the beneficiaries who will inherit the assets and properties of the testator's estate.</b>
                </label>
                <TextArea
                onkeypress
                placeholder="Dev, Arati"
                bind={setDivideMultipleAlternateBenificiaryUnequal} 
                />
                <label>
                    <b>Description of how the assets and properties of the estate of the testator will be split between the multiple beneficiaries.</b>
                </label>
                    <TextArea
                    onkeypress
                    placeholder="Dev, Arati"
                    bind={setDivideMultipleAlternateBenificiaryUnequalShare} 
                    />
                <ContainerPosition>
                    <CustomButtom event={() => nextBenQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousAssetDistribution()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 32 ?
            <div>
                {scroll(ref6)}
                <label>
                     <b>Description of who will inherit the assets and properties of the testator's estate.</b>
                </label>
                    <TextArea
                    onkeypress
                    placeholder="Dev, Arati"
                    bind={setDescriptionWillInherit} 
                    />
                    <ContainerPosition>
                        <CustomButtom event={() => nextAssetQuestions()} title={'Next'} />
                        <CustomButtom event={() => previousAssetDistribution()} title={'Previous'} />
                    </ContainerPosition>
            </div> : null}


            {question === 33 ?
                <div>
                 {scroll(ref6)}
                <label>
                    <b>What is the name of the person who is to be excluded from inherting any assets or properties of the testator under the will?</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setExculdePersonName}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextExculdePersonQUestion()} title={'Next'} />
                    <CustomButtom event={() => PreviousChildExcludeQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}


            {question === 34 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>If all the children and grandchildren predecease the testator and leave no issue, who should receive the assets of the estate?</b>
                </label>
                <DropDown
                    noClass
                    placeholder='Please Select'
                    options={childAssetDistributionOpt}
                    bind={setChildAssetDistribution}
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextChildDistribution()} title={'Next'} />
                    <CustomButtom event={() => previousAlternateChildQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 35 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>What is the name of the person who is being appointed as trustee for the trust set up for minor beneficiaries (i.e. person who will manage the property left for minor beneficiaries till attain majority)?</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setTrusteeName}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextChildQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousExecutorQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 36 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Do you have any bank accounts?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={bankAccount === 'Yes'}
                    name={'inlineRadioBankAccount'}
                    id={'inlineRadioBankAccount'}
                    value={'Yes'}
                    onChange={(event) => setBankAccount(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={bankAccount === 'No'}
                    name={'inlineRadioBankAccount'}
                    id={'inlineRadioBankAccount'}
                    value={'No'}
                    onChange={(event) => setBankAccount(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                
                <label>
                    <b>Do you have any fixed deposits which are to be distributed?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={fixedDeposits === 'Yes'}
                    name={'inlineRadioFixedDeposits'}
                    id={'inlineRadioFixedDeposits'}
                    value={'Yes'}
                    onChange={(event) => setFixedDeposits(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={fixedDeposits === 'No'}
                    name={'inlineRadioFixedDeposits'}
                    id={'inlineRadioFixedDeposits'}
                    value={'No'}
                    onChange={(event) => setFixedDeposits(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousExecutorExclude()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>VERY IMPORTANT. Nomination registered on your Bank accounts, FD, PF, Shares etc SUPERCEDE your will. Please ensure there are no conflicts between Nominees and Beneficiaries.</li>
                        </p>
                    </div>


            </div> : null}

            {question === 37 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Do you have any lockers in the bank or elsewhere whose contents are to be distributed to beneficiaries?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={bankLocker === 'Yes'}
                    name={'inlineRadioBankLocker'}
                    id={'inlineRadioBankLocker'}
                    value={'Yes'}
                    onChange={(event) => setBankLocker(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={bankLocker === 'No'}
                    name={'inlineRadioBankLocker'}
                    id={'inlineRadioBankLocker'}
                    value={'No'}
                    onChange={(event) => setBankLocker(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>VERY IMPORTANT. Nominees registered on your Bank locker will get first access.</li>
                        </p>
                    </div>
            </div> : null}

            {question === 38 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Do you have any public provident fund accounts which are to be distrbuted to beneficiaries?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={pf === 'Yes'}
                    name={'inlineRadioPf'}
                    id={'inlineRadioPf'}
                    value={'Yes'}
                    onChange={(event) => setPf(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={pf === 'No'}
                    name={'inlineRadioPf'}
                    id={'inlineRadioPf'}
                    value={'No'}
                    onChange={(event) => setPf(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Many have multiple unclaimed PF accounts. Talk to us how to consolidate these to make the claim process easy.</li>
                        </p>
                    </div>
            </div> : null}

            {question === 39 ?
             <div>
             {scroll(ref7)}
             <label>
                <b>(Optional) List any and all gifts of specific items of property or money to a named beneficiary that you wish to make (which have not already been included above). The gift must be described well enough that anyone reading the Will would recognize what you meant. It is also important to clearly identify the beneficiary.</b>
            </label>
            <TextArea
                onkeypress
                bind={setTestatorGifts}
                placeholder="E.g. My property situated at Road No. 1, Banjara Hills, Hyderabad to Age Help Foundation; my Maruti Swift car to my daughter in law Sunita Verma."
            />
             <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
        </div> : null}

            {question === 40 ?
                <div>
                    {scroll(ref7)}
                <label>
                    <b>The person managing estate</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setPersonManagingEstate}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                 <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 41 ?
            <div>
                {scroll(ref8)}
                <label>
                    <b>Are there any special instructions regarding any assets or properties or any other instructions?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={specialInstructions === 'Yes'}
                    name={'inlineRadioSpecialInstructions'}
                    id={'inlineRadioSpecialInstructions'}
                    value={'Yes'}
                    onChange={(event) => setSpecialInstructions(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={specialInstructions === 'No'}
                    name={'inlineRadioSpecialInstructions'}
                    id={'inlineRadioSpecialInstructions'}
                    value={'No'}
                    onChange={(event) => setSpecialInstructions(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 42 ?
            <div>
                {scroll(ref8)}
                <label>
                    <b>Please list any special instructions here.</b>
                </label>
                <TextArea
                onkeypress
                bind={setSpecialInstructionDetails}
                placeholder="E.g. My horse Chetan to be taken care of by my eldest son, Nikhil and monthly expense of Rs. 10000 will be paid from my bank account no. 121331313 to Nikhil for the same."
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 43 ?
            <div>
                {scroll(ref8)}
                <label>
                    <b>(Optional) In the event that all named beneficiaries and their alternate predecease you, to whom should the estate be distributed?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setTestatorAlternate}
                    placeholder="E.g. My niece, Neha Saini; Helpage India"
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>This is called a WIPEOUT clause. Sadly, it does happen. Choose wisely; your gift can change many lives.</li>
                        </p>
                    </div>


            </div> : null}

            {question === 44 ?
            <div>
                {scroll(ref8)}
                <label>
                    <b>(Optional) Do you wish to appoint a guardian for a minor or dependent child ?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setTestatorGuardian}
                    placeholder="E.g. My niece, Neha Saini; Helpage India"
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Highly recommended, especially if your spouse predeceases you.</li>
                        </p>
                    </div>
            </div> : null}

            {question === 45 ?
            <div>
                {scroll(ref8)}
                <label>
                    <b>City and province of residence of the guardian.</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setTestatorGuardianResidence}
                    placeholder="E.g. Bangalore"
                  />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}
            
            {question === 46 ?
            <div>
                {scroll(ref9)}
                <label>
                    <b>Are there any minor beneficiaries (i.e. children or persons less than 18 years of age) who will inherit from you ?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={minorBeneficiaries === 'Yes'}
                    name={'inlineRadioMinorBeneficiaries'}
                    id={'inlineRadioMinorBeneficiaries'}
                    value={'Yes'}
                    onChange={(event) => setMinorBeneficiaries(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={minorBeneficiaries === 'No'}
                    name={'inlineRadioMinorBeneficiaries'}
                    id={'inlineRadioMinorBeneficiaries'}
                    value={'No'}
                    onChange={(event) => setMinorBeneficiaries(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextMinorBenificiary()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Pay ATTENTION here. Minors will inherit ONLY when they become 18 years old. Till then, your property will have to be in Trust.<br></br>                            <div align= 'center'><Button onClick={appointment} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'50%' }}>
                            {'Talk to our lawyer'}
                            </Button></div></li>
                        </p>
                    </div>
            </div> : null}

            {question === 47 ?
            <div>
                {scroll(ref6)}
                <label>
                    <b>Who is to be appointed as Trustee (i.e. person who will manage the property left for minor beneficiaries till they attain majority) of the trust set up for minor beneficiaries?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={appointTrusteeForMinor === 'the Executor (if applicable)'}
                    name={'inlineRadioAppointTrusteeForMinor'}
                    id={'inlineRadioAppointTrusteeForMinor'}
                    value={'the Executor (if applicable)'}
                    onChange={(event) => setAppointTrusteeForMinor(event.target.value)}
                    />
                    the Executor (if applicable)
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={appointTrusteeForMinor === 'some other person'}
                    name={'inlineRadioAppointTrusteeForMinor'}
                    id={'inlineRadioAppointTrusteeForMinor'}
                    value={'some other person'}
                    onChange={(event) => setAppointTrusteeForMinor(event.target.value)}
                    />
                    some other person
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextTrustee()} title={'Next'} />
                    <CustomButtom event={() => PreviousTrustee()} title={'Previous'} />
                </ContainerPosition>

                <div style={{padding: '5px', marginBottom: 10, position: 'absolute', bottom: '0px'}}>
                        <p style={{ fontWeight: 400, marginBottom: 0, fontSize: 14 }}>
                            <hr/>
                            <HighlightedText>TIPS & RECOMMENDATION :</HighlightedText>
                            <br></br>
                            <li style={{ listStyle: 'circle', fontSize: '14px', fontWeight: 200 }}>Choose wisely. This is very IMPORTANT. Talk to us for various options.<br></br><div align= 'center'><Button onClick={appointment} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'50%' }}>
                            {'Talk to our lawyer'}
                            </Button></div></li>
                        </p>
                    </div>

            </div> : null}
            
            {question === 48 ?
                <div>
                    {scroll(ref6)}
                <label>
                    <b>Who are you appointing as trustee for the trust set up for minor beneficiaries (i.e. person who will manage the property left for minor beneficiaries till attain majority)?</b>
                </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={setMinorTrusteeName}
                    placeholder="E.g. Manik Chand, Sarita Verma" 
                  />
                 <ContainerPosition>
                    <CustomButtom event={() => nextChildQuestion()} title={'Next'} />
                    <CustomButtom event={() => PreviousChildMinorQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 49 ?
            <div>
                {scroll(ref10)}
                <label>
                    <b>Do you wish to specify how your remains are to be handled after death?</b>
                </label>
                <div className='row' style={{ marginLeft: 5 }}>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={testatorDeath === 'Yes'}
                    name={'inlineRadioTestatorDeath'}
                    id={'inlineRadioTestatorDeath'}
                    value={'Yes'}
                    onChange={(event) => setTestatorDeath(event.target.value)}
                    />
                    Yes
                </label>
                </div>
                <div className='form-check form-check-inline'>
                <label className='form-check-label'>
                    <input
                    className='form-check-input'
                    type='radio'
                    checked={testatorDeath === 'No'}
                    name={'inlineRadioTestatorDeath'}
                    id={'inlineRadioTestatorDeath'}
                    value={'No'}
                    onChange={(event) => setTestatorDeath(event.target.value)}
                    />
                    No
                </label>
                </div>
                </div>
                <ContainerPosition>
                    <CustomButtom event={() => nextDeathQuestion()} title={'Next'} />
                    <CustomButtom event={() => PreviousMinorBenificiary()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 50 ?
            <div>
                {scroll(ref10)}
                <label>
                    <b>My remains are to be:</b>
                </label>
                <DropDown
                    noClass
                    placeholder='Please Select'
                    options={deathOpt}
                    bind={setTestatorDeathOption}
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> : null}

            {question === 51 ?
            <div>
                {scroll(ref10)}
                <label>
                    <b>(Optional) If any, enter further instructions you may have in regards to your remains.</b>
                </label>
                <TextArea
                onkeypress
                bind={setInstructions}
                placeholder="E.g. My remains are to be buried in my home city, Banaras, U.P.; my cremated remains are to become part of the Ganga ; I want my cremated remains to be scattered under the oak tree in the backyard of my countryside property." 
                />
                <ContainerPosition>
                    <CustomButtom event={() => nextQuestion()} title={'Next'} />
                    <CustomButtom event={() => previousQuestion()} title={'Previous'} />
                </ContainerPosition>
            </div> 
            : null}

             {question === 52 ?
            <div style={{textAlign:'center'}}>
                <Button onClick={backToFirst} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'100%' }}>
                    {!Object.keys(willDataList).length ? `Draft a Will.` : `Continue editing draft`}
                </Button>
                <Button onClick={resetWillData} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'100%' }}>
                    {`Reset draft Will`}
                </Button>
                <Button onClick={appointment} variant='primary' style={{ marginTop: '20px', fontWeight:700 , marginRight: '5px',fontSize:'1.5vmin',width:'100%' }}>
                    {'Get FREE copy on mail + FREE consult'}
                </Button>

            </div> 
            : null}
            </div>
</div>
<div className='col-sm-9'>
<div style={{ 
        paddingLeft:'10px',
        backgroundColor:'#fff', 
        height:500, 
        overflow:'scroll',
         }} 
        className='d-none d-sm-block'>
<div id="HTMLtoPDF" className="unselectable">
<ClaimDocument/>

</div>
</div>

<div className='d-block d-sm-none'>
<div>
</div>
</div>
</div>
<ModalContainer visible={visibleMuslimReligion} toggle={toggleMuslimReligion}>
    <div style={{ padding: '40px' }}>
        <h3 style={{textAlign:'center'}}>IMPORTANT</h3>
        <p>In India, matters relating to succession and inheritance of a Muslim are governed by Muslim personal laws. The general rule under Muslim personal laws in India is that a Muslim may, by his will, dispose only up to one-third of his property which is left after payment of funeral expenses and debts without the consent of his heirs. The remaining two-thirds of the testator’s property must go to those who are his heirs at the time of his death.</p>
        <div style={{textAlign:'center'}}>
        <button style={{backgroundColor:'#b3b3b3',padding:'5px',borderRadius:'10px',border: 'none',color:'white',fontWeight:700}} onClick={() =>StayOnPageReligionButton(toggleMuslimReligion)}>Stay On Page</button>
        <button style={{backgroundColor:'#007bff',padding:'5px',borderRadius:'10px',border: 'none',color:'white',fontWeight:700}} onClick={() =>nextReligionButton(toggleMuslimReligion)}>Next</button>
        </div>
    </div>
</ModalContainer>

<ModalContainer visible={visibleChristianReligion} toggle={toggleChristianReligion}>
    <div style={{ padding: '40px' }}>
        <h3 style={{textAlign:'center'}}>IMPORTANT</h3>
        <p>Upon the marriage of an Indian christian, his or her will automatically stands revoked. Accordingly, post marrriage a person has to again make a will.</p>
        <div style={{textAlign:'center'}}>
        <button style={{backgroundColor:'#b3b3b3',padding:'5px',borderRadius:'10px',border: 'none',color:'white',fontWeight:700}} onClick={() =>StayOnPageReligionButton(toggleChristianReligion)}>Stay On Page</button>
        <button style={{backgroundColor:'#007bff',padding:'5px',borderRadius:'10px',border: 'none',color:'white',fontWeight:700}} onClick={() =>nextReligionButton(toggleChristianReligion)}>Next</button>
        </div>
    </div>
</ModalContainer>

<ModalContainer visible={visibleParsiReligion} toggle={toggleParsiReligion}>
    <div style={{ padding: '40px' }}>
        <h3 style={{textAlign:'center'}}>IMPORTANT</h3>
        <p>Upon the marriage of a Parsi, his or her will automatically stands revoked. Accordingly, post marrriage a person has to again make a will.</p>
        <div style={{textAlign:'center'}}>
        <button style={{backgroundColor:'#b3b3b3',padding:'5px',borderRadius:'10px',border: 'none',color:'white',fontWeight:700}} onClick={() => StayOnPageReligionButton(toggleParsiReligion)}>Stay On Page</button>
        <button style={{backgroundColor:'#007bff',padding:'5px',borderRadius:'10px',border: 'none',color:'white',fontWeight:700}} onClick={() => nextReligionButton(toggleParsiReligion)}>Next</button>
        </div>
    </div>
</ModalContainer>

{calenderslot === true ?
<CalenderSlots name={personalDetails.firstName} email={personalDetails.primaryEmail} freeConsult={'true'} updateId={personalDetails.id} toggleFreeConsultModal={toggleCalenderSlot} consultWho = {'Our Legal Experts'} will content = {mailcontent} summarypayload = {mailpayload}/>
: null }

<ModalContainer visible={visibleLogin} toggle={toggleLogin}>
        <div style={{ padding: '40px' }}>
            <Login onClose={toggleLogin} will/>
        </div>
</ModalContainer>

<Modal
        show={visibleAssetDescriptionToo1}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
    <div>
        <button style={{marginRight:'20px',marginTop:'10px'}} type='button' className='close' onClick={toggleAssetDescriptionToo1}>
            &times;
        </button>
        <Assign/>
    </div>
</Modal>

<ModalContainer visible={visiblePayment} toggle={togglePayment}>
<div>
    <div style={{paddingBottom:'30px',paddingRight:'30px',paddingTop:'30px'}}>
    <button type='button' className='close' onClick={togglePayment}>
        &times;
    </button>
    </div>
    <tr>
        <th style={{textAlign:'center', paddingBottom:'10px'}} colspan={1}>Did you review your draft with our lawyers? Your first consult is FREE.<hr></hr></th>
    </tr>
    <tr>
        <td width="30%">
        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> You get legal vetting</ol>
        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Printing and home delivery</ol>
        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Registration and other paperwork</ol>
        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Digital Archive</ol>
        <ol> <img alt='' src="../img/banner/tick.png" height={20} width={20}/> Preferred pricing on other products</ol>
        </td>
        <td width='3%'></td>
    </tr>
    <tr>
        <td align="center"><ol><Payment productID={1} amount={35000} productDescrition={'Will and Estate Services'} will={'createWill'} togglePayment={togglePayment}/></ol></td>
        <td width='3%'></td>
    </tr>
</div>

</ModalContainer>
</div>
			</div>
       		)
}

const mapStateToProps = (state) => ({
    userProfileId: getStoreUserId(state),
    userRole:getStoreUserRole(state),

    personalDetails: getStoreUser(state),

    familyList: getStoreFamily(state),
    contactList: getStoreContactList(state),
    
    familyListExecutor: getStoreFamily(state).filter(fa=> fa.deceased === false),
    contactListExecutor: getStoreContactList(state).filter(fa=> fa.deceased === false),

    familyListBeneficiary: getStoreFamily(state).filter(fa=> fa.deceased === false && fa.disinherit === false),
    contactListBeneficiary: getStoreContactList(state).filter(fa=> fa.deceased === false && fa.disinherit === false),
 
    disinheritList: (getStoreContactList(state).concat(getStoreFamily(state)).filter((dis) => dis.disinherit === true)),
    executorList: (getStoreContactList(state).concat(getStoreFamily(state)).filter((exe) => exe.executorFlag === true && exe.custField5 !== 'true')),
    alternateExecutorList: (getStoreContactList(state).concat(getStoreFamily(state)).filter((exe) => exe.executorFlag === true && exe.custField5 === 'true')),

    fatherList: (getStoreFamily(state)).filter((f) => f.relationship === 'FATHER'),
    motherList: (getStoreFamily(state)).filter((m) => m.relationship === 'MOTHER'),
    spouseList: (getStoreFamily(state)).filter((s) => s.relationship === 'SPOUSE'),
    childList: (getStoreFamily(state)).filter((c) => c.relationship === 'CHILD'),

    pickBeneficiaryList: (getStoreContactList(state).concat(getStoreFamily(state))).filter(c => _.findIndex(_.flattenDeep(getStoreBeneficiaryList(state).map(b => b.contacts)), { id: _.get(c, ['id']) }) === -1),
    beneficiaryList: getStoreBeneficiaryList(state),

    finAssetList: getStoreFinAssetList(state),
    digitalAssetList: getStoreDigitalAssetList(state),
    immPropImmovableAssetList: getStoreImmovableAssetList(state),
    otherAssetList: getStoreOtherAssetList(state),

    pfFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'PF'),
    fdFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'FD'),
    bankFinAssetList: getStoreFinAssetList(state).filter(fa => fa.assetLookupCode === 'BNK'),
    lockerOtherAssetList: getStoreOtherAssetList(state).filter(fa=> fa.assetLookupCode === 'LOCK'),

    willDataList: getWillDataList(state),
  });
  const mapDispatchToProps = () => dispatch => ({
    dispatchSetUserDetails: user => dispatch(setUserData(user)),

    dispatchSetFamilyList: (contact) => dispatch(setFamilyData(contact)),
    dispatchSetContactList: (contact) => dispatch(setContactData(contact)),

    dispatchCreateFamily: parent => dispatch(createFamily(parent)),
    dispatchEditFamily: parent => dispatch(editFamily(parent)),
    
    dispatchAddContact: contact => dispatch(addContact(contact)),
    dispatchEditContact: contact => {
                                        dispatch(editContact(contact));
                                        dispatch(updateContactBeneficiary(contact))
                                    },

    dispatchSetExecutorList: executor => dispatch(setExecutorData(executor)),
    dispatchSetBeneficiaryList: beneficiary => dispatch(setBeneficiaryData(beneficiary)),

    dispatchAddFinAsset: (finAsset) => dispatch(setFinAssetData(finAsset)),
    dispatchSetImmovableAssetList: (immovableAsset) => dispatch(setImmovableAssetData(immovableAsset)),
    dispatchSetDigitalAssetList: (digitalAsset) => dispatch(setDigitalAssetData(digitalAsset)),
    dispatchSetOtherAssetList: (otherAsset) => dispatch(setOtherAssetData(otherAsset)),

    dispatchUpdateExecutorList: executor => dispatch(updateToExecutor(executor)),
    dispatchDeleteExecutorList: executorId => dispatch(deleteExecutorFromList(executorId)),

    dispatchCreateBeneficiary: ben => dispatch(createBeneficiary(ben)),
    dispatchDeleteBeneficiary: beneficiaryId => dispatch(deleteBeneficiaryFromList(beneficiaryId)),

    dispatchSetWillData: willData => dispatch(setWillDataList(willData)),
  
  })
  
export default connect(mapStateToProps, mapDispatchToProps)(Claim);