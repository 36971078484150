import React from 'react';
import SectionHeading from '../../home/component/layout/SectionHeading';
import { Link} from 'react-router-dom';

const Security = ()  => {

    let imgUrl = '../img/banner/greybackground.png';

    return(
            <div className='container'>
            <div className='d-block d-sm-none'>

            <div className = 'Component-Bg' 
                    style = {{ backgroundImage: `url(${imgUrl})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'left center',
                    backgroundRepeat: 'no-repeat',
                    }}> 
              
                    <div id ='intro' style={{width:'100%', paddingLeft:'5%', paddingRight:'2%'}}>  
                    
                        <SectionHeading heading={'Is your data safe with us?'} subHeading={''} image={'../img/banner/plain_greybackground.png'}/>
                        <p align='justify'>We are paranoid about data security. Just as you care about the security of your family, we ensure your data stays
                            absolutely safe with us. We are not just a comprehensive digital archive with industry-leading security 
                            technology, we employ Banking level standards and 2048 bit SSl all through the site.</p>
                        <p>But you may have heard this from others as well.</p>
                        <p><b><i>But we went an extra mile to partner with Government of India's DigiLocker Initiative. You can read more
                            about it later, but in short, we keep your data where some of the leading government agencies do.</i></b></p> 
                        <p><b><i>Most importantly, ONLY YOU can access it at all times.</i></b>
                        </p>

                        <div id = 'philosophy'><p> <br></br><br></br></p></div>
                  </div>
        </div>
    </div>

    <div className='d-none d-sm-block'>

            <div className = 'Component-Bg' 
                    style = {{ backgroundImage: `url(${imgUrl})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'left center',
                    backgroundRepeat: 'no-repeat',
                    }}> 
              
                    <div id ='intro' style={{width:'100%', paddingLeft:'35%', paddingRight:'2%',paddingTop:'40px'}}>  
                    
                        <SectionHeading heading={'Is your data safe with us?'} subHeading={''} image={'../img/banner/plain_greybackground.png'}/>
                        <p align='justify'>We are paranoid about data security. Just as you care about the security of your family, we ensure your data stays
                            absolutely safe with us. We are not just a comprehensive digital archive with industry-leading security 
                            technology, we employ Banking level standards and 2048 bit SSl all through the site.</p>
                        <p>But you may have heard this from others as well.</p>
                        <p><b><i>But we went an extra mile to partner with Government of India's DigiLocker Initiative. You can read more
                            about it later, but in short, we keep your data where some of the leading government agencies do.</i></b></p> 
                        <p><b><i>Most importantly, ONLY YOU can access it at all times.</i></b>
                        </p>

                        <div id = 'philosophy'><p> <br></br><br></br></p></div>
                  </div>
        </div>
    </div>
      
      

<h4>Securing Your Data with &nbsp;<img alt='' width='160' height='40'src ='../img/banner/logo-aws-security-hub.png'></img></h4>
<p>We are hosted on Amazon Web Services and needless to say, we use their infrastructure security to the fullest.
 Within our systems, all your data is stored using AES-256 encryption with a uniquely derived key for each user. 
We encrypt every single personally identifiable (PII) field in the database, 
including your name and email address. For searching and indexing, we hash a number of fields using HMAC. 
We apply the same encryption technique to all files you upload or download.<br></br><br></br>

As with all systems such as ours, the security of your information depends on you. 
Choose a strong password (we enforce that as best we can) and never share it with anyone. 
</p>

<br></br>

<h4>Digital Archive at <img alt='' width='120' height='44'src ='../img/banner/digilocker-logo.png'></img></h4>
<p>We are a part of Government of India's <Link to ='www.digilocker.gov.in'> DigiLocker initiative</Link>. Any file you wish to store on our site, is  
    uploaded on DigiLocker. ONLY you can access these records and share with others when needed. All files have a HMAC signature 
    is checked at both ends to ensure authenticity.<br></br><br></br>
</p>

<h4>Securing Your Data in Transit <img alt='' width='100' height='54'src ='../img/banner/ssl.png'></img></h4>
<p>All communications between you and EasyInherit are encrypted via SSL using 2048-bit certificates and we 
require SSL on all communications. We support perfect forward secrecy so that even if someone eavesdrops 
on your communication, they will still not be able to decrypt the data in the event that our key is compromised.</p>

<br></br>

<h4>Operational Procedures to Keep the Site Secure <img alt='' width='70' height='54'src ='../img/banner/aws-inspector.png'></img></h4>
<p>EasyInherit follows best practices to keep your data secure. We regularly audit our environments and code 
for security issues and apply patches expeditiously. We use commercial services that regularly check our site 
and we retain our own security experts to probe and verify the security of our site.</p>

<br></br>

<h4>Restricted Access to your Information <img alt='' width='170' height='54'src ='../img/banner/privacy_shield.png'></img></h4>
<p>Our strict internal procedures prevent any EasyInherit employee or administrator from gaining access to your account, 
beyond a limited set of data necessary to help grant you access to your account (e.g. triggering confirmation emails) 
and restricting access to your account in urgent circumstances (e.g. limiting or removing access). 
EasyInherit administrators can never see the plan information that you fill out or any documents that you upload. 
EasyInherit logs and regularly audits all accesses to your account, whether by you, an administrator or your deputies.</p>

</div>

    )
}

export default (Security);
