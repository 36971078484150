import React, { forwardRef, useState, useRef } from 'react';
import CollapseSection from "../../../../components/CollapsibleSection";
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import { useInput } from "../../../../hooks/useInput";
import DropDown from '../../../../components/DropDown';
import string from '../../../../constant/en.json';
import { batchState, getId, getContactFullName } from '../../../../utils/commonUtil';
import { ContainerPosition, CustomButtom, AlignColumn, CenterAlignRow, TableContainer, ActionItemUpload } from '../../../../components/CustomElements';
import PickContactTable from '../../../components/PickContactTable';
import { updateFinAssetById, createFinAsset, deleteFinAsset } from '../finAssetService';
import { getStoreFinAssetList, addFinAsset, editFinAsset, deleteFinAssetFromList } from '../../../../actions/finAssetsActions';
import { getStoreUserId, getStoreUserRole } from '../../../../selectors/userSelectors';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getStoreContactList } from '../../../../actions/contactActions';
import { getStoreFamily } from '../../../../actions/familyActions';
import HelperModal from '../../../helper/HelperModal';
import SliderBar from '../../../../components/UploadComponent';
import { useQuaishToasts } from '../../../../actions/alertAction';
import ModalContainer from '../../../../home/component/layout/Modal'
import DataShareModal from '../../../../dashboard/forms/dataShare/DataShareModal'

const PhysicalShares = ({userId, pickList, phySharesFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, dispatchDeleteFinAsset, userRole}) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [phyShares, setPhyShares] = useState();
  let [visibleDataShare, setVisibleDataShare] = useState(false)
  let toggleDataShare = () => setVisibleDataShare(!visibleDataShare)
  const [dataShareId, setDataShareId] = useState('')

  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && phyShares &&  phyShares.id ) {
        setPhyShares(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setPhyShares(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
      setPhyShares(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const onShare = (c) => {
    setDataShareId(c)
    toggleDataShare()
  }
       
  const docsRef = React.useRef()
  const onUpload = (c) => {
    docsRef.current.submit('SHR', c.id, "Upload Files")
  }
  return (
    <div>

        <SavedPhyShares onUpload={onUpload} data={phySharesFinAssetList} onEdit={onEdit} onShare={onShare} isFormHidden={isFormHidden} dispatchDeleteFinAsset={dispatchDeleteFinAsset} />
        <div  style={{ marginBottom: 20, display: 'block' }}>
          <ContainerPosition>
            <CustomButtom event={() => toggleForm()} title={'Add Physical Shares'} />
          </ContainerPosition>
        </div>
        {isFormHidden ? null : <PhysSharesForm phyShares={phyShares} userId={userId} onCancel={toggleForm} onDone={onDone} phySharesFinAssetList={phySharesFinAssetList} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} />}
        <SliderBar ref={docsRef} />
        {visibleDataShare
                  ? 
                  <ModalContainer visible={visibleDataShare} toggle={toggleDataShare}>
                    <DataShareModal visibleDataShare={visibleDataShare} toggleDataShare={toggleDataShare} dataShareId={dataShareId} AssetList={phySharesFinAssetList} assetLookupKey={"asset_fin"} assetLookupCode={'SHR'}/>
                  </ModalContainer> 
                  : null 
        }
    </div>
  );
}

const PhysSharesFrm = ({phyShares, pickList,userId, onCancel, onDone, phySharesFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, userRole, displayNominee}, ref) => {
    const { value: assetIssuerName, bind: bind1 } = useInput(!phyShares ? '' : phyShares.assetIssuerName);
    const { value: assetQuantity, bind: bind2 } = useInput(!phyShares ? '' : phyShares.assetQuantity);
    const { value: pickedNominee, bind: bindNominee } =useInput(userRole === 'RECEIVER' ? null : !phyShares ? 'INDV' : phyShares['contacts']['contactLookupCode']);
    const selectedId =  userRole === 'RECEIVER' ? null : !phyShares ? '' : phyShares['contacts']['id']
    const refPickNominee = useRef();
    const { success, error, removeAll } = useQuaishToasts()
    /*const validate=(payload)=>{
      const ev=[]
      for (var key in payload) {
        switch(key){
            case 'assetIssuerName':
              _.isEmpty(payload[key]) ? ev.push(string.forms.physicalShares.emptyCompanyName):null
              break
            case 'assetQuantity':
              _.isEmpty(payload[key]) ? ev.push(string.forms.physicalShares.emptySharesQuantity): !(/^\d+$/).test(payload[key])? ev.push("Shares Quantity Should be a Number\n") : null
              break
            case 'contacts':
              payload[key]['id']=== undefined ? ev.push(string.forms.physicalShares.emptyNominee):null
              break
            default:
              break
            } 
          }
            if(ev.length>0){
              warn(ev)
              return false
            }
            else return true
          }*/
  const submit = () => {
    removeAll()
    const roleOfUser= userRole==='GIVER' ? false : true
    const payload = {
      userId,
      assetLookupCode: 'SHR',
      receiverFlag:	roleOfUser,
      assetIssuerName,
      assetQuantity:assetQuantity.toString(),
      contacts: roleOfUser ? null : displayNominee === 'no' ? _.isEmpty(phyShares) || _.isEmpty(phyShares.contacts) ? null : { id: phyShares['contacts']['id'] } : { id: _.head(refPickNominee.current.returnDetails()) }
    }
    console.log(payload)
    //if(validate(payload))

    getId(phyShares) ? updateFinAssetById(phyShares.id,{id:phyShares.id ,...payload})
    .then(response => {
        success('Record Updated Successfullyess');
        dispatchEditFinAsset(response.data)
        onDone()
    }).catch(err => {
        error(err)
    }) : 
    createFinAsset(payload)
    .then(response => {
      success('Record Created Successfully');
      dispatchAddFinAsset(response.data);
      onDone()
        // dispatchSetAllContactList(response.data);
    })
    .catch(err => {
      error(err)
    })
  }
    return <CollapseSection  mainTitle={'Enter ' + string.forms.physicalShares.physicalSharesFormTitle} >
      <section className='section_form'>
        <div>
          <HeadBar title={string.forms.physicalShares.companyName} subtitle={'*'} />
        </div>
        <div className='row'>
          <Input type='text' placeholder={string.forms.physicalShares.companyName} isBigger bind={bind1} />
        </div>
        <div>
          <HeadBar title={string.forms.physicalShares.sharesQuantity} subtitle={'*'} />
        </div>
        <div className='row'>
          <Input type='text' placeholder={string.forms.physicalShares.sharesQuantityPlaceholder} isBigger bind={bind2} />
        </div>
        {userRole === 'GIVER' && displayNominee !== 'no'?
      <div>
        <div className="row" style={{marginLeft:'2px'}}>
        <HeadBar title={'Who is the nominee'} subtitle={''} /> 
        <HelperModal helperTextId={"nominee"} />
      </div>
      <div className='row'>
      <DropDown options={[{ value: 'Individual', code: 'INDV' }, { value: 'Institution', code: 'INST' }]} placeholder={string.forms.commonFormFields.nominee} isBigger bind={bindNominee} />
      </div>
      <PickContactTable isSingle data={pickList.filter(c => _.get(c, ['contactLookupCode']) === pickedNominee)} ref={refPickNominee} selected={[selectedId]} />
      </div>
      : null
    }
      </section>
      <ContainerPosition>
      <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
      <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
    </ContainerPosition>
    </CollapseSection>
  }
  export function SavedPhyShares ({onUpload, data, dispatchDeleteFinAsset, onEdit, dispalyActions, onShare, datashare, dataShareByMe, accessLevelArray, toggle }) {
    const { success, error } = useQuaishToasts()
    const deletePhyShares = (id) =>{
      if(window.confirm("Delete?\n Are you sure you want to delete this record?")){
          deleteFinAsset(id)
          .then(response => {
            success('Record Deleted Successfully');
            dispatchDeleteFinAsset(id);
  
          })
          .catch(err => {
            error(err)
          })
      }
    }
    const row = data.map((e) => {
      return(<TableContainer>
        <CenterAlignRow>
          <AlignColumn label="Company Name" text={e.assetIssuerName} />
          <AlignColumn label="Share Quantity" text={e.assetQuantity} />
          <AlignColumn label="Nominee" text={e.contacts !== null ?getContactFullName(e.contacts) : ''} />
          {datashare === 'yes' ? <AlignColumn label="Shared with" text={_.isEmpty(dataShareByMe[e.id][0]) ? null : dataShareByMe[e.id][0].map((f,index)=>(<span style={{cursor:'pointer', color: '#007bff'}} onClick={()=>toggle(dataShareByMe[e.id][1][index])}><span style={{textDecoration:'underline'}}>{f}</span><span>{index !== (dataShareByMe[e.id][0].length) -1 ? ' , ' : ''}</span></span>))} />: null}
          {datashare === 'shareby' ? <AlignColumn label="Shared By" text={dataShareByMe[e.id]} />: null}
          {dispalyActions === 'no' || datashare === 'yes' || (datashare === 'shareby' && accessLevelArray[e.id]==='READ')  ? null :
          <AlignColumn label="Action" text={<ActionItemUpload onUpload={onUpload} onEdit={()=>onEdit(e)} onDelete={()=>deletePhyShares(e.id)} onShare={() => onShare(e.id)} datashare={datashare}/>} />}
          </CenterAlignRow>
      </TableContainer>
    )});
    return (
      data.length === 0 ? null :
      <div>
      <CollapseSection isHidden={data.length === 0} mainTitle={'Physical Shares'} onShare={() => onShare('multicreate')}  collapsed={false} isCollapsable={true} customStyle={true}  datashareuser={datashare}  datashare={'yes'}>
        {row}
      </CollapseSection>
       {dispalyActions === 'no' || datashare === 'yes' || datashare === 'shareby' ? <br/> : null }
       </div>
    );
  };
  
  export const PhysSharesForm = forwardRef(PhysSharesFrm);
  const mapStateToProps = (state) => ({
    phySharesFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'SHR'),
    userId: getStoreUserId(state),
    pickList: (getStoreContactList(state).concat(getStoreFamily(state))),
    userRole:getStoreUserRole(state),
  });
  const mapDispatchToProps = () => dispatch => ({
    dispatchAddFinAsset: finAsset => dispatch(addFinAsset(finAsset)),
    dispatchEditFinAsset:finAsset=>dispatch(editFinAsset(finAsset)),
    dispatchDeleteFinAsset:finAsset=>dispatch(deleteFinAssetFromList(finAsset)),
  })
  export default connect(mapStateToProps, mapDispatchToProps)(PhysicalShares);
