export const ADD_NONDISINHERIT_LIST = 'ADD_NONDISINHERIT_LIST';
export const setNonDisinheritData = (nonDisInherit) => {

    return {
        type:ADD_NONDISINHERIT_LIST,
        nonDisInherit
    }
}
export const DELETE_DISINHERIT = 'DELETE_DISINHERIT';
export const deleteContactFromDisinheritList = (contactId) => {

    return {
        type:DELETE_DISINHERIT,
        contactId
    }
}
export const getStoreNonDisinheritList = state => state.nonDisInherit;