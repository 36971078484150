import React, { useEffect } from 'react';

import Auth from "@aws-amplify/auth";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setUserData } from '../actions/userActions';
import { AWS_CONFIG } from '../constant/ServerConst';
import { getUserByUserName, updateUser } from '../dashboard/forms/personal/personalService';
import { getStoreUserId } from '../selectors/userSelectors';
import { setUserRoleData } from '../actions/userRolesAction'
import { Post } from '../utils/ApiUtil';

import Spinner from 'react-bootstrap/Spinner'

Auth.configure({ ...AWS_CONFIG.Auth });

const GoogleLoginRedirect = ({dispatchSetUserDetails, dispatchSetUserRoleData, userId, will}) => {
  const history = useHistory();

  const getUserByRole = () =>{
    let payload={
      roleLookupCode : 'GIVER',
    }
    dispatchSetUserRoleData(payload)
    if(will) { 
      window.open("/dashboard")
    }
    else{
      history.push('/dashboard')
    }
  }

  
  useEffect( () =>{
    const timer = setTimeout(() => {
        Auth.currentAuthenticatedUser({
          bypassCache:false
        })
      .then(user => {
        getUserByUserName(user.username)
        .then(resp => {
          dispatchSetUserDetails(resp.data)
          getUserByRole()             
        })
        .catch(err => {
          Post('userprofile/create?userId='+user.username,
          { 
              userName:user.username,
              mobilePhone: 1234567890,
              primaryEmail: user.signInUserSession.idToken.payload.email,
          })
          .then((resp)=>{
            updateUser(resp.data.id, {mobilePhone: 1234567890, primaryEmail: user.signInUserSession.idToken.payload.email})
            .then(res => {
                dispatchSetUserDetails(res.data)
                getUserByRole()
            })
            .catch(err=>console.log(err))
          })
            .catch((error)=>{
                console.log('backend error')
                console.log(error)
              });
        })
        })
      .catch(() => {console.log("Not signed in")});
      }, 2000);
      return () => clearTimeout(timer);
  },[])


    return (
        <div>
        <div style={{marginTop:'2%',textAlign: 'center'}}>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
      </div>
    );
}
const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchSetUserRoleData: (userRolesData) =>dispatch(setUserRoleData(userRolesData)),
  dispatchSetUserDetails: user => dispatch(setUserData(user)),
})
export default connect(mapStateToProps, mapDispatchToProps)(GoogleLoginRedirect);