import React, { useImperativeHandle, forwardRef } from 'react';
import { useInput } from '../../hooks/useInput';
import HeadBar from '../../components/HeaderBar';
import Input from '../../components/Input';


export const EmailDeetsForm = ({ defaultValue = { primaryEmail: '', secondaryEmail: '' } }, ref) => {
  const { value: primaryEmail, bind: bindFN } = useInput(defaultValue.primaryEmail);
  const { value: secondaryEmail, bind: bindMN } = useInput(defaultValue.secondaryEmail);
  useImperativeHandle(ref, () => ({
    returnDetails() { return { primaryEmail, secondaryEmail } }
  }));
  return <section className='section_form'>
    <HeadBar title={'Email Details*'} subtitle={'(Alternate email-address is optional)'} />
    <div className='row'>
      <Input type='text' placeholder={'Primary Email Address'} isBigger bind={bindFN} />
      <Input type='text' placeholder={'Alternate Email Address'} isBigger bind={bindMN} />
    </div>
  </section>
};
const EmailDetails = forwardRef(EmailDeetsForm);
export default EmailDetails;