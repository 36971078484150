import initialState from "./initialState";
import _ from 'lodash';
import { ADD_DIGITAL_ASSIGN_LIST, DELETE_DIGITAL_ASSIGN, CREATE_DIGITAL_ASSIGN, EDIT_DIGITAL_ASSIGN } from "../actions/digitalAssignActions";
export default (state = initialState.digitalAssigns, action) => {
    switch (action.type) {
        case ADD_DIGITAL_ASSIGN_LIST:
            return action.digitalAssigns;
        case DELETE_DIGITAL_ASSIGN:
            return state.filter(con => con.id !== action.digitalAssignId);
        case CREATE_DIGITAL_ASSIGN:
            return state.concat(action.digitalAssign)
        case EDIT_DIGITAL_ASSIGN:
            const index = _.findIndex(state, { id: action.digitalAssign.id });
            state.splice(index, 1, action.digitalAssign);
            return [...state];
        default:
            return state;
    }
}