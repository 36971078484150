import React, { forwardRef, useState, useRef } from 'react';
import CollapseSection from "../../../../components/CollapsibleSection";
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import { useInput } from "../../../../hooks/useInput";
import { CardRadioBtn } from '../../../components/HoldingTypeTag';
import string from '../../../../constant/en.json';
import { batchState, getId, getContactFullName } from '../../../../utils/commonUtil';
import { ContainerPosition, CustomButtom, AlignColumn, CenterAlignRow, TableContainer, ActionItemUpload } from '../../../../components/CustomElements';
import { updateFinAssetById, createFinAsset, deleteFinAsset } from '../finAssetService';
import PickContactTable from '../../../components/PickContactTable';
import DropDown from '../../../../components/DropDown';
import { getStoreFinAssetList, addFinAsset, editFinAsset, deleteFinAssetFromList } from '../../../../actions/finAssetsActions';
import { connect } from 'react-redux';
import { getStoreUserId, getStoreUserRole } from '../../../../selectors/userSelectors';
import _ from 'lodash';
import { getStoreContactList } from '../../../../actions/contactActions';
import { getStoreFamily } from '../../../../actions/familyActions';
import HelperModal from '../../../helper/HelperModal';
import SliderBar from '../../../../components/UploadComponent';
import { useQuaishToasts } from '../../../../actions/alertAction';
import ModalContainer from '../../../../home/component/layout/Modal'
import DataShareModal from '../../../../dashboard/forms/dataShare/DataShareModal'

const BondDetails = ({userId, pickList, bondFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, dispatchDeleteFinAsset, userRole}, ref) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [bond, setBond] = useState();
  let [visibleDataShare, setVisibleDataShare] = useState(false)
  let toggleDataShare = () => setVisibleDataShare(!visibleDataShare)
  const [dataShareId, setDataShareId] = useState('')

  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && bond &&  bond.id ) {
        setBond(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setBond(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
      setBond(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const onShare = (c) => {
    setDataShareId(c)
    toggleDataShare()
  }
       
  const docsRef = React.useRef()
  const onUpload = (c) => {
    docsRef.current.submit('BON', c.id, "Upload Files")
  }
  return (
    <div>
        <SavedBonds onUpload={onUpload} data={bondFinAssetList} onEdit={onEdit} onShare={onShare} isFormHidden={isFormHidden} dispatchDeleteFinAsset={dispatchDeleteFinAsset} />
        <div  style={{ marginBottom: 20, display: 'block' }}>
          <ContainerPosition>
            <CustomButtom event={() => toggleForm()} title={'Add Bonds'} />
          </ContainerPosition>
        </div>
        {isFormHidden ? null : <BondFrm bond={bond} userId={userId} onCancel={toggleForm} onDone={onDone} bondFinAssetList={bondFinAssetList} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole}/>}
        <SliderBar ref={docsRef} />
        {visibleDataShare
                  ? 
                  <ModalContainer visible={visibleDataShare} toggle={toggleDataShare}>
                    <DataShareModal visibleDataShare={visibleDataShare} toggleDataShare={toggleDataShare} dataShareId={dataShareId} AssetList={bondFinAssetList} assetLookupKey={"asset_fin"} assetLookupCode={'BON'}/>
                  </ModalContainer> 
                  : null 
        }
    </div>
  );
}

const BondFrm = ({bond,userId, onCancel, onDone, bondFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, pickList, userRole, displayNominee}, ref) => {
    const { value: assetIssuerName, bind: bindName } = useInput(!bond ? '' : bond.assetIssuerName);
  const { value: assetQuantity, bind: bindUnits } = useInput(!bond ? '' : bond.assetQuantity);
  const { value: pickedNominee, bind: bindNominee } =useInput(userRole === 'RECEIVER' ? null : !bond ? 'INDV' : bond['contacts']['contactLookupCode']);
  const [provideDeets, setProvideDeets] = useState('self')
  const selectedId = userRole === 'RECEIVER' ? null : !bond ? '' : bond['contacts']['id']
  const refPickNominee = useRef();
  const { success, error, removeAll } = useQuaishToasts()
  /*const validate=(payload)=>{
    const ev=[]
    for (var key in payload) {
      switch(key){
          case 'assetIssuerName':
            _.isEmpty(payload[key]) ? ev.push(string.forms.bonds.emptyBondName):null
            break
          case 'assetQuantity':
            _.isEmpty(payload[key]) ? ev.push(string.forms.physicalShares.emptySharesQuantity): !(/^\d+$/).test(payload[key])? ev.push("Number of Units Should be a Number\n") : null
          break
          case 'contacts':
            payload[key]['id']=== undefined ? ev.push(string.forms.bonds.emptyNominee):null
            break
          default:
            break
          } 
        }
          if(ev.length>0){
            warn(ev)
            return false
          }
          else return true
}*/
  const submit = () => {
    removeAll()
    const roleOfUser= userRole==='GIVER' ? false : true
    const payload = {
      userId,
      assetLookupCode: 'BON',
      receiverFlag:	roleOfUser,
      assetIssuerName,
      assetQuantity:assetQuantity.toString(),
      contacts: roleOfUser ? null : displayNominee === 'no' ? _.isEmpty(bond) || _.isEmpty(bond.contacts) ? null : { id: bond['contacts']['id'] } : { id: _.head(refPickNominee.current.returnDetails()) }
    }
    console.log(payload)
    //if(validate(payload))

    getId(bond) ? updateFinAssetById(bond.id,{id:bond.id ,...payload})
    .then(response => {
        success('Record Updated Successfully');
        dispatchEditFinAsset(response.data)
        onDone()
    }).catch(err => {
        error(err)
    }) : 
    createFinAsset(payload)
    .then(response => {
      success('Record Created Successfully');
      dispatchAddFinAsset(response.data);
      onDone()
        // dispatchSetAllContactList(response.data);
    })
    .catch(err => {
      error(err)
    })
}
    return <CollapseSection  mainTitle={'Enter ' + string.forms.bonds.bondsFormTitle} >
      <section className='section_form'>
        <CardRadioBtn options={[{ value: 'self', display: string.forms.bonds.allBondDetails },
        { value: 'auto', display: string.forms.bonds.BondsNSDLStatement }]} onChange={setProvideDeets} defaultValue={'self'} />
        {provideDeets ==='self' ?
        <div><div>
          <HeadBar title={string.forms.bonds.bondName} subtitle={'*'} />
        </div>
        <div className='row'>
          <Input type='text' placeholder={string.forms.bonds.bondName} isBigger bind={bindName} />
        </div>
        <div>
          <HeadBar title={string.forms.bonds.noOfUnits} subtitle={'*'} />
        </div>
        <div className='row'>
          <Input type='text' placeholder={string.forms.bonds.noOfUnits} isBigger bind={bindUnits} />
        </div>
        {userRole === 'GIVER' && displayNominee !== 'no'?
      <div>
        <div className="row" style={{marginLeft:'2px'}}>
        <HeadBar title={'Who is the nominee ( as declared in the Bond papers ). Remember, your nomination supercedes any assignment through a Will.'} subtitle={''} /> 
        <HelperModal helperTextId={"nominee"} />
      </div>
      <div className='row'>
        <DropDown options={[{ value: 'Individual', code: 'INDV' }, { value: 'Institution', code: 'INST' }]} placeholder={string.forms.commonFormFields.nominee} isBigger bind={bindNominee} />
      </div>
      <PickContactTable isSingle data={pickList.filter(c => _.get(c, ['contactLookupCode']) === pickedNominee)} ref={refPickNominee} selected={[selectedId]} />
      </div>
      : null
    }
      </div> :null }
    </section>
    <ContainerPosition>
            <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
            <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
        </ContainerPosition>
  
    </CollapseSection>
  }
export const BondForm = forwardRef(BondFrm);

export function SavedBonds ({onUpload, data, dispatchDeleteFinAsset, onEdit, dispalyActions, onShare, datashare, dataShareByMe, accessLevelArray, toggle}) {
  const { success, error } = useQuaishToasts()
  const deleteBON = (id) =>{
    if(window.confirm("Delete?\n Are you sure you want to delete this record?")){
        deleteFinAsset(id)
        .then(response => {
          success('Record Deleted Successfully');
          dispatchDeleteFinAsset(id);

        })
        .catch(err => {
          error(err)
        })
    }
  }

  const row = data.map((e) => {
    return(
      
    <TableContainer>
      <CenterAlignRow>
        <AlignColumn label="Bank Name" text={e.assetIssuerName} />
        <AlignColumn label="Unit" text={e.assetQuantity} />
        <AlignColumn label="Nominee" text={e.contacts!== null ? getContactFullName(e.contacts) : ''} />
        {datashare === 'yes' ? <AlignColumn label="Shared with" text={_.isEmpty(dataShareByMe[e.id][0]) ? null : dataShareByMe[e.id][0].map((f,index)=>(<span style={{cursor:'pointer', color: '#007bff'}} onClick={()=>toggle(dataShareByMe[e.id][1][index])}><span style={{textDecoration:'underline'}}>{f}</span><span>{index !== (dataShareByMe[e.id][0].length) -1 ? ' , ' : ''}</span></span>))} />: null}
        {datashare === 'shareby' ? <AlignColumn label="Shared By" text={dataShareByMe[e.id]} />: null}
        {dispalyActions === 'no' || datashare === 'yes' || (datashare === 'shareby' && accessLevelArray[e.id]==='READ')  ? null :
        <AlignColumn label="Action" text={<ActionItemUpload onUpload={onUpload} onEdit={()=>onEdit(e)} onDelete={()=>deleteBON(e.id)} onShare={() => onShare(e.id)} datashare={datashare}/>} />}
        </CenterAlignRow>
    </TableContainer>
  )});
  return (
    data.length === 0 ? null :
    <div>
    <CollapseSection isHidden={data.length === 0} mainTitle={'Bonds'} collapsed={false} onShare={() => onShare('multicreate')}  isCollapsable={true} customStyle={true}  datashareuser={datashare}  datashare={'yes'}>
      {row}
    </CollapseSection>
          {dispalyActions === 'no' || datashare === 'yes' || datashare === 'shareby' ? <br/> : null }
     </div>
  );
};

 const mapStateToProps = (state) => ({
  bondFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'BON'),
  userId: getStoreUserId(state),
  pickList: (getStoreContactList(state).concat(getStoreFamily(state))),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchAddFinAsset: finAsset => dispatch(addFinAsset(finAsset)),
  dispatchEditFinAsset:finAsset=>dispatch(editFinAsset(finAsset)),
  dispatchDeleteFinAsset:finAsset=>dispatch(deleteFinAssetFromList(finAsset)),
})
 export default connect(mapStateToProps, mapDispatchToProps)(BondDetails);
