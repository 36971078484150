import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getStoreFamily, setFamilyData } from '../../../actions/familyActions';
import { HelpSection, HighlightedText, List, CustomButtom, ContainerPosition } from '../../../components/CustomElements';
import string from '../../../constant/en.json';
import { getStoreUserId } from '../../../selectors/userSelectors';
import ContactListTable from '../../components/ContactTable';
import { getFamilyListFromServer } from './familyService';
import MyChildrenForm from './MyChildren';
import ParentForm from './Parent';
import SpouseForm from './Spouse';
import { getRelationShip, batchState, getContactFullName, getId } from '../../../utils/commonUtil';
import { useRef } from 'react';
import SliderBar from '../../../components/UploadComponent';
let style = {
  marginBottom: 20
}
const Family = ({ family, userId, dispatchSetFamilyList }) => {
  //let [isLoading, loading] = useState(true);
  const [editPerson, setFamily] = useState();
  const [form, setForm] = useState();
  useEffect(() => {
    getFamilyListFromServer(userId)
      .then(response => {
        dispatchSetFamilyList(response.data)
      })
      .catch(error => {
        alert(error);
      })
  }, []);
  let isParentEdit = false, isSpouseEdit = false, bothParent = false, spouseMax = false;
  const isParent = (f) => getRelationShip(f) === 'FATHER' || getRelationShip(f) === 'MOTHER';
  const isSpouse = f => getRelationShip(f) === 'SPOUSE';
  if (family.filter(isParent).length === 2) {
    bothParent = true;
  }
  if (family.filter(isSpouse).length !== 0) {
    spouseMax = true;
  }
  if (isParent(editPerson)) {
    isParentEdit = true;
  }
  if (isSpouse(editPerson)) {
    isSpouseEdit = true
  }
  const onParentCancel = () => {
    onCancel()
  }
  const onCancel = () => {
    batchState(() => {
      setForm(null)
      setFamily()
    })
  }
  const parentRef = useRef();
  const onEdit = e => {
    batchState(() => {
      setForm(isParent(e) ? 'parent' : isSpouse(e) ? 'spouse' : 'child')
      setFamily(e)
    })

  }
  
  const docsRef = React.useRef()
  const onUpload = (c) => {
    docsRef.current.submit('CONTACT', c.id, getContactFullName(c))
  }
  const ParentTag = form === 'parent' ? <div style={style}>
    <ParentForm ref={parentRef} onDone={onParentCancel} onCancel={onParentCancel} data={{ ...editPerson }} userId={userId} />
  </div> : null

  const SpouseTag = form === 'spouse' ? <div style={style}>
    <SpouseForm onDone={onParentCancel} onCancel={onParentCancel} data={{ ...editPerson }} userId={userId} />
  </div> : null
  const Child = form === 'child' ? <div style={style}>
    <MyChildrenForm onDone={onParentCancel} onCancel={onParentCancel} data={{ ...editPerson }} userId={userId} />
  </div> : null
  return (
    <div>
      <HelpSection heading="HELP TIPS!">
        <List>
          Add all your family member details in place to make it easier for you to pick executors and beneficiaries . Make sure you fill all the necessary blanks as best as you can.{' '}
          <HighlightedText>{string.forms.commonFormFields.mandatoryFields}</HighlightedText>
        </List>
      </HelpSection>
      <div style={{padding:'2px',paddingTop:'1.5%',paddingLeft:'1%',marginBottom:'6px',border:'rgb(204 204 204) 1px solid', background:'rgba(0, 0, 0, .03)',borderRadius:'5px'}}>
        <p>Enter Your Family Details</p>
      </div>
      {family.length > 0 ? <div style={style}>
        <ContactListTable onEdit={onEdit} onUpload={onUpload} data={family} conType={'Family'} />
      </div> : null}
      <div style={{ marginBottom: 20, display: 'block' }}>
        <ContainerPosition>
          {isParentEdit || !bothParent ? <CustomButtom event={() => {
            batchState(() => {
              setForm("parent")
              setFamily()
            })
          }} title={"Add Parent"} /> : null}
          {isSpouseEdit || !spouseMax ? <CustomButtom event={() => {
            batchState(() => {
              setForm("spouse")
              setFamily()
            })
          }} title={"Add Spouse"} /> : null}
          <CustomButtom event={() => {
            batchState(() => {
              setForm("child")
              setFamily()
            })
          }} title={"Add Child"} />
        </ContainerPosition>
        {ParentTag}{SpouseTag}{Child}
      </div>
      <SliderBar ref={docsRef} docKey={'CONTACT'} docId={getId(family)} />
    </div>
  );
}
const mapStateToProps = (state) => ({
  family: getStoreFamily(state),
  userId: getStoreUserId(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchSetFamilyList: contact => dispatch(setFamilyData(contact)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Family);
