import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Drawer,
  AppBar,
  Toolbar,
  CssBaseline,
  IconButton,
  withStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import FooterTag from '../dashboard/Footer';
import HeaderBar from '../dashboard/Header';
import DrawerItems from '../dashboard/DrawerItems'
import CalenderSlots from '../dashboard/components/CalenderSlots'

const drawerWidth = 280;

class DrawerMenu extends React.Component {
  state = {
    open: false,
    index: null,
    userExpand: false,
    legalReview : false,
    calenderslot : false,
    };
  handleClick = e => {
    this.setState(state => {
      return { [e]: !state[e] };
    });
  };
  toggleCalenderSlot = () => {
    const currentState = this.state.calenderslot;
    this.setState({ calenderslot: !currentState });
    console.log(this.state.calenderslot)
  };
 
  getFullName = (e) => {
        let name = e.firstName + " " + e.middleName + " " + e.lastName;
        name = name.replace(/null/g, '')
        return name.replace(/ +/g, " ")
    }
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  toggleCalSlot = () => {
    this.toggleCalenderSlot()
    this.handleDrawerClose()
  };
  indexFind = index => {
    this.setState({ index });
    localStorage.setItem('index', index);
  };

  render() {
    const { classes, children } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          style={this.state.open ? { background: '#1b2e4a' } : { background: '#1b2e4a' }}
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open
          })}
        >
          <Toolbar disableGutters={!this.state.open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: this.state.open
              })}
            >
              <MenuIcon />
            </IconButton>
            <HeaderBar drawermenu={this.state.open}/>
          
          </Toolbar>
        </AppBar>
        <div className='d-none d-sm-block'>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open
            })
          }}
          open={this.state.open}
        >
        <DrawerItems  toggleModal={this.toggleCalenderSlot} userDetails={this.props.userDetails} role={this.props.role} open={this.state.open} handleDrawerClose={this.handleDrawerClose} handrawerClose={''}/>
        </Drawer>
        </div>

        <div className='res-hide-full'>
        {this.state.open ?
        <Drawer
          variant="persistent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open
            })
          }}
          open={this.state.open}
        >
        <DrawerItems  toggleModal={this.toggleCalSlot} userDetails={this.props.userDetails} role={this.props.role} open={this.state.open} handleDrawerClose={this.handleDrawerClose} handrawerClose={this.handleDrawerClose}/>
        </Drawer>
        : null
        }
        </div>
        
        {this.state.calenderslot === true ?
        <CalenderSlots name={this.props.userDetails.firstName} email={this.props.userDetails.primaryEmail} freeConsult={'true'} updateId={this.props.userDetails.id} toggleFreeConsultModal={this.toggleCalenderSlot} consultWho = {'Our Legal Experts'}/>
        : null }

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
          <div >
          <FooterTag/>
         </div>
        </main>
      </div>
    );
  }
}

DrawerMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const styles = theme => ({
  itemText: { paddingLeft: 0 },
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 10-5.7,
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  menuItem: {
    '&:focus': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  },
  menuIcon: {
    fontSize: 23
    // fontWeight: 400
  },
  subMenuIcon: {
    fontSize: 18
    // fontWeight: 350
  },
  purpleAvatar: {
    color: '#fff',
    backgroundColor: '#ff3939b0',
    marginTop: 8
  }
});

export default (withStyles(styles, { withTheme: true })(DrawerMenu));

