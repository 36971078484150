import { combineReducers } from 'redux';
import user from './userReducer';
import contacts from './contactReducer';
import family from './familyReducer';
import beneficiary from './benficiaryReducer';
import storage from 'redux-persist/lib/storage'
import nonDisInherit from './nonDisInheritReducer';
import executor from './executorReducer';
import liability from './liabilitiesReducer'
import disInherit from './disInheritReducer';
import allContacts from './allContactsReducer';
import finAssets from './finAssetsReducer';
import otherAssets from './otherAssetsReducer';
import immovableAssets from './immovableAssetsReducer';
import digitalAssets from './digitalAssetsReducer';
import finAssigns from './finAssignReducer';
import immPropAssigns from './immPropAssignReducer';
import otherAssigns from './otherAssignReducer';
import digitalAssigns from './digitalAssignReducer'
import speedometerData from './speedometerReducer'
import helpList from './helpListReducer'
import payment from './paymentReducer'
import orders from './orderReducer'
import userRolesData from './userRolesReducer'
import digilockerData from './digilockerReducer'
import digilockerFiles from './digilockerFileReducer'
import legalReview from './freeLegalReviewReducer'
import estatePlan from './freeEstatePlanReducer'
import willData from './willReducer'
import dataSharedByme from './dataSharedByMeReducer'
import dataShareWithme from './dataSharedWithMeReducer'
import dataSharedWithNames from './dataSharedWithNamesReducer'
import dataSharedBy from './dataShareByReducer'
import dataSharedByNames from './dataSharedByNamesReducer'
import { LOG_OUT } from '../actions/appActions';

const appReducer = combineReducers({ user, contacts, family, beneficiary, nonDisInherit, executor, disInherit, liability, finAssets, otherAssets, immovableAssets, digitalAssets, finAssigns, immPropAssigns, otherAssigns, digitalAssigns, allContacts, speedometerData, helpList, payment, userRolesData, digilockerData, digilockerFiles, orders, legalReview, estatePlan, willData, dataSharedByme, dataShareWithme, dataSharedWithNames, dataSharedBy, dataSharedByNames});
const rootReducer = (state, action) => {
    if (action.type === LOG_OUT) {
        // for all keys defined in your persistConfig(s)
        storage.removeItem('persist:root')
        // storage.removeItem('persist:otherKey')

        state = undefined;
    }
    return appReducer(state, action);
};
export default rootReducer;

