import React from 'react';
import SectionHeading from '../../home/component/layout/SectionHeading';

const Privacy = ()  => {

    let imgUrl = '../img/banner/greybackground.png';

    const PrivacyTerms = () =>{
        return(
            <div>
            <SectionHeading heading={'Privacy Policy'} subHeading={''} image={'../img/banner/plain_greybackground.png'}/>
            <p align='justify'>Your privacy is important to us. <br></br><br></br>
            This privacy statement explains the personal data EasyInherit processes, how EasyInherit processes it, 
            and for what purposes.Please read the product-specific details in this privacy statement, 
            which provide additional relevant information. <br></br><br></br>
            This statement applies to the interactions EasyInherit has with you and the EasyInherit products listed below, as well as other EasyInherit products that display this statement.</p><br></br><br></br>
            </div>
        )
    }
    return(
 <div className='container' style={{textAlign:'justify'}}>

        <div className='d-block d-sm-none'>         

        <div className = 'Component-Bg' 
                style = {{ backgroundImage: `url(${imgUrl})`,
                backgroundSize: 'cover', 
                backgroundPosition: 'left center',
                backgroundRepeat: 'no-repeat',
                }}> 
        
        <div style={{width:'100%', paddingLeft:'5%', paddingRight:'2%'}}>  
            <PrivacyTerms/>
        </div>
        </div>
        </div>

        <div className='d-none d-sm-block'>

        <div className = 'Component-Bg' 
                style = {{ backgroundImage: `url(${imgUrl})`,
                backgroundSize: 'cover', 
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                }}> 
          
        <div style={{width:'100%', paddingLeft:'35%', paddingRight:'2%',paddingTop:'40px'}}>  
            <PrivacyTerms/>
        </div>
        </div>
        </div>

            <br></br>
                    <p>We collect data from you, through our interactions with you and through our products. 
                        You provide some of this data directly, and we get some of it by collecting data 
                        about your interactions, use, and experiences with our products. <br></br><br></br>
                        The data we collect depends on the context of your interactions with 
                        EasyInherit and the choices you make, including your privacy settings and 
                        the products and features you use. We also obtain data about you from third parties.</p>

                    <p><b>Personal Data</b> - We DO NOT SHARE your personal data with anyone without your explicit permission and in the 
                        context of certain products. We share your personal information with our lawyers and legal experts in the context
                        of a Legal Consult AFTER we have mutually signed non-disclosures and a Power of Attorney. Such information is protected
                        and bound by the Attorney-Client privilege.
                    </p><br></br>

                    <p><b>Asset Information</b> - We DO NOT SHARE your asset information with anyone without your explicit permission and in the 
                        context of certain products. You have full control on permitting and withdrawing such permission, to the system to 
                        share data with a person or persons of your choice. At no point does EasyInherit or its employees have access to this data
                        or can further distribute this data.
                    </p><br></br>

                    <p><b>Cookies and Similar technologies</b> - Cookies are small text files placed on your device to store data that can be 
                        recalled by a web server in the domain that placed the cookie. We use cookies and similar technologies for storing and honouring your preferences and settings, enabling you to sign in,combating fraud and analysing how our products perform.
                        You have full control on refusing to install our cookies or restrict their usage without compromising any of the key 
                        functionality of the products.
                    </p><br></br>
                    <p>If case of any questions or clarifications, please write to us <u>info@easyinherit.in</u>. We would be happy to talk to you.
                    </p><br></br>
    </div>

    )
}


export default (Privacy);
