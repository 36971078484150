import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react'
import { ToastProvider } from 'react-toast-notifications'
import { CustomToast } from './components/CustomElements';
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ToastProvider components={{ Toast: CustomToast }} placement="bottom-right">
        <App />
      </ToastProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
