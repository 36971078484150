export const ADD_ESTATEPLAN_CONSTANTS = 'ADD_ESTATEPLAN_CONSTANTS';
export const setEstatePlanData = (estatePlan) => {

    return {
        type: ADD_ESTATEPLAN_CONSTANTS,
        estatePlan
    }
}

export const getEstatePlanData = state => state.estatePlan;
