import { useState, useEffect } from 'react';

export const useInput = (initialValue, prepopulate) => {
  const [value, setValue] = useState(!initialValue ? '' : initialValue);
  useEffect(()=>{
    setValue(!initialValue ? '' : initialValue)
  },[initialValue])

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onFocus:()=>{
        if(prepopulate && prepopulate==='mobile' && value==='')
          setValue('+91')
      },
      onBlur:()=>{
        if(prepopulate && prepopulate==='mobile' && value==='+91')
          setValue('')
      },
      onChange: event => {
        setValue(event.target.value);
      }
    }
  };
};