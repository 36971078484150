import React from 'react';
import Footer from './component/layout//Footer';
import Header from './component/layout/Header';
import TermsAndConditions from '../dashboard/components/TermsAndConditions';

const TermsAndCdt = () => {
    return (
        <div>
        <Header />
        <TermsAndConditions/>
        <Footer />
      </div>
    );
}
export default TermsAndCdt;