import React, { useImperativeHandle, forwardRef, useRef, useState } from 'react';
import { useInput } from '../../hooks/useInput';
import HeadBar from '../../components/HeaderBar';
import DropDown from '../../components/DropDown';
import CheckBox from '../../components/CheckBox';
import string from '../../constant/en.json';
import HelperModal from '../helper/HelperModal';
import DigilockerBtn from '../../components/DigilockerBtn'

const MarriageForm = ({ defaultValue }, ref) => {
  const refSpl = useRef();
  const { value: maritalStatus, bind: bindMarriage } = useInput(defaultValue.maritalStatus);
  const splMarriageFlag = defaultValue.splMarriageFlag;
  useImperativeHandle(ref, () => ({
    returnDetails() { return { maritalStatus, splMarriageFlag: refSpl.current.returnDetails() } }
  }));
  const opt=[{ code: 'Single', value: 'Single' }, { code: 'Married', value: 'Married' }, { code: 'Divorced', value: 'Divorced' }, { code: 'Divorced and Remarried', value: 'Divorced and Remarried' }, { code: 'Widow/Widower', value: 'Widow/Widower' }, { code: 'Other', value: 'Other' }]
  const opt1=[{ code: 'Married', value: 'Married' }, { code: 'Divorced', value: 'Divorced' }, { code: 'Divorced and Remarried', value: 'Divorced and Remarried' }, { code: 'Widow/Widower', value: 'Widow/Widower' }, { code: 'Other', value: 'Other' }]
  const [splflag, setSplflag] = useState(opt);
  const onAction = (e) => (e ? setSplflag(opt1) : setSplflag(opt));
  return <section className='section_form'>
    <HeadBar title={string.forms.commonFormFields.marriageStatus} />
    <div className='row' style={{marginLeft:'2px'}}>
    <CheckBox title={string.forms.personalDetailSpecific.specialMarriagesAct} onChange={(e) => onAction(e)} defaultValue={splMarriageFlag} ref={refSpl} fsize/>
    <HelperModal helperTextId={'splMarriage'} />
    </div>
    <div style={{marginTop:'5px'}} className='row'>
    <DropDown placeholder={string.forms.commonFormFields.pleaseSelect} options={splflag} bind={bindMarriage} />
    <DigilockerBtn/>
    </div>
  </section>
};
const Marriage = forwardRef(MarriageForm);
export default Marriage;