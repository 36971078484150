import initialState from './initialState'
import { ADD_DATASHARE_WITHNAMES_CONSTANTS } from '../actions/dataSharedWithNamesAction'
export default (state = initialState.dataSharedWithNames, action) => {
  switch (action.type) {
    case ADD_DATASHARE_WITHNAMES_CONSTANTS:
      return { ...state, ...action.dataSharedWithNames }
    default:
      return state
  }
}
