export const ADD_IMMOVABLE_ASSET_LIST = 'ADD_IMMOVABLE_ASSET_LIST';
export const CREATE_IMMOVABLE_ASSET = 'CREATE_IMMOVABLE_ASSET';
export const EDIT_IMMOVABLE_ASSET = 'EDIT_IMMOVABLE_ASSET';
export const DELETE_IMMOVABLE_ASSET = 'DELETE_IMMOVABLE_ASSET';
export const setImmovableAssetData = (immovableAssets) => {

    return {
        type: ADD_IMMOVABLE_ASSET_LIST,
        immovableAssets
    }
}
export const deleteImmovableAssetFromList = (immovableAssetId) => {

    return {
        type: DELETE_IMMOVABLE_ASSET,
        immovableAssetId
    }
}

export const addImmovableAsset = immovableAsset => ({ type: CREATE_IMMOVABLE_ASSET, immovableAsset });
export const editImmovableAsset = immovableAsset => ({ type: EDIT_IMMOVABLE_ASSET, immovableAsset });
export const getStoreImmovableAssetList = state => state.immovableAssets;
