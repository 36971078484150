import initialState from "./initialState";
import _ from 'lodash';
import { ADD_LIABILITY_LIST, DELETE_LIABILITY, CREATE_LIABILITY, EDIT_LIABILITY } from "../actions/liabilitiesActions";
import { contactToUiLoad } from "../objectMapping/contactMapping";
export default (state = initialState.liability, action) => {
    switch (action.type) {
        case ADD_LIABILITY_LIST:
            return action.liability.map(res => contactMap(res));

        case DELETE_LIABILITY:
            return _.uniqBy(state.filter(con => con.id !== action.liabilityId), 'id');
        case EDIT_LIABILITY:
            const newLiabs = state.map(l => {
                if (l.id === action.liability.id) {
                    return contactMap(action.liability)
                }
                return l
            })
            return newLiabs
        case CREATE_LIABILITY:
            return state.concat(contactMap(action.liability))
        default:
            return state;
    }
}

const contactMap = (liab) => ({ ...liab, contacts: contactToUiLoad(liab.contacts), userLiabilitiesBeneficiaries: (liab.userLiabilitiesBeneficiaries.map(c => ({ ...c, contacts: contactToUiLoad(c.contacts) }))).filter(c => c.active) })