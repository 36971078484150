import moment from 'moment'

export const userToUiLoad = (obj) => {
  const {
    perHomeAddress1,
    perHomeAddress2,
    perHomeAddressCity,
    perHomeAddressState,
    perHomeAddressCountry,
    perHomeAddressPin,
    currHomeAddress1,
    currHomeAddress2,
    currHomeAddressCity,
    currHomeAddressState,
    currHomeAddressCountry,
    currHomeAddressPin,
    splMarriageFlag,
    maritalStatus,
    primaryPhone,
    primaryEmail,
    secondaryPhone,
    userDocuments,
    gender,
    userName,
    religion,
    nationality,
    custField1,
    custField2,
    custField3,
    custField4,
    custField5,
    mobilePhone,
    id,
    dob,
    sameAddressFlag,
    idProofNumber,
    idProofType,
    firstName,
    middleName,
    lastName,
    fullName,
    title,
    domicile,
  } = obj

  return {
    id,
    dob: moment(dob).format('YYYY-MM-DD'),
    firstName,
    lastName,
    middleName,
    fullName,
    userName,
    primaryEmail,
    title,
    userDocuments: userDocuments.filter((u) => u.active),
    gender,
    religion,
    nationality,
    custField1,
    custField2,
    custField3,
    custField4,
    custField5,
    permanentAddress: getAddressMapped(
      perHomeAddress1,
      perHomeAddress2,
      perHomeAddressCity,
      perHomeAddressState,
      perHomeAddressCountry,
      perHomeAddressPin
    ),
    currentAddress: getAddressMapped(
      currHomeAddress1,
      currHomeAddress2,
      currHomeAddressCity,
      currHomeAddressState,
      currHomeAddressCountry,
      currHomeAddressPin
    ),
    domicile,
    splMarriageFlag,
    maritalStatus,
    primaryPhone,
    secondaryPhone,
    mobilePhone,
    sameAddressFlag,
    idProofNumber,
    idProofType,
  }
}

export const getAddressMapped = (
  houseName,
  street,
  city,
  state,
  country,
  postalCode
) => ({ houseName, street, city, state, country, postalCode })
