import React, { useImperativeHandle, forwardRef } from 'react';
import { useInput } from '../../hooks/useInput';
import HeadBar from '../../components/HeaderBar';
import DropDown from '../../components/DropDown';
import { getCodeValueMap } from '../../utils/commonUtil';
import string from '../../constant/en.json';
import HelperModal from '../helper/HelperModal';
import DigilockerBtn from '../../components/DigilockerBtn'

const ReligionNationalityForm = ({ defaultValue = { religion: '', nationality: '' } }, ref) => {
  const { value: religion, bind: bindReligion } = useInput(defaultValue.religion);
  const { value: nationality, bind: bindNationality } = useInput(defaultValue.nationality);
  useImperativeHandle(ref, () => ({
    returnDetails() { return { religion, nationality } }
  }));
  return <section className='section_form'>
    <div className="row" style={{marginLeft:'2px'}}>
      <HeadBar title={string.forms.commonFormFields.relAndNat} />
      <HelperModal helperTextId={'religionAndNationality'} />
    </div>
    <div className='row'>
      <DropDown placeholder={string.forms.commonFormFields.religion} options={getCodeValueMap(['Hindu', 'Buddhist', 'Sikh', 'Jain', 'Christian', 'Jewish', 'Parsi', 'Muslim', 'Other'])} bind={bindReligion} />
      <DropDown placeholder={string.forms.commonFormFields.nationality} options={[{ code: 'Indian', value: 'Indian' }, { code: 'Other', value: 'Other' }]} bind={bindNationality} />
      <DigilockerBtn/>
    </div>
  </section>
};
const ReligionNationality = forwardRef(ReligionNationalityForm);

export default ReligionNationality;