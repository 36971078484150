import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getStoreBeneficiaryList, setBeneficiaryData } from '../../../actions/beneficiaryActions';
import { getStoreDigitalAssetList } from '../../../actions/digitalAssetsActions';
import { getStoreDigitalAssignList, setDigitalAssignData, addDigitalAssign } from '../../../actions/digitalAssignActions';
import { getStoreFinAssetList } from '../../../actions/finAssetsActions';
import { getStoreFinAssignList, setFinAssignData, addFinAssign } from '../../../actions/finAssignActions';
import { getStoreImmovableAssetList } from '../../../actions/immovableAssetsActions';
import { getStoreImmPropAssignList, setImmPropAssignData, addImmPropAssign } from '../../../actions/immovablePropAssignActions';
import { getStoreOtherAssetList } from '../../../actions/otherAssetsActions';
import { getStoreOtherAssignList, setOtherAssignData, addOtherAssign } from '../../../actions/otherAssignActions';
import CheckBox from '../../../components/CheckBox';
import CollapseSection from '../../../components/CollapsibleSection';
import { HelpSection, HighlightedText, List } from '../../../components/CustomElements';
import HeadBar from '../../../components/HeaderBar';
import RadioBtnGrp from '../../../components/RadioButtonGroup';
import string from '../../../constant/en.json';
import { getStoreUserId, getStoreUserRole } from '../../../selectors/userSelectors';
import AssignForms, { SectionContainer } from './AssignForms';
import { createDigitalAssignment, createFinAssignment, createOtherAssignment, getDigitalAssignListFromServer, getFinAssignListFromServer, getImmovableAssignListFromServer, getOtherAssignListFromServer, createImmovableAssignment } from './assignService';
import { getBeneficiaryListFromServer } from '../benificiary/beneficiaryService';
import ModalContainer from '../../../home/component/layout/Modal';

const Assign = ({ beneficiariesList,dispatchSetBeneficiaryList, finAssetList, userId, finAssign, otherAssetList, immPropAssetList, digitalAssetList, digitalAssign,otherAssign, dispatchSetFinAssignList, dispatchSetImmPropAssignList, immPropAssign, dispatchSetOtherAssignList, dispatchSetDigitalAssignList, dispAddFin, dispAddDigital, dispAddOther, dispAddImmProp, userRole}) => {
  const [selectedAssets, setSelected] = useState([]);
  const [assignAll, setAssignAll] = useState('No');
  useEffect(()=>{
    
  })
  let immPropAssigned = new Set(immPropAssetList.map(obj => obj.id));
  immPropAssigned = immPropAssign.filter(obj => immPropAssigned.has(obj.assetId));

  let otherAssigned = new Set(otherAssetList.map(obj => obj.id));
  otherAssigned = otherAssign.filter(obj => otherAssigned.has(obj.assetId));

  let digitalAssigned = new Set(digitalAssetList.map(obj => obj.id));
  digitalAssigned = digitalAssign.filter(obj => digitalAssigned.has(obj.assetId));

  let socAssigned = digitalAssign.filter(e=>e.assetLookupCode === 'SOC'),
  webAssigned = digitalAssign.filter(e=>e.assetLookupCode === 'WEB'),
  artefactsAssigned = otherAssign.filter(e=>e.assetLookupCode === 'ARTI'),
  jewlAssigned = otherAssign.filter(e=>e.assetLookupCode === 'JEWL'),
  artAssigned = otherAssign.filter(e=>e.assetLookupCode === 'ART'),
  lockAssigned = otherAssign.filter(e=>e.assetLookupCode === 'LOCK'),
  vehAssigned = otherAssign.filter(e=>e.assetLookupCode === 'VEH'),
  persAssigned = otherAssign.filter(e=>e.assetLookupCode === 'PERS');

  let restOfAssets = [].concat(digitalAssetList).concat(otherAssetList).concat(immPropAssetList);
  let restOfAssign = [].concat(digitalAssign).concat(otherAssign).concat(immPropAssign);
  let assigned1 = new Set(restOfAssets.map(obj => obj.id));
  let assigned = restOfAssign.filter(obj => assigned1.has(obj.assetId));
  let assignmentListRest = restOfAssets.filter(c => _.findIndex(_.flattenDeep(assigned), { assetId: _.get(c, ['id']) })===-1)
  let finAssignAll = [];
  finAssignAll = _.uniqBy([].concat(...Object.values(finAssign)),'assetId')
  let assigned2 = new Set(finAssignAll.map(obj => obj.assetId));
  let assignedFin = finAssignAll.filter(obj => assigned2.has(obj.assetId));
  let assignmentListFin = finAssetList.filter(c => _.findIndex(_.flattenDeep(assignedFin), { assetId: _.get(c, ['id']) })===-1)
  const [load, setLoad] = useState(true)
  const onCheck = (e) => {
    setSelected([e, ...selectedAssets]);
  };
  const onUnCheck = (e) => {
    setSelected(_.filter(selectedAssets, (s) => s !== e));
  };
  const receiverFlag = userRole==='GIVER' ? false : true
  const onAction = (e, id) => (e ? onCheck(id) : onUnCheck(id));
  useEffect(()=>{
    setTimeout(()=>{
      setLoad(false)
    },500)
    getBeneficiaryListFromServer(userId, receiverFlag)
      .then(response => {
        dispatchSetBeneficiaryList(response.data)
        // loading(false) 
      })
      .catch(error => {
        alert(error);
        // loading(false);
      })
    getFinAssignListFromServer(userId, receiverFlag)
    .then(resp=>{
      //console.log(resp.data)
      if(!_.isEmpty(resp.data))
        dispatchSetFinAssignList(resp.data)
    })
    .catch(err=>{
      //console.log(err)
    })
    getImmovableAssignListFromServer(userId, receiverFlag)
    .then(resp=>{
      //console.log(resp.data)
      if(!_.isEmpty(resp.data))
        dispatchSetImmPropAssignList(resp.data)
    })
    .catch(err=>{
      //console.log(err)
    })
    getOtherAssignListFromServer(userId, receiverFlag)
    .then(resp=>{
      //console.log(resp.data)
        dispatchSetOtherAssignList(resp.data)
    })
    .catch(err=>{
      //console.log(err)
    })
    getDigitalAssignListFromServer(userId, receiverFlag)
    .then(resp=>{
      //console.log(resp.data)
        dispatchSetDigitalAssignList(resp.data)
    })
    .catch(err=>{
      //console.log(err)
    })
  },[])
  let fds =[],
  phyShares=[],
  demat=[],
  govtSchemes=[],
  portfolio=[],
  bankAccounts=[],
  insurance=[],
  mfs=[],
  bonds=[],
  pfs=[],
  gratuity=[],
  artefacts=[],
  jewellery=[],
  art=[],
  lockers=[],
  vehicles=[],
  perEffect=[],
  social=[],
  web=[];
  if(finAssetList.length >0){
     fds = finAssetList.filter(e=>e.assetLookupCode === 'FD');
     phyShares = finAssetList.filter(e=>e.assetLookupCode === 'SHR');
     demat = finAssetList.filter(e=>e.assetLookupCode === 'DEM');
     govtSchemes = finAssetList.filter(e=>e.assetLookupCode === 'GOV');
     portfolio = finAssetList.filter(e=>e.assetLookupCode === 'PMS');
     bankAccounts = finAssetList.filter(e=>e.assetLookupCode === 'BNK');
     insurance = finAssetList.filter(e=>e.assetLookupCode === 'INS');
     mfs = finAssetList.filter(e=>e.assetLookupCode === 'MF');
     bonds = finAssetList.filter(e=>e.assetLookupCode === 'BON');
     pfs = finAssetList.filter(e=>e.assetLookupCode === 'PF');
     gratuity = finAssetList.filter(e=>e.assetLookupCode === 'GRA');
  }
  if(otherAssetList.length >0){
    artefacts = otherAssetList.filter(e=>e.assetLookupCode === 'ARTI');
    jewellery = otherAssetList.filter(e=>e.assetLookupCode === 'JEWL');
    art = otherAssetList.filter(e=>e.assetLookupCode === 'ART');
    lockers = otherAssetList.filter(e=>e.assetLookupCode === 'LOCK');
    vehicles = otherAssetList.filter(e=>e.assetLookupCode === 'VEH');
    perEffect = otherAssetList.filter(e=>e.assetLookupCode === 'PERS');
 }
 if(digitalAssetList.length >0){
  social = digitalAssetList.filter(e=>e.assetLookupCode === 'SOC');
  web = digitalAssetList.filter(e=>e.assetLookupCode === 'WEB');
}
if(load) return (<ModalContainer visible={load}>
    <span className="sr-only">Loading...</span>
  </ModalContainer>);
 return (
    <div >
      <HelpSection heading="HELP TIPS!">
        <List>
          {string.forms.assign.assignHelpTip}
          <HighlightedText>{string.forms.assign.assignHighlight}</HighlightedText>
        </List>
      </HelpSection>
      {/* map in case of multiple section */}
      <CollapseSection mainTitle={'Assets Beneficiary Assignment'}>
      {finAssetList.length > 0 && assignmentListFin.length === 0 && restOfAssets.length > 0 && assignmentListRest.length ===0 ? null:
        <div>
          <HeadBar title={'How do you want to assign your assets?'} />
        
        <div className="row" style={{ marginBottom :20}}>
          <RadioBtnGrp
            options={[
              { value: 'Yes', display: 'Assign all my asset at once' },
              { value: 'No', display: 'Let me choose my assets for assignment.' },
            ]}
            defaultValue={assignAll}
            onChange={setAssignAll}
          />
        </div>
        </div>}
        {assignAll === 'Yes' ? <div>
          {finAssetList.length > 0 && assignmentListFin.length > 0 ?
          <AssignAllAssets beneficiariesList={beneficiariesList} assignmentList={assignmentListFin} asset="Financial Assets" dispAddFin={dispatchSetFinAssignList} userId={userId}/>
          :null}
          {restOfAssets.length > 0 && assignmentListRest.length >0 ? <AssignAllAssets beneficiariesList={beneficiariesList} assignmentList={assignmentListRest} asset="Digital/Other/Immovable Property Assets" dispAddDigital={dispAddDigital} dispAddImmProp={dispAddImmProp} dispAddOther={dispAddOther} userId={userId} />
        :null}
        {finAssetList.length > 0 && assignmentListFin.length === 0 && restOfAssets.length > 0 && assignmentListRest.length ===0 ?
        <HelpSection>All assets have been assigned.</HelpSection>
        :null}
        </div> :
        <div className="card-block">
          <div
            className="card-header"
            style={{
              fontSize: 15,
              color: '#007bff',
            }}
          >
            Please select from the Assets listed below -
          </div>
          <div className="row" style={{ marginTop: '10px', wordWrap:'break-word' }}>
            <div className="col-xl-3 col-md-6 col-lg-3">
              <h5 className="size14">Financial Assets</h5>
              <CheckBox defaultValue={_.size(finAssign['FD'])>0 } disabled={_.size(finAssign['FD']) > 0 ||_.size(fds) === 0} onChange={(e) => onAction(e, 0)} title={_.size(finAssign['FD']) > 0 ?<span>Fixed Deposits<i className="fa fa-check" aria-hidden="true"/></span>: 'Fixed Deposits'} />
              <CheckBox  defaultValue={_.size(finAssign['SHR'])>0 } disabled={_.size(finAssign['SHR']) > 0 ||_.size(phyShares) === 0 } onChange={(e) => onAction(e, 1)} title={_.size(finAssign['SHR']) > 0 ?<span>Physical Share<i className="fa fa-check" aria-hidden="true"/></span> :'Physical Shares'} />
              <CheckBox  defaultValue={_.size(finAssign['DEM'])>0 } disabled={_.size(finAssign['DEM']) > 0 ||_.size(demat) === 0 } onChange={(e) => onAction(e, 2)} title={_.size(finAssign['DEM']) > 0 ?<span>Demat Account<i className="fa fa-check" aria-hidden="true"/></span> : 'Demat Account'} />
              <CheckBox  defaultValue={_.size(finAssign['GOV'])>0 } disabled={_.size(finAssign['GOV']) > 0 ||_.size(govtSchemes) === 0 } onChange={(e) => onAction(e, 3)} title={_.size(finAssign['GOV']) > 0 ?<span>Government saving schemes<i className="fa fa-check" aria-hidden="true"/></span>: 'Government saving schemes'} />
              <CheckBox  defaultValue={_.size(finAssign['BNK'])>0 } disabled={_.size(finAssign['BNK']) > 0 ||_.size(bankAccounts) === 0 } onChange={(e) => onAction(e, 4)} title={_.size(finAssign['BNK']) > 0 ? <span>Bank Account<i className="fa fa-check" aria-hidden="true"/></span>: 'Bank Account'} />
              <CheckBox  defaultValue={_.size(finAssign['PMS'])>0 } disabled={_.size(finAssign['PMS']) > 0 ||_.size(portfolio) === 0 } onChange={(e) => onAction(e, 13)} title={_.size(finAssign['PMS']) > 0 ? <span>Portfolio Management Services<i className="fa fa-check" aria-hidden="true"/></span> : 'Portfolio Management Services'} />
            </div> 
            <div className="col-xl-3 col-md-6 col-lg-3">
              <br/>
              <CheckBox  defaultValue={_.size(finAssign['INS'])>0 } disabled={_.size(finAssign['INS']) > 0 ||_.size(insurance) === 0 } onChange={(e) => onAction(e, 5)} title={_.size(finAssign['INS']) > 0 ?<span>Insurance Policy<i className="fa fa-check" aria-hidden="true"/></span> : 'Insurance Policy'} />
              <CheckBox  defaultValue={_.size(finAssign['MF'])>0 } disabled={_.size(finAssign['MF']) > 0 ||_.size(mfs) === 0 } onChange={(e) => onAction(e, 6)} title={_.size(finAssign['MF']) > 0 ?<span>Mutual Funds Units[Physical]<i className="fa fa-check" aria-hidden="true"/></span> : 'Mutual Funds Units[Physical]'} />
              <CheckBox  defaultValue={_.size(finAssign['BON'])>0 } disabled={_.size(finAssign['BON']) > 0 ||_.size(bonds) === 0 } onChange={(e) => onAction(e, 7)} title={_.size(finAssign['BON']) > 0 ?<span>Bonds<i className="fa fa-check" aria-hidden="true"/></span> : 'Bonds'} />
              <CheckBox  defaultValue={_.size(finAssign['PF'])>0 } disabled={_.size(finAssign['PF']) > 0 ||_.size(pfs) === 0 } onChange={(e) => onAction(e, 8)} title={_.size(finAssign['PF']) > 0 ?<span>Provident Fund<i className="fa fa-check" aria-hidden="true"/></span> : 'Provident Fund'} />
              <CheckBox defaultValue={_.size(finAssign['GRA'])>0 } disabled={_.size(finAssign['GRA']) > 0 ||_.size(gratuity) === 0 } onChange={(e) => onAction(e, 9)} title={_.size(finAssign['GRA']) > 0 ?<span>Gratuity<i className="fa fa-check" aria-hidden="true"/></span>: 'Gratuity'} />
            </div>
            <div className="col-xl-3 col-md-6 col-lg-3">
            <h5 className="size14">Immovable Assets</h5>
              <CheckBox defaultValue={_.size(immPropAssign) > 0 && _.size(immPropAssign) === _.size(immPropAssetList)} disabled={_.size(immPropAssetList) === 0 || _.size(immPropAssign) === _.size(immPropAssetList) } onChange={(e) => onAction(e, 10)} title={_.size(immPropAssetList) > 0  && _.size(immPropAssign) === _.size(immPropAssetList) ? <span>Immovable Properties<i className="fa fa-check" aria-hidden="true"/></span> : 'Immovable Properties'} />
              <br/>
              <h5 className="size14">Digital Assets</h5>
              <CheckBox defaultValue={_.size(socAssigned) > 0 && _.size(socAssigned) === _.size(social)} disabled={_.size(social) === 0 || _.size(socAssigned) === _.size(social) } onChange={(e) => onAction(e, 11)} title={_.size(social) > 0 && _.size(socAssigned) === _.size(social) ? <span>Social Media/Email Account<i className="fa fa-check" aria-hidden="true"/></span> : 'Social Media/Email Account'} />
              <CheckBox defaultValue={_.size(webAssigned) > 0 && _.size(webAssigned) === _.size(web)} disabled={_.size(web) === 0 || _.size(webAssigned) === _.size(web) } onChange={(e) => onAction(e, 12)} title={_.size(web) > 0 && _.size(webAssigned) === _.size(web) ? <span>Websites<i className="fa fa-check" aria-hidden="true"/></span> :'Websites'} />
            </div>
            <div className="col-xl-3 col-md-6 col-lg-3">
            <h5 className="size14">Other Assets</h5>
              <CheckBox defaultValue={_.size(artefacts) > 0 && _.size(artefactsAssigned) === _.size(artefacts)} disabled={_.size(artefacts) === 0 || _.size(artefactsAssigned) === _.size(artefacts) }onChange={(e) => onAction(e, 14)} title={_.size(artefacts) > 0 && _.size(artefactsAssigned) === _.size(artefacts) ? <span>Artefacts<i className="fa fa-check" aria-hidden="true"/></span> :'Artefacts'} />
              <CheckBox defaultValue={_.size(jewellery) > 0 && _.size(jewlAssigned) === _.size(jewellery)} disabled={_.size(jewellery) === 0 || _.size(jewlAssigned) === _.size(jewellery) } onChange={(e) => onAction(e, 15)} title={_.size(jewellery) > 0 && _.size(jewlAssigned) === _.size(jewellery) ? <span>Jewellery<i className="fa fa-check" aria-hidden="true"/></span> :'Jewellery'} />
              <CheckBox defaultValue={_.size(artAssigned) > 0 && _.size(artAssigned) === _.size(art)} disabled={_.size(art) === 0 || _.size(artAssigned) === _.size(art) }onChange={(e) => onAction(e, 16)} title={_.size(art) > 0 && _.size(artAssigned) === _.size(art) ? <span>Art<i className="fa fa-check" aria-hidden="true"/></span> :'Art'} />
              <CheckBox defaultValue={_.size(lockAssigned) > 0 && _.size(lockAssigned) === _.size(lockers)} disabled={_.size(lockers) === 0 || _.size(lockAssigned) === _.size(lockers) } onChange={(e) => onAction(e, 17)} title={_.size(lockers) > 0 && _.size(lockAssigned) === _.size(lockers) ? <span>Bank Lockers<i className="fa fa-check" aria-hidden="true"/></span> :'Bank Lockers'} />
              <CheckBox defaultValue={_.size(vehAssigned) > 0 && _.size(vehAssigned) === _.size(vehicles)} disabled={_.size(vehicles) === 0 || _.size(vehAssigned) === _.size(vehicles) } onChange={(e) => onAction(e, 18)} title={_.size(vehicles) > 0 && _.size(vehAssigned) === _.size(vehicles) ? <span>Vehicles<i className="fa fa-check" aria-hidden="true"/></span> :'Vehicles'} />
              <CheckBox defaultValue={_.size(persAssigned) > 0 && _.size(persAssigned) === _.size(perEffect)} disabled={_.size(perEffect) === 0 || _.size(persAssigned) === _.size(perEffect) } onChange={(e) => onAction(e, 19)} title={_.size(perEffect) > 0 && _.size(persAssigned) === _.size(perEffect) ? <span>Personal Effects<i className="fa fa-check" aria-hidden="true"/></span> :'Personal Effects'} />
              {/* <h5 className="size14">Receivable Assets</h5>
              <CheckBox onChange={(e) => onAction(e, 20)} title={'Receivables(From Loans)'} /> */}
            </div>
          </div>
        </div>
        }
        <br />
        <p>To review your bequethments please check the <a href='/bequethmentSummary'> Bequethment Summary </a> tab.</p>
      </CollapseSection>
      <AssignForms selectedAssets={selectedAssets} onAction={onAction}  />
    </div>
  );
};


const AssignAllAssets = ({beneficiariesList, assignmentList,asset, dispAddOther, dispAddImmProp, dispAddFin, dispAddDigital, userId, assigned }) =>{
  const refAllAssign = useRef();
  const beneficiaryData = beneficiariesList.map(e=>{ return {userId:e.userId, contacts: e.contacts, assetPercentage:'', isChecked:false, userBeneficiariesId:e.id}})
  const onSubmit = (i) => {
    let payload = []
      payload = refAllAssign.current.returnDetails().filter(e=>e.isChecked===true).map(p=>{
        return assignmentList.map(e=>{
          return {...p, contacts:{id: p.contacts.id},assetId: e.id, assetLookupCode: e.assetLookupCode, assetIssuerName: e.assetIssuerName ? e.assetIssuerName : e.assetAddress1? e.assetAddress1 : e.assetDetail , assetDescription: e.assetDescription? e.assetDescription : '' }
        })
      })
    payload = [].concat(...payload)
    //console.log(payload)
    let sum = payload.map(v => {
        return (
            parseFloat(v.assetPercentage))
        })
    .reduce(function (a, b) {
        return a + b;
    }, 0);
        if(sum/assignmentList.length > 100)
            alert('Ratio Exceeded maximum Limit! Please maintain the ratio within 100')
        else if(sum/assignmentList.length < 100)
            alert('The sum of the ratio of all the beneficiaries should be equal to 100, please check.')
        else if(sum/assignmentList.length === 100){
          try{
            if(asset==='Financial Assets')
            createFinAssignment(payload.filter(e=>e.assetLookupCode === 'FD' || e.assetLookupCode === 'SHR  ' ||
             e.assetLookupCode === 'DEM'||  e.assetLookupCode === 'GOV' || e.assetLookupCode === 'BNK' || 
             e.assetLookupCode === 'PMS' || e.assetLookupCode === 'INS' || e.assetLookupCode === 'MF' ||
              e.assetLookupCode === 'BON' || e.assetLookupCode === 'PF' || e.assetLookupCode === 'GRA'  ))
            .then(resp=>{
                //console.log(resp.data)
                alert('Assigned Financial Assets')
                getFinAssignListFromServer(userId)
                .then(resp=>{
                  //console.log(resp.data)
                  if(!_.isEmpty(resp.data))
                    dispAddFin(resp.data)
                })
                .catch(err=>{
                  //console.log(err)
                })
                    
            })
            .catch(err=>{
                //console.log(err)
                alert(err)
            })
            else{
              if(payload.filter(e=>e.assetLookupCode === 'PROP' ).length >0)
            createImmovableAssignment(payload.filter(e=>e.assetLookupCode === 'PROP' ))
            .then(resp=>{
                //console.log(resp.data)
                alert('Assigned Immovable Property')
                dispAddImmProp(resp.data)
                
            })
            .catch(err=>{
                //console.log(err)
                alert(err)
            })
            if(payload.filter(e=>e.assetLookupCode === 'SOC' || e.assetLookupCode === 'WEB' ).length >0)
            createDigitalAssignment(payload.filter(e=>e.assetLookupCode === 'SOC' || e.assetLookupCode === 'WEB' ))
            .then(resp=>{
                //console.log(resp.data)
                alert('Assigned Digital Assets')
                dispAddDigital(resp.data)
            })
            .catch(err=>{
                //console.log(err)
                alert(err)
            })
            if(payload.filter(e=>e.assetLookupCode === 'ARTI' || e.assetLookupCode === 'ART' ||
            e.assetLookupCode === 'VEH'||  e.assetLookupCode === 'LOCK' || e.assetLookupCode === 'JEWL' || 
            e.assetLookupCode === 'PERS' ).length >0)
            createOtherAssignment(payload.filter(e=>e.assetLookupCode === 'ARTI' || e.assetLookupCode === 'ART' ||
             e.assetLookupCode === 'VEH'||  e.assetLookupCode === 'LOCK' || e.assetLookupCode === 'JEWL' || 
             e.assetLookupCode === 'PERS'  ))
            .then(resp=>{
                //console.log(resp.data)
                alert('Assigned Other Assets')
                dispAddOther(resp.data)
            })
            .catch(err=>{
                //console.log(err)
                alert(err)
            })
          }
        }
      catch(error){
          alert(error)
      }
    }
    }
  return(
    <div>
      <SectionContainer mainTitle={ "Assign All "+ asset } data={beneficiaryData} ref={refAllAssign} onSubmit={onSubmit} disabled={asset !== "Financial Assets"} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  immPropAssetList: getStoreImmovableAssetList(state),
  finAssetList: getStoreFinAssetList(state),
  otherAssetList: getStoreOtherAssetList(state),
  digitalAssetList : getStoreDigitalAssetList(state),
  beneficiariesList: getStoreBeneficiaryList(state),
  userId: getStoreUserId(state),
  finAssign : getStoreFinAssignList(state),
  immPropAssign: getStoreImmPropAssignList(state),
  otherAssign: getStoreOtherAssignList(state),
  digitalAssign: getStoreDigitalAssignList(state),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchSetBeneficiaryList: beneficiary => dispatch(setBeneficiaryData(beneficiary)),
  dispatchSetFinAssignList: finAssign=> dispatch(setFinAssignData(finAssign)),
  dispatchSetImmPropAssignList: immProp => dispatch(setImmPropAssignData(immProp)),
  dispatchSetDigitalAssignList:digitalAssign=>dispatch(setDigitalAssignData(digitalAssign)),
  dispatchSetOtherAssignList:otherAssign=>dispatch(setOtherAssignData(otherAssign)),
  dispAddOther:otherAssign=>dispatch(addOtherAssign(otherAssign)),
  dispAddDigital:digitalAssign=>dispatch(addDigitalAssign(digitalAssign)),
  dispAddImmProp: immProp => dispatch( addImmPropAssign(immProp)),
  dispAddFin: finAssign=> dispatch(addFinAssign(finAssign)),


})
 export default connect(mapStateToProps, mapDispatchToProps)(Assign);
