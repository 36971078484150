import React, { forwardRef, useState } from 'react';
import CollapseSection from "../../../../components/CollapsibleSection";
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import { useInput } from "../../../../hooks/useInput";
import DropDown from '../../../../components/DropDown';
import { HoldingTypeTag } from '../../../components/HoldingTypeTag';
import string from '../../../../constant/en.json';
import { batchState, getId, getContactFullName } from '../../../../utils/commonUtil';
import { connect } from 'react-redux';
import { ContainerPosition, CustomButtom, TableContainer, CenterAlignRow, AlignColumn, ActionItemUpload } from '../../../../components/CustomElements';
import { getStoreUserId, getStoreUserRole } from '../../../../selectors/userSelectors';
import { useRef } from 'react';
import PickContactTable from '../../../components/PickContactTable';
import { getStoreFinAssetList, addFinAsset, deleteFinAssetFromList, editFinAsset } from '../../../../actions/finAssetsActions';
import { updateFinAssetById, createFinAsset, deleteFinAsset } from '../finAssetService';
import { getStoreContactList } from '../../../../actions/contactActions';
import { getStoreFamily } from '../../../../actions/familyActions';
import _ from 'lodash';
import HelperModal from '../../../helper/HelperModal';
import SliderBar from '../../../../components/UploadComponent';
import { useQuaishToasts } from '../../../../actions/alertAction';
import ModalContainer from '../../../../home/component/layout/Modal'
import DataShareModal from '../../../../dashboard/forms/dataShare/DataShareModal'

const BankAccount = ({ userId, pickList, bankFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, dispatchDeleteFinAsset, userRole }, ref) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [bank, setBank] = useState();
  let [visibleDataShare, setVisibleDataShare] = useState(false)
  let toggleDataShare = () => setVisibleDataShare(!visibleDataShare)
  const [dataShareId, setDataShareId] = useState('')

  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && bank && bank.id) {
        setBank(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setBank(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
      setBank(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const onShare = (c) => {
    setDataShareId(c)
    toggleDataShare()
  }
       
  const docsRef = React.useRef()
  const onUpload = (c) => {
    docsRef.current.submit('BNK', c.id, "Upload Files")
  }
  return (
    <div>
        <SavedBankAcounts onUpload={onUpload} data={bankFinAssetList} onEdit={onEdit} onShare={onShare} isFormHidden={isFormHidden} dispatchDeleteFinAsset={dispatchDeleteFinAsset} />
        <div style={{ marginBottom: 20, display: 'block' }}>
          <ContainerPosition>
            <CustomButtom event={() => toggleForm()} title={'Add Bank Accounts'} />
          </ContainerPosition>
        </div>
        {isFormHidden ? null : <BankAccountForm bank={bank} userId={userId} onCancel={toggleForm} onDone={onDone} bankFinAssetList={bankFinAssetList} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole}/>}
      <SliderBar ref={docsRef} />
      {visibleDataShare
                  ? 
                  <ModalContainer visible={visibleDataShare} toggle={toggleDataShare}>
                    <DataShareModal visibleDataShare={visibleDataShare} toggleDataShare={toggleDataShare} dataShareId={dataShareId} AssetList={bankFinAssetList} assetLookupKey={"asset_fin"} assetLookupCode={'BNK'}/>
                  </ModalContainer> 
                  : null 
        }
    </div>
  );
}

const BankAccFrm = ({ bank, userId, onCancel, onDone, bankFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, pickList, userRole, displayNominee }, ref) => {
  const { value: assetLookupSubcode, bind: bindAccType } = useInput(!bank ? '' : bank.assetLookupSubcode);
  const { value: assetIssuerName, bind: bindName } = useInput(!bank ? '' : bank.assetIssuerName);
  const { value: assetIdentificationNumber, bind: bindAccNo } = useInput(!bank ? '' : bank.assetIdentificationNumber);
  const { value: assetIssuerAddress, bind: bindBranch } = useInput(!bank ? '' : bank.assetIssuerAddress);
  const { value: assetHoldingType, bind: bindHolding } = useInput(!bank ? '' : bank.assetHoldingType);
  const { value: pickedNominee, bind: bindNominee } =useInput(userRole === 'RECEIVER' ? null : !bank ? 'INDV' : bank['contacts']['contactLookupCode']);
  const selectedId =  userRole === 'RECEIVER' ? null : !bank ? '' : bank['contacts']['id']
  const refPickNominee = useRef();
  const { success, error, removeAll } = useQuaishToasts()
  /*const validate = (payload) => {
    const ev = []
    for (var key in payload) {
      switch (key) {
        case 'assetLookupSubcode':
          _.isEmpty(payload[key]) ? ev.push(string.forms.bankAccount.emptyAccountType) : null
          break
        case 'assetIssuerName':
          _.isEmpty(payload[key]) ? ev.push(string.forms.bankAccount.emptyBankName) : null
          break
        case 'assetIssuerAddress':
          _.isEmpty(payload[key]) ? ev.push(string.forms.bankAccount.emptyBankBranchName) : null
          break
        case 'assetIdentificationNumber':
          _.isEmpty(payload[key]) ? ev.push(string.forms.bankAccount.emptyAccountId) : null
          break
        case 'assetHoldingType':
          _.isEmpty(payload[key]) ? ev.push(string.forms.bankAccount.emptyBankHolding) : null
          break
        case 'contacts':
          payload[key]['id'] === undefined ? ev.push(string.forms.bankAccount.emptyNominee) : null
          break
        default:
          break
      }
    }
    if (ev.length > 0) {
      warn(ev)
      return false
    }
    else return true
}*/

  const submit = () => {
    removeAll()
    const roleOfUser= userRole==='GIVER' ? false : true
    const payload = {
      userId,
      assetLookupCode: 'BNK',
      receiverFlag:	roleOfUser,
      assetLookupSubcode: assetLookupSubcode === '' ? null : assetLookupSubcode,
      assetIssuerName,
      assetIssuerAddress,
      assetIdentificationNumber,
      assetHoldingType: assetHoldingType === '' ? null : assetHoldingType,
      contacts: roleOfUser ? null : displayNominee === 'no' ? _.isEmpty(bank) || _.isEmpty(bank.contacts) ? null : { id: bank['contacts']['id'] } : { id: _.head(refPickNominee.current.returnDetails()) }
    }
    console.log(payload)
    //if (validate(payload))

      getId(bank) ? updateFinAssetById(bank.id, { id: bank.id, ...payload })
        .then(response => {
          success('Record Updated Successfully');
          dispatchEditFinAsset(response.data)
          onDone()
        }).catch(err => {
          error(err)
        }) :
        createFinAsset(payload)
          .then(response => {
            success('Record Created Successfully');
            dispatchAddFinAsset(response.data);
            onDone()
            // dispatchSetAllContactList(response.data);
          })
          .catch(err => {
            error(err)
          })
  }
  return (
    <CollapseSection mainTitle={'Enter ' + string.forms.bankAccount.bankAccountFormTitle} >
      <section className='section_form'>

        <div>
          <HeadBar title={string.forms.bankAccount.accountType} subtitle={'*'} />
        </div>
        <div className='row'>
          <DropDown placeholder={string.forms.commonFormFields.pleaseSelect} options={[{
            "lookupKey": "fin_asset_acc_type",
            "code": "ACC_CURR",
            "value": "Current"
          },
          {
            "lookupKey": "fin_asset_acc_type",
            "code": "ACC_SAV",
            "value": "Savings"
          }]} bind={bindAccType} />
        </div>
        <div>
          <HeadBar title={string.forms.bankAccount.bankName} subtitle={'*'} />
        </div>
        <div className='row'>
          <Input type='text' placeholder={string.forms.bankAccount.bankName} isBigger bind={bindName} />
        </div>
        <div>
          <HeadBar title={string.forms.bankAccount.bankAccountNo} subtitle={''} />
        </div>
        <div className='row'>
          <Input type='text' placeholder={string.forms.bankAccount.bankAccountNo} isBigger bind={bindAccNo} />
        </div>
        <div>
          <HeadBar title={string.forms.bankAccount.bankBranchName} subtitle={'*'} />
        </div>
        <div className='row'>
          <Input type='text' placeholder={string.forms.bankAccount.bankBranchName} isBigger bind={bindBranch} />
        </div>
        <HoldingTypeTag bind={bindHolding} />
        {userRole === 'GIVER' && displayNominee !== 'no'?
      <div>
        <div className="row" style={{ marginLeft: '2px' }}>
          <HeadBar title={'Who is the nominee (as declared in the bank account). If you do not recall, please check with your bank. Avoid conflict with Nominee and Beneficiary.'} subtitle={''} />
          <HelperModal helperTextId={"nominee"} />
        </div>
        <div className='row'>
          <DropDown options={[{ value: 'Individual', code: 'INDV' }, { value: 'Institution', code: 'INST' }]} placeholder={string.forms.commonFormFields.nominee} isBigger bind={bindNominee} />
        </div>
        <PickContactTable isSingle data={pickList.filter(c => _.get(c, ['contactLookupCode']) === pickedNominee)} ref={refPickNominee}  selected={[selectedId]} />
        </div>
      : null
    }
      </section>
      <ContainerPosition>
        <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
        <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
      </ContainerPosition>
    </CollapseSection>
  );
}
export function  SavedBankAcounts ({ onUpload,data, dispatchDeleteFinAsset, onEdit, dispalyActions, onShare, datashare, dataShareByMe, accessLevelArray, toggle }) {
  const { success, error } = useQuaishToasts()
  const deleteBnk = (id) => {
    if (window.confirm("Delete?\n Are you sure you want to delete this record?")) {
      deleteFinAsset(id)
        .then(response => {
          success('Record Deleted Successfully');
          dispatchDeleteFinAsset(id);

        })
        .catch(err => {
          error(err)
        })
    }
  }

  const row = data.map((e) => {
    return (

      <TableContainer>
        <CenterAlignRow>
          <AlignColumn label="Bank Name" text={e.assetIssuerName} />
          <AlignColumn label="Account No." text={e.assetIdentificationNumber} />
          <AlignColumn label="Nominee" text={e.contacts !== null ? getContactFullName(e.contacts) : ''} />
          {datashare === 'yes' ? <AlignColumn label="Shared with" text={_.isEmpty(dataShareByMe[e.id][0]) ? null : dataShareByMe[e.id][0].map((f,index)=>(<span style={{cursor:'pointer', color: '#007bff'}} onClick={()=>toggle(dataShareByMe[e.id][1][index])}><span style={{textDecoration:'underline'}}>{f}</span><span>{index !== (dataShareByMe[e.id][0].length) -1 ? ' , ' : ''}</span></span>))} />: null}
          {datashare === 'shareby' ? <AlignColumn label="Shared By" text={dataShareByMe[e.id]} />: null}
          {dispalyActions === 'no' || datashare === 'yes' || (datashare === 'shareby' && accessLevelArray[e.id]==='READ')  ? null :
          <AlignColumn label="Action" text={<ActionItemUpload onUpload={onUpload} onEdit={() => onEdit(e)} onDelete={() => deleteBnk(e.id)} onShare={() => onShare(e.id)} datashare={datashare}/>} />}
          </CenterAlignRow>
      </TableContainer>
    )
  });
  return (
    data.length === 0 ? null :
    <div>
    <CollapseSection isHidden={data.length === 0} mainTitle={'Bank Accounts'} onShare={() => onShare('multicreate')}  collapsed={false} isCollapsable={true} customStyle={true}  datashareuser={datashare} datashare={'yes'}>
      {row}
    </CollapseSection>
          {dispalyActions === 'no' || datashare === 'yes' || datashare === 'shareby' ? <br/> : null }
     </div>
  );
};
export const BankAccountForm = forwardRef(BankAccFrm);

const mapStateToProps = (state) => ({
  bankFinAssetList: getStoreFinAssetList(state).filter(fa => fa.assetLookupCode === 'BNK'),
  userId: getStoreUserId(state),
  pickList: (getStoreContactList(state).concat(getStoreFamily(state))),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchAddFinAsset: finAsset => dispatch(addFinAsset(finAsset)),
  dispatchEditFinAsset: finAsset => dispatch(editFinAsset(finAsset)),
  dispatchDeleteFinAsset: finAsset => dispatch(deleteFinAssetFromList(finAsset)),
})
export default connect(mapStateToProps, mapDispatchToProps)(BankAccount);