import initialState from "./initialState";
import _ from 'lodash';
import { ADD_DISINHERIT_LIST, REMOVE_FROM_DISINHERIT_LIST } from "../actions/disinheritActions";
export default (state = initialState.nonDisInherit, action) => {
    switch (action.type) {

        case ADD_DISINHERIT_LIST:
            return  _.uniqBy(state.concat(action.disInherit), 'id');

        case REMOVE_FROM_DISINHERIT_LIST:
            return _.uniqBy(state.filter(con=> con.id !== action.disInheritId), 'id');

        default:
            return state;
    }
}