export const ADD_DATASHARED_BYME_CONSTANTS = 'ADD_DATASHARED_BYME_CONSTANTS';
export const CREATE_DATASHARED_BYME_CONSTANTS = 'CREATE_DATASHARED_BYME_CONSTANTS';
export const EDIT_DATASHARED_BYME_CONSTANTS = 'EDIT_DATASHARED_BYME_CONSTANTS';
export const DELETE_DATASHARED_BYME_CONSTANTS = 'DELETE_DATASHARED_BYME_CONSTANTS';

export const setDataSharedByme = (dataSharedByme) => {

    return {
        type: ADD_DATASHARED_BYME_CONSTANTS,
        dataSharedByme
    }
}

export const deleteDataSharedbymeList = (dataSharedBymeId) => {

    return {
        type: DELETE_DATASHARED_BYME_CONSTANTS,
        dataSharedBymeId
    }
}

export const getDataSharedByme = state => state.dataSharedByme;
export const addDataSharedbyme = dataShareBymeids => ({ type: CREATE_DATASHARED_BYME_CONSTANTS, dataShareBymeids });
export const editDataSharedbyme = dataShareBymeids => ({ type: EDIT_DATASHARED_BYME_CONSTANTS, dataShareBymeids });