import initialState from "./initialState";
import _ from 'lodash';
import { ADD_ALL_CONTACT_LIST, DELETE_FROM_ALL_CONTACT } from "../actions/allContactsActions";
export default (state = initialState.allContacts, action) => {
    switch (action.type) {
        case ADD_ALL_CONTACT_LIST:
            return  _.uniqBy(state.concat(action.contact), 'id');
            
        case DELETE_FROM_ALL_CONTACT:
            return _.uniqBy(state.filter(con=> con.id !== action.contactId), 'id');

        default:
            return state;
    }
}