import React, { useEffect } from 'react'
import ReactPDF, { Page, Text, View, Document, StyleSheet, Font, Image, PDFDownloadLink, fileName} from '@react-pdf/renderer';
import Storage from "@aws-amplify/storage";
import { getStoreUser } from '../selectors/userSelectors'
import { connect } from 'react-redux'
import _ from 'lodash';
import {S3URL} from "../constant/ServerConst";
import { getStoreContactList } from '../actions/contactActions';
import { getStoreFamily } from '../actions/familyActions';
import { getContactFullName } from '../utils/commonUtil';
import { getStoreOtherAssetList } from '../actions/otherAssetsActions';
import { getStoreFinAssetList } from '../actions/finAssetsActions';
import { getStoreImmovableAssetList } from '../actions/immovableAssetsActions';
import { getStoreDigitalAssetList } from '../actions/digitalAssetsActions';
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';
import { TRAKING_ID } from '../constant/ServerConst'
import { createUserDoc } from '../dashboard/forms/assets/finAssetService'
import moment from 'moment';

ReactGA.initialize(TRAKING_ID);
mixpanel.init("60ba11d6c224405b0b1a24bacb55bd8b");

const MyDocument = ({
    user,
    contactList,
    familyList,
    artOtherAssetList,
    artefactOtherAssetList,
    bankFinAssetList,
    bondFinAssetList,
    dematFinAssetList,
    fdFinAssetList,
    govSchemeFinAssetList,
    gratuityFinAssetList,
    immPropImmovableAssetList,
    insuranceFinAssetList,
    jewelleryOtherAssetList,
    lockerOtherAssetList,
    mfFinAssetList,
    perEffectOtherAssetList,
    pfFinAssetList,
    phySharesFinAssetList,
    portfolioFinAssetList,
    socialDigitalAssetList,
    vehicleOtherAssetList,
    webDigitalAssetList,
    financialAssetList,
    digitalAssetList,
    otherAssetList,
    relationshipMapping,
    propertyType,
    statesCode
  }) => (

    <Document>

        <Page>
          <Text style={styles.name}>{getContactFullName(user)}</Text>
              <Image
              style={styles.img}
              src={require(`../assets/img/logo2.png`)}
              alt=''
              />
              
              <View>
              <Text style={styles.web}>info@easyinherit.in</Text>
              </View>

              
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
        </Page>

      <Page>
          <Image
              style={styles.iconImg}
              src={require(`../assets/img/icons/name.png`)}
              alt=''
          />
          <Text style={styles.iconTitle}>Personal Info</Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
     </Page>

      <Page>
          <Text style={styles.personalTitle}>PERSONAL INFO</Text>
            <Text style={styles.personalName}>{getContactFullName(user)}</Text>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Phone Number :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{user.mobilePhone}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Email Id :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{user.primaryEmail}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>DOB :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{user.dob === 'Invalid date' ? '' : user.dob}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Gender :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{user.gender}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Nationality :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{user.nationality}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Religion :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{user.religion}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Marital Status :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{user.maritalStatus}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Id Proof Type :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{user.idProofType}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Id Proof Number :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{user.idProofNumber}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Permanent Address :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{user.permanentAddress.houseName}</Text>
              </View>
            </View>

            {_.isEmpty(user.permanentAddress.street) ? null :
            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text></Text>
              </View>
              <View style={styles.flexRight}>
              <Text>{user.permanentAddress.street}</Text>
              </View>
            </View>
            }

            {_.isEmpty(user.permanentAddress.city) ? null :
            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text></Text>
              </View>
              <View style={styles.flexRight}>
             <Text>{user.permanentAddress.city}</Text>
              </View>
            </View>
            }

            {(_.isEmpty(user.permanentAddress.state) && _.isEmpty(user.permanentAddress.country) &&  _.isEmpty(user.permanentAddress.postalCode)) ? null :
            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text></Text>
              </View>
              <View style={styles.flexRight}>
              <Text>{statesCode[user.permanentAddress.state]}, {user.permanentAddress.country}, {user.permanentAddress.postalCode}</Text>
              </View>
            </View>
            }

            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
     
      {familyList.length === 0 ? null : <Page>
        <Image
            style={styles.iconImg}
            src={require(`../assets/img/icons/family.png`)}
            alt=''
        />
        <Text style={styles.iconTitle}>Family</Text>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {familyList.length === 0 ? null : <Page style={styles.page}>
          <Text style={styles.contTitle} fixed>FAMILY</Text>
          {familyList.map((c,index)=>(
            <View>

            <Text style={styles.contName}>{getContactFullName(c)}</Text>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>RelationShip :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{relationshipMapping[c.relationship]}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Nationality :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.nationality}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Id Proof Type :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.idProofType}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Id Proof Number :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.idProofNumber}</Text>
              </View>
            </View>

           {familyList.length > 0 && index !== (familyList.length - 1) ? <Text style={styles.marginLine}></Text> : null}

            </View>
          ))}
           <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {contactList.length === 0 ? null : <Page>
        <Image
            style={styles.iconImg}
            src={require(`../assets/img/icons/other_contact.png`)}
            alt=''
        />
        <Text style={styles.iconTitle}>My Contacts</Text>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

       {contactList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>CONTACTS</Text>
        {contactList.map((c,index)=>(
          <View>
            <Text style={styles.contName}>{getContactFullName(c)}</Text>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Phone Number :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.mobilePhone}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>RelationShip :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{relationshipMapping[c.relationship]}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Type :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contactLookupCode === 'INDV' ? 'Individual' : 'Institution'}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>DOB :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.dob === 'Invalid date' ? '' : c.dob}</Text>
              </View>
            </View>

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Home Address :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.homeAddress.houseName}</Text>
              </View>
            </View>

            {_.isEmpty(c.homeAddress.street) ? null :
            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text></Text>
              </View>
              <View style={styles.flexRight}>
              <Text>{c.homeAddress.street}</Text>
              </View>
            </View>
            }

            {_.isEmpty(c.homeAddress.city) ? null :
            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text></Text>
              </View>
              <View style={styles.flexRight}>
             <Text>{c.homeAddress.city}</Text>
              </View>
            </View>
            }

            {(_.isEmpty(c.homeAddress.state) && _.isEmpty(c.homeAddress.country) &&  _.isEmpty(c.homeAddress.postalCode)) ? null :
            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text></Text>
              </View>
              <View style={styles.flexRight}>
              <Text>{statesCode[c.homeAddress.state]}, {c.homeAddress.country}, {c.homeAddress.postalCode}</Text>
              </View>
            </View>
            }

            <View style={styles.flexContainerPer}>
              <View style={styles.flexLeft}>
                <Text>Gender :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.gender}</Text>
              </View>
            </View>

            {contactList.length > 0 && index !== (contactList.length - 1) ? <Text style={styles.marginLine}></Text> : null}

          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {insuranceFinAssetList.length === 0 ? null : <Page>
        <Image
            style={styles.iconImg}
            src={require(`../assets/img/icons/hospital.png`)}
            alt=''
        />
        <Text style={styles.iconTitle}>Insurance</Text>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {insuranceFinAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>INSURANCE</Text>
        <Text style={styles.assetName} fixed>Insurance Policy</Text>
        {insuranceFinAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Policy Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIdentificationNumber}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Nominee :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contacts !== null ? getContactFullName(c.contacts) : ''}</Text>
              </View>
            </View>

          {insuranceFinAssetList.length > 0 && index !== (insuranceFinAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }
  
      {financialAssetList.length === 0 ? null : <Page>
        <Image
            style={styles.iconImg}
            src={require(`../assets/img/icons/budget.png`)}
            alt=''
        />
        <Text style={styles.iconTitle}>Financial Assets</Text>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {bankFinAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>FINANCIAL ASSETS</Text>
        <Text style={styles.assetName} fixed>Bank Account</Text>
        {bankFinAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Bank Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Account No. :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIdentificationNumber}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Nominee :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contacts !== null ? getContactFullName(c.contacts) : ''}</Text>
              </View>
            </View>

           {bankFinAssetList.length > 0 && index !== (bankFinAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {bondFinAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>FINANCIAL ASSETS</Text>
        <Text style={styles.assetName} fixed>Bonds</Text>
        {bondFinAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Bank Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Unit :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetQuantity}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Nominee :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contacts !== null ? getContactFullName(c.contacts) : ''}</Text>
              </View>
            </View>

          {bondFinAssetList.length > 0 && index !== (bondFinAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {dematFinAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>FINANCIAL ASSETS</Text>
        <Text style={styles.assetName} fixed>Demat Accounts</Text>
        {dematFinAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Bank Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>DP ID. :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIdentificationNumber}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Customer ID. :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIdentificationNumber2}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Nominee :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contacts !== null ? getContactFullName(c.contacts) : ''}</Text>
              </View>
            </View>

           {dematFinAssetList.length > 0 && index !== (dematFinAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }
 
      {fdFinAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>FINANCIAL ASSETS</Text>
        <Text style={styles.assetName} fixed>Fixed Deposits</Text>
        {fdFinAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Bank Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Account No. :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIdentificationNumber}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Nominee :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contacts !== null ? getContactFullName(c.contacts) : ''}</Text>
              </View>
            </View>

            {fdFinAssetList.length > 0 && index !== (fdFinAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }
  
      {govSchemeFinAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>FINANCIAL ASSETS</Text>
        <Text style={styles.assetName} fixed>Government Schemes</Text>
        {govSchemeFinAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Scheme Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Account No. :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIdentificationNumber}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Nominee :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contacts !== null ? getContactFullName(c.contacts) : ''}</Text>
              </View>
            </View>

            {govSchemeFinAssetList.length > 0 && index !== (govSchemeFinAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {gratuityFinAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>FINANCIAL ASSETS</Text>
        <Text style={styles.assetName} fixed>Gratuity</Text>
        {gratuityFinAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Issuer Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>ID No. :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIdentificationNumber}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Nominee :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contacts !== null ? getContactFullName(c.contacts) : ''}</Text>
              </View>
            </View>

            {gratuityFinAssetList.length > 0 && index !== (gratuityFinAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {mfFinAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>FINANCIAL ASSETS</Text>
        <Text style={styles.assetName} fixed>Mutual Funds</Text>
        {mfFinAssetList.map((c,index)=>(
          <View>

          <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Bank Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Account No. :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIdentificationNumber}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Unit :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetQuantity}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Nominee :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contacts !== null ? getContactFullName(c.contacts) : ''}</Text>
              </View>
            </View>

            {mfFinAssetList.length > 0 && index !== (mfFinAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }
 
      {pfFinAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>FINANCIAL ASSETS</Text>
        <Text style={styles.assetName} fixed>Provident Fund</Text>
        {pfFinAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Issuer Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>ID No. :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIdentificationNumber}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Nominee :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contacts !== null ? getContactFullName(c.contacts) : ''}</Text>
              </View>
            </View>

          {pfFinAssetList.length > 0 && index !== (pfFinAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

       {phySharesFinAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>FINANCIAL ASSETS</Text>
        <Text style={styles.assetName} fixed>Physical Shares</Text>
        {phySharesFinAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Company Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Share Quantity :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetQuantity}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Nominee :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contacts !== null ? getContactFullName(c.contacts) : ''}</Text>
              </View>
            </View>

            {phySharesFinAssetList.length > 0 && index !== (phySharesFinAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {portfolioFinAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>FINANCIAL ASSETS</Text>
        <Text style={styles.assetName} fixed>Portfolio Management Services</Text>
        {portfolioFinAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Provider Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Account No. :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIdentificationNumber}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Nominee :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contacts !== null ? getContactFullName(c.contacts) : ''}</Text>
              </View>
            </View>

            {portfolioFinAssetList.length > 0 && index !== (portfolioFinAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {immPropImmovableAssetList.length === 0 ? null : <Page >
        <Image
            style={styles.iconImg}
            src={require(`../assets/img/icons/house.png`)}
            alt=''
        />
        <Text style={styles.iconTitle}>Immovable Property</Text>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {immPropImmovableAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>IMMOVABLE ASSETS</Text>
        <Text style={styles.assetName} fixed>Home and Property</Text>

        

        {immPropImmovableAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Property Type :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{propertyType[c.assetHolding]}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Property No. :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetAddress1}</Text>
              </View>
            </View>

            {_.isEmpty(c.assetAddress2) ? null :
            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text></Text>
              </View>
              <View style={styles.flexRight}>
              <Text>{c.assetAddress2}</Text>
              </View>
            </View>
            }

            {_.isEmpty(c.assetAddressCity) ? null :
            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text></Text>
              </View>
              <View style={styles.flexRight}>
             <Text>{c.assetAddressCity}</Text>
              </View>
            </View>
            }

            {(_.isEmpty(c.assetAddressState) && _.isEmpty(c.assetAddressCountry) &&  _.isEmpty(c.assetAddressPin)) ? null :
            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text></Text>
              </View>
              <View style={styles.flexRight}>
              <Text>{statesCode[c.assetAddressState]}, {c.assetAddressCountry}, {c.assetAddressPin}</Text>
              </View>
            </View>
            }

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Joint Holder :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contacts !== null ? getContactFullName(c.contacts) : ''}</Text>
              </View>
            </View>

            {immPropImmovableAssetList.length > 0 && index !== (immPropImmovableAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }
 
      {digitalAssetList.length === 0 && otherAssetList.length === 0 ? null : <Page>
        <Image
            style={styles.iconImg}
            src={require(`../assets/img/icons/computer.png`)}
            alt=''
        />
        <Text style={styles.iconTitle}>Digital and Other Assets</Text>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

    {artOtherAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>OTHER ASSETS</Text>
        <Text style={styles.assetName} fixed>Art</Text>
        {artOtherAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Art Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Description :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetDescription}</Text>
              </View>
            </View>

            {artOtherAssetList.length > 0 && index !== (artOtherAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}

          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {artefactOtherAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>OTHER ASSETS</Text>
        <Text style={styles.assetName} fixed>Artefacts</Text>
        {artefactOtherAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Artefact Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Description :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetDescription}</Text>
              </View>
            </View>

            {artefactOtherAssetList.length > 0 && index !== (artefactOtherAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}

          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {jewelleryOtherAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>OTHER ASSETS</Text>
        <Text style={styles.assetName} fixed>Jewellery</Text>
        {jewelleryOtherAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Jewellery Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Description :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetDescription}</Text>
              </View>
            </View>

            {jewelleryOtherAssetList.length > 0 && index !== (jewelleryOtherAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {lockerOtherAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>OTHER ASSETS</Text>
        <Text style={styles.assetName} fixed>Bank Lockers</Text>
        {lockerOtherAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Bank Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Locker No. :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIdentificationNumber}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Locker Address :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetAddress.split(',')[0]}</Text>
              </View>
            </View>

            {_.isEmpty(c.assetAddress.split(',')[1]) ? null :
            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text></Text>
              </View>
              <View style={styles.flexRight}>
              <Text>{c.assetAddress.split(',')[1]}</Text>
              </View>
            </View>
            }

            {_.isEmpty(c.assetAddress.split(',')[2]) ? null :
            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text></Text>
              </View>
              <View style={styles.flexRight}>
             <Text>{c.assetAddress.split(',')[2]}</Text>
              </View>
            </View>
            }

            {(_.isEmpty(c.assetAddress.split(',')[3]) && _.isEmpty(c.assetAddress.split(',')[4]) &&  _.isEmpty(c.assetAddress.split(',')[5])) ? null :
            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text></Text>
              </View>
              <View style={styles.flexRight}>
              <Text>{statesCode[c.assetAddress.split(',')[3]]}, {c.assetAddress.split(',')[4]}, {c.assetAddress.split(',')[5]}</Text>
              </View>
            </View>
            }

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Nominee :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.contacts !== null ? getContactFullName(c.contacts) : ''}</Text>
              </View>
            </View>

            {lockerOtherAssetList.length > 0 && index !== (lockerOtherAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }
 
      {perEffectOtherAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>OTHER ASSETS</Text>
        <Text style={styles.assetName} fixed>Personal Effects</Text>
        {perEffectOtherAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Description :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetDescription}</Text>
              </View>
            </View>

            {perEffectOtherAssetList.length > 0 && index !== (perEffectOtherAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {vehicleOtherAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>OTHER ASSETS</Text>
        <Text style={styles.assetName} fixed>Vehicles</Text>
        {vehicleOtherAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Vehicle Name :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIssuerName}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Vehicle No. :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetIdentificationNumber}</Text>
              </View>
            </View>

            {vehicleOtherAssetList.length > 0 && index !== (vehicleOtherAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
           ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

      {socialDigitalAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>DIGITAL ASSETS</Text>
        <Text style={styles.assetName} fixed>Social Media</Text>
        {socialDigitalAssetList.map((c,index)=>(
          <View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Platform :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetDetail}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Username :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetDescription}</Text>
              </View>
            </View>

            {socialDigitalAssetList.length > 0 && index !== (socialDigitalAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }
 
      {webDigitalAssetList.length === 0 ? null : <Page style={styles.page}>
        <Text style={styles.contTitle} fixed>DIGITAL ASSETS</Text>
        <Text style={styles.assetName} fixed>Websites</Text>
        {webDigitalAssetList.map((c,index)=>(
          <View>

          <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Platform :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetDetail}</Text>
              </View>
            </View>

            <View style={styles.flexContainer}>
              <View style={styles.flexLeft}>
                <Text>Username :</Text>
              </View>
              <View style={styles.flexRight}>
                <Text>{c.assetDescription}</Text>
              </View>
            </View>

           {webDigitalAssetList.length > 0 && index !== (webDigitalAssetList.length - 1) ? <Text style={styles.infomargin}></Text> : null}
          
          </View>
        ))}
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
      }

    </Document>
    
  );
  
  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  });
  
  const styles = StyleSheet.create({
    
    iconImg:{
      width:150,
      height:130,
      position:'absolute',
      top: '35%',
      left:'35%',
    },
    iconTitle:{
      textAlign:'center',
      fontSize:25,
      position:'absolute',
      top: '55%',
    },

    page:{
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },

    marginLine:{
      borderBottom:'1 solid grey',
      margin:12,
      marginRight:'10%',
      marginLeft:'10%',
    },

    //page1
    name:{
      textAlign:'center',
      paddingTop:'55%', 
      borderBottom:'1 solid grey', 
      fontSize:30,
      paddingBottom: 20,
      marginRight:'5%',
      marginLeft:'5%'
    },
    img:{
      width: 180,
      height: 30,
      position:'absolute',
      top:'52%',
      left:'35%',
    },
    web:{
      marginLeft:'2%',
      fontSize:25,
      position:'absolute',
      top:'55%',
      textAlign:'center'
    },
   
    //page2
    personalTitle:{
      fontSize:25,
      margin:20,
      fontWeight:'bold',
      paddingBottom:5,
      borderBottom:'2 solid grey', 
    },
    personalName:{
      fontSize:25,
      paddingTop:'10%', 
      textAlign:'center',
    },
    info:{
      paddingLeft:'20%',
      paddingTop: 15,
    },

    //page3
    contTitle:{
      fontSize:25,
      margin:20,
      paddingBottom:5,
      borderBottom:'1 solid grey', 
    },
    contName:{
      fontSize:25,
      textAlign:'center',
    },

    //
    assetName:{
      fontSize:25,
      paddingBottom:'1%', 
      textAlign:'center',
    },
    infomargin:{
      borderBottom:'1 solid grey',
      margin:12,
      marginRight:'10%',
      marginLeft:'10%',
    },

    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'black',
    },

    flexContainerPer: {
      paddingTop:10,
      flexDirection: 'row',
      marginLeft:'15%',
    },
  
    flexContainer: {
      paddingTop:10,
      flexDirection: 'row',
      marginLeft:'5%',
    },
    flexLeft: {
      width: '40%',
      textAlign: 'justify',
    },
    flexRight: {
      width: '60%',
      textAlign: 'justify',
    },

  });
  const s3Url = S3URL;
  const SummaryPdf = (
      {
        user, 
        contactList,
        familyList,
        artOtherAssetList,
        artefactOtherAssetList,
        bankFinAssetList,
        bondFinAssetList,
        dematFinAssetList,
        fdFinAssetList,
        govSchemeFinAssetList,
        gratuityFinAssetList,
        immPropImmovableAssetList,
        insuranceFinAssetList,
        jewelleryOtherAssetList,
        lockerOtherAssetList,
        mfFinAssetList,
        perEffectOtherAssetList,
        pfFinAssetList,
        phySharesFinAssetList,
        portfolioFinAssetList,
        socialDigitalAssetList,
        vehicleOtherAssetList,
        webDigitalAssetList,
        financialAssetList,
        digitalAssetList,
        otherAssetList,
      }
  ) => {
    const onDownloadClick = (blob) =>{
      Storage.put(
        `Portfolio/${user.id}` +`PS.pdf`,
        blob,{contentType:'application/pdf'}
      )
        .then((resp) => {
          alert("Successfully uploaded to DigiLocker")
          console.log('uploaded to DL')
          /*console.log('The userid is -'+user.id)
          console.log('The user mail is -'+user.primaryEmail)*/
          
          const payload = {
            userId : user.id,
            active : 'true',
            receiverFlag : 'false',
            custField1: user.primaryEmail,
            docLookupCode : "PORTSUM",
            docLink: s3Url+"Portfolio/"+user.id+"PS.pdf",
            docNumber: "in.quaish-OTHER-"+user.id+"PS"
        }
        
        //call the create user_document API 
        createUserDoc(payload)
        .then(resp=>{
          console.log(resp)
          console.log('Portfolio document table entry success')
      })

        }).catch((error)=>{
          alert(error.message)
        })
    }
          const relationshipMapping = {
            'FATHER' : 'Father',
            'MOTHER' : 'Mother',
            'SPOUSE' : 'Spouse',
            'CHILD' : 'Child',
            "IN-LAWS" : "In Laws",
            "SIBLING" : "Siblings",
            "OTH-FAM" : "Other Family Members",
            "FRND" : "Friends",
            "COL" : "Colleagues",
            "DOC" : "Doctor",
            "LAW" : "Lawyer",
            "ACC" : "Accountant",
            "TAX" : "Consultants"
          }

          const propertyType={
              "RES":"Residential",
              "COMM": "Commercial",
              "LAND": "Land"
            }
           
          const statesCode = {
              "AN":"Andaman and Nicobar Islands",
              "AP": "Andhra Pradesh",
              "AR": "Arunachal Pradesh",
              "AS": "Assam",
              "BR": "Bihar",
              "CG": "Chandigarh",
              "CH": "Chhattisgarh",
              "DH": "Dadra and Nagar Haveli",
              "DD": "Daman and Diu",
              "DL": "Delhi",
              "GA": "Goa",
              "GJ": "Gujarat",
              "HR": "Haryana",
              "HP": "Himachal Pradesh",
              "JK": "Jammu and Kashmir",
              "JH": "Jharkhand",
              "KA": "Karnataka",
              "KL": "Kerala",
              "LD": "Lakshadweep",
              "MP": "Madhya Pradesh",
              "MH": "Maharashtra",
              "MN": "Manipur",
              "ML": "Meghalaya",
              "MZ": "Mizoram",
              "NL": "Nagaland",
              "OR": "Odisha",
              "PY": "Puducherry",
              "PB": "Punjab",
              "RJ": "Rajasthan",
              "SK": "Sikkim",
              "TN": "Tamil Nadu",
              "TS": "Telangana",
              "TR": "Tripura",
              "UK": "Uttarakhand",
              "UP": "Uttar Pradesh",
              "WB": "West Bengal"
            }    

            useEffect(() => {
                ReactGA.pageview('Portfolio Summary Page')
                mixpanel.track("Portfolio Summary Page")
                mixpanel.people.set({
                  "$email": user.primaryEmail
                })


          },[])
         
      return(
    <div>
         <PDFDownloadLink   
        document={<MyDocument user={user} 
        contactList={contactList} 
        familyList={familyList} 
        artOtherAssetList={artOtherAssetList} 
        artefactOtherAssetList={artefactOtherAssetList} 
        bankFinAssetList={bankFinAssetList} 
        bondFinAssetList={bondFinAssetList} 
        dematFinAssetList={dematFinAssetList} 
        fdFinAssetList={fdFinAssetList} 
        govSchemeFinAssetList={govSchemeFinAssetList} 
        gratuityFinAssetList={gratuityFinAssetList} 
        immPropImmovableAssetList={immPropImmovableAssetList} 
        insuranceFinAssetList={insuranceFinAssetList} 
        jewelleryOtherAssetList={jewelleryOtherAssetList} 
        lockerOtherAssetList={lockerOtherAssetList} 
        mfFinAssetList={mfFinAssetList} 
        perEffectOtherAssetList={perEffectOtherAssetList} 
        pfFinAssetList={pfFinAssetList} 
        phySharesFinAssetList={phySharesFinAssetList} 
        portfolioFinAssetList={portfolioFinAssetList} 
        socialDigitalAssetList={socialDigitalAssetList} 
        vehicleOtherAssetList={vehicleOtherAssetList} 
        webDigitalAssetList={webDigitalAssetList} 
        financialAssetList={financialAssetList} 
        digitalAssetList={digitalAssetList} 
        otherAssetList={otherAssetList} 
        relationshipMapping={relationshipMapping} 
        propertyType={propertyType} 
        statesCode={statesCode}/>}
        fileName="Portfolio Summary.pdf">
        {({ blob, url, loading, error }) => 
        {
          return (loading && blob && url ? <span style={{float:'right'}}>...Loading summary...</span> 
          : <button onClick={()=>onDownloadClick(blob)}  className='btn btn-primary' style={{float:'right'}}>Download Summary</button>
         )} }
      </PDFDownloadLink><span style={{float:'center' ,textAlign:'justify', fontSize:'small'}}><b>Note :</b> Auto issued to DigiLocker on download .</span> 
      <br/><br/><br/> 
    </div>
  )
      }
      
const mapStateToProps = (state) => ({
  user: getStoreUser(state),

  contactList: getStoreContactList(state),
  familyList:getStoreFamily(state),
  financialAssetList:getStoreFinAssetList(state),
  digitalAssetList:getStoreDigitalAssetList(state),
  otherAssetList:getStoreOtherAssetList(state),
  
  artOtherAssetList: getStoreOtherAssetList(state).filter(fa=> fa.assetLookupCode === 'ART'),
  artefactOtherAssetList: getStoreOtherAssetList(state).filter(fa=> fa.assetLookupCode === 'ARTI'),
  bankFinAssetList: getStoreFinAssetList(state).filter(fa => fa.assetLookupCode === 'BNK'),
  bondFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'BON'),
  dematFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'DEM'),
  fdFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'FD'),
  govSchemeFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'GOV'),
  gratuityFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'GRA'),
  immPropImmovableAssetList: getStoreImmovableAssetList(state),
  insuranceFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'INS'),
  jewelleryOtherAssetList: getStoreOtherAssetList(state).filter(fa=> fa.assetLookupCode === 'JEWL'),
  lockerOtherAssetList: getStoreOtherAssetList(state).filter(fa=> fa.assetLookupCode === 'LOCK'),
  mfFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'MF'),
  perEffectOtherAssetList: getStoreOtherAssetList(state).filter(fa=> fa.assetLookupCode === 'PERS'),
  pfFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'PF'),
  phySharesFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'SHR'),
  portfolioFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'PMS'),
  socialDigitalAssetList: getStoreDigitalAssetList(state).filter(fa=> fa.assetLookupCode === 'SOC'),
  vehicleOtherAssetList: getStoreOtherAssetList(state).filter(fa=> fa.assetLookupCode === 'VEH'),
  webDigitalAssetList: getStoreDigitalAssetList(state).filter(fa=> fa.assetLookupCode === 'WEB'),
});

export default connect(mapStateToProps)(SummaryPdf)