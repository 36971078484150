import React, { useState } from 'react'
import ModalContainer from '../../home/component/layout/Modal'
import helperTextContent from '../../resources/helperTextContent.json'
import { CustomButtom } from '../../components/CustomElements'
import { useInput } from '../../hooks/useInput'
import TextArea from '../../components/TextArea'
import { useSelector } from 'react-redux'
import { mailQueries } from './MailingServices'

const closeStyle = {
  color: '#B0B0B0',
  cursor: 'pointer',
  float: 'right',
  paddingLeft: '5px',
  paddingRight: '10px',
}

const HelperModal = ({ helperTextId }) => {
  const [isVisible, setIsvisible] = useState(false)
  const [isVisibleText, setIsvisibleText] = useState(false)
  const user = useSelector((state) => state.user)
  const toggleModal = () => setIsvisible(!isVisible)
  const toggleModalText = () => setIsvisibleText(!isVisibleText)

  const TextAreaComponent = () => {
    
    const { value: question, bind: bindQues } = useInput('')
    const submitFunc = () => {
      let payload = {
        userId: user.id,
        email: user.primaryEmail,
        topic: helperTextId,
        question
      }
      mailQueries(payload)
        .then(() => {
          toggleModal()
          setTimeout(() => {
            alert(
              'Thank you for your query!\n We will reach back to you via your email. Please check for an email from EasyInherit.'
            )
          }, 100)
        })
        .catch((error) => {
          console.log(error)
          alert('Error! Please try again!')
        })
    }

    return (
      <div style={{ margin: '10px', padding: '10px' }}>
        <p>Ask us your question and we will reach out to you:</p>
        <TextArea bind={bindQues} />
        <div style={{ textAlign: 'center' }}>
          <CustomButtom
            event={submitFunc}
            title={'Submit'}
            disabled={question === ''}
          />
          <CustomButtom
            event={toggleModalText}
            title={'Cancel'}
            variant={'outline-primary'}
          />
        </div>
      </div>
    )
  }
  return (
    <div>
      <i
        className='fa fa-question-circle'
        aria-hidden='true'
        style={{ cursor: 'pointer', paddingLeft: '5px', paddingTop: '5px' }}
        onClick={toggleModal}
      />
      <ModalContainer visible={isVisible}>
        <div style={{ textAlign: 'center', backgroundColor: '#1b2e4a' }}>
          <h5
            style={{
              display: 'inline-block',
              color: '#B0B0B0',
              marginTop: '5px',
            }}
          >
            Explain This?
          </h5>
          <h3 onClick={toggleModal} style={closeStyle}>
            &times;
          </h3>
        </div>
        <p style={{ padding: '15px', fontSize: '14px' }}>
          {helperTextContent[helperTextId].helperText}
        </p>
        {!isVisibleText ? (
          <p style={{ textAlign: 'center' }}>
            HAVE QUESTIONS?
            <button
              className='btn btn-link'
              onClick={() => {
                toggleModalText()
              }}
            >
              Ask us NOW!
            </button>
            or
            <button
              className='btn btn-link'
              onClick={() => {
                toggleModalText()
              }}
            >
              Add it your LIST!
            </button>
          </p>
        ) : null}
        {isVisibleText ? <TextAreaComponent /> : null}
      </ModalContainer>
    </div>
  )
}

export default HelperModal
