export const ADD_EXECUTOR_LIST = 'ADD_EXECUTOR_LIST';
export const setExecutorData = (executor) => {

    return {
        type: ADD_EXECUTOR_LIST,
        executor
    }
}
export const UPDATE_CONTACTS_TO_EXECUTOR = 'UPDATE_CONTACTS_TO_EXECUTOR';
export const updateToExecutor = contacts => {
    return {
        type: UPDATE_CONTACTS_TO_EXECUTOR,
        contacts
    }
}

export const DELETE_EXECUTOR = 'DELETE_EXECUTOR';
export const deleteExecutorFromList = (executorId) => {
    return {
        type: DELETE_EXECUTOR,
        executorId
    }
}
export const getStoreExecutorList = state => (state.contacts.filter(contact => contact.executorFlag)).concat(state.family.filter(contact => contact.executorFlag));
