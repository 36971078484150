import initialState from "./initialState";
import _ from 'lodash';
import { ADD_FAMILY, DELETE_FAMILY_CONTACT, CREATE_FAMILY, EDIT_FAMILY } from "../actions/familyActions";
import { contactToUiLoad } from "../objectMapping/contactMapping";
import { DELETE_EXECUTOR, UPDATE_CONTACTS_TO_EXECUTOR } from "../actions/executorActions";
export default (state = initialState.family, action) => {
    switch (action.type) {

        case ADD_FAMILY:
            return action.family.map(c => ({ ...contactToUiLoad(c) }));

        case DELETE_FAMILY_CONTACT:

            return state.filter(con => con.id !== action.familyId);
        case CREATE_FAMILY:
            return state.concat(contactToUiLoad(action.person))
        case EDIT_FAMILY:
            const index = _.findIndex(state, { id: action.person.id });
            state.splice(index, 1, contactToUiLoad(action.person));
            return [...state];
        case UPDATE_CONTACTS_TO_EXECUTOR:
            return state.map(c => {
                if (action.contacts.includes(c.id)) {
                    return { ...c, executorFlag: true }
                } else return c;
            })
        case DELETE_EXECUTOR:
            return state.map(c => {
                if (action.executorId === c.id) {
                    return { ...c, executorFlag: false }
                } else return c;
            })
        default:
            return state;
    }
}