import initialState from "./initialState";
import { SET_USER_DATA } from "../actions/userActions";
import { userToUiLoad } from "../objectMapping/userMapping";
export default (state = initialState.user, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return { ...userToUiLoad(action.user) };
        default:
            return state;
    }
}