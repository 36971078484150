import React from 'react';
import SectionHeading from '../../home/component/layout/SectionHeading';

const AboutUs = ()  => {

    let imgUrl = '../img/banner/greybackground.png';

    return(
        <div>
        
<div className='container'>

<div className='d-block d-sm-none'>
            <div className = 'Component-Bg' 
                    style = {{ backgroundImage: `url(${imgUrl})`,
                    backgroundSize: 'cover', 
                    backgroundOrigin:'left',
                    backgroundPosition: 'left center',
                    backgroundRepeat: 'no-repeat',
                    }}> 
              
                    <div style={{width:'100%', paddingLeft:'5%', paddingRight:'2%'}}>  
                    
                        <SectionHeading heading={'About Us'}  image={'../img/banner/plain_greybackground.png'}/>
                        <p align='justify'>Both the EasyInherit co-founders were fortunate enough to have parents who had 
                        well documented Wills. But the succession process was exhausting and time-consuming. Even bank account 
                        transfers, where the co-founders were nominees, made them face the reality of bureaucratic processes. 
                        All to claim what was rightfully theirs.</p>
                        <p>They heard the same story from numerous friends and family.</p>
                        <p><b><i>This led to the idea of EasyInherit which is the platform to facilitate all aspects of Inheritance 
                        Management -  documentation, probates, succession, claims and transfers.</i></b></p> 
                        <p><b><i>All this, in a secure, transparent, efficient and outcome based model.</i></b>
                        </p>
                  </div>
        </div>

            <div style={{width:'100%', paddingTop:'0px'}}>               
                    <p style={{ fontSize: 30,textAlign: 'center' }}>Our Philosophy</p>

                        <p style={{paddingLeft:'10%', paddingRight:'2%'}}>EasyInherit’s philosophy is that ‘Inheritance’ should be an act of ‘Joy and Pride ’for each 
                        generation. It should not be exhausting for the generation planning to bequeath 
                        nor the generation claiming what has been lovingly left behind for them.

                        Our guiding principles for EasyInherit -</p>
            </div>  
            <div className='row'>
            <div style={{paddingLeft:'10%', paddingRight:'2%'}}><h4>Hassle-Free and Exhaustive</h4></div> 
                    
            <div style={{paddingLeft:'10%', paddingRight:'2%'}}>               

                <p>Most people think a simple "Will" or "Nominees" would suffice. They evaluate with their skills, knowledge and connections in mind. They forget, it is their 
                BENEFICIARIES (predominantly spouse and children) who would have to do the hard work to get access to the assets. We at EasyInherit see your intent of ‘Will making’ 
                as the first identified step in the process of Inheritance Planning. In fact we follow a five step process to 
                help you plans your Inheritance customized to the needs of your loved ones and your life circumstances. </p>
            </div> 
            <div style={{paddingLeft:'10%', paddingRight:'2%'}}><h4 >Contact-less Delivery</h4></div> 

            <div style={{paddingLeft:'10%', paddingRight:'2%'}}>               

                <p>EasyInherit’s unique Legal Tech Platform helps simplify the process of Estate and Inheritance Planning and 
                receiving digitally and contact-less. This more so than ever is relevant now when the world is in the grips of 
                Covid-19 and where every industry is aiming to become digital. </p>
            </div> 
            <div style={{paddingLeft:'10%', paddingRight:'2%'}}><h4>Expert Counsel</h4></div> 
    
            <div style={{paddingLeft:'10%', paddingRight:'2%'}}>               
               <p>Once you create your EasyInherit Inheritance Portfolio you get appropriate legal counsel and financial consultation 
                from experts in estate management to determine the best course of action to fulfil your wishes in the form of a 
                legal structure e.g.: A Will or Trust or Guardianship.</p>
            </div> 
        
            <div style={{paddingLeft:'10%', paddingRight:'2%'}}><h4>Technology powered</h4></div> 
        
            <div style={{paddingLeft:'10%', paddingRight:'2%'}}>               
              <p>We provide clear and precise legal counsel, clean documentation and hassle-free execution from the comfort of your 
                home through our secure, AI powered tech platform. Our platform makes sure your Inheritance Management process is 
                always miles ahead of the conventional documentation which is prone to manual errors.</p>
            </div> 

            <div style={{width:'100%'}}>               
                <p style={{ fontSize: 30,textAlign: 'center' }}>Meet the Team</p>
            </div>
            <div style={{paddingLeft:'10%', paddingRight:'2%'}}>               

              <p>EasyInherit is a team effort. While we have Founders and Advisors, there is a large team of lawyers and 
                AI experts that works to make everything possible.</p>
            </div> 
            
            <br></br><br></br>

            <div style={{ width:'100%', textAlign:'center'}}>
            <img alt='' width='180' height='200' src='../img/banner/sejal.jpg'></img>
            </div> 

            <div style={{width:'100%'}}>               

            <p style={{ fontSize: 18, textAlign:'center' }}><b>Sejal Sheth, Co-Founder and CEO</b></p>
            <p style={{paddingLeft:'10%', paddingRight:'2%'}}>Sejal Sheth started the first decade of her career as a marketing professional with the 
                communications agency JWT. Post that she spent the next decade establishing a Sustainability 
                Consulting firm called Green Evangelist. She is an alumnus of NMIMS and has done her Strategic 
                Management from IIM Calcutta. EasyInherit is her vision to make Inheritance for loved ones 
                become a source of ‘joy and pride’ - like it’s meant to be – by eliminating all the ‘daud-bhaag’ 
                around it.</p>
            </div>
            <div style={{width:'100%', paddingLeft:'10%', paddingTop:'0px', paddingRight:'2%'}}> <i>"EasyInherit is my vision to make inheritance a 
                truly enjoyable experience - a matter of Pride and Joy - like it is meant to be. Minus the 'daud-bhag' that it entails today."</i>
            </div>
                    <br></br>

            <div  style={{ width:'100%', textAlign:'center'}}>
            <img alt='' width='180' height='200' src='../img/banner/soubir.jpg'></img>
            </div> 


            <div style={{width:'100%'}}>               
            <p style={{ fontSize: 18,textAlign:'center' }}><b>Soubir Bose, Co-Founder and CTO</b></p>
            <p style={{paddingLeft:'10%', paddingRight:'2%'}}>A technopreneur with more than two decades in the technology industry, this is Soubir's second venture.
            An alumnus of IIM-K, he worked in the Silicon Valley with companies like Xerox PARC, Hewitt and Oracle 
            on a wide range 
            of technologies and domains. His last venture was a successful CRM product which he continues to direct. 
            EasyInherit 
            was born out of a desire to see his family, and many like his, navigate the legal quagmire of inheritance 
            planning in India.
            </p>
            </div> 
            <div style={{width:'100%', paddingLeft:'10%', paddingTop:'0px', paddingRight:'2%'}}> <i>"EasyInherit was born out of a desire to see
            my family, and many like mine, navigate the quagmire of inheritance planning in India. More so, in these challenging times."</i> </div>  
        </div>
</div> 


<div className='d-none d-sm-block'>
        <div id='intro'></div>
        <div className = 'Component-Bg' 
                            style = {{ backgroundImage: `url(${imgUrl})`,
                            backgroundSize: 'cover', 
                            backgroundPosition: 'center center',
                            backgroundRepeat: 'no-repeat',
                            }}> 
                    
                            <div style={{width:'100%', paddingLeft:'35%', paddingRight:'2%',paddingTop:'40px'}}>  
                            
                                <SectionHeading heading={'About Us'} subHeading={''} image={'../img/banner/plain_greybackground.png'}/>
                                <p align='justify'>Both the EasyInherit co-founders were fortunate enough to have parents who had 
                                well documented Wills. But the succession process was exhausting and time-consuming. Even bank account 
                                transfers, where the co-founders were nominees, made them face the reality of bureaucratic processes. 
                                All to claim what was rightfully theirs.</p>
                                <p>They heard the same story from numerous friends and family.</p>
                                <p><b><i>This led to the idea of EasyInherit which is the platform to facilitate all aspects of Inheritance 
                                Management -  documentation, probates, succession, claims and transfers.</i></b></p> 
                                <p><b><i>All this, in a secure, transparent, efficient and outcome based model.</i></b>
                                </p>

                                <div id ='philosophy'><p> <br></br><br></br></p></div>
                        </div>
                </div>

                <div style={{width:'100%', paddingLeft:'35%', paddingTop:'0px'}}>               
                    <p style={{ fontSize: 30,textAlign: 'left' }}>Our Philosophy</p>

                        <p>EasyInherit’s philosophy is that ‘Inheritance’ should be an act of ‘Joy and Pride ’for each 
                        generation. It should not be exhausting for the generation planning to bequeath nor for the generation claiming what has been lovingly left behind for them.

                        Our guiding principles for EasyInherit -</p></div>  
                <div style={{ width:'30%', float:'left'}}><h4>Hassle-Free <br></br>and Exhaustive</h4></div> 
        
                <div style={{width:'100%', paddingLeft:'35%', paddingTop:'0px'}}>               

                        <p>Most people think a simple "Will" or "Nominees" would suffice. They evaluate with their skills, knowledge and connections in mind. They forget, it is their 
                        BENEFICIARIES (predominantly spouse and children) who would have to do the hard work to get access to the assets. We at EasyInherit see your intent of ‘Will making’ 
                        as the first identified step in the process of Inheritance Planning. In fact we follow a five step process to 
                        help you plans your Inheritance customized to the needs of your loved ones and your life circumstances. </p>
                </div> 

                <div style={{ width:'30%', float:'left'}}><h4>Contact-less Delivery</h4></div> 
            
                <div style={{width:'100%', paddingLeft:'35%', paddingTop:'0px'}}>               

                        <p>EasyInherit’s unique Legal Tech Platform helps simplify the process of Estate and Inheritance Planning and 
                        receiving digitally and contact-less. This more so than ever is relevant now when the world is in the grips of 
                        Covid-19 and where every industry is aiming to become digital. </p>
                </div> 
         
                <div style={{ width:'30%', float:'left'}}><h4>Expert Counsel</h4></div> 
            
                <div style={{width:'100%', paddingLeft:'35%', paddingTop:'0px'}}>               

                       <p>Once you create your EasyInherit Inheritance Portfolio you get appropriate legal counsel and financial consultation 
                        from experts in estate management to determine the best course of action to fulfil your wishes in the form of a 
                        legal structure e.g.: A Will or Trust or Guardianship.</p>
                </div> 
            
                <div style={{ width:'30%', float:'left'}}><h4>Technology powered</h4></div> 
            
                <div style={{width:'100%', paddingLeft:'35%', paddingTop:'0px'}}>               

                      <p>We provide clear and precise legal counsel, clean documentation and hassle-free execution from the comfort of your 
                        home through our secure, AI powered tech platform. Our platform makes sure your Inheritance Management process is 
                        always miles ahead of the conventional documentation which is prone to manual errors.</p>
                </div> 
         
          
                <div id = 'team'><p> <br></br><br></br></p></div>

                <div style={{width:'100%', paddingLeft:'35%', paddingTop:'0px'}}>               
                            <p style={{ fontSize: 30,textAlign: 'left' }}>Meet the Team</p>
                </div>

                
                <div style={{width:'100%', paddingLeft:'35%', paddingTop:'0px'}}>               

                      <p>EasyInherit is a team effort. While we have Founders and Advisors, there is a large team of lawyers and 
                        AI experts that works to make everything possible.</p>
                </div> 
                
                <br></br><br></br>

                <div style={{ width:'30%', float:'left'}}><img alt='' width='180' height='200' src='../img/banner/sejal.jpg'></img></div> 
                <div style={{width:'100%', paddingLeft:'35%', paddingTop:'0px'}}>               

                    <p style={{ fontSize: 20,textAlign: 'left' }}><b>Sejal Sheth, Co-Founder and CEO</b></p>
                    <p>Sejal Sheth started the first decade of her career as a marketing professional with the 
                        communications agency JWT. Post that she spent the next decade establishing a Sustainability 
                        Consulting firm called Green Evangelist. She is an alumnus of NMIMS and has done her Strategic 
                        Management from IIM Calcutta. EasyInherit is her vision to make Inheritance for loved ones 
                        become a source of ‘joy and pride’ - like it’s meant to be – by eliminating all the ‘daud-bhaag’ 
                        around it.</p>
                </div>
                <div style={{width:'100%', paddingLeft:'10%', paddingRight:'2%', paddingTop:'0px'}}> <i>"EasyInherit is my vision to make inheritance a 
                    truly enjoyable experience - a matter of Pride and Joy - like it is meant to be. Minus the 'daud-bhag' that it entails today."</i> </div>
                        <br></br>
                <div  style={{ width:'50%'}}><img alt='' style={{float:'left'}}width='180' height='200' src='../img/banner/soubir.jpg'></img></div> 


                <div style={{width:'100%', paddingLeft:'35%', paddingTop:'0px'}}>               
                    <p style={{ fontSize: 20,textAlign: 'left' }}><b>Soubir Bose, Co-Founder and CTO</b></p>
                    <p>A technopreneur with more than two decades in the technology industry, this is Soubir's second venture.
                    An alumnus of IIM-K, he worked in the Silicon Valley with companies like Xerox PARC, Hewitt and Oracle 
                    on a wide range 
                    of technologies and domains. His last venture was a successful CRM product which he continues to direct. 
                    EasyInherit 
                    was born out of a desire to see his family, and many like his, navigate the legal quagmire of inheritance 
                    planning in India.
                    </p>
                </div> 
                <div style={{width:'100%', paddingLeft:'10%', paddingRight:'2%', paddingTop:'0px'}}> <i>"EasyInherit was born out of a desire to see
                    my family, and many like mine, navigate the quagmire of inheritance planning in India. More so, in these challenging times."</i> </div>
                    </div>

                    <div  style={{ width:'50%'}}><br></br><br></br><img alt='' style={{float:'left', verticalAlign:'center'}}width='180' height='45' src='../img/banner/ashira_logo.png'></img></div> 
                    <div style={{width:'100%', paddingLeft:'35%', paddingTop:'0px'}}>               
                    <p style={{ fontSize: 20,textAlign: 'left' }}><b>Our Legal Partners</b></p>
                    <p> The law offices of Ashira Law are our legal partner, providing high quality professional legal 
                        services with exceptional integrity, personal attention and flexibility. The firm 
                        provides transactional, advisory and litigation services in a wide spectrum of inheritance law.
                    </p>
                </div> 
    </div>
</div>

    )
}


export default (AboutUs);
