import React,{ useRef, useCallback, useImperativeHandle, useState } from "react";
import { forwardRef } from "react";
import NameForm from "./NameForm";
import GenderForm from "./Gender";

const NameAndGenderForm = ({defaultValue},ref) =>{
    let [gender, setGender] = useState(defaultValue.gender)
    let [name, setName] = useState(defaultValue.name);

    const refGenderForm = useRef();
    const refNameForm = useCallback(node => {
      if (node !== null && node.returnDetails()) {
        let {firstName, lastName, middleName, title} = node.returnDetails()
        if((firstName !=="" || lastName !== "" || middleName !== "" || title !== ""))
          setName(node.returnDetails())
        if(title !== "" ){
          if(title === 'Mr.')
            setGender('Male')
          else if((title === 'Miss.' || title === 'Mrs.' || title === 'Ms.'))
            setGender('Female')
        }
      }
    },[name]);
    useImperativeHandle(ref, () => ({
        returnDetails() {
          let details = {
            name,
            gender:gender===defaultValue.gender ? refGenderForm.current.returnDetails().gender: gender
          };
          return {...details}
        },
      }));
    return(
        <div>
            <NameForm ref={refNameForm} defaultValue={name}/>
            <GenderForm ref={refGenderForm} defaultValue={gender} />
        </div>
    )
}

const NameAndGender = forwardRef(NameAndGenderForm)
export default NameAndGender;