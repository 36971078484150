import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useInput } from '../../hooks/useInput'
import states from '../../resources/states.json'
import { getStoreUser, getStoreUserRole } from '../../selectors/userSelectors'
import { getCodeValueMap } from '../../utils/commonUtil'
import DropDown from '../DropDown'
import GetStartedTemplate from '../get_started/template'
import HeadBar from '../HeaderBar'
import Input from '../Input'
import TextArea from '../TextArea'
import Questions from './Questions'
import { getStoreFamily } from '../../actions/familyActions'
import { mailEstatePlan } from '../../dashboard/helper/MailingServices'
import { setUserData } from '../../actions/userActions'
import { updateUser } from '../../dashboard/forms/personal/personalService'
import { Post,Patch } from '../../utils/ApiUtil'
import { getSplitName, getContactFullName } from '../../utils/commonUtil'
import Auth from '@aws-amplify/auth'
import { AWS_CONFIG } from '../../constant/ServerConst'
import Digilocker from '../../dashboard/forms/digilocker/Digilocker';
import Login from '../../auth/Login'
import { Link } from 'react-router-dom'
import ModalContainer from '../../home/component/layout/Modal'
import { getUserIdByEmail } from '../../dashboard/forms/personal/personalService'
import CalenderSlots from '../../dashboard/components/CalenderSlots'
import { setEstatePlanData} from '../../actions/freeEstatePlanAction'
Auth.configure({ ...AWS_CONFIG.Auth })

const FreeEstatePlan = ({
  user,
  toggleModal,
  visible,
  dispatchSetUserDetails,
  child,
  userRole,
  dashboardSignUp,
  dispatchSetEstatePlanData,
  calender
}) => {
  const [page, setPage] = useState(0),
    [gender, setGender] = useState(user.gender),
    { value: dob, bind: setDOB } = useInput(user.dob),
    { value: religion, bind: setReligion } = useInput(user.religion),
    [indian, setNationality] = useState(
      user.nationality === 'Indian' ? 'Yes' : 'No'
    ),
    [married, setMarried] = useState(
      user.maritalStatus === 'Married' ? 'Yes' : 'No'
    ),
    [spouseIndian, setSpouseIndian] = useState(''),
    [haveChildren, setHaveChildren] = useState(child.length > 0 ? 'Yes' : 'No'),
    [anyMinorChild, setAnyMinorChild] = useState(
      child.length > 0 && child.some((e) => e.age !== null && e.age < 18)
        ? 'Yes'
        : 'No'
    ),
    [memberHUF, setMemberHUF] = useState(''),
    [hufImmovableAsset, setHUFImmovableAsset] = useState(''),
    [hufIASharePartOfEstate, setHUFIASharePartOfEstate] = useState(''),
    [hufFinInvestments, setHUFFinInvestments] = useState(''),
    [priorWill, setPriorWill] = useState(''),
    { value: executeWillIn, bind: setExecuteWillIn } = useInput(''),
    [pets, setPets] = useState(''),
    [mentionPetsInWill, setMentionPetsInWill] = useState(''),
    { value: otherWishes, bind: setOtherWishes } = useInput('')
    let [visibleLogin, setVisibleLogin] = useState(false)
    let toggleLogin = () => setVisibleLogin(!visibleLogin)
    const [updateId, setUpdateId] = useState('')
    const hufFISharePartOfEstate = ''

  const onSubmit = () => setPage(page + 1)
  
  const onSubmitUserChoice = () =>{
    religion === 'Hindu' ?
     setPage(page + 1) :
     setPage(page + 2)
  }
  const onSubmitBtn = () => {
    updateUser(user.id, {
      custField2: 'ESTATE_PLANNER_DONE',
    })
      .then((resp) => {
        dispatchSetUserDetails(resp.data)
      })
      .catch((err) => {
        console.log(err)
        alert('Please try again!')
      })
  }
  const { value: userDetailsNameValue, bind: userDetailsName } = useInput(dashboardSignUp ? '' :
    user ? getContactFullName(user) : ''
  ),
  { value: userDetailsEmailValue, bind: userDetailsEmail } = useInput(
    user ? user.primaryEmail : ''
  ),
  { value: userDetailsMobileValue, bind: userDetailsMobile } = useInput(
    user ? user.mobilePhone : ''
  )

  const createUser = async () => {
    const re = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/g
    if (
      re.test(String(userDetailsEmailValue).toLowerCase()) &&
      userDetailsMobileValue.length === 10
    ) {
      let phone = '+' + userDetailsMobileValue
      let awsPayload = {
        username: userDetailsEmailValue,
        password: 'Welcome@01',
        attributes: {
          email: userDetailsEmailValue,
          phone_number: phone,
          given_name: userDetailsNameValue,
        },
      }
      console.log(awsPayload)
      Auth.signUp(awsPayload)
        .then((response) => {
          console.log('aws signup complete')
          console.log(response)
          Post('userprofile/create?userId=' + response.userSub, {
            userName: response.userSub,
            primaryEmail: userDetailsEmailValue,
            mobilePhone: userDetailsMobileValue,
            custField2: 'ESTATE_PLANNER_DONE',
            ...getSplitName(userDetailsNameValue),
          })
            .then((resp) => {
              console.log('backend user create complete')
              console.log(resp)
              setUpdateId(resp.data.id)
              let estatePayload={
                custField2: 'ESTATE_PLANNER_DONE',
              }
              dispatchSetEstatePlanData(estatePayload)
              callApi() 
            })
            .catch((error) => {
              console.log('backend error')
              console.log(error)
              alert('Unable to register, please try again!')
            })
        })
        .catch((error) => {
          console.log('aws error')
          if (error.code === 'UsernameExistsException') {
            getUserIdByEmail(userDetailsEmailValue)
            .then(res=>{
              setUpdateId(res.data.id)
              Patch('/userprofile/update/'+res.data.id, {
                custField2: 'ESTATE_PLANNER_DONE',
              })
                .then((resp) => {
              let estatePayload={
                custField2: 'ESTATE_PLANNER_DONE',
              }
              dispatchSetEstatePlanData(estatePayload)
            })
          })
            callApi()
            //alert(error.message + ' Please try logging in!')
          } else alert(error.message)
        })
      // onSubmit()
    } else {
      if (userDetailsMobileValue.length !== 10)
        alert('please enter valid phone number')
      else
        alert(
          'Please enter a valid email address. You could be missing something in the e-mail format.'
        )
    }
  }

  const callApi = () => {
    let payload = userRole === 'GIVER' ? {
      userId: user.id,
      email: user.primaryEmail,
      gender,
      dob,
      religion,
      indian,
      married,
      spouseIndian,
      haveChildren,
      anyMinorChild,
      memberHUF,
      hufImmovableAsset,
      hufIASharePartOfEstate,
      hufFinInvestments,
      hufFISharePartOfEstate,
      priorWill,
      executeWillIn,
      pets,
      mentionPetsInWill,
      otherWishes,
    }
    :
    userRole === 'RECEIVER' ? {
      userId: user.id,
      email: user.primaryEmail,
      gender,
      dob,
      religion,
      indian,
      married,
      spouseIndian,
      haveChildren,
      anyMinorChild,
      memberHUF,
      hufImmovableAsset,
      hufIASharePartOfEstate,
      hufFinInvestments,
      hufFISharePartOfEstate,
      priorWill,
      executeWillIn,
      pets,
      mentionPetsInWill,
      otherWishes,
    } : dashboardSignUp ?
    {
      email: userDetailsEmail.value,
      gender,
      dob,
      religion,
      indian,
      married,
      spouseIndian,
      haveChildren,
      anyMinorChild,
      memberHUF,
      hufImmovableAsset,
      hufIASharePartOfEstate,
      hufFinInvestments,
      hufFISharePartOfEstate,
      priorWill,
      executeWillIn,
      pets,
      mentionPetsInWill,
      otherWishes,

    }:
    null

    console.log(payload)
    mailEstatePlan(payload)
      .then(() => {
        if(dashboardSignUp )
        {
          setPage(page + 1)
        }
        else
        {
         onSubmitBtn();
         setPage(page + 2)
        }
      })
      .catch((error) => {
        console.log(error)
        alert('Please try again!')
        toggleModal()
      })
  }
  const onBack = () => setPage(page - 1)

  const onBackUserChoice = () =>{
    religion === 'Hindu' ?
     setPage(page - 1) :
     setPage(page - 2)
  }

  const renderPage = () => {
    switch (page) {
      case 0:
        return (
          <GetStartedTemplate
            title={"Let's get started!"}
            bar={5}
            body={
              <div>
                <p style={{ textAlign: 'left', fontSize: 14 }}>
                  In the next few minutes, we're going to help you compile a
                  list of simple yet useful bits of information our Estate
                  Planning Consultants need to help evaluate your situation.
                  <br />
                  You can skip questions you're not ready to answer and come
                  back to them later before sending your list.
                  <br /> (Or leave them blank if they don't apply.)
                  <br />
                </p>
                <Button
                  onClick={onSubmit}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                >
                  Get Started
                </Button>
                <br />
                <Button
                  className='btn btn-outline-primary'
                  onClick={toggleModal}
                  style={{ paddingLeft: 40, paddingRight: 40 }}
                >
                  Later
                </Button>
                <p style={{ textAlign: 'left' }}></p>
              </div>
            }
          />
        )
      case 1:
        return (
          <GetStartedTemplate
            title={'A few questions from our Estate Planners '}
            bar={15}
            body={
              <div style={{ textAlign: 'left' }}>
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>

                {userRole === 'GIVER' ?
                <div>
                                  <p style={{ textAlign: 'left', fontSize: 14 }}>
                  We need to know a few details that can help our
                  Estate Planners guide you better. E.g. your nationality
                  determines how Indian laws would apply to you. Or your
                  religion determines which Succession Act applies. Indian
                  succession is determined by acts like the Hindu Succession Act
                  or the Muslim Personal Law Board or the Indian Succession Act.
                  <br />
                  <br />
                </p>
                <div className='row' style={{ marginLeft: 5 }}>
                  <HeadBar title={'What is your gender ?   '} />
                  <div className='row' style={{ marginLeft: 5 }}>
                    <div className='form-check form-check-inline'>
                      <label className='form-check-label'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={gender === 'Male'}
                          name={'inlineRadiogender'}
                          id={'inlineRadiogender'}
                          value={'Male'}
                          onChange={(event) => setGender(event.target.value)}
                        />
                        Male
                      </label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <label className='form-check-label'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={gender === 'Female'}
                          name={'inlineRadiogender'}
                          id={'inlineRadiogender'}
                          value={'Female'}
                          onChange={(event) => setGender(event.target.value)}
                        />
                        Female
                      </label>
                    </div>
                    <div className='form-check form-check-inline'>
                      <label className='form-check-label'>
                        <input
                          className='form-check-input'
                          type='radio'
                          checked={gender === 'Other'}
                          name={'inlineRadiogender'}
                          id={'inlineRadiogender'}
                          value={'Other'}
                          onChange={(event) => setGender(event.target.value)}
                        />
                        Other
                      </label>
                    </div>
                  </div>
                </div>
                <Questions
                  question={'Are you an Indian National  ?'}
                  setFunction={setNationality}
                  value={indian}
                />
                <div
                  className='row'
                  style={{
                    marginLeft: 5,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <HeadBar title={'What is your Date of Birth ?'} />
                  <Input isBigger type='date' bind={setDOB} />
                </div>
                <div
                  className='row'
                  style={{
                    marginLeft: 5,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <HeadBar title={'What is your Religion  ?  '} />
                  <DropDown
                    placeholder='Please Select'
                    options={getCodeValueMap([
                      'Hindu',
                      'Buddhist',
                      'Sikh',
                      'Jain',
                      'Christian',
                      'Jewish',
                      'Parsi',
                      'Muslim',
                      'Other',
                    ])}
                    bind={setReligion}
                  />
                </div>
                </div>
                : 
                  <div
                  className='row'
                  style={{
                    marginLeft: 5,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ textAlign: 'left', fontSize: 14 }}>
                  We need to know a few details that can help our
                  Estate Planners guide you better. <br></br>
                  If you have a legal succession document, it would be a great help if you can upload it to your 
                  DigiLocker. This is a Govt. of India secure site and even we cannot access without your approval. 
                  <br />
                  <br />
                </p>
                <div className='row'>
                  <HeadBar title={'Do you have a succession document ?  '} />
                  <DropDown
                    placeholder='Please Select'
                    options={getCodeValueMap([
                      'No',
                      'A Will',
                      'Probate Order',
                      'Letter of Authorization',
                      'Succession Certificate',
                      
                    ])}
                    bind={setReligion}
                  /> 
                  {dashboardSignUp ? null :
                  <Digilocker uploadType = {'uploadIcon'}/>
                  }
                  </div>
                </div>
                }
                
                          
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={onBack}
                    style={{
                      margin: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={onSubmit}
                    style={{
                      margin: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            }
          />
        )
      case 2:
        return (
          <GetStartedTemplate
            title={'Estate Planner Consultation'}
            bar={35}
            body={
              <div style={{ textAlign: 'left' }}>
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>
                {userRole === 'GIVER' ?
                <div>
                <p style={{ textAlign: 'left', fontSize: 14 }}>
                  The nationality of your spouse is important. The age of your
                  children are even more important since minors cannot legally
                  enter contracts and need guardians to be provided. If you do
                  not explicitly do so, the State will provide so.
                  <br />
                  <br />
                </p>
                <Questions
                  question={'Are you presently married ?'}
                  setFunction={setMarried}
                  value={married}
                />
                <Questions
                  question={'Is your spouse an Indian National ?'}
                  setFunction={setSpouseIndian}
                  value={spouseIndian}
                />
                <Questions
                  question={'Do you have children ? '}
                  setFunction={setHaveChildren}
                  value={haveChildren}
                />
                <Questions
                  question={'Are any of them a Minor ?'}
                  setFunction={setAnyMinorChild}
                  value={anyMinorChild}
                />
                 </div>

                : 
                
                <div>
                  <p style={{ textAlign: 'left', fontSize: 14 }}>
                  A few more facts about your situation will help our planners 
                  advise you better. For eg. your nationality or your religion ( yes, in India succession laws 
                  are based on your religious affiliation ).
                  <br />
                  <br />
                </p>
                <Questions
                  question={'Are you an Indian National  ?'}
                  setFunction={setNationality}
                  value={indian}
                /> <p></p>
                <HeadBar title={'What is your religion ?'}/>
                <DropDown
                    placeholder='Please Select'
                    options={getCodeValueMap([
                      'Hindu',
                      'Buddhist',
                      'Sikh',
                      'Jain',
                      'Christian',
                      'Jewish',
                      'Parsi',
                      'Muslim',
                      'Other',
                    ])}
                    bind={setReligion}
                  />
                </div>
                }
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={onBack}
                    style={{
                      margin: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={onSubmitUserChoice}
                    style={{
                      margin: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            }
          />
        )
      case 3:
        return (
          <GetStartedTemplate
            title={'Estate Planner Consultation'}
            bar={55}
            body={
              <div style={{ textAlign: 'left' }}>
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>
                <p style={{ textAlign: 'left', fontSize: 14 }}>
                  Ownership in a business is an important information,
                  especially if the business interest is to be passed on to 
                  beneficiaries. If you own a share in a HUF, our Estate
                  Planners and Lawyers need to understand the situation to
                  advise you best.
                  <br />
                  <br />
                </p>
                {userRole === 'GIVER' ?
                <div>
                <Questions
                  question={
                    'Do you own a business or member of a Hindu United Family ?'
                  }
                  setFunction={setMemberHUF}
                  value={memberHUF}
                  dontKnowOption
                />
                <Questions
                  question={'Does the HUF have any immovable assets ? '}
                  setFunction={setHUFImmovableAsset}
                  value={hufImmovableAsset}
                  dontKnowOption
                />

                <Questions
                  question={'Does the HUF have any Financial Investments ?'}
                  dontKnowOption
                  setFunction={setHUFFinInvestments}
                  value={hufFinInvestments}
                />
                <Questions
                  question={
                    'Would your share in such business be part of your Estate  ?'
                  }
                  dontKnowOption
                  setFunction={setHUFIASharePartOfEstate}
                  value={hufIASharePartOfEstate}
                />
                 </div>
                
                :
                
                <div> 
                
                <Questions
                question={
                  'Are you a member of a Hindu United Family ?'
                }
                setFunction={setMemberHUF}
                value={memberHUF}
                dontKnowOption
              />
              <Questions
                question={'Does the HUF have any immovable assets ? '}
                setFunction={setHUFImmovableAsset}
                value={hufImmovableAsset}
                dontKnowOption
              />
              <Questions
                question={'Does the HUF have any Financial Investments ?'}
                dontKnowOption
                setFunction={setHUFFinInvestments}
                value={hufFinInvestments}
              />
                              <Questions
                  question={
                    'Have you inherited any of the above  ?'
                  }
                  dontKnowOption
                  setFunction={setHUFIASharePartOfEstate}
                  value={hufIASharePartOfEstate}
                />
              </div>
                }
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={onBackUserChoice}
                    style={{
                      margin: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={onSubmit}
                    style={{
                      margin: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            }
          />
        )
      case 4:
        return (
          <GetStartedTemplate
            title={'Estate Planner Consultation'}
            bar={70}
            body={
              <div style={{ textAlign: 'left' }}>
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>
                {userRole === 'GIVER' ?
                <div>
                 <p style={{ textAlign: 'left', fontSize: 14 }}>
                  This is very important, since a new Will always nullifies an
                  older one. However, this is also one of the most contested
                  parts of Estate Planning, since interested parties can question
                  the veracity of either document. Our Estate Planners and
                  Lawyers will ensure beneficiaries do not suffer for a
                  small mistake.
                  <br />
                  <br />
                </p>
                <Questions
                  question={'Have you made a Will prior to this ?'}
                  setFunction={setPriorWill}
                  value={priorWill}
                />
                <div
                  className='row'
                  style={{
                    marginLeft: 5,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <HeadBar
                    title={'Where would you like the Will to be executed ?'}
                  />
                  <DropDown
                    placeholder='Please Select'
                    options={states}
                    bind={setExecuteWillIn}
                  />
                </div>
                </div>

                : 
                
                <div>
                  <p style={{ textAlign: 'left', fontSize: 14 }}>
                  This is very important section. Later Wills always nullify 
                  older ones and late discovery complicates the process. 
                  Knowing existing liabilities (like loans or mortgages) on the estate is as important.
                  We ensure beneficiaries are well advised and do not suffer for 
                  small mistakes.
                  <br />
                  <br />
                </p>

                  <Questions
                  question={'Are you aware of a prior or later will ?'}
                  setFunction={setPriorWill}
                  value={priorWill}
                  dontKnowOption/>

                  <Questions
                  question={'If yes, was it registered ?'}
                  setFunction={setPriorWill}
                  value={priorWill}
                  dontKnowOption/>   

                <Questions
                  question={'Are there any liabilities on the estate like Loans, Mortgages etc ?'}
                  setFunction={setPriorWill}
                  value={priorWill}
                  dontKnowOption/>  

                </div>
                }
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={onBack}
                    style={{
                      margin: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={onSubmit}
                    style={{
                      margin: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            }
          />
        )
      case 5:
        return (
          <GetStartedTemplate
            title={'Estate Planner Consultation'}
            bar={85}
            body={
              <div style={{ textAlign: 'left' }}>
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>

                {userRole === 'GIVER' ?
                <div>
                  <p style={{ textAlign: 'left', fontSize: 14 }}>
                  We absolutely understand the sentiments of pet parents. And
                  while many others would laugh away at providing for such
                  needs, our Estate Planners would like to assure you that we do
                  not take this lightly at all.
                  <br />
                  <br />
                </p>
                <Questions
                  question={'Do you have any pets ? '}
                  setFunction={setPets}
                  value={pets}
                />
                <Questions
                  question={
                    'If Yes, would you want them to be specially mentioned in your Will ?'
                  }
                  setFunction={setMentionPetsInWill}
                  value={mentionPetsInWill}
                />
                <div
                  className='row'
                  style={{
                    marginLeft: 5,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <HeadBar
                    title={
                      'Do you have any other wishes ? For example donations to a charity or socio-medical causes.'
                    }
                  />
                  <TextArea bind={setOtherWishes} />
                </div>
                <div
                  className='row'
                  style={{
                    marginLeft: 5,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                ></div>
                 </div>
                : <div>
                <p style={{ textAlign: 'left', fontSize: 14 }}>
                  We absolutely understand the sentiments of pet parents or desires of higher goals. 
                  And while many others would laugh away at providing for such
                  needs, our Estate Planners would like to assure you that we do
                  not take this lightly at all.
                  <br />
                  <br />
                </p>
                <Questions
                  question={
                    'Were provisions for other causes specially mentioned in the Will (e.g. charities, pets etc) ?'
                  }
                  setFunction={setMentionPetsInWill}
                  value={mentionPetsInWill}
                />

                 <HeadBar
                    title={
                      'Were there any other undocumented wishes ?'
                    }
                  />
                  <TextArea bind={setOtherWishes} />
                </div>
                }
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={onBack}
                    style={{
                      margin: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    onClick={onSubmit}
                    style={{
                      margin: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            }
          />
        )
      case 6:
        return (
          <GetStartedTemplate
            title={'🎉 All done!'}
            bar={90}
            body={
              <div>
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>
                <p>
                  Do you want to send the Estate planner consultation form to
                  yourself?{' '}
                </p>
                <Button
                    onClick={onBack}
                    style={{
                      margin: 10,
                      paddingLeft: 40,
                      paddingRight: 40,
                    }}
                  >
                    Back
                  </Button>
                <Button
                  //onClick={callApi}
                  onClick={dashboardSignUp ? onSubmit : callApi}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                >
                  Send it to me
                </Button>
                
              </div>
            }
          />
        )
        case 7:
        return (
          <GetStartedTemplate
            title={'Securing your account'}
            bar={95}
            body={
              <div>
                  <p style={{ textAlign: 'left', fontSize: 14 }}>
                  Let's set up your account so none of your information is lost.{' '}
                  <br />
                  Fear not, your info will be kept completely secure!
                </p>

                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>What's your name?</b>
                  </label>
                  <Input
                    noClass
                    type='text'
                    className='form-control form-control-lg'
                    bind={userDetailsName}
                  />
                </div>
                <br />
                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>What is your email address?</b>
                  </label>
                  <Input
                    noClass
                    type='email'
                    className='form-control form-control-lg'
                    bind={userDetailsEmail}
                  />
                  <small className='form-text text-muted'>
                    We'll never share your email with anyone else.
                  </small>
                </div>
                <div className='form-group' style={{ textAlign: 'left' }}>
                  <label>
                    <b>What is your phone number?</b>
                  </label>
                  <Input
                    noClass
                    type='number'
                    className='form-control form-control-lg'
                    bind={userDetailsMobile}
                  />
                  <small className='form-text text-muted'>
                    We'll never share your number with anyone else.
                  </small>
                </div>
                <Button
                  onClick={createUser}
                  style={{
                    paddingLeft: 40,
                    paddingRight: 40,
                    marginTop: 50,
                    marginBottom: 50,
                  }}
                  disabled={
                    !(
                      userDetailsNameValue !== '' &&
                      userDetailsMobileValue !== '' &&
                      userDetailsEmailValue !== ''
                    )
                  }
                >
                  Continue
                </Button>
              </div>
            }
          />
        )
        case 8:
        return (
          <CalenderSlots name={userDetailsNameValue} email={userDetailsEmailValue} freeConsult={'true'} updateId={updateId} toggleFreeConsultModal={toggleModal} consultWho = {'Our Estate Planners'}/>
        )
      case 9:
        return (
          <GetStartedTemplate
            title={'One more step'}
            bar={99}
            body={
              <div style={{ textAlign: 'left', fontSize: 14 }}>
                <button style={{position:'absolute',top:'3%', left:'90%'}} type='button' className='close' onClick={toggleModal}>&times;</button>
                <p>
                  We compiled your Estate planner consultation form and sent a
                  personalized email to{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {user.primaryEmail}
                  </span>
                  <br />
                  Please look for an email from{' '}
                  <span style={{ color: '#007bff', fontWeight: 'bold' }}>
                    EasyInherit
                  </span>{' '}
                  in your inbox.
                </p>
                <p>
                {dashboardSignUp ?
                <div> Please{' '}
                  <Link
              className='top-nav-link'
              style={{ fontWeight: 500, marginRight: 25, color: '#000' }}
              onClick={toggleLogin}
            >
             <span style={{ color: '#007bff', fontWeight: 'bold' }}>
             Login
              </span>
            </Link>
            <ModalContainer visible={visibleLogin} toggle={toggleLogin}>
              <div style={{ padding: '40px' }}>
                <Login onClose={toggleLogin} />
              </div>
            </ModalContainer>to get
            </div>
                :
                  'Book your'
            }{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    30 minute FREE appointment{' '}
                  </span>
                  with our Estate planning consultants by selecting a slot on
                  the calendar once you exit this pop-up.
                  <br />
                </p>
                <p>
                  Our Estate planning consultants will get back to you as soon
                  as possible.
                </p>
                {dashboardSignUp ? null :
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    onClick={dashboardSignUp ? toggleModal : onSubmitBtn}
                    style={{
                      paddingLeft: 40,
                      paddingRight: 40,
                      marginTop: 50,
                      marginBottom: 50,
                    }}
                  >
                    Schedule Now!
                  </Button>
                </div>
                }
              </div>
            }
          />
        )

      default:
        return null
    }
  }
  return (
    <Modal
      show={visible}
      size='lg'
      scrollable
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      {renderPage()}
    </Modal>
  )
}

const mapDispatchToProps = () => (dispatch) => ({
  dispatchSetUserDetails: (user) => dispatch(setUserData(user)),
  dispatchSetEstatePlanData : (estatePlan) => dispatch(setEstatePlanData(estatePlan))
})

const mapStateToProps = (state) => ({
  user: getStoreUser(state),
  child: getStoreFamily(state).filter((f) => f.relationship === 'CHILD'),
  userRole:getStoreUserRole(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(FreeEstatePlan)
