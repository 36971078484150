import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getStoreContactList, setContactData } from '../../../actions/contactActions';
import { ContainerPosition, CustomButtom, HelpSection, HighlightedText, List } from '../../../components/CustomElements';
import string from '../../../constant/en.json';
import { getStoreUserId } from '../../../selectors/userSelectors';
import { batchState, getContactFullName } from '../../../utils/commonUtil';
import ContactListTable from '../../components/ContactTable';
import ContactForm from './ContactForm';
import { getContactListFromServer } from './ContactService';
import SliderBar from '../../../components/UploadComponent';

const Contacts = ({ userId, contactList, dispatchSetContactList }) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [contact, setContact] = useState();
  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && contact) {
        setContact(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setContact(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
      setContact(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const docsRef = React.useRef()
  const onUpload = (c) => {
    docsRef.current.submit('CONTACT', c.id, getContactFullName(c))
  }
  useEffect(() => {
    getContactListFromServer(userId)
      .then(response => {
        dispatchSetContactList(response.data)
      })
      .catch(error => {
        alert(error);
      })
  }, [])
  return (
    <div>
      <HelpSection heading="HELP TIPS!">
        <List>
          Add all your contacts in place to make it easier for you to pick executors and beneficiaries . Make sure you fill all the necessary blanks as best as you can.{' '}
          <HighlightedText>{string.forms.commonFormFields.mandatoryFields}</HighlightedText>
        </List>
      </HelpSection>
      {/* display added contacts table here */}
      <DisplayTableAddBtn contactList={contactList} onUpload={onUpload} onEdit={onEdit} isFormHidden={isFormHidden} />
      <div style={{ marginBottom: 20, display: 'block' }}>
        <ContainerPosition>
          <CustomButtom event={() => toggleForm()} title={string.forms.contactSpecific.addContact} />
        </ContainerPosition>
      </div>
      {/*display form for new contact below */}
      {isFormHidden ? null : <ContactForm contact={contact} userId={userId} onCancel={toggleForm} onDone={onDone} />}
      <SliderBar ref={docsRef} />
    </div>
  );
};
const DisplayTableAddBtn = ({ contactList, onEdit, onUpload }) => {
  return (
    contactList.length > 0 ?
      <div style={{ marginBottom: 20, display: 'block' }}>
        <ContactListTable data={contactList} onUpload={onUpload} onEdit={onEdit} conType={'Contacts'} />
      </div>
      : null
  );
}

const mapStateToProps = (state) => ({
  contactList: getStoreContactList(state),
  userId: getStoreUserId(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchSetContactList: contact => dispatch(setContactData(contact)),
  // dispatchSetAllContactList: contact => dispatch(setAllContactData(contact)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Contacts);

