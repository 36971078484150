import React from 'react';
import { initialAssets } from './InitialAssets';

const AssetsBody = ({ selectedAssets }) => {
    return <div className='card-block'>
        {selectedAssets.map((e,i) => <React.Fragment key={i}>
            {initialAssets[e]}
            <br />
        </React.Fragment>)}
    </div>
};
export default AssetsBody;