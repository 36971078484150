import React, { useState, useEffect } from 'react';
import { setUserData } from '../../actions/userActions';
import { connect } from 'react-redux';
import ModalContainer from '../../home/component/layout/Modal';
import ChangePassword from '../../auth/ChangePassword'
import { getStoreUser } from '../../selectors/userSelectors';
import Auth from "@aws-amplify/auth";
import { AWS_CONFIG } from '../../constant/ServerConst'
Auth.configure({ ...AWS_CONFIG.Auth });

 const Profile =({personalDetails})=>{

    const [visibleLogin, setVisibleLogin] = useState(false)
    const toggleLogin = ()=>setVisibleLogin(!visibleLogin)
    const [changePassword, setchangePassword] = useState(false);

    useEffect( () =>{
        Auth.currentAuthenticatedUser()
        .then(currentUser => {
        if(currentUser.username.slice(0, 6) === 'Google')
        {
          setchangePassword(true)
        }
    })
      },[])

  return (
    <div className="container" style={{ marginTop: '8vh' }}>
        <div className="section_title">
            <h2>My Profile</h2>
        </div>
        <hr/>
        <div style={{ marginTop:'10vh', marginLeft:'3vw',marginRight:'3vw', boxShadow: '10px 10px 10px grey, 0 0 10px black', padding:'3vw'}}>
        <div className='row'>
            <div  className="col-sm-4">
                <div align="center">
                    <i className="fas fa-user-circle" style={{fontSize:'160px', color: 'grey'}}></i>
                </div><br/>
                <div  style={{fontSize:20}} className="text-center"><b>{personalDetails['firstName']+' '+ personalDetails['lastName']}</b></div>
            </div>
            <div className="col-sm-8 border-left" style={{'paddingLeft':'5vh'}}><br/><br/><br/>
            <div>
                <i className="fas fa-envelope" style={{fontSize: 15, color: 'grey'}}></i><span style={{paddingLeft:15}}>{personalDetails['primaryEmail'].toLowerCase().trim()}</span>
            </div><br/>
            <div>
                <i className="fas fa-phone" style={{fontSize: 15, color: 'grey',float:'left'}}></i><span style={{paddingLeft:15}}>{personalDetails['mobilePhone']}</span>   
            </div> 
            </div>
        </div>    
    </div>
        <br/>
        {changePassword ? null :

        <div className='form-group' style={{marginTop:'5vh', textAlign:'center'}}>
            <button type='submit' className='btn btn-primary' onClick={toggleLogin}>
                Change Password
            </button>
            <ModalContainer visible={visibleLogin} toggle={toggleLogin}>
                <div style={{ padding: '40px' }}>
                    <ChangePassword onClose={toggleLogin} />
                </div>
            </ModalContainer>
      </div>
      }
      <br/><br/><br/>
    </div>
  );
};
const mapStateToProps = (state) => ({
    personalDetails: getStoreUser(state)
  });

  const mapDispatchToProps = () => dispatch => ({
    dispatchSetUserDetails: user => dispatch(setUserData(user))
  })
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
