export const ADD_DATASHARED_WITHME_CONSTANTS = 'ADD_DATASHARED_WITHME_CONSTANTS';
export const setDataShareWithme = (dataShareWithme) => {

    return {
        type: ADD_DATASHARED_WITHME_CONSTANTS,
        dataShareWithme
    }
}

export const getDataShareWithme = state => state.dataShareWithme;
