import React from 'react';
import SectionHeading from '../../home/component/layout/SectionHeading';

const Refund = ()  => {

    let imgUrl = '../img/banner/greybackground.png';

    const RefundTerms = () =>{
        return(
            <div>
            <SectionHeading heading={'Refund Policy'} subHeading={''} image={'../img/banner/plain_greybackground.png'}/>
            <p align='justify'>Your happiness is important to us. <br></br><br></br>
            At EasyInherit, we have full confidence in our varied range of services, products and solutions. 
            We leave no stone unturned in providing our esteemed clients fast, reliable and exceptional 
            service guarantee every time in time we do business with them. <br></br><br></br>
            All the services available at EasyInherit's website are carried out for the clients after extensive 
            analysis using complete scope documentation. It ensures full understanding of the work and almost 
            no possibilities of any project cancellation, reversal or dispute. <br></br><br></br>
            However, in the rarest occassion where you are not satisfied with our products or services, we are happy to refund, subject to certain conditions.</p><br></br><br></br>
            </div>
        )
    }
    return(
 <div className='container' style={{textAlign:'justify'}}>

        <div className='d-block d-sm-none'>         

        <div className = 'Component-Bg' 
                style = {{ backgroundImage: `url(${imgUrl})`,
                backgroundSize: 'cover', 
                backgroundPosition: 'left center',
                backgroundRepeat: 'no-repeat',
                }}> 
        
        <div style={{width:'100%', paddingLeft:'5%', paddingRight:'2%'}}>  
            <RefundTerms/>
        </div>
        </div>
        </div>

        <div className='d-none d-sm-block'>

        <div className = 'Component-Bg' 
                style = {{ backgroundImage: `url(${imgUrl})`,
                backgroundSize: 'cover', 
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                }}> 
          
        <div style={{width:'100%', paddingLeft:'35%', paddingRight:'2%',paddingTop:'40px'}}>  
            <RefundTerms/>
        </div>
        </div>
        </div>

            <br></br>
                    <p>(1) A subscriber can place the cancellation order for a particular service within 3 days of service agreement via e-mail to info@easyinherit.in</p>
                    <p>(2) Fees are due after signing a contract. Fee, once paid, will not be refunded in any case if the cancellation is posted after 24 hours of paying a fee.</p>
                    <p>(3) A service stands cancel if next payment is not received within 7 working days.</p>
                    <p>(4) There is always a certain set of resources that are involved in achieving every milestone of a campaign. Thus, a refund would not be entertained for the work already completed.</p>
                    <p>(5) No refund is possible for the fee paid in the form of administrative fees or taxes paid to the government or regulatory bodies.</p>
                    <p>(6) EasyInherit is not liable to pay refund if delay or service disruption happens due to third party involvement or factors beyond our control.</p>
                    <p>(7) All refunds are credited to the client's bank account with 7 days from acceptance of a refund request.</p><br></br><br></br>


                    <p>If case of any questions or clarifications, please write to us <u>info@easyinherit.in</u>. We would be happy to talk to you.
                    </p><br></br>
    </div>

    )
}


export default (Refund);
