import onClickOutside from "react-onclickoutside";
import React from 'react'
import './Header.css';
import { SignOut } from '../../../auth/SignOut'

class DashboardMobileDropdown extends React.Component {
      
    handleClickOutside = () => {   
            var dropdowns = document.getElementsByClassName("dropdown-content4");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
              var openDropdown = dropdowns[i];
              if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
              }
            }
          
  }
  
     showDropdown(d) {
        document.getElementById(d).classList.toggle("show");
      }
 
  render() {
    return (
            <div>
                <ul
                    className='navbar-nav'
                    style={{
                        position: 'absolute',
                        right: 0,
                        textAlign: 'right',
                        top: 8,
                        cursor:'pointer',
                        }}
                    >
                    <li className='nav-item dropdown'>
                        <span onClick={() => this.showDropdown('myDropdown8')} class="dropbtn" style={{color:'white'}}>
                            <img
                             alt=''
                            src='../img/banner/profile.png'
                            width='40'
                            height='38'
                            className='rounded-circle '
                            />
                        </span>
                        <div
                        id="myDropdown8" 
                        class="dropdown-content4"
                        aria-labelledby='navbarDropdownMenuLink'
                        style={{textAlign:'left'}}
                        >
                        
                        <a className='dropdown-item' href='./profile'>
                        My Account
                        </a>
                        <a className='dropdown-item' href='./myorders'>
                        My Orders
                        </a>
                    
                        <span
                        className='dropdown-item'
                        onClick={() => SignOut(this.props.history, this.props.dispatch, this.props.personalDetails)}
                        style={{ cursor: 'pointer', color:'black' }}
                        >
                        Log Out
                        </span>
                        </div>
                        </li>
                        </ul>
      </div>
    );
  }
}

export default onClickOutside(DashboardMobileDropdown)