export const ADD_LIABILITY_LIST = 'ADD_LIABILITY_LIST';
export const setLiabilityData = (liability) => {

    return {
        type: ADD_LIABILITY_LIST,
        liability
    }
}
export const DELETE_LIABILITY = 'DELETE_LIABILITY';
export const deleteLiabilityFromList = (liabilityId) => {

    return {
        type: DELETE_LIABILITY,
        liabilityId
    }
}

export const EDIT_LIABILITY = 'EDIT_LIABILITY';
export const editLiabilityAction = (liability) => {
    return {
        type: EDIT_LIABILITY,
        liability
    }
}

export const CREATE_LIABILITY = 'CREATE_LIABILITY';
export const createLiabilityAction = (liability) => {
    return {
        type: CREATE_LIABILITY,
        liability
    }
}
export const getStoreLiabilityList = state => state.liability;