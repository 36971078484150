export const getStoreUser = state => state.user;

export const getNameUser = state => state.user.firstName;
export const getStoreUserId = state => state.user.id;

export const getSideBarSession = state => state.sideBar;

export const getStoreUserRole = state => state.userRolesData.roleLookupCode;

export const getStoreRefreshToken = state => state.digilockerData.custField2;

export const getStoreAccessToken = state => state.digilockerData.custField1;