import moment from "moment";
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getContactFromFamOrCon, getStoreContactList } from '../../../actions/contactActions';
import { getStoreFamily } from '../../../actions/familyActions';
import { deleteLiabilityFromList, getStoreLiabilityList, setLiabilityData } from '../../../actions/liabilitiesActions';
import CollapseSection from '../../../components/CollapsibleSection';
import {
  ActionItem, AlignColumn, CenterAlignRow,
  ContainerPosition, CustomButtom, HelpSection,
  List, TableContainer
} from '../../../components/CustomElements';
import string from '../../../constant/en.json';
import { getStoreUserId, getStoreUserRole } from '../../../selectors/userSelectors';
import { batchState, getContactFullName, getLiabType } from '../../../utils/commonUtil';
import { deleteLiability, getLiabilityListFromServer } from './LiabilitiesService';
import LiabilityForm from './LiabilityForm';
import { useQuaishToasts } from '../../../actions/alertAction';

const Liabilities = ({ familyList, contactList, liabilityList, userId, dispatchSetLiabilityList, dispatchDeleteLiability, userRole }) => {
  const {error } = useQuaishToasts()
  const receiverFlag= userRole==='GIVER' ? false : true
  useEffect(() => {
    getLiabilityListFromServer(userId, receiverFlag).then(response => {
      dispatchSetLiabilityList(response.data)
    }).catch(err => {
      error(err.message)
    })
  }, [userId])
  let [isFormHidden, formVisibility] = useState(true);
  const [liab, setLiab] = useState()
  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && liab) {
        setLiab(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  liabilityList = liabilityList.map(l => ({ ...l, contacts: getContactFromFamOrCon(familyList, contactList, l.contacts), userLiabilitiesBeneficiaries: l.userLiabilitiesBeneficiaries.map(b => ({ ...b, contacts: getContactFromFamOrCon(familyList, contactList, b.contacts) })) }))
  return (
    <div>
      <div style={{ marginBottom: 20, display: 'block' }}>
        <HelpSection heading="HELP TIPS!">
          <List>{string.forms.liabilitySpecific.liabHelpTipHeader}</List>

        </HelpSection>
        {liabilityList.length > 0 ? <div>
          <SavedLiabilities setLiab={setLiab} data={liabilityList} setToggleForm={formVisibility} dispatchDeleteLiability={dispatchDeleteLiability} userRole={userRole}/>
        </div> : null}
        <ContainerPosition>
          <CustomButtom event={() => toggleForm()} title={string.forms.liabilitySpecific.addLiability} />
        </ContainerPosition>
      </div>
      {isFormHidden ? null : <LiabilityForm data={liab} isHidden={isFormHidden} onCancel={toggleForm} />}
    </div>
  );
}




const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
  liabilityList: getStoreLiabilityList(state),
  contactList: getStoreContactList(state),
  familyList: getStoreFamily(state),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchSetLiabilityList: liability => dispatch(setLiabilityData(liability)),
  dispatchDeleteLiability: liabilityId => dispatch(deleteLiabilityFromList(liabilityId)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Liabilities);







export function SavedLiabilities ({ data, setToggleForm, dispatchDeleteLiability, setLiab, dispalyActions, userRole }) {
  const { success, error } = useQuaishToasts()
  const deleteLiab = (id) => {
    if (window.confirm("Delete?\n Are you sure you want to delete this record?")) {
      deleteLiability(id)
        .then(response => {
          success('Record Deleted Successfully'); console.log(JSON.stringify(response))
          dispatchDeleteLiability(id);
          if (data.length === 0) setToggleForm(true)
        })
        .catch(err => {
          error(err)
        })
    }

  }
  const getBenName = (userBens) => {
    const l = userBens.length;
    const more = l === 1 ? "" : `  +${l - 1}`
    return `${getContactFullName(_.get(_.head(userBens), ['contacts']))} ${more}`;
  }
  const row = data.map((e, i) => {
    return (
      <TableContainer key={i}>
        <CenterAlignRow>
        {dispalyActions === 'no' || userRole ==='RECEIVER'? null :
          <AlignColumn label="Lender" text={e.contacts !== null ? getContactFullName(e.contacts) : ''} />
    }
          <AlignColumn label="Type" text={getLiabType(e)} />
          <AlignColumn label="Start Date" text={moment(e.liabStartDate).format('YYYY-MM-DD')} />
          <AlignColumn label="End Date" text={moment(e.liabEndDate).format('YYYY-MM-DD')} />
          <AlignColumn label="Account No." text={e.liabAccountNumber} />
          {dispalyActions === 'no' || userRole ==='RECEIVER' ? null :
          <AlignColumn label="To Be Paid By" text={e.userLiabilitiesBeneficiaries.length === 0 ? 'Estate' : getBenName(e.userLiabilitiesBeneficiaries)} />
    }
          {dispalyActions === 'no' ? null :
          <AlignColumn label="Action" text={<ActionItem onEdit={() => {
            setLiab(e)
            setToggleForm(false)
          }} onDelete={() => deleteLiab(e.id)} datashare={'noShare'}/>} />
        }
        </CenterAlignRow>
      </TableContainer>
    )
  });
  return (
    <CollapseSection mainTitle={string.forms.liabilitySpecific.savedLiabilities} collapsed={false} isCollapsable={true} customStyle={true}>
      {row}
    </CollapseSection>
  );
};




