export const ADD_PAYMENT_CONSTANTS = 'ADD_PAYMENT_CONSTANTS';
export const setPaymentData = (payment) => {

    return {
        type: ADD_PAYMENT_CONSTANTS,
        payment
    }
}

export const getPaymentData = state => state.payment;
