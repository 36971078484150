import React,  { useEffect }  from 'react';
import { useLocation } from 'react-router-dom';
import { setDigilockerData, getDigilockerList, setDigilockerFiles, getDigilockerFileList } from '../../../actions/digilockerAction'
import { getStoreRefreshToken, getStoreAccessToken, getStoreUserId } from '../../../selectors/userSelectors'
import { connect } from 'react-redux'
import { updateUserDocumentById, createUserDocument, getAccessToken, getUserDocumentById } from './digilockerServices';
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
const DigilockerAuth = ({userId, tokenData})  => {
    const query = useQuery();
    const authCode=query.get('code')
    useEffect(() => {
      getAccessToken(authCode)
      .then(access => {
        getUserDocumentById(userId)
        .then(response => {
            const responseData = response.data
        if(Object.keys(responseData).length === 0 ){
        const format1 = "YYYY-MM-DD HH:mm:ss"
        const date1 = new Date()
        const now = moment(date1).format(format1);
        const date2 = new Date()
        date2.setHours( date2.getHours() + 1 );
        const validTill = moment(date2).format(format1);
        const date3 = new Date()
        date3.setMinutes(date3.getMinutes() + 50)
        const accessTokenTimestamp = moment(date3).format(format1);
        createUserDocument(userId, access.data.access_token, access.data.refresh_token, now, validTill, accessTokenTimestamp)
            .then(response => {
              console.log(response)
            //window.opener = self
            window.close()
          }
            ).catch(err =>{
            console.log(err);
          })
        }
        else{
          const format1 = "YYYY-MM-DD HH:mm:ss"
          const date1 = new Date()
          const now = moment(date1).format(format1);
          const date2 = new Date()
          date2.setHours( date2.getHours() + 1 );
          const validTill = moment(date2).format(format1);
          const date3 = new Date()
          date3.setMinutes(date3.getMinutes() + 50)
          const accessTokenTimestamp = moment(date3).format(format1);
          updateUserDocumentById( userId,  now, access.data.access_token, access.data.refresh_token,  validTill, accessTokenTimestamp)
          .then(response => {
            console.log(response)
            //window.opener = self
            window.close()
          }
          ).catch(err =>{
          console.log(err);
        })
        }
        })
        })
    }, [])

return(
    <div style={{marginTop:'2%',textAlign: 'center'}}>
        <Spinner animation="border" role="status">
           <span className="sr-only">Loading...</span>
        </Spinner>
    </div>
    )
}

const mapStateToProps = (state) => ({
    refreshToken:getStoreRefreshToken(state),
    tokenData:getDigilockerList(state),
    accessTokenData:getStoreAccessToken(state),
    digilockerFileData:getDigilockerFileList(state),
    userId: getStoreUserId(state),
  })
  const mapDispatchToProps = () => (dispatch) => ({
    dispatchSetDigilockerData: (digilockerData) => dispatch(setDigilockerData(digilockerData)),
    dispatchSetDigilockerFiles: (digilockerFiles) => dispatch(setDigilockerFiles(digilockerFiles)),
  })

export default connect(mapStateToProps, mapDispatchToProps)(DigilockerAuth)