export const TOGGLE = 'TOGGLE';
export const toggleAction = (action) => {

    return {
        type: TOGGLE,
        action
    }
}
// export const CLOSE = 'CLOSE';
// export const closeAction = () => {
//     return {
//         type: CLOSE
//     }
// }
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const openSideBarAction = (action) => {
    return {
        type: OPEN_SIDEBAR,
        action
    }
}
export const isSideBarOpen = state => state.sideBar.isOpen