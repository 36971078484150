import React, { useImperativeHandle, forwardRef } from 'react';
import { useInput } from '../../hooks/useInput';
import HeadBar from '../../components/HeaderBar';
import DropDown from '../../components/DropDown';
import Input from '../../components/Input';
import string from '../../constant/en.json';
import { openSideBarAction } from '../../actions/sideBarActions';
import { connect } from 'react-redux';
import HelperModal from '../helper/HelperModal';
import DigilockerBtn from '../../components/DigilockerBtn'

const IdentificationForm = ({ defaultValue = { idProofNumber: '', idProofType: '' }, onOpen }, ref) => {
  const { value: idProofType, bind: bindIdType } = useInput(defaultValue.idProofType);
  const { value: idProofNumber, bind: bindIdNum } = useInput(defaultValue.idProofNumber);

  useImperativeHandle(ref, () => ({
    returnDetails() { return { idProofNumber, idProofType } }
  }));
  return <section className='section_form'>
    <div className="row" style={{marginLeft:'2px'}}>
      <HeadBar title={string.forms.commonFormFields.identification} />
      <HelperModal helperTextId={'identification'} />
    </div>
    <div className='row'>
      <DropDown placeholder={string.forms.commonFormFields.pleaseSelect} options={[{ code: 'Aadhar Card', value: 'Aadhar Card' }, { code: 'Pan Card', value: 'Pan Card' }, { code: 'Passport', value: 'Passport' }]} bind={bindIdType} />
      <Input placeholder={string.forms.commonFormFields.documentId} isBigger bind={bindIdNum} />
      <DigilockerBtn/>
    </div>
  </section>
};
const mapDispatchToProps = () => dispatch => ({
  // toggleSideBar: isOpen => dispatch(toggleAction(isOpen)),
  dispatchSlideBar:obj=>dispatch(openSideBarAction(obj))
})
export default connect(null, mapDispatchToProps, null, { forwardRef: true })(forwardRef(IdentificationForm));