import initialState from "./initialState";
import { ADD_BENEFICIARY_LIST, DELETE_BENEFICIARY, UPDATE_CONTACT_BENEFICIARY, CREATE_BENEFICIARY } from "../actions/beneficiaryActions";
import _ from 'lodash';
import { contactToUiLoad } from "../objectMapping/contactMapping";
export default (state = initialState.beneficiary, action) => {
    switch (action.type) {
        case ADD_BENEFICIARY_LIST:
            return action.beneficiary.map(b => ({ ...b, contacts: contactToUiLoad(b.contacts) }));
        case CREATE_BENEFICIARY:
            return state.concat(action.beneficiary.map(b => ({ ...b, contacts: contactToUiLoad(b.contacts) })))
        case DELETE_BENEFICIARY:
            return _.uniqBy(state.filter(con => con.id !== action.beneficiaryId), 'id');
        case UPDATE_CONTACT_BENEFICIARY:
            return state.map(b => {
                if (b.contacts.id === action.contact.id) {
                    return { ...b, contacts: contactToUiLoad(action.contact) };
                }
                return b
            })

        default:
            return state;
    }
}
