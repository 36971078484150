import Auth from '@aws-amplify/auth';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { setUserData } from '../actions/userActions';
import { getStoreUserId } from '../selectors/userSelectors';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const ForgotPasswordRedirect = ({dispatchSetUserDetails}) => {
  const query = useQuery();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [confirmPassword, setCPass] = useState(null);

  const onSubmit = (event) => {
    event.preventDefault();
    Auth.forgotPasswordSubmit(query.get('email'), query.get('code'), password)
      .then(() => {
        alert('Your Password has been successfully changed');
                history.push('/');
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  if (!query.get('code') && !query.get('email')) return <Redirect to={'/'} />;
  return (
    <div className="container" style={{ marginTop: 50 }}>
      <h4 style={{ marginBottom: 20 }}>Welcome to the Reset Password page. Please enter a new password.</h4><br></br>
      <h6>Note : Remember to have atleast 1 Capital letter, 1 Number and 1 special character to make a strong password.</h6>
      <div className="form-group">
        <input type="password" onChange={(e) => setPassword(e.target.value)} className="form-control" name="otp" placeholder="Enter New Password" />
      </div>
      <div className="form-group">
        <input type="password" onChange={(e) => setCPass(e.target.value)} className="form-control" name="otp" placeholder="Confirm New Password" />
      </div>
      <div className="form-group">
        <button onClick={(event)=>onSubmit(event)} disabled={password !== confirmPassword} type="submit" className="btn btn-primary btn-lg btn-block login-btn">
          Reset
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
});
const mapDispatchToProps = () => (dispatch) => ({
  dispatchSetUserDetails: (user) => dispatch(setUserData(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordRedirect);
