import onClickOutside from "react-onclickoutside";
import React from 'react'
import './Header.css';

class ServicesDropdown extends React.Component {
    
    handleClickOutside = () => {   
            var dropdowns = document.getElementsByClassName("dropdown-content1");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
              var openDropdown = dropdowns[i];
              if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
              }
            }
          
  }
  
     showDropdown(d) {
        this.props.servicepage()
        document.getElementById(d).classList.toggle("show");
      }
 
  render() {
    return (
        this.props.content==="service" ?
        <div class="dropdown">
        <span onClick={() => this.showDropdown('myDropdown3')} class="dropbtn" style={{color:'white'}}>Services <img alt='' src="../img/banner/white-inverted-triangle.png" width='8' height='8'/> </span>
        <div id="myDropdown3" class="dropdown-content1">
          <ul>
          <li><a style={{textDecoration: 'none'}} href="./service#testator">For a Testator</a></li>
          <li><a style={{textDecoration: 'none'}} href="./service#beneficiary">For a Beneficiary</a></li>
          </ul>
        </div>
      </div>
      :
      this.props.content==="services" ?
      <div class="dropdown">
      <span onClick={() => this.showDropdown('myDropdown4')} class="dropbtn" style={{color:'white'}}>Services <img alt='' src="../img/banner/white-inverted-triangle.png" width='8' height='8'/> </span>
      <div id="myDropdown4" class="dropdown-content1">
        <ul>
        <li><a style={{textDecoration: 'none'}} href="./services#testator">For a Testator</a></li>
        <li><a style={{textDecoration: 'none'}} href="./services#beneficiary">For a Beneficiary</a></li>
        </ul>
      </div>
    </div>
      : null
    );
  }
}

export default onClickOutside(ServicesDropdown)