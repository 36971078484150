export const ADD_DISINHERIT_LIST = 'ADD_DISINHERIT_LIST';
export const setDisinheritData = (disInherit) => {

    return {
        type: ADD_DISINHERIT_LIST,
        disInherit
    }
}
export const REMOVE_FROM_DISINHERIT_LIST = 'REMOVE_FROM_DISINHERIT_LIST';
export const removeFromDisinheritList = (disInheritId) => {

    return {
        type: REMOVE_FROM_DISINHERIT_LIST,
        disInheritId
    }
}
export const getStoreDisinheritList = state => {
    const contacts = state.contacts.filter(contact => contact.disinherit);
    const family = state.family.filter(contact => contact.disinherit);
    return contacts.concat(family)
};