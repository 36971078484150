import React from 'react';

const DropDown = ({ isSmaller, placeholder, options, bind, disabled, noClass }) => {
  return <div className={noClass? null : isSmaller ? 'col-xl-3 col-md-6 col-lg-3' : 'col-xl-4 col-md-6 col-lg-4'}>
    <div className='form-group '>
      <select disabled={disabled} {...bind} className='form-control form-control-gp' style={{ color: '#495057' }}>
        <option value='' disabled>
          {placeholder}
        </option>
        {options.map((o) => (
          <option key={o.code} value={o.code}>{o.value}</option>
        ))}
      </select>
    </div> 
  </div>
};
export default DropDown;
