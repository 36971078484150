export const ADD_SPEEDOMETER_CONSTANTS = 'ADD_SPEEDOMETER_CONSTANTS';
export const setSpeedometerData = (speedometerData) => {

    return {
        type: ADD_SPEEDOMETER_CONSTANTS,
        speedometerData
    }
}

export const getSpeedometerList = state => state.speedometerData;
