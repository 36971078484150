import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './dashboard/components/Router';



var be = null;
console.warn = () => null;
class App extends Component {
  componentDidMount() {
    window.__be = window.__be || {};
    window.__be.id = 'hs-script-loader';
    (function () {
      var be = document.createElement('script');
      be.type = 'text/javascript';
      be.async = true;
      be.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'js.hs-scripts.com/8729061.js';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(be, s);
    })();
  }
  render() {
    return (
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    );
  }
}

export default App;
