import React, {useEffect} from 'react';
import {getHelpList, sendMail} from '../../../src/dashboard/helper/MailingServices'
import { getStoreUser } from '../../selectors/userSelectors';
import { connect } from 'react-redux';
import {getHelpListData, setHelpListData} from '../../actions/helpListAction'

const HelpList= ({userDetails, dispatchSetHelpListData,helpListData}) =>{
    const mailQuestions = () =>{
        let payload={
            userId:userDetails.id,
            email:userDetails.primaryEmail
        }
        sendMail(payload)
		.then((resp) => {
            setTimeout(() => {
                alert(
                  'Please check for an email from EasyInherit.'
                )
              }, 100)
        })
        .catch((error) => {
          console.log(error)
          alert(error)
          })	
  }
const Question = () =>{

    const questionList = helpListData.map(question=>{
        for (var key in question) {
            return(   
                <table><td style={{width:'40vw'}}>{key}</td> <td style={{width:'60vw'}}>{question[key]}</td></table>
            );
            }
        })
            return(
                <div className="top_padding">
                    {questionList}
                </div>
            )
    }
    useEffect(() => {
        getHelpList(userDetails.id).then(res => {
            dispatchSetHelpListData(res.data)
        }).catch(e => {
            console.log(e)
        })
    })

    return(
        <div className="help_list_text" style={{padding:'5px'}}>
        {helpListData.length === 0 ? <p style={{textAlign:'center',paddingTop:'5%'}}> You have not added any questions to your list yet</p> : <div>
        <table><td  style={{width:'40vw',fontWeight:'700'}}>TOPICS</td><td  style={{width:'60vw',fontWeight:'700'}}>YOUR QUESTIONS</td></table>
        <hr/>
        <Question/>
        <br/>
        <button onClick={mailQuestions} className='btn btn-primary'>Mail me a copy</button>
        </div>
        }
    </div>
    );
}

  const mapDispatchToProps = () => (dispatch) => ({
    dispatchSetHelpListData: (helpList) =>
      dispatch(setHelpListData(helpList)),
  })
  
  const mapStateToProps = (state) => ({
    userDetails: getStoreUser(state),
    helpListData: getHelpListData(state)
  })

export default connect(mapStateToProps, mapDispatchToProps)(HelpList);
