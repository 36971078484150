export const ADD_OTHER_ASSIGN_LIST = 'ADD_OTHER_ASSIGN_LIST';
export const CREATE_OTHER_ASSIGN = 'CREATE_OTHER_ASSIGN';
export const EDIT_OTHER_ASSIGN = 'EDIT_OTHER_ASSIGN';
export const DELETE_OTHER_ASSIGN = 'DELETE_OTHER_ASSIGN';
export const setOtherAssignData = (otherAssigns) => {

    return {
        type: ADD_OTHER_ASSIGN_LIST,
        otherAssigns
    }
}
export const deleteOtherAssignFromList = (otherAssignId) => {

    return {
        type: DELETE_OTHER_ASSIGN,
        otherAssignId
    }
}

export const addOtherAssign = otherAssign => ({ type: CREATE_OTHER_ASSIGN, otherAssign });
export const editOtherAssign = otherAssign => ({ type: EDIT_OTHER_ASSIGN, otherAssign });
export const getStoreOtherAssignList = state => state.otherAssigns;
