import React, { useState, useImperativeHandle, forwardRef } from 'react';

export const CheckBoxFrm = ({ title, onChange, disabled, defaultValue, noClass, fsize }, ref) => {
  const [value, setValue] = useState(defaultValue);
  const check = (e) => setValue(v => !v, typeof e === 'function' ? e(!value) : null)
  useImperativeHandle(ref, () => ({
    returnDetails() { return value }
  }));
  return <div className= {noClass ? null: 'row col-xl-3 col-md-6 col-lg-3' }>
    <div className='form-check'>
      <label className='form-check-label' style={{ whiteSpace: 'pre', fontSize:fsize ? '12px' : '14px' }}>
        <input type='checkbox' checked={value} disabled={disabled} className='form-check-input' onChange={onChange ? () => {
          check(onChange)
        } : check} />
        {title}
      </label>
    </div>
  </div>
};
const CheckBox = forwardRef(CheckBoxFrm);
export default CheckBox;