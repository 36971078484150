import React, { useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import IdleTimer from 'react-idle-timer'
import { connect, useDispatch } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { setUserData } from '../../actions/userActions'
import ForgotPasswordRedirect from '../../auth/ForgotPasswordRedirect'
import { SignOut } from '../../auth/SignOut'
import GetStarted from '../../components/get_started/'
import AboutPage from '../../home/AboutPage'
import ContactPage from '../../home/ContactPage'
import FaqPage from '../../home/FaqPage'
import Home from '../../home/Home'
import SecurityPage from '../../home/SecurityPage'
import ServicePage from '../../home/ServicePage'
import FreqQuestions from '../../home/QuestionsPage'
import PrivacyTermsandCond from '../../home/PrivacyPage'
import RefundTermsandCond from '../../home/RefundPage'
import TermsAndCdt from '../../home/TermsAndCdt'
import { getStoreUserId, getStoreUser } from '../../selectors/userSelectors'
import AboutUs from '../components/AboutUs'
import Contact from '../components/Contact'
import FAQ from '../components/FAQ'
import Security from '../components/Security'
import Services from '../components/Services'
//import Dashboard from '../Dashboard'
import Assets from '../forms/assets/Assets'
import Assign from '../forms/assign/Assign'
import Beneficiary from '../forms/benificiary/Beneficiary'
import Contacts from '../forms/contacts/Contacts'
import Disinheritence from '../forms/disinherit/Disinherit'
import Executor from '../forms/executor/Executor'
import Family from '../forms/family/Family'
import Liabilities from '../forms/liabilities/Liabilities'
import Personal from '../forms/personal/Personal'
import Summary from '../forms/summary/Summary'
import DashboardContainer from './DashboardContainer'
import HelpList from '../helper/HelpList'
import Profile from '../../dashboard/components/Profile'
import MyOrders from '../../dashboard/components/MyOrders'
import { getUserRolesList } from '../../actions/userRolesAction'
import DigilockerAuth from '../../dashboard/forms/digilocker/DigilockerAuth'
import Insurance from '../forms/assets/forms/Insurance'
import ImmovableProperty from '../forms/assets/forms/ImmovableProperty';
import Pets from '../forms/pets/Pets'
import TestatorSummary from '../forms/summary/TestatorSummary'
//import TextSelection from '../forms/textSelection/TextSelection'
import Will from '../forms/will/Will'
import Claim from '../forms/claim/Claim'
import CreateWill from '../forms/will/CreateWill'
import DrawerMenu from  '../DrawerMenu'
import DataShareBy from '../forms/dataShare/DataShareBy'
import DataShareWith from '../forms/dataShare/DataShareWith'
import GoogleLoginRedirect from '../../auth/GoogleLoginRedirect'
import Login from '../../auth/Login'

const Router = ({ dispatchSetUserDetails, userId, userRoleListData, personalDetails }) => {
  //const history = useHistory()
  //const [isLoading, setLoading] = useState(true)
  return (
    <Switch>
      <Route exact path='/' component={Login} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/forgotpassword' component={ForgotPasswordRedirect} />
      <Route exact path='/aboutus' component={AboutPage} />
      <Route exact path='/service' component={ServicePage} />
      <Route exact path='/secure' component={SecurityPage} />
      <Route exact path='/q&a' component={FaqPage} />
      <Route exact path='/contactus' component={ContactPage} />
      <Route exact path='/privacy' component={PrivacyTermsandCond} />
      <Route exact path='/questions' component={FreqQuestions} />
      <Route exact path='/refund' component={RefundTermsandCond} />
      <Route exact path='/t&c' component={TermsAndCdt} />
      <Route exact path='/get-started' component={GetStarted} />
      <Route exact path='/dashboardsignup' component={DashboardContainer} />   
      {/*<Route exact path='/textSelection' component={TextSelection} />*/}
      <Route exact path='/createwill' component={CreateWill} />
      <Route exact path='/googleLoginRedirect' component={GoogleLoginRedirect} />

      {userId && userRoleListData.roleLookupCode === 'GIVER' ? (
        <React.Fragment>
          <DrawerMenu role={userRoleListData.roleLookupCode} userDetails={personalDetails}>
            <Route exact path='/' component={Login} />
            <Route exact path='/about' component={AboutUs} />
            <Route exact path='/services' component={Services} />
            <Route exact path='/security' component={Security} />
            <Route exact path='/faq' component={FAQ} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/dashboard' component={DashboardContainer} />
            <Route exact path='/personal' component={Personal} />
            <Route exact path='/contacts' component={Contacts} />
            <Route exact path='/family' component={Family} />
            <Route exact path='/executor' component={Executor} />
            <Route exact path='/beneficiary' component={Beneficiary} />
            <Route path='/assets/:id?' component={Assets} />
            <Route exact path='/assign' component={Assign} />
            <Route exact path='/liabilities' component={Liabilities} />
            <Route exact path='/disinheritence' component={Disinheritence} />
            <Route exact path='/bequethmentSummary' component={Summary} />
            <Route exact path='/summary' component={TestatorSummary} />
            <Route exact path='/helplist' component={HelpList} />
            <Route exact path='/profile' component={Profile} />
            <Route exact path='/myorders' component={MyOrders} />
            <Route exact path='/easyinherit' component={DigilockerAuth} />
            <Route exact path='/insurance' component={Insurance} />
            <Route exact path='/immovableAsset' component={ImmovableProperty} />
            <Route exact path='/pets' component={Pets} />
            <Route exact path='/will' component={Will} />
            <Route exact path='/claim' component={Claim} />
            <Route exact path='/datashareby' component={DataShareBy} />
            <Route exact path='/datasharewith' component={DataShareWith} />
          </DrawerMenu>
          <MyVerticallyCenteredModal />
        </React.Fragment>
      ) : userId && userRoleListData.roleLookupCode=== 'RECEIVER' ? (
        <React.Fragment>
          <DrawerMenu role={userRoleListData.roleLookupCode} userDetails={personalDetails}>
          <Route exact path='/' component={Login} />
          <Route exact path='/about' component={AboutUs} />
          <Route exact path='/services' component={Services} />
          <Route exact path='/security' component={Security} />
          <Route exact path='/faq' component={FAQ} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/dashboard' component={DashboardContainer} />
          <Route exact path='/personal' component={Personal} />
          <Route path='/assets/:id?' component={Assets} />
          <Route exact path='/assign' component={Assign} />
          <Route exact path='/liabilities' component={Liabilities} />
          <Route exact path='/summary' component={Summary} />
          <Route exact path='/helplist' component={HelpList} />
          <Route exact path='/profile' component={Profile} />
          <Route exact path='/easyinherit' component={DigilockerAuth} />
          <Route exact path='/insurance' component={Insurance} />
          <Route exact path='/immovableAsset' component={ImmovableProperty} />
          <Route exact path='/myorders' component={MyOrders} />
        </DrawerMenu>
        <MyVerticallyCenteredModal />
      </React.Fragment>
        )
        :(
        <Redirect exact to='/' />
      )}
    </Switch>
  )
}
const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
  userRoleListData: getUserRolesList(state),
  personalDetails: getStoreUser(state),
})
const mapDispatchToProps = () => (dispatch) => ({
  dispatchSetUserDetails: (user) => dispatch(setUserData(user)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Router)

function MyVerticallyCenteredModal(props) {
  const ref = useRef()
  const [modalShow, setModalShow] = React.useState(false)
  const dispatch = useDispatch()
  const [seconds, setSeconds] = useState(0)
  React.useEffect(() => {
    let interval = null
    if (modalShow) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1)
      }, 1000)
    } else if (!modalShow && seconds !== 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [modalShow, seconds])
  const onIdle = () => {
    setModalShow(true)
  }
  const onActive = () => {
    // close()
  }
  const onAction = () => {
    // close()
  }
  const close = () => {
    if (modalShow) setModalShow(false)
  }
  const onLogOut = () => {
    SignOut(null, dispatch)
    setModalShow(false)
  }
  const onHide = () => {
    close()
  }
  if (seconds === 31) onLogOut()
  return (
    <React.Fragment>
      <IdleTimer
        ref={ref}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={1000 * 60 * 15}
      />
      <Modal
        {...props}
        show={modalShow}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title id='contained-modal-title-vcenter'>
            Inactive.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row' style={{ paddingLeft: 20, paddingRight: 20 }}>
            <p>
              You have been inactive for a long time. Please take any below
              mentioned action.
            </p>
            <div style={{ width: 30 }} />
            <h3>{30 - seconds}s</h3>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onLogOut}>Log me out</Button>
          <Button onClick={onHide}>Keep me logged in.</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}
