import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Typography,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  Collapse,
  withStyles,
  Tooltip,
  Zoom,
  ListItemAvatar,
  Avatar
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { NavLink } from 'react-router-dom';
import Digilocker from '../dashboard/forms/digilocker/Digilocker'

const drawerWidth = 280;

class DrawerItems extends React.Component {
  state = {
    open: false,
    index: null,
    userExpand: false,
    legalReview : false,
  };
  handleClick = e => {
    this.setState(state => {
      return { [e]: !state[e] };
    });
  };
 
  getFullName = (e) => {
        let name = e.firstName + " " + e.middleName + " " + e.lastName;
        name = name.replace(/null/g, '')
        return name.replace(/ +/g, " ")
    }
  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  indexFind = index => {
    this.setState({ index });
    localStorage.setItem('index', index);
  };
  navigateToWill = () => {
  window.location.href='/will'
}
  render() {
    const { classes, theme } = this.props;
    return (
         <div>
          <div className={classes.toolbar} style={{ background: this.props.open ? '#1b2e4a' : '#1b2e4a' }}>
            <ListItem style={{ width: '78%', alignItems: 'flex-start', overflow: 'hidden', padding: 0 }}>
              <ListItemAvatar>
                {/* <Avatar alt="Remy Sharp" src={''} />*/}
                <Avatar className={classes.purpleAvatar}>{_.isEmpty(this.props.userDetails.firstName) ? 'A' : this.props.userDetails.firstName[0]}</Avatar>
              </ListItemAvatar>
              <ListItemText
                style={{ color: '#fff' }}
                primary={
                  <Typography type="body2" style={{ color: '#FFFFFF' }}>
                    {this.props.userDetails.firstName}
                  </Typography>
                }
              />
            </ListItem>
            <IconButton style={{ color: '#fff' }} onClick={this.props.handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>

          {/* My Dashboard */}
          <Tooltip
            TransitionComponent={Zoom}
            title="My Dashboard"
            placement="right"
            disableHoverListener={this.props.open}
            disableFocusListener={true}
            enterDelay={400}
          >
            <ListItem component={NavLink} to={'/dashboard'} onClick={this.props.handrawerClose} activeStyle={{ background: '#f2f2f2' }} button>
              <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/dashboard.svg`)}
                        alt=''
                      />
              </ListItemIcon>
              <ListItemText className={classes.itemText} inset primary="My Dashboard" />
            </ListItem>
          </Tooltip>

            {/*Personal Details*/}
          { this.props.role === 'RECEIVER' ? 
            (
          <Tooltip
            TransitionComponent={Zoom}
            title="My Info"
            placement="right"
            disableHoverListener={this.props.open}
            disableFocusListener={true}
            enterDelay={400}
          >
            <ListItem component={NavLink} to={'/personal'} onClick={this.props.handrawerClose} activeStyle={{ background: '#f2f2f2' }} button>
              <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/name.svg`)}
                        alt=''
                      />
              </ListItemIcon>
              <ListItemText className={classes.itemText} inset primary="My Info" />
            </ListItem>
          </Tooltip>
            )  : null }

            { this.props.role === 'RECEIVER' ? null :
            (
            <div>
              <Tooltip
                TransitionComponent={Zoom}
                title="User's Menu"
                placement="right"
                disableHoverListener={this.props.open}
                disableFocusListener={true}
                enterDelay={400}
              >
                <ListItem button onClick={() => this.handleClick('userExpand')}>
                  <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/contact_parent.svg`)}
                        alt=''
                      />
                    {this.props.open ? null : this.state.userExpand ? (
                      <ExpandLess style={{ width: '16px' }} />
                    ) : (
                        <ExpandMore style={{ width: '16px' }} />
                      )}
                  </ListItemIcon>
                  <ListItemText className={classes.itemText} inset primary="Users" />
                  {this.state.userExpand ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              </Tooltip>
              <Collapse in={this.state.userExpand} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <Tooltip
                    TransitionComponent={Zoom}
                    title="My Info"
                    placement="right"
                    disableHoverListener={this.props.open}
                    disableFocusListener={true}
                    enterDelay={400}
                  >
                  <ListItem
                    component={NavLink}
                    to={'/personal'}
                    onClick={this.props.handrawerClose}
                    activeStyle={{ background: '#f2f2f2' }}
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/name.svg`)}
                        alt=''
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.itemText} inset primary={"My Info"} />
                  </ListItem>
                  </Tooltip>
                  
                  <Tooltip
                    TransitionComponent={Zoom}
                    title="My Family"
                    placement="right"
                    disableHoverListener={this.props.open}
                    disableFocusListener={true}
                    enterDelay={400}
                  >
                  <ListItem
                    button
                    component={NavLink}
                    to={'/family'}
                    onClick={this.props.handrawerClose}
                    activeStyle={{ background: '#f2f2f2' }}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/family.svg`)}
                        alt=''
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.itemText} inset primary="My Family" />
                  </ListItem>
                  </Tooltip>

                  <Tooltip
                    TransitionComponent={Zoom}
                    title="My Contacts"
                    placement="right"
                    disableHoverListener={this.props.open}
                    disableFocusListener={true}
                    enterDelay={400}
                  >
                  <ListItem
                    component={NavLink}
                    to={'/contacts'}
                    onClick={this.props.handrawerClose}
                    activeStyle={{ background: '#f2f2f2' }}
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon>.
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/other_contact.svg`)}
                        alt=''
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.itemText} inset primary="My Contacts" />
                  </ListItem>
                  </Tooltip>

                  <Tooltip
                    TransitionComponent={Zoom}
                    title="Pets and Companions"
                    placement="right"
                    disableHoverListener={this.props.open}
                    disableFocusListener={true}
                    enterDelay={400}
                  >
                  <ListItem
                    button
                    component={NavLink}
                    to={'/pets'}
                    onClick={this.props.handrawerClose}
                    activeStyle={{ background: '#f2f2f2' }}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/joshua-tree.svg`)}
                        alt=''
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.itemText} inset primary="Pets and Companions" />
                  </ListItem>
                  </Tooltip>
                </List>
              </Collapse>
            </div>
            )
            }

            {/*Assets*/ }
              <Tooltip
                TransitionComponent={Zoom}
                title="EasyInherit Portfolio"
                placement="right"
                disableHoverListener={this.props.open}
                disableFocusListener={true}
                enterDelay={400}
              >
                <ListItem button onClick={() => this.handleClick('assetsExpand')}>
                  <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/Logo251.svg`)}
                        alt=''
                      />
                    {this.props.open ? null : this.state.assetsExpand ? (
                      <ExpandLess style={{ width: '16px' }} />
                    ) : (
                        <ExpandMore style={{ width: '16px' }} />
                      )}
                  </ListItemIcon>
                  <ListItemText className={classes.itemText} inset primary="EasyInherit Portfolio" />
                  {this.state.assetsExpand ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              </Tooltip>
              <Collapse in={this.state.assetsExpand} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                <Tooltip
                TransitionComponent={Zoom}
                title="Insurance"
                placement="right"
                disableHoverListener={this.props.open}
                disableFocusListener={true}
                enterDelay={400}
              >
                  <ListItem
                    component={NavLink}
                    to={'/insurance'}
                    onClick={this.props.handrawerClose}
                    activeStyle={{ background: '#f2f2f2' }}
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/hospital.svg`)}
                        alt=''
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.itemText} inset primary={"Insurance"} />
                  </ListItem>
                  </Tooltip>

                  <Tooltip
                  TransitionComponent={Zoom}
                  title="Home and Property"
                  placement="right"
                  disableHoverListener={this.props.open}
                  disableFocusListener={true}
                  enterDelay={400}
                  >
                  <ListItem
                    button
                    component={NavLink}
                    to={'/immovableAsset'}
                    onClick={this.props.handrawerClose}
                    activeStyle={{ background: '#f2f2f2' }}
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/house.svg`)}
                        alt=''
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.itemText} inset primary="Home and Property" />
                    </ListItem>
                    </Tooltip>

                    <Tooltip
                    TransitionComponent={Zoom}
                    title="Financial"
                    placement="right"
                    disableHoverListener={this.props.open}
                    disableFocusListener={true}
                    enterDelay={400}
                    >
                  <ListItem
                    component={NavLink}
                    to={'/assets/financial'}
                    onClick={this.props.handrawerClose}
                    activeStyle={{ background: '#f2f2f2' }}
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/budget.svg`)}
                        alt=''
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.itemText} inset primary="Financial" />
                  </ListItem>
                    </Tooltip>

                    <Tooltip
                    TransitionComponent={Zoom}
                    title="Other Assets"
                    placement="right"
                    disableHoverListener={this.props.open}
                    disableFocusListener={true}
                    enterDelay={400}
                    >
                  <ListItem
                    component={NavLink}
                    to={'/assets/digital&other'}
                    onClick={this.props.handrawerClose}
                    activeStyle={{ background: '#f2f2f2' }}
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/computer.svg`)}
                        alt=''
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.itemText} inset primary="Other Assets" />
                  </ListItem>
                  </Tooltip>

                  <Tooltip
                    TransitionComponent={Zoom}
                    title="Liabilities"
                    placement="right"
                    disableHoverListener={this.props.open}
                    disableFocusListener={true}
                    enterDelay={400}
                    >
                  <ListItem
                    component={NavLink}
                    to={'/liabilities'}
                    onClick={this.props.handrawerClose}
                    activeStyle={{ background: '#f2f2f2' }}
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon>
                    <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/liability.svg`)}
                        alt=''
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.itemText} inset primary="Liabilities " />
                  </ListItem>
                  </Tooltip>

                  <Tooltip
                    TransitionComponent={Zoom}
                    title="Portfolio Report"
                    placement="right"
                    disableHoverListener={this.props.open}
                    disableFocusListener={true}
                    enterDelay={400}
                    >
                  <ListItem
                    component={NavLink}
                    to={'/summary'}
                    onClick={this.props.handrawerClose}
                    activeStyle={{ background: '#f2f2f2' }}
                    button
                    className={classes.nested}
                  >
                    <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/portfolio_report.svg`)}
                        alt=''
                      />
                    </ListItemIcon>
                    <ListItemText className={classes.itemText} inset primary="Portfolio Report" />
                  </ListItem>
                  </Tooltip>

                </List>
              </Collapse>

             {/* Make My Will*/}
            { this.props.role === 'GIVER' ? 
            <Tooltip
              TransitionComponent={Zoom}
              title="Make My Will"
              placement="right"
              disableHoverListener={this.props.open}
              disableFocusListener={true}
              enterDelay={400}
            >
              <ListItem onClick={this.navigateToWill} activeStyle={{ background: '#f2f2f2' }} button>
                <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/will.svg`)}
                        alt=''
                      />
                </ListItemIcon>
                <ListItemText className={classes.itemText} inset primary=" Make My Will" />
              </ListItem>
            </Tooltip>
            : null
            }
          


             {/* Free Legal Consult */}
            { this.props.role === 'GIVER' ? 
            <Tooltip
              TransitionComponent={Zoom}
              title="Free Legal Consult"
              placement="right"
              disableHoverListener={this.props.open}
              disableFocusListener={true}
              enterDelay={400}
            >
              <ListItem onClick={this.props.toggleModal} activeStyle={{ background: '#f2f2f2' }} button>
                <ListItemIcon>
                    <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/advocate.svg`)}
                        alt=''
                      />
                </ListItemIcon>
                <ListItemText className={classes.itemText} inset primary=" Free Legal Consult" />
              </ListItem>
            </Tooltip>
            : null
            }
            
            {/*Probate Services*/}
            {this.props.role === 'RECEIVER' ? 
              <Tooltip
                TransitionComponent={Zoom}
                title="Probate Services"
                placement="right"
                disableHoverListener={this.props.open}
                disableFocusListener={true}
                enterDelay={400}
              >
                <ListItem  component={NavLink} to={'/faq#price'}  onClick={this.props.handrawerClose} activeStyle={{ background: '#f2f2f2' }} button>
                  <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/probate.svg`)}
                        alt=''
                      />
                  </ListItemIcon>
                  <ListItemText className={classes.itemText} inset primary="Probate Services" />
                </ListItem>
              </Tooltip>
            :
            null
            }

            {/*Transfer Services*/}
            {this.props.role === 'RECEIVER' ? 
              <Tooltip
                TransitionComponent={Zoom}
                title="Transfer Services"
                placement="right"
                disableHoverListener={this.props.open}
                disableFocusListener={true}
                enterDelay={400}
              >
                <ListItem  component={NavLink} to={'/faq#price'} onClick={this.props.handrawerClose} activeStyle={{ background: '#f2f2f2' }} button>
                  <ListItemIcon>
                     <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/transfer.svg`)}
                        alt=''
                      />
                  </ListItemIcon>
                  <ListItemText className={classes.itemText} inset primary="Transfer Services" />
                </ListItem>
              </Tooltip>
            :
            null
            }

            <div onClick={this.props.handrawerClose}>
            <Digilocker uploadType = 'menubar' drawerState={this.props.open}/>
            </div>
             {/* My Help List */}
             <Tooltip
              TransitionComponent={Zoom}
              title="My Help List"
              placement="right"
              disableHoverListener={this.props.open}
              disableFocusListener={true}
              enterDelay={400}
            >
              <ListItem component={NavLink} to={'/helplist'} onClick={this.props.handleDrawerClose} activeStyle={{ background: '#f2f2f2' }} button>
                <ListItemIcon>
                      <img
                        style={{ width: '2', height: '2em' }}
                        src={require(`../assets/svg/help.svg`)}
                        alt=''
                      />
                </ListItemIcon>
                <ListItemText className={classes.itemText} inset primary="My Help List" />
              </ListItem>
            </Tooltip>
      </div>
    );
  }
}

DrawerItems.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

const styles = theme => ({
  itemText: { paddingLeft: 0 },
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 - 2
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3
  },
  menuItem: {
    '&:focus': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  },
  menuIcon: {
    fontSize: 23
    // fontWeight: 400
  },
  subMenuIcon: {
    fontSize: 18
    // fontWeight: 350
  },
  purpleAvatar: {
    color: '#fff',
    backgroundColor: '#ff3939b0',
    marginTop: 8
  }
});

export default (withStyles(styles, { withTheme: true })(DrawerItems));

