import Storage from "@aws-amplify/storage";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { useQuaishToasts } from "../../../../actions/alertAction";
import {
  ContainerPosition,
  CustomButtom,
  AlignColumn, CenterAlignRow} from "../../../../components/CustomElements";
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import string from "../../../../constant/en.json";
import { AWS_CONFIG, S3URL } from "../../../../constant/ServerConst";
import { useInput } from "../../../../hooks/useInput";
import { getStoreUserId } from "../../../../selectors/userSelectors";
import { createFinAssetDoc } from "../finAssetService"
import { getFinAssetDoc } from "../finAssetService"


const s3Url = S3URL;
Storage.configure(AWS_CONFIG.Storage);
const status = 'uploaded';

const NSDL = ({ userId }) => {
  const { value: password, bind: bindPassword, reset: resetPassword } = useInput("");
  const [inProcess, setProcess] = useState(false)
  const { success, error } = useQuaishToasts()
  const [file, setFile] = useState(null);
  const [type, setType] = useState(null);
  const onCancel = () => {
    resetPassword();
    setFile(null);
    setType(null)
  };
  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
  };

{/* Fetch the list of uploaded documents to be used in the display at the bottom*/}
  const finAssetsDocFetch = () => {
    getFinAssetDoc(userId).then(res=>{
      console.log(res)
    }).catch(err=>{
      console.log(err)
    })
    
 }

  const submit = () => {
    finAssetsDocFetch();
    return;
    setProcess(true)
      if(file ==="" || type ===null || password.trim() === ""){
          alert("Please fill out all the fields.")
          setProcess(false)
          return
      }
    Storage.put(
      `${userId}/${type}/${type}` +`.pdf`  
        //file.name
        // +moment().format("_YYYYMMDDHHmmss")
        ,
      file
    )
      .then((resp) => {
        success("File Uploaded Successfully.")
        const payload = {
            userId,
            active : 'true',
            receiverFlag : 'false',
            docDescription : file.name,
            docLookupCode : type,
            docLink: s3Url+"/"+resp.key,
            docOtherDetails: password
        }
        
        //call the create fin_user_document API 
        createFinAssetDoc(payload)
        .then(resp=>{
          console.log(resp)
          console.log('Fin asset document success')
      })
      .catch(error=>{
          console.log(error)
      })
        setProcess(false)
      })
      .catch((err) => {
        setProcess(false)
        error(err.message);
      });
  };
  return (
   
   
   <div>
          <div>
            <HeadBar title={"Statement Type"} subtitle={"* CAMS/NSDL Only. Other statements may cause errors."} />
          </div>
          <div className="row" style={{ margin: 0 }}>
        <div className="row" style={{ marginLeft: 0 }}>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                checked={type === "NSDL"}
                name={"inlineRadiogender"}
                id={"inlineRadiogender"}
                value={"NSDL"}
                onChange={(event) => setType(event.target.value)}
              />
              NSDL Statement
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                checked={type === "CAMS"}
                name={"inlineRadiogender"}
                id={"inlineRadiogender"}
                value={"CAMS"}
                onChange={(event) => setType(event.target.value)}
              />
              CAMS Statement
            </label>
          </div>
        </div>
      </div>
      <div><br></br></div>
      <div>
        <HeadBar title={"Upload Statement"} subtitle={"* Choose statement file."} />
      </div>
      <div className="row">
        <div className={"col-xl-4 col-md-6 col-lg-4"}>
          <div className="form-group">
            <input
              className="form-control"
              type="file"
              onChange={handleFileInput}
            />
          </div>
        </div>
      </div>
      <div>
        <HeadBar title={"Password *"} subtitle={"Enter statement password."} />
      </div>
      <div className="row">
        <Input
          type="password"
          name="password"
          required="required"
          isBigger
          bind={bindPassword}
        />
      </div>
      <ContainerPosition>
        <CustomButtom
        disabled={inProcess}
          event={submit}
          title={string.forms.commonFormFields.saveBtn}
        />
        <CustomButtom
        disabled={inProcess}
          event={onCancel}
          title={string.forms.commonFormFields.cancelBtn}
        />
       </ContainerPosition>

       {/*Table to show uploaded documents*/}
         
         <div><HeadBar title={"Statements uploaded and processed"} subtitle={""} /><br></br></div>
          <CenterAlignRow className='section_form'>
              <AlignColumn label="Statement Type" text={'CAMS'} />
              <AlignColumn label="File Name" text={'950242171202...504.pdf'} />
              <AlignColumn label="Upload Date" text={'May 18, 2021, 12:50:06'} />
              {status === 'Processed' ? <AlignColumn label="Status" text={'Processed'}/> : <AlignColumn label="Status" text={'uploaded'}/>}
              {/*this button should show only if file is to be processed. OnClick, it will call my API to process and update status in table*/}
              <Button></Button>
          </CenterAlignRow>

       
    </div>
  );

};


const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
});
const mapDispatchToProps = () => (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(NSDL);
