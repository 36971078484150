import React from 'react';

const SectionHeading = ({headingMain, heading, heading2, subHeading, image}) => {
  return (
    <div className='row'>
      <div className='col-xl-12'>
        <div className='section_title mb-40' style={{ textAlign: 'center' }}>
          <h4>{headingMain}</h4>
          <h3>{heading}{heading2}</h3>
          <p>{subHeading}</p>
        </div>
      </div>
    </div>
  );
};
export default SectionHeading;