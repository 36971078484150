import initialState from './initialState'
import { ADD_SPEEDOMETER_CONSTANTS } from '../actions/speedometerAction'
export default (state = initialState.speedometerData, action) => {
  switch (action.type) {
    case ADD_SPEEDOMETER_CONSTANTS:
      return { ...state, ...action.speedometerData }
    default:
      return state
  }
}
