import React, { forwardRef, useState, useRef } from 'react';
import CollapseSection from "../../../../components/CollapsibleSection";
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import { useInput } from "../../../../hooks/useInput";
import DropDown from '../../../../components/DropDown';
import { CardRadioBtn } from '../../../components/HoldingTypeTag';
import string from '../../../../constant/en.json';
import { batchState, getId, getContactFullName } from '../../../../utils/commonUtil';
import { ContainerPosition, CustomButtom, TableContainer, CenterAlignRow, AlignColumn, ActionItemUpload } from '../../../../components/CustomElements';
import { updateFinAssetById, createFinAsset, deleteFinAsset} from '../finAssetService';
import PickContactTable from '../../../components/PickContactTable';
import { getStoreFinAssetList, addFinAsset, editFinAsset, deleteFinAssetFromList } from '../../../../actions/finAssetsActions';
import { getStoreUserId, getStoreUserRole } from '../../../../selectors/userSelectors';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getStoreContactList } from '../../../../actions/contactActions';
import { getStoreFamily } from '../../../../actions/familyActions';
import HelperModal from '../../../helper/HelperModal';
import SliderBar from '../../../../components/UploadComponent';
import { useQuaishToasts } from '../../../../actions/alertAction';
import ModalContainer from '../../../../home/component/layout/Modal'
import DataShareModal from '../../../../dashboard/forms/dataShare/DataShareModal'

const MutualFund = ({userId,pickList, mfFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, dispatchDeleteFinAsset, userRole}, ref) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [mf, setMF] = useState();
  let [visibleDataShare, setVisibleDataShare] = useState(false)
  let toggleDataShare = () => setVisibleDataShare(!visibleDataShare)
  const [dataShareId, setDataShareId] = useState('')

  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && mf &&  mf.id ) {
        setMF(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setMF(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
      setMF(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const onShare = (c) => {
    setDataShareId(c)
    toggleDataShare()
  }
       
  const docsRef = React.useRef()
  const onUpload = (c) => {
    docsRef.current.submit('MF', c.id, "Upload Files")
  }
  return (
    <div>
        <SavedMutualFunds onUpload={onUpload} data={mfFinAssetList} onEdit={onEdit} onShare={onShare} isFormHidden={isFormHidden} dispatchDeleteFinAsset={dispatchDeleteFinAsset} />
        <div  style={{ marginBottom: 20, display: 'block' }}>
          <ContainerPosition>
            <CustomButtom event={() => toggleForm()} title={'Add Mutual Funds'} />
          </ContainerPosition>
        </div>
        {isFormHidden ? null : <MFform mf={mf} userId={userId} onCancel={toggleForm} onDone={onDone} mfFinAssetList={mfFinAssetList} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole}/>}
        <SliderBar ref={docsRef} />
        {visibleDataShare
                  ? 
                  <ModalContainer visible={visibleDataShare} toggle={toggleDataShare}>
                    <DataShareModal visibleDataShare={visibleDataShare} toggleDataShare={toggleDataShare} dataShareId={dataShareId} AssetList={mfFinAssetList} assetLookupKey={"asset_fin"} assetLookupCode={'MF'}/>
                  </ModalContainer> 
                  : null 
        }
    </div>
  );
}

const MFfrm = ({mf,userId, pickList, onCancel, onDone, mfFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, userRole, displayNominee}, ref) => {
  const { value: assetIssuerName, bind: bindName } = useInput(!mf ? '' : mf.assetIssuerName);
  const { value: assetIdentificationNumber, bind: bindId } = useInput(!mf ? '' : mf.assetIdentificationNumber);
  const { value: assetQuantity, bind: bindUnit } = useInput(!mf ? '' : mf.assetQuantity);
  const { value: pickedNominee, bind: bindNominee } =useInput(userRole === 'RECEIVER' ? null : !mf ? 'INDV' : mf['contacts']['contactLookupCode']);
  const selectedId =  userRole === 'RECEIVER' ? null : !mf ? '' : mf['contacts']['id']
  const [provideDeets, setProvideDeets] = useState('self')
  const refPickNominee = useRef();
  const { success, error, removeAll } = useQuaishToasts()
 /*const validate=(payload)=>{
    const ev=[]
    for (var key in payload) {
      switch(key){
          case 'assetIssuerName':
            _.isEmpty(payload[key]) ? ev.push(string.forms.mutualfunds.emptyFundName):null
            break
          case 'assetIdentificationNumber':
            _.isEmpty(payload[key]) ? ev.push(string.forms.mutualfunds.emptyFundId):null
            break
          case 'assetQuantity':
            _.isEmpty(payload[key]) ? ev.push(string.forms.physicalShares.emptySharesQuantity): !(/^\d+$/).test(payload[key])? ev.push("Fund Unit Should be a Number\n") : null
          break
          case 'contacts':
            payload[key]['id']=== undefined ? ev.push(string.forms.mutualfunds.emptyNominee):null
            break
          default:
            break
          } 
        }
          if(ev.length>0){
            warn(ev)
            return false
          }
          else return true
}*/
  const submit = () => {
    removeAll()
    const roleOfUser= userRole==='GIVER' ? false : true
    const payload = {
      userId,
      assetLookupCode: 'MF',
      receiverFlag:	roleOfUser,
      assetIssuerName,
      assetIdentificationNumber,
      assetQuantity:assetQuantity.toString(),
      contacts: roleOfUser ? null : displayNominee === 'no' ? _.isEmpty(mf) || _.isEmpty(mf.contacts) ? null : { id: mf['contacts']['id'] } : { id: _.head(refPickNominee.current.returnDetails()) }
    }
    console.log(payload)
    //if(validate(payload))

    getId(mf) ? updateFinAssetById(mf.id,{id:mf.id ,...payload})
    .then(response => {
        success('Record Updated Successfully');
        dispatchEditFinAsset(response.data)
        onDone()
    }).catch(err => {
        error(err)
    }) : 
    createFinAsset(payload)
    .then(response => {
      success('Record Created Successfully');
      dispatchAddFinAsset(response.data);
      onDone()
        // dispatchSetAllContactList(response.data);
    })
    .catch(err => {
      error(err)
    })
}
  return <CollapseSection  mainTitle={'Enter ' + string.forms.mutualfunds.mfFormTitle} >
    <section className='section_form'>
      <CardRadioBtn options={[{ value: 'self', display: string.forms.mutualfunds.allMfDetails },
      { value: 'auto', display: string.forms.mutualfunds.MfNSDLStatement}]} onChange={setProvideDeets} defaultValue={'self'} />
      {provideDeets ==='self' ?
        <div><div>
        <HeadBar title={string.forms.mutualfunds.fundName} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.mutualfunds.mfFundName} isBigger bind={bindName} />
      </div>
      <div>
        <HeadBar title={string.forms.mutualfunds.idNo} subtitle={''} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.mutualfunds.mfNo} isBigger bind={bindId} />
      </div>
      <div>
        <HeadBar title={string.forms.mutualfunds.fundUnit} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.mutualfunds.mfFundUnit} isBigger bind={bindUnit} />
      </div>
      </div> :null }
      {provideDeets ==='self' && userRole === 'GIVER'  && displayNominee !== 'no' ?
      <div>
      <div className="row" style={{marginLeft:'2px'}}>
        <HeadBar title={'Who is the nominee'} subtitle={''} /> 
        <HelperModal helperTextId={"nominee"} />
      </div>
      <div className='row'>
      <DropDown options={[{ value: 'Individual', code: 'INDV' }, { value: 'Institution', code: 'INST' }]} placeholder={string.forms.commonFormFields.nominee} isBigger bind={bindNominee} />
      </div>
      <PickContactTable isSingle data={pickList.filter(c => _.get(c, ['contactLookupCode']) === pickedNominee)} ref={refPickNominee} selected={[selectedId]} />
      </div>
      : null
    }
    </section>
    <ContainerPosition>
            <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
            <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
        </ContainerPosition>
  </CollapseSection>
}
export const MFform = forwardRef(MFfrm);

export function SavedMutualFunds ({onUpload, data, dispatchDeleteFinAsset, onEdit, dispalyActions, onShare, datashare, dataShareByMe, accessLevelArray, toggle }) {
  const { success, error } = useQuaishToasts()
  const deleteMF = (id) =>{
    if(window.confirm("Delete?\n Are you sure you want to delete this record?")){
        deleteFinAsset(id)
        .then(response => {
          success('Record Deleted Successfully');
          dispatchDeleteFinAsset(id);

        })
        .catch(err => {
          error(err)
        })
    }
  }

  const row = data.map((e) => {
    return(
      
    <TableContainer>
      <CenterAlignRow>
        <AlignColumn label="Bank Name" text={e.assetIssuerName} />
        <AlignColumn label="Account No." text={e.assetIdentificationNumber} />
        <AlignColumn label="Unit" text={e.assetQuantity} />
        <AlignColumn label="Nominee" text={e.contacts !== null ?getContactFullName(e.contacts) : ''} />
        {datashare === 'yes' ? <AlignColumn label="Shared with" text={_.isEmpty(dataShareByMe[e.id][0]) ? null : dataShareByMe[e.id][0].map((f,index)=>(<span style={{cursor:'pointer', color: '#007bff'}} onClick={()=>toggle(dataShareByMe[e.id][1][index])}><span style={{textDecoration:'underline'}}>{f}</span><span>{index !== (dataShareByMe[e.id][0].length) -1 ? ' , ' : ''}</span></span>))} />: null}
        {datashare === 'shareby' ? <AlignColumn label="Shared By" text={dataShareByMe[e.id]} />: null}
        {dispalyActions === 'no' || datashare === 'yes' || (datashare === 'shareby' && accessLevelArray[e.id]==='READ')  ? null :
        <AlignColumn label="Action" text={<ActionItemUpload onUpload={onUpload} onEdit={()=>onEdit(e)} onDelete={()=>deleteMF(e.id)} onShare={() => onShare(e.id) } datashare={datashare}/>} />}
        </CenterAlignRow>
    </TableContainer>
  )});
  return (
    data.length === 0 ? null :
    <div>
    <CollapseSection isHidden={data.length === 0} mainTitle={'Mutual Funds'} collapsed={false} onShare={() => onShare('multicreate')} isCollapsable={true} customStyle={true}  datashareuser={datashare}  datashare={'yes'}>
      {row}
    </CollapseSection>
    {dispalyActions === 'no' || datashare === 'yes' || datashare === 'shareby' ? <br/> : null }
     </div>
    
  );
};

 const mapStateToProps = (state) => ({
  mfFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'MF'),
  userId: getStoreUserId(state),
  pickList: (getStoreContactList(state).concat(getStoreFamily(state))),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchAddFinAsset: finAsset => dispatch(addFinAsset(finAsset)),
  dispatchEditFinAsset:finAsset=>dispatch(editFinAsset(finAsset)),
  dispatchDeleteFinAsset:finAsset=>dispatch(deleteFinAssetFromList(finAsset)),
})
 export default connect(mapStateToProps, mapDispatchToProps)(MutualFund);
