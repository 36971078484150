import Axios from 'axios'
import { Patch, Post } from "../../../utils/ApiUtil";
import { FLASK_SERVER } from "../../../constant/ServerConst"

const baseuri = FLASK_SERVER
export const createPayment = (data) => Post('/order/create?', data);
export const updatePaymentById = (id, data) => Patch('/order/update/' + id, data);
export const createOrder = (body) =>
  Axios.post(baseuri+'razrpayOrderCreate', body)

export const getAllOrders = (id) =>
  Axios.post(baseuri+'getOrdersByUserId', { }, {
    headers: {
      'user_id': id
    }
  })