import React, { useState, forwardRef, useCallback, useImperativeHandle } from "react";
import DOB from "./DOB";
import CheckBox from "../../components/CheckBox";
import HeadBar from "../../components/HeaderBar";
import Input from "../../components/Input";
import string from "../../constant/en.json";
import moment from 'moment';

const AgeAndDOBForm = ({ defaultValue = { age: 0, minor: false, dob: null } }, ref) => {
    let [age, setAge] = useState(defaultValue.age);
    let [minor, setMinor] = useState(defaultValue.minor);
    let [dob, setDob] = useState(defaultValue.dob);
    let bindAge = {
        value: age
    }
    const refDOB = useCallback(node => {
        if (node !== null && node.returnDetails() && node.returnDetails().dob !== '') {
            let dob = node.returnDetails().dob;
            let age = moment().diff(moment(dob, 'YYYYMMDD'), 'years')
            setAge(age)
            setDob(node.returnDetails().dob)
            setMinor(age < 18)
        }
    }, [dob]);
    useImperativeHandle(ref, () => ({
        returnDetails() {
            return {
                age,
                minor,
                dob,
            };
        },
    }));
    return (
        <div>
            <DOB defaultValue={dob} ref={refDOB} />
            {minor ? <CheckBox title={string.forms.commonFormFields.minor} defaultValue={minor} disabled={true} /> : null}
            <HeadBar title={'Age'} />
            <div className='row'>
                <Input placeholder={string.forms.commonFormFields.age} bind={bindAge} disabled isBigger />
            </div>
        </div>
    );
}

const AgeAndDOB = forwardRef(AgeAndDOBForm);
export default AgeAndDOB;