import React, { useCallback, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { createFamily, editFamily } from '../../../actions/familyActions';
import CheckBox from '../../../components/CheckBox';
import CollapseSection from '../../../components/CollapsibleSection';
import { ContainerPosition, CustomButtom } from '../../../components/CustomElements';
import HeadBar from '../../../components/HeaderBar';
import RadioBtnGrp from '../../../components/RadioButtonGroup';
import string from '../../../constant/en.json';
import { getId, getMapping, getValue } from '../../../utils/commonUtil';
import Identification from '../../components/Identification';
import NameDetails from '../../components/NameDetails';
import ReligionNationality from '../../components/ReligionNationality';
import { createContact, updateContactById } from '../contacts/ContactService';
import { forwardRef } from 'react';
import HelperModal from '../../helper/HelperModal';
import { useQuaishToasts } from '../../../actions/alertAction';

const Parent = ({ onDone, onCancel, dispatchEditFamily, dispatchCreateFamily, userId, data = { religion: '', nationality: '', relationship: '', title: '', firstName: '', lastName: '', middleName: '', idProofNumber: '', idProofType: '' } },ref) => {
  const { relationship: relationshipDef, title, middleName, firstName, lastName, religion, nationality, idProofNumber, idProofType, deceased, id } = data;
  const nameDeets = useRef();
  let [collapse, setCollapse] = useState(false);
  const refReligionNationality = useRef();
  const refId = useRef();
  const refDec = useRef();
  let [relationship, setRelation] = useState(relationshipDef);
  const relationRef = useCallback(node => {
    if (node !== null) {
      setRelation(node.returnDetails().value)
    }
  }, []);
  const { success, error, removeAll } = useQuaishToasts()
  const submit = () => {
    removeAll()
    let payload = {
      userId,
      relationship,
      deceased: getValue(refDec),
      contactLookupCode: 'INDV',
      gender: relationship === 'MOTHER' ? 'Female' : 'Male',
      ...getValue(refReligionNationality),
      ...getValue(nameDeets),
      ...getMapping(['idProofNumber', 'idProofType'], refId),
    }
    getId(data) ? updateContactById(id, payload)
      .then(response => {
        success('Record Updated Successfully');
        dispatchEditFamily(response.data)
        setCollapse(true)
        onDone()
      }).catch(err => {
        error(err)
      }) : createContact(payload)
        .then(response => {
          success('Record Created Successfully');
          dispatchCreateFamily(response.data);
          setCollapse(true)
          onDone()
        })
        .catch(err => {
          error(err)
        })

  }
  return <CollapseSection mainTitle={'Enter Your Parent Details'} collapsed={collapse} showForm={setCollapse} isCollapsable>
    <section className='section_form'>
      <HeadBar title={`Father's / Mother's Name*`} />
      <div className='row' style={{ marginBottom: '15px', marginTop: '5px' }}>
        <RadioBtnGrp
          ref={relationRef}
          defaultValue={relationship}
          options={[
            { value: 'FATHER', display: 'Father`s' },
            { value: 'MOTHER', display: 'Mother`s' },
            { value: 'N/A', display: 'Not Applicable' },
          ]}
          onChange={setRelation}
        />
      </div>
      {relationship === 'N/A' ? null :
        <div>
          <NameDetails defaultValue={{ title, middleName, firstName, lastName }} ref={nameDeets} />
          <div className="row"  style={{marginLeft:'2px'}}>
            <CheckBox defaultValue={deceased} ref={refDec} title={'Deceased?'} noClass />
            <HelperModal helperTextId={"deceased"} />
          </div>
          <ReligionNationality defaultValue={{ religion, nationality }} ref={refReligionNationality} />
          <Identification defaultValue={{ idProofNumber, idProofType }} ref={refId} />
          <ContainerPosition>
            <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
            <CustomButtom event={() => {
              setCollapse(true)
              onCancel()
            }} title={string.forms.commonFormFields.cancel} />
          </ContainerPosition>
        </div>
      }
    </section>
  </CollapseSection>
};
const mapDispatchToProps = () => dispatch => ({
  dispatchCreateFamily: parent => dispatch(createFamily(parent)),
  dispatchEditFamily: parent => dispatch(editFamily(parent)),
})
const ParentFrm= forwardRef(Parent)
export default connect(null, mapDispatchToProps)(ParentFrm);
