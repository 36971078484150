import React from 'react';
import Footer from './component/layout//Footer';
import Header from './component/layout/Header';
import  FAQ from '../dashboard/components/FAQ';

const Home = () => {
    return (
        <div>
        <Header />
        <FAQ/>
        <Footer />
      </div>
    );
}
export default Home;