import React, { forwardRef, useRef, useState } from 'react';
import CollapseSection from "../../../../components/CollapsibleSection";
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import { useInput } from "../../../../hooks/useInput";
import DropDown from '../../../../components/DropDown';
import { HoldingTypeTag, CardRadioBtn } from '../../../components/HoldingTypeTag';
import string from '../../../../constant/en.json';
import { addFinAsset, editFinAsset, deleteFinAssetFromList, getStoreFinAssetList } from '../../../../actions/finAssetsActions';
import { getStoreUserId, getStoreUserRole } from '../../../../selectors/userSelectors';
import { connect } from 'react-redux';
import { getContactFullName, getId, batchState } from '../../../../utils/commonUtil';
import { AlignColumn, TableContainer, CenterAlignRow, CustomButtom, ContainerPosition, ActionItemUpload } from '../../../../components/CustomElements';
import { deleteFinAsset, createFinAsset, updateFinAssetById } from '../finAssetService';
import PickContactTable from '../../../components/PickContactTable';
import _ from 'lodash';
import { getStoreContactList } from '../../../../actions/contactActions';
import { getStoreFamily } from '../../../../actions/familyActions';
import HelperModal from '../../../helper/HelperModal';
import SliderBar from '../../../../components/UploadComponent';
import { useQuaishToasts } from '../../../../actions/alertAction';
import ModalContainer from '../../../../home/component/layout/Modal'
import DataShareModal from '../../../../dashboard/forms/dataShare/DataShareModal'
import NSDL from './NSDL';

const Demat = ({userId, pickList, dematFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, dispatchDeleteFinAsset, userRole}, ref) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [demat, setDemat] = useState();
  let [visibleDataShare, setVisibleDataShare] = useState(false)
  let toggleDataShare = () => setVisibleDataShare(!visibleDataShare)
  const [dataShareId, setDataShareId] = useState('')

  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && demat &&  demat.id ) {
        setDemat(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setDemat(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
      setDemat(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const onShare = (c) => {
    setDataShareId(c)
    toggleDataShare()
  }
       
  const docsRef = React.useRef()
  const onUpload = (c) => {
    docsRef.current.submit('DEM', c.id, "Upload Files")
  }
  return (
    <div>

        <SavedDemat onUpload={onUpload} data={dematFinAssetList} onEdit={onEdit} onShare={onShare} isFormHidden={isFormHidden} dispatchDeleteFinAsset={dispatchDeleteFinAsset} />
        <div  style={{ marginBottom: 20, display: 'block' }}>
          <ContainerPosition>
            <CustomButtom event={() => toggleForm()} title={'Add Demat Accounts'} />
          </ContainerPosition>
        </div>
        {isFormHidden ? null : <DematFrm demat={demat} userId={userId} onCancel={toggleForm} onDone={onDone} dematFinAssetList={dematFinAssetList} dispatchAddFinAsset={dispatchAddFinAsset} dispatchEditFinAsset={dispatchEditFinAsset} pickList={pickList} userRole={userRole} />}
        <SliderBar ref={docsRef} />
        {visibleDataShare
                  ? 
                  <ModalContainer visible={visibleDataShare} toggle={toggleDataShare}>
                    <DataShareModal visibleDataShare={visibleDataShare} toggleDataShare={toggleDataShare} dataShareId={dataShareId} AssetList={dematFinAssetList} assetLookupKey={"asset_fin"} assetLookupCode={'DEM'}/>
                  </ModalContainer> 
                  : null 
        }
    </div>
  );
}

const DematFrm = ({demat,userId, onCancel, onDone, dematFinAssetList, dispatchAddFinAsset, dispatchEditFinAsset, pickList, userRole, displayNominee}, ref) => {
  const { value: assetIssuerName, bind: bindName } = useInput(!demat ? '' : demat.assetIssuerName);
  const { value: assetDescription, bind: bindassetDescription } = useInput(!demat ? '' : demat.assetDescription);
  const { value: assetIdentificationNumber, bind: bindDpId } = useInput(!demat ? '' : demat.assetIdentificationNumber);
  const { value: assetIdentificationNumber2, bind: bindCustId } = useInput(!demat ? '' : demat.assetIdentificationNumber2);
  const { value: pickedNominee, bind: bindNominee } =useInput(userRole === 'RECEIVER' ? null : !demat ? 'INDV' : demat['contacts']['contactLookupCode']);
  const { value: assetHoldingType, bind: bindAccHoldingType } = useInput(!demat ? '' : demat.assetHoldingType);
  const [provideDeets, setProvideDeets] = useState('self')
  const selectedId =  userRole === 'RECEIVER' ? null : !demat ? '' : demat['contacts']['id']
  const refPickNominee = useRef();
  const { success, error, removeAll } = useQuaishToasts()
  /*const validate=(payload)=>{
    const ev=[]
    for (var key in payload) {
      switch(key){
          case 'assetIssuerName':
            _.isEmpty(payload[key]) ? ev.push(string.forms.demataccount.emptyBankName):null
            break
          case 'assetIdentificationNumber':
            _.isEmpty(payload[key]) ? ev.push(string.forms.demataccount.emptyDpID):null
            break
          case 'assetIdentificationNumber2':
            _.isEmpty(payload[key]) ? ev.push(string.forms.demataccount.emptyCustomerId):null
            break
          case 'assetHoldingType':
            _.isEmpty(payload[key]) ? ev.push(string.forms.demataccount.emptyHolding):null
            break
          case 'contacts':
            payload[key]['id']=== undefined ? ev.push(string.forms.demataccount.emptyNominee):null
            break
          default:
            break
          } 
        }
          if(ev.length>0){
            warn(ev)
            return false
          }
          else return true
}*/
  const submit = () => {
    removeAll()
    const roleOfUser= userRole==='GIVER' ? false : true
    const payload = {
      userId,
      assetLookupCode: 'DEM',
      receiverFlag:	roleOfUser,
      assetIssuerName,
      assetDescription,
      assetIdentificationNumber,
      assetIdentificationNumber2,
      assetHoldingType: assetHoldingType ==='' ? null : assetHoldingType,
      contacts: roleOfUser ? null : displayNominee === 'no' ? _.isEmpty(demat) || _.isEmpty(demat.contacts) ? null : { id: demat['contacts']['id'] } : { id: _.head(refPickNominee.current.returnDetails()) }
    }
    console.log(payload)
    //if(validate(payload))

    getId(demat) ? updateFinAssetById(demat.id,{id:demat.id,...payload})
    .then(response => {
        success('Record Updated Successfully');
        dispatchEditFinAsset(response.data)
        onDone()
    }).catch(err => {
        error(err)
    }) : 
    createFinAsset(payload)
    .then(response => {
      success('Record Created Successfully');
      dispatchAddFinAsset(response.data);
      onDone()
        // dispatchSetAllContactList(response.data);
    })
    .catch(err => {
      error(err)
    })
}
  return <CollapseSection  mainTitle={'Enter ' + string.forms.demataccount.dematAccountFormTitle} >
    <section className='section_form'>
      <CardRadioBtn options={[{ value: 'self', display: string.forms.demataccount.allDematDetails },
      { value: 'auto', display: string.forms.demataccount.dematNSDLStatement }]} onChange={setProvideDeets} defaultValue={'self'} />
      {provideDeets ==='self' ?
        <div><div>
        <HeadBar title={string.forms.demataccount.bankName} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.demataccount.bankNamePlaceholder} isBigger bind={bindName} />
      </div>
      <div>
        <HeadBar title={string.forms.demataccount.dpID} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.demataccount.dpIDPlaceholder} isBigger bind={bindDpId} />
      </div>
      <div>
        <HeadBar title={string.forms.demataccount.customerId} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.demataccount.customerIdPlaceholder} isBigger bind={bindCustId} />
      </div>
      <HoldingTypeTag bind={bindAccHoldingType} />
      {userRole === 'GIVER' && displayNominee !== 'no'?
      <div>
      <div className="row" style={{marginLeft:'2px'}}>
        <HeadBar title={'Who is/are the nominee(s)?'} subtitle={'This should be the same as declared in the Demat account'} /> 
        <HelperModal helperTextId={"nominee"} />
      </div>
      <div className='row'>
      <DropDown options={[{ value: 'None', code: 'INDV' },{ value: 'Individual', code: 'INDV' }, { value: 'Institution', code: 'INST' }]} placeholder={string.forms.commonFormFields.nominee} isBigger bind={bindNominee} />
      </div>
       {<PickContactTable isSingle data={pickList.filter(c => _.get(c, ['contactLookupCode']) === pickedNominee)} ref={refPickNominee} selected={[selectedId]} />}
       <div><br></br></div>
       <div>
        <HeadBar title={string.forms.demataccount.multipleNominee} subtitle={'Note: DEMAT accounts allow upto 3 nominees'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.demataccount.nomineePlaceholder} isBigger bind={bindassetDescription} />
      </div>
      
      </div>
      
      : null
    }
      </div>:<NSDL/>}
    </section>
    {provideDeets ==='self' ?<ContainerPosition>
            <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
            <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
        </ContainerPosition>:null}
  </CollapseSection>
}
export const DematForm = forwardRef(DematFrm);

export function SavedDemat ({onUpload, data, dispatchDeleteFinAsset, onEdit, dispalyActions, onShare, datashare, dataShareByMe, accessLevelArray, toggle }) {
  const { success, error } = useQuaishToasts()
  const deleteDemat = (id) =>{
    if(window.confirm("Delete?\n Are you sure you want to delete this record ?")){
        deleteFinAsset(id)
        .then(response => {
          success('Record Deleted Successfully');
          dispatchDeleteFinAsset(id);

        })
        .catch(err => {
          error(err)
        })
    }
  }

  const row = data.map((e) => {
    return(
    <TableContainer>
      <CenterAlignRow>
        <AlignColumn label="Bank Name" text={e.assetIssuerName} />
        <AlignColumn label="DP ID." text={e.assetIdentificationNumber} />
        <AlignColumn label="Customer ID." text={e.assetIdentificationNumber2} />
        <AlignColumn label="Nominee" text={e.contacts !==null ? getContactFullName(e.contacts) : ''} />
        {datashare === 'yes' ? <AlignColumn label="Shared with" text={_.isEmpty(dataShareByMe[e.id][0]) ? null : dataShareByMe[e.id][0].map((f,index)=>(<span style={{cursor:'pointer', color: '#007bff'}} onClick={()=>toggle(dataShareByMe[e.id][1][index])}><span style={{textDecoration:'underline'}}>{f}</span><span>{index !== (dataShareByMe[e.id][0].length) -1 ? ' , ' : ''}</span></span>))} />: null}
        {datashare === 'shareby' ? <AlignColumn label="Shared By" text={dataShareByMe[e.id]} />: null}
        {dispalyActions === 'no' || datashare === 'yes' || (datashare === 'shareby' && accessLevelArray[e.id]==='READ')  ? null :
        <AlignColumn label="Action" text={<ActionItemUpload onUpload={onUpload} onEdit={()=>onEdit(e)} onDelete={()=>deleteDemat(e.id)} onShare={() => onShare(e.id)} datashare={datashare}/>} />}
      </CenterAlignRow>
    </TableContainer>
  )});
  return (
    data.length === 0 ? null :
    <div>
    <CollapseSection isHidden={data.length === 0} mainTitle={'Demat Accounts'} collapsed={false} onShare={() => onShare('multicreate')}  isCollapsable={true} customStyle={true}  datashareuser={datashare}  datashare={'yes'}>
      {row}
    </CollapseSection>
          {dispalyActions === 'no' || datashare === 'yes' || datashare === 'shareby' ? <br/> : null }
     </div>
  );
};

 const mapStateToProps = (state) => ({
  dematFinAssetList: getStoreFinAssetList(state).filter(fa=> fa.assetLookupCode === 'DEM'),
  userId: getStoreUserId(state),
  pickList: (getStoreContactList(state).concat(getStoreFamily(state))),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchAddFinAsset: finAsset => dispatch(addFinAsset(finAsset)),
  dispatchEditFinAsset:finAsset=>dispatch(editFinAsset(finAsset)),
  dispatchDeleteFinAsset:finAsset=>dispatch(deleteFinAssetFromList(finAsset)),
})
 export default connect(mapStateToProps, mapDispatchToProps)(Demat);
