import React, { useRef, useState } from 'react';
import Auth from "@aws-amplify/auth";
import { AWS_CONFIG } from '../constant/ServerConst'
import { useHistory } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
Auth.configure({ ...AWS_CONFIG.Auth });

const ChangePassword = ({onClose}) => {
const history = useHistory();
const refOldPassword = useRef();
const refNewRePassword = useRef();
const [disableBtn, setDisableBtn] = useState(false);
const { addToast, removeAllToasts } = useToasts()

async function passwordChange(event) {
    event.preventDefault();
    removeAllToasts()
    setDisableBtn(true)
    let password = refOldPassword.current.value
    let repassword = refNewRePassword.current.value
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        password,
        repassword
      ).then((response)=>{
          onClose()
          addToast("Password Update Successful.", { variant: 'success' })
      })
      history.push("/profile");
    } catch (error) {
        onClose()
        if(error){
            alert(error)
        }
      console.log(error);
      setDisableBtn(false);
    }
  }
return(
    <div>
    <div style={{ marginBottom: 20, textAlign: 'center' }}>
      <h4 style={{ display: 'inline-block' }}>Change Password</h4>
      <button type='button' className='close' onClick={onClose}>
        &times;
      </button>
    </div>
    <form onSubmit={(event) => passwordChange(event)}>
      <div className='form-group'>
        <input type='password' className='form-control' name='password' placeholder='Old Password' required='required' ref={refOldPassword} />
      </div>
      <div className='form-group'>
        <input type='password' className='form-control' name='repassword' placeholder='New Password' required='required' ref={refNewRePassword} />
      </div>
      <div className='form-group'>
        <button type='submit' className='btn btn-primary btn-lg btn-block login-btn' disabled={disableBtn}>
          Submit
          </button>
      </div>
    </form>
  </div>
);
}

export default ChangePassword;
