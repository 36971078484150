import React, { useImperativeHandle, forwardRef } from 'react';
import { useInput } from '../../hooks/useInput';
import HeadBar from '../../components/HeaderBar';
import Input from '../../components/Input';
import string from '../../constant/en.json';

export const DOBForm = (props, ref) => {
  const { value, bind } = useInput(props.defaultValue);
  useImperativeHandle(ref, () => ({

    returnDetails() {
      const dob = value === 'Invalid date' ? null : value
      return { dob }
    }
  }));
  return <section className='section_form'>
    <HeadBar title={string.forms.commonFormFields.dob} />
    <div className='row'>
      <Input type='date' bind={bind} />
    </div>
  </section>
};
const DOB = forwardRef(DOBForm);
export default DOB;
