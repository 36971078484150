import initialState from "./initialState";
import _ from 'lodash';
import { ADD_DATASHARE_BY_CONSTANTS, EDIT_DATASHARE_BY_CONSTANTS } from "../actions/dataShareByAction";
export default (state = initialState.dataSharedBy, action) => {
    switch (action.type) {
        case ADD_DATASHARE_BY_CONSTANTS:
            return action.dataSharedBy;

        case EDIT_DATASHARE_BY_CONSTANTS:
            const index = _.findIndex(state, { id: action.dataShareBy.id });
            state.splice(index, 1, action.dataShareBy);
            return [...state];
        default:
            return state;
    }
}
