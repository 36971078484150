import React, { useEffect, useState } from 'react';
import { getStoreUserId, getStoreUserRole } from '../../../selectors/userSelectors';
import { getStoreFinAssignList, setFinAssignData, deleteFinAssignFromList } from '../../../actions/finAssignActions';
import { getContactFullName } from '../../../utils/commonUtil';
import { connect } from 'react-redux';
import { editImmovableAsset, addImmovableAsset, getStoreImmovableAssetList } from '../../../actions/immovableAssetsActions';
import { getStoreFinAssetList, addFinAsset, editFinAsset } from '../../../actions/finAssetsActions'
import { editOtherAsset, addOtherAsset, getStoreOtherAssetList } from '../../../actions/otherAssetsActions';
import { getStoreDigitalAssetList, addDigitalAsset, editDigitalAsset } from '../../../actions/digitalAssetsActions';
import { Button } from 'react-bootstrap';
import { getStoreImmPropAssignList, setImmPropAssignData, deleteImmPropAssignFromList } from '../../../actions/immovablePropAssignActions';
import { getStoreOtherAssignList, deleteOtherAssignFromList, setOtherAssignData } from '../../../actions/otherAssignActions';
import { getStoreDigitalAssignList, deleteDigitalAssignFromList, setDigitalAssignData } from '../../../actions/digitalAssignActions';
import { getUserDataFromServer } from '../personal/personalService'
import { SavedFD } from '../assets/forms/FixedDeposit'
import { SavedPhyShares } from '../assets/forms/PhysicalShares'
import { SavedDemat } from '../assets/forms/Demat'
import { SavedGovSchemes } from '../assets/forms/GovtScheme'
import { SavedPortfolio } from '../assets/forms/Portfolio'
import { SavedBankAcounts } from '../assets/forms/BankAccount'
import { SavedInsurance } from '../assets/forms/Insurance'
import { SavedMutualFunds } from '../assets/forms/MutualFund'
import { SavedBonds } from '../assets/forms/Bond'
import { SavedPF } from '../assets/forms/PF'
import { SavedGratuity } from '../assets/forms/Gratuity'
import { SavedArtefacts } from '../assets/forms/Artefacts'
import { SavedJewellery } from '../assets/forms/Jewellery'
import { SavedArts } from '../assets/forms/Art'
import { SavedLockers } from '../assets/forms/Locker'
import { SavedVehicles } from '../assets/forms/Vehicle'
import { SavedPersonalEffects } from '../assets/forms/PersonalEffect'
import { SavedSocials } from '../assets/forms/Social'
import { SavedWebsites } from '../assets/forms/Website'
import { SavedimmProps } from '../assets/forms/ImmovableProperty'
import { HelpSection, HighlightedText, List } from '../../../components/CustomElements';
import { getStoreLiabilityList } from '../../../actions/liabilitiesActions';
import { getAllDataShareByMe, deleteDataShareById, updateDataShareById } from '../../../dashboard/forms/dataShare/datashareService'
import { getDataSharedByme, editDataSharedbyme, deleteDataSharedbymeList, setDataSharedByme} from '../../../actions/dataSharedByMeActions';
import { setDataShareWithme, getDataShareWithme } from '../../../actions/dataSharedWithmeActions';
import { getStoreContactList } from '../../../actions/contactActions';
import { getStoreFamily } from '../../../actions/familyActions';
import { setDataSharedWithNames, getDataSharedWithNamesList } from '../../../actions/dataSharedWithNamesAction'
import ModalContainer from '../../../home/component/layout/Modal';

const DataShareBy = (
  {userId, 
  immPropAssetList, 
  finAssetList, 
  otherAssetList, 
  digitalAssetList, 
  dispatchDataSharedByme,  
  shareDataByMe,
  shareDataWithNames, 
  dispatchDataShareWithNames,
  dispatchEditDataShare, 
  dispatchDeleteDataShare
}) =>{
 
  let isFormHidden = true
  let [visibleAction, setVisibleAction] = useState(false)
  let [duId, setDuId]=useState('')
  let [al, setAl]=useState(false)

  let toggleAction = (e)=>{
    setVisibleAction(true)
    setDuId(e)
    setAl(true)
  }

  let onClose = () =>{
    setVisibleAction(false)
  }
 
  const [ accessLevelData, setAccessLevelData ] = useState(!al ? 'READ' : (Object.values(shareDataByMe).filter(f=>f.id===duId))[0].accessLevel)

 const assetsAndLiabilities = () =>{
  let fds =[],
  phyShares=[],
  demat=[],
  govtSchemes=[],
  portfolio=[],
  bankAccounts=[],
  insurance=[],
  mfs=[],
  bonds=[],
  pfs=[],
  gratuity=[],
  artefacts=[],
  jewellery=[],
  art=[],
  lockers=[],
  vehicles=[],
  perEffect=[],
  social=[],
  web=[],
  immov=[]
  const dataShareAssetsList = Object.values(shareDataByMe);
  if(  dataShareAssetsList.length >0){
     fds = finAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'FD').map((p)=> p.assetId)).includes(filteredPerson.id))
     phyShares = finAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'SHR').map((p)=> p.assetId)).includes(filteredPerson.id))
     demat = finAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'DEM').map((p)=> p.assetId)).includes(filteredPerson.id))
     govtSchemes = finAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'GOV').map((p)=> p.assetId)).includes(filteredPerson.id))
     portfolio = finAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'PMS').map((p)=> p.assetId)).includes(filteredPerson.id))
     bankAccounts = finAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'BNK').map((p)=> p.assetId)).includes(filteredPerson.id))
     insurance = finAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'INS').map((p)=> p.assetId)).includes(filteredPerson.id))
     mfs = finAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'MF').map((p)=> p.assetId)).includes(filteredPerson.id))
     bonds = finAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'BON').map((p)=> p.assetId)).includes(filteredPerson.id))
     pfs = finAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'PF').map((p)=> p.assetId)).includes(filteredPerson.id))
     gratuity = finAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'GRA').map((p)=> p.assetId)).includes(filteredPerson.id))
     artefacts = otherAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'ARTI').map((p)=> p.assetId)).includes(filteredPerson.id))
     jewellery = otherAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'JEWL').map((p)=> p.assetId)).includes(filteredPerson.id))
     art = otherAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'ART').map((p)=> p.assetId)).includes(filteredPerson.id))
     lockers = otherAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'LOCK').map((p)=> p.assetId)).includes(filteredPerson.id))
     vehicles = otherAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'VEH').map((p)=> p.assetId)).includes(filteredPerson.id))
     perEffect = otherAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'PERS').map((p)=> p.assetId)).includes(filteredPerson.id))
     social = digitalAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'SOC').map((p)=> p.assetId)).includes(filteredPerson.id))
     web = digitalAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'WEB').map((p)=> p.assetId)).includes(filteredPerson.id))
     immov = immPropAssetList.filter(filteredPerson=> (dataShareAssetsList.filter(idss => idss.assetLookupCode === 'PROP').map((p)=> p.assetId)).includes(filteredPerson.id))
    }
    
    const deleteDataShare = (id) =>{
      if(window.confirm("Delete?\n Are you sure you want to delete this record?")){
        setAl(false)
        deleteDataShareById(id)
          .then(response => {
            dispatchDeleteDataShare(id)
            console.log('Record Deleted Successfully');  
            onClose()
            window.location.reload();
          })
          .catch(err => {
            console.log(err)
          })
      }
    }

    const updateDataShare = (id, accessLevel) =>{
      let payload={
        accessLevel:accessLevel
      }
        updateDataShareById(id, payload)
          .then(response => {
            console.log('Record Updated Successfully');  
            dispatchEditDataShare(response.data)
            onClose()
          })
          .catch(err => {
            console.log(err)
          })
    }
  return(
    <div id='scrolltop'>
      {dataShareAssetsList.length===0 ? <div><p style={{textAlign:'center',paddingTop:'5%'}}>You Don't have any shared Assets information yet</p></div> :
      <div>
        <HelpSection heading="HELP TIPS !">
        <List>
          These are records shared by you. Depending on access level ( Read or Write ) your deputies may view and edit these records.
          <HighlightedText></HighlightedText>
        </List>
        <List>
          <HighlightedText>You can change access level at any time.</HighlightedText> Just review the records and edit or remove access.
        </List>
        </HelpSection>
      <div id='htmlpdf'>
      <SavedFD data={fds} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction}  dataShareByMe={shareDataWithNames}/>
      <SavedPhyShares data={phyShares} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction} dataShareByMe={shareDataWithNames}/>
      <SavedDemat data={demat} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction} dataShareByMe={shareDataWithNames}/>
      <SavedGovSchemes data={govtSchemes} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction} dataShareByMe={shareDataWithNames}/>
      <SavedPortfolio data={portfolio} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction} dataShareByMe={shareDataWithNames}/>
      <SavedBankAcounts data={bankAccounts} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction} dataShareByMe={shareDataWithNames}/>
      <SavedInsurance data={insurance} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction}  dataShareByMe={shareDataWithNames}/>
      <SavedMutualFunds data={mfs} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction}  dataShareByMe={shareDataWithNames}/>
      <SavedBonds data={bonds} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction} dataShareByMe={shareDataWithNames}/>
      <SavedPF data={pfs} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction}  dataShareByMe={shareDataWithNames}/>
      <SavedGratuity data={gratuity} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction}  dataShareByMe={shareDataWithNames}/>
      <SavedArtefacts data={artefacts} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction} dataShareByMe={shareDataWithNames}/>
      <SavedJewellery data={jewellery} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction} dataShareByMe={shareDataWithNames}/>
      <SavedArts data={art} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction}  dataShareByMe={shareDataWithNames}/>
      <SavedLockers data={lockers} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction}  dataShareByMe={shareDataWithNames}/>
      <SavedVehicles data={vehicles} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction}  dataShareByMe={shareDataWithNames}/>
      <SavedPersonalEffects data={perEffect} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction}  dataShareByMe={shareDataWithNames}/>
      <SavedSocials data={social} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction}  dataShareByMe={shareDataWithNames}/>
      <SavedWebsites data={web} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction}  dataShareByMe={shareDataWithNames}/>
      <SavedimmProps data={immov} isFormHidden={isFormHidden} datashare={'yes'} toggle={toggleAction}  dataShareByMe={shareDataWithNames}/>
      </div>
      <ModalContainer visible={visibleAction} toggle={toggleAction}>
            <div style={{ padding: '20px',textAlign:'center' }}>
              <button type='button' className='close' onClick={onClose}>
                &times;
              </button>
              <div style={{marginTop:'30px'}}>
              You are about to change shared access for this record.
                <br/><br/>Your deputy can currently  {!al ? 'READ' : (Object.values(shareDataByMe).filter(f=>f.id===duId))[0].accessLevel} this record.<br></br>
                {/*<a style={{color:'rgb(0, 123, 255)', cursor:'pointer'}}>Change Access</a>*/}
                <div>
                <br/>Choose new access level : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                    className='form-check-input'
                    type='radio'
                    //checked={accessLevelData == 'READ'}
                    name={'inlineRadioAccessLevel'}
                    id={'inlineRadioAccessLevel'}
                    value={'READ'}
                    onChange={(event) => setAccessLevelData(event.target.value)}
                    />
                    Read only &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                    className='form-check-input'
                    type='radio'
                    //checked={accessLevelData == 'WRITE'}
                    name={'inlineRadioAccessLevel'}
                    id={'inlineRadioAccessLevel'}
                    value={'EDIT'}
                    onChange={(event) => setAccessLevelData(event.target.value)}
                    />
                    Edit &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                    className='form-check-input'
                    type='radio'
                    //checked={accessLevelData == 'WRITE'}
                    name={'inlineRadioAccessLevel'}
                    id={'inlineRadioAccessLevel'}
                    value={'REMOVE'}
                    onChange={(event) => setAccessLevelData(event.target.value)}
                    />
                    Remove &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <br/><br/>
                <Button style={{textAlign:'center'}} onClick={()=>accessLevelData ==='REMOVE' ? deleteDataShare(duId) : updateDataShare(duId, accessLevelData)}>Save</Button>
                </div>
              </div>
            </div>
        </ModalContainer>
    </div>
  }
    </div>
  )
  }
  const receiverFlag= false
  useEffect(()=>{
    getAllDataShareByMe(userId, receiverFlag)
    .then(resp=>{
      const idList ={}
      const idAssetList ={}
      const nameAssetDict={}
      const emptyData = []
      if(resp.data.length === 0)
       {
        emptyData.push(null)
       }
      else
       {
         resp.data.map(e=>
        {
          if(!idList[e.assetId])
          {
            idList[e.assetId]=[]
            idAssetList[e.assetId]=[]
          }
          idList[e.assetId].push(e.shareToUserId)
          idAssetList[e.assetId].push(e.id)
        })
      }
      const sharedUserIds = resp.data.length === 0 ? [] : resp.data.map(e=>e.shareToUserId).filter((v, i, a) => a.indexOf(v) === i);
      const idListLength=(sharedUserIds.length)
      const userIdWithName = {}
      let arrayLength=[]
      if(sharedUserIds.length > 0) {
      sharedUserIds.map((u,i) => {
        getUserDataFromServer(u)
        .then(res=>{
          let fullName=getContactFullName(res.data)
          userIdWithName[u] = fullName
          arrayLength.push(i)
          if(arrayLength.length === idListLength){
          for (const [key, value] of Object.entries(idList)) {
              if(value.length > 1){
                  value.map((m)=>{
                  if (!nameAssetDict[key]) {
                    nameAssetDict[key] = [[]];
                  }
                  nameAssetDict[key][0].push(userIdWithName[m])
                  nameAssetDict[key][1]=idAssetList[key]
                })
              }
              else{
                if (!nameAssetDict[key]) {
                  nameAssetDict[key] = [[]];
                }
                nameAssetDict[key][0].push(userIdWithName[value])
                nameAssetDict[key][1]=idAssetList[key]
              }
          }
          dispatchDataShareWithNames(nameAssetDict)
        }
        })
        .catch(err=>console.log("error",err))
      }
      )
    }
    else{
      let nameAssetDict={}
      dispatchDataShareWithNames(nameAssetDict)
    }
      dispatchDataSharedByme(resp.data)
    }
      )
    
    .catch(err=>{
      console.log(err)
    })
    
  },[shareDataByMe])

  return (
        <div>
               {assetsAndLiabilities()}
         </div> 
);
}


const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
  finAssignList : getStoreFinAssignList(state),
  immPropAssignList: getStoreImmPropAssignList(state),
  immPropAssetList: getStoreImmovableAssetList(state),
  otherAssignList: getStoreOtherAssignList(state),
  digitalAssignList: getStoreDigitalAssignList(state),
  finAssetList:getStoreFinAssetList(state),
  otherAssetList: getStoreOtherAssetList(state),
  digitalAssetList: getStoreDigitalAssetList(state),
  userRole:getStoreUserRole(state),
  liabilityList: getStoreLiabilityList(state),

  pickList: (getStoreContactList(state).concat(getStoreFamily(state))),

  shareDataWithMe:getDataShareWithme(state),
  shareDataByMe:getDataSharedByme(state),
  shareDataWithNames : getDataSharedWithNamesList(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchSetFinAssignList: finAssign=> dispatch(setFinAssignData(finAssign)),
  dispatchSetImmPropAssignList: immPropAssign=> dispatch(setImmPropAssignData(immPropAssign)),
  dispatchSetOtherAssignList: otherAssign=> dispatch(setOtherAssignData(otherAssign)),
  dispatchSetDigitalAssignList: digitalAssign=> dispatch(setDigitalAssignData(digitalAssign)),
  dispatchDeleteFinAssignList: finAssign=> dispatch(deleteFinAssignFromList(finAssign)),
  dispatchDeleteImmPropAssignList: immPropAssign=> dispatch(deleteImmPropAssignFromList(immPropAssign)),
  dispatchDeleteOtherAssign: otherAssign=> dispatch(deleteOtherAssignFromList(otherAssign)),
  dispatchDeleteDigitalAssign: digitalAssign=> dispatch(deleteDigitalAssignFromList(digitalAssign)),

  dispatchEditDataShare:dataShareBymeids=>dispatch(editDataSharedbyme(dataShareBymeids)),
  dispatchDeleteDataShare:dataShareBymeids=>dispatch(deleteDataSharedbymeList(dataShareBymeids)),

  dispatchAddFinAsset: finAsset => dispatch(addFinAsset(finAsset)),
  dispatchEditFinAsset:finAsset=>dispatch(editFinAsset(finAsset)),

  dispatchAddOtherAsset: finAsset => dispatch(addOtherAsset(finAsset)),
  dispatchEditOtherAsset:finAsset=>dispatch(editOtherAsset(finAsset)),

  dispatchAddDigitalAsset: digitalAsset => dispatch(addDigitalAsset(digitalAsset)),
  dispatchEditDigitalAsset:digitalAsset=>dispatch(editDigitalAsset(digitalAsset)),

  dispatchDataShareWithme: dataShareWithme=> dispatch(setDataShareWithme(dataShareWithme)),
  dispatchDataSharedByme: dataSharedByme=> dispatch(setDataSharedByme(dataSharedByme)),
  dispatchDataShareWithNames: dataSharedWithNames=> dispatch(setDataSharedWithNames(dataSharedWithNames)),

  dispatchAddImmovableAsset: immProp => dispatch(addImmovableAsset(immProp)),
  dispatchEditImmovableAsset:immProp=>dispatch(editImmovableAsset(immProp)),

  })
export default connect(mapStateToProps, mapDispatchToProps)(DataShareBy);