import React from 'react'
import { useState } from 'react'
import ModalContainer from './Modal'
import Login from '../../../auth/Login'
import {useHistory } from 'react-router-dom'
import { Nav, Navbar, Form } from 'react-bootstrap'
import { HashLink as Link } from 'react-router-hash-link'
//import { NavHashLink as NavLink } from 'react-router-hash-link'
import './Header.css';
import AboutDropdown from './AboutDropdown'
import ServicesDropdown from './ServicesDropdown'
import HeaderMobileDropdown from './HeaderMobileDropdown'


const Header = () => {
  let [visibleLogin, setVisibleLogin] = useState(false)
  let toggleLogin = () => setVisibleLogin(!visibleLogin)
  const history = useHistory()
  const aboutPage = () =>{
    history.push('/aboutus')

  }
  const servicePage = () =>{
    history.push('/service')

  }
  return (
    <div className='sticky-top'style={{ background: '#1b2e4a', padding: 0, flex: 1 }}>
      <Navbar className='container' expand='lg' style={{ background: '#1b2e4a', padding: 5, flex: 1 }} >

              <Navbar.Brand>
                    <img
                        style={{ width: 180, height: 40, marginLeft: 20, cursor: 'pointer' }}
                        src={require('../../../assets/img/logo.png')}
                        alt=''
                        onClick={() => { history.push('/') }}
                    />
              </Navbar.Brand>

            <div className='res-hide-full'>
            <HeaderMobileDropdown toggleLogin={toggleLogin}/>
           </div>
        
        {/*<Navbar.Collapse>
          <Nav className='mr-auto' />
          <Form inline style={{ marginLeft: 20, marginRight: 20 }}>
          { <Navbar.Text style={{ marginRight: 20, marginLeft: 20 }}>
          <AboutDropdown content="aboutus" aboutpage={aboutPage}/>

            </Navbar.Text>}
           
            { <Navbar.Text style={{ marginRight: 20, marginLeft: 20 }}>
            <ServicesDropdown content="service" servicepage={servicePage}/>
            </Navbar.Text>}
            { <Navbar.Text style={{ marginRight: 10, marginLeft: 20 }}>
            <Link
              to='/secure'
              style={{ fontWeight: 500, marginRight: 25, color: '#fff' }}
            >
              Security
            </Link>
            </Navbar.Text>}

            { <Navbar.Text style={{ marginRight: 10, marginLeft: 20 }}>
            <Link
              to='/q&a'
              style={{ fontWeight: 500, marginRight: 25, color: '#fff' }}
            >
              Pricing
            </Link>
            </Navbar.Text>}

            { <Navbar.Text style={{ marginRight: 10, marginLeft: 20 }}>
            <Link
              to='/questions'
              style={{ fontWeight: 500, marginRight: 25, color: '#fff' }}
            >
              FAQ
            </Link>
            </Navbar.Text>}
            
            { <Navbar.Text style={{ marginRight: 10, marginLeft: 20 }}>
            <Link
              to='/contactus'
              style={{ fontWeight: 500, marginRight: 25, color: '#fff' }}
            >
              Contact
            </Link>
             </Navbar.Text>}
             { <Navbar.Text style={{ marginRight: 10, marginLeft: 20 }}>
            <Link
              className='top-nav-link'
              style={{ fontWeight: 500,  color: '#fff' }}
              onClick={toggleLogin}
            >
             Login
            </Link>
            </Navbar.Text>}
            <ModalContainer visible={visibleLogin} toggle={toggleLogin}>
              <div style={{ padding: '40px' }}>
                <Login onClose={toggleLogin} />
              </div>
            </ModalContainer>
          </Form>
        </Navbar.Collapse>*/}
        
      </Navbar>
    </div>
  )
}

export default Header
