export const ADD_FIN_ASSIGN_LIST = 'ADD_FIN_ASSIGN_LIST';
export const CREATE_FIN_ASSIGN = 'CREATE_FIN_ASSIGN';
export const EDIT_FIN_ASSIGN = 'EDIT_FIN_ASSIGN';
export const DELETE_FIN_ASSIGN = 'DELETE_FIN_ASSIGN';

export const setFinAssignData = (finAssigns) => {

    return {
        type: ADD_FIN_ASSIGN_LIST,
        finAssigns
    }
}
export const deleteFinAssignFromList = (finAssign) => {

    return {
        type: DELETE_FIN_ASSIGN,
        finAssign
    }
}

export const addFinAssign = finAssign => ({ type: CREATE_FIN_ASSIGN, finAssign });
export const editFinAssign = finAssign => ({ type: EDIT_FIN_ASSIGN, finAssign });
export const getStoreFinAssignList = state => state.finAssigns;


