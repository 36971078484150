export const ADD_DIGITAL_ASSET_LIST = 'ADD_DIGITAL_ASSET_LIST';
export const CREATE_DIGITAL_ASSET = 'CREATE_DIGITAL_ASSET';
export const EDIT_DIGITAL_ASSET = 'EDIT_DIGITAL_ASSET';
export const DELETE_DIGITAL_ASSET = 'DELETE_DIGITAL_ASSET';
export const setDigitalAssetData = (digitalAssets) => {

    return {
        type: ADD_DIGITAL_ASSET_LIST,
        digitalAssets
    }
}
export const deleteDigitalAssetFromList = (digitalAssetId) => {

    return {
        type: DELETE_DIGITAL_ASSET,
        digitalAssetId
    }
}

export const addDigitalAsset = digitalAsset => ({ type: CREATE_DIGITAL_ASSET, digitalAsset });
export const editDigitalAsset = digitalAsset => ({ type: EDIT_DIGITAL_ASSET, digitalAsset });
export const getStoreDigitalAssetList = state => state.digitalAssets;
