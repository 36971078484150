import React, { forwardRef, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { createFamily, editFamily } from '../../../actions/familyActions';
import CollapseSection from '../../../components/CollapsibleSection';
import { ContainerPosition, CustomButtom } from '../../../components/CustomElements';
import HeadBar from '../../../components/HeaderBar';
import string from '../../../constant/en.json';
import { getId, getMapping, getValue } from '../../../utils/commonUtil';
import DOB from '../../components/DOB';
import Identification from '../../components/Identification';
import NameAndGender from '../../components/NameAndGender';
import ReligionNationality from '../../components/ReligionNationality';
import { createContact, updateContactById } from '../contacts/ContactService';
import moment from 'moment';
import { useQuaishToasts } from '../../../actions/alertAction';

const Spouse = ({ onCancel, onDone, dispatchEditFamily, dispatchCreateFamily, userId, data = { religion: '', nationality: '', relationship: '', title: '', firstName: '', lastName: '', middleName: '', idProofNumber: '', idProofType: '' } }, ref) => {
  const { title, middleName, firstName, lastName, religion, nationality, idProofNumber, idProofType, gender, id, dob } = data;
  const refReligionNationality = useRef();
  const refNameAndGender = useRef();
  const refId = useRef();
  const refDOB = useRef();
  let [collapse, setCollapse] = useState(false);
  const { success, error, removeAll } = useQuaishToasts()

  const submit = () => {
    removeAll()
    let age = moment().diff(moment(refDOB.current.returnDetails()['dob'], 'YYYYMMDD'), 'years')
    let payload = {
      userId,
      relationship: 'SPOUSE',
      contactLookupCode: 'INDV',
      ...refDOB.current.returnDetails(),
      age:age,
      gender: refNameAndGender.current.returnDetails().gender,
      ...getValue(refReligionNationality),
      ...getValue(refNameAndGender).name,
      ...getMapping(['idProofNumber', 'idProofType'], refId),
    }
    getId(data) ? updateContactById(id, payload)
      .then(response => {
        success('Record Updated Successfully');
        dispatchEditFamily(response.data)
        onDone()
      }).catch(err => {
        error(err)
      }) : createContact(payload)
        .then(response => {
          success('Record Created Successfully');
          dispatchCreateFamily(response.data);
          onDone()
        })
        .catch(err => {
          error(err)
        })
  }
  return <CollapseSection mainTitle={'Enter Your Spouse Details'} collapsed={collapse} showForm={setCollapse} isCollapsable>
    <section className='section_form'>
      <HeadBar title={`Spouse's Name*`} />
      <NameAndGender ref={refNameAndGender} defaultValue={{ gender, name: { title, firstName, middleName, lastName } }} />
      <DOB defaultValue={dob} ref={refDOB} />
      <ReligionNationality defaultValue={{ religion, nationality }} ref={refReligionNationality} />
      <Identification defaultValue={{ idProofNumber, idProofType }} ref={refId} />
    </section>
    <ContainerPosition>
      <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
      <CustomButtom event={() => onCancel()} title={string.forms.commonFormFields.cancel} />
    </ContainerPosition>
  </CollapseSection>
};
const SpouseForm = forwardRef(Spouse);

const mapDispatchToProps = () => dispatch => ({
  dispatchCreateFamily: parent => dispatch(createFamily(parent)),
  dispatchEditFamily: parent => dispatch(editFamily(parent)),
})
export default connect(null, mapDispatchToProps)(SpouseForm);
