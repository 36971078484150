export const ADD_IMMPROP_ASSIGN_LIST = 'ADD_IMMPROP_ASSIGN_LIST';
export const CREATE_IMMPROP_ASSIGN = 'CREATE_IMMPROP_ASSIGN';
export const EDIT_IMMPROP_ASSIGN = 'EDIT_IMMPROP_ASSIGN';
export const DELETE_IMMPROP_ASSIGN = 'DELETE_IMMPROP_ASSIGN';
export const setImmPropAssignData = (immPropAssigns) => {

    return {
        type: ADD_IMMPROP_ASSIGN_LIST,
        immPropAssigns
    }
}
export const deleteImmPropAssignFromList = (immPropAssignId) => {

    return {
        type: DELETE_IMMPROP_ASSIGN,
        immPropAssignId
    }
}

export const addImmPropAssign = immPropAssign => ({ type: CREATE_IMMPROP_ASSIGN, immPropAssign });
export const editImmPropAssign = immPropAssign => ({ type: EDIT_IMMPROP_ASSIGN, immPropAssign });
export const getStoreImmPropAssignList = state => state.immPropAssigns;
