import React, { forwardRef, useState } from 'react';
import CollapseSection from "../../../../components/CollapsibleSection";
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import { useInput } from "../../../../hooks/useInput";
import string from '../../../../constant/en.json';
import { batchState, getId } from '../../../../utils/commonUtil';
import { ContainerPosition, CustomButtom, ActionItem, AlignColumn, CenterAlignRow, TableContainer } from '../../../../components/CustomElements';
import { updateOtherAssetById, createOtherAsset, deleteOtherAsset } from '../otherAssetService';
import { connect } from 'react-redux';
import { deleteOtherAssetFromList, editOtherAsset, addOtherAsset, getStoreOtherAssetList } from '../../../../actions/otherAssetsActions';
import { getStoreUserId, getStoreUserRole } from '../../../../selectors/userSelectors';
import _ from 'lodash';
import { useQuaishToasts } from '../../../../actions/alertAction';
import ModalContainer from '../../../../home/component/layout/Modal'
import DataShareModal from '../../../../dashboard/forms/dataShare/DataShareModal'

const Vehicle = ({userId, vehicleOtherAssetList, dispatchAddOtherAsset, dispatchEditOtherAsset, dispatchDeleteOtherAsset, userRole}, ref) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [vehicle, setVehicle] = useState();
  let [visibleDataShare, setVisibleDataShare] = useState(false)
  let toggleDataShare = () => setVisibleDataShare(!visibleDataShare)
  const [dataShareId, setDataShareId] = useState('')

  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && vehicle &&  vehicle.id ) {
        setVehicle(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setVehicle(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
      setVehicle(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const onShare = (c) => {
    setDataShareId(c)
    toggleDataShare()
  }
       
  return (
    <div>
        <SavedVehicles data={vehicleOtherAssetList} onEdit={onEdit} onShare={onShare} isFormHidden={isFormHidden} dispatchDeleteOtherAsset={dispatchDeleteOtherAsset} />
        <div  style={{ marginBottom: 20, display: 'block' }}>
          <ContainerPosition>
            <CustomButtom event={() => toggleForm()} title={'Add Vehicles'} />
          </ContainerPosition>
        </div>
        {isFormHidden ? null : <Vehicleform vehicle={vehicle} userId={userId} onCancel={toggleForm} onDone={onDone} vehicleOtherAssetList={vehicleOtherAssetList} dispatchAddOtherAsset={dispatchAddOtherAsset} dispatchEditOtherAsset={dispatchEditOtherAsset} userRole={userRole} />}
        {visibleDataShare
                  ? 
                  <ModalContainer visible={visibleDataShare} toggle={toggleDataShare}>
                    <DataShareModal visibleDataShare={visibleDataShare} toggleDataShare={toggleDataShare} dataShareId={dataShareId} AssetList={vehicleOtherAssetList} assetLookupKey={"asset_other"} assetLookupCode={'VEH'}/>
                  </ModalContainer> 
                  : null 
        }
    </div>
  );
}

const Vehiclefrm = ({vehicle,userId, onCancel, onDone, vehicleOtherAssetList, dispatchAddOtherAsset, dispatchEditOtherAsset, userRole}, ref) => {
  const { value: assetIssuerName, bind: bindName } = useInput(!vehicle ? '' : vehicle.assetIssuerName);
  const { value: assetIdentificationNumber, bind: bindId } = useInput(!vehicle ? '' : vehicle.assetIdentificationNumber);
  const { success, error, removeAll } = useQuaishToasts()
  /*const validate=(payload)=>{
    const ev=[]
    for (var key in payload) {
      switch(key){
          case 'assetIssuerName':
            _.isEmpty(payload[key])   ? ev.push(string.forms.vehicle.emptyName):null
            break
          case 'assetIdentificationNumber':
            _.isEmpty(payload[key])   ? ev.push(string.forms.vehicle.emptyVehicleNo):null
            break
          default:
            break
          } 
        }
          if(ev.length>0){
            warn(ev)
            return false
          }
          else return true
        }*/
  const submit = () => {
    removeAll()
    const roleOfUser= userRole==='GIVER' ? false : true
    const payload = {
      userId,
      assetLookupCode: 'VEH',
      receiverFlag:	roleOfUser,
      assetIssuerName,
      assetIdentificationNumber,
      // contacts: refPickNominee.current.returnDetails()
    }
    console.log(payload)
    //if(validate(payload))
    getId(vehicle) ? updateOtherAssetById(vehicle.id,{id:vehicle.id ,...payload})
    .then(response => {
      success('Record Updated Successfully');
        dispatchEditOtherAsset(response.data)
        onDone()
    }).catch(err => {
      error(err)
    }) : 
    createOtherAsset(payload)
    .then(response => {
      success('Record Created Successfully');
      dispatchAddOtherAsset(response.data);
      onDone()
        // dispatchSetAllContactList(response.data);
    })
    .catch(err => {
      error(err)
    })
}
  return <CollapseSection  mainTitle={'Enter ' +string.forms.vehicle.vehicleFormTitle} >
    <section className='section_form'>
      <div>
        <HeadBar title={string.forms.commonFormFields.nameField} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.vehicle.name} isBigger bind={bindName} />
      </div>
      <div>
        <HeadBar title={string.forms.vehicle.vehicleNo} subtitle={''} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.vehicle.vehicleNoPlaceHolder} isBigger bind={bindId} />
      </div>
    </section>
    <ContainerPosition>
            <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
            <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
        </ContainerPosition>
  </CollapseSection>
}
export const Vehicleform = forwardRef(Vehiclefrm);

export function SavedVehicles ({ data, dispatchDeleteOtherAsset, onEdit, dispalyActions, onShare, datashare, dataShareByMe, accessLevelArray, toggle }) {
  const { success, error } = useQuaishToasts()
  const deleteVeh = (id) =>{
    if(window.confirm("Delete?\n Are you sure you want to delete this record?")){
        deleteOtherAsset(id)
        .then(response => {
          success('Record Deleted Successfully');
          dispatchDeleteOtherAsset(id);

        })
        .catch(err => {
          error(err)
        })
    }
  }

  const row = data.map((e) => {
    return(
      
    <TableContainer>
      <CenterAlignRow>
        <AlignColumn label="Vehicle Name" text={e.assetIssuerName} />
        <AlignColumn label="Vehicle No." text={e.assetIdentificationNumber} />
        {datashare === 'yes' ? <AlignColumn label="Shared with" text={_.isEmpty(dataShareByMe[e.id][0]) ? null : dataShareByMe[e.id][0].map((f,index)=>(<span style={{cursor:'pointer', color: '#007bff'}} onClick={()=>toggle(dataShareByMe[e.id][1][index])}><span style={{textDecoration:'underline'}}>{f}</span><span>{index !== (dataShareByMe[e.id][0].length) -1 ? ' , ' : ''}</span></span>))} />: null}
        {datashare === 'shareby' ? <AlignColumn label="Shared By" text={dataShareByMe[e.id]} />: null}
       {dispalyActions === 'no' || datashare === 'yes' || (datashare === 'shareby' && accessLevelArray[e.id]==='READ')  ? null :
        <AlignColumn label="Action" text={<ActionItem onEdit={()=>onEdit(e)} onDelete={()=>deleteVeh(e.id)} onShare={() => onShare(e.id)} datashare={datashare}/>} />}
      </CenterAlignRow>
    </TableContainer>
  )});
  return (
    data.length === 0 ? null :
    <div>
    <CollapseSection isHidden={data.length === 0} mainTitle={'Motor Vehicles'} onShare={() => onShare('multicreate')}  collapsed={false} isCollapsable={true} customStyle={true}  datashareuser={datashare}  datashare={'yes'}>
      {row}
    </CollapseSection>
     {dispalyActions === 'no' || datashare === 'yes' || datashare === 'shareby' ? <br/> : null }
     </div>
  );
};

 const mapStateToProps = (state) => ({
  vehicleOtherAssetList: getStoreOtherAssetList(state).filter(fa=> fa.assetLookupCode === 'VEH'),
  userId: getStoreUserId(state),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchAddOtherAsset: finAsset => dispatch(addOtherAsset(finAsset)),
  dispatchEditOtherAsset:finAsset=>dispatch(editOtherAsset(finAsset)),
  dispatchDeleteOtherAsset:finAsset=>dispatch(deleteOtherAssetFromList(finAsset)),
})
 export default connect(mapStateToProps, mapDispatchToProps)(Vehicle);
