import axios from "axios";
import { SERVER } from "../constant/ServerConst";

export let ServerService = axios.create({
  baseURL: SERVER,
  headers: {
    "Content-Type": "application/json"
  }
});

export function Get(url) {
    return ServerService({
      method: "GET",
      url
    });
  }
  export function Delete(url, data) {
    console.log(JSON.stringify(data))
    return ServerService({
      method: "DELETE",
      url,
      data
    });
  }
  export function Post(url, data) {
    console.log(JSON.stringify(data))
    return ServerService({
      method: "Post",
      url,
      data
    });
  }
  export function Patch(url, data) {
    console.log(JSON.stringify(data))
    return ServerService({
      method: "Patch",
      url,
      data
    });
  }
  