import React, { useState, useEffect } from 'react'
import { Nav, Navbar, Form } from 'react-bootstrap'
import { useDispatch, connect } from 'react-redux'
import { useHistory, NavLink } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import '../css/dashboard.css'
import '../css/bootstrap.min.css'
import { SignOut } from '../auth/SignOut'
import { getStoreUserId, getStoreUserRole } from '../selectors/userSelectors'
import string from '../constant/en.json'
import { getUserRolesList } from '../actions/userRolesAction'
import Digilocker from '../dashboard/forms/digilocker/Digilocker'
import '../home/component/layout/Header.css'
import AboutDropdown from '../home/component/layout/AboutDropdown'
import ServicesDropdown from '../home/component/layout/ServicesDropdown'
import LogoutDropdown from '../home/component/layout/LogoutDropdown'
import DashboardMobileDropdown from '../home/component/layout/DashboardMobileDropdown'

export const HeaderBar = ({ toggleMenu, isVisible, userId, userRole }) => {
  const history = useHistory()
  const aboutPage = () =>{
    history.push('/about')
  }
  const servicePage = () =>{
    history.push('/services')
  }
  const dispatch = useDispatch()
  return (
    <div
      className='sticky-top'
      style={{ background: '#1b2e4a', padding: 0, flex: 1 }}
    >
      <Navbar
        expand='lg'
        style={{ background: '#1b2e4a', padding: 0, flex: 1 }}
      >
        <Navbar.Brand>
          <img
            style={{ width: 180, marginLeft: 20, cursor: 'pointer' }}
            src={require('../assets/img/logo.png')}
            alt='logo'
            onClick={() => {
              // SignOut(history, dispatch)
            }}
          />
        </Navbar.Brand>
        <span className='res-hide-full'>
          <i
            onClick={() => toggleMenu(!isVisible)}
            className='fa fa-bars'
            style={{
              fontSize: 22,
              color: '#fff',
              marginRight: 10,
              position: 'absolute',
              right: '70px',
              cursor: 'pointer',
              top: 15,
            }}
            aria-hidden='true'
          ></i>
        </span>
        <div className='res-hide-full'>
         <DashboardMobileDropdown history={history} dispatch={dispatch}/>
        </div>
        <Navbar.Collapse>
          <Nav className='mr-auto' />
          <Form inline style={{ marginLeft: 20, marginRight: 100 }}>

          { <Navbar.Text style={{ marginRight: 20, marginLeft: 20 }}>
              <AboutDropdown content="about" aboutpage={aboutPage}/>
            </Navbar.Text>}
            <Navbar.Text style={{ color: '#fff', marginLeft: 20, marginRight: 20 }}>
              |
            </Navbar.Text>
            { <Navbar.Text style={{ marginRight: 20, marginLeft: 20 }}>
              <ServicesDropdown content="services" servicepage={servicePage}/>
            </Navbar.Text>}

            <Navbar.Text style={{ color: '#fff', marginLeft: 20, marginRight: 20 }}>
              |
            </Navbar.Text>
            <Navbar.Text style={{ color: '#fff', marginLeft:20,marginRight: 25 }}>
            <Link to='/security' style={{ color: '#fff', marginRight: 20 }}>
              Security
            </Link>
            </Navbar.Text>
            <Navbar.Text style={{ color: '#fff', marginRight: 20 }}>
              |
            </Navbar.Text>
            <Navbar.Text style={{ color: '#fff', marginLeft:20, marginRight: 20 }}>
            <Link to='/faq' style={{ color: '#fff', marginRight: 20 }}>
              Pricing{' '}
            </Link>
            </Navbar.Text>



            <Navbar.Text style={{ color: '#fff', marginRight: 20 }}>
              |
            </Navbar.Text>
            <Navbar.Text style={{ color: '#fff', marginLeft:20,marginRight: 25 }}>
            <Link to='/contact' style={{ color: '#fff', marginRight: 20 }}>
              Contact{' '}
            </Link>
            </Navbar.Text>
           
            <Navbar.Text>
            <LogoutDropdown history={history} dispatch={dispatch}/>
            <div className='res-hide-full'>
            <div class="dropdown">
                      <span style={{color:'white'}}>
                      <img
                      src='../img/banner/profile.png'
                      width='40'
                      height='38'
                      alt='profile'
                      className='rounded-circle '
                    />
                   </span>                       
                  <div
                    className='dropdown-menu '
                    style={{ left: '-150%' }}
                    aria-labelledby='navbarDropdownMenuLink'
                  >
                    <span className='res-hide-full'>
                      <Navbar.Text style={{ color: '#fff', marginRight: 20 }}>
                        |
                      </Navbar.Text>
                      <Link
                        to='/profile'
                        style={{ color: '#fff', marginRight: 20 }}
                      >
                        My Account
                      </Link>
                      <Navbar.Text style={{ color: '#fff', marginRight: 20 }}>
                        |
                      </Navbar.Text>
                      <Link
                        to='/myorders'
                        style={{ color: '#fff', marginRight: 20 }}
                      >
                        My Orders
                      </Link>
                      <Navbar.Text style={{ color: '#fff', marginRight: 20 }}>
                        |
                      </Navbar.Text>
                      <Navbar.Text
                        onClick={() => SignOut(history, dispatch)}
                        style={{ color: '#fff', marginRight: 20 }}
                      >
                        Log Out
                      </Navbar.Text>
                    </span>
                  </div>
                    </div>
                    </div>
            </Navbar.Text>
            
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

const Dashboard = ({ children, userRoleListData, userId, userRole }) => {
  const [toggle, setToggle] = useState(false)
  /*let [activeLink, setNav] = useState('Personal')
  const cx = (e, disabled) => {
    let value = 'menu_item'
    if (e === activeLink) value = 'menu_item_active'
    if (disabled) value = `${value} disabled`
    return value
  }*/
  return (
    <div>
      <HeaderBar toggleMenu={setToggle} isVisible={toggle} userId={userId} userRole={userRole}/>
      <SideMenu
        container={children}
        isVisible={toggle}
        toggleMenu={setToggle}
        userRoleListData={userRoleListData}
      />
      <FooterTag />
    </div>
  )
}
export const FooterTag = () => (
  <footer className='footer-distributed'>
    <div className='footer-left'>
      {/* <p className="footer-links">
      <a href="#">Home</a>·<a href="#">Blog</a>·<a href="#">Pricing</a>·<a href="#">About</a>·<a href="#">Faq</a>·<a href="#">Contact</a>
    </p> */}

      <p className='footer-company-name'>Quaish Bizsense Pvt. Ltd.  </p>
      <p className='footer-company-name'>All Copyright Reserved, 2020</p>
    </div>

    <div className='footer-center'>
      <div>
        <i className='fa fa-map-marker'></i>
        <p>
          <span>
            C-1021, Windmills of the Mind, Whitefield<br></br>Bengaluru, India
          </span>
        </p>
      </div>

      <div>
        <i className='fa fa-phone'></i>
        <p>
          {' '}
          <span><a href="tel:+919902435733">(+91) 99024-35733</a></span>
        </p>
      </div>

      <div>
        <i className='fa fa-envelope'></i>
        <p>
          <a href='mailto:info@easyinherit.in'>info@easyinherit.in</a>
        </p>
      </div>
    </div>

    <div className='footer-right'>
      <p className='footer-company-about'>
        <span> About the company</span>
        {string.home.footer.text}
      </p>

      <div className='footer-icons'>
        <a href='#'>
          <i className='fa fa-facebook'></i>
        </a>
        <a href='#'>
          <i className='fa fa-twitter'></i>
        </a>
        <a href='#'>
          <i className='fa fa-linkedin'></i>
        </a>
      </div>
    </div>
  </footer>
)
const SideMenu = ({ container, isVisible, toggleMenu, userRoleListData }) => {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    if (window.innerWidth <= 990) setIsMobile(true)
  })
  const onClickItem = () => {
    if (isMobile) {
      toggleMenu(!isVisible)
    }
  }
  return (
    <div style={{ paddingBottom: 20 }}>
      <div className={`d-flex ${isVisible ? 'toggled' : ''}`} id='wrapper'>
        <div className='bg-light border-right' id='sidebar-wrapper'>
          <div
            className='list-group list-group-flush'
            style={{ marginTop: 10, fontSize: 13 }}
          >
            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/dashboard'
            >
              <span
                style={{ paddingTop: 10, fontSize: 15, fontWeight: 'bold' }}
              >
                My Dashboard{''}
              </span>
            </NavLink>

            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/personal'
            >
              Personal Info and ID
            </NavLink>

            {userRoleListData.roleLookupCode === 'RECEIVER' ? 
            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/helplist'
            >
              My Help List
            </NavLink>
            :
            null
            }

          {userRoleListData.roleLookupCode === 'RECEIVER' ? 
            <Digilocker uploadType='menubar'/>
            :
            null
            }

            {userRoleListData.roleLookupCode === 'RECEIVER' ? 

              <span className='list-group-item list-group-item-action bg-quaish' style={{ paddingTop: 1,fontSize: 15, fontWeight: 'bold',color: '#fff' }}>
                My Inheritance List{''}
              </span>
            :
            null
            }


            {userRoleListData.roleLookupCode === 'RECEIVER' ? null
            
            : <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/family'
            >
              Family and Loved Ones
            </NavLink>
            }
             {userRoleListData.roleLookupCode === 'RECEIVER' ? null :
            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/contacts'
            >
              My Other Contacts
            </NavLink>
             }
            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/insurance'
            >
              Insurance and Health Plans
            </NavLink>
            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/immovableAsset'
            >
              Home and Property
            </NavLink>
            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/assets/financial'
            >
              Financial Investments
            </NavLink>
            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/assets/digital&other'
            >
              Digital and Other Assets
            </NavLink>
            {userRoleListData.roleLookupCode === 'RECEIVER' ? null : 
            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/beneficiary'
            >
              Selecting my Beneficiaries
            </NavLink>
            }
             {userRoleListData.roleLookupCode === 'RECEIVER' ? null :
            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/assign'
            >
              Distributing my Assets
            </NavLink>
             }
              {userRoleListData.roleLookupCode === 'RECEIVER' ? null :
            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/executor'
            >
              Choosing Executors
            </NavLink>
            }
            {/* <NavLink onClick={onClickItem} activeStyle={{color:'rgb(0, 123, 255)'}} className="list-group-item list-group-item-action bg-light" to="/assets">
              Assets
            </NavLink> */}

            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/liabilities'
            >
              Knowing my Liabilities
            </NavLink>
            {userRoleListData.roleLookupCode === 'RECEIVER' ? null : 
            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/disinheritence'
            >
              Disinheritence
            </NavLink>
            }
                        
            <NavLink
            onClick={onClickItem}
            activeStyle={{ color: 'rgb(0, 123, 255)' }}
            className='list-group-item list-group-item-action bg-light'
            to='/summary'
          >
            EasyInherit Portfolio
          </NavLink>

          {userRoleListData.roleLookupCode === 'GIVER' ? 
            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/bequethmentSummary'
            >
              Bequethment Summary
            </NavLink>
            :
            null

            }

            {userRoleListData.roleLookupCode === 'RECEIVER' ? null
            :
            <NavLink
              onClick={onClickItem}
              activeStyle={{ color: 'rgb(0, 123, 255)' }}
              className='list-group-item list-group-item-action bg-light'
              to='/helplist'
            >
              My Help List
            </NavLink>
            }

            {userRoleListData.roleLookupCode === 'RECEIVER' ? null

            :
            <Digilocker uploadType='menubar'/>
          }
          {userRoleListData.roleLookupCode === 'RECEIVER' ? null
            :
            null
          }
            {userRoleListData.roleLookupCode === 'RECEIVER' ? null
            :
null
            }
            <div className='list-group-item list-group-item-action bg-light'></div>
          </div>
        </div>
        <div id='page-content-wrapper'>
          <div className='container-fluid' style={{ marginTop: 10 }}>
            {container}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userRoleListData: getUserRolesList(state),
  userId: getStoreUserId(state),
  userRole:getStoreUserRole(state),
})

export default connect(mapStateToProps)(Dashboard)