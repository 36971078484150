import React, { forwardRef, useState } from 'react';

const RadioBtn = ({ key, value, display, onChange, checked }) => {
  return <div className='form-check form-check-inline'>
    <label className='form-check-label'>
      <input className='form-check-input' type='radio' checked={checked===value}  id= {key} value={value} onChange={onChange} />
      {display}
    </label>
  </div>
};

export const RadioBtnForm = ({ options, defaultValue, onChange }, ref) => {
  const [checkVal, setCheckVal] = useState(defaultValue);
  const callOnChange = e => {
    onChange(e.target.value);
    setCheckVal(e.target.value)
  }
  return <div className='col-xl-4 col-md-6 col-lg-4'>
    {options.map(({ value, display }, i) => (
      <RadioBtn key={display + i} checked={checkVal} value={value} display={display} onChange={callOnChange}/>
    ))}
  </div>
};
const RadioBtnGrp = forwardRef(RadioBtnForm);
export default RadioBtnGrp;