import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import CalenderSlots from '../../../dashboard/components/CalenderSlots';

const ServiceProductCard = ({image,title,link,text, calenderSlot}) => {
  let [calender, setCalender] = useState(false)
  let toggleCalender= ()=>setCalender(!calender)
  return (
    <div className='col-xl-3 col-md-3 col-lg-3'>
        <div style={{ textAlign: 'left', paddingTop:'20px'}}>
                <h5 style={{ fontSize: 25 }}><b>{title}</b></h5>
                <br></br>
                {/*<img  style={{ width: 170, height: 100 }} src={require(`../../../assets/img/card/${image}`)} alt='' />*/}
                <p style={{ fontSize: 15}}>{text}</p>
                <br></br> 
        </div>
        <div className='video_service_btn1' style={{position: 'absolute', bottom: '0px'}} align='left'>
            {calenderSlot ?
            <Button variant='outline-primary' size= 'md' type= 'button' onClick={toggleCalender}>Try Now</Button> 
            :
            <Button variant='outline-primary' size= 'md' type= 'button' href={link}>Try Now</Button> 
            }
        </div>
        <br></br> <br></br> 
        {calender === true ?
        <CalenderSlots freeConsult={'true'} toggleFreeConsultModal={toggleCalender} consultWho = {'Our Legal Experts'} starProductCard/>
        : null }
    </div>
  );
};

export default ServiceProductCard;
