import _ from 'lodash';
import relationOptions from "../resources/relationships.json";
import cType from "../resources/contactType.json";

export const getValue = (ref, key) => {
    const data = ref.current.returnDetails();
    return key ? data[key] : data;
};
export const getObjMapped = (keys, obj) => _.zipObject(keys, Object.values(obj));
export const getMapping = (keys, ref) => getObjMapped(keys, getValue(ref));

export const getCodeValueMap = (arr) => {
    const newArr = arr.map(a => ({ 'code': a, 'value': a }))
    return newArr
}

export const getRelation = (e) => {
    let relation = _.find(relationOptions, { code: e.relationship });
    let value = relation ? relation.value : ''
    return value;
}

export const getContactType = (e) => {
    let contactType = _.find(cType, { code: e.contactLookupCode });
    let value = contactType ? contactType.value : ''
    return value;
}
export const getAssetType = (e) => {
    const assetTypeList=[
        {
            "lookupKey": "liab_type",
            "code": "FD",
            "value": "Fixed Deposits"
        },
        {
            "lookupKey": "liab_type",
            "code": "SHR",
            "value": "Physical Shares"
        },
        {
            "lookupKey": "liab_type",
            "code": "DEM",
            "value": "Demat Account"
        },
        {
            "lookupKey": "liab_type",
            "code": "GOV",
            "value": "Government saving schemes"
        },{
            "lookupKey": "liab_type",
            "code": "BNK",
            "value": "Bank Accounts"
        },{
            "lookupKey": "liab_type",
            "code": "PMS",
            "value": "Portfolio Management Services"
        },{
            "lookupKey": "liab_type",
            "code": "INS",
            "value": "Insurance Policy"
        },{
            "lookupKey": "liab_type",
            "code": "MF",
            "value": "Mutual Funds Units[Physical]"
        },{
            "lookupKey": "liab_type",
            "code": "BON",
            "value": "Bonds"
        },{
            "lookupKey": "liab_type",
            "code": "PF",
            "value": "Provident Fund"
        },{
            "lookupKey": "liab_type",
            "code": "GRA",
            "value": "Gratuity"
        },{
            "lookupKey": "liab_type",
            "code": "PROP",
            "value": "Immovable Properties"
        },{
            "lookupKey": "liab_type",
            "code": "SOC",
            "value": "Social Media/Email Account"
        },{
            "lookupKey": "liab_type",
            "code": "WEB",
            "value": "Websites"
        },{
            "lookupKey": "liab_type",
            "code": "ARTI",
            "value": "Artefacts"
        },{
            "lookupKey": "liab_type",
            "code": "JEWL",
            "value": "Jewellery"
        },{
            "lookupKey": "liab_type",
            "code": "ART",
            "value": "Art"
        },{
            "lookupKey": "liab_type",
            "code": "LOCK",
            "value": "Bank Lockers"
        },{
            "lookupKey": "liab_type",
            "code": "VEH",
            "value": "Vehicles"
        },{
            "lookupKey": "liab_type",
            "code": "PERS",
            "value": "Personal Effects"
        },
    ]
    let assetType = _.find(assetTypeList, { code: e });
    let value = assetType ? assetType.value : ''
    return value;
}
export const getLiabType = (e) => {
    const lType = [{
        "lookupKey": "liab_type",
        "code": "LOAN",
        "value": "Loans"
    },
    {
        "lookupKey": "liab_type",
        "code": "MORT",
        "value": "Mortgage"
    },
    {
        "lookupKey": "liab_type",
        "code": "HYPO",
        "value": "Hypothecation"
    },
    {
        "lookupKey": "liab_type",
        "code": "GUAR",
        "value": "Guarantor"
    },
    {
        "lookupKey": "liab_type",
        "code": "OTH_LIAB",
        "value": "Others"
    }]
    let liabType = _.find(lType, { code: e.liabLookupCode });
    let value = liabType ? liabType.value : ''
    return value;
}
export const getContactFullName = (e) => {
    let name = e.title + e.firstName + " " + e.middleName + " " + e.lastName;
    name = name.replace(/null/g, '')
    return name.replace(/ +/g, " ")
}

export const getId = data => _.get(data, ['id']);

export const removeArrayFromArrayById = (arr, a) => _.remove(arr, c => _.findIndex(a, { id: c.id }) === -1);

export const batchState = fn => Promise.resolve().then(fn());
export const isEmpty = data => _.isEmpty(data);
export const getRelationShip = d => _.get(d, ['relationship']);
export const getKeyValue = (d, k) => _.get(d, [k])

export const getSplitName = (name) => {
    let index = name.indexOf(' ');
    name = index === -1 ? [name] : [name.slice(0, index), name.slice(index + 1)];
    let firstName = name[0];
    let lastName = name.length > 1 ? name[1] : '';
    return { firstName, lastName }
}

export const isFamily = obj => {
    const relation = _.get(obj, ['relationship'])
    return relation === 'FATHER' || relation === 'MOTHER' || relation === 'SPOUSE' || relation === 'CHILD'
}