import initialState from "./initialState";
import _ from 'lodash';
import { ADD_NONDISINHERIT_LIST, DELETE_DISINHERIT } from "../actions/nonDisinheritActions";
export default (state = initialState.nonDisInherit, action) => {
    switch (action.type) {

        case ADD_NONDISINHERIT_LIST:
            return  _.uniqBy(state.concat(action.nonDisInherit), 'id');
        
        case DELETE_DISINHERIT:
            return _.uniqBy(state.filter(con=> con.id !== action.contactId), 'id');

        default:
            return state;
    }
}