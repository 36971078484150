import React, { useImperativeHandle, forwardRef, useState } from 'react';
import { useInput } from '../../hooks/useInput';
import HeadBar from '../../components/HeaderBar';
import Input from '../../components/Input';
import CheckBox from '../../components/CheckBox';
import states from '../../resources/states.json'
import DropDown from '../../components/DropDown';
import string from '../../constant/en.json';

const AddressFrm = ({ title, checkbox, onCheck, first = string.forms.commonFormFields.houseName, defaultValue = { houseName: '', street: '', state: '', city: '', postalCode: '', country: '' } }, ref) => {
  const { value: houseName, bind: bindHN, setValue: setH } = useInput(defaultValue.houseName);
  const { value: street, bind: bindStreet, setValue: setSt } = useInput(defaultValue.street);
  const { value: state, bind: bindState, setValue: setS } = useInput(defaultValue.state);
  const { value: city, bind: bindCity, setValue: setC } = useInput(defaultValue.city);
  const { value: postalCode, bind: bindPC, setValue: setP } = useInput(defaultValue.postalCode);
  const { value: country, bind: bindCountry, setValue: setCon } = useInput(defaultValue.country);
  const [disabled, setDisable] = useState(defaultValue.sameAddressFlag);
  useImperativeHandle(ref, () => ({
    returnDetails() { return { houseName, street, state, city, country, postalCode } },
    setAddress(obj) {
      setH(obj.houseName);
      setSt(obj.street);
      setS(obj.state);
      setC(obj.city);
      setP(obj.postalCode);
      setCon(obj.country);
      setDisable(true)
    },
    setNotSame() {
      setDisable(false)
    }
  }));
  return <section className='section_form'>
    <HeadBar title={title} />
    <div style={{marginBottom:'5px'}}>
    {checkbox ? <CheckBox defaultValue={defaultValue.sameAddressFlag} title={string.forms.commonFormFields.sameAddressCheckBox} onChange={onCheck} /> : null}
    </div>
    <div className='row'>
      <Input type='text' placeholder={first} isBigger bind={bindHN} disabled={disabled} />
      <Input type='text' placeholder={string.forms.commonFormFields.street} isBigger bind={bindStreet} disabled={disabled} />
      <DropDown placeholder={string.forms.commonFormFields.state} options={states} bind={bindState} disabled={disabled} />
    </div>
    <div className='row'>
      <Input type='text' placeholder={string.forms.commonFormFields.city} isBigger bind={bindCity} disabled={disabled} />
      <Input type='text' placeholder={string.forms.commonFormFields.country} isBigger bind={bindCountry} disabled={disabled} />
      <Input type='text' placeholder={string.forms.commonFormFields.postalCode} isBigger bind={bindPC} disabled={disabled} />
    </div>
  </section>
};
const Address = forwardRef(AddressFrm);
export default Address;