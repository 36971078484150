import React, { forwardRef, useImperativeHandle, useRef } from "react";
import CheckBox from "../../components/CheckBox";
import string from "../../constant/en.json";
import { getMapping, getObjMapped, getValue } from "../../utils/commonUtil";
import Address from "./Address";
import AgeAndDOB from "./AgeAndDOB";
import Identification from "./Identification";
import NameAndGender from "./NameAndGender";
import RelationshipForm from "./Relationship";
import ReligionNationality from "./ReligionNationality";

const IndividualForm = ({ onUploadOpen, defaultValue = { idProofNumber: '', idProofType: '', firstName: '', middleName: '', lastName: '', title: '', deceased: false, gender: '', religion: '', nationality: '', relationship: '', homeAddress: '' } }, ref) => {
  const { gender, firstName, lastName, middleName, title, idProofNumber, idProofType, religion, nationality, relationship, dob, age, minor, homeAddress } = defaultValue;
  const refAddress = useRef();
  const refReligionNationality = useRef();
  const refId = useRef();
  const refAgeDOB = useRef();
  const refNameAndGender = useRef();
  const refRelationshipForm = useRef();
  const refDeceased = useRef();
  useImperativeHandle(ref, () => ({
    returnDetails() {
      let details = {
        deceased: getValue(refDeceased),
        ...getValue(refAgeDOB),
        gender: refNameAndGender.current.returnDetails().gender,
        ...refRelationshipForm.current.returnDetails(),
        ...getValue(refReligionNationality),
        ...getValue(refNameAndGender).name,
        ...getMapping(['idProofNumber', 'idProofType'], refId),
        ...getObjMapped(
          ['contHomeAddress1', 'contHomeAddress2', 'contHomeAddressState', 'contHomeAddressCity', 'contHomeAddressCountry', 'contHomeAddressPin'],
          refAddress.current.returnDetails()
        ),
      };
      return { ...details }
    },
  }));
  return (
    <React.Fragment>
      <NameAndGender ref={refNameAndGender} defaultValue={{ gender, name: { title, firstName, middleName, lastName } }} />
      <AgeAndDOB defaultValue={{ dob, age, minor }} ref={refAgeDOB} />
      <CheckBox title={string.forms.commonFormFields.deceased} defaultValue={defaultValue.deceased} ref={refDeceased} />
      <RelationshipForm defaultValue={relationship} ref={refRelationshipForm} />
      <ReligionNationality defaultValue={{ religion, nationality }} ref={refReligionNationality} />
      <Identification defaultValue={{ idProofNumber, idProofType }} ref={refId} onOpen={onUploadOpen} />
      <Address defaultValue={{ ...homeAddress }} title={string.forms.commonFormFields.houseAddress} ref={refAddress} />
    </React.Fragment>
  );
}

export const Individual = forwardRef(IndividualForm);
