import _ from 'lodash';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { addContact, editContact } from '../../../actions/contactActions';
import CollapseSection from '../../../components/CollapsibleSection';
import { ContainerPosition, CustomButtom } from '../../../components/CustomElements';
import string from '../../../constant/en.json';
import { getId, getObjMapped, getValue } from '../../../utils/commonUtil';
import Address from '../../components/Address';
import ContactDeets from '../../components/ContactDetails';
import EmailDetails from '../../components/EmailDetails';
import { createContact, updateContactById } from './ContactService';
import ContactTypeAndDetails from './ContactTypeDetails';
import { updateContactBeneficiary } from '../../../actions/beneficiaryActions';
import { useQuaishToasts } from '../../../actions/alertAction';

const ContactForm = ({ onCancel, contact, userId, dispatchAddContact, dispatchEditContact, onDone }) => {
  const refContactDeets = useRef();
  const refEmailDeets = useRef();
  const refOfficeAddress = useRef();
  const refTypeDetails = useRef()
  const { success, error, removeAll } = useQuaishToasts()
  const submit = () => {
    removeAll()
    const payload = {
      userId,
      ...refTypeDetails.current.returnDetails(),
      ...getValue(refContactDeets),
      ...getValue(refEmailDeets),
      ...getObjMapped(
        ['contOfficeAddress1', 'contOfficeAddress2', 'contOfficeAddressState', 'contOfficeAddressCity', 'contOfficeAddressCountry', 'contOfficeAddressPin'],
        refOfficeAddress.current.returnDetails()
      ),
    }
    console.log(payload)

      getId(contact) ? updateContactById(contact.id, payload)
        .then(response => {
          success('Record Updated Successfully');
          dispatchEditContact(response.data)
          onDone()
        }).catch(err => {
          error(err)
        }) : createContact(payload)
          .then(response => {
            success('Record Created Successfully');
            dispatchAddContact(response.data);
            onDone()
            // dispatchSetAllContactList(response.data);
          })
          .catch(err => {
            error(err)
          })
  }
  return <div><CollapseSection isHidden={false} mainTitle={string.forms.contactSpecific.contactFormTitle} subtitle={string.forms.commonFormFields.mandatoryFields}>
    <ContactTypeAndDetails defaultValue={{ ...contact }} ref={refTypeDetails} />
    <Address defaultValue={_.get(contact, 'officeAddress')} title={string.forms.commonFormFields.officeAddress} ref={refOfficeAddress} first={string.forms.commonFormFields.officeName} />
    <ContactDeets defaultValue={{ ...contact }} ref={refContactDeets} />
    <EmailDetails defaultValue={{ ...contact }} ref={refEmailDeets} />
    <ContainerPosition>
      <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
      {/* <CustomButtom isUpload event={() => docsRef.current.submit()} title={"Upload"} /> */}
      <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
    </ContainerPosition>
  </CollapseSection></div >
}
const mapDispatchToProps = () => dispatch => ({
  dispatchAddContact: contact => dispatch(addContact(contact)),
  dispatchEditContact: contact => {
    dispatch(editContact(contact));
    dispatch(updateContactBeneficiary(contact))
  },
  // dispatchSetAllContactList: contact => dispatch(setAllContactData(contact)),
})
export default connect(null, mapDispatchToProps)(ContactForm);