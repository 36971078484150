import _ from 'lodash';
import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getStoreContactList, editContact, setContactData } from '../../../actions/contactActions';
import { getStoreDisinheritList } from '../../../actions/disinheritActions';
import { getStoreFamily, editFamily, setFamilyData } from '../../../actions/familyActions';
import CollapseSection from '../../../components/CollapsibleSection';
import {
  SingleActionItem, AlignColumn, CenterAlignRow,
  ContainerPosition,
  CustomButtom, HelpSection,
  HighlightedText, List,
  TableContainer
} from '../../../components/CustomElements';
import DropDown from '../../../components/DropDown';
import HeadBar from '../../../components/HeaderBar';
import TextArea from '../../../components/TextArea';
import string from '../../../constant/en.json';
import { useInput } from '../../../hooks/useInput';
import { getStoreUserId } from '../../../selectors/userSelectors';
import { getCodeValueMap, getContactFullName, getRelation, isFamily } from '../../../utils/commonUtil';
import { updateContactById, getContactListFromServer } from '../contacts/ContactService';
import { deleteDisinheritence } from './disinheritService';
import PickContactTable from '../../components/PickContactTable';
import { getFamilyListFromServer } from '../family/familyService';
import HelperModal from '../../helper/HelperModal';
import { getStoreBeneficiaryList } from '../../../actions/beneficiaryActions';
import { useQuaishToasts } from '../../../actions/alertAction';

const Disinheritence = ({ userId, dispatchDeleteDisinherit,dispatchSetFamilyList, dispatchSetContactList, disinheritList, dispatchSetDisinheritList }) => {
  const { success, warn, error, removeAll } = useQuaishToasts()
  useEffect(() => {
    removeAll()
    getContactListFromServer(userId)
      .then(response => {
        dispatchSetContactList(response.data)
        // loading(false)
      })
      .catch(err => {
        error(err);
        // loading(false);
      });
      getFamilyListFromServer(userId)
      .then(response => {
        dispatchSetFamilyList(response.data)
      })
      .catch(err => {
        error(err);
      })
  }, [])
  const [isFormVisible, formVisibility] = useState(true);
  const setToggleForm = () => formVisibility(!isFormVisible);
  const refPickDisinherit = useRef();
  const refReason = useRef();
  const addDisinherit = (body, relationship) => {
    formVisibility(true)
    dispatchSetDisinheritList(body)
  }
  const validate=(payload,id)=>{
    const ev=[]
    let emptyData = []
    for (var key in payload) {
      switch(key){
          case 'disinheritReason':
            _.isEmpty(payload[key]) ? ev.push(string.forms.disinheritenceSpecific.emptyDisinheritenceReason):payload[key].length > 255 ? ev.push(string.forms.disinheritenceSpecific.disinheritReason) : emptyData.push(null)
            break
          default:
                break
      } 
  }
  if(id === undefined){
    ev.push(string.forms.disinheritenceSpecific.emptySelect)
  }
    if(ev.length>0){
      warn(ev)
      return false
    }
    else return true
  }
  const submit = () => {
    removeAll()
    let id = _.head(refPickDisinherit.current.returnDetails());
    let body = {
      disinherit: true,
      ...refReason.current.returnDetails(),
    };
    if(validate(body,id))
    updateContactById(id, body)
      .then(response => {
        success("Successfully Updated")
        addDisinherit(response.data);
      })
      .catch(err => {
        error(err)
      })
  };
  return (
    <div>
      <HelpSection heading="HELP TIPS!">
        <List>{string.forms.disinheritenceSpecific.disinheritHelpTipHeader}</List>
        <List>
          <HighlightedText>{string.forms.disinheritenceSpecific.deleteBeneficiaryFirst}</HighlightedText>
        </List>
      </HelpSection>
      {/* Saved Disinherits */}
      <div style={{ marginBottom: 20, display: 'block' }}>
        {disinheritList.length > 0 ? <div><SavedDisinherited dispatchDeleteDisinherit={dispatchDeleteDisinherit} data={disinheritList} toggleForm={setToggleForm} />
        </div> : null}
        <ContainerPosition>
          <CustomButtom event={setToggleForm} title={string.forms.executorSpecific.addDisinhert} />
        </ContainerPosition>
      </div>
      {/* ---- */}
      <CollapseSection isHidden={isFormVisible} mainTitle={string.forms.disinheritenceSpecific.disinheritenceFormTitle} subtitle={string.forms.commonFormFields.mandatoryFields} >
        <PickDisinheritTable ref={refPickDisinherit} />
        <DisinheritanceReason ref={refReason} />
        <ContainerPosition>
          <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
          {disinheritList.length > 0 ? <CustomButtom event={setToggleForm} title={string.forms.commonFormFields.cancel} /> : null}
        </ContainerPosition>
      </CollapseSection>
    </div>
  );
};
const DisinheritanceReasonForm = (props, ref) => {
  const { value, bind } = useInput('');
  useImperativeHandle(ref, () => ({
    returnDetails() {
      return {
        disinheritReason: value
      };
    },
  }));
  return (
    <div>
      <HeadBar title={string.forms.disinheritenceSpecific.disinheritReasonLabel} />
      <div className="row">
        <TextArea bind={bind} />
      </div>
    </div>
  );
}
const DisinheritanceReason = forwardRef(DisinheritanceReasonForm);

const SavedDisinherited = ({ data, dispatchDeleteDisinherit, toggleForm }) => {
  const { success, error } = useQuaishToasts()
  const deleteDisin = (con) => {
    if (window.confirm("Delete?\n Are you sure you want to delete this record?")) {
      deleteDisinheritence(con.id)
        .then(response => {
          success('Record Deleted Successfully');
          if (data.length === 0 && toggleForm) toggleForm();
          dispatchDeleteDisinherit(con);

        })
        .catch(err => {
          error(err)
        })
    }
  }

  const row = data.map((e) => (
    <TableContainer>
      <CenterAlignRow>
        <AlignColumn label="Name" text={getContactFullName(e)} />
        <AlignColumn label="Relationship" text={getRelation(e)} />
        <AlignColumn label="Reason" text={e.disinheritReason} />
        <AlignColumn label="Action" text={<SingleActionItem onDelete={() => deleteDisin(e)} />} />
      </CenterAlignRow>
    </TableContainer>
  ));
  return (
    <CollapseSection mainTitle={string.forms.executorSpecific.saveDisinherit} collapsed={false} isCollapsable={true} customStyle={true}>
      {row}
    </CollapseSection>
  );
};
const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
  disinheritList: getStoreDisinheritList(state)
});
const mapDispatchToProps = () => dispatch => ({
  dispatchSetFamilyList: contact => dispatch(setFamilyData(contact)),
  dispatchSetContactList: contact => dispatch(setContactData(contact)),
  dispatchSetDisinheritList: contact => {
    if (isFamily(contact))
      dispatch(editFamily(contact))
    else
      dispatch(editContact(contact))
  },
  dispatchDeleteDisinherit: contact => {
    if (isFamily(contact))
      dispatch(editFamily({ ...contact, disinherit: false, disinheritReason: "" }))
    else
      dispatch(editContact({ ...contact, disinherit: false, disinheritReason: "" }))
  },
})
export default connect(mapStateToProps, mapDispatchToProps)(Disinheritence);












const PickDisinherit = ({ contactNonDisList, familyNonDisList }, ref) => {
  let { value: pickDisinherit, bind: bindPickDisinherit } = useInput('Contact');
  const refPickContact = useRef()
  useImperativeHandle(ref, () => ({
    returnDetails() { return refPickContact.current.returnDetails() }
  }));
  return <React.Fragment>
    <div className="row"  style={{marginLeft:'2px'}}>
      <HeadBar title={string.forms.executorSpecific.existingExecutor} />
      <HelperModal helperTextId={"disinheritDropdown"} />
    </div>
    <div className="row">
      <DropDown placeholder={string.forms.commonFormFields.pleaseSelect}
        options={getCodeValueMap(['Contact', 'Family'])}
        bind={bindPickDisinherit}
      />
    </div>
    <PickContactTable isSingle data={pickDisinherit === 'Contact' ? contactNonDisList : familyNonDisList} ref={refPickContact} />
  </React.Fragment>
}
const mapStateToPropsBen = state => ({
  contactNonDisList: getStoreContactList(state).filter(c => !_.get(c, ['disinherit'])).filter(c => _.findIndex(_.flattenDeep(getStoreBeneficiaryList(state).map(b => b.contacts)), { id: _.get(c, ['id']) }) === -1),
  familyNonDisList: getStoreFamily(state).filter(c => !_.get(c, ['disinherit'])).filter(c => _.findIndex(_.flattenDeep(getStoreBeneficiaryList(state).map(b => b.contacts)), { id: _.get(c, ['id']) }) === -1),
})
const PickDisinheritTable = connect(mapStateToPropsBen, null, null, { forwardRef: true })(forwardRef(PickDisinherit));
