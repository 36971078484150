import _ from 'lodash';

export const ADD_CONTACT_LIST = 'ADD_CONTACT_LIST';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const setContactData = (contacts) => {

    return {
        type: ADD_CONTACT_LIST,
        contacts
    }
}
export const deleteContactFromList = (contactId) => {

    return {
        type: DELETE_CONTACT,
        contactId
    }
}

export const addContact = contact => ({ type: CREATE_CONTACT, contact });
export const editContact = contact => ({ type: EDIT_CONTACT, contact });
export const getStoreContactList = state => state.contacts;

export const getContactFromFamOrCon = (familList, contactList, contact) => {
    const familyCon = _.find(familList, { id: _.get(contact, [ 'id']) })
    const con = _.find(contactList, { id: _.get(contact, ['id']) })
    const s = familyCon || con || contact;
    return s
}

