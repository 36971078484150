import initialState from "./initialState";
import _ from 'lodash';
import { ADD_DATASHARED_BYME_CONSTANTS, DELETE_DATASHARED_BYME_CONSTANTS, CREATE_DATASHARED_BYME_CONSTANTS, EDIT_DATASHARED_BYME_CONSTANTS } from "../actions/dataSharedByMeActions";
export default (state = initialState.dataSharedByme, action) => {
    switch (action.type) {
        case ADD_DATASHARED_BYME_CONSTANTS:
            return action.dataSharedByme;
        case DELETE_DATASHARED_BYME_CONSTANTS:
            return state.filter(con => con.id !== action.dataSharedBymeId);
        case CREATE_DATASHARED_BYME_CONSTANTS:
            return state.concat(action.dataShareBymeids)
        case EDIT_DATASHARED_BYME_CONSTANTS:
            const index = _.findIndex(state, { id: action.dataShareBymeids.id });
            state.splice(index, 1, action.dataShareBymeids);
            return [...state];
        default:
            return state;
    }
}