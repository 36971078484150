import React from 'react';
import string from '../../../constant/en.json';

const Footer = () => {
  return (
    <footer className='footer'>
      <div>
        <div className='container' style={{ marginBottom: 10, marginTop: 40 }}>
          <div className='row'>
            <div className='col-xl-4 col-md-4 col-lg-4'>
              <div className='footer_widget'>
                {/* <h3 className='footer_title'>Services</h3> */}<br/><br/>
                <img alt='' style={{ width: 200, height: 39 }} src={require('../../../assets/img/logo2.png')} />
                <p style={{ fontSize: 14 }}>
                  <ul>
                  <li></li>
                  <li>
                  (+91 ) 99024-35733
                  </li>
                  <li>
                    info@easyinherit.in
                  </li>
                  <li>
                    C1021, Windmills Of The Mind, Whitefield,
                  </li>
                  <li>Bangalore 560 066, India</li>
                </ul></p>
              </div>
              <p style={{ fontSize: 12, fontWeight:'bold' }}>Please read our < a href='/t&c'><u>T&C</u></a>, | < a href='/Privacy'><u>Privacy</u></a> | <a href='/Refund'><u>Refund</u></a> policies</p>
            </div>

            <div className='col-xl-7 col-md-7 col-lg-7' style={{ marginTop: 100, borderLeft: '2px solid #ccc' }}>
              <div className='details_main_wrap'>
                <div className='single_details'>
                  <p style={{ fontSize: 14 }}>{string.home.footer.title}</p>
                  <img alt='' width='150' height='44' src='../img/banner/startupIndiaLogo.png'></img>&nbsp;&nbsp;&nbsp;&nbsp;
                  <img alt='' width='120' height='44'src ='../img/banner/digilocker-logo.png'></img>&nbsp;&nbsp;&nbsp;&nbsp;
                  <img alt='' width='200' height='48'src='../img/banner/DPIIT.png'></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='copy-right_text'>
        <div className='container'>
          <div className='footer_border'></div>
          <div className='row'>
            <div className='col-xl-12'>
              <p className='copy_right text-center'>All copyright Quaish Bizsense Pvt. Ltd. 2021</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
