import React, { useState, useEffect } from 'react';

 const CollapseSection = ({ children, mainTitle, subtitle, collapsed, isCollapsable,customStyle,isHidden, showForm, onShare, datashare, datashareuser }) => {
    let [isCollapse, setCollapse] = useState(collapsed);
    let cls = `collapse ${!isCollapse ? `show` : null}`;
    useEffect(()=>{
      setCollapse(collapsed)
    },[collapsed])
    const toggle = () => {
      setCollapse(!isCollapse);
      if(showForm)
        showForm(!isCollapse)
    };
    const icon = () => (!isCollapse ? `fa fa-minus` : `fa fa-plus`);
    if(isHidden) return null;
    return (
      <div className='card'>
        <div className='card-header' id='headingOne' data-toggle='collapse' style={{ cursor: isCollapsable ? 'pointer' : 'default', backgroundColor:'#fff' }} onClick={isCollapsable ? toggle : null}>
          <p className='mb-0'>
            {mainTitle}
            <span
              style={{
                margin: '0 5px',
                fontSize: 12,
                color: '#007bff',
                fontWeight: 400,
              }}
            >
              {datashareuser === 'yes' || datashareuser === 'shareby' || datashareuser==='noShare' ? null :
              datashare === 'yes' ?
              <i className="fa fa-share-alt" aria-hidden="true" onClick={onShare} style={{ cursor: 'pointer', padding: 4 }} />
              : null
            }
              {subtitle}
            </span>
            {isCollapsable ? <i style={{ float: 'right', lineHeight: '28px' }} className={icon()}></i> : null}
          </p>
        </div>
        <div aria-labelledby='headingOne' className={cls}>
          <div className={!customStyle?'card-body':'empty'}>{children}</div>
        </div>
      </div>
    );
  };

  export default CollapseSection;