export const ADD_DATASHARE_BY_CONSTANTS = 'ADD_DATASHARE_BY_CONSTANTS';
export const EDIT_DATASHARE_BY_CONSTANTS = 'EDIT_DATASHARE_BY_CONSTANTS';
export const setDataSharedBy = (dataSharedBy) => {

    return {
        type: ADD_DATASHARE_BY_CONSTANTS,
        dataSharedBy
    }
}

export const editDataSharedBy = dataShareBy => ({ type: EDIT_DATASHARE_BY_CONSTANTS, dataShareBy });
export const getDataSharedByList = state => state.dataSharedBy;