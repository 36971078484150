import Auth from "@aws-amplify/auth";
import { AWS_CONFIG } from '../constant/ServerConst';
import { logOut } from "../actions/appActions";

Auth.configure({ ...AWS_CONFIG.Auth });

export const SignOut = (history, dispatch, personalDetails) =>{
  
       /*//Axios.get("https://devoauth2.easyinherit.in.auth.us-west-2.amazoncognito.com/logout?response_type=token&client_id=4amjue2qnq08fd25bgvd03420a&redirect_uri=http://localhost:3000/googleLoginRedirectstate=STATE&scope=email+profile+openid")
     Axios.get("https://devoauth2.easyinherit.in.auth.us-west-2.amazoncognito.com/logout?client_id=4amjue2qnq08fd25bgvd03420a&logout_uri=http://localhost:3000")
     .then(ress=>console.log("RESPONSE",ress))
     .catch(err=>console.log("ERROR",err))
     https://my-domain.auth.us-west-2.amazoncognito.com/logout?client_id=63...ng&logout_uri=http:%2F%2Fyahoo.com*/

     Auth.signOut({ global: false })
    .then(data => {
        console.log('successful sign out!');
        dispatch(logOut());
        window.location.reload();
        //history.push('/')
  })
  .catch(err => console.log('error signing out!: ', err));
}

