import React, {useState} from 'react'
import { createPayment, createOrder, updatePaymentById, getAllOrders } from '../../../dashboard/forms/payment/paymentService'
import { getStoreUser } from '../../../selectors/userSelectors';
import { connect } from 'react-redux'
import _ from 'lodash';
import PaymentReceipt from './PaymentReceipt';
import ModalContainer from '../../../home/component/layout/Modal';
import { setPaymentData } from '../../../../src/actions/paymentAction'
import Spinner from 'react-bootstrap/Spinner'
import { setOrderData } from '../../../actions/orderAction';
import { RAZORPAY_KEY } from '../../../constant/ServerConst'

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}

function Payment({personalDetails, dispatchSetPaymentData, productID, amount, productDescrition, dispatchSetOrderData, will, togglePayment}) {
	let [visiblePaymentReceipt, setPaymentReceipt] = useState(false)
	let togglePaymentReceipt = ()=>setPaymentReceipt(!visiblePaymentReceipt)
	let doNothing = () =>{
		let doNo = []
		doNo.push(null)
	}
	let [loader, setLoader] = useState(false)
	async function displayRazorpay() {
		setLoader(true)
		if(isNaN(personalDetails.id)){
			setLoader(false)
			alert("Please Sign in to pay and proceed")
		}
		else{
		const payload={
			userId:personalDetails.id,
			amount: amount,
			productDesc: productDescrition,
			productId: productID, 
			currency: 'INR',
			razorpayStatus: 'in progress'
		}
		const orderId_payload={
			amount:amount*100,
			currency:'INR',
			payment_capture:'1'
		}
		const res =  await loadScript('https://checkout.razorpay.com/v1/checkout.js')
		
		if (!res) {
			alert('Razorpay SDK failed to load. Are you online?')
			return
		}
		
		await createPayment(payload)
		.then((resp) => {
		createOrder(orderId_payload).then(res => {
		 const options = {
			key: RAZORPAY_KEY,
			currency: res.data.currency,
			amount: res.data.amount*100,
			name: 'EasyInherit Services',
      image: require('../../../assets/img/razrpay.png'),
			buttontext:'Pay with Razorpay',
			order_id: res.data.id,
			receiptId : resp.data.receiptId,
			handler: function (response) {				
					const payload={
					"razorpayOrderId": response.razorpay_order_id,
					"razorpayPaymentId": response.razorpay_payment_id,
					"razorpaySignature": response.razorpay_signature,
					"razorpayStatus":	_.isEmpty(response.razorpay_payment_id) || _.isEmpty(response.razorpay_order_id) || _.isEmpty(response.razorpay_signature) ?  "Failed" : "Success",
				}
				updatePaymentById(resp.data.id, payload)
				.then((response) => {
					dispatchSetPaymentData(response.data)
					togglePaymentReceipt()
					getAllOrders(personalDetails.id)
					.then(res=>{
						dispatchSetOrderData(res.data)
					})
				  })
				  .catch((error) => {
					console.log(error)
					alert(error)
					setLoader(false)
					})	
			},
			prefill: {
				name:personalDetails.firstName,
				email: personalDetails.primaryEmail,
			}
		}
		const paymentObject = new window.Razorpay(options)
		setLoader(false);
		paymentObject.open();
		will === 'createWill' ? togglePayment() : doNothing()
	})
	.catch((error) => {
	  console.log(error)
		alert(error)
		setLoader(false)
	})
}).catch(e => {
	console.log(e)
	setLoader(false)
})
}
	}
	return (
		<div >
			<button onClick={displayRazorpay} className='btn btn-primary'>Proceed to Pay
				{ loader ?
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
      	:null}
			</button>
			<div>
			<ModalContainer visible={visiblePaymentReceipt} toggle={togglePaymentReceipt}>
          <div>
            <PaymentReceipt onClose={togglePaymentReceipt}/>
          </div>
        </ModalContainer>
				</div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	personalDetails: getStoreUser(state),
  })

	const mapDispatchToProps = () => (dispatch) => ({
		dispatchSetPaymentData: (payment) =>dispatch(setPaymentData(payment)),
		dispatchSetOrderData: orders => dispatch(setOrderData(orders)),
	})
	
export default connect(mapStateToProps, mapDispatchToProps)(Payment)
