export const ADD_DIGILOCKER = 'ADD_DIGILOCKER';
export const setDigilockerData = (digilockerData) => {

    return {
        type: ADD_DIGILOCKER,
        digilockerData
    }
}

export const getDigilockerList = state => state.digilockerData;


export const ADD_DIGILOCKER_FILES = 'ADD_DIGILOCKER_FILES';
export const setDigilockerFiles = (digilockerFiles) => {

    return {
        type: ADD_DIGILOCKER_FILES,
        digilockerFiles
    }
}

export const getDigilockerFileList = state => state.digilockerFiles;
