import React from 'react'
import HeadBar from '../HeaderBar'

const Questions = ({
  question,
  dontKnowOption,
  otherOption,
  value,
  setFunction,
}) => (
  <div className='row' style={{ marginLeft: 5 }}>
    <HeadBar title={question} />
    <div className='row' style={{ marginLeft: 5 }}>
      <div className='form-check form-check-inline'>
        <label className='form-check-label'>
          <input
            className='form-check-input'
            type='radio'
            checked={value === 'Yes'}
            name={'inlineRadio' + question}
            id={'inlineRadio' + question}
            value={'Yes'}
            onChange={(event) => setFunction(event.target.value)}
          />
          Yes
        </label>
      </div>
      <div className='form-check form-check-inline'>
        <label className='form-check-label'>
          <input
            className='form-check-input'
            type='radio'
            checked={value === 'No'}
            name={'inlineRadio' + question}
            id={'inlineRadio' + question}
            value={'No'}
            onChange={(event) => setFunction(event.target.value)}
          />
          No
        </label>
      </div>
      {dontKnowOption && (
        <div className='form-check form-check-inline'>
          <label className='form-check-label'>
            <input
              className='form-check-input'
              type='radio'
              checked={value === "I don't know"}
              name={'inlineRadio' + question}
              id={'inlineRadio' + question}
              value={"I don't know"}
              onChange={(event) => setFunction(event.target.value)}
            />
            I don't know
          </label>
        </div>
      )}
      {otherOption && (
        <div className='form-check form-check-inline'>
          <label className='form-check-label'>
            <input
              className='form-check-input'
              type='radio'
              checked={value === 'Other'}
              name={'inlineRadio' + question}
              id={'inlineRadio' + question}
              value={'Other'}
              onChange={(event) => setFunction(event.target.value)}
            />
            Other
          </label>
        </div>
      )}
    </div>
  </div>
)

export default Questions
