import initialState from "./initialState";
import _ from 'lodash';
import { ADD_IMMOVABLE_ASSET_LIST, DELETE_IMMOVABLE_ASSET, CREATE_IMMOVABLE_ASSET, EDIT_IMMOVABLE_ASSET } from "../actions/immovableAssetsActions";
export default (state = initialState.immovableAssets, action) => {
    switch (action.type) {
        case ADD_IMMOVABLE_ASSET_LIST:
            return action.immovableAssets;

        case DELETE_IMMOVABLE_ASSET:
            return state.filter(con => con.id !== action.immovableAssetId);
        case CREATE_IMMOVABLE_ASSET:
            return state.concat(action.immovableAsset)
        case EDIT_IMMOVABLE_ASSET:
            const index = _.findIndex(state, { id: action.immovableAsset.id });
            state.splice(index, 1, action.immovableAsset);
            return [...state];
        default:
            return state;
    }
}