import { useToasts } from "react-toast-notifications";

export const useQuaishToasts = () => {

    const { addToast, removeAllToasts } = useToasts();
    return {
        success: (message) => addToast(message, { variant: 'success', autoDismiss: true, autoDismissTimeout: 1000 }),
        error: (message) => addToast((message, { variant: 'error', autoDismiss: true, autoDismissTimeout: 1000 })),
        warn: (message) => Array.isArray(message) ? message.map(m => addToast(m, { variant: 'warning', autoDismiss: false })) : addToast(message, { variant: 'warn', autoDismiss: false }),
        removeAll: removeAllToasts
    }
}