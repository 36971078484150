import onClickOutside from "react-onclickoutside";
import React from 'react'
import './Header.css';
import { SignOut } from '../../../auth/SignOut'
import { HashLink as Link } from 'react-router-hash-link'

class LogoutDropdown extends React.Component {
   
    handleClickOutside = () => {   
            var dropdowns = document.getElementsByClassName("dropdown-content2");
            var i;
            for (i = 0; i < dropdowns.length; i++) {
              var openDropdown = dropdowns[i];
              if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
              }
            }
          
  }

     showDropdown(d) {
        document.getElementById(d).classList.toggle("show");
      }
 
  render() {
    return (
        <div class="dropdown">
        <span onClick={() => this.showDropdown('myDropdown5')} class="dropbtn" style={{color:'white'}}>
        <img
        alt=''
        src='../img/banner/profile.png'
        width='40'
        height='38'
        className='rounded-circle '
      />
     </span>
        <div id="myDropdown5" class="dropdown-content2">      
          <ul>
          <Link to='/profile' className='dropdown-item'>
            Profile
          </Link>
          <Link to='/myorders' className='dropdown-item'>
            My orders
          </Link>
          <Link onClick={() => SignOut(this.props.history, this.props.dispatch, this.props.personalDetails)} className='dropdown-item'>
            Log Out
          </Link>
          </ul>
        </div>
      </div>
    );
  }
}

export default onClickOutside(LogoutDropdown)