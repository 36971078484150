import _ from 'lodash';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import DropDown from '../../../components/DropDown';
import HeadBar from '../../../components/HeaderBar';
import string from '../../../constant/en.json';
import { useInput } from '../../../hooks/useInput';
import { Individual } from '../../components/IndividualContact';
import { Institution } from '../../components/InstitutionContact';

const ContactTypeAndDetailsForm = ({onUploadOpen, defaultValue = { contactLookupCode: 'INDV', firstName: '' } }, ref) => {
    const { value: contactLookupCode, bind: bindContactType } = useInput(defaultValue.contactLookupCode);
    const refIndividual = useRef();
    const refInstitution = useRef();
    useImperativeHandle(ref, () => ({
        returnDetails() {
            let details = contactLookupCode === 'INST' ? refInstitution.current.returnDetails() : refIndividual.current.returnDetails();
            return {
                contactLookupCode: _.isEmpty(contactLookupCode) ? 'INDV' : contactLookupCode,
                ...details,
            };
        },
    }));
    return (
        <React.Fragment>
            <section className='section_form'>
                <HeadBar title={string.forms.commonFormFields.pleaseSelect} />
                <div className='row'>
                    <DropDown
                        placeholder={'Please Select'}
                        options={[
                            { code: 'INST', value: 'Institution' },
                            { code: 'INDV', value: 'Individual' },
                        ]}
                        value={contactLookupCode}
                        bind={bindContactType} />
                </div>
            </section>
            {contactLookupCode === 'INST' ? <Institution  defaultValue={{ ...defaultValue }} ref={refInstitution} /> : <Individual defaultValue={{ ...defaultValue }} onUploadOpen={onUploadOpen} ref={refIndividual} />}
        </React.Fragment>
    );
}
const ContactTypeAndDetails = forwardRef(ContactTypeAndDetailsForm);
export default ContactTypeAndDetails;