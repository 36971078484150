import { getAddressMapped } from "./userMapping";
import moment from 'moment';
export const contactToUiLoad = obj => {
    const {
        id,
        contHomeAddress1,
        contHomeAddress2,
        contHomeAddressCity,
        contHomeAddressCountry,
        contHomeAddressPin,
        contHomeAddressState,
        contOfficeAddress1,
        contOfficeAddress2,
        contOfficeAddressCity,
        contOfficeAddressCountry,
        contOfficeAddressPin,
        contOfficeAddressState,
        gender,
        age,
        contactLookupCode,
        deceased,
        relationship,
        dob,
        minor,
        religion,
        nationality,
        primaryEmail,
        secondaryEmail,
        adopted, disabled, documents,
        mobilePhone, title, disinherit, disinheritReason,
        idProofNumber, idProofType, maritalStatus, custField1, custField2, custField3,custField4, custField5, fullName, firstName, middleName, lastName, primaryPhone, secondaryPhone
    } = obj;
    return {
        idProofNumber, idProofType, maritalStatus, custField1, custField2, custField3,custField4, custField5, executorFlag: obj.executorFlag,
        id, disinherit, disinheritReason, documents: documents === null ? [] : documents.filter(u => u.active),
        dob: moment(dob).format('YYYY-MM-DD'), minor, religion, nationality, age, gender, adopted, disabled,
        firstName, lastName, middleName, title, fullName,
        primaryPhone, secondaryPhone, mobilePhone,
        homeAddress: getAddressMapped(contHomeAddress1,
            contHomeAddress2, contHomeAddressCity,
            contHomeAddressState, contHomeAddressCountry,
            contHomeAddressPin),
        officeAddress: getAddressMapped(contOfficeAddress1,
            contOfficeAddress2, contOfficeAddressCity,
            contOfficeAddressState, contOfficeAddressCountry,
            contOfficeAddressPin),
        deceased,
        contactLookupCode,
        primaryEmail,
        secondaryEmail,
        relationship
    }
}