import React, { forwardRef, useState } from 'react';
import CollapseSection from "../../../../components/CollapsibleSection";
import HeadBar from "../../../../components/HeaderBar";
import Input from "../../../../components/Input";
import { useInput } from "../../../../hooks/useInput";
import TextArea from '../../../../components/TextArea';
import string from '../../../../constant/en.json';
import { CustomButtom, ContainerPosition, ActionItem, AlignColumn, CenterAlignRow, TableContainer } from '../../../../components/CustomElements';
import { batchState, getId } from '../../../../utils/commonUtil';
import { connect } from 'react-redux';
import { deleteDigitalAssetFromList, editDigitalAsset, addDigitalAsset, getStoreDigitalAssetList } from '../../../../actions/digitalAssetsActions';
import { getStoreUserId, getStoreUserRole } from '../../../../selectors/userSelectors';
import { deleteDigitalAsset, updateDigitalAssetById, createDigitalAsset } from '../digitalAssetService';
import _ from 'lodash';
import { useQuaishToasts } from '../../../../actions/alertAction';
import ModalContainer from '../../../../home/component/layout/Modal'
import DataShareModal from '../../../../dashboard/forms/dataShare/DataShareModal'

const Website = ({userId, webDigitalAssetList, dispatchAddDigitalAsset, dispatchEditDigitalAsset, dispatchDeleteDigitalAsset, userRole}, ref) => {
  let [isFormHidden, formVisibility] = useState(true);
  const [web, setWeb] = useState();
  let [visibleDataShare, setVisibleDataShare] = useState(false)
  let toggleDataShare = () => setVisibleDataShare(!visibleDataShare)
  const [dataShareId, setDataShareId] = useState('')

  const toggleForm = () => {
    batchState(() => {
      if (!isFormHidden && web &&  web.id ) {
        setWeb(undefined)
      }
      formVisibility(!isFormHidden)
    })
  }
  const onDone = () => {
    toggleForm()
    // batchState(() => {
    //   setWeb(undefined);
    //   toggleForm()
    // })
  }
  const onEdit = (c) => {
    batchState(() => {
      setWeb(c);
      if (isFormHidden)
        formVisibility(false)
    })
  }
  const onShare = (c) => {
    setDataShareId(c)
    toggleDataShare()
  }
       
  return (
    <div>
        <SavedWebsites data={webDigitalAssetList} onEdit={onEdit} onShare={onShare} isFormHidden={isFormHidden} dispatchDeleteDigitalAsset={dispatchDeleteDigitalAsset} />
        <div  style={{ marginBottom: 20, display: 'block' }}>
          <ContainerPosition>
            <CustomButtom event={() => toggleForm()} title={'Add Websites'} />
          </ContainerPosition>
        </div>
        {isFormHidden ? null : <WebForm web={web} userId={userId} onCancel={toggleForm} onDone={onDone} webDigitalAssetList={webDigitalAssetList} dispatchAddDigitalAsset={dispatchAddDigitalAsset} dispatchEditDigitalAsset={dispatchEditDigitalAsset} userRole={userRole} />}
        {visibleDataShare
                  ? 
                  <ModalContainer visible={visibleDataShare} toggle={toggleDataShare}>
                    <DataShareModal visibleDataShare={visibleDataShare} toggleDataShare={toggleDataShare} dataShareId={dataShareId} AssetList={webDigitalAssetList}  assetLookupKey={"asset_digital"} assetLookupCode={'WEB'}/>
                  </ModalContainer> 
                  : null 
        }
    </div>
  );
}


const WebFrm = ({web,userId, onCancel, onDone, webDigitalAssetList, dispatchAddDigitalAsset, dispatchEditDigitalAsset, userRole}, ref) => {
  const { value: assetDetail, bind: bindName } = useInput(!web ? '' : web.assetDetail);
  const { value: assetDescription, bind: bindDesc } = useInput(!web ? '' : web.assetDescription);
  const { success, error, removeAll } = useQuaishToasts()
  /*const validate=(payload)=>{
    const ev=[]
    for (var key in payload) {
      switch(key){
          case 'assetDescription':
            _.isEmpty(payload[key]) ? ev.push(string.forms.websites.emptyHostingDetails):null
            break
          case 'assetDetail':
            _.isEmpty(payload[key]) ? ev.push(string.forms.websites.emptyDomainName):null
            break
          default:
            break
          } 
        }
          if(ev.length>0){
            warn(ev)
            return false
          }
          else return true
        }*/
  const submit = () => {
    removeAll()
    const roleOfUser= userRole==='GIVER' ? false : true
    const payload = {
      userId,
      assetLookupCode: 'WEB',
      receiverFlag:	roleOfUser,
      assetDescription,
      assetDetail,
      // contacts: refPickNominee.current.returnDetails()
    }
    console.log(payload)
    //if(validate(payload))

    getId(web) ? updateDigitalAssetById(web.id,{id:web.id ,...payload})
    .then(response => {
      success('Record Updated Successfully');
        dispatchEditDigitalAsset(response.data)
        onDone()
    }).catch(err => {
      error(err)
    }) : 
    createDigitalAsset(payload)
    .then(response => {
      success('Record Created Successfully');
      dispatchAddDigitalAsset(response.data);
      onDone()
        // dispatchSetAllContactList(response.data);
    })
    .catch(err => {
      error(err)
    })
  }
  return <CollapseSection  mainTitle={'Enter ' + string.forms.websites.websiteDetailsFormTitle} >
    <section className='section_form'>
      <div>
        <HeadBar title={string.forms.websites.domainName} subtitle={'*'} />
      </div>
      <div className='row'>
        <Input type='text' placeholder={string.forms.websites.domainName} isBigger bind={bindName} />
      </div>
      <div>
        <HeadBar title={string.forms.websites.hostingDetails} subtitle={'*'} />
      </div>
      <div className='row'>
        <TextArea placeholder={string.forms.websites.hostingDetails} bind={bindDesc} />
      </div>
    </section>
    <ContainerPosition>
            <CustomButtom event={() => submit()} title={string.forms.commonFormFields.saveBtn} />
            <CustomButtom event={onCancel} title={string.forms.commonFormFields.cancelBtn} />
        </ContainerPosition>
  </CollapseSection>
}
export const WebForm = forwardRef(WebFrm);
export function SavedWebsites ({ data, dispatchDeleteDigitalAsset, onEdit, dispalyActions, onShare, datashare, dataShareByMe, accessLevelArray, toggle }) {
  const { success, error } = useQuaishToasts()
  const deleteWeb = (id) =>{
    if(window.confirm("Delete?\n Are you sure you want to delete this record?")){
        deleteDigitalAsset(id)
        .then(response => {
          success('Record Deleted Successfully');
          dispatchDeleteDigitalAsset(id);

        })
        .catch(err => {
          error(err)
        })
    }
  }

  const row = data.map((e) => {
    return(
      
    <TableContainer>
      <CenterAlignRow>
        <AlignColumn label="Platform" text={e.assetDetail} />
        <AlignColumn label="Username" text={e.assetDescription} />
        {datashare === 'yes' ? <AlignColumn label="Shared with" text={_.isEmpty(dataShareByMe[e.id][0]) ? null : dataShareByMe[e.id][0].map((f,index)=>(<span style={{cursor:'pointer', color: '#007bff'}} onClick={()=>toggle(dataShareByMe[e.id][1][index])}><span style={{textDecoration:'underline'}}>{f}</span><span>{index !== (dataShareByMe[e.id][0].length) -1 ? ' , ' : ''}</span></span>))} />: null}
        {datashare === 'shareby' ? <AlignColumn label="Shared By" text={dataShareByMe[e.id]} />: null}
       {dispalyActions === 'no' || datashare === 'yes' || (datashare === 'shareby' && accessLevelArray[e.id]==='READ')  ? null :
        <AlignColumn label="Action" text={<ActionItem onEdit={()=>onEdit(e)} onDelete={()=>deleteWeb(e.id)} onShare={() => onShare(e.id)} datashare={datashare}/>} />}
      </CenterAlignRow>
    </TableContainer>
  )});
  return (
    data.length === 0 ? null :
    <div>
    <CollapseSection isHidden={data.length === 0} mainTitle={'Websites and Media'} onShare={() => onShare('multicreate')} collapsed={false} isCollapsable={true} customStyle={true}  datashareuser={datashare}  datashare={'yes'}>
      {row}
    </CollapseSection>
     {dispalyActions === 'no' || datashare === 'yes' || datashare === 'shareby' ? <br/> : null }
     </div>
  );
};

 const mapStateToProps = (state) => ({
  webDigitalAssetList: getStoreDigitalAssetList(state).filter(fa=> fa.assetLookupCode === 'WEB'),
  userId: getStoreUserId(state),
  userRole:getStoreUserRole(state),
});
const mapDispatchToProps = () => dispatch => ({
  dispatchAddDigitalAsset: digitalAsset => dispatch(addDigitalAsset(digitalAsset)),
  dispatchEditDigitalAsset:digitalAsset=>dispatch(editDigitalAsset(digitalAsset)),
  dispatchDeleteDigitalAsset:digitalAsset=>dispatch(deleteDigitalAssetFromList(digitalAsset)),
})
 export default connect(mapStateToProps, mapDispatchToProps)(Website);
