import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getNameUser, getStoreUser } from '../../selectors/userSelectors'
import { setFamilyData } from '../../actions/familyActions'
import {
  setFinAssetData,
  getStoreFinAssetList,
} from '../../actions/finAssetsActions'
import { setImmovableAssetData } from '../../actions/immovableAssetsActions'
import { setDigitalAssetData } from '../../actions/digitalAssetsActions'
import { setOtherAssetData } from '../../actions/otherAssetsActions'
import { getFamilyListFromServer } from '../../dashboard/forms/family/familyService'
import { getFinAssetListFromServer, getFinAssetListById } from '../../dashboard/forms/assets/finAssetService'
import { getDigitalAssetListFromServer, getDigitalAssetListById } from '../../dashboard/forms/assets/digitalAssetService'
import { getImmovableAssetListFromServer, getImmovableAssetListById } from '../../dashboard/forms/assets/immovableAssetService'
import { getOtherAssetListById, getOtherAssetListFromServer } from '../../dashboard/forms/assets/otherAssetService'
import { connect } from 'react-redux'
import { getStoreUserId, getStoreUserRole } from '../../selectors/userSelectors'
import { setContactData } from '../../actions/contactActions'
import { getContactListFromServer } from '../forms/contacts/ContactService'
import FreeEstatePlan from '../../components/FreeConsultations/FreeEstatePlan'
import CalenderSlots from '../../dashboard/components/CalenderSlots'
import { getSpeedometerList } from '../../actions/speedometerAction'
import FreeLegalReview from '../../components/FreeConsultations/FreeLegalReview'
import JustInCase from '../../components/FreeConsultations/JustInCase'
import { getUserDataFromServer } from '../../dashboard/forms/personal/personalService'
import Digilocker from '../../dashboard/forms/digilocker/Digilocker'
import { getUserDocumentById } from '../../dashboard/forms/digilocker/digilockerServices';
import { setDigilockerData } from '../../actions/digilockerAction'
import _ from 'lodash';
import ModalContainer from '../../home/component/layout/Modal'
import Login from '../../auth/Login'
import { FooterTag } from '../Dashboard'
import Header from '../../home/component/layout/Header'
import { setUserRoleData } from '../../actions/userRolesAction'
import { getAllOrders } from '../../dashboard/forms/payment/paymentService'
import { setOrderData } from '../../actions/orderAction';
import { getEstatePlanData} from '../../actions/freeEstatePlanAction';
import { getLegalReviewData } from '../../actions/freeLegalReviewAction';
import { useInput } from '../../hooks/useInput'
import { setWillDataList } from '../../actions/willAction'
import { getWillData } from '../../dashboard/forms/will/WillServices'
import { getAllDataShareWithMe, getAllDataShareByMe } from '../../dashboard/forms/dataShare/datashareService'
import { setDataSharedByme, getDataSharedByme } from '../../actions/dataSharedByMeActions';
import { setDataShareWithme } from '../../actions/dataSharedWithmeActions';
import { setDataSharedWithNames, getDataSharedWithNamesList } from '../../actions/dataSharedWithNamesAction'
import { setDataSharedBy, getDataSharedByList } from '../../actions/dataShareByAction'
import { setDataSharedByNames } from '../../actions/dataSharedByNamesAction'
import { getContactFullName } from '../../utils/commonUtil';
import { TRAKING_ID } from '../../constant/ServerConst'
import ReactGA from 'react-ga';
import mixpanel from 'mixpanel-browser';

ReactGA.initialize(TRAKING_ID);
mixpanel.init("60ba11d6c224405b0b1a24bacb55bd8b");

const DashboardContainer = ({
  userId,
  user,
  dispatchSetFamilyList,
  dispatchAddFinAsset,
  insuranceFinAssetList,
  dispatchSetImmovableAssetList,
  dispatchSetDigitalAssetList,
  dispatchSetOtherAssetList,
  dispatchSetContactList,
  userRole,
  FinAssetList,
  dispatchSetDigilockerData,
  dispatchSetUserRoleData,
  dispatchSetWillData,
  dispatchSetOrderData,
  EstatePlanData,
  LegalReviewData,
  dispatchDataShareWithme,
  dispatchDataSharedByme,
  dispatchDataShareWithNames,
  dispatchDataShareBy,
  dispatchDataShareByNames,
  shareDataByMe,
  shareDataBy,
  shareDataByCount
}) => {
  const [family, setFamily] = useState('')
  const [immovableAsset, setImmovableAsset] = useState('')
  const [digitalAsset, setDigitalAsset] = useState('')
  const [pets, setPets] = useState('')
  const [justInCase, showJustInCase] = useState(false)
  const [estatePlan, showEstatePlan] = useState(false)
  const [legalReview, showLegalReview] = useState(false)
  const receiverFlag= userRole==='GIVER' ? false : true
  const [estatePlanGrid, showEstatePlanGrid] = useState(isNaN(userId) || _.isEmpty(userRole) ? EstatePlanData === {} ? false : EstatePlanData.custField4 ==='ESTATE_PLANNER_APT_SCHEDULED' ? 'Reset Appointment' : EstatePlanData.custField2 ==='ESTATE_PLANNER_DONE' ? 'Estate Planning Done' :  null
                                                  :
                                                  user.custField2 !== "ESTATE_PLANNER_DONE" ? false : user.custField4 === "ESTATE_PLANNER_APT_SCHEDULED" ? 'Reset Appointmnet': user.custField2 === "ESTATE_PLANNER_DONE" ? 'Estate Planning Done' : null
                                                 )
  const [legalReviewGrid, showLegalReviewGrid] = useState( isNaN(userId) || _.isEmpty(userRole) ? LegalReviewData === {} ? false : LegalReviewData.custField5 ==='ESTATE_PLANNER_APT_SCHEDULED' ? 'Reset Appointment' : LegalReviewData.custField3 ==='ESTATE_PLANNER_DONE' ? 'Legal Review Done' :  null
                                                  :
                                                  user.custField3 !== "LEGAL_REVIEW_DONE" ? false : user.custField5 === "LEGAL_REVIEW_APT_SCHEDULED" ? 'Reset Appointmnet': user.custField3 === "LEGAL_REVIEW_DONE" ? 'Legal Review Done' : null
                                                  )

  const  [esatateCalender, setEsatateCalender] = useState(estatePlanGrid === 'Estate Planning Done' ? true : false)
  const  [legalReviewCalender, setLegalReviewCalender] = useState(legalReviewGrid === 'Legal Review Done' ? true : false)


  useEffect(() => {
    ReactGA.pageview('Dashboard Page')
    mixpanel.track("Dashboard Page")
    mixpanel.people.set({
      "$email": user.primaryEmail
    })
    const ac = new window.AbortController()
    const nullData = []
    isNaN(userId) || _.isEmpty(userRole) ? nullData.push(null) :(

    Promise.all([
      getFamilyListFromServer(userId, ac),
      getFinAssetListFromServer(userId, receiverFlag, ac),
      getImmovableAssetListFromServer(userId, receiverFlag, ac),
      getDigitalAssetListFromServer(userId, receiverFlag, ac),

      getOtherAssetListFromServer(userId, receiverFlag, ac),

      getContactListFromServer(userId),
      getUserDocumentById(userId),
      getAllOrders(userId),
      getContactListFromServer(userId),
      getWillData(userId),
      getAllDataShareByMe(userId, false),
      getAllDataShareWithMe(userId, false)
    ])
      .then((response) => {
        dispatchSetFamilyList(response[0].data);
        dispatchAddFinAsset(response[1].data);
        dispatchSetImmovableAssetList(response[2].data);
        dispatchSetDigitalAssetList(response[3].data);

        dispatchSetOtherAssetList(response[4].data);

        dispatchSetContactList(response[5].data);
        dispatchSetDigilockerData(response[6].data);
        dispatchSetOrderData(response[7].data);
        dispatchSetWillData(response[9].data);
        dispatchDataSharedByme(response[10].data);
        dispatchDataShareWithme(response[11].data);
        setFamily(response[0].data);
        
        setImmovableAsset(response[2].data);
        setDigitalAsset(response[3].data);
        setPets(response[8].data.filter((fa) => fa.relationship === 'PET'));

        const idList ={}
        const nameAssetDict={}
        const idAssetList ={}
        const emptyData =[]
        if(response[10].data.length === 0)
        { 
          emptyData.push(null)
         }
         else{
          response[10].data.map(e=>
          {
            if(!idList[e.assetId])
            {
              idList[e.assetId]=[]
              idAssetList[e.assetId]=[]
            }
            idList[e.assetId].push(e.shareToUserId)
            idAssetList[e.assetId].push(e.id)
          })
        }
        const sharedUserIds = response[10].data.length === 0 ? [] : response[10].data.map(e=>e.shareToUserId).filter((v, i, a) => a.indexOf(v) === i);
        const idListLength=(sharedUserIds.length)
        const userIdWithName = {}
        let arrayLength=[]
        if(sharedUserIds.length > 0) {
          sharedUserIds.map((u,i) => {
            getUserDataFromServer(u)
          .then(res=>{
            let fullName=getContactFullName(res.data)
            userIdWithName[u] = fullName
            arrayLength.push(i)
            if(arrayLength.length === idListLength){
            for (const [key, value] of Object.entries(idList)) {
                if(value.length > 1){
                    value.map((m)=>{
                    if (!nameAssetDict[key]) {
                      nameAssetDict[key] = [[]];
                  }
                  nameAssetDict[key][0].push(userIdWithName[m])
                  nameAssetDict[key][1]=idAssetList[key]
                  })
                }
                else{
                  if (!nameAssetDict[key]) {
                    nameAssetDict[key] = [[]];
                  }
                  nameAssetDict[key][0].push(userIdWithName[value])
                  nameAssetDict[key][1]=idAssetList[key]
                }
            }
            dispatchDataShareWithNames(nameAssetDict)
          }
        })
        .catch(err=>console.log("error",err))
      }
      )
    }
      else{
        let nameAssetDict={}
        dispatchDataShareWithNames(nameAssetDict)
      }

      const allAssets=[]
      response[11].data.map((d)=> {
              if(d.assetLookupCode === 'FD' || 
              d.assetLookupCode === 'SHR' || 
              d.assetLookupCode === 'DEM' || 
              d.assetLookupCode === 'GOV' || 
              d.assetLookupCode === 'PMS' ||
              d.assetLookupCode === 'BNK' || 
              d.assetLookupCode === 'INS' ||
              d.assetLookupCode === 'MF' || 
              d.assetLookupCode === 'BON' || 
              d.assetLookupCode === 'PF' || 
              d.assetLookupCode === 'GRA')
              (
              getFinAssetListById(d.assetId).
              then(res=> {
                  allAssets.push(res.data)
                  dispatchDataShareBy(allAssets)
                })
              .catch((err) => alert(err))
              )
              if(d.assetLookupCode ===  'PROP')
              (
              getImmovableAssetListById(d.assetId).
              then(res=> {
                allAssets.push(res.data)
                dispatchDataShareBy(allAssets)
              })
              .catch((err) => alert(err))
              )
              if(d.assetLookupCode === 'SOC' || d.assetLookupCode === 'WEB')
              (
              getDigitalAssetListById(d.assetId).
              then(res=> {
                allAssets.push(res.data)
                dispatchDataShareBy(allAssets)
              })
              .catch((err) => alert(err))
              )
              if(d.assetLookupCode === 'ARTI' ||
              d.assetLookupCode === 'JEWL' || 
              d.assetLookupCode === 'ART' || 
              d.assetLookupCode === 'LOCK' || 
              d.assetLookupCode === 'VEH' || 
              d.assetLookupCode === 'PERS')
              (
              getOtherAssetListById(d.assetId).
              then(res=> {
                allAssets.push(res.data)
                dispatchDataShareBy(allAssets)
              })
              .catch((err) => alert(err))
              )
              })

              const assetOwnerName ={}
              response[11].data.length === 0 ? emptyData.push(null) : response[11].data.map(e=>
                {
                getUserDataFromServer(e.sharedByUserId)
                .then(res=>{
                  let fullName=getContactFullName(res.data)
                  assetOwnerName[e.assetId]=fullName
                  dispatchDataShareByNames(assetOwnerName)
                })
                .catch(err=>console.log("error",err))
            })
      })
      .catch((ex) => console.error(ex))
    )
    return function cleanup() {
      ac.abort()
    }
  }, [])

  const { name } = useSelector((state) => ({ name: getNameUser(state) }))
 
  const totalItems =
  userRole==='GIVER' ?
    family.length +
    pets.length +
    FinAssetList.length +
    insuranceFinAssetList.length +
    immovableAsset.length +
    digitalAsset.length
    :
    FinAssetList.length +
    insuranceFinAssetList.length +
    immovableAsset.length +
    digitalAsset.length



  /*const resetJustInCase = () =>{
    let payload ={
      custField1:''
    }
    updateUser(user.id, payload)
      .then((resp) => {
       dispatchData(setUserData(resp.data))
    })
    .catch((err) => {
      console.log(err)
        alert('Please try again!')
    })
  }
  const estatePlanScheduleReset =  () =>{
    let payload ={
      custField2:'',
      custField4:''
    }
    updateUser(user.id, payload)
      .then((resp) => {
       dispatchData(setUserData(resp.data))
    })
    .catch((err) => {
      console.log(err)
        alert('Please try again!')
    })
  }
  const legalReviewScheduleReset =  () =>{
    let payload ={
      custField3:'',
      custField5:''
    }
    updateUser(user.id, payload)
      .then((resp) => {
       dispatchData(setUserData(resp.data))
    })
    .catch((err) => {
      console.log(err)
        alert('Please try again!')
    })
  }*/
  
  let [visibleLogin1, setVisibleLogin1] = useState(false)
  let toggleLogin1 = () => setVisibleLogin1(!visibleLogin1)

  let [visibleLogin2, setVisibleLogin2] = useState(false)
  let toggleLogin2 = () => setVisibleLogin2(!visibleLogin2)

  let [visibleLogin3, setVisibleLogin3] = useState(false)
  let toggleLogin3 = () => setVisibleLogin3(!visibleLogin3)

  let [visibleLogin4, setVisibleLogin4] = useState(false)
  let toggleLogin4 = () => setVisibleLogin4(!visibleLogin4)

  let [visibleLogin5, setVisibleLogin5] = useState(false)
  let toggleLogin5 = () => setVisibleLogin5(!visibleLogin5)

  let [visibleLogin6, setVisibleLogin6] = useState(false)
  let toggleLogin6 = () => setVisibleLogin6(!visibleLogin6)

  let [visibleLogin7, setVisibleLogin7] = useState(false)
  let toggleLogin7 = () => setVisibleLogin7(!visibleLogin7)

  let [visibleLogin8, setVisibleLogin8] = useState(false)
  let toggleLogin8 = () => setVisibleLogin8(!visibleLogin8)

  let [visibleLogin9, setVisibleLogin9] = useState(false)
  let toggleLogin9 = () => setVisibleLogin9(!visibleLogin9)

  let [visibleLogin10, setVisibleLogin10] = useState(false)
  let toggleLogin10 = () => setVisibleLogin10(!visibleLogin10)

  //let [visibleLogin11, setVisibleLogin11] = useState(false)
  //let toggleLogin11 = () => setVisibleLogin11(!visibleLogin11)

  let [visibleLogin12, setVisibleLogin12] = useState(false)
  let toggleLogin12 = () => setVisibleLogin12(!visibleLogin12)

  let [visibleLogin13, setVisibleLogin13] = useState(false)
  let toggleLogin13 = () => setVisibleLogin13(!visibleLogin13)

  let [visibleLogin14, setVisibleLogin14] = useState(false)
  let toggleLogin14 = () => setVisibleLogin14(!visibleLogin14)

  let [visibleLogin123, setVisibleLogin123] = useState(false)
  let toggleLogin123 = () => setVisibleLogin123(!visibleLogin123)

  //let togglelegalConsult = () => showLegalReview(!legalReview)
  //let toggleestateConsult = () => showEstatePlan(!estatePlan)

  let [calenderslot, setCalenderSlot] = useState(false)
  let toggleCalenderSlot= ()=>setCalenderSlot(!calenderslot)

  let [calenderslotUnloggedIn, setCalenderSlotUnloggedIn] = useState(false)
  let toggleCalenderSlotUnloggedIn= ()=>setCalenderSlotUnloggedIn(!calenderslotUnloggedIn)

  const roleOfUser = 'GIVER'
  const uRole = isNaN(userId) || _.isEmpty(userRole) ? roleOfUser : userRole

  /*const setRole = (r) =>{
    sRole(r)
    let payload={
      roleLookupCode : r,
    }
    let emptyData = []
    isNaN(userId) || _.isEmpty(userRole) ? emptyData.push(null) : dispatchSetUserRoleData(payload)
    const receiverFlag=r==='GIVER'?false:true 
    Promise.all([
      getFamilyListFromServer(userId),
      getFinAssetListFromServer(userId, receiverFlag),
      getImmovableAssetListFromServer(userId, receiverFlag),
      getDigitalAssetListFromServer(userId, receiverFlag),
      getContactListFromServer(userId),
      getUserDocumentById(userId),
    ])
      .then((response) => {
        dispatchSetFamilyList(response[0].data);
        dispatchAddFinAsset(response[1].data);
        dispatchSetImmovableAssetList(response[2].data);
        dispatchSetDigitalAssetList(response[3].data);
        dispatchSetContactList(response[4].data);
        dispatchSetDigilockerData(response[5].data);

        setFamily(response[0].data);
          setFinAsset(response[1].data);
          setImmovableAsset(response[2].data);
          setDigitalAsset(response[3].data);
      })
      .catch((ex) => console.error(ex))
    
  }
  */
  return (
    <div>
    {isNaN(userId) || _.isEmpty(userRole) ?
      <Header/>
      :
      null
      }
    <div className='container' style={{ marginTop: 20 }}>
    {isNaN(userId) || _.isEmpty(userRole) ? null :
      <div className='section_title mb-40'> 
        <div className='row'>
          <div className='col-sm-7'>
          <div>
          <p style={{ marginTop: 10, fontSize: 15 }}>
             Welcome, <b>{name}</b>. Till date you've added{' '}
              <b style={{ marginTop: 10, fontSize: 15 }}>{totalItems}</b> items
              to your Inheritance Portfolio. 
            </p>

            {totalItems<10 ? 
             <div><p style={{ marginTop: 10, fontSize: 15 }}>You can do better though. Do take the time out to keep these records. Your family will benefit the most.</p></div>
             :
             <div><p style={{ marginTop: 10, fontSize: 15 }}>You are doing great <img alt='' height ='23' width='23' src='../img/banner/clap.svg'></img>. Remember, its your family who benefits the most from this.</p></div>
             }

          </div>
          </div>
        </div>
        <hr/>
      </div>
      }
    <div className='container' style={{ marginTop: 20 }}>

    {isNaN(userId) || _.isEmpty(userRole) ? 
    <div className='section_title mb-40'> 
          <div className='row'>
            <div className='col-sm-7'>
                <div><p style={{ marginTop: 10, fontSize: 15 }}>
                Welcome <b> Guest</b>. The <b>Inheritance Portfolio</b> is a great place to start your journey. <br></br>One consolidated 
                record of all your Assets and Liabilities that can be shared with your family. Start today <img height ='23' width='23' src='../img/banner/clap.svg'></img>
                </p>
                
                <p style={{ marginTop: 10 , fontSize: 13 }}>
                  <img alt='' height ='13' width='13' src='../img/banner/alert.png'></img> &nbsp;&nbsp;&nbsp;
                  You are not logged in. Please < Link onClick={toggleLogin123}> Sign up or Login </Link> to save your records.
                </p>
              
          </div>
        </div>
      </div>
      <div>&nbsp;<hr /></div>
    </div>
    : null }
        

      <div style={{ justifyContent: 'center',  alignItems: 'center', }} className='row' >
          {' '}
        </div>
      </div>
      
      <div style={{ marginBottom: 40 }}> 
      {isNaN(userId) || _.isEmpty(userRole) ? 
       <div> 
         <p style={{ marginTop: 0, fontSize: 13, marginLeft: '10%'}} >You can also share this portfolio with your family. < Link onClick={toggleLogin123}> Sign up or Login</Link> to know more.</p>    
      </div>
      :
      <div>
        <p style={{ marginTop: 0, fontSize: 13, marginLeft: '10%'}} >You can also share this portfolio with your family. It is a great way to keep everyone involved. </p>   
          {userRole==='GIVER' ?
              <div>
                <p style={{ marginTop: 0, fontSize: 13, fontWeight:'300', marginLeft: '10%'}} >
                <Link to="/datashareby">
                    <span>Shared by me [ {Object.values(shareDataByMe).map(f=>f.assetId).length} ]</span>
                </Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link to="/datasharewith">
                    <span>Shared with me [ {Object.values(shareDataBy).length} ]</span>
                </Link></p>
              </div>
              :
              null
        }
          </div>
        }

        <div  style={{justifyContent: 'center'}} className='row'>
          <GridCard
            icon='name'
            text="Personal Info and ID"
            link={'/personal'}
            userId={userId}
            visibleLogin={visibleLogin1}
            toggleLogin={toggleLogin1}
            userRole={userRole}
          />
          {uRole ==='GIVER' ?
          <GridCard
            icon='family'
            text='Family and Loved Ones'
            subText={family.length + ' items added'}
            link={'/family'}
            userId={userId}
            visibleLogin={visibleLogin2}
            toggleLogin={toggleLogin2}
            userRole={userRole}
          />
          : null
          }
          {uRole ==='GIVER' ?
          <GridCard icon='joshua-tree' 
          text="Pets and Companions" 
          subText={pets.length + ' items added'}
          link={'/pets'} 
          userId={userId}
          visibleLogin={visibleLogin3}
          toggleLogin={toggleLogin3}
          userRole={userRole}
          />
          : null
        }
          <GridCard
            icon='house'
            text='Home and Property'
            subText={immovableAsset.length + ' items added'}
            link={'/immovableAsset'}
            userId={userId}
            visibleLogin={visibleLogin4}
            toggleLogin={toggleLogin4}
            userRole={userRole}
          />
          <GridCard
            icon='budget'
            text='Financial Assets'
            subText={FinAssetList.length + ' items added'}
            link={'/assets/financial'}
            userId={userId}
            visibleLogin={visibleLogin5}
            toggleLogin={toggleLogin5}
            userRole={userRole}
          />

          <GridCard
            icon='hospital'
            text='Health and Life Insurance'
            subText={insuranceFinAssetList.length + ' items added'}
            link={'/insurance'}
            userId={userId}
            visibleLogin={visibleLogin6}
            toggleLogin={toggleLogin6}
            userRole={userRole}
          />
          <GridCard
            icon='computer'
            text='Digital and Other Assets'
            subText={digitalAsset.length + ' items added'}
            link={'/assets/digital'}
            userId={userId}
            visibleLogin={visibleLogin7}
            toggleLogin={toggleLogin7}
            userRole={userRole}
          />
           <Digilocker 
            uploadType='grid'  
            userId={userId}
            visibleLogin={visibleLogin8}
            toggleLogin={toggleLogin8}
            userRole={userRole}
          />

          <GridCard
            icon='Logo251'
            text='EasyInherit Portfolio'
            subText={FinAssetList.length +
              insuranceFinAssetList.length +
              immovableAsset.length +
              digitalAsset.length + ' items added'}
            link={'/summary'}
            userId={userId}
            visibleLogin={visibleLogin9}
            toggleLogin={toggleLogin9}
            userRole={userRole}
          />

            <GridCard
            icon='advocate'
            text='Free Legal Consult'
            subText={legalReviewGrid === false ? 'Talk to our Lawyers' : legalReviewGrid}
            link={'/assets/digital'}
            userId={userId}
            visibleLogin={visibleLogin10}
            toggleLogin={toggleLogin10}
            userRole={userRole}
            consult={'calenderSlot'}
            togglefreeConsult = {toggleCalenderSlot}
            togglefreeConsultUnloggedIn = {toggleCalenderSlotUnloggedIn}
            estatePlanGrid = {legalReviewGrid}
          />

            {/*<GridCard
            icon='advisor'
            text='Free Inheritance Consult'
            subText={estatePlanGrid===false ? 'Talk to our Consultants': estatePlanGrid}
            link={'/assets/digital'}
            userId={userId}
            visibleLogin={visibleLogin11}
            toggleLogin={toggleLogin11}
            userRole={userRole}
            consult={'freeconsult'}
            togglefreeConsult = {toggleestateConsult}
            estatePlanGrid={estatePlanGrid}
            />*/}
            
        {uRole ==='GIVER' ? null 
        
        :
            <GridCard
            icon='probate'
            text='Probate Services'
            subText={'Starting at 99000/-'}
            link={'/faq#price'}
            userId={userId}
            visibleLogin={visibleLogin12}
            toggleLogin={toggleLogin12}
            userRole={userRole}
          />
          }
          
          {uRole ==='GIVER' ? null :
          <GridCard icon='transfer' 
          text='Transfer my Inheritance' 
          subText={'Starting at 65000/-'}
          link={'/faq#price'} 
            userId={userId}
            visibleLogin={visibleLogin13}
            toggleLogin={toggleLogin13}
            userRole={userRole}
            />
          }

        {uRole ==='GIVER' ?
          <GridCard icon='will' 
          text='Create My will' 
          subText={'Starting at INR 15000/-'}
          link={'/will'} 
            userId={userId}
            visibleLogin={visibleLogin14}
            toggleLogin={toggleLogin14}
            userRole={userRole}
            />
          : null
        }

        </div>
      </div>
      <JustInCase
        toggleModal={() => showJustInCase(!justInCase)}
        visible={justInCase}
        dashboardSignUp={ isNaN(userId) || _.isEmpty(userRole) ? true : false }
      />
      <FreeEstatePlan
        toggleModal={() => showEstatePlan(!estatePlan)}
        visible={estatePlan}
        dashboardSignUp={ isNaN(userId) || _.isEmpty(userRole) ? true : false }
        calender = {esatateCalender}
      />
      <FreeLegalReview
        toggleModal={() => showLegalReview(!legalReview)}
        visible={legalReview}
        dashboardSignUp={ isNaN(userId) || _.isEmpty(userRole) ? true : false }
        calender = {legalReviewCalender}
      />
      {calenderslot === true ?
        <CalenderSlots name={user.firstName} email={user.primaryEmail} freeConsult={'true'} updateId={user.id} toggleFreeConsultModal={toggleCalenderSlot} consultWho = {'Our Legal Experts'}/>
        : null }

      {calenderslotUnloggedIn === true ?
        <CalenderSlots  freeConsult={'true'} toggleFreeConsultModal={toggleCalenderSlotUnloggedIn} consultWho = {'Our Legal Experts'} unloggedinCalender/>
      : null }
    </div>
    {isNaN(userId) || _.isEmpty(userRole) ?
      <FooterTag/>
      : null
      }
    <ModalContainer visible={visibleLogin123} toggle={toggleLogin123}>
        <div style={{ padding: '40px' }}>
            <Login onClose={toggleLogin123} />
        </div>
    </ModalContainer>

  </div>
  )
}

const FreeConsultationCard = ({ title, text, subtext, event }) => (
  <Card
    style={{ width: '20rem', margin: 10, textAlign: 'center', fontSize: 15 }}
  >
    <Card.Body>
      <Card.Text style={{ fontWeight: 'bold' }}>
        <b>{title}</b>
      </Card.Text>
      <Card.Text style={{ marginTop: 5, fontSize: 13 }}>{text}</Card.Text>
      <Card.Text>
        <button className='btn btn-primary' onClick={event}>
          TRY NOW
        </button>
      </Card.Text>
      <Card.Text style={{ marginTop: 5, fontSize: 13 }}>{subtext}</Card.Text>
    </Card.Body>
  </Card>
)
const SliderCard = ({ title, text, event, btn  }) => (
  <Card style={{ width: '20rem', margin: 10 }}>
    <Card.Body>
      <Card.Text style={{ fontWeight: 'bold'}}>{title}</Card.Text>
      <Card.Text style={{ fontSize: 13 , lineHeight:2}}>{text}</Card.Text>
      <Card.Text style={{ fontSize: 10, lineHeight:2 }}>
        In case you haven't received an invite, please check your spam folder.{' '}
      </Card.Text>
      <Card.Text style={{textAlign:'center' }}>
        <button  style={{ fontSize: 11, fontWeight:600}} className='btn btn-primary' onClick={event}>{btn}</button>
      </Card.Text>
    </Card.Body>
  </Card>
)


const GridCard = ({ icon, text, subText, link, userId, visibleLogin, toggleLogin, userRole, consult, togglefreeConsult, togglefreeConsultUnloggedIn,  estatePlanGrid }) => (
  <Card
    border='primary'
    style={{ width: '18rem', margin: 5, cursor: 'pointer' }}
  >
  {isNaN(userId) || _.isEmpty(userRole) ?
  consult === 'freeconsult' ?
  <div>
    <Link  onClick={togglefreeConsult}> 
      <Card.Body style={{ textAlign: 'center', fontSize: 13 }}>
        {/* <Card.Title>Primary Card Title</Card.Title> */}
        <img
          style={{ width: '5', height: '4em' }}
          src={require(`../../assets/svg/${icon}.svg`)}
          alt=''
        />
        <Card.Text style={{ marginTop: 5, fontSize: 13 }}>{text}</Card.Text>
        <Card.Text style={{ marginTop: 10, fontSize: 11 }}>{subText}</Card.Text>
      </Card.Body>
    </Link>
    </div>
    :
    consult === 'calenderSlot' ?
  <div>
    <Link  onClick={togglefreeConsultUnloggedIn}> 
      <Card.Body style={{ textAlign: 'center', fontSize: 13 }}>
        {/* <Card.Title>Primary Card Title</Card.Title> */}
        <img
          style={{ width: '5', height: '4em' }}
          src={require(`../../assets/svg/${icon}.svg`)}
          alt=''
        />
        <Card.Text style={{ marginTop: 5, fontSize: 13 }}>{text}</Card.Text>
        <Card.Text style={{ marginTop: 10, fontSize: 11 }}>{subText}</Card.Text>
      </Card.Body>
    </Link>
    </div>
    :
  <div>
      <Link
          className='top-nav-link'
          style={{ fontWeight: 500, marginRight: 25, color: '#fff' }}
          onClick={toggleLogin}
      >
      <Card.Body style={{ textAlign: 'center', fontSize: 13 }}>
      {/* <Card.Title>Primary Card Title</Card.Title> */}
      <img
        style={{ width: '5', height: '4em' }}
        src={require(`../../assets/svg/${icon}.svg`)}
        alt=''
      />
      <Card.Text style={{ marginTop: 5, fontSize: 13 }}>{text}</Card.Text>
      <Card.Text style={{ marginTop: 10, fontSize: 11 }}>{subText}</Card.Text>
      </Card.Body>
      </Link>
      <ModalContainer visible={visibleLogin} toggle={toggleLogin}>
          <div style={{ padding: '40px' }}>
              <Login onClose={toggleLogin} />
          </div>
      </ModalContainer>
    </div>
    : consult === 'freeconsult' ?
    <div>
    <Link  onClick={togglefreeConsult}> 
      <Card.Body style={{ textAlign: 'center', fontSize: 13 }}>
        {/* <Card.Title>Primary Card Title</Card.Title> */}
        <img
          style={{ width: '5', height: '4em' }}
          src={require(`../../assets/svg/${icon}.svg`)}
          alt=''
        />
        <Card.Text style={{ marginTop: 5, fontSize: 13 }}>{text}</Card.Text>
        <Card.Text style={{ marginTop: 10, fontSize: 11 }}>{subText}</Card.Text>
      </Card.Body>
    </Link>
    </div>
    :
    consult === 'calenderSlot' ?
    <div>
      <Link  onClick={togglefreeConsult}> 
        <Card.Body style={{ textAlign: 'center', fontSize: 13 }}>
          {/* <Card.Title>Primary Card Title</Card.Title> */}
          <img
            style={{ width: '5', height: '4em' }}
            src={require(`../../assets/svg/${icon}.svg`)}
            alt=''
          />
          <Card.Text style={{ marginTop: 5, fontSize: 13 }}>{text}</Card.Text>
          <Card.Text style={{ marginTop: 10, fontSize: 11 }}>{subText}</Card.Text>
        </Card.Body>
      </Link>
      </div>
    :
    <div>
    <Link to={link}> 
      <Card.Body style={{ textAlign: 'center', fontSize: 13 }}>
        {/* <Card.Title>Primary Card Title</Card.Title> */}
        <img
          style={{ width: '5', height: '4em' }}
          src={require(`../../assets/svg/${icon}.svg`)}
          alt=''
        />
        <Card.Text style={{ marginTop: 5, fontSize: 13 }}>{text}</Card.Text>
        <Card.Text style={{ marginTop: 10, fontSize: 11 }}>{subText}</Card.Text>
      </Card.Body>
    </Link>
    </div>
  }
  </Card>
)

const mapStateToProps = (state) => ({
  userId: getStoreUserId(state),
  user: getStoreUser(state),
  insuranceFinAssetList: getStoreFinAssetList(state).filter(
    (fa) => fa.assetLookupCode === 'INS'
  ),
  FinAssetList: getStoreFinAssetList(state).filter(
    (fa) => fa.assetLookupCode !== 'INS'
  ),
  speedometerDataList: getSpeedometerList(state),
  userRole:getStoreUserRole(state),
  EstatePlanData : getEstatePlanData(state),
  LegalReviewData : getLegalReviewData(state),
  
  shareDataByMe:getDataSharedByme(state),
  shareDataBy : getDataSharedByList(state),
  shareDataByCount : getDataSharedWithNamesList(state),

})
const mapDispatchToProps = () => (dispatch) => ({
  dispatchSetFamilyList: (contact) => dispatch(setFamilyData(contact)),
  dispatchAddFinAsset: (finAsset) => dispatch(setFinAssetData(finAsset)),
  dispatchSetImmovableAssetList: (immovableAsset) =>
    dispatch(setImmovableAssetData(immovableAsset)),
  dispatchSetDigitalAssetList: (digitalAsset) =>
    dispatch(setDigitalAssetData(digitalAsset)),
    dispatchSetOtherAssetList: (otherAssets) =>
    dispatch(setOtherAssetData(otherAssets)),
  dispatchSetContactList: (contact) => dispatch(setContactData(contact)),
  dispatchSetDigilockerData: (digilockerData) => dispatch(setDigilockerData(digilockerData)),
  dispatchSetUserRoleData: (userRolesData) =>dispatch(setUserRoleData(userRolesData)),
  dispatchSetOrderData: orders => dispatch(setOrderData(orders)),
  dispatchSetWillData: willData => dispatch(setWillDataList(willData)),
  dispatchDataShareWithme: dataShareWithme=> dispatch(setDataShareWithme(dataShareWithme)),
  dispatchDataSharedByme: dataSharedByme=> dispatch(setDataSharedByme(dataSharedByme)),
  dispatchDataShareWithNames: dataSharedWithNames=> dispatch(setDataSharedWithNames(dataSharedWithNames)),
  dispatchDataShareBy: dataSharedBy=> dispatch(setDataSharedBy(dataSharedBy)),
  dispatchDataShareByNames : dataSharedByNames=>dispatch(setDataSharedByNames(dataSharedByNames)),

})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer)
