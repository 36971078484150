import React from 'react';
import {Modal} from 'react-bootstrap';

const ModalContainer=({visible, children})=> {
    return (
      <Modal
        show={visible}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {children}
      </Modal>
    );
  }

  export default ModalContainer