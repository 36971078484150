import React,{ useImperativeHandle, forwardRef } from "react";

import string from "../../constant/en.json";
import HeadBar from "../../components/HeaderBar";
import Input from "../../components/Input";
import { useInput } from "../../hooks/useInput";

const InstitutionForm = ({ defaultValue= { firstName: '' } }, ref) =>{
    const { value: firstName, bind: bindInstitutionName } = useInput(defaultValue.firstName);

    useImperativeHandle(ref, () => ({
      returnDetails() {
        return {
          firstName,
          relationship: 'OTH_REL'
        };
      },
    }));
    return(
      <React.Fragment>
        <HeadBar title={string.forms.contactSpecific.instituteName} />
        <div className='row'>
            <Input type='text' placeholder={string.forms.contactSpecific.instituteName} isBigger bind={bindInstitutionName} />
        </div>
      </React.Fragment>
    );
  }
  export const Institution = forwardRef(InstitutionForm);
  