import React, { useImperativeHandle, forwardRef } from 'react';
import DropDown from '../../components/DropDown';
import Input from '../../components/Input';
import { useInput } from '../../hooks/useInput';

export const NameDeets = ({ defaultValue = { firstName: '', middleName: '', lastName: '', title: '' } }, ref) => {
    const { value: firstName, bind: bindFN } = useInput(defaultValue.firstName);
    const { value: middleName, bind: bindMN } = useInput(defaultValue.middleName);
    const { value: lastName, bind: bindLN } = useInput(defaultValue.lastName);
    const { value: title, bind: bindTitle } = useInput(defaultValue.title);
    useImperativeHandle(ref, () => ({
        returnDetails() { return { title, firstName, middleName, lastName } }
    }));
    return <div className='row'>
        <DropDown isSmaller placeholder={'Title'} options={[
            { code: 'Mr.', value: 'Mr.' },
            { code: 'Miss.', value: 'Miss.' },
            { code: 'Mrs.', value: 'Mrs.' },
            { code: 'Ms.', value: 'Ms.' },
            { code: 'Dr.', value: 'Dr.' }]} bind={bindTitle} />
        <Input type='text' placeholder={'First Name'} bind={bindFN} />
        <Input type='text' placeholder={'Middle Name'} bind={bindMN} />
        <Input type='text' placeholder={'Last Name'} bind={bindLN} />
    </div>
};
const NameDetails = forwardRef(NameDeets);
export default NameDetails;