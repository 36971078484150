import moment from "moment";
import React from 'react';
import { connect } from 'react-redux';
import { deleteContactFromList } from '../../actions/contactActions';
import { deleteFamilyFromList } from '../../actions/familyActions';
import CollapseSection from "../../components/CollapsibleSection";
import { AlignColumn, CenterAlignRow, TableContainer, ActionItemUpload } from "../../components/CustomElements";
import { getContactFullName, getContactType, getRelation, isEmpty } from '../../utils/commonUtil';
import { deleteContact } from '../forms/contacts/ContactService';
import { useQuaishToasts } from '../../actions/alertAction';

const ContactListTable = ({ data, conType, dispatchDeleteContact, dispatchDeleteFamily, toggleForm, onEdit, onUpload }) => {
  const isContact = conType === 'Contacts';
  const isFamily = conType === 'Family';
  const { success, error } = useQuaishToasts()
  const deleteCon = (id) => {
    if (window.confirm("Delete?\n Are you sure you want to delete this record?")) {
      deleteContact(id)
        .then(response => {
          success('Record Deleted Successfully');
          if (data.length === 0 && toggleForm) toggleForm();
          if (isContact)
            dispatchDeleteContact(id);
          else if (isFamily)
            dispatchDeleteFamily(id);
        })
        .catch(err => {
          error(err)
        })
    }
  }

  const row = data.map((e, i) => {
    const isSpouse = e.relationship === 'SPOUSE';
    const isChild = e.relationship === 'CHILD';
    const isParent = e.relationship === 'FATHER' || e.relationship === 'MOTHER';
    const isChildOrSpouse = isSpouse || isChild;
    return (
      <TableContainer key={e.id}>
        <CenterAlignRow>
          <AlignColumn label="Name" text={getContactFullName(e)} />
          <AlignColumn label="Relationship" text={getRelation(e)} />
          {isChildOrSpouse || isParent ?
            <AlignColumn label="Gender" text={e.gender} />
            : <AlignColumn label="Type" text={getContactType(e)} />
          }
          {isSpouse || isChild ?
            <AlignColumn label="DOB" text={!isEmpty(e.dob) ? moment(e.dob).format('YYYY-MM-DD') : ''} /> :
            isParent ?
              <AlignColumn label="Deceased" text={e.deceased ? 'Yes' : 'No'} /> : null}
          <AlignColumn label="Action" text={<ActionItemUpload onUpload={()=>onUpload(e)} datashare={'noShare'} onEdit={() => onEdit(e)} onDelete={() => deleteCon(e.id)}
          />} />
        </CenterAlignRow>
      </TableContainer>
    )
  });
  return (
    <div>
      <CollapseSection mainTitle={'' + conType} collapsed={false} isCollapsable={true} customStyle={true}>
        {row}
      </CollapseSection>
    </div>
  );
};

const mapDispatchToProps = () => dispatch => ({
  dispatchDeleteContact: contactId => dispatch(deleteContactFromList(contactId)),
  dispatchDeleteFamily: contactId => dispatch(deleteFamilyFromList(contactId)),
})
export default connect(null, mapDispatchToProps)(ContactListTable);
