import initialState from "./initialState";
import _ from 'lodash';
import { ADD_FIN_ASSET_LIST, DELETE_FIN_ASSET, CREATE_FIN_ASSET, EDIT_FIN_ASSET } from "../actions/finAssetsActions";
export default (state = initialState.finAssets, action) => {
    switch (action.type) {
        case ADD_FIN_ASSET_LIST:
            return action.finAssets;

        case DELETE_FIN_ASSET:
            return state.filter(con => con.id !== action.finAssetId);
        case CREATE_FIN_ASSET:
            return state.concat(action.finAsset)
        case EDIT_FIN_ASSET:
            const index = _.findIndex(state, { id: action.finAsset.id });
            state.splice(index, 1, action.finAsset);
            return [...state];
        default:
            return state;
    }
}