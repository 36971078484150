import initialState from "./initialState";
import _ from 'lodash';
import { ADD_DIGITAL_ASSET_LIST, DELETE_DIGITAL_ASSET, EDIT_DIGITAL_ASSET, CREATE_DIGITAL_ASSET } from "../actions/digitalAssetsActions";
export default (state = initialState.digitalAssets, action) => {
    switch (action.type) {
        case ADD_DIGITAL_ASSET_LIST:
            return action.digitalAssets;

        case DELETE_DIGITAL_ASSET:
            return state.filter(con => con.id !== action.digitalAssetId);
        case CREATE_DIGITAL_ASSET:
            return state.concat(action.digitalAsset)
        case EDIT_DIGITAL_ASSET:
            const index = _.findIndex(state, { id: action.digitalAsset.id });
            state.splice(index, 1, action.digitalAsset);
            return [...state];
        default:
            return state;
    }
}